import React, { useState, useEffect } from "react";
import DataTable from "../../../components/Tables/DataTable";
import DelegationCreationForm from "./DelegationCreationForm";
import { Button, Typography } from "@mui/material";
import { DelegationService } from "../../../services/DelegationService";
import { getUser } from "../../Common/Utils";
import { delegationAction } from "../../../actions/delegationAction";
import { useDispatch, useSelector } from "react-redux";


const DelegationTable = () => {
  const [showForm, setShowForm] = useState(false);
  const [Isdelete, setIsDelete] = useState(false);
  const [selectedDel, setSelectedDel] = useState(null);
  const [delegation, setDelegation] = useState([]);
  const dispatch = useDispatch();

  const columns1 = ["Starts", "Ends", "Delegate_users", "Call_routing", "Messaging", "Appointments", "Status", "Actions"];
  const columns = ["start_date", "end_date", "created_at", "delegate_user_id", "allocation", "active_flag", "Actions"];
  const rows = [
    {
        Starts: "2025-01-23 09:00 AM",
        Ends: "2025-01-23 10:00 AM",
        Delegate_users: ["John Doe", "Steve Doe", "Stete Doe"],
        Call_routing: "True",
        Messaging: "True",
        Appointments: "True",
        Status: "Active",
        Actions: "View Details"
      },
      {
        Starts: "2025-01-23 11:00 AM",
        Ends: "2025-01-23 12:00 PM",
        Delegate_users: "Jane Smith",
        Call_routing: "True",
        Messaging: "False",
        Appointments: "True",
        Status: "Pending",
        Actions: "Reschedule"
      }
  ];

  const handleDeleteClick = async (delegationId) => {
    const user = getUser();
    try {
      await dispatch(delegationAction.deleteDelegation(delegationId, user.user_id)); 

      // Fetch updated agents list after deletion
      //await fetchTerritory();
      setIsDelete(true)
    } catch (error) {
      console.error("Error deleting agent:", error);
    }
 
  };

  useEffect(() => {
    const user = getUser();
    const fetchDelegation = async () => {
      const data = await DelegationService.getDelegationByUserId(user.user_id);
      setDelegation(data?.data);
    };
    fetchDelegation();
  }, []);

  

  const enhancedRows = delegation.map((row) => ({
    ...row,
    Actions: (
      <>
        {/* <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            setSelectedDel(row); // Set the selected role for editing
            // setOpenCreate(true); // Open the form
            // setOpenForm(true);
          }}          
          style={{ marginRight: "10px" }}
        >
          Edit
        </Button> */}
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => handleDeleteClick(row.id)}
        >
          Delete
        </Button>
      </>
    ),
  }));

  const handleAddDelegation = () => {
    setShowForm(true);
  };

  const handleCancelForm = () => {
    setShowForm(false);
  };

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <Button variant="contained" color="primary" onClick={handleAddDelegation}>
          Add Delegation
        </Button>
      </div>
      {showForm && (
        <div style={{ marginBottom: "20px" }}>
          <DelegationCreationForm onCancel={handleCancelForm} />
        </div>
      )}
      <Typography variant="h6" gutterBottom>
        Delegation Details
      </Typography>
      <DataTable columns={columns} rows={enhancedRows} tableType="Delegation" />
    </div>
  );
};

export default DelegationTable;
