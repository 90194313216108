import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialogbox from "../../../Common/DialogBox";
import EmailBody from "./Emailbody";
// import { useGoogleLogin } from "react-google-login";
import { useGoogleLogin } from "@react-oauth/google";
// import { workspaceService } from "../../../../../services/workspaceService";
import { workspaceService } from "../../../../services";
import { crmActions } from "../../../../actions";
import {
  CLIENT_ID,
  API_KEY,
  DISCOVERY_DOCS,
  SCOPES,
} from "../../../Common/Constants";
import { set } from "react-ga";

// console.log(customerId, "<==customerId composebygmail");
const loadGoogleScript = () => {
  //loads the Google JavaScript Library
  (function () {
    const id = "google-js";
    const src = "https://apis.google.com/js/platform.js";

    //we have at least one script (React)
    const firstJs = document.getElementsByTagName("script")[0];

    //prevent script from loading twice
    if (document.getElementById(id)) {
      return;
    }
    const js = document.createElement("script");
    js.id = id;
    js.src = src;
    js.onload = window.onGoogleScriptLoad;
    firstJs.parentNode.insertBefore(js, firstJs);
  })();
};

const ComposeByGmail = ({
  surveyID,
  show,
  handleClose,
  records = [],
  emailType,
  email,
  customerId,
  customers,
  usr,
  isAppointment,
  subject,
  subjectApp,
  scheduleApp,
  contentApp,
  content
}) => {
  // console.log(surveyID, "<==surveyID");
  const [body, setBody] = useState({ subject: "", content: "", schedule: "" });
  const [gAuth, setGoogleAuth] = useState();
  const [gapi, setGapi] = useState();
  const [accessToken, setAccessToken] = useState();
  const [refreshToken, setRefreshToken] = useState();
  const [isSchedule, setIsSchedule] = useState(false);
  const [fromEmail, setFromEmail] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    //window.gapi is available at this point
    console.log("Compose by gmail render");
    window.onGoogleScriptLoad = () => {
      const _gapi = window.gapi;
      setGapi(_gapi);
      _gapi.load("auth2", () => {
        (async () => {
          if (!gapi.auth2.getAuthInstance()) {
            // console.log("CLIENT_ID", CLIENT_ID);
            const _googleAuth = await _gapi.auth2.init({
              client_id: CLIENT_ID,
              api_key: API_KEY,
              discoveryDocs: DISCOVERY_DOCS,
              scope: SCOPES,
            });

            console.log("_googleAuth", _googleAuth);

            setGoogleAuth(_googleAuth);
          } else {
            console.log("GAPI already initialized");
          }
        })();
      });
    };
    //ensure everything is set before loading the script
    loadGoogleScript();
  });
  useEffect(() => {
    gAuth && getToken();
  }, [gAuth]);

  const onSuccess = (response) => {
    const { code } = response;
    try {
      const params = {
        code: code,
      };
      workspaceService.oauth2TokenExchange(params).then((res) => {
        const accessToken = res.data.access_token;
        const refreshToken = res.data.refresh_token;

        const auth = JSON.parse(localStorage.getItem('auth')) || {}; 
        auth.access_token = accessToken;
        auth.refresh_token = refreshToken;
        localStorage.setItem('auth', JSON.stringify(auth));
        
        setAccessToken(res.data.access_token);
        setTimeout(() => {
          sendEmail(res.data.access_token, "", res.data.refresh_token);
        }, 100);
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const onFailure = (res) => {
    console.log("onFailure ", res);
  };

  const signIn = useGoogleLogin({
    onSuccess,
    onFailure,
    scope: SCOPES,
    flow: "auth-code",
  });

  const getToken = () => {
    try {
      let googleA = window.gapi.auth2.getAuthInstance();

      let googleUser = googleA.currentUser.get();
      let authResp = googleUser.getAuthResponse(true);
      let profile = googleUser.getBasicProfile();

      if (authResp) {
        let access_token = authResp["access_token"];
        setAccessToken(authResp["access_token"]);
        setRefreshToken(authResp["refresh_token"]);
        setFromEmail(profile.getEmail());
      }
    } catch (e) {
      console.log("Access token not fond", e);
    }
  };

  // ---------- Get Customer Local Time ---------- //
  const onChange = (e) => {
    const { name = "", value = "" } = e && e.target ? e.target : {};
    setBody((body) => ({ ...body, [name]: value }));
    console.log(body, "<==body");
  };
  const reset = () => {
    setBody({ subject: "", content: "", schedule: "" });
  };

  useEffect(() => {
    if (isAppointment && subjectApp && contentApp) {
      // Auto-fill subject and content, and send the email programmatically
      setBody({ subjectApp, contentApp, scheduleApp });
      setTimeout(() => sendEmail(), 100); // Allow state to update before sending
    }
  }, [isAppointment, subject, content]);

  const sendEmail = (token, senderEmail, refresh) => {
    if (!accessToken && !token && usr === "gmail") {
      signIn();
      return;
    }

    try {
      const params = {
        access_token: token ? token : accessToken,
        refresh_token: refresh ? refresh : refreshToken,
        email_type: emailType,
        subject: isAppointment ? subjectApp : body.subject,
        body: isAppointment ? contentApp : body.content,
        schedule: isAppointment ? scheduleApp :  body.schedule,
        ...(customers.length > 0 && { customer_ids: customers }),
      };
      console.log(usr, "<===params");
      // usr === "gmail"
      //   ? dispatch(crmActions.sendEmail(params, customerId))
      //   : dispatch(crmActions.sendEmailSMTP(params));
      if (isAppointment) {
        if (usr === null) {
          dispatch(crmActions.sendEmailSMTP(params));
        }
      } else {
        if (usr === "gmail" || usr === "outlook") {
          dispatch(crmActions.sendEmail(params, customerId));
        } else {
          dispatch(crmActions.sendEmailSMTP(params));
        }
      }
      reset();
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (customers.length > 1) {
      setIsSchedule(true);
    }
  }, [customers]);


  if (isAppointment) return null;

  return (
    <Dialogbox
      open={show}
      rootClass={"addLogoDialog addPageTitleDialog"}
      handleClose={handleClose}
      dialogContentClass={"pageTitleDialogInner"}
    >
      <EmailBody
        body={body}
        setBody={setBody}
        onChange={onChange}
        handleClose={handleClose}
        sendEmail={sendEmail}
        signIn={signIn}
        isSchedule={isSchedule}
      />
    </Dialogbox>
  );
};

export default ComposeByGmail;
