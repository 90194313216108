import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from '@mui/icons-material/Search';
import { visuallyHidden } from '@mui/utils';
import { clone } from '../../Common/Utils';
import Backdrop from '../../Common/Backdrop';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, headCells, hasDeleteBtn, onAllSelect, rows=[] } = props;
    const filteredRows = rows.filter(r=> r?.survey_sent !== 'Sent');
    const checkedAllSelect = filteredRows.length && (filteredRows.filter(r=>r.selected).length === filteredRows.length);
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox" >
                    { onAllSelect ? <Checkbox color="primary" checked={checkedAllSelect} onChange={onAllSelect}/> : null }
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell?.id}
                        align={headCell?.numeric ? 'right' : 'left'}
                        padding={headCell?.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell?.id ? order : false}
                    >
                        {headCell.hideSorting ? headCell?.label :
                            <TableSortLabel
                            active={orderBy === headCell?.id}
                            direction={orderBy === headCell?.id ? order : 'asc'}
                            onClick={createSortHandler(headCell?.id)}
                            >
                            {headCell?.label}
                            {orderBy === headCell?.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                                ) : null}
                            </TableSortLabel>
                        }

                    </TableCell>
                ))}
                {hasDeleteBtn && <TableCell padding="checkbox" ></TableCell>}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    headCells: PropTypes.array.isRequired,
};

const CustomTable = ({rows, headCells, hasPagination=true, onRowSelect, hasDeleteBtn=false, onDelete, loading, hideCheckbox, emptyText, onAllSelect}) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onCheckUncheck = (e, row, index) => {
        const {checked} = e?.target;
        onRowSelect && onRowSelect(row, ((rowsPerPage*page) + index), checked);

    };
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return <div>
        <TableContainer className={"tableWrap"}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
                <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} hasDeleteBtn={hasDeleteBtn} onAllSelect={onAllSelect} rows={rows}/>
                <TableBody>
                    {stableSort(rows, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                            const isDisable = row?.survey_sent === 'Sent' || row?.survey_sent === 'Scheduled';
                            const rowStyle = (hasDeleteBtn && !isDisable && row.selected) ? {backgroundColor: "#E8E8E8"}:{};
                            const trashStyle = (!isDisable && row.selected)? {display:'block'}:{display:'none'};
                            return (
                                <TableRow tabIndex={-1} key={index} sx={rowStyle}>
                                    <TableCell padding="checkbox">{ hideCheckbox ? null : <Checkbox color="primary" disabled={isDisable} checked={isDisable ? false : !!row.selected} onChange={(e)=>onCheckUncheck(e, row, index)}/>}</TableCell>
                                    {headCells.map((h, i)=><TableCell key={"body-cell-"+i}
                                        align={h?.numeric ? 'right' : 'left'}
                                        padding={h?.disablePadding ? 'none' : 'normal'}>

                                            { h.renderer ? h.renderer(row)  : row[h?.id]}

                                            </TableCell>)}
                                    {hasDeleteBtn &&
                                        <TableCell padding="checkbox">
                                            <div className={"trash"} style={trashStyle} onClick={() => onDelete(row, index)}>
                                                <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15 23C15.2652 23 15.5196 22.8946 15.7071 22.7071C15.8946 22.5196 16 22.2652 16 22V16C16 15.7348 15.8946 15.4804 15.7071 15.2929C15.5196 15.1054 15.2652 15 15 15C14.7348 15 14.4804 15.1054 14.2929 15.2929C14.1054 15.4804 14 15.7348 14 16V22C14 22.2652 14.1054 22.5196 14.2929 22.7071C14.4804 22.8946 14.7348 23 15 23ZM25 11H21V10C21 9.20435 20.6839 8.44129 20.1213 7.87868C19.5587 7.31607 18.7956 7 18 7H16C15.2044 7 14.4413 7.31607 13.8787 7.87868C13.3161 8.44129 13 9.20435 13 10V11H9C8.73478 11 8.48043 11.1054 8.29289 11.2929C8.10536 11.4804 8 11.7348 8 12C8 12.2652 8.10536 12.5196 8.29289 12.7071C8.48043 12.8946 8.73478 13 9 13H10V24C10 24.7956 10.3161 25.5587 10.8787 26.1213C11.4413 26.6839 12.2044 27 13 27H21C21.7956 27 22.5587 26.6839 23.1213 26.1213C23.6839 25.5587 24 24.7956 24 24V13H25C25.2652 13 25.5196 12.8946 25.7071 12.7071C25.8946 12.5196 26 12.2652 26 12C26 11.7348 25.8946 11.4804 25.7071 11.2929C25.5196 11.1054 25.2652 11 25 11ZM15 10C15 9.73478 15.1054 9.48043 15.2929 9.29289C15.4804 9.10536 15.7348 9 16 9H18C18.2652 9 18.5196 9.10536 18.7071 9.29289C18.8946 9.48043 19 9.73478 19 10V11H15V10ZM22 24C22 24.2652 21.8946 24.5196 21.7071 24.7071C21.5196 24.8946 21.2652 25 21 25H13C12.7348 25 12.4804 24.8946 12.2929 24.7071C12.1054 24.5196 12 24.2652 12 24V13H22V24ZM19 23C19.2652 23 19.5196 22.8946 19.7071 22.7071C19.8946 22.5196 20 22.2652 20 22V16C20 15.7348 19.8946 15.4804 19.7071 15.2929C19.5196 15.1054 19.2652 15 19 15C18.7348 15 18.4804 15.1054 18.2929 15.2929C18.1054 15.4804 18 15.7348 18 16V22C18 22.2652 18.1054 22.5196 18.2929 22.7071C18.4804 22.8946 18.7348 23 19 23Z" fill="#AEB6CF" />
                                                </svg>
                                            </div>
                                        </TableCell>
                                    }
                                </TableRow>
                            );
                        })}
                    {emptyRows > 0 && (
                        <TableRow style={{height: (53) * emptyRows}}>
                            <TableCell colSpan={5} />
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
        {rows && rows.length ? null:<div style={{textAlign: 'center', padding: 20}}><p>{emptyText || "No data found."}</p></div>}
        { hasPagination ? <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        /> : null }
        <Backdrop show={loading}/>
    </div>
};

export default CustomTable;
