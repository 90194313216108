import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Button, Paper, Collapse, FormGroup } from '@mui/material';
import InviteTeamPopup from './InviteTeamPopup';
import DataTable from '../../../components/Tables/DataTable';
import { AdminService } from '../../../services/AdminService';
import UserForm from './UserForm';
import api from "../../../services/api";
import { getUser } from '../../Common/Utils';
import { swalAlert } from '../../Common/SwalAlert';

const UsersAndTeams = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isUserFormVisible, setIsUserFormVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userData, setUserData] = useState([]);
  const [pendingUser, setPendingUser] = useState([]);
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');

  // Handle file selection
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'text/csv') {
      setFile(selectedFile);
      setUploadStatus('File selected: ' + selectedFile.name);

      // Automatically call the API after file selection
      await handleUpload(selectedFile);
    } else {
      setUploadStatus('Please select a valid CSV file.');
      setFile(null);
    }
  };

  // Handle file upload
  const handleUpload = async (selectedFile) => {
    if (!selectedFile) {
      setUploadStatus('No file selected.');
      return;
    }

    setUploadStatus('Uploading...');

    const formData = new FormData();
    formData.append('file', selectedFile); // Use 'file' as the field name

    try {
      // Call the API with the required headers
      const result = await api.post('/api/users/invite/bulk', formData, {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`, // Replace with your token retrieval logic
          'Content-Type': 'multipart/form-data',
        },
      });

      setUploadStatus('Upload successful!');
      setRefresh(prev => !prev);
      console.log('API Response:', result.data);
    } catch (error) {
      setUploadStatus('Upload failed. Please try again.');
      console.error('Error uploading file:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setRefresh(prev => !prev);
  };

  const toggleUserForm = (user) => {
    setSelectedUser(user); // Set the selected user data
    setIsUserFormVisible(!isUserFormVisible); // Toggle UserForm visibility
  };

  async function handleRevoke(id) {
    try {
      const userData = { status: "REVOKED" };
      const response = await AdminService.revokeUsers(userData, id);
      swalAlert({
        icon: "success",
        title: "Revoked successfully!",
        showConfirmButton: true,
      });
      setRefresh(prev => !prev);
      setRefresh(true);
    } catch (error) {
      console.error("Error revoking user:", error);
    }
  }

  const currentUserData = [
    { name: "Kane", email: "kane21@mail.com", role: "User", team: "None", territory: "Not Assigned" },
    { name: "Steve", email: "steve34@mail.com", role: "User", team: "None", territory: "Not Assigned" },
  ];

  const columns = ["firstname", "email", "role_name", "status", "extension_number", "date_created", "Team", "Territory", "Actions"];

  const fetchUsers = async () => {
    const data = await AdminService.getUsers();
    setUserData(data?.data);
  };

  const fetchPendingUsers = async () => {
    const data = await AdminService.pendingUsers("PENDING");
    setPendingUser(data?.data);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const data = await AdminService.getUsers();
      setUserData(data?.data);
    };
    fetchUsers();
  }, []);

  const rows = userData.map((row, index) => ({
    id: index + 1, // Add a unique key for each row
    Name: row.username,
    Email: row.email,
    Role: row.role_name,
    Team: row.team,
    Territory: row.territory,
  }));

  useEffect(() => {
    fetchUsers();
    fetchPendingUsers();
  }, [refresh]);

  const pendingUserData = [
    { name: 'John Doe', email: 'john.doe@example.com' },
    { name: 'Jane Smith', email: 'jane.smith@example.com' },
  ];

  const pendigColumns = ["invited_email", "status", "invite_type", "Action"];

  const pendingRows = pendingUser?.map((row, index) => ({
    invited_email: row.invited_email,
    status: row.status,
    invite_type: row.invite_type,
    Source: row.source,
    Action: (
      <>
        {/* <Button
          variant="contained"
          color="success"
          size="small"
          style={{ marginRight: "8px" }}
        >
          Approve
        </Button> */}
        <Button variant="contained" color="error" size="small"
          onClick={() => handleRevoke(row.id)}
        >
          Revoke
        </Button>
      </>
    ),
  }));

  const enhancedRows = userData?.map((row) => ({
    ...row,
    Actions: (
      <>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => toggleUserForm(row)} // Pass the selected row data
          style={{ marginRight: "10px" }}
        >
          Edit
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => alert(`Delete ${row.username}`)}
        >
          Delete
        </Button>
      </>
    ),
  }));

  return (
    <div style={{ padding: '16px', flexGrow: 1 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', gap: '16px' }}>
        {/* Responsive Tabs */}
        <div style={{ flex: '0 0 40%' }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            style={{ borderRadius: '4px', textTransform: 'capitalize' }}
          >
            <Tab label="Current User" style={{ textTransform: 'capitalize' }} />
            <Tab label="Pending User" style={{ textTransform: 'capitalize' }} />
          </Tabs>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '5px' }}>
          {/* Small Download Template Link */}
          <a
            href="/user_invite_template.csv"
            download="template.csv"
            style={{
              fontSize: '12px',
              color: '#0b7ec1',
              textDecoration: 'underline',
              alignSelf: 'flex-start',
              cursor: 'pointer',
              marginLeft: "10px",
            }}
          >
            Download Template
          </a>

          {/* Hidden file input */}
          <div>
          <input
            type="file"
            accept=".csv"
            id="csv-upload"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />

          {/* Upload CSV Button */}
          <label htmlFor="csv-upload">
            <Button
              variant="contained"
              color="grey"
              style={{ alignSelf: 'flex-start', margin: '5px' }}
              component="span"
            >
              Upload CSV
            </Button>
          </label>

          {/* Invite Users Button */}
          <Button
            variant="contained"
            color="primary"
            style={{ alignSelf: 'flex-start', backgroundColor: '#1595a3' }}
            onClick={openPopup}
          >
            Invite Users
          </Button>
          </div>

          {/* Upload Status */}
          <div style={{ marginTop: '10px', color: uploadStatus.includes('failed') ? 'red' : 'green' }}>
            {uploadStatus}
          </div>
        </div>

      </div>

      {/* Collapse for UserForm */}
      <Collapse in={isUserFormVisible}>
        <FormGroup sx={{ pl: 2, fontSize: '0.875rem' }}>
          <UserForm user={selectedUser} onCancel={() => setIsUserFormVisible(false)} />
        </FormGroup>
      </Collapse>

      <Paper style={{ marginTop: '16px' }}>
        {activeTab === 0 && (
          <DataTable columns={columns} rows={enhancedRows} tableType="Users" />
        )}

        {activeTab === 1 && (
          <DataTable columns={pendigColumns} rows={pendingRows} tableType="Pending Users" />
        )}
      </Paper>

      {isPopupOpen && <InviteTeamPopup onClose={closePopup} />}
    </div>
  );
};

export default UsersAndTeams;