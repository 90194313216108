import { useEffect } from 'react';

const COOKIE_ID = "cookieyes";
const COOKIE_TYPE = "text/javascript";
// add cookie src
const COOKIE_SRC = "https://cdn-cookieyes.com/client_data/59701f66180a5770d8fbcd6b/script.js";

const CookieBanner = () => 
{ 
    useEffect(() => {
         document.addEventListener('cookieyes_consent_update', (eventData)=>{ 
                //console.log('window-->', window.cookieyes._ckyGetFromStore('consent'));
                //console.log("HANDLE CONSENT--->", eventData) 
            });
            const script = document.createElement('script'); 
            script.src = COOKIE_SRC; 
            script.id = COOKIE_ID; 
            script.type = COOKIE_TYPE; 
            document.head.appendChild(script); 
            return () => { document.head.removeChild(script); }; 
        }, []); 
        return null; 
    }; 
    
    
    
    export default CookieBanner;