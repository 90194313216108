import * as React from "react";
import { useEffect, useState, useRef } from "react";
import cn from "classnames";
import { audienceActions } from "../../../../actions";
import { useDispatch } from "react-redux";
import { BarChart, CustomChart, GoogleGeoChart } from "../Chart";
import Select from "react-select";
import { Pie } from "react-chartjs-2";
import autocolors from "chartjs-plugin-autocolors";

import { useSelector } from "react-redux";
import { surveyActions } from "../../../../actions";
import Backdrop from "../../../Common/Backdrop";
import Highlight from "highlight-react/dist/highlight";
import Notes from "../AnswersAnalysis/Notes";
import { ANS_TYPE } from "../../../Common/Constants";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MuiSelect from "@mui/material/Select";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, getElementAtEvent } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  autocolors,

  Legend
);

const barGraphConfig = () => {
  return {
    labels: ["New york", "Los Angeles", "Chicago", "Houston"],
    onHoverLabel: "Number of Email Sents",
    // width: 345,
    // height: 144,
    backgroundColor: "rgba(255, 99, 132, 0.5)",
    dataSet: [10, 14, 43, 56],
  };
};
function formatOptionLabel({ label, value }) {
  return (
    <div>
      <span>{label}</span>
    </div>
  );
}

const AudienceAnalysis = ({ isFormType }) => {
  const chartRef = useRef(null);

  const pieData = {
    labels: ["Improve Body", "Exercise Deliver Oxygen", "Lung Health Improve"],
    datasets: [
      {
        // label: "# of Votes",
        data: [12, 19, 25],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const [showloader, setshowloader] = useState(false);
  const dispatch = useDispatch();
  const [pieChartData, setpieChartData] = useState([]);
  const [pieChartLabel, setpieChartLabel] = useState([]);
  const longTextSummary = useSelector(
    (state) => state.survey.longTextAudienceSummary
  );

  const getFormattedLabel = (val) => {
    let label = "";
    ANS_TYPE.forEach((e) => {
      if (e.value === val) {
        label = e.label;
      }
    });
    return label;
  };
  const [isNote, setNote] = useState(false);
  const audienceStateFilter = useSelector(
    (state) => state.survey.audienceStateFilter
  );
  const openNotes = () => {
    setNote(true);
  };
  const audienceAiFilter = useSelector(
    (state) => state.survey.audienceAiFilter
  );

  const chart = useRef();

  const options = {
    indexAxis: "y",
    responsive: true,

    maintainAspectRatio: false,

    aspectRatio: 1,
    onClick: (c, i) => {
      // console.log(
      //   "Get the underlying label for click,",
      //   c,
      //   i,
      //   c.chart.config._config.data.labels[i[0].index],
      //   c.chart.config._config.data.labels[i[0].index],
      //   c.chart.tooltip.dataPoints[0].dataset.label
      // );
      setselectedtext(c.chart.tooltip.dataPoints[0].dataset.label);
      getSummarizedData(c.chart.tooltip.dataPoints[0].dataset.label);
    },

    plugins: {
      legend: {
        labels: {
          position: "bottom",
          generateLabels: (chart) => {
            var text = [];

            text.push({
              text: "Positive",
              fillStyle: "#AFE1AF",
            });
            text.push({
              text: "Very Positive",
              fillStyle: "#50C878",
            });
            text.push({
              text: "Extremely Positive",
              fillStyle: "#097969",
            });
            text.push({
              text: "Negative",
              fillStyle: "#EE4B2B",
            });
            text.push({
              text: "Very Negative",
              fillStyle: "#AA4A44",
            });
            text.push({
              text: "Extremely Negative",
              fillStyle: "#880808",
            });
            text.push({
              text: "Neutral",
              fillStyle: "#FFD300",
            });

            return text;
          },
        },
      },
      tooltip: {
        callbacks: {
          title: function (custom) {
            return custom[0].dataset.label;
          },
        },
      },
    },

    scales: {
      x: {
        stacked: true,

        ticks: {
          autoSkip: false,
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          autoSkip: false,
        },
      },
    },
  };
  const [label, setLabel] = useState([]);
  const getSummarizedData = (text) => {
    var results;
    longTextSummary.data.forEach((e) => {
      e.sub_headings.forEach((val) => {
        if (val.text === text) {
          results = val.occurrence;
        }
      });
    });

    setoccurence(results);
  };
  const getSummarizedDataByLabel = (text) => {
    var results = [];

    let index = longTextSummary.data.findIndex((x) => x.text === text);
    console.log("dsjjd", index);
    for (let i = 0; i < longTextSummary.data[index].sub_headings.length; i++) {
      results.push(longTextSummary.data[index].sub_headings[i].occurrence[0]);
    }

    let unique = [...new Set(results)];
    setoccurence(unique);
  };
  const onClick = (event) => {
    console.log(getElementAtEvent(chartRef.current, event));
    if (chartRef.current) {
      const chart = ChartJS.getChart(chartRef.current);
      const clickedElements = chart.getElementsAtEventForMode(
        event,
        "y",
        { axis: "x", intersect: false },
        true
      );
      if (clickedElements.length > 0) {
        console.log(clickedElements[0].index); // Here clicked label | data index
        var label = chart.data.labels[clickedElements[0].index];
        var value =
          chart.data.datasets[clickedElements[0].datasetIndex].data[
            clickedElements[0].index
          ];
        setselectedtext(label);
        getSummarizedDataByLabel(label);
      }
    }
  };
  useEffect(() => {
    let pieLabel = [];
    let pieArray = [];
    longTextSummary &&
      longTextSummary["categorize_and_location_chart"] &&
      Object.entries(longTextSummary["categorize_and_location_chart"]).forEach(
        ([key, value]) => {
          pieLabel.push(key);
          pieArray.push(parseInt(value));
        }
      );
    setpieChartData(pieArray);
    setpieChartLabel(pieLabel);
  }, [longTextSummary]);

  const getBarChartData = () => {
    const label = longTextSummary.data.map((x) => {
      return x.text;
    });
    let newData = [];
    const insert = (index, newItem) => {
      let newarr = [];
      for (let i = 0; i < index + 1; i++) {
        if (i === index) newarr.push(newItem);
        else newarr.push(0);
      }
      return newarr;
    };
    longTextSummary.data.map((x, j) => {
      x.sub_headings.map((k, i) => {
        newData.push({
          stack: x.text,
          data: insert(
            j,
            Math.max(
              k.positive,
              k.negative,
              k.neutral,
              k["very negative"],
              k["extremely negative"],
              k["very positive"],
              k["extremely positive"]
            )
          ),
          label: k.text,
          borderColor: "black",
          borderWidth: 1,
          backgroundColor:
            Math.max(
              k.positive,
              k.negative,
              k.neutral,
              k["very negative"],
              k["extremely negative"],
              k["very positive"],
              k["extremely positive"]
            ) == k.positive
              ? "#AFE1AF"
              : Math.max(
                  k.positive,
                  k.negative,
                  k.neutral,
                  k["very negative"],
                  k["extremely negative"],
                  k["very positive"],
                  k["extremely positive"]
                ) == k["very positive"]
              ? "#50C878"
              : Math.max(
                  k.positive,
                  k.negative,
                  k.neutral,
                  k["very negative"],
                  k["extremely negative"],
                  k["very positive"],
                  k["extremely positive"]
                ) == k["extremely positive"]
              ? "#097969"
              : Math.max(
                  k.positive,
                  k.negative,
                  k.neutral,
                  k["very negative"],
                  k["extremely negative"],
                  k["very positive"],
                  k["extremely positive"]
                ) == k.negative
              ? "#EE4B2B"
              : Math.max(
                  k.positive,
                  k.negative,
                  k.neutral,
                  k["very negative"],
                  k["extremely negative"],
                  k["very positive"],
                  k["extremely positive"]
                ) == k["very negative"]
              ? "#AA4A44"
              : Math.max(
                  k.positive,
                  k.negative,
                  k.neutral,
                  k["very negative"],
                  k["extremely negative"],
                  k["very positive"],
                  k["extremely positive"]
                ) == k["extremely negative"]
              ? "#880808"
              : "#FFD300",
        });
      });
    });
    const data = {
      labels: label,
      datasets: newData,
    };
    return data;
  };
  const questionId = useSelector((state) => state.audience.questionId);

  const getCount = (text) => {
    return text.split(selectedtext).length - 1;
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const [defaultChecked, setDefaultChecked] = useState(0);
  const [occurence, setoccurence] = useState([]);
  const [selectedtext, setselectedtext] = useState("");
  useEffect(() => {
    if (
      longTextSummary &&
      longTextSummary.data &&
      longTextSummary.data.length > 0 &&
      longTextSummary.data[0].sub_headings.length > 0
    ) {
      setselectedtext(longTextSummary.data[0].sub_headings[0].text);
      getSummarizedData(longTextSummary.data[0].sub_headings[0].text);
    } else {
      setselectedtext("");
      setoccurence([]);
    }
  }, [longTextSummary]);
  const [data, setData] = useState([]);
  const surveyStatus = useSelector((state) => state.audience.surveyStatus);
  const questionData = useSelector(
    (state) => state.survey.questionAnalysisData
  );
  const audienceType = useSelector((state) => state.audience.audienceType);
  const [emailSentLabel, setEmailSentLabel] = useState([]);
  const [emailSentValue, setEmailSentValue] = useState([]);

  const [country, setcountry] = useState([]);
  const audienceData = useSelector(
    (state) => state.audience.audienceAnalysisData
  );
  useEffect(() => {
    let label = [];
    let value2 = [];
    audienceData &&
      audienceData["email_sent_response_graph"] &&
      Object.entries(audienceData["email_sent_response_graph"]).forEach(
        ([key, value]) => {
          label.push(key);
          value2.push(value);
        }
      );
    setEmailSentLabel(label);
    setEmailSentValue(value2);
  }, [surveyStatus, audienceData]);
  const [selectedOption, setSelectedOption] = useState({
    label: "Email Sent",
    value: "email_sent",
  });
  const [selectedOption1, setSelectedOption1] = useState({});
  const [dropdownValues, setDropdownValues] = useState([]);
  const [question, setQuestion] = useState({});
  const [questionText, setQuestionText] = useState("");

  const [selectedValue, setSelectedvalue] = useState(null);
  const [questionType, setQuestionType] = useState(null);
  useEffect(() => {
    let ques = [];
    questionData?.long.forEach((e) => {
      ques.push(e);
    });
    questionData?.others.forEach((e) => {
      ques.push(e);
    });
    setQuestion(ques);
    if (ques.length > 0) {
      setQuestionType(ques[0]["question_type"]);
      setQuestionText(ques[0].question);
    }
  }, [questionData]);
  useEffect(() => {
    setshowloader(true);
    let countryData = [];
    let label = [];
    let newData = [];

    audienceData &&
      audienceData.bar_graph_response &&
      audienceData.bar_graph_response.length > 0 &&
      audienceData.bar_graph_response.forEach((e, index) => {
        if (e.country !== null) {
          countryData.push({
            label: e.country + " " + e.total_count,
            country: e.country,
          });
          if (index === 0) {
            setSelectedvalue({
              label: e.country + " " + e.total_count,
              country: e.country,
            });
          }

          if (index === 0) {
            e.state.forEach((e) => {
              if (e.state !== null) {
                label.push(e.state);
                newData.push(e.total_count);
              }
            });
          }
        }
      });
    setcountry(countryData);
    setLabel(label);
    if (label && label.length > 0)
      dispatch(surveyActions.setAudienceStateFilter(label[0]));
    else dispatch(surveyActions.setAudienceStateFilter("all"));

    setData(newData);
    setshowloader(false);
  }, [audienceData]);
  useEffect(() => {
    setDropdownValues([]);
    // if (audienceType === "mail_user") {
    //   dispatch(audienceActions.setSurveyStatus("email_sent"));
    // }
    if (audienceType === "social_channel") {
      var option =
        audienceData.dropdown_values &&
        audienceData.dropdown_values.length > 0 &&
        audienceData.dropdown_values.map((x) => {
          return {
            label: x,
            value: x,
          };
        });

      setDropdownValues(option);
    } else {
      setSelectedOption1({});
    }
  }, [audienceType, audienceData]);
  const getAudienceType = () => {
    return {
      label:
        audienceType === "mail_user"
          ? "Mail User"
          : audienceType === "anon"
          ? "Default"
          : "Social Channel",
      type: audienceType,
      value: "/images/mailuser.png",
    };
  };
  const getSurveyStatus = () => {
    return {
      label: surveyStatus === "email_sent" ? "Email Sent" : "Email Received",
      type: surveyStatus,
      value: "/images/mailuser.png",
    };
  };
  const getDropdownStatus = () => {
    return {
      label: surveyStatus,
      type: surveyStatus,
      value: "/images/mailuser.png",
    };
  };
  return (
    <div className="audienceAnalysisPage">
      <div className="customStyle">
        <Backdrop show={showloader} />

        <div className="row ">
          <div className="col-sm-12 col-md-6">
            <div className="row">
              <div className="col-md-8 col-sm-12">
                <div className={cn("", "analyseQuestionSelect")}>
                  {/* <select
                    value={questionId}
                    onChange={(e) => {
                      setshowloader(true);
                      let index = question.findIndex(
                        (x) => x["qus_id"] === parseInt(e.target.value)
                      );
                      setQuestionText(question[index].question);

                      setQuestionType(question[index].question_type);
                      dispatch(audienceActions.setQuestionId(e.target.value));
                    }}
                    name="question"
                    className="analyseQuestion"
                  >
                    {question &&
                      question.length > 0 &&
                      question.map((ques, index) => {
                        return (
                          <option value={ques["qus_id"]} key={index}>
                            Q:{index + 1} {ques.question}
                          </option>
                        );
                      })}
                  </select> */}

                  <Box
                    sx={{ maxWidth: 485, maxHeight: 55 }}
                    className="analysisCustomSelectBox"
                  >
                    <FormControl fullWidth>
                      <MuiSelect
                        value={questionId || ""}
                        onChange={(e) => {
                          setshowloader(true);
                          let index = question.findIndex(
                            (x) => x["qus_id"] === parseInt(e.target.value)
                          );
                          setQuestionText(question[index].question);

                          setQuestionType(question[index].question_type);
                          dispatch(
                            audienceActions.setQuestionId(e.target.value)
                          );
                        }}
                        name="question"
                        label=""
                        className="analysisCustomSelect"
                        MenuProps={{
                          classes: { paper: "selectPaperRoot" },
                        }}
                      >
                        {question &&
                          question.length > 0 &&
                          question.map((ques, index) => {
                            return (
                              <MenuItem
                                value={ques["qus_id"]}
                                key={index}
                                className="analysisMenuItem"
                              >
                                Q:{index + 1} {ques.question}
                              </MenuItem>
                            );
                          })}
                      </MuiSelect>
                    </FormControl>
                  </Box>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className={cn("analyseCard", "analyseQuestionType")}>
                  <span className="text-capitalize">
                    {questionType ? getFormattedLabel(questionType) : ""}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6">
            <div className={cn("analyseMailDropdown", "mt-2")}>
              <Select
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                className="mailDropdownButton"
                onChange={(e) => {
                  // setSelectedvalue(e);
                  if (audienceType === e.type) {
                  } else setshowloader(true);
                  if (e.type === "mail_user") {
                    dispatch(audienceActions.setSurveyStatus("email_sent"));
                  } else dispatch(audienceActions.setSurveyStatus(""));

                  dispatch(audienceActions.setAudienceType(e.type));
                }}
                getOptionValue={(option) => option.label}
                formatOptionLabel={formatOptionLabel}
                defaultValue={getAudienceType()}
                options={[
                  {
                    label: "Mail User",
                    type: "mail_user",

                    value: "/images/mailuser.png",
                  },
                  {
                    label: "Social Channel",
                    type: "social_channel",

                    value: "/images/Facebook.png",
                  },
                  {
                    label: "Default",
                    type: "anon",

                    value: "/images/Facebook.png",
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-7 col-sm-12 mt-4">
          <CustomChart audienceData={audienceData} />
        </div>
        <div className="col-md-5 col-sm-12 mt-4">
          <div className={cn("analyseCard", "barChart")}>
            <div className="analyse-filter-box">
              <div className="title">No. of users</div>
              <div className="select-items select-items-flex">
                {country && country.length > 0 ? (
                    <div className="item">
                        <Select
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            onChange={(e) => {
                                setSelectedvalue(e);
                                setshowloader(true);

                                let label = [];
                                let data = [];
                                const index = audienceData.data.findIndex(
                                (x) => x.country == e.country
                                );

                                audienceData.data[index].state.forEach((e) => {
                                if (e.state !== null) {
                                    label.push(e.state);
                                    data.push(e.total_count);
                                }
                                });
                                if (label.length > 0) {
                                dispatch(
                                    surveyActions.setAudienceStateFilter(label[0])
                                );
                                } else {
                                dispatch(surveyActions.setAudienceStateFilter("all"));
                                }

                                setLabel(label);
                                setData(data);
                                setshowloader(false);
                            }}
                            getOptionValue={(option) => option.country}
                            formatOptionLabel={formatOptionLabel}
                            value={selectedValue}
                            options={country}
                        />
                    </div>
                    ) : ("")
                }
                
                {audienceType === "social_channel" ? (
                    <div className="item">
                        <Select
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            // defaultValue={get()}
                            placeholder="Select"
                            // // value={selectedOption}
                            onChange={(e) => {
                                if (surveyStatus === e.value) {
                                } else setshowloader(true);

                                dispatch(audienceActions.setSurveyStatus(e.value));
                                setSelectedOption1(e);
                            }}
                            // value={getDropdownStatus()}
                            // value={selectedOption1}
                            options={dropdownValues}
                        />
                    </div>
                ) : null}
                {audienceType === "mail_user" ? (
                    <div className="item">
                        <Select
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            defaultValue={getSurveyStatus()}
                            // value={selectedOption}
                            onChange={(e) => {
                                if (surveyStatus === e.value) {
                                } else setshowloader(true);

                                dispatch(audienceActions.setSurveyStatus(e.value));
                                setSelectedOption(e);
                            }}
                            options={[
                                {
                                label: "Email Sent",
                                value: "email_sent",
                                },
                                {
                                label: "Email Recieved",
                                value: "response_received",
                                },
                            ]}
                        />
                    </div>
                ) : null}
              </div>
            </div>
            {data &&
            data.length === 0 &&
            emailSentValue &&
            emailSentValue.length === 0 ? (
              <div
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  padding: 20,
                }}
              >
                <p>Data Not Found</p>
              </div>
            ) : null}

            <div className="anlyseBarChart">
              {data && data.length > 0 ? (
                <BarChart
                  config={{
                    labels: label,

                    onHoverLabel:
                      surveyStatus === "email_sent"
                        ? "Number of Email Sent"
                        : surveyStatus === "response_received"
                        ? "Number of Email Received"
                        : "Number of Responses",
                    // width: 345,
                    // height: 144,
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                    dataSet: data,
                  }}
                />
              ) : null}

              {surveyStatus === "email_sent" &&
              emailSentValue &&
              emailSentValue.length > 0 ? (
                <BarChart
                  config={{
                    labels: emailSentLabel,

                    onHoverLabel:
                      surveyStatus === "email_sent"
                        ? "Number of Email Sent"
                        : surveyStatus === "response_received"
                        ? "Number of Email Received"
                        : "Number of Responses",
                    // width: 345,
                    // height: 144,
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                    dataSet: emailSentValue,
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {longTextSummary && longTextSummary.data.length > 0 ? (
        <div className="answerAnalysisPage">
          <div className="analyse-answerResponse analyseChartBox">
            <div className="analyseChart-content">
              <div className="answer-item">
                <div className="filterbox">
                  <h3 className="title">Sentiments and Response</h3>
                  <div className="buttonbox">
                    <button
                      className="view-btn"
                      onClick={() => {
                        chart.current.classList.toggle("fixedHeightColumn");
                      }}
                    >
                      <svg
                        width="14"
                        height="10"
                        viewBox="0 0 14 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 3.24126C6.09488 3.24126 5.35851 4.03013 5.35851 5.00003C5.35851 5.96993 6.09488 6.7588 7 6.7588C7.90512 6.7588 8.64149 5.96981 8.64149 5.00003C8.64149 4.03024 7.90512 3.24126 7 3.24126ZM7 0C3.89998 0 0.84123 2.3823 0 5C0.841283 7.61759 3.89998 10 7 10C10.1 10 13.1588 7.6177 14 5C13.1587 2.38241 10.1 0 7 0ZM7 7.86253C5.52668 7.86253 4.32835 6.57839 4.32835 5C4.32835 3.42161 5.52678 2.13747 7 2.13747C8.47322 2.13747 9.67165 3.42172 9.67165 5C9.67165 6.57828 8.47322 7.86253 7 7.86253Z"
                          fill="#18A0FB"
                        />
                      </svg>
                    </button>
                    {label && label.length > 0 ? (
                      <select
                        value={audienceStateFilter}
                        onChange={(e) => {
                          setDefaultChecked(0);
                          setActiveIndex(0);
                          dispatch(
                            surveyActions.setAudienceStateFilter(e.target.value)
                          );
                        }}
                        className="select-field"
                      >
                        {label && label.length > 0
                          ? label.map((value, index) => {
                              return <option value={value}>{value}</option>;
                            })
                          : null}
                      </select>
                    ) : null}

                    <select
                      value={audienceAiFilter}
                      onChange={(e) => {
                        setDefaultChecked(0);
                        setActiveIndex(0);
                        dispatch(
                          surveyActions.setAudienceAiFilter(e.target.value)
                        );
                      }}
                      className="select-field"
                    >
                      <option value="all">All</option>
                      <option value="30">Last 30 Days</option>
                      <option value="50">Last 50 Days</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="answer-item">
                <div className="filterbox">
                  <button onClick={openNotes} className="assign-btn">
                    Assign
                  </button>
                </div>
              </div>
            </div>

            <div className="analyseChart-content " ref={chart}>
              <div className="analyseChart-content">
                <div className="answer-item">
                  <ul className="progress-list">
                    {longTextSummary &&
                    longTextSummary.data.length > 0 &&
                    getBarChartData()?.labels?.length > 0 &&
                    getBarChartData()?.datasets?.length > 0 ? (
                      <div >
                        <Bar
                          ref={chartRef}
                          onClick={onClick}
                          options={options}
                          data={getBarChartData()}
                        />
                      </div>
                    ) : (
                      <div style={{ height: "1200px" }}></div>
                    )}
                  </ul>
                </div>
                <div className="answer-item">
                  <ul className="listingwrap">
                    {occurence && occurence.length > 0
                      ? occurence.map((value, index) => {
                          return (
                            <li className="questtion-item active">
                              <h3 className="questtion-tilte">
                                <Highlight search={selectedtext}>
                                  {value}
                                </Highlight>

                                <span className="coutdata">
                                  {getCount(value)}
                                </span>
                              </h3>
                              {/* <div className="answer-data">
                                                                <p>
                                                                Improve your muscle strenght or web designs. The
                                                                passage is attributed to an unknown
                                                                </p>
                                                            </div> */}
                            </li>
                          );
                        })
                      : null}
                  </ul>
                </div>
              </div>

              <div className="analyseChart-piechart">
                <div className={cn("analyseCard", "analyseCircle")}>
                  <div className="analyse-filter-box">
                    <div className="title">Categorization and location</div>
                  </div>
                  {/* <Pie data={pieData} />; */}
                  {pieChartData && pieChartData.length > 0 ? (
                    <Pie
                      plugins={autocolors}
                      options={{
                        // labels: pieChartLabel,
                        // datasets: pieChartData,

                        plugins: {
                          autocolors: {
                            mode: "data",
                          },
                          legend: {
                            position: "bottom",
                            align: "middle",
                            padding: {
                              top: 4,
                            },
                          },
                        },
                      }}
                      data={{
                        labels: pieChartLabel,
                        plugins: autocolors,

                        datasets: [
                          {
                            data: pieChartData,

                            borderWidth: 1,
                          },
                        ],
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {isNote && (
        <Notes type="audience" question={questionText} setDialog={setNote} />
      )}
    </div>
  );
};
export default AudienceAnalysis;
