import React, {useEffect, useState} from "react";
import SelectCountryFlag from "../../Components/CountryDropdown";
import {checkPhone} from '../../../Common/Utils';

const Contact = (props) =>{
    const {layout,children, qus_id, question, desc="", qno=1, srNo=1, settings={}, cardRef, quesType, onChange, qRefs, submit, btnText='Next', sliderView, onFocus} = props;
    const { font, underline, bold, fontSize, italic, fontColor, require } = settings,
        style = { fontFamily: font, fontSize, fontWeight: bold ? 'bold' : '', fontStyle: italic ? 'italic' : '', textDecoration: underline ? 'underline' : '', color: fontColor };

    const [ans, setAns] = useState('');
    const [err, setErr] = useState('');

    useEffect(()=> {
        const elements = document.getElementsByName("question_"+qno);
        let ele = elements.length ? elements[0] : elements;
        ele.style.setProperty('font-family', font, 'important');
    }, [font]);

    const onNext = () => {
        if(btnText === 'Next'){
            cardRef && cardRef.current.next();
            (qRefs?.current?.length >=qno) && setTimeout(() => { qRefs.current[qno]?.focus() }, 10);
        }else{
            submit && submit();
        }
    };

    const onBlur = () => {
        const _ans = ans.trim();
        if(require && _ans === ''){
            setAns(_ans)
            setErr('This is required.');
            return;
        }
        const isValid = checkPhone(_ans);
        let obj = {
            "qus_id": qus_id,
            "q_type":quesType,
            "qus_text": question,
            "answers": [{ "text":_ans }]
        };
        if(isValid){
            onChange && onChange({name: qno, value:obj});
        } else {
            if(_ans.length){
                setErr('Enter a valid number.');
                setAns(_ans);
                onChange && onChange({name: qno, value:obj, error:'Enter a valid number.'});
                return;
            }
        }
        setAns(_ans);
        setErr('');
    };

    const triggerFocus = () => {
        onFocus && qno > 0 && onFocus(qno-1);
    };

    return(
        <div className="imageWrapInner">
            <div className="ques">
            <h3 name={"question_"+qno} style={style}>{srNo}-{question}
                <span style={{color:"red", marginLeft:'5px',display: require?'unset':'none'}}>*</span>
            </h3>
                <em> {desc} </em>
            </div>
            {layout == 1 && children}
            <div className="contactInner">
                <div className="flag">
                    <SelectCountryFlag value={settings.country_code} showSelectedLabel={false} fullWidth={true} selectedSize={20}/>
                </div>
                <input ref={(r)=> qRefs && (qRefs.current[qno-1] = r)} type="text" placeholder={"(201) 555-1023"} onChange={(e)=> setAns(e.target.value)} onBlur={onBlur} onFocus={triggerFocus}/>
            </div>
            { err !== "" ? <div> <p style={{color:'red'}}>{err}</p></div> : <div><p>&nbsp;</p></div> }
            { sliderView ? <div className="btnGroup">
                <button className="btnSave" onClick={onNext} >{btnText}</button>
            </div> : null}
        </div>
    )
}

export default Contact;
