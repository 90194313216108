import React, { useState, useRef, useEffect } from "react";
import { Header, MainMenu, Filters } from "../Common/Header";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { workspaceActions } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../Common/Utils";
import DashboardViews from "./DashboardViews";
import Backdrop from "../Common/Backdrop";
import { setLocalStorage } from "../Common/Utils";

const Main = ({showUserSetting, settingsTab}) => {
  setLocalStorage('activeTab', 'Summary');
  const dispatch = useDispatch();
  const { default_WS_id, team_id, user_id , default_camp_id} = getUser();
  const details = useSelector((state) => state.allWsDetails);
  const ws = useSelector((state) => state.workspaceByUser);
  const [selectedWS, setWsSelection] = useState();
  const [surveyCount, setSurveyCount] = useState(0);
  const [wsDetails, setWsDetails] = useState([]);
  const [workspace, setWorkspace] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewPage, setViewPage] = React.useState(false),
    onHandleView = () => {
      setViewPage(true);
    };

    // useEffect(() => {
    //   // Dispatch the action with team_id and user_id
    //   dispatch(workspaceActions.getWorkspaceByUser(team_id, user_id));
    // }, [team_id, user_id, dispatch]);

    useEffect(() => {
      // Dispatch the action with team_id and user_id
      dispatch(workspaceActions.getWorkspaceByUser());
    }, [dispatch]);

  useEffect(() => {    
    const user = getUser();
    const campId = user
    console.log("default_camp_id",campId);
    
    if (!ws.loading && ws?.res?.campaigns) {
      const index = ws.res.campaigns.findIndex((c) => c.is_default);
      const id =
        selectedWS ||
        (index > -1 ? ws.res.campaigns[index].id : ws.res.campaigns[0].id) || campId.default_WS_id;
      setLocalStorage("workspaceID", id);
      dispatch(workspaceActions.getAllWSDetails(id));
      setWsSelection(id);
      setWorkspace(ws.res.campaigns);
      setLoading(false);
    }
  }, [ws]);

  useEffect(() => {
    if (
      !details.loading &&
      details &&
      details.res &&
      details.res.campaignDetails
    ) {
      setWsDetails(details.res.campaignDetails);
      setSurveyCount(details.res.surveyLength);
    }
  }, [details]);

  return (
    <>
      {/* <Header /> */}
      {/* <MainMenu workspace_id={selectedWS} dashboardMenu={true} /> */}
      <DashboardViews
        data={wsDetails}
        count={surveyCount}
        workspaceList={workspace}
        setLoader={setLoading}
        setWsForHeader={setWsSelection}
        selectedWorkspace={selectedWS}
        detailsLoading={details.loading}
        wsLoading={ws.loading}
        setLoading={setLoading}
        showUserSetting={showUserSetting}
        settingsTab = {settingsTab}
      />
      <Backdrop show={ws.loading || details.loading || loading} />
    </>
  );
};

export default Main;
