import { teamsConstants } from "../constants/teamsConstants";
import { AdminService } from "../services/AdminService";
import { swalAlert } from "../voc/Common/SwalAlert";

const addSubTeam = (TeamData) => {
  const request = () => ({
    type: teamsConstants.ADD_TEAMS_REQUEST,
  });

  const success = (teams) => ({
    type: teamsConstants.ADD_TEAMS_SUCCESS,
    teams,
  });

  const failure = (error) => ({ 
    type: teamsConstants.ADD_TEAMS_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());
    AdminService.addSubTeam(TeamData).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Subteam added successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const updateTeam = (TeamData, teamId) => {
  const request = () => ({
    type: teamsConstants.EDIT_TEAMS_REQUEST,
  }); 

  const success = (alerts) => ({
    type: teamsConstants.EDIT_TEAMS_SUCCESS,
    alerts,
  });

  const failure = (error) => ({
    type: teamsConstants.EDIT_TEAMS_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());
    AdminService.updateTeam(TeamData, teamId).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Team updated successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const deleteSubTeam = (teamId) => {  
  const request = () => ({
    type: teamsConstants.DELETE_TEAMS_REQUEST,
  });
  
  const success = (teamId) => ({
    type: teamsConstants.DELETE_TEAMS_SUCCESS,
    teamId,
  });
  
  const failure = (error) => ({
    type: teamsConstants.DELETE_TEAMS_FAILURE,
    error,
  });
  
  return (dispatch) => {
    dispatch(request());
    AdminService.deleteSubTeam(teamId).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Team deleted successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const updateSubTeam = (TeamData, teamId) => {
  const request = () => ({
    type: teamsConstants.EDIT_TEAMS_REQUEST,
  }); 

  const success = (alerts) => ({
    type: teamsConstants.EDIT_TEAMS_SUCCESS,
    alerts,
  });

  const failure = (error) => ({
    type: teamsConstants.EDIT_TEAMS_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());
    AdminService.updateSubTeam(TeamData, teamId).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Team updated successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};


export const teamsAction = {
  addSubTeam,
  updateTeam,
  deleteSubTeam,
  updateSubTeam
};
