import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { CommonWrapper, Wrapper } from '../Common/CommonWrapper';
import { userActions } from '../../actions';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';

import './Signup.css';

const ConfirmationContent = () => { 
    const email = localStorage.getItem('VocSentEmail');
    const location = useLocation();
    const dispatch = useDispatch();

    const emailResend = () => {
        if(email && email.length){
            const { from } = location.state || {from: { pathname: "/confirmation" }};
            const link = window.location.origin+"/profile";
            dispatch(userActions.verify(email, from, link));
        }
    };
    return <div className={"confirmationWrap"}>
        <div>
            <img src="images/arrow.png" className={'confirm-logo'} />
            <img src="images/line.png" className={'confirm-line'} />
            <span className={'confiration-heading'}> Activation Email Sent!</span>
        </div>
        <div className='confirmationContent'>
            We've sent an email to {email}.
            Click the confirmation link in that email to begin using Articience.
        </div>
        <div className={'confiration-sub-heading'}>if you did not receive the email,</div>
        <div className={'confiration-link'} onClick={emailResend}>Resend another email</div>
    </div>
};

const Confirmation = () => {
    const {verifying=false} = useSelector(state => state.users);
    const image = () => {
        return(
            <>  
                <div className="bannerWrapper">
                    <div className="bannerWrapper--left">
                        <h1>Start your free trial today</h1>
                        {/* <h2>No credit card required</h2> */}
                        <p>The trial includes:</p>
                        <ul>
                        <li>
                            <DoneRoundedIcon />
                            Customized data based on your information
                        </li>
                        <li>
                            <DoneRoundedIcon />
                            Pre-configured dashboards and reports
                        </li>
                        <li>
                            <DoneRoundedIcon />
                            Customized templates and question banks
                        </li>
                        <li>
                            <DoneRoundedIcon />
                            Easy help and reference
                        </li>
                        <li>
                            <DoneRoundedIcon />
                            AI Analysis
                        </li>
                        </ul>
                    </div>
                    
                    <div className="bannerWrapper--right">
                        <img src="images/mainBannerFull.png" />
                    </div>
                    
                </div>
                {/* <img src="images/Untitled-2.gif" /> */}
            </>
        );
    },
        LeftWrapper = () => <Wrapper component={image} className={'leftWrapper'} />,
        RightWrapper = () => <Wrapper component={ConfirmationContent} className={'rightWrapper'} />,
        Content = () => <div className={"signInSinUpWrap"}><LeftWrapper /><RightWrapper /></div>;

    return <CommonWrapper component={Content} isLoader={verifying}/>
};

export default Confirmation;
