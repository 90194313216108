import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {clone} from "../../../Common/Utils";

const Drop = (props) =>{
    const {layout,children, qus_id, question, desc="", qno=1, dropdownList="", settings={}, cardRef, quesType, onChange, qRefs, submit, btnText='Next', sliderView, onFocus, showSubmitBtn} = props;
    const { font, underline, bold, fontSize, italic, fontColor, require, sorting } = settings,
        style = { fontFamily: font, fontSize, fontWeight: bold ? 'bold' : '', fontStyle: italic ? 'italic' : '', textDecoration: underline ? 'underline' : '', color: fontColor };

    const dropAns = (window.answeredData && window.answeredData[question]) ? window.answeredData[question][0].text : '';
    const [val, setVal] = useState({[qus_id]:dropAns});
    const [err, setErr] = useState('');

    useEffect(()=> {
        const elements = document.getElementsByName("question_"+qno);
        let ele = elements.length ? elements[0] : elements;
        ele.style.setProperty('font-family', font||'Poppins', 'important');
    }, [font]);

    const defaultProps = {
        options: dropdownList.length ? sorting ? dropdownList.split('\n').sort() : dropdownList.split('\n') : [],
        className:"autoSelectWrap"
    };

    const onNext = () => {
        if(btnText === 'Next'){
            cardRef && cardRef.current.next();
            (qRefs?.current?.length >=qno) && setTimeout(() => { qRefs.current[qno]?.focus() }, 10);
        }else{
            submit && submit();
        }
    };

    const onSelect = (e, a) => {
        const v = e?.target?.innerHTML || '';
        const cloned = clone(val);
        cloned[qus_id] = (v !== "" && defaultProps.options.includes(v)) ? v : '';
        setVal(cloned);
        triggerFocus();
        saveChanges(cloned[qus_id]);
    };

    const saveChanges = (v) => {
        if(require && v === ''){
            setErr('This is required.');
            return;
        }
        let obj = {
            "qus_id": qus_id,
            "q_type":quesType,
            "qus_text": question,
            "answers": [{text: v}]
        };
        onChange && onChange({name: qno, value:obj});
        setErr('');
    };

    const triggerFocus = () => {
        onFocus && qno > 0 && onFocus(qno-1);
    };

    return(
        <div className="imageWrapInner">
            <div className="ques">
            <h3 name={"question_"+qno} style={style}>{question}
                 <span style={{color:"red", marginLeft:'5px',display: require?'unset':'none'}}>*</span>
            </h3>
                <p> {desc} </p>
            </div>
            {layout == 1 && children}
            <div className=" dropDownInner" ref={ (r)=> qRefs && (qRefs.current[qno-1] = r) } tabIndex={-1}>
                <div className="selectWrap">
                    <div className="selectInner">
                        <Autocomplete
                            {...defaultProps}
                            id="disable-close-on-select"
                            value={val[qus_id]||''}
                            renderInput={(params) => (
                                <TextField {...params} label="Type or select an option" variant="standard" />
                            )}
                            onChange={onSelect}
                        />
                    </div>
                </div>
            </div>
            { err !== "" ? <div> <p style={{color:'red'}}>{err}</p></div> : <div><p>&nbsp;</p></div> }
            { sliderView ? (btnText=== 'Submit' && !showSubmitBtn) ? null : <div className="btnGroup">
                <button className="btnSave" onClick={onNext}>{btnText}</button>
            </div> : null}

        </div>
    )
}

export default Drop;
