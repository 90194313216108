import { callQueueConstant } from "../constants/callQueueConstant";

export function callQueueLogic(state = {}, action) {
    switch (action.type) {
        case callQueueConstant.ADD_CALL_QUEUE:
            return {...state, addCallQueue: action.payload}

        case callQueueConstant.GET_CALL_QUEUE:
            return {...state, callQueue: action.payload}

        case callQueueConstant.UPDATE_CALL_QUEUE:
            return {...state, updateCallQueue: action.payload}
        default:
            return {...state}
    }
}
