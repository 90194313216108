import { SET_DIALOG } from "../actions/dialogActions";

const initialState = {
  dialog: '', 
};

const dialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DIALOG:
      return {
        ...state,
        dialog: action.payload,
      };
    default:
      return state;
  }
};

export default dialogReducer;