import { queryBuilderConstants } from "../constants";
import { queryBuilderService } from "../services";
import { swalAlert } from "../voc/Common/SwalAlert";

const request = (message) => ({ type: queryBuilderConstants.REQUEST, message });

const success = (message) => ({ type: queryBuilderConstants.SUCCESS, message });

const errors = (message) => ({ type: queryBuilderConstants.ERROR, message });

const clear = () => ({ type: queryBuilderConstants.CLEAR });

const getQueryBuilder = (surveyId) => {
  return (dispatch) => {
    dispatch(request());

    queryBuilderService.getQueryBuilderData(surveyId).then(
      (alerts) => {
        dispatch(success(alerts));
      },
      (error) => {
        dispatch(errors(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};
const deleteQuery = (detail_id, query_name) => {
  //GET_QUERIES_BY_ID_REQUEST:
  const request = (detail_id) => ({
    type: queryBuilderConstants.DELETE_QUERY_REQUEST,
    detail_id,
  });
  const success = (detail_id) => ({
    type: queryBuilderConstants.DELETE_QUERY_SUCCESS,
    detail_id,
  });
  const failure = (error) => ({
    type: queryBuilderConstants.DELETE_QUERY_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request(detail_id));

    return queryBuilderService.deleteQuery(detail_id, query_name).then(
      (detail_id) => {
        dispatch(success(detail_id));
        return detail_id;
        //window.location.reload()
        //dispatch(alertActions.success(`Detail ${detail_id} question descriptions received.`));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};
const getQueriesById = (detail_id) => {
  //GET_QUERIES_BY_ID_REQUEST:
  const request = (detail_id) => ({
    type: queryBuilderConstants.GET_QUERIES_BY_ID_REQUEST,
    detail_id,
  });
  const success = (detail_id) => ({
    type: queryBuilderConstants.GET_QUERIES_BY_ID_SUCCESS,
    detail_id,
  });
  const failure = (error) => ({
    type: queryBuilderConstants.GET_QUERIES_BY_ID_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request(detail_id));

    queryBuilderService.getQueriesById(detail_id).then(
      (detail_id) => {
        dispatch(success(detail_id));
        //dispatch(alertActions.success(`Detail ${detail_id} question descriptions received.`));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const savePivotState = (
  detail_id,
  query_name,
  query_description,
  questions,
  pivotState
) => {
  // console.log("PIVOT SAVE WAS WAS CALLED");

  const request = (pivState) => ({
    type: queryBuilderConstants.SAVE_PIVOTSTATE_REQUEST,
    pivState,
  });
  const success = (pivState) => ({
    type: queryBuilderConstants.SAVE_PIVOTSTATE_SUCCESS,
    pivState,
  });
  const failure = (error) => ({
    type: queryBuilderConstants.SAVE_PIVOTSTATE_FAILURE,
    error,
  });
  return (dispatch) => {
    //pivotState["unusedOrientationCutoff"] = Infinity;
    var config_copy = JSON.parse(JSON.stringify(pivotState));
    //delete some values which will not serialize to JSON
    delete config_copy["aggregators"];
    delete config_copy["renderers"];
    config_copy["unusedOrientationCutoff"] = Infinity;
    // console.log("CONFIG STATE?", config_copy);
    dispatch(request(config_copy));

    return queryBuilderService
      .savePivotState(
        detail_id,
        query_name,
        query_description,
        questions,
        config_copy
      )
      .then(
        (answer_data) => {
          dispatch(success(config_copy));
          return answer_data;
          //window.location.reload()
          //dispatch(alertActions.success(`Detail ${detail_id} question descriptions received.`));
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
  };
};
export const queryBuilderActions = {
  getQueryBuilder,
  savePivotState,
  deleteQuery,
  getQueriesById,
};
