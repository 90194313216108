import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, MenuItem, Select, FormControl, InputLabel, Button, Grid } from '@mui/material';
import api from "../../../services/api";
import { swalAlert } from '../../Common/SwalAlert';
import { AdminService } from '../../../services/AdminService';

const UpdateUser = async (id, data) => {
  if (!id) {
    console.error("User ID is missing. Cannot update user.");
    return null;
  }

  try {
    const result = await api.put(`/api/users/update_by_admin/${id}`, data);
    
    swalAlert({
      icon: "success",
      title: "User updated successfully!",
      showConfirmButton: true,
    });

    return result.data;  // Return after alert
  } catch (error) {
    console.error("Error updating user:", error);
    return null;
  }
};


const UserForm = ({ user, onCancel }) => {
  const [formData, setFormData] = useState({
    firstname: "",
    //email: "",
    role_name: "",
    status: "",
    extension_number: "",
    exension_password: "",
    // id: "",
    //territory: "North America",
  });

  // Load user data when the component mounts
  useEffect(() => {
    if (user) {
      setFormData({
        firstname: user.firstname || "",
        //email: user.email || "",
        role_name: user.role_name || "", // Adjust role logic if needed
        status: user.status || "Active",
        extension_number: user.extension_number || "",
        exension_password: user.exension_password || "",
        //territory: user.territory || "North America",
        // id: user.id || ""
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Updated User Data:", formData);
    const response = await UpdateUser(user.id, formData);
    try{
    if (response) {
      console.log("User updated successfully!");

      const payloadExt = {
        extensionId: parseInt(formData.extension_number, 10), // Get from formData
        name: formData.extension_number.toString(),
        extPassword: formData.exension_password, // Ensure correct field name
      };

      const result = await AdminService.updateExtension(payloadExt);
      if (result) {
        console.log("Extension updated successfully!");
      } else {
        console.error("Failed to update extension.");
      }
    }
  } catch (error) {
    console.error("Error updating user or extension:", error);
  }
};

  return (
    <Container sx={{ mt: 4, textAlign: "left" }}>
      <Typography variant="h4" gutterBottom>
        Edit User Details
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Firstname"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>User Role</InputLabel>
              <Select
                label="User Role"
                name="role"
                value={formData.role_name}
                onChange={handleChange}
              >
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="User">User</MenuItem>
                <MenuItem value="Manager">Manager</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                name="status"
                value={formData.status}
                onChange={handleChange}
              >
                <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
                <MenuItem value="Suspended">Suspended</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Extension</InputLabel>
              <Select
                label="Extension"
                name="extension_number"
                value={formData.extension_number}
                onChange={handleChange}
              >
                <MenuItem value="1003">1003</MenuItem>
                <MenuItem value="1004">1004</MenuItem>
                <MenuItem value="1005">1005</MenuItem>
                <MenuItem value="1006">1006</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Extension"
              name="extension_number"
              value={formData.extension_number}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Territory</InputLabel>
              <Select
                label="Territory"
                name="territory"
                value={formData.territory}
                onChange={handleChange}
              >
                <MenuItem value="North America">North America</MenuItem>
                <MenuItem value="Europe">Europe</MenuItem>
                <MenuItem value="Asia">Asia</MenuItem>
                <MenuItem value="South America">South America</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Password"
              name="exension_password"
              value={formData.exension_password}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" size="large" sx={{ mr: 2 }}>
              Update User
            </Button>
            <Button variant="outlined" color="secondary" size="large" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default UserForm;
