import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import Label from "../../common/controls/inputs/Label";
import Button from "../../common/controls/Button";
import { QuestionAnswerProfile } from "../Common/CommonWrapper";
import {
  MaterialInput,
  MaterialSelect,
} from "../../common/controls/inputs/Material";
import { userActions } from "../../actions";
import { workspaceService } from "../../services"
import CookieBanner from "../Common/CookieBanner";
import { cookieAccepted } from "../Common/Utils";
import "./Signup.css";

const steps = ["stepOne", "stepTwo", "stepThree", "stepFour", "stepFive"],
  Question = ({
    isError,
    handleStep,
    otherInfo: {
      id,
      step,
      setQuesAns,
      quesAns,
      label,
      type,
      labelClass,
      fieldClass,
      dropDown,
      subLabel,
    },
  }) => {
    const value = quesAns[id],
      user = localStorage.getItem("user"),
      { firstName = "" } = user && JSON.parse(user),
        [err, setErr] = useState(""),
        dispatch = useDispatch(),
       handleChange = (e) => {
        const { target: { name, value } = {} } = e,
          data = quesAns;
          if(name==="company_website"){
            const rgx =/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
            const isValid = rgx.test(value)
            if(!isValid){
              setErr(" Invalid website");
              return;
            }
          }
        data[name] = value;
        setQuesAns((inputs) => ({ ...inputs, [name]: value }));
      },

      onKeyPress = (e) => {
        if(e && e.key==='Enter'){
          console.log("qq",e);
          handleChange(e);
          handleStep("next")
          
        }
      };

    return (
      <>
        {step === "stepOne" && (
          <div>
            <span className="welcomeText">
              Nice to meet you {firstName}{" "}
              <img
                style={{ position: "relative", left: "5px" }}
                src={"images/hello_icon.png"}
              />
            </span>
          </div>
        )}
        <Label
          config={{ label, className: cn(labelClass, "commonQuestionsLabel") }}
        />
        {type === "Select" ? (
          <MaterialSelect
            config={{
              label: subLabel,
              name: id,
              value,
              menuItems: dropDown,
              fieldClass,
              onChange: handleChange,
              isError,
              error: "This field is required",
            }}
          />
        ) : (
          <MaterialInput
            config={{
              label: subLabel,
              name: id,
              value,
              fieldClass,
              onBlur: handleChange,
              onKeyPress: onKeyPress,
              isError: err.length || isError,
              error: err.length ? err : "This field is required",
            }}
          />
        )}
      </>
    );
  },
  Content = (props) => {
    const {
        otherInfo: { step, setStep, quesAns, id, submitHandler},
      } = props,
      [isError, setError] = useState(false),
      handleStep = (type, isSkip = false) => {
        const index = steps.indexOf(step);
        if (type === "back") {
          setError(false);
          index > 0 && setStep(steps[index - 1]);
        } else if (type === "next") {
          if (isSkip || quesAns[id]) {
            index < 4 && setStep(steps[index + 1]);
            index === 4 && submitHandler();
            isSkip && setError(false);
          } else {
            setError(true);
          }
        }
      };

    return (
      <>
        <Label
          config={{
            label: "Skip",
            className: "skipBtn",
            onClick: () => handleStep("next", true),
          }}
        />
        <Question {...props} handleStep={handleStep} isError={isError} />
        {step !== "stepOne" && (
          <div onClick={() => handleStep("back")} className="backIcon">
            <img src={"images/back.png"} />
          </div>
        )}
        {step === "stepFive" ? (
          <Button
            text={"Finish"}
            customCSS="finishBtn"
            btnTextClass={"finishText"}
            clickHandler={() => handleStep('next')}
          />
        ) : (
          <div onClick={() => handleStep("next")} className="nextIcon">
            <img src={"images/next_button.png"} />
          </div>
        )}
      </>
    );
  },
  config = {
    stepOne: {
      question: Content,
      image: "images/image_industry.png",
      imageClass: "industry",
      id: "industry",
      imageText:
        "135,000 customers in over 120 countries are growing with Articience.",
      imageTextClass: "industryText",
      label: "What industry are you in?",
      subLabel: "Select industries",
      type: "Select",
      labelClass: "industryLabel",
      fieldClass: "industryField",
      dropDown: [
        "Healthcare",
        "Technology",
        "Construction",
        "Retail",
        " Non-durable Manufacturing",
        "Services",
        "Finance",
      ],
    },

    stepTwo: {
      question: Content,
      image: "images/job_role.png",
      imageClass: "industry",
      label: "What is your job role?",
      subLabel: "Select job roles",
      id: "job_role",
      type: "Select",
      labelClass: "roleLabel",
      fieldClass: "roleField",
      dropDown: [
        "Product Design",
        "Management",
        "Business Development",
        "Business",
        "Analytics",
      ],
    },

    stepThree: {
      question: Content,
      image: "images/company_name.png",
      imageClass: "industry",
      id: "company_name",
      label: "What is your company's name?",
      subLabel: "Company name",
      type: "Text",
      fieldClass: "companyField",
      labelClass: "comapanyLabel",
    },

    stepFour: {
      question: Content,
      image: "images/peoples.png",
      imageClass: "industry",
      id: "company_size",
      label: "How many people work at your company?",
      subLabel: "Select the number",
      type: "Select",
      labelClass: "peopleLabel",
      fieldClass: "peopleField",
      dropDown: [
        "0 to 1",
        "2 to 5",
        "6 to 10",
        "11 to 25",
        "26 to 50",
        "51 to 200",
      ],
    },

    stepFive: {
      question: Content,
      image: "images/website.png",
      imageClass: "industry",
      label: "What is your company's website?",
      type: "Text",
      subLabel: "Website",
      id: "company_website",
      fieldClass: "websiteField",
      labelClass: "websiteLabel",
    },
  };

  const dummyAddress = {
    "country_code": "",
    "country_name": "",
    "city": "",
    "postal": "",
    "latitude": 0,
    "longitude": 0,
    "IPv4": "0.0.0.0",
    "state": "",
    "state_code": "",
    "flag": "",
    "emoji_flag": "",
    "emoji_unicode": ""
  };

const QuestionsAnswers = () => {
  const [step, setStep] = useState(steps[0]),
    [quesAns, setQuesAns] = useState({
      industry: "",
      job_role: "",
      company_name: "",
      company_size: "",
      company_website: "",
    }),
    { industry, job_role, company_name, company_size, company_website } =
      quesAns,
    dispatch = useDispatch(),
    { updating = false, error } = useSelector((state) => state.users),
    [userAddress, setUserAddress] = useState({});

    useEffect(() => {
      workspaceService.getUserAddress().then((res) => {
          if(res && res.country_name !== ""){
              const address = {"country_code": res.country_code,
                  "country_name": res.country_name,
                  "city": res.city,
                  "postal": res.postal,
                  "latitude": res.latitude,
                  "longitude": res.longitude,
                  "IPv4": res.ip,
                  "state": res.region,
                  "state_code": res.region_code,
                  "flag": res.flag,
                  "emoji_flag": res.emoji_flag,
                  "emoji_unicode": res.emoji_unicode};
              setUserAddress(address);
              dummyAddress.country_code = res.country_code;
              dummyAddress.country_name = res.country_name;
              dummyAddress.flag = res.flag;
          }
      });
    }, []);


    const submitHandler = () => {
      const signup_address = cookieAccepted() ? userAddress : dummyAddress;
      dispatch(
        userActions.updateUser(
          industry,
          job_role,
          company_name,
          company_size,
          company_website,
          signup_address
        )
      );
    };

  return (
    <>
      <CookieBanner />
      <QuestionAnswerProfile
        config={config}
        step={step}
        setStep={setStep}
        quesAns={quesAns}
        setQuesAns={setQuesAns}
        submitHandler={submitHandler}
        isLoader={updating}
      />
    </>
  );
};

export default QuestionsAnswers;
