import { callQueueConstant } from "../constants/callQueueConstant";
import { CallQueueService } from "../services/callQueueService";
import { swalAlert } from "../voc/Common/SwalAlert";

function addCallQueue(payload) {
    const success = (callQueue) => ({ type: callQueueConstant.ADD_SUCCESS_CALL_QUEUE, callQueue});
    const failure = (error) => ({ type: callQueueConstant.FAILURE_SUCCESS_CALL_QUEUE, error });
    return (dispatch) => {
        CallQueueService.createCallQueue(payload).then((data) => {
            dispatch(success(data));
            dispatch(getCallQueue());
        }).catch((error) => {
            dispatch(failure(error));
            swalAlert({
                icon: "error",
                title: error?.response?.detail || "Failed to Add Call Queue",
                showConfirmButton: true,
            });
        })
        dispatch({type: callQueueConstant.ADD_CALL_QUEUE, payload: payload});
    }

}


function getCallQueue(){
    return (dispatch) => {
        CallQueueService.getCallQueue().then((data) => {
            dispatch({type: callQueueConstant.GET_CALL_QUEUE, payload: data});
        }).catch((err) => {
            swalAlert({
                icon: "error",
                title: err?.response?.detail || "Failed to Fetch Call Queue",
                showConfirmButton: true,
            });
        });
    }
}

function updateCallQueue(callQueueId, payload){
    return (dispatch) => {
        CallQueueService.updateCallQeue(callQueueId, payload).then((data) => {
            dispatch({type: callQueueConstant.UPDATE_CALL_QUEUE, payload: data});
            dispatch(getCallQueue())
            swalAlert({
                icon: "success",
                title: "Updated Call Queue Successfully",
            });
        }).catch((err) => {
            swalAlert({
                icon: "error",
                title: err?.response?.detail || "Failed to Update Call Queue",
                showConfirmButton: true,
            });
        });
    }
}


function deleteCallQueue(callQueueId){
    return (dispatch) => {
        CallQueueService.deleteCallQueue(callQueueId).then((data) => {
            dispatch({type: callQueueConstant.REMOVE_CALL_QUEUE, payload: data});
            dispatch(getCallQueue())
            swalAlert({
                icon: "success",
                title: "Deleted Call Queue Successfully",
            });
        }).catch((err) => {
            swalAlert({
                icon: "error",
                title: err?.response?.detail || "Failed to Delete Call Queue",
                showConfirmButton: true,
            });
        });
    }
}


export const callQueueActions = {
    addCallQueue,
    getCallQueue,
    updateCallQueue,
    deleteCallQueue
}
