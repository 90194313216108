import { useState, useEffect } from 'react'; 
import { useGoogleLogin } from '@react-oauth/google';
import { PublicClientApplication } from '@azure/msal-browser';
import { workspaceService } from '../../../services';
import { getUser } from '../Utils';

const MSAL_CONFIG = {
  auth: {
    clientId: "05f8de0d-f2ba-4128-b516-b830c99d6098", // Your Microsoft client ID
    authority: "https://login.microsoftonline.com/common",
    redirectUri: "http://localhost:3000"
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  }
};

const useSocialAuth = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [authError, setAuthError] = useState(null);
  const [pca] = useState(new PublicClientApplication(MSAL_CONFIG));

  // Handle Microsoft Redirect Response
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.hash.substring(1));
    const authCode = urlParams.get("code");

    if (authCode) {
      handleAuthSuccess(authCode, 'microsoft');
    }
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: (response) => {
      handleAuthSuccess(response.code, 'google');
    },
    onError: (error) => {
      setAuthError(error);
      setIsAuthenticating(false);
    },
    flow: "auth-code",
    scope: "email profile https://www.googleapis.com/auth/gmail.send"
  });

  const microsoftLogin = async () => {
    setIsAuthenticating(true);
    try {
      await pca.loginRedirect({
        scopes: ["mail.send", "User.Read"],
      });
    } catch (error) {
      setAuthError(error);
      setIsAuthenticating(false);
      throw error;
    }
  };

  const handleAuthSuccess = async (authCodeOrToken, provider) => {
    try {
      const res = await workspaceService.oauth2TokenExchange({
        code: authCodeOrToken
      });
      
      const authData = {
        access_token: res.data.access_token,
        refresh_token: res.data.refresh_token,
        provider,
        expires_at: Date.now() + (res.data.expires_in * 1000)
      };
      
      localStorage.setItem('auth', JSON.stringify(authData));
      localStorage.setItem('social_type', provider); 
      setIsAuthenticating(false);
      return authData;
    } catch (error) {
      setAuthError(error);
      setIsAuthenticating(false);
      throw error;
    }
  };

  const initiateAuth = (provider) => {
    setAuthError(null);
    setIsAuthenticating(true);
    const user = getUser();

    if (user?.social_type === 'gmail') {
      googleLogin();
    } else if (user?.social_type === 'outlook') {
      microsoftLogin();
    }
  };

  return {
    initiateAuth,
    isAuthenticating,
    authError,
    clearError: () => setAuthError(null)
  };
};

export default useSocialAuth;
