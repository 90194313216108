import cn from "classnames";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import Label from "../../../../common/controls/inputs/Label";
import { useSelector, useDispatch } from "react-redux";
import { surveyActions } from "../../../../actions";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ANS_TYPE } from "../../../Common/Constants";

const Tags = (props) => {
  const { id } = useParams();
  const interval = useRef(null);
  const getFormattedLabel = (val) => {
    let label = "";
    ANS_TYPE.forEach((e) => {
      if (e.value === val) {
        label = e.label;
      }
    });
    return label;
  };
  const dispatch = useDispatch();
  const [question, setQuestion] = useState([]);
  const [questionId, setQuestionId] = useState(null);
  const questionData = useSelector(
    (state) => state.survey.questionAnalysisData
  );
  const aiRecommendationStatus = useSelector(
    (state) => state.survey.processingStatus
  );
  const aiRecommendationsStatus = useSelector(
    (state) => state.survey.aiRecommendationStatus
  );
  // aiRecommendationQuestionId;
  const aiRecommendationQuestionId = useSelector(
    (state) => state.survey.aiRecommendationQuestionId
  );

  const categoryInsights = useSelector(
    (state) => state.survey.categoryInsights
  );
  const categoryInsightsByQuestion = useSelector(
    (state) => state.survey.categoryInsightsByQuestion
  );
  const processingStatus = useSelector(
    (state) => state.survey.processingStatus
  );

  useEffect(() => {
    if (processingStatus && aiRecommendationQuestionId !== null) {
      function getRecommended() {
        // dispatch(surveyActions.getCategoryInsights(id));
        dispatch(
          surveyActions.getAiRecommendationsStatuses(
            aiRecommendationQuestionId,
            id
          )
        );
      }

      interval.current = setInterval(() => getRecommended(), 10000);
      // setIntervalId(interval);
      if (aiRecommendationsStatus?.data?.recommendation_status === "success") {
        dispatch(surveyActions.setAiRecommendationQuestionId(null));
        dispatch(surveyActions.setAiRecommendationProcessingStatus(false));
        clearInterval(interval.current);
        dispatch(surveyActions.getCategoryInsights(id));
      }
      if (aiRecommendationsStatus?.data?.recommendation_status === "failure") {
        dispatch(surveyActions.setAiRecommendationQuestionId(null));
        dispatch(surveyActions.setAiRecommendationProcessingStatus(false));

        clearInterval(interval.current);
        dispatch(surveyActions.getCategoryInsights(id));
      }
    }
    return () => {
      clearInterval(interval.current);
    };
  }, [
    processingStatus,
    aiRecommendationStatus,
    aiRecommendationQuestionId,
    aiRecommendationsStatus,
  ]);

  const [questionType, setQuestionType] = useState("All");
  useEffect(() => {
    if (questionType === "All") {
      dispatch(surveyActions.getCategoryInsights(id));
    } else {
      dispatch(surveyActions.getCategoryInsightsByQuestion(id, questionId));
    }
  }, [questionType, questionId]);
  // const getRecommendationStatus = (questionId) => {
  //   dispatch(surveyActions.getAiRecommendationsStatuses(questionId, id));
  // };
  useEffect(() => {
    let ques = [];
    ques.push({
      qus_id: "99",
      question: "All Questions",
      question_type: "All",
    });
    questionData?.long.forEach((e) => {
      ques.push(e);
    });
    // questionData.others.forEach((e) => {
    //   ques.push(e);
    // });
    setQuestion(ques);
    if (ques.length > 0) {
      setQuestionType(ques[0]["question_type"]);
    }
  }, [questionData]);

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 600,
    },
  });

  return (
    <div className="TagsPage">
      <div className="customStyle">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <div className="row">
              <div className="col-md-7 col-sm-12">
                <div className={cn("analyseCard")}>
                  <div className="item">
                    {/* <select
                      onChange={(e) => {
                        let index = question.findIndex(
                          (x) => x.qus_id === parseInt(e.target.value)
                        );
                        if (index !== -1) {
                          setQuestionId(parseInt(e.target.value));
                        }
                        if (index === -1) setQuestionType("All");
                        else setQuestionType(question[index].question_type);
                      }}
                      className="select-question"
                    >
                      {question && question.length > 0
                        ? question.map((value, index) => {
                            return (
                              <option value={value.qus_id}>
                                Q:{index + 1} {value.question}
                              </option>
                            );
                          })
                        : null}
                    </select> */}
                    {/*console.log("dfjdsjkdfjkfd", question)*/}
                    <Box
                      sx={{ maxWidth: 485, maxHeight: 55 }}
                      className="analysisCustomSelectBox"
                    >
                      <FormControl fullWidth>
                        <Select
                          value={questionType === "All" ? "99" : questionId}
                          onChange={(e) => {
                            let index = question.findIndex(
                              (x) => x.qus_id === parseInt(e.target.value)
                            );
                            if (index !== -1) {
                              setQuestionId(parseInt(e.target.value));
                            }
                            if (index === -1) setQuestionType("All");
                            else setQuestionType(question[index].question_type);
                          }}
                          label=""
                          className="analysisCustomSelect"
                          MenuProps={{
                            classes: { paper: "selectPaperRoot" },
                          }}
                        >
                          {question && question.length > 0
                            ? question.map((value, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={value.qus_id}
                                    className="analysisMenuItem"
                                  >
                                    {value.question}
                                  </MenuItem>
                                );
                              })
                            : null}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                </div>
              </div>
              {questionType !== "All" ? (
                <div className="col-md-5 col-sm-12">
                  <div className={cn("analyseCard", "analyseQuestionType")}>
                    <span className="text-capitalize">
                      {questionType ? getFormattedLabel(questionType) : ""}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {questionType !== "All" ? (
            <div className="col-md-4 col-sm-12">
              <div className={cn("analyseCard", "analyseExperience")}>
                <span>
                  {categoryInsightsByQuestion &&
                  categoryInsightsByQuestion.data &&
                  categoryInsightsByQuestion.data.new_answers_count === 0
                    ? "No New Answers"
                    : "New Answers"}
                </span>
                {/* <em className="bubble">5</em> */}
                <div className="bubble">
                  <h4 className="bubble-text">
                    {categoryInsightsByQuestion &&
                      categoryInsightsByQuestion.data &&
                      categoryInsightsByQuestion.data.new_answers_count}
                  </h4>
                  <span className="bubble-circle-1"></span>
                  <span className="bubble-circle-2"></span>
                  <span className="bubble-circle-3"></span>
                </div>
              </div>
            </div>
          ) : null}
        </div>{" "}
      </div>
      {questionType !== "All" ? (
        <>
          <div className="row">
            <div className="col-md-12 mt-2">
              <div className="card">
                <div className="row mt-4">
                  <div className="col-xs-12 col-sm-12 col-md-6">
                    <div className="insightSpan ml-4"> Building Insights</div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-6">
                        <div
                          onClick={() => {
                            if (
                              categoryInsightsByQuestion &&
                              categoryInsightsByQuestion.data &&
                              categoryInsightsByQuestion.data
                                .recommendation_status === "initiated"
                            ) {
                              return;
                            }
                            dispatch(
                              surveyActions.setCategorizationQuestionId(
                                questionId
                              )
                            );
                            dispatch(
                              surveyActions.setActiveMenu(
                                "GetNewRecommendations"
                              )
                            );
                          }}
                          style={{ cursor: "pointer" }}
                          className="companyName"
                        >
                          <Label
                            config={{
                              label: "Get New Reco.",
                              className: "",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6">
                        <div className="progress-wrapper">
                          {" "}
                          <div className="progress">
                            <div
                              className="progress-bar progress-bar-striped progress-bar-animated"
                              role="progressbar"
                              style={{
                                width: `${
                                  categoryInsightsByQuestion &&
                                  categoryInsightsByQuestion.data &&
                                  categoryInsightsByQuestion.data
                                    .get_new_recommendation_status_percent
                                }%`,
                              }}
                              aria-valuenow="100"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                            <div></div>
                          </div>
                          <div>
                            {" "}
                            <span className="insightSpan">
                              Get New Recommendations
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12 col-sm-12">
                    <div className="table-responsive">
                      <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col" style={{ width: "25%" }}>
                              AI Recommended Categories
                            </th>
                            <th scope="col" style={{ width: "10%" }}>
                              Categorized Data
                            </th>
                            <th scope="col" style={{ width: "25%" }}>
                              Summary
                            </th>
                            <th scope="col" style={{ width: "40%" }}>
                              Outlier
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td style={{ width: "25%" }}>
                              {categoryInsightsByQuestion &&
                                categoryInsightsByQuestion.data &&
                                categoryInsightsByQuestion.data
                                  .ai_recommended_categories_percent}
                              %
                            </td>
                            <td style={{ width: "10%" }}>
                              {" "}
                              {categoryInsightsByQuestion &&
                                categoryInsightsByQuestion.data &&
                                categoryInsightsByQuestion.data
                                  .categorized_data_percent}
                              %
                            </td>
                            <td style={{ width: "25%" }}>
                                <div className="tableCellText">
                                  {" "}
                                  {categoryInsightsByQuestion &&
                                    categoryInsightsByQuestion.data &&
                                    categoryInsightsByQuestion.data.summary}
                                </div>
                            </td>
                            <td style={{ width: "40%" }}>
                                <div className="tableCellText">
                                  {" "}
                                  {categoryInsightsByQuestion &&
                                    categoryInsightsByQuestion.data &&
                                    categoryInsightsByQuestion.data.outlier}
                                </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-12 mt-2">
                <div className="card opinionCard">
                    <div className="insightSpan">Common Opinion</div>
                    <div className="insightText">Almond Cookie Black Tea blend combines traditional black tea with the decadence of almond for an indulgent cup.Cup ital Tea is a Black woman owned tea brand offering specially handcrafted loose leaf tea and herbal tea blend.Darjeeling First Flush comes in a beautifully illustrated tin with 20 pyramids of tea or an art deco inspired tin with 10 pyramids of tea.Great plain and simple or with a touch of oat milk Mel s favorite.It has a bronze cup even leaves and a delicate fragrance.Rocky Mountain SereniTEA is a locally owned tea shop in Estes Park CO that carries over 100 luxury loose teas.Savor this pleasant rounded black tea with your breakfast and enjoy a balanced lift of caffeine throughout your morning.Th saurus Tea is blended with dainty claws by a geeky dinosaur and then steeped in the fandom While they have all the usual classic blends and more that one might expect of any tea shop they also host an ever growing list of original teas based on popular sci fi shows movies books and video games On top of all this they take special care with their impact on the environment The majority of their teas come from fair trade suppliers who also prioritize that their products are ethical ecological and economical.Their teas are grown at high altitudes and their blends are inspired by Rocky Mountain National Park.They are committed to spreading health and wellness through delicious tea blends.They blend and pack their teas in small batches for unique flavor and freshness.This black tea is picked out every year by tea tasters around the globe.Through their love for tea Cup ital Tea helps others to discover and share fun filled experiences over a brewing cup of great tasting teas.Try adding your favorite milk for a delicious cookie tea latte.Women supporting women and enjoying tea together is a cuppa we can get behind</div>

                    <div className="insightSpan">Outlier Opinion</div>
                    <div className="insightText">This sweet nostalgic treat will remind you of neighborhood bakeries and after school snacks</div>
                </div>
            </div>
        </div> */}
        </>
      ) : (
        <div className="row">
          <div className="col-md-12 mt-2">
            <div className="card">
              <div className="row mt-4">
                <div className="col-md-5 col-xs-6 col-sm-6 mt-2">
                  <span className="insightSpan ml-4"> Building Insights</span>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 col-sm-12">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" style={{ width: "40%" }}>
                            Question
                          </th>
                          <th scope="col">Processing Status</th>

                          <th scope="col">AI Recommended Categories</th>
                          <th scope="col">Categorized Data</th>
                          <th scope="col">New Answers</th>
                        </tr>
                      </thead>
                      <tbody>
                        {categoryInsights && categoryInsights.data.length > 0
                          ? categoryInsights.data.map((value, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    style={{ textAlign: "left", width: "40%" }}
                                  >
                                    <Tooltip
                                      title={value.short_answer_question}
                                      placement="bottom-start"
                                    >
                                      <a
                                        style={{
                                          pointerEvents:
                                            value.recommendation_status ===
                                            "initiated"
                                              ? "none"
                                              : "",
                                        }}
                                        className="tableCellLink"
                                        onClick={() => {
                                          if (
                                            value.recommendation_status ===
                                            "initiated"
                                          ) {
                                            return;
                                          }
                                          dispatch(
                                            surveyActions.setCategorizationQuestionId(
                                              value.qus_id
                                            )
                                          );
                                          dispatch(
                                            surveyActions.setActiveMenu(
                                              "GetNewRecommendations"
                                            )
                                          );
                                        }}
                                        href="#"
                                      >
                                        {value.short_answer_question}
                                      </a>
                                    </Tooltip>
                                  </td>
                                  <td>
                                    {" "}
                                    {/* <div className="row">
                                      <div className="col-md-12 col-xs-12 col-sm-12">
                                        {" "}
                                        <div className="progress mt-1">
                                          <div
                                            className="progress-bar progress-bar-striped"
                                            role="progressbar"
                                            style={{
                                              width: `${value.processing_status_percent}%`,
                                            }}
                                            aria-valuenow="100"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                          ></div>
                                          <div></div>
                                        </div>
                                      </div>
                                    </div> */}
                                    {value.recommendation_status}
                                  </td>
                                  <td>
                                    {value.ai_recommended_category_percent}%
                                  </td>
                                  <td>{value.categorized_data_percent}%</td>
                                  <td>{value.new_answers}</td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tags;
