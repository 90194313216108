import React, {useState} from 'react';
import "rsuite/dist/rsuite.css";

import { Rate } from 'rsuite';
import FrownIcon from '@rsuite/icons/legacy/FrownO';
import MehIcon from '@rsuite/icons/legacy/MehO';
import SmileIcon from '@rsuite/icons/legacy/SmileO';
import HeartIcon from '@rsuite/icons/legacy/Heart';
import CardBottomImage from '../../../assets/images/card-bg-bottom.svg';
import decorator from '../../../assets/images/decorator.svg';
import decorator1 from '../../../assets/images/decorator1.svg';
import decorator2 from '../../../assets/images/decorator2.svg';
import ThumbsUp from '@rsuite/icons/legacy/ThumbsUp';
import PictureGrid from '../../Survey/Components/DnD';
import { clone } from '../../Common/Utils';
import CancelIcon from "@mui/icons-material/Cancel";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { uploadFileToS3DECORATOR,uploadFileToS3 } from '../../Common/Utils';


const TypeDecoratorChoice = (props) =>{
    const {layout, children, qus_id, question, desc="", qno=1, settings={}, ans_options=[], cardRef, quesType, onChange, qRefs, submit, btnText='Next', sliderView, onFocus, showSubmitBtn,if_pictures_type = [] ,decoratorTheme = [],} = props;
    const { font, underline, bold, fontSize, italic, fontColor, require,horizontal } = settings,
        style = { fontFamily: font, fontSize, fontWeight: bold ? 'bold' : '', fontStyle: italic ? 'italic' : '', textDecoration: underline ? 'underline' : '', color: fontColor };
    const [err, setErr] = useState('');
    const [alertMsg, setAlertMsg] = useState("");
    const [imgData, setImgData] = useState(if_pictures_type);
    const [newdecoratorTheme, setNewDecoratorTheme] = useState([decoratorTheme])
    // useEffect(()=> {
    //     const elements = document.getElementsByName("question_"+qno);
    //     let ele = elements.length ? elements[0] : elements;
    //     ele.style.setProperty('font-family', font||'Poppins', 'important');
    // }, [font]);

    // const onNext = () => {
    //     if(btnText === 'Next'){
    //       cardRef && cardRef.current.next();
    //       (qRefs?.current?.length >=qno) && setTimeout(() => { qRefs.current[qno]?.focus() }, 10);
    //     }else{
    //         submit && submit();
    //     }
    // };

    // const triggerFocus = () => {
    //     onFocus && qno > 0 && onFocus(qno-1);
    // };
 
    console.log("PROPSDECORATPR",props) 

    const getImgData = (file, index) => {
        uploadFileToS3(file, (result) => {
          const clonedImgData = clone(imgData) || [];
          if (index > -1) {
            clonedImgData[index] = { base64: result, id: index + 1, label: "" };
          } else {
            clonedImgData.push({
              base64: result,
              id: clonedImgData.length + 1,
              label: "",
            });
          }
          setImgData(clonedImgData);
          onChange({ name: "if_pictures_type", value: clonedImgData });
        });
      };

    const updatePictureDetail = (e, index) => {
        if (e && e.target) {
          const clonedImgData = clone(imgData);
          clonedImgData[index][e.target.name] = e.target.value;
          setImgData(clonedImgData);
          onChange({ name: "if_pictures_type", value: clonedImgData });
        }
      };

    const GridItem = ({ item, index }) => {
        const [details, setDetails] = useState(item),
          editLabel = (e) => {
            if (e && e.target) {
              const cloned = clone(details);
              cloned[e.target.name] = e.target.value;
              setDetails(cloned);
            }
          };
    
        const blur = (e) => {
          updatePictureDetail(e, index);
        };
    
      
        const onCancel = () => {
          setImgData((imgData) => {
            imgData?.length && imgData.splice(index, 1);
            onChange({ name: "if_pictures_type", value: imgData });
            return imgData;
          });
        };
    
        return (
          <div
            key={"img_" + index}
            className={`imageUploadCard uploaded`}
          >
            <div className="cancel" onClick={onCancel}>
              <CancelIcon />
            </div>
            <div className="inner">
              <img src={item.base64} alt="arrow icon" />
              <AddPhotoAlternateIcon />
              <input
                type="file"
                id="img"
                name="img"
                accept="image/*"
                onChange={(e) => onSelect(e, index)}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>{String.fromCharCode(65 + index)}</div>
              {settings.show_label && (
                <input
                  type="text"
                  name="label"
                  placeholder={"Type label"}
                  value={details.label || ""}
                  onChange={editLabel}
                  onBlur={blur}
                />
              )}
            </div>
          </div>
        );
      };
  
    const onSelect = (e, index = -1) => {
        const ImageSize = 200000; //200kb
        if (e?.target?.files?.length) {
          const file = e.target.files[0];
          console.log(file);
          if (file?.size <= ImageSize) {
            getImgData(file, index);
          } else {
            setAlertMsg("Image size should be less than or equal to 200KB");
          }
        }
      };

      const StaticItem = (props) => {
        if(if_pictures_type.length){
          return(
            <div>
              
            </div>
          )
        }
        else{
          return (
            <div className="imageUploadCard" style={{ height: 110, width: 100 }}>
              <div className="inner">
                <AddPhotoAlternateIcon />
                <input
                  type="file"
                  id="img"
                  name="img"
                  accept="image/*"
                  onChange={onSelect}
                />
              </div>
            </div>
          );
        }
      };

    return <div className='decoratorImage'>
           <div className='imageBottom'>
            {props?.value?.decoratorTheme == "decorator1" ? 
            <img src={decorator}></img> 
            : props?.value?.decoratorTheme == "decorator2" ? <img src={decorator1}></img> : props?.value?.decoratorTheme == "decorator3" ? <img src={decorator2}></img> : 
            <img src={props.value.decoratorTheme}></img>
          }
                {/* <img src={props.value ? props.value.decoratorTheme : {CardBottomImage}}></img> */}
            </div>

        </div>
};

export default TypeDecoratorChoice;
