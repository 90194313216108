import { useEffect, useState } from "react";
import cn from "classnames";
import { audienceActions } from "../../../../actions";
import { useDispatch } from "react-redux";
import { BarChart, CustomChart } from "../Chart";
import Select from "react-select";
import autocolors from "chartjs-plugin-autocolors";

import { useSelector } from "react-redux";
import { surveyActions } from "../../../../actions";
import Backdrop from "../../../Common/Backdrop";

import { ANS_TYPE } from "../../../Common/Constants";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MuiSelect from "@mui/material/Select";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  autocolors,

  Legend
);

function formatOptionLabel({ label, value }) {
  return (
    <div>
      <span>{label}</span>
    </div>
  );
}

const AudienceAnalysis = ({ isFormType }) => {
  const [showloader, setshowloader] = useState(false);
  const dispatch = useDispatch();

  const getFormattedLabel = (val) => {
    let label = "";
    ANS_TYPE.forEach((e) => {
      if (e.value === val) {
        label = e.label;
      }
    });
    return label;
  };

  const [label, setLabel] = useState([]);

  const surveyData = useSelector((state) => state.survey.lexicalItems);

  const lexicalQuestionId = useSelector(
    (state) => state.survey.lexicalQuestionId
  );

  const [data, setData] = useState([]);
  const surveyStatus = useSelector(
    (state) => state.audience.lexicalSurveyStatus
  );
  const questionData = useSelector(
    (state) => state.survey.lexicalQuestionAnalysisData
  );
  const audienceType = useSelector(
    (state) => state.audience.lexicalAudienceType
  );
  const [emailSentLabel, setEmailSentLabel] = useState([]);
  const [emailSentValue, setEmailSentValue] = useState([]);

  const [country, setcountry] = useState([]);
  const audienceData = useSelector(
    (state) => state.audience.lexicalAudienceAnalysisData
  );
  useEffect(() => {
    let label = [];
    let value2 = [];
    audienceData &&
      audienceData["email_sent_response_graph"] &&
      Object.entries(audienceData["email_sent_response_graph"]).forEach(
        ([key, value]) => {
          label.push(key);
          value2.push(value);
        }
      );
    setEmailSentLabel(label);
    setEmailSentValue(value2);
  }, [surveyStatus, audienceData]);
  const [selectedOption, setSelectedOption] = useState({
    label: "Email Sent",
    value: "email_sent",
  });
  const [selectedOption1, setSelectedOption1] = useState({});
  const [dropdownValues, setDropdownValues] = useState([]);

  const [selectedValue, setSelectedvalue] = useState(null);

  useEffect(() => {
    setshowloader(true);
    let countryData = [];
    let label = [];
    let newData = [];

    audienceData &&
      audienceData.location_response_graph &&
      audienceData.location_response_graph.length > 0 &&
      audienceData.location_response_graph.forEach((e, index) => {
        if (e.country !== null) {
          countryData.push({
            label: e.country + " " + e.total_count,
            country: e.country,
          });
          if (index === 0) {
            setSelectedvalue({
              label: e.country + " " + e.total_count,
              country: e.country,
            });
          }

          if (index === 0) {
            e.state.forEach((e) => {
              if (e.state !== null) {
                label.push(e.state);
                newData.push(e.total_count);
              }
            });
          }
        }
      });
    setcountry(countryData);
    setLabel(label);
    if (label && label.length > 0)
      dispatch(surveyActions.setLexicalAudienceStateFilter(label[0]));
    else dispatch(surveyActions.setLexicalAudienceStateFilter("all"));

    setData(newData);
    setshowloader(false);
  }, [audienceData]);
  useEffect(() => {
    setDropdownValues([]);

    if (audienceType === "social_channel") {
      var option =
        audienceData &&
        audienceData.dropdown_values &&
        audienceData.dropdown_values.length > 0 &&
        audienceData.dropdown_values.map((x) => {
          return {
            label: x,
            value: x,
          };
        });

      setDropdownValues(option?.length > 0 ? option : []);
    } else {
      setSelectedOption1({});
    }
  }, [audienceType, audienceData]);
  const getAudienceType = () => {
    return {
      label:
        audienceType === "mail_user"
          ? "Mail User"
          : audienceType === "anon"
          ? "Default"
          : "Social Channel",
      type: audienceType,
      value: "/images/mailuser.png",
    };
  };
  const getSurveyStatus = () => {
    return {
      label: surveyStatus === "email_sent" ? "Email Sent" : "Email Received",
      type: surveyStatus,
      value: "/images/mailuser.png",
    };
  };

  return (
    <div className="audienceAnalysisPage">
      <div className="customStyle">
        <Backdrop show={showloader} />
        {questionData && questionData.length > 0 ? (
          <div className="row ">
            <div className="col-sm-12 col-md-6">
              <div className="row">
                <div className="col-md-8 col-sm-12">
                  <div className={cn("", "analyseQuestionSelect")}>
                    <Box
                      sx={{ maxWidth: 485, maxHeight: 55 }}
                      className="analysisCustomSelectBox"
                    >
                      <FormControl fullWidth>
                        <MuiSelect
                          value={lexicalQuestionId || ""}
                          onChange={(e) => {
                            setshowloader(true);

                            dispatch(
                              surveyActions.setLexicalQuestionId(e.target.value)
                            );
                          }}
                          name="question"
                          label=""
                          className="analysisCustomSelect"
                          MenuProps={{
                            classes: { paper: "selectPaperRoot" },
                          }}
                        >
                          {questionData &&
                            questionData.length > 0 &&
                            questionData.map((ques, index) => {
                              return (
                                <MenuItem
                                  value={ques["qus_id"]}
                                  key={index}
                                  className="analysisMenuItem"
                                >
                                  Q:{index + 1} {ques.question}
                                </MenuItem>
                              );
                            })}
                        </MuiSelect>
                      </FormControl>
                    </Box>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  {surveyData?.current_question_type ? (
                    <div className={cn("analyseCard", "analyseQuestionType")}>
                      <span className="text-capitalize">
                        {surveyData &&
                          getFormattedLabel(surveyData?.current_question_type)}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6">
              <div className={cn("analyseMailDropdown", "mt-2")}>
                <Select
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  className="mailDropdownButton"
                  onChange={(e) => {
                    if (audienceType === e.type) {
                    } else setshowloader(true);
                    if (e.type === "mail_user") {
                      dispatch(
                        audienceActions.setLexicalSurveyStatus("email_sent")
                      );
                    } else {
                      dispatch(audienceActions.setLexicalSurveyStatus(""));
                    }
                    dispatch(audienceActions.setLexicalAudienceType(e.type));
                  }}
                  getOptionValue={(option) => option.label}
                  formatOptionLabel={formatOptionLabel}
                  defaultValue={getAudienceType()}
                  options={[
                    {
                      label: "Mail User",
                      type: "mail_user",

                      value: "/images/mailuser.png",
                    },
                    {
                      label: "Social Channel",
                      type: "social_channel",

                      value: "/images/Facebook.png",
                    },
                    {
                      label: "Default",
                      type: "anon",

                      value: "/images/Facebook.png",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div className="row">
        <div className="col-md-7 col-sm-12 mt-4">
          <CustomChart audienceData={audienceData} />
        </div>
        <div className="col-md-5 col-sm-12 mt-4">
          <div className={cn("analyseCard", "barChart")}>
            <div className="analyse-filter-box">
              <div className="title">No. of users</div>
              <div className="select-items select-items-flex">
                {country && country.length > 0 ? (
                  <div className="item">
                    <Select
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={(e) => {
                        setSelectedvalue(e);
                        setshowloader(true);
                        setTimeout(() => {
                          setshowloader(false);
                        }, [2000]);
                        let label = [];
                        let data = [];
                        const index = audienceData.data.findIndex(
                          (x) => x.country == e.country
                        );

                        audienceData.data[index].state.forEach((e) => {
                          if (e.state !== null) {
                            label.push(e.state);
                            data.push(e.total_count);
                          }
                        });
                        if (label.length > 0) {
                          dispatch(
                            surveyActions.setLexicalAudienceStateFilter(
                              label[0]
                            )
                          );
                        } else {
                          dispatch(
                            surveyActions.setLexicalAudienceStateFilter("all")
                          );
                        }

                        setLabel(label);
                        setData(data);
                      }}
                      getOptionValue={(option) => option.country}
                      formatOptionLabel={formatOptionLabel}
                      value={selectedValue}
                      options={country}
                    />
                  </div>
                ) : (
                  ""
                )}

                {audienceType === "social_channel" ? (
                  <div className="item">
                    <Select
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      placeholder="Select"
                      onChange={(e) => {
                        if (surveyStatus === e.value) {
                        } else {
                          setshowloader(true);
                          setTimeout(() => {
                            setshowloader(false);
                          }, [2000]);
                        }

                        dispatch(
                          audienceActions.setLexicalSurveyStatus(e.value)
                        );
                        setSelectedOption1(e);
                      }}
                      options={dropdownValues}
                    />
                  </div>
                ) : null}
                {audienceType === "mail_user" ? (
                  <div className="item">
                    <Select
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      defaultValue={getSurveyStatus()}
                      onChange={(e) => {
                        if (surveyStatus === e.value) {
                        } else {
                          setshowloader(true);
                          setTimeout(() => {
                            setshowloader(false);
                          }, [2000]);
                        }

                        dispatch(
                          audienceActions.setLexicalSurveyStatus(e.value)
                        );
                        setSelectedOption(e);
                      }}
                      options={[
                        {
                          label: "Email Sent",
                          value: "email_sent",
                        },
                        {
                          label: "Email Recieved",
                          value: "response_received",
                        },
                      ]}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            {data &&
            data.length === 0 &&
            emailSentValue &&
            emailSentValue.length === 0 ? (
              <div
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  padding: 20,
                }}
              >
                <p>Data Not Found</p>
              </div>
            ) : null}

            <div className="anlyseBarChart">
              {data && data.length > 0 ? (
                <BarChart
                  config={{
                    labels: label,

                    onHoverLabel:
                      surveyStatus === "email_sent"
                        ? "Number of Email Sent"
                        : surveyStatus === "response_received"
                        ? "Number of Email Received"
                        : "Number of Responses",

                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                    dataSet: data,
                  }}
                />
              ) : null}

              {surveyStatus === "email_sent" &&
              emailSentValue &&
              emailSentValue.length > 0 ? (
                <BarChart
                  config={{
                    labels: emailSentLabel,

                    onHoverLabel:
                      surveyStatus === "email_sent"
                        ? "Number of Email Sent"
                        : surveyStatus === "response_received"
                        ? "Number of Email Received"
                        : "Number of Responses",

                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                    dataSet: emailSentValue,
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AudienceAnalysis;
