import React, { useState, useEffect } from "react";
import { Button,Typography } from "@mui/material";
import DataTable from "../../../components/Tables/DataTable";
import CreateRoleForm from "./CreateRoleForm"; // Import the new form component
import { AdminService } from "../../../services/AdminService";

const RoleTable = () => {
  const [openForm, setOpenForm] = useState(false);
  const [openCreate, setOpenCreate] = useState(false); // State to control form visibility
  const [selectedRole, setSelectedRole] = useState(null); 
  const [roles, setRoles] = useState([]); 

  const columns = ["name", "description", "Actions"];

  const rows = [
    {
      role_id: "1",
      Role_name: "Administrator",
      Description:
        "Oversees and manages the entire system with full access to settings, users, and data",
    },
    {
      role_id: "2",
      Role_name: "Manager",
      Description:
        "Handles specific operations or teams with delegated permissions",
    },
    {
      role_id: "3",
      Role_name: "User",
      Description:
        "Engages with the platform to perform assigned tasks ",
    },
  ];
 
  useEffect(() => {
    const fetchRoles = async () => {
      const data = await AdminService.getRoles();
      setRoles(data?.data);
    };
    fetchRoles();
  }, []);

  const enhancedRows = roles.map((row) => ({
    ...row,
    Actions: (
      <>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            setSelectedRole(row); // Set the selected role for editing
            setOpenCreate(true); // Open the form
            setOpenForm(true);
          }}          
          style={{ marginRight: "10px" }}
        >
          Edit
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => alert(`Delete ${row.Role_name}`)}
        >
          Delete
        </Button>
      </>
    ),
  }));


  return (
    <div>
     {/* <Typography variant="h5" gutterBottom>
        Roles
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenForm(true)} // Open the form when clicked
        sx={{marginBottom:"10px"}}
      >
        Add Role
      </Button> */}

      {/* Render the CreateRoleForm when the state is true */}
     
        <CreateRoleForm
          open={openForm}
          setOpen={setOpenForm}
          onClose={() => {
            setOpenCreate(false); // Close the form
            setSelectedRole(null); // Reset the selected role
          }}
          roleData={selectedRole} // Pass the selected role to the form
        />
      

      <DataTable columns={columns} rows={enhancedRows} tableType="Role" />
    </div>
  );
};

export default RoleTable;
