import React, { useState, useEffect } from 'react';
import AuthPrompt from './AuthPrompt';
import useSocialAuth from './useSocialAuth';


const Authenticate = ({ onAuthSuccess }) => {
  const [showAuthPrompt, setShowAuthPrompt] = useState(false);
  const { initiateAuth, isAuthenticating } = useSocialAuth();

  // Check authentication status on mount
  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('auth'));
    const socialType = localStorage.getItem('social_type');
    
    if (authData && authData.provider !== socialType) {
      // Clear auth if provider doesn't match
      localStorage.removeItem('auth');
    }
  }, []);

  const handleAuthAction = () => {
    const authData = JSON.parse(localStorage.getItem('auth'));
    const userData = JSON.parse(localStorage.getItem('user'));
    const socialType = localStorage.getItem('social_type') || 'google';
    
    if (authData?.access_token) {
      onAuthSuccess();  // Call function to refresh calendar
    } else {
      setShowAuthPrompt(true);
    }
  };

  const handleAuthSuccess = () => {
    setShowAuthPrompt(false);
    onAuthSuccess();  // Call parent function to refresh appointments
    alert("Authentication successful!");  // Show success message
  };

  return (
    <div   
    style={{
      display: 'flex',
      alignItems: 'center',
      gap: '12px' // Space between button and text
    }}>
<button 
  onClick={handleAuthAction}
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    padding: '12px 24px',
    borderRadius: '8px',
    background: 'linear-gradient(135deg, #4f6af5 0%, #3a56e8 100%)',
    color: 'white',
    border: 'none',
    fontWeight: '600',
    fontSize: '16px',
    cursor: 'pointer',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    ':hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
      background: 'linear-gradient(135deg, #3a56e8 0%, #2a46d6 100%)'
    },
    ':active': {
      transform: 'translateY(0)',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
    }
  }}
>
  <svg 
    width="20" 
    height="20" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: '8px' }}
  >
    <path 
      d="M8 2V5M16 2V5M3.5 9.09H20.5M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" 
      stroke="white" 
      strokeWidth="1.5" 
      strokeMiterlimit="10" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <path 
      d="M15.6947 13.7H15.7037M15.6947 16.7H15.7037M11.9955 13.7H12.0045M11.9955 16.7H12.0045M8.29431 13.7H8.30329M8.29431 16.7H8.30329" 
      stroke="white" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
  </svg>
  Sync Up
</button>

<span 
    style={{
      fontSize: '14px',
      fontWeight: '500',
      color: '#4f6af5',
      background: '#f3f5ff',
      padding: '8px 12px',
      borderRadius: '6px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
    }}
  >
    Get synced to manage your appointments
  </span>

      
      <AuthPrompt
        open={showAuthPrompt}
        onClose={() => setShowAuthPrompt(false)}
        onAuthenticate={() => initiateAuth().then(handleAuthSuccess)}        
        isLoading={isAuthenticating}
      />
    </div>
  );
};

export default Authenticate;