import { useEffect, useRef, useCallback} from "react";
import { useDispatch, useSelector } from "react-redux";
import JsSIP from "jssip";
import { customCallActions } from "../actions/customCallActions";
import { CrmService } from "../services";
import { getUser } from "../voc/Common/Utils";
import { jwtDecode } from "jwt-decode";
import { callRouteAction } from "../actions/callRouteAction";
import { removeActiveCalls } from "../actions/callRouteAction";
import Swal from "sweetalert2";
import { AdminService } from "../services/AdminService";
const {
    registerSIP,
    setIncommingCall,
    declineCallRequest,
    getAudioRef,
    startCaptureVoice,
    stopCaptureVoice,
    updateCallLogs,
    setTranscription,
    getStartTime,
    captureAgentVoice,
    stopAgentVoice,
} = customCallActions;

const SIP_WS_URL = "wss://pbx.articence.com:8089/ws";

const REGISTRATION_INTERVAL = 120;

const SipConnector = () => {
    const dispatch = useDispatch();
    const uaRef = useRef(null);
    const sessionRef = useRef(null);
    const remoteAudioRef = useRef(null);
    const isConnectedRef = useRef(false);
    const callLogIdRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const roleRef = useRef(null);
    const extensionRef = useRef(null);
    const isAiAgentActiveRef = useRef(null);
    const isHumanAgentActiveRef = useRef(null);
    const {aiAgent,isAiResponse} = useSelector((state) => ({
        aiAgent: state?.callRoute?.aiAgent,
        isAiResponse: state?.callRoute?.isAiResponse
    }))


    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        const token = user?.access_token;

        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                roleRef.current = decodedToken.role;
                extensionRef.current = user.extension_number;
                console.log("User Role:", roleRef.current);
            } catch (error) {
                console.error("Invalid token:", error);
            }
        }

    }, []);


    const getExtensionData = () => {
        return new Promise((resolve, reject) => {
            const user = getUser();

            if (user && user.extension_number && user.extension_password) {
                resolve({
                    extension_number: user.extension_number,
                    extension_password: user.extension_password,
                });
            } else {
                reject(new Error("User extension data is not available"));
            }
        });
    };

    useEffect(() => {
        const initialize = async () => {
            try {
                const data = await getExtensionData();

                // Now you can safely use extensionData
                const SIP_URI = `sip:${data.extension_number}@pbx.articence.com`;
                const SIP_PASSWORD = data.extension_password;

                // Proceed with further logic
                //setupSocketConnection()
                setupSIPConnection(SIP_URI, SIP_PASSWORD);
            } catch (error) {
                console.error("Error fetching extension data:", error);
            }
        };

        initialize();
    }, []);

    const { callLogId } = useSelector((store) => ({
        callLogId: store.customCall.callLogId,
    }));

    useEffect(() => {
        if (callLogId) {
            callLogIdRef.current = callLogId;
        }
    }, [callLogId]);

    const setupSIPConnection = useCallback(
        (SIP_URI, SIP_PASSWORD) => {
            try {
                const sipSocket = new JsSIP.WebSocketInterface(SIP_WS_URL);
                const configuration = {
                    sockets: [sipSocket],
                    uri: SIP_URI,
                    password: SIP_PASSWORD,
                    register: true,
                    register_expires: REGISTRATION_INTERVAL,
                    connection_recovery_min_interval: 60,
                    connection_recovery_max_interval: 60,
                    use_preloaded_route: true,
                    hack_ip_in_contact: true,
                };

                const ua = new JsSIP.UA(configuration);
                uaRef.current = ua;

                ua.on("connected", () => {
                    console.log("WebSocket connected");
                    isConnectedRef.current = true;
                    //startHeartbeat();
                });

                ua.on("disconnected", () => {
                    console.log("WebSocket disconnected");
                    isConnectedRef.current = false;
                    //stopHeartbeat();
                });

                ua.on("registered", () => {
                    console.log("Registered with SIP server");
                    dispatch(registerSIP(ua, "Registered", "registered"));
                    // dispatch(getSession(sessionRef));
                });

                ua.on("unregistered", () => {
                    console.log("Unregistered from SIP server");
                    dispatch(registerSIP(null, "Unregistered", "unregistered"));
                });

                ua.on("registrationFailed", (event) => {
                    console.error("Registration failed:", event);
                    dispatch(registerSIP(null, event.cause, "registration_failed"));
                });

                ua.on("newRTCSession", handleNewRTCSession);

                ua.start();
            } catch (e) {
                console.log("errr", e);
            }
        },
        [dispatch],
    );

    let sequence_number = 1;

    const isAiCallActive = function (aiAgent, agentType) {
        if (!aiAgent) return false;

        aiAgent = aiAgent.filter((e) => e["agent_type"] === agentType)[0];

        if (aiAgent?.status !== "active"){
            return false;
        }

        const now = new Date();
        const currDate = now.toISOString().split("T")[0]; // Get current date (YYYY-MM-DD)
        const currDay = now.toLocaleString("en-US", { weekday: "long" });

        const currHours = now.getHours();
        const currMinutes = now.getMinutes();

        const fromDate = new Date(aiAgent.call_handling_rules[0]?.from_date).toISOString().split("T")[0];
        const toDate = new Date(aiAgent.call_handling_rules[0]?.to_date).toISOString().split("T")[0];

        const startTime = new Date(aiAgent.call_handling_rules[0]?.start_time);
        const endTime = new Date(aiAgent.call_handling_rules[0]?.end_time);

        const startHours = startTime.getHours();
        const startMinutes = startTime.getMinutes();
        const endHours = endTime.getHours();
        const endMinutes = endTime.getMinutes();
        if (currDate < fromDate || currDate > toDate) {
            return false;
        }

        if (!aiAgent.call_handling_rules[0].active_days.includes(currDay)) {
            return false;
        }

        if (
            currHours < startHours || 
            (currHours === startHours && currMinutes < startMinutes) ||
            currHours > endHours || 
            (currHours === endHours && currMinutes > endMinutes)
        ) {
            return false;
        }

        return true;
    };

    isAiAgentActiveRef.current = isAiCallActive(aiAgent, "AI Agent");
    isHumanAgentActiveRef.current = isAiCallActive(aiAgent, "Voice Agent");

    console.log(isAiAgentActiveRef.current, isHumanAgentActiveRef.current, "huma-agent");

    console.log(isAiAgentActiveRef.current, "active-agent");
    function transcribeAudio(callId) {
        // TODO: if AI agent is Active then call this function
        if (isAiAgentActiveRef.current === true) { 
            console.log(isAiAgentActiveRef.current, "active-agent");
            dispatch(stopCaptureVoice(callId)).then(
                ({ audioBlob, customerVoiceCaptureStartTime, callLogId }) => {
                    console.log(isAiResponse, "ai-1");
                    const formData = new FormData();
                    formData.append(
                        "file",
                        audioBlob,
                        `recording-${sequence_number}.webm`,
                    );
                    formData.append("call_log_id", callLogId);
                    formData.append("start_time", new Date().toISOString());
                    formData.append("end_time", new Date().toISOString());
                    formData.append("sequence_number", sequence_number);
                    formData.append("response_type", "audio");
                    formData.append("speaker", "ai");
                    if (isAiResponse) {
                        AdminService.aiAudioResponse(formData).then((audioUrl) => {
                            dispatch(callRouteAction.setAiAudioResponse(audioUrl.data, callId)); 
                            sequence_number += 1;
                        }).catch((e) =>{
                            console.log("error from AI response:", e);
                        })
                        dispatch(callRouteAction.processAiCallResponse(false));
                        console.log(isAiResponse, "ai-2");
                    }
                })
        } else {
            dispatch(stopCaptureVoice(callId)).then(
                async ({audioBlob, customerVoiceCaptureStartTime}) => {
                    try {
                        const formData = new FormData();
                        formData.append("file", audioBlob, "recording.webm"); // add UUID here
                        formData.append("call_log_id", callLogIdRef.current);
                        formData.append("start_time", new Date(customerVoiceCaptureStartTime).toISOString().slice(0,16));
                        formData.append("sequence_number", sequence_number);
                        formData.append("speaker", "customer");
                        const audioUrl = await CrmService.uploadAudio(formData);
                        dispatch(setTranscription(audioUrl.data));
                        sequence_number += 1;
                    } catch (e) {
                        console.error("Error uploading audio:", e);
                    }
                },
            );
        }
    }

    const activeCalls = new Map();

    const handleAudioStream = (stream, callId) => {
        if (!callId) {
            console.error("Missing callId for stream");
            return;
        }

        // Create or reuse call-specific audio components
        if (!activeCalls.has(callId)) {
            const audioContext = new AudioContext();
            const source = audioContext.createMediaStreamSource(stream);
            const analyser = audioContext.createAnalyser();
            const dataArray = new Uint8Array(analyser.fftSize);

            activeCalls.set(callId, {
                audioContext,
                source,
                analyser,
                dataArray,
                isCurrentlyRecording: false,
                silenceTimeout: null,
            });

            source.connect(analyser);
        }

        console.log(activeCalls, "sipActiveCalls");
        const callData = activeCalls.get(callId);



        console.log(callData.audioContext.state, "audio-state");

        const startRecording = () => {
            if (!callData.isCurrentlyRecording) {
                console.log("Recording started");
                dispatch(startCaptureVoice(callId));

                if (!isAiAgentActiveRef.current || !isHumanAgentActiveRef.current) {
                    dispatch(
                        stopAgentVoice(callId),
                    );
                }
                callData.isCurrentlyRecording = true;
            }
        };

        const stopRecording = () => {
            if (callData.isCurrentlyRecording) {
                console.log("Recording stopped due to silence");
                transcribeAudio(callId);
                if (!isAiAgentActiveRef.current || !isHumanAgentActiveRef.current) {
                    dispatch(
                        stopAgentVoice(callId),
                    );
                    console.log("recording stopped for agent");
                    dispatch(captureAgentVoice(callId));
                }
                callData.isCurrentlyRecording = false;
            }
        };

        const detectVoice = () => {
            callData.analyser.getByteTimeDomainData(callData.dataArray);

            const isSpeaking = callData.dataArray.some(
                (value) => Math.abs(value - 128) > 10,
            );


            if (isSpeaking) {
                console.log("speaking now");
                if (callData.silenceTimeout) {
                    clearTimeout(callData.silenceTimeout);
                    callData.silenceTimeout = null;
                }
                if (!callData.isCurrentlyRecording) {
                    startRecording();
                }
            } else {
                if (!callData.silenceTimeout) {
                    callData.silenceTimeout = setTimeout(() => {
                        stopRecording();
                    }, 2000);
                }
            }

            requestAnimationFrame(detectVoice);
        };

        if(!isHumanAgentActiveRef.current) {
            detectVoice();
        }

    };


    // FAKE USER INTERACTION TO RESUME AUDIO CONTEXT
    useEffect(() => {
        const unlockAudioContext = () => {
            if (!window.myAudioContext) {
                window.myAudioContext = new (window.AudioContext || window.webkitAudioContext)();
            }

            console.log(window.myAudioContext.state, "audio-window");

            if (window.myAudioContext.state === "suspended") {
                window.myAudioContext.resume().then(() => {
                    console.log("AudioContext resumed after user interaction.");
                });
            }

            document.removeEventListener("click", unlockAudioContext);
            document.removeEventListener("keydown", unlockAudioContext);
        };

        document.addEventListener("click", unlockAudioContext);
        document.addEventListener("keydown", unlockAudioContext);

        return () => {
            document.removeEventListener("click", unlockAudioContext);
            document.removeEventListener("keydown", unlockAudioContext);
        };
    }, []);

    const handleNewRTCSession = useCallback(
        (data) => {
            const session = data.session;
            sessionRef.current = session;

            const callId = session.remote_identity.uri.user;

            session.on("sdp", (data) => {
                console.log("Received SDP:", data.sdp);
            });

            dispatch(setIncommingCall(session, sessionRef, "OUTBOUND"));
            if (session.direction === "incoming") {
                playRingtone();
                dispatch(setIncommingCall(session, sessionRef, "INBOUND"));
                dispatch(callRouteAction.getAiAgentByExtension(extensionRef.current));
                session.on("peerconnection", (e) => {
                    const peerConnection = e.peerconnection;

                    peerConnection.addEventListener("track", async (event) => {
                        if (event.track.kind === "audio") {
                            let stream = event.streams[0];
                            if (remoteAudioRef.current) {
                                remoteAudioRef.current.srcObject = stream;
                                remoteAudioRef.current.play().catch((error) => {
                                    console.error("Error playing audio:", error);
                                });

                                handleAudioStream(stream, callId);

                                dispatch(
                                    getAudioRef({
                                        audioRef: remoteAudioRef.current,
                                    }),
                                );
                            }
                        }
                    });
                });

                setTimeout(() => {
                    if (isAiAgentActiveRef.current || isHumanAgentActiveRef.current) { 
                        dispatch(callRouteAction.pickCall(session, roleRef.current, isAiAgentActiveRef.current));
                    }
                },3000);
            }

            const sessionEvents = {
                accepted: () => {
                    console.log("Call accepted");
                    dispatch(getStartTime());
                    dispatch(captureAgentVoice(mediaRecorderRef, audioChunksRef));
                    window.isCallConnected = true;
                    if (session.currentAudioStream) {
                        handleAudioStream(session.currentAudioStream, callId);
                    }
                },
                ended: () => {
                    console.log("Call ended");
                    dispatch(updateCallLogs("Completed"));
                    dispatch(declineCallRequest());
                    dispatch(
                        setTranscription({
                            transcription: null,
                            message: null,
                        }),
                    );
                    window.isCallConnected = false;
                    if (sessionRef.current) {
                        const callId = sessionRef.current.remote_identity.uri.user;
                        dispatch(callRouteAction.removeActiveAiCalls(callId));
                        if (activeCalls.has(callId)) {
                            activeCalls.delete(callId);
                            console.log(activeCalls, "sipActiveCalls");
                        }else {
                            console.log("sipActiveCalls not found");
                        }
                        //dispatch(customCallActions.removeSession(callId));
                    }
                },
                failed: (e) => {
                    console.log("Call failed", e);

                    Swal.fire({
                        title: `Call Failed`,
                        icon: "info",
                        html: `
                        ${e?.message?.reason_phrase?.toString()}
                        `,
                    });
                    dispatch(updateCallLogs("Failed"));
                    dispatch(declineCallRequest());
                    dispatch(
                        setTranscription({
                            transcription: null,
                            message: null,
                        }),
                    );
                    dispatch(removeActiveCalls(callId));
                    window.isCallConnected = false;
                },

                hold: () => {
                    console.log("Call on hold");
                    window.isCallConnected = true;
                },
                unhold: () => {
                    console.log("Call resumed");
                    window.isCallConnected = true;
                },
                muted: () => {
                    console.log("Call muted");
                    window.isCallConnected = true;
                },
                unmuted: () => {
                    console.log("Call unmuted");
                    window.isCallConnected = true;
                },
            };

            Object.entries(sessionEvents).forEach(([event, handler]) => {
                session.on(event, handler);
            });

            session.connection.ontrack = (event) => {
                const audioRef = (remoteAudioRef.current.srcObject = event.streams[0]);
                session.currentAudioStream = audioRef;
            };
        },

        [dispatch],
    );

    const playRingtone = () => {
        const ringtone = new Audio("/path/to/ringtone.mp3");
        ringtone.loop = true;
        ringtone.play().catch((error) => {
            console.error("Error playing ringtone:", error);
        });
    };

    return <audio ref={remoteAudioRef} autoPlay />;
};

export default SipConnector;
