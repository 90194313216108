import { useState, useEffect, useRef } from "react";
import Dropdown from "./Dropdown";
import { useSelector, useDispatch } from "react-redux";
import { surveyActions } from "../../../../../actions";
const MenuItems = ({ items, depthLevel }) => {
  const dispatch = useDispatch();
  const allFilter = useSelector((state) => state.survey.allFilter);
  const sentimentFilter = useSelector((state) => state.survey.sentimentFilter);

  const [dropdown, setDropdown] = useState(false);
  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}
    >
      {items.submenu && items.icon ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            <div>
              <span>
                {" "}
                <img
                  style={{ width: "15px", height: "13px" }}
                  src="/images/tick.png"
                ></img>
              </span>
              <span className="ml-2" style={{ color: "black" }}>
                {" "}
                {items.title}
              </span>
            </div>

            {depthLevel > 0 ? (
              <span className="head"></span>
            ) : (
              <span className="arrow" />
            )}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : !items.icon && items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.value === "all" ? (
              <div>
                <span>
                  {" "}
                  {sentimentFilter.findIndex(
                    (item) => item.value === "profanity"
                  ) === -1 ||
                  sentimentFilter.findIndex(
                    (item) => item.value === "shortAnswers"
                  ) === -1 ||
                  sentimentFilter.findIndex(
                    (item) => item.value === "sentiment"
                  ) === -1 ||
                  sentimentFilter.findIndex(
                    (item) => item.value === "positive"
                  ) === -1 ||
                  sentimentFilter.findIndex(
                    (item) => item.value === "negative"
                  ) === -1 ||
                  sentimentFilter.findIndex(
                    (item) => item.value === "neutral"
                  ) === -1 ? (
                    <img
                      onClick={() => {
                        dispatch(
                          surveyActions.setSentimentFilter([
                            ...sentimentFilter,
                            { value: "profanity" },
                            { value: "shortAnswers" },
                            { value: "sentiment" },
                            { value: "positive" },
                            { value: "negative" },
                            { value: "neutral" },
                          ])
                        );
                        dispatch(surveyActions.setAllFilter("all"));
                      }}
                      style={{ width: "15px", height: "13px" }}
                      src="/images/untick.png"
                    ></img>
                  ) : (
                    <img
                      onClick={() => {
                        dispatch(surveyActions.setSentimentFilter([]));
                        dispatch(surveyActions.setAllFilter("negative"));
                      }}
                      style={{ width: "15px", height: "13px" }}
                      src="/images/tick.png"
                    ></img>
                  )}
                </span>
                <span
                  onClick={() => {
                    if (allFilter !== "all") {
                      dispatch(
                        surveyActions.setSentimentFilter([
                          ...sentimentFilter,
                          { value: "profanity" },
                          { value: "shortAnswers" },
                          { value: "sentiment" },
                          { value: "positive" },
                          { value: "negative" },
                          { value: "neutral" },
                        ])
                      );
                      dispatch(surveyActions.setAllFilter("all"));
                    } else {
                      dispatch(surveyActions.setSentimentFilter([]));
                      dispatch(surveyActions.setAllFilter("negative"));
                    }
                  }}
                  className="ml-2"
                  style={{ color: "black" }}
                >
                  {" "}
                  {items.title}
                </span>
              </div>
            ) : (
              <div>
                {items.value !== "sentiment" ? (
                  <span>
                    {" "}
                    {sentimentFilter.findIndex(
                      (item) => item.value === items.value
                    ) === -1 ? (
                      <img
                        onClick={() => {
                          dispatch(
                            surveyActions.setSentimentFilter([
                              ...sentimentFilter,
                              { value: items.value },
                            ])
                          );
                        }}
                        style={{ width: "15px", height: "13px" }}
                        src="/images/untick.png"
                      ></img>
                    ) : (
                      <img
                        onClick={() => {
                          const temp = [...sentimentFilter];
                          let filter;

                          filter = temp.filter(
                            (item, i) => item.value !== items.value
                          );

                          dispatch(surveyActions.setSentimentFilter(filter));
                        }}
                        style={{ width: "15px", height: "13px" }}
                        src="/images/tick.png"
                      ></img>
                    )}
                  </span>
                ) : (
                  <span className="ml-4"></span>
                )}

                <span
                  onClick={() => {
                    if (
                      sentimentFilter.findIndex(
                        (item) => item.value === items.value
                      ) === -1
                    ) {
                      dispatch(
                        surveyActions.setSentimentFilter([
                          ...sentimentFilter,
                          { value: items.value },
                        ])
                      );
                      dispatch(surveyActions.setAllFilter(items.value));
                    } else {
                      const temp = [...sentimentFilter];

                      const filter = temp.filter(
                        (item, i) => item.value !== items.value
                      );

                      dispatch(surveyActions.setSentimentFilter(filter));
                    }
                  }}
                  className="ml-2"
                  style={{ color: "black" }}
                >
                  {" "}
                  {items.title}
                </span>
              </div>
            )}

            {depthLevel > 0 ? (
              <span className="head"></span>
            ) : (
              <span className="arrow" />
            )}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : (
        <div className="custom">
          <div>
            {/* <span>
              {" "}
              {sentimentFilter.findIndex(
                (item) => item.value === items.value
              ) === -1 ? (
                <img
                  onClick={() => {
                    dispatch(
                      surveyActions.setSentimentFilter([
                        ...sentimentFilter,
                        { value: items.value },
                      ])
                    );
                  }}
                  style={{ width: "15px", height: "13px" }}
                  src="/images/untick.png"
                ></img>
              ) : (
                <img
                  onClick={() => {
                    const temp = [...sentimentFilter];
                    let filter;
                    if (items.value === "sentiment") {
                      filter = temp.filter(
                        (item, i) => item.value !== items.value
                      );
                      filter = temp.filter(
                        (item, i) => item.value !== "positive"
                      );
                      filter = temp.filter(
                        (item, i) => item.value !== "negative"
                      );
                      filter = temp.filter(
                        (item, i) => item.value !== "neutral"
                      );
                    } else {
                      filter = temp.filter(
                        (item, i) => item.value !== items.value
                      );
                    }
                    dispatch(surveyActions.setSentimentFilter(filter));
                  }}
                  style={{ width: "15px", height: "13px" }}
                  src="/images/tick.png"
                ></img>
              )}
            </span> */}
            <span
              onClick={() => {
                if (
                  sentimentFilter.findIndex(
                    (item) => item.value === items.value
                  ) === -1
                ) {
                  dispatch(
                    surveyActions.setSentimentFilter([
                      ...sentimentFilter,
                      { value: items.value },
                    ])
                  );
                  dispatch(surveyActions.setAllFilter(items.value));
                } else {
                  const temp = [...sentimentFilter];
                  let filter;

                  filter = temp.filter((item, i) => item.value !== items.value);

                  dispatch(surveyActions.setSentimentFilter(filter));
                }
              }}
              className="ml-2 mt-2"
              style={{ color: "black" }}
            >
              {" "}
              {items.title}
            </span>
          </div>
        </div>
      )}
    </li>
  );
};

export default MenuItems;
