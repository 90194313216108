import React, { useState, useEffect, useCallback } from "react";
import { GoogleLoginButton } from "react-social-login-buttons";
import { useGoogleLogin } from "react-google-login";

var CLIENT_ID =
  "612675255354-0qkd9nsdae2le75snqhb9k8bhd5bbttv.apps.googleusercontent.com";

const GoogleLogin = (props) => {
  const searchParams = new URLSearchParams(window.location.search);
  const inviteType = searchParams.get("invitetype");

  const onSuccess = (res) => {
    if (res?.profileObj) {
      let { name, email, googleId } = res.profileObj;
      props.onSuccess({ name, email, accountId: googleId, type: "gmail" });
    }
  };
  const onFailure = (res) => {
    console.log("onFailure ", res);
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId: CLIENT_ID,
    isSignedIn: false,
    accessType: "offline",
  });

  useEffect(() => {
    if (inviteType === "gmail") {
      const timer = setTimeout(() => {
        const button = document.getElementById("google-login-btn");
        if (button) {
          button.click(); 
        }
      }, 2000); 

      return () => clearTimeout(timer); 
    }
  }, [inviteType]);

  

  return props.showOnlyIcon ? (
    <span
      id="google-login-btn"
      className="googleIconCircle"
      onClick={signIn}
      style={props.style || {}}
    >
      <img src="images/googleLogo.png" className="googleIcon" />
    </span>
  ) : (
    <GoogleLoginButton 
    id="google-login-btn"
    onClick={signIn} 
    />
  );
};

export default GoogleLogin;
