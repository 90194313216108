import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TablePagination } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Header, MainMenu } from '../Common/Header';
import AIAgentModal from '../CRM/CustomerTable/AgentModal';
import { agentsAction } from '../../actions/agentsActions';
import { useDispatch, useSelector } from "react-redux";
import { AgentsService } from '../../services/AgentsService';
import { agentsConstants } from '../../constants/agentsConstants';
import DataTable from '../../components/Tables/DataTable';


const VoiceAgentsTable = () => {

  const [openAIAgentModal, setOpenAIAgentModal] = useState(false);
  const [editAIAgent, setEditAIAgent] = useState(false);
  const [agentsData, setAgentsData] = useState(null);
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const isAgentAdded = useSelector((state) => state.agentsLogic.isAgentAdded);

  // useEffect(() => {
  //   (async () => {
  //     const data = await AgentsService.getAgents();
  //     setAgentsData(data?.data?.ai_agents);
  //   })();
  // }, []);

  // Fetch agents from API
  const fetchAgents = async () => {
    setLoading(true);
    try {
      const response = await AgentsService.getAgents();
      const agents = response?.data?.ai_agent || [];
      setAgentsData(agents);
    } catch (error) {
      console.error("Error fetching agents:", error);
      setAgentsData([]); // Reset the table to empty if an error occurs
    } finally {
      setLoading(false);
    }
  };

  // Trigger fetch when component mounts or when agent is added
  useEffect(() => {
    fetchAgents();

    if (isAgentAdded) {
      fetchAgents();
      dispatch({ type: agentsConstants.RESET_AGENT_ADDED }); // Reset flag after re-fetch
    }
  }, [isAgentAdded, dispatch]);

  const handleDeleteClick = async (agentId) => {
    try {
      setIsDelete(true);
      await dispatch(agentsAction.deleteAgent(agentId)); // Dispatch the delete action

      // Fetch updated agents list after deletion
      await fetchAgents();
    } catch (error) {
      console.error("Error deleting agent:", error);
    }
  };

  const columns = ["agent_name", "agent_type", "preferred_lang", "call_script", "extension", "sp_instructions", "status", "Actions"];
  const rows = [
    {
      id: 1,
      agent_name: "Agent 1",
      agent_type: "Inbound",
      preferred_lang: "English",
      call_script: "Welcome Script",
      extension: "101",
      sp_instructions: "Handle with care",
      status: "active"
    },
    {
      id: 2,
      agent_name: "Agent 2",
      agent_type: "Outbound",
      preferred_lang: "Spanish",
      call_script: "Sales Pitch",
      extension: "102",
      sp_instructions: "Focus on product benefits",
      status: "active"
    }
  ];

  // const handleAIAgentSubmit = (agentData) => {
  //   console.log('AI Agent submitted:', agentData);
  //   dispatch(agentsAction.addAgents(agentData))
  //   dispatch(agentsAction.editAgent(agentData))
  //   setOpenAIAgentModal(false);
  // };

  const handleRowClick = (id) => {
    setSelectedAgentId(id);
    setOpenAIAgentModal(true);
    setEditAIAgent(true);
  };
  

  const handleAIAgentSubmit = (agentData) => {

    if (editAIAgent) {
      dispatch(agentsAction.editAgent(agentData, selectedAgentId));
    } else {
      dispatch(agentsAction.addAgents(agentData));
    }

    setOpenAIAgentModal(false);
  };


  const handleAgentClick = () => {
    setOpenAIAgentModal(true);
  }
  
  const handleEditClick = () => {
    setOpenAIAgentModal(true);
    setEditAIAgent(true);
  }

  const filteredAgents = agentsData ? agentsData.filter(agent => 
    agent.agent_name.toLowerCase().includes(filterText.toLowerCase()) ||
    agent.agent_type.toLowerCase().includes(filterText.toLowerCase()) ||
    agent.preferred_lang.toLowerCase().includes(filterText.toLowerCase()) ||
    agent.call_script.toLowerCase().includes(filterText.toLowerCase()) ||
    agent.extension.toLowerCase().includes(filterText.toLowerCase()) ||
    agent.sp_instructions.toLowerCase().includes(filterText.toLowerCase()) ||
    agent.status.toLowerCase().includes(filterText.toLowerCase())
  ) : [];

  console.log("filteredAgents", filteredAgents);

  const enhancedRows = filteredAgents?.map((row) => ({
    ...row,
    Actions: (
      <>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => handleRowClick(row.id)}      
          style={{ marginRight: "10px" }}
        >
          Edit
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => handleDeleteClick(row.id)}
        >
          Delete
        </Button>
      </>
    ),
  }));
  
{/* <Container sx={{ mt: 4, maxWidth: '1400px' }}>
        <Typography variant="h5" component="h1" gutterBottom>
          AI Voice Agents
        </Typography>

        <Button variant="contained" color="primary" sx={{ mb: 2 }} onClick={handleAgentClick}>
          Add Agent
        </Button>

        <TextField
          variant="outlined"
          placeholder="Enter content to filter"
          fullWidth
          sx={{ mb: 2 }}
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)} // Update filterText state
        /> */}

        {/* <Button variant="contained" color="primary" sx={{ mb: 2 }}>
        Sort By
      </Button> */}

        {/* <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((col, index) => (
                  <TableCell key={index} sx={{ fontWeight: 'bold' }}>
                    {col}
                  </TableCell>
                ))}
                <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAgents && filteredAgents.length > 0 ? (
                filteredAgents.map((row) => (
                  <TableRow key={row.id} onClick={() => handleRowClick(row.id)} style={{ cursor: 'pointer' }}>
                    <TableCell>{row.agent_name}</TableCell>
                    <TableCell>{row.agent_type}</TableCell>
                    <TableCell>{row.preferred_lang}</TableCell>
                    <TableCell>{row.call_script}</TableCell>
                    <TableCell>{row.extension}</TableCell>
                    <TableCell>{row.sp_instructions}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                      <Button variant="contained" color="primary" size="small"
                        // startIcon={<EditIcon sx={{ fontSize: 13 }}/>}  
                        sx={{ mr: 1, fontSize: '0.75rem', textTransform: 'none', whiteSpace: 'nowrap' }}
                        onClick={handleEditClick}>
                        Edit Agent
                      </Button>
                      <IconButton color="primary"
                        onClick={() => handleDeleteClick(row.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length + 1} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>


        <TablePagination
          component="div"
          count={1}
          rowsPerPage={10}
          page={0}
          onPageChange={() => { }}
          onRowsPerPageChange={() => { }}
        />
      </Container> */}

  return (
    <div>
      {/* <Header /> */}
      {/* <MainMenu /> */}
      <div style={{ marginBottom: "20px" }}>
        <Button variant="contained" color="primary" onClick={handleAgentClick}>
          Add Agents
        </Button>
      </div>
      <DataTable columns={columns} rows={enhancedRows} loading={loading} tableType="Agents" />

      <AIAgentModal
        open={openAIAgentModal}
        onClose={() => setOpenAIAgentModal(false)}
        handleSubmit={handleAIAgentSubmit}
        editAIAgent={editAIAgent}
        selectedAgentId={selectedAgentId}
        setEditAIAgent={setEditAIAgent}
      />

    </div>
  );
};

export default VoiceAgentsTable;
