import React from 'react';
import {setter} from "../EmbedPage";
import ReactDOMServer from "react-dom/server";

const Full = (props) => {
    const {url} = props;
    const Iframe = ({src}) => <iframe width="100%" height="100%"  style={{textAlign:'center', pointerEvents:'unset', border:'none'}} src={src} />;
    const getDOMString = () => {
        const src = url.replace("embed/survey_ans", "survey_ans");
        const domString = ReactDOMServer.renderToString(<Iframe src={src}/>);
        return `<!DOCTYPE html> <html lang="en"> <head> <meta charset="utf-8" />
                             <meta name="viewport" content="width=device-width, initial-scale=1" />
                             <title>My Survey</title>
                             </head> <body>
                             <div style="width: auto; height: 100vh">
                                ${domString.replace("embed/survey_ans", "survey_ans")}
                             </div>
                              </body>
                             </html>`
    };
    React.useEffect(()=>{
        setter(getDOMString());
    },[]);
    return <Iframe src={url} />;
};

export default Full;