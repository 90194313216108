import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ContentBoxBanner from "../../../assets/images/content-box-banner.svg";
import Label from "../../../common/controls/inputs/Label";
import { Toggle } from "../../../common/controls/inputs/Material";
import { workspaceActions } from "../../../actions";
import {CalendarIcon, PenIcon, PlaneIcon, EyeIcon, ListIcon, HelpIcon, ResponseIcon, CompletionIcon, NotificationIcon} from './components';
import {ANALYSE_RESULTS_MENU} from "../../Common/Constants";
import Backdrop from '../../Common/Backdrop.js'
import {history} from "../../../helpers";
import "./Summary.scss";

const origin = window.location.origin;
const COLS_MAP = {
  social_link:'Send with a social link',
  survey_link:'Send with a survey link',
  target_audience:'Target Audience',
  tags_used:'Tags Used',
  opinion:'Opinion',
  model_effectiveness:'Model Effectiveness',
  assigned_tasks:' Assigned Tasks',
  full_embed: 'Full Embed',
  outcomes: 'Outcomes',
  sliderTop_embed: 'Slider Top Embed',
  slider_embed : 'Slider Embed',
  standard_embed: 'Standard Embed',
  surveys_linked: 'Surveys Linked'
}
const SURVEY_AUDIENCE = ['social_link', 'survey_link', 'target_audience'];
const FORM_AUDIENCE = ['social_link', 'full_embed', 'sliderTop_embed', 'slider_embed', 'standard_embed', 'survey_link'];
const HISTORICAL_AUDIENCE = ['outcomes', 'assigned_tasks'];
const SCORE = {
  internal: {
    left:{text:'Sentiments', id:'sentiments'},
    mid:{text:'Locations Surveyed', id:'location_surveys', subMenu: ANALYSE_RESULTS_MENU[1]},
    right:{text:'Survey Effectiveness', id:"survey_effectivess", sign:'%', subMenu: ANALYSE_RESULTS_MENU[3]},
  },historical: {
    left:{text:'Sentiments', id:'sentiments'},
    mid:{text:'Linked Analysis', id:'linked_analysis'},
    right:{text:'Number of Queries', id:"number_of_queries", subMenu: ANALYSE_RESULTS_MENU[5]},
  },external: {
    left:{text:'Sentiments', id:'sentiments'},
    mid:{text:'Linked Analysis', id:'linked_analysis'},
    right:{text:'Number of Queries', id:"number_of_queries", subMenu: ANALYSE_RESULTS_MENU[5]},
  },form: {
    left:{text:'Sentiments', id:'sentiments'},
    mid:{text:'Response Locations', id:'location_surveys', subMenu: ANALYSE_RESULTS_MENU[1]},
    right:{text:'Effectiveness', id:"survey_effectivess", sign:'%', subMenu: ANALYSE_RESULTS_MENU[3]},
  },
}

const Summary = ({surveyID, setActiveTab, setAnalysisTab}) => {
  const formUrl = origin+"/form_ans/3952&ydkof244mjfhkih933klk30hgmn44$oj";
  const [desc, setDesc] = useState("");
  const [data, setData] = useState({});
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeCols, setActiveCols] = useState('');
  const [textAnalysis, setTextAnalysis] = useState({});
  const dispatch = useDispatch();

  useEffect(()=>{
    setLoading(true)
    dispatch(workspaceActions.getSummary({detail_id: surveyID})).then(res=>{
      if(res?.data){
        setToggle(res.data.stop);
        setData(res.data);
      }
      setLoading(false);
    });
  }, [surveyID]);

  useEffect(()=>{
    if(data && data.audience){
      const name = Object.keys(data.audience)[0];
      setActiveCols(name);
    }
    if(Object.keys(data?.next_plan_features||{}).length > 0){
      setDesc(Object.keys(data.next_plan_features)[0])
    }
  },[data]);

  useEffect(()=>{
    activeCols.length && setTextAnalysis(data?.audience[activeCols]?.text_analysis);
  },[activeCols]);

  const handleChange = (event) => {
    setDesc(event.target.value);
  };

  const goTo = (tab, menu) => {
    const isForm = data?.ws_detail_type === 'form';
    const _tab =  (isForm && tab === 'AnalyseResults') ? 'AnalyseSubmission' : (isForm && tab === 'DesignSurvey') ? 'CreateForm' : tab;
    setActiveTab(()=>{
      menu && setAnalysisTab(menu);
      return _tab;
    });
  };

   const handleToggle = (value, name) => {
     setToggle(value);
     setLoading(true);
     dispatch(workspaceActions.surveyStop({detail_id: surveyID, stop: value})).then(res=>{
       setLoading(false);
     })
  };

  const upgradePlan = () => {
    history.push('/subscription')
  };

  const SummaryHeadItems = () => {
    return <div className="summary__items">
      <div className="summary__flex">
        <div className="summary__item">
          <CalendarIcon />
          <span>{data?.survey_created && new Date(data.survey_created).toLocaleDateString()}</span>
        </div>
        <div className="summary__item">
          <p>{data?.num_of_questions || 0} Questions</p>
        </div>
      </div>

      <div className="summary__flex">
        <div className="">{
          ["historical", "external"].indexOf(data?.ws_detail_type || "") > -1 ? null :
          <div className="toggleWrap">
            <Toggle config={{name:'stopSurvey',value: toggle, handleValue:handleToggle }} />
            <Label config={{ label: 'Stop Survey', style: {color:'#8a94a6'} }} />
          </div>
        }
          
        </div>
        <div className="summary__item">
          <p>
            Theme: <span>{data?.experience_type}</span>
          </p>
        </div>
      </div>
    </div>
  };

  const SummaryScore = () => {
    const name = data?.ws_detail_type||'internal';
    const detail = (key) => {
      const id = SCORE[name][key]['id'];
      const sign = SCORE[name][key]['sign'];
      if(key==='mid' && data.hasOwnProperty(id)){
        return typeof data[id] === 'boolean' ? data[id] ? 'Y' : 'N' : data[id];
      }else if(data.hasOwnProperty(id)){
        return sign ? data[id]+sign : data[id];
      }
      return null;
    };
    return <div className="summary__score">
      <div className="summary__score--items">
        <div style={{cursor:'pointer'}} onClick={()=>goTo('AnalyseResults')}>
          <ul className="score__list">
            <li className="score__list--data bg--success"><div className="data--text">{detail('left')?.positive}%</div></li>
            <li className="score__list--data bg--warning"><div className="data--text">{detail('left')?.neutral}%</div></li>
            <li className="score__list--data bg--danger"><div className="data--text">{detail('left')?.negative}%</div></li>
          </ul>
          <h2>{SCORE[name]['left']['text']}</h2>
        </div>
      </div>
      <div className="summary__score--items">
        <div style={{cursor:'pointer'}} onClick={()=>goTo('AnalyseResults', SCORE[name]['mid']['subMenu'])}>
          <ul className="score__list">
            <li className="score__list--data bg--blue"><div className="data--text">{detail('mid')}</div></li>
          </ul>
        <h2>{SCORE[name]['mid']['text']}</h2>
        </div>
      </div>
      <div className="summary__score--items">
        <div style={{cursor:'pointer'}} onClick={()=>goTo('AnalyseResults', SCORE[name]['right']['subMenu'])}>
          <ul className="score__list">
            <li className="score__list--data bg--purple"><div className="data--text">{detail('right')}</div></li>
          </ul>
          <h2>{SCORE[name]['right']['text']}</h2>
        </div>
      </div>
    </div>
  };

  const SummaryLeftPanel = () => {
    const cs = ['form', 'internal'].indexOf(data?.ws_detail_type)>-1 ? "":' disable';
    const redirect = (tab) => {
      goTo(tab);
    };
    return <div className="summary__leftPanel">
      <ul className="button__list">
        <li className={"button__list--item"+cs}>
          <button onClick={()=>redirect('DesignSurvey')}>
            <PenIcon />
          </button>
        </li>
        <li className={"button__list--item"+cs}>
          <button onClick={()=>redirect('Preview')}>
            <EyeIcon />
          </button>
        </li>
        <li className={"button__list--item"+cs}>
          <button onClick={()=>redirect('CollectResponses')}>
            <PlaneIcon />
          </button>
        </li>
        <li className="button__list--item">
          <button onClick={()=>redirect('AnalyseResults')}>
            <ListIcon />
          </button>
        </li>
      </ul>

      <div className="content__box">
        <div className="content__box--head">
          <Box className="upgrade--dropdown">
            <FormControl fullWidth size="small">
              <Select
                  labelId="select-upgrade-label"
                  value={desc}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
              >
                {Object.keys(data?.next_plan_features||{}).map(plan=>{
                  return <MenuItem key={plan} value={plan}>{plan}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Box>

          <div className="content__box--actions">
            <button className="upgrade--btn" onClick={upgradePlan}>Upgrade</button>
            <button className="help--btn">
              <HelpIcon />
            </button>
          </div>
        </div>

        <div className="content__box--banner">
          <div className="banner--text">
            <h2>Did you know?</h2>
            <a href="#">Learn now</a>
          </div>

          <div className="banner--image">
            <img src={ContentBoxBanner} alt="" width="75" />
          </div>
        </div>

        <div className="content__box--body">
          <div className="body--title">
            <p>{`Response Left : ${data?.current_plan_info?.answers_left}`}</p>
            {data?.current_plan_info?.time_left !== "" ? <p>{`Time Left : ${data?.current_plan_info?.time_left.split(',')[0]}`}</p>: null}
          </div>
          <h2>{data?.next_plan_features && data.next_plan_features[desc]?.heading}</h2>
          <ul>
            {data?.next_plan_features && data.next_plan_features[desc]?.features.map(f=><li key={f.Label}> {f.Label} <span>{f.value?'Y':'N'}</span> </li>)}
          </ul>
          <p>
            {data?.next_plan_features && data.next_plan_features[desc]?.description}
          </p>
          <button>Learn more</button>
        </div>
      </div>
    </div>
  };

  const SummaryRightPanel = () => {
    const ws_name = ['historical','external'].indexOf(data?.ws_detail_type)>-1 ? HISTORICAL_AUDIENCE : data?.ws_detail_type === 'form' ? FORM_AUDIENCE: SURVEY_AUDIENCE;
    const TextAnalysisResp = () => {
      const redirect = (v) => {
        if(v==='opinion'){
          if(textAnalysis[v] === 'Y'){
            goTo('AnalyseResults', ANALYSE_RESULTS_MENU[4]);
          }
        }else if(v==='model_effectiveness'){
          window.open(formUrl, '_blank')
        }else{
          goTo('AnalyseResults');
        }
      };
      const TextVal = ({val}) => {
        if(val === 'opinion'){
          return ['Y', 'N'].indexOf(textAnalysis[val])>-1 ? <span>{textAnalysis[val]}</span>:<button className="upgrade-btn" onClick={upgradePlan}>Upgrade</button>
        }else if(val === 'model_effectiveness'){
          return <a href="#">show</a>
        }
        return <span>{textAnalysis[val]}</span>;
      };
      return textAnalysis ? Object.keys(textAnalysis).map(val=>{
        return COLS_MAP[val] ? <li className={"textAnalysis"} key={val} onClick={()=>redirect(val)}>
          {COLS_MAP[val]} <TextVal val={val}/>
        </li> : null
      }) : <></>
    };
    return <div className="summary__rightPanel">
      <div className="grid__cards">
        <div onClick={()=> goTo('AnalyseResults')} className="grid__cards--item bg--linearGradientPink">
          <div className="card--info">
            <h2>Total Responses</h2>
            <span>{data.total_responses}</span>
          </div>
          <div className="card--icon">
            <ResponseIcon />
          </div>
        </div>
        <div onClick={()=> goTo('AnalyseResults')} className="grid__cards--item bg--linearGradientGreen">
          <div className="card--info">
            <h2>Completion</h2>
            <span>{data.completion}%</span>
          </div>
          <div className="card--icon">
            <CompletionIcon />
          </div>
        </div>
        <div onClick={()=> goTo('AnalyseResults', ANALYSE_RESULTS_MENU[6])} className="grid__cards--item bg--linearGradientPurple">
          <div className="card--info">
            <h2>Alerts</h2>
            <span>{data.alerts}</span>
          </div>
          <div className="card--icon">
            <NotificationIcon />
          </div>
        </div>
      </div>

      <div className="grid__row">
        <div className="grid__col">
          <h2>Collectors</h2>
          <p>Audience</p>
          <ul style={{height: 241}}>
            {data.audience && ws_name.map(val=>{
              return <li className={activeCols === val ? 'active':''} key={val} onClick={()=>setActiveCols(val)}>{COLS_MAP[val]} <span>{data.audience[val].count}</span>
              </li>
            })}
          </ul>
        </div>

        <div className="grid__col">
          <h2>Text Analysis</h2>
          <p></p>
          <ul style={{minHeight: 241}}>
            <TextAnalysisResp />
          </ul>
        </div>
      </div>
    </div>;
  };

  return (
    <>
      <div className="summary">
        <div className="summary__head">
          <SummaryHeadItems />
          <SummaryScore />
        </div>

        <div className="summary__body">
          <SummaryLeftPanel />
          <SummaryRightPanel />
        </div>
      </div>
      <Backdrop  show={loading}/>
    </>
  );
};

export default Summary;
