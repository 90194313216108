import React, { useState, useEffect, useMemo } from "react";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Apperance from "../Apperance";
import { ANS_TYPE, ANS_TYPE_INDEXING } from "../../Common/Constants";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { AddLogo, AddTitle } from "../LogoTitle";
import Tooltip from "@mui/material/Tooltip";
import Prompt from "../../Common/ConfirmationPrompt";
import { surveyActions } from "../../../actions";
import { useDispatch } from "react-redux";

const origin = window.location.origin;

const AddQuestionQueue = ({ onTypeSelect, ansTypes }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelect = (t) => {
    if (t.value === "Help") {
      dispatch(surveyActions.setTriggerType("help"));
    }
    onTypeSelect && onTypeSelect(t);
  };

  return (
    <React.Fragment>
      <Tooltip title="Add Elements Type">
        <ControlPointIcon onClick={handleClick} />
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 8,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <div className="questionQuePopup tooltipPopup">
          <h3>All Element Types</h3>
          <div className="innerPopup">
            <h4>Recommended</h4>
            <div className="items">
              {ansTypes.map((t, i) => (
                <div
                  key={"qt_" + i}
                  className={`item ${t.id === "help" ? "questionHelp" : ""}`}
                  onClick={() => onSelect(t)}
                >
                  <div className="ai">AI</div>
                  <img
                    src={origin + t.icon}
                    alt="arrow icon"
                    className={t.id === "Picture" ? "pictureIcon" : ""}
                  />
                  <p>{t.label}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Menu>
    </React.Fragment>
  );
};

const Dropdown = ({ handleKebab, pos, len = 0 }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const handleAction = (action) => {
    setAnchorEl(null);
    if (action) {
      handleKebab && handleKebab(pos, action);
    }
  };

  return (
    <div>
      <MoreVertIcon onClick={handleClick} />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        className={"questionDropDown"}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        {len > 1 && (
          <MenuItem name="delete" onClick={() => handleAction("delete")}>
            Delete
          </MenuItem>
        )}
        <MenuItem name="duplicate" onClick={() => handleAction("duplicate")}>
          Duplicate
        </MenuItem>
        <MenuItem name="moveUp" onClick={() => handleAction("moveUp")}>
          Move Up
        </MenuItem>
        <MenuItem name="moveDown" onClick={() => handleAction("moveDown")}>
          Move down
        </MenuItem>
      </Menu>
    </div>
  );
};

const QuestionsQueue = (props) => {
  const { queue = [], onQuesSelect, position, isLoading } = props;
  const queuing = useMemo(() => {
    const onSelectQues = (idx) => {
      onQuesSelect && onQuesSelect(idx);
    };

    return isLoading ? (
      <div></div>
    ) : (
      queue.map((q, i) => {
        const qType = ANS_TYPE[ANS_TYPE_INDEXING[q.q_type]] || {};
        const disable = q?.can_be_updated === false ? true : false;
        return (
          <div
            key={"qu-" + i}
            className={`item ${qType.wrapperClass}${
              position === i ? " active" : ""
            }`}
          >
            <div onClick={() => onSelectQues(i)} style={{ cursor: "pointer" }}>
              <div className="icon">
                <img
                  src={origin + qType.icon}
                  alt="arrow icon"
                  className={qType.id === "Picture" ? "pictureIcon" : ""}
                />
              </div>
              <Tooltip title={`${i + 1}- ${q.question}`}>
                <div className="text">{`${i + 1}- ${q.question}`}</div>
              </Tooltip>
            </div>

            {disable ? null : (
              <div className="iconDot">
                <Dropdown {...props} pos={i} len={queue.length} />
              </div>
            )}
          </div>
        );
      })
    );
  }, [queue, position]);

  return (
    <div className="questionsQueueWrap">
      <div className="logoPageTitle">
        <AddLogo {...props} />
        <AddTitle {...props} />
        {/*<div className="addLogo">Logo <ControlPointIcon/></div>*/}
        {/*<div className="addLogo pageTitle">Page Title <ControlPointIcon/></div>*/}
      </div>
      <div className="questionQueueInner">
        <div className="head">
          Form Elements <AddQuestionQueue {...props} />
        </div>
        <div className="details">{queuing}</div>
      </div>
    </div>
  );
};

const Appearance = (props) => {
  const [showAlert, setAlert] = useState(false);
  const { data = {} } = props;
  const disable = data?.can_be_updated === false ? true : false;
  const cssName = disable ? " disableCard" : "";
  return (
    <div
      className={"formatWrapper"}
      ref={props.rightMenuActive}
      onClick={() => {
        disable && !showAlert && setAlert(true);
      }}
    >
      <div className="head">
        Appearance
        <div className="surveyMenuCloseBtn">
          <button
            onClick={() =>
              props.rightMenuActive.current.classList.toggle("menu-active")
            }
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_2441_8773)">
                <circle cx="9.5" cy="8.5" r="7.5" fill="#18A0FB" />
              </g>
              <g clipPath="url(#clip0_2441_8773)">
                <path
                  d="M13.6253 3.91797H5.37533C5.25377 3.91797 5.13719 3.96626 5.05123 4.05221C4.96528 4.13817 4.91699 4.25474 4.91699 4.3763V12.6263C4.91699 12.7479 4.96528 12.8644 5.05123 12.9504C5.13719 13.0363 5.25377 13.0846 5.37533 13.0846H13.6253C13.7469 13.0846 13.8635 13.0363 13.9494 12.9504C14.0354 12.8644 14.0837 12.7479 14.0837 12.6263V4.3763C14.0837 4.25474 14.0354 4.13817 13.9494 4.05221C13.8635 3.96626 13.7469 3.91797 13.6253 3.91797ZM10.417 12.168H5.83366V4.83464H10.417V12.168ZM13.167 12.168H11.3337V4.83464H13.167V12.168Z"
                  fill="white"
                />
              </g>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.44009 9.27037C8.51997 9.18815 8.51997 9.05486 8.44009 8.97264L7.49382 7.9987L8.44009 7.02476C8.51997 6.94254 8.51997 6.80924 8.44009 6.72703C8.36021 6.64481 8.2307 6.64481 8.15082 6.72703L7.05991 7.84983C6.98003 7.93205 6.98003 8.06535 7.05991 8.14756L8.15082 9.27037C8.2307 9.35259 8.36021 9.35259 8.44009 9.27037Z"
                fill="white"
                stroke="white"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <defs>
                <filter
                  id="filter0_d_2441_8773"
                  x="0"
                  y="0"
                  width="19"
                  height="19"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="1" />
                  <feGaussianBlur stdDeviation="1" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2441_8773"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_2441_8773"
                    result="shape"
                  />
                </filter>
                <clipPath id="clip0_2441_8773">
                  <rect
                    width="11"
                    height="11"
                    fill="white"
                    transform="translate(4 3)"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>
      <div className={"formatWrapInner" + cssName}>
        <Apperance {...props} />
      </div>
      {disable ? (
        <DisableAlert showAlert={showAlert} setAlert={setAlert} />
      ) : null}
    </div>
  );
};

const DisableAlert = ({ showAlert, setAlert }) => {
  return (
    <Prompt
      show={showAlert}
      singleBtn={true}
      message={"Answered form element can't be edit."}
      handler={(e) => {
        setAlert(false);
      }}
      btnLabel={"OK"}
    />
  );
};

export { QuestionsQueue, Appearance, DisableAlert };
