export const tagsConstants = {
    // get all TAGS
    GET_ALL_TAGS_REQUEST: "GET_ALL_TAGS_REQUEST",
    GET_ALL_TAGS_SUCCESS: "GET_ALL_TAGS_SUCCESS",
    GET_ALL_TAGS_FAILURE: "GET_ALL_TAGS_FAILURE",
  
    // add TAGS
    ADD_TAGS_REQUEST: "ADD_TAGS_REQUEST",
    ADD_TAGS_SUCCESS: "ADD_TAGS_SUCCESS",
    ADD_TAGS_FAILURE: "ADD_TAGS_FAILURE",
    RESET_AGENT_ADDED: "RESET_AGENT_ADDED",
  
    // edit TAGS
    EDIT_TAGS_REQUEST: "EDIT_TAGS_REQUEST",
    EDIT_TAGS_SUCCESS: "EDIT_TAGS_SUCCESS",
    EDIT_TAGS_FAILURE: "EDIT_TAGS_FAILURE",
  
    // delete TAGS
    DELETE_TAGS_REQUEST: "DELETE_TAGS_REQUEST",
    DELETE_TAGS_SUCCESS: "DELETE_TAGS_SUCCESS",
    DELETE_TAGS_FAILURE: "DELETE_TAGS_FAILURE",
  };
  