import React, { useEffect, useState, useRef, useMemo } from "react";
import Tooltip from "@mui/material/Tooltip";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Dialogbox from "../../Common/DialogBox";
import CancelIcon from "@mui/icons-material/Cancel";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import CardPreview from "./PreviewCard";
import Backdrop from "../../Common/Backdrop";
import Alert from "../../Common/ConfirmationPrompt";

import { SURVEY_BG_THEME } from "../../Common/Constants";
import { useDispatch, useSelector } from "react-redux";
import { workspaceActions, emailActions } from "../../../actions";
import { workspaceService } from "../../../services/index";
import { Carousel, Radio } from "antd";
import { clone } from "../../Common/Utils";
const origin = window.location.origin;

const IntroCard = ({ survey, cardRef, setTimer }) => {
  const { value = "", type } = survey?.estimated_time || {};
  const totalTime = Number(value) || 0;
  const tt = totalTime > 0 ? (type === "min" ? totalTime * 60 : totalTime) : 0;
  const mm = parseInt("" + tt / 60);
  const ss = parseInt("" + (tt % 60));
  const timer = `${mm > 9 ? mm : "0" + mm}:${ss > 9 ? ss : "0" + ss}`;
  return (
    <div className="introCard">
      <div className="logoTitle ">
        {survey?.page_logo?.length ? (
          <div className="logo">
            <img src={survey.page_logo} alt="Default Logo" />
          </div>
        ) : null}
        {survey?.page_info?.title?.length ? (
          <div className="title">{survey.page_info.title}</div>
        ) : null}
      </div>
      {survey?.page_info?.intro?.length ? (
        <div className="description">{survey.page_info.intro}</div>
      ) : null}
      <div className="groupBottom">
        <button
          className="btn"
          onClick={() => {
            cardRef && cardRef.current.next();
            cardRef && setTimer(true);
          }}
        >
          Start Survey
        </button>
        {totalTime > 0 ? (
          <div className="time">
            <AccessTimeFilledIcon /> Takes {timer} {mm > 0 ? " min" : " sec"}
          </div>
        ) : null}
      </div>
    </div>
  );
};
const FinalSubmitCard = ({ survey = {}, onSubmit }) => {
  const { page_info = {} } = survey;
  return (
    <div className="finalSubmitCard">
      <button className="btn submit" onClick={onSubmit}>
        Submit
      </button>
      {!page_info?.repeatFooter && page_info?.footer?.length ? (
        <div className="footerContent">
          <p>{page_info?.footer}</p>
        </div>
      ) : null}
    </div>
  );
};

const Slider = (props) => {
  const {
    quesData = [],
    survey,
    onSubmit,
    showIntroCard,
    showSubmitCard,
    setTimer,
  } = props;
  const cardRef = useRef();
  const nextEl = document.getElementsByClassName("slick-next");
  const prevEl = document.getElementsByClassName("slick-prev");

  useEffect(() => {
    if (prevEl && prevEl.length) {
      prevEl[0].style.display = "none";
    }
  }, []);

  const beforeChange = (from, to) => {
    const cardsLen =
      cardRef?.current?.innerSlider?.track?.props?.children?.length ||
      quesData.length;
    if (nextEl && nextEl.length) {
      nextEl[0].style.display = to === cardsLen - 1 ? "none" : "block";
    }
    if (prevEl && prevEl.length) {
      prevEl[0].style.display = to === 0 ? "none" : "block";
    }
    if (showIntroCard && survey?.page_info?.title?.length) {
      from === 0 && setTimer(true);
      prevEl[0].style.display = to === 1 ? "none" : "block";
    }
  };

  return (
    <div className={"slider"}>
      <Carousel
        dots={false}
        loop={false}
        arrows
        ref={cardRef}
        beforeChange={beforeChange}
      >
        {showIntroCard && survey?.page_info?.title?.length ? (
          <IntroCard survey={survey} cardRef={cardRef} setTimer={setTimer} />
        ) : null}
        {QuestionCards({ ...props, cardRef, showSubmitCard })}
        {showSubmitCard ? (
          <FinalSubmitCard survey={survey} onSubmit={onSubmit} />
        ) : null}
      </Carousel>
    </div>
  );
};

const QuestionCards = (props) => {
  const {
    quesData = [],
    survey,
    cardRef,
    onChange,
    qRefs,
    visitorId,
    surveyId,
    responseBy,
    userAddress,
    sliderView,
    onSubmit,
    showSubmitCard,
    onFocus,
  } = props;
  console.log("dkdkkds", quesData);
  const quesLen = quesData.filter((q) => !q.isHidden);
  let quesIndex = 0;
  let count = 0;
  return quesData.map((q, i) => {
    const { settings = {}, q_type } = q || {};
    q.visitorId = visitorId;
    q.surveyId = surveyId;
    q.userAddress = userAddress;
    q.responseBy = responseBy;

    if (!q.isHidden) {
      quesIndex++;
      const submit =
        sliderView && !showSubmitCard && quesIndex === quesLen.length
          ? onSubmit
          : null;
      const btnText =
        sliderView && !showSubmitCard && quesIndex === quesLen.length
          ? "Submit"
          : "Next";
      if (q_type !== "Help" && q_type !== "Decorator") {
        count++;
      }
      return (
        <CardPreview
          key={"sr_" + i}
          {...q}
          layout={settings?.layout}
          isRequired={settings.require}
          quesType={q_type}
          qno={quesIndex}
          srNo={q_type === "Help" || q_type === "Decorator" ? 0 : count}
          cardRef={cardRef}
          onChange={onChange}
          qRefs={qRefs}
          submit={submit}
          btnText={btnText}
          sliderView={sliderView}
          onFocus={onFocus}
        />
      );
    }
  });
};

const Timer = (props) => {
  const { onSubmit, isPreview, survey } = props;
  const { value = "", type, autoSubmit } = survey?.estimated_time || {};
  const [countDown, setCountDown] = useState(0);
  const [showAlert, setAlert] = useState(false);
  useEffect(() => {
    const totalTime = Number(value);
    if (totalTime > 0) {
      const timer = type === "min" ? totalTime * 60 : totalTime;
      setCountDown(timer);
      const id = setInterval(() => {
        setCountDown((countDown) => {
          if (countDown < 1) {
            clearInterval(id);
            setAlert(true);
            return countDown;
          } else {
            return countDown - 1;
          }
        });
      }, 1000);
    }
  }, []);
  const alertHandler = () => {
    setAlert(false);
    !isPreview && autoSubmit && onSubmit && onSubmit({ isTimeExpire: true });
  };

  const mm = parseInt("" + countDown / 60);
  const ss = parseInt("" + (countDown % 60));
  const timer = `${mm > 9 ? mm : "0" + mm}:${ss > 9 ? ss : "0" + ss}`;
  return value && Number(value) > 0 ? (
    <div className="time">
      <AccessTimeFilledIcon /> Takes<span>{timer}</span>
      {`${countDown > 59 ? "min" : "sec"}`}
      <Alert
        show={showAlert}
        btnLabel={"OK"}
        singleBtn={true}
        message={"Time is over."}
        handler={alertHandler}
      />
    </div>
  ) : null;
};

const Body = (props) => {
  const { quesData, survey = {}, desktopView, sliderView, isLoading } = props;
  const { page_logo = "", page_info = {} } = survey;
  const [isSuccess, setSuccess] = useState(false);
  const [showTimer, setTimer] = useState(false);
  const { loading, success } = useSelector((state) => state.addData);
  const qRefs = useRef([]);

  useEffect(() => {
    setSuccess(success);
  }, [success]);

  const getTheme = () => {
    const key = desktopView ? "web" : "tablet";
    const thm = SURVEY_BG_THEME[key][survey.survey_bg_theme];
    if (survey.survey_bg_theme.includes("base64")) {
      return survey.survey_bg_theme;
    }
    return origin + "/images/themes/" + thm;
  };
  const themeStyle = survey?.survey_bg_theme?.length
    ? { backgroundImage: `url(${getTheme()})` }
    : {};
  const showHeader =
    survey?.format === "Classic" ||
    (survey?.format === "SingleQuestion" && !page_info?.showIntroOnce);
  const showFooter =
    survey?.format === "Classic" ||
    (survey?.format === "SingleQuestion" && page_info?.repeatFooter);
  return (
    <div
      className={`mainBodyPre ${desktopView ? "desktop" : "mobile"}`}
      style={themeStyle}
    >
      {showHeader &&
      (page_logo?.length ||
        page_info?.title?.length ||
        page_info?.intro?.length) ? (
        <div className="bodyHead">
          <div className="logoTitleTime">
            {page_logo?.length ? (
              <div className="logo">
                <img src={page_logo} alt="Default Logo" />
              </div>
            ) : null}
            {page_info?.title?.length ? (
              <div className="title">{page_info.title}</div>
            ) : null}
            <Timer {...props} />
          </div>
          {page_info?.intro?.length ? (
            <div className="description"> {page_info.intro || ""} </div>
          ) : null}
        </div>
      ) : null}
      {!showHeader && showTimer && Number(survey?.estimated_time?.value) > 0 ? (
        <div className="bodyHead" style={{ background: "unset" }}>
          <div className="logoTitleTime">
            <Timer {...props} />
          </div>
        </div>
      ) : null}
      <div
        className={`bodyInner previewBodyInner ${
          sliderView ? "sliderWrap" : ""
        }`}
      >
        {sliderView ? (
          <Slider
            {...props}
            showIntroCard={!showHeader}
            showSubmitCard={!showFooter && page_info?.footer?.trim().length}
            setTimer={setTimer}
          />
        ) : (
          <QuestionCards {...props} qRefs={qRefs} />
        )}
      </div>

      {showFooter ? (
        <div className="bodyFooter">
          {sliderView ? null : (
            <button className="btn submit" onClick={props.onSubmit}>
              Submit
            </button>
          )}
          {page_info?.footer?.length ? (
            <div className="footerContent">
              {" "}
              <p>{page_info.footer}</p>{" "}
            </div>
          ) : null}
        </div>
      ) : null}

      <Backdrop show={isLoading || loading} />
      {isSuccess && alert("submitted")}
    </div>
  );
};

const PreviewDialog = (props) => {
  const {
    surveyID,
    show,
    handleClose,
    isPreview,
    onChange,
    onSubmit,
    submitted = false,
    inline = false,
    responseBy,
    userAddress,
    setRawData,
    error,
    setError,
    setDemo,
  } = props;
  const { loading, res = {} } = useSelector(
    (state) => state[isPreview ? "wsDetailsAuth" : "wsDetails"]
  );
  const [visitorId, setVisitorId] = useState("");
  const [trackedIDs, setTrackedID] = useState([]);
  const [apiCalled, setApiCalled] = useState(true);
  const dispatch = useDispatch();
  const [answerData, setAnswerData] = useState({});
  const [data, setData] = useState([]);
  const [linkedData, setLinkData] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [link, setLink] = useState([]);
  const [surveyDetail, setSurveyDetail] = useState({});
  const [desktopView, setDesktopView] = useState(true);

  useEffect(() => {
    //surveyID && dispatch(workspaceActions.getWsDetail(surveyID));
  }, [surveyID, show]);

  useEffect(() => {
    if (submitted) {
      createEventsTracker("survey submitted");
      if (responseBy.email.length || responseBy.group.length) {
        dispatch(
          emailActions.updatePersonResponse(
            surveyID,
            responseBy.email.length ? responseBy.email : responseBy.group,
            submitted
          )
        );
      }
    }
  }, [submitted]);

  useEffect(() => {
    if (!loading && res?.id) {
      window["answeredData"] = null;
      const {
        survey_title,
        quest_desc = {},
        survey_bg_theme,
        estimated_time,
        stop,
        is_demo,
      } = res || {};
      const {
        detail_id,
        format = "",
        page_logo = "",
        page_info = {},
        questions_list = [],
        links = [],
      } = quest_desc || {};
      setSurveyDetail({
        detail_id,
        survey_title,
        page_logo,
        page_info,
        format,
        survey_bg_theme,
        estimated_time,
      });
      setDemo && setDemo(is_demo);
      let decoratorVar = [];
      let newDecoratorVar = [];
      questions_list &&
        questions_list.map((item, index) => {
          if (item.q_type == "Decorator") {
            decoratorVar.push(item);
          } else {
            newDecoratorVar.push(item);
          }
        });
      if (decoratorVar.length) {
        let classDecorator = decoratorVar;
        classDecorator[0].initialDecorator = true;
        newDecoratorVar.push(decoratorVar[0]);
        newDecoratorVar.unshift(classDecorator[0]);
      }
      setData(newDecoratorVar || []);
      setQuestionList(questions_list || []);
      setLink(links || []);
      setRawData && setRawData(questions_list || []);
    }
  }, [res]);

  useEffect(() => {
    if (!isPreview && surveyID && apiCalled && !loading && res && res.id) {
      workspaceService
        .getTrackEventsLastVisitorID({ detail_id: surveyID, _limit: 2 })
        .then((response) => {
          const eventName = res?.stop ? "survey stopped" : "survey started";
          if (response.status === 200) {
            let vID = response.data["visitor_id"];
            const newVisitorId = vID + 1;
            newVisitorId != 0 && createEventsTracker(eventName, newVisitorId);
            setApiCalled(false);
            setTimeout(() => setVisitorId(vID), 100);
          }
        });
    }
  }, [surveyID, apiCalled, visitorId, loading, res]);

  useEffect(() => {
    if (link && link.length) {
      pageLogic();
    }
  }, [link, answerData]);

  const matchAnsOption = (opt, ans = []) => {
    const pos = ans.findIndex((f) => opt.includes(f.text));
    return pos > -1;
  };

  const pageLogic = () => {
    let qObj = {};
    let cloned = clone(linkedData.length ? linkedData : data);
    cloned=cloned.map((x,index)=>{
     if(x.is_payment!==true){
       x.qus_id = index + 1 
       if(x.question == "Payment"){
         x.question = `${x.question} ${x.paymentGroup}`
       }
     return x
 }
}).filter((newVal)=> !!newVal)
    cloned.forEach((d) => {
      delete d.isHidden;
      qObj[d.question] = { ...d, ["seq_no"]: d.qus_id };
    });

    const unhideQues = { [link[0].input]: true };
    const sortedLink = link.sort((a, b) => a.data.index - b.data.index);

    for (let i = 0; i < sortedLink.length; i++) {
      const l = sortedLink[i];
      if (
        !qObj[l.input]["isHidden"] &&
        (String(l.data.optArr[0]).toLowerCase() === "next question" ||
          matchAnsOption(l?.data?.optArr, answerData[l.input]))
      ) {
        qObj[l.output]["isHidden"] = false;
        qObj[l.output]["seq_no"] = l.data.index + 1;
        unhideQues[[l.output]] = true;
      } else {
        if (!unhideQues[[l.output]]) {
          qObj[l.output]["isHidden"] = true;
        }
      }
    }
    const arr = Object.values(qObj);
    const sortedArr = arr.sort((a, b) => a.seq_no - b.seq_no);
    setLinkData(arr);
    setData(sortedArr);
    setRawData && setRawData(arr || []);
  };

  const createEventsTracker = (trigger, id) => {
    const params = {
      detail_id: surveyID,
      visitor_id: id || visitorId + 1,
      last_click: trigger,
      user_details: { ...responseBy, address: userAddress },
    };
    workspaceService.createTrackEvents(params).then((res) => {
      if (res.status === 200) {
        trigger !== "survey started" && setTrackedID(trigger);
      }
    });
  };

  const onHandleDesktopView = () => {
      setDesktopView(true);
    },
    onHandleMobileView = () => {
      setDesktopView(false);
    };

  const triggerOnFocus = (index) => {
    const { qus_id, question } = data[index];
    if (!isPreview) {
      if (trackedIDs.indexOf(String(qus_id)) < 0 && qus_id) {
        createEventsTracker(String(qus_id));
      } else {
        console.log("Question id " + qus_id + " already tracked.");
      }
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e;
    const ansData = {
      ...answerData,
      [value.qus_text]: value.answers,
    };
    setAnswerData(ansData);
    window["answeredData"] = ansData;
    onChange && onChange(e);
  };

  const Head = () => {
    return (
      <div className="headerPre">
        <div className="leftSide">
          <div className="arrow1" onClick={handleClose}>
            <img src={origin + "/images/SVG/left-arrow.svg"} alt="arrow icon" />
          </div>
          {/* <div className="logoHead">
                 <img src={origin+"/images/logo.png"} alt="logo icon"/>
                 </div>*/}
        </div>
        <h2>{isPreview ? "Final Preview" : surveyDetail.survey_title}</h2>
        {handleClose ? (
          <div className="cancelIcon" onClick={handleClose}>
            <CancelIcon />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const commonComp = useMemo(() => {
    return (
      <>
        {isPreview ? <Head /> : null}
        {res?.stop && !isPreview ? (
          <ThanksCard stop={true} greeting={false} />
        ) : submitted ? (
          <ThanksCard />
        ) : (
          <Body
            userAddress={userAddress}
            responseBy={responseBy}
            visitorId={visitorId}
            surveyId={surveyID}
            isLoading={loading}
            sliderView={surveyDetail?.format === "SingleQuestion"}
            quesData={data}
            links={link}
            survey={surveyDetail}
            desktopView={desktopView}
            onChange={onInputChange}
            onSubmit={onSubmit}
            onFocus={triggerOnFocus}
            isPreview={isPreview}
          />
        )}

        {isPreview ? (
          <div className="footerPre">
            <div className="btnGroup">
              <div
                className={`btn ${desktopView && "btnDefault"}`}
                onClick={onHandleDesktopView}
              >
                Desktop
              </div>
              <div
                className={`btn ${!desktopView && "btnDefault"}`}
                onClick={onHandleMobileView}
              >
                Mobile
              </div>
            </div>
          </div>
        ) : null}
        <Alert
          show={!!error?.length}
          singleBtn={true}
          btnLabel="Ok"
          message={error}
          handler={() => setError("")}
        />
      </>
    );
  });

  return inline ? (
    <div className={"previewDialog"}>
      {" "}
      <div className={"previewDialogInner"}> {commonComp}</div>{" "}
    </div>
  ) : (
    <Dialogbox
      open={show}
      //   rootClass={"previewDialog"}
      rootClass={`previewDialog previewThankyou ${
        props.previewFull ? "previewDialogFull" : ""
      }`}
      handleClose={handleClose}
      dialogContentClass={"previewDialogInner"}
    >
      {commonComp}
    </Dialogbox>
  );
};

const ThanksCard = ({ greeting = true, stop = false }) => {
  const { loading, res = {} } = useSelector((state) => state["wsDetails"]);

  return (
    <div className="previewContainer">
      <div className="previewInfo">
        <svg
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="CheckCircleOutlineRoundedIcon"
        >
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.88-11.71L10 14.17l-1.88-1.88a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7c.39-.39.39-1.02 0-1.41-.39-.39-1.03-.39-1.42 0z"></path>
        </svg>
        {greeting ? (
          <h2>
            {res &&
            res.quest_desc &&
            res.quest_desc.page_info &&
            res.quest_desc.page_info.thankYou
              ? res.quest_desc.page_info.thankYou
              : "Thank You"}
          </h2>
        ) : null}
        {stop ? (
          <>
            <p>Thanks for helping us with your feedback.</p>
            <p>At this moment we are not collecting any more response.</p>
          </>
        ) : (
          <p>Survey has been submitted.</p>
        )}
      </div>
    </div>
  );
};

const Preview = (props) => {
  const { surveyID, inline = false } = props;
  const [show, setDialog] = useState(false);

  const handleClose = () => {
      setDialog(false);
    },
    onHandleOpen = () => {
      setDialog(!show);
    };
  return (
    <>
      <Tooltip title="Preview">
        <RemoveRedEyeIcon onClick={onHandleOpen} />
      </Tooltip>
      <PreviewDialog
        show={show}
        handleClose={handleClose}
        surveyID={surveyID}
        isPreview={true}
        inline={inline}
      />
    </>
  );
};

export { PreviewDialog, Preview };
