import React, {useRef} from 'react';
import cn from 'classnames';
import Input from "../../../common/controls/inputs/Input";
import Label from "../../../common/controls/inputs/Label";
import FontPicker from "font-picker-react";
import { SketchPicker } from 'react-color';
import { useOutsideAlerter } from "../../Common/Header";

const TextEditor = (props) => {
    const {handleNumberField, onChange, font, fontSize, bold, italic, underline, fontColor, onColorChange} = props;
    const [isColorPlate, setColorPlate] = React.useState({show: false});
    const clrRef = useRef();
    useOutsideAlerter(clrRef, setColorPlate);
    const onChangeColor = (color) => {
        onColorChange && onColorChange(color);
    };

    return <div className="textPropertyWrap" >
        <Label config={{ label: 'Text Property', className: 'apperanceTextHeading' }} />

        <div className="fontPickSize">
            <FontPicker apiKey="AIzaSyDGg_Tk8-veCYdZw3XF5652c1JNREUnRzM"
                        activeFontFamily={font}
                        onChange={(nextFont) => onChange({ target: { value: nextFont.family, name: 'font' } })} />
            <div className="fontSizeBox">
                <div className="symbol" onClick={() => handleNumberField('-')}>-</div>
                <Input config={{ name: 'fontSize', type: 'text', className: 'fontSizeInputBox', inputClass: 'fontSizeInput' }} readOnly={true} value={fontSize} />
                <div className="symbol" style={{ left: '85px' }} onClick={() => handleNumberField('+')}>+</div>
            </div>
        </div>

        <div className="fontStyle">
            <div className={cn("boldImg")} onClick={() => onChange({ target: { name: 'bold', value: !bold } })}>
                <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="24" height="24" rx="2" fill={bold ? "#18A0FB" : "#F2F3F7"} fillOpacity={bold ? 0.2 : 1} />
                    <path d="M14.92 11.8C15.7067 11.9467 16.3533 12.34 16.86 12.98C17.3667 13.62 17.62 14.3533 17.62 15.18C17.62 15.9267 17.4333 16.5867 17.06 17.16C16.7 17.72 16.1733 18.16 15.48 18.48C14.7867 18.8 13.9667 18.96 13.02 18.96H7V5H12.76C13.7067 5 14.52 5.15333 15.2 5.46C15.8933 5.76667 16.4133 6.19333 16.76 6.74C17.12 7.28667 17.3 7.90667 17.3 8.6C17.3 9.41333 17.08 10.0933 16.64 10.64C16.2133 11.1867 15.64 11.5733 14.92 11.8ZM9.8 10.76H12.36C13.0267 10.76 13.54 10.6133 13.9 10.32C14.26 10.0133 14.44 9.58 14.44 9.02C14.44 8.46 14.26 8.02667 13.9 7.72C13.54 7.41333 13.0267 7.26 12.36 7.26H9.8V10.76ZM12.62 16.68C13.3 16.68 13.8267 16.52 14.2 16.2C14.5867 15.88 14.78 15.4267 14.78 14.84C14.78 14.24 14.58 13.7733 14.18 13.44C13.78 13.0933 13.24 12.92 12.56 12.92H9.8V16.68H12.62Z" fill={bold ? "#18A0FB" : "#AEB6CF"} />
                </svg>
            </div>
            <div className={cn("italicImg")} onClick={() => onChange({ target: { name: 'italic', value: !italic } })}>
                <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="24" height="24" rx="2" fill={italic ? "#18A0FB" : "#F2F3F7"} fillOpacity={italic ? 0.2 : 1} />
                    <path d="M8 19.102C8.044 18.8087 8.10267 18.5813 8.176 18.42C8.264 18.244 8.36667 18.1413 8.484 18.112L9.958 17.65L9.562 18.068L11.454 6.1L11.762 6.496L10.068 6.078C10.0973 5.84333 10.1487 5.64533 10.222 5.484C10.31 5.32267 10.4493 5.16133 10.64 5H15.348C15.304 5.29333 15.238 5.528 15.15 5.704C15.0767 5.86533 14.9813 5.96067 14.864 5.99L13.236 6.452L13.742 6.012L11.85 18.024L11.476 17.606L13.28 18.024C13.236 18.2587 13.1773 18.4567 13.104 18.618C13.0307 18.7647 12.906 18.926 12.73 19.102H8Z" fill={italic ? "#18A0FB" : "#AEB6CF"} />
                </svg>
            </div>
            <div className={cn("underlineImg")} onClick={() => onChange({ target: { name: 'underline', value: !underline } })}>
                <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="24" height="24" rx="2" fill={underline ? "#18A0FB" : "#F2F3F7"} fillOpacity={underline ? 0.2 : 1} />
                    <path d="M8.988 4V11.938C8.988 13.054 9.258 13.882 9.798 14.422C10.35 14.962 11.112 15.232 12.084 15.232C13.044 15.232 13.794 14.962 14.334 14.422C14.886 13.882 15.162 13.054 15.162 11.938V4H16.8V11.92C16.8 12.964 16.59 13.846 16.17 14.566C15.75 15.274 15.18 15.802 14.46 16.15C13.752 16.498 12.954 16.672 12.066 16.672C11.178 16.672 10.374 16.498 9.654 16.15C8.946 15.802 8.382 15.274 7.962 14.566C7.554 13.846 7.35 12.964 7.35 11.92V4H8.988Z" fill={underline ? "#18A0FB" : "#AEB6CF"} />
                    <path d="M6 18.346H18.15V19.246H6V18.346Z" fill={underline ? "#18A0FB" : "#AEB6CF"} />
                </svg>
            </div>
            <div className={cn("textcolorImg")} onClick={() => !(isColorPlate?.show) && setColorPlate({show:true})}>
                <div ref={clrRef}>
                <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="24" height="24" rx="2" fill={fontColor} fillOpacity="0.2" />
                    <path d="M14.536 13.056H10.088L9.352 15.184H7L11.016 4H13.624L17.64 15.184H15.272L14.536 13.056ZM13.928 11.264L12.312 6.592L10.696 11.264H13.928Z" fill={fontColor} />
                    <path d="M19.1111 19H4.88885C4.39822 19 4 18.553 4 18C4 17.447 4.39822 17 4.88885 17H19.1111C19.6027 17 20 17.447 20 18C20 18.553 19.6027 19 19.1111 19Z" fill={fontColor} />
                </svg>
                {isColorPlate?.show && <SketchPicker className="colorPicker" color={fontColor} onChangeComplete={onChangeColor}/>}
                </div>
            </div>
        </div>
    </div>
};
export default  TextEditor;