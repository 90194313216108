import React, { useEffect, useState } from "react";
import { checkEmail } from "../../../Common/Utils";
import { useParams } from "react-router-dom";
import axios from "axios";
import { commonService } from "../../../../services/CommonService";

const Help = (props) => {
  const {
    layout,
    html,
    json,
    children,
    qus_id,
    question,
    desc = "",
    qno = 1,
    settings = {},
    cardRef,
    quesType,
    onChange,
    qRefs,
    submit,
    btnText = "Next",
    sliderView,
    visitorId,
    surveyId,
    responseBy,
    userAddress,
    onFocus,
    showSubmitBtn,
  } = props;
  const { font, underline, bold, fontSize, italic, fontColor, require } =
      settings,
    style = {
      fontFamily: font,
      fontSize,
      fontWeight: bold ? "bold" : "",
      fontStyle: italic ? "italic" : "",
      textDecoration: underline ? "underline" : "",
      color: fontColor,
    };
  const { id } = useParams();
  const apiUrl = commonService.apiUrl;
  //console.log("sdksdksd", html, props);
  const [ans, setAns] = useState("");
  const [err, setErr] = useState("");

  useEffect(() => {
    const elements = document.getElementsByName("question_" + qno);
    let ele = elements.length ? elements[0] : elements;
    ele.style.setProperty("font-family", font, "important");
  }, [font]);

  const onNext = () => {
    if (btnText === "Next") {
      cardRef && cardRef.current.next();
      qRefs?.current?.length >= qno &&
        setTimeout(() => {
          qRefs.current[qno]?.focus();
        }, 10);
    } else {
      submit && submit();
    }
  };

  const onBlur = () => {
    const _ans = ans.trim();
    if (require && _ans === "") {
      setAns(_ans);
      setErr("This is required.");
      return;
    }

    const isValid = checkEmail(_ans);
    let obj = {
      qus_id: qus_id,
      q_type: quesType,
      qus_text: question,
      answers: [{ text: _ans }],
    };
    if (isValid) {
      onChange && onChange({ name: qno, value: obj });
    } else {
      if (_ans.length) {
        setAns(_ans);
        setErr("Enter a valid email");
        onChange &&
          onChange({ name: qno, value: obj, error: "Enter a valid email" });
        return;
      }
    }
    setAns(_ans);
    setErr("");
  };

  const triggerFocus = () => {
    onFocus && qno > 0 && onFocus(qno - 1);
  };

  return (
    <div className="imageWrapInner">
      <div className="ques">
        <h3 name={"question_" + qno} style={style}>
          {question}
          <span
            style={{
              color: "red",
              marginLeft: "5px",
              display: require ? "unset" : "none",
            }}
          >
            *
          </span>
        </h3>
        <em> {desc} </em>
      </div>
      {layout == 1 && children}
      <div
          onMouseOver={(e) => {
            console.log("hover", e.target.textContent, e.target.parentNode);
            if (
              e?.target?.parentNode?.classList?.value
                ?.toLowerCase()
                .includes("link")
            ) {
              try {
                const result = axios.post(`${apiUrl}/createTrackEvents`, {
                  params: {
                    detail_id: surveyId,
                    visitor_id: visitorId,
                    last_click: props.qus_id,
                    label: e.target.textContent,

                    user_details: {
                      ...responseBy,
                      address: userAddress,
                    },
                  },
                });
              } catch (err) {
                console.log(err);
              }
            }
          }}
          onClick={(e) => {
            if (
              e?.target?.parentNode?.classList?.value
                .toLowerCase()
                .includes("collapsible") ||
              e?.target?.parentNode?.parentNode?.classList?.value
                .toLowerCase()
                .includes("collapsible") ||
              e?.target?.parentNode?.parentNode?.nodeName
                ?.toLowerCase()
                .includes("details") ||
              e?.target?.parentNode?.nodeName
                ?.toLowerCase()
                .includes("details") ||
              e?.target?.parentNode?.parentNode?.getAttribute(
                "data-lexical-collapsible-content"
              ) ||
              e?.target?.parentNode?.getAttribute(
                "data-lexical-collapsible-content"
              )
            ) {
              try {
                const result = axios.post(`${apiUrl}/createTrackEvents`, {
                  params: {
                    detail_id: surveyId,
                    visitor_id: visitorId,
                    last_click: props.qus_id,
                    label: e.target.textContent,

                    user_details: { ...responseBy, address: userAddress },
                  },
                });
              } catch (err) {
                console.log(err);
              }
            }
          }}
          dangerouslySetInnerHTML={{
            __html: html.replaceAll('open="false"', ""),
          }}
        ></div>
      {err.length ? (
        <div>
          {" "}
          <p style={{ color: "red" }}>{err}</p>
        </div>
      ) : (
        <div>
          <p>&nbsp;</p>
        </div>
      )}

      {sliderView ? (
        btnText === "Submit" && !showSubmitBtn ? null : (
          <div className="btnGroup">
            <button className="btnSave" onClick={onNext}>
              {btnText}
            </button>
          </div>
        )
      ) : null}
    </div>
  );
};

export default Help;
