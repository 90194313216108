import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { teamsAction } from "../../../actions/teamsAction";
import { AdminService } from "../../../services/AdminService";

const TeamCreationForm = ({ onCancel, teamData, teamEdit }) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    member_id: [],
  });
  const [userData, setUserData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUsers = async () => {
      const data = await AdminService.getUsers();
      setUserData(data?.data);
    };
    fetchUsers();
  }, []);


  const membersList = [
    { id: 1, name: "John Doe" },
    { id: 2, name: "Jane Smith" },
    { id: 3, name: "Alice Brown" },
    { id: 4, name: "Bob White" },
    { id: 5, name: "Charlie Green" },
  ];

  // Initialize formData when editing
  useEffect(() => {
    if (teamEdit && teamData) {
      setFormData({
        team_name: teamData.team_name || "",
        member_id: teamData.member_id || [],
        domain_name: teamData.domain_name || "",
        organization_name: teamData.organization_name || "",
        contact_email: teamData.contact_email || "",
        contact_person: teamData.contact_person || "",
        contact_phone: teamData.contact_phone,
        team_code: teamData.team_code,
      });
    }
  }, [teamEdit, teamData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Populate formData.member_id with team members' IDs when teamdata is available
  useEffect(() => {
    if (teamData?.members) {
      setFormData((prev) => ({
        ...prev,
        member_id: teamData.members.map((member) => member.id),
      }));
    }
  }, [teamData]);

  const handleMemberToggle = (id) => {
    setFormData((prev) => ({
      ...prev,
      member_id: prev.member_id.includes(id)
        ? prev.member_id.filter((memberId) => memberId !== id)
        : [...prev.member_id, id],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedFormData = {
      ...formData,
      members: formData.member_id, // Rename `member_id` to `members`
    };
    delete updatedFormData.member_id;

    if (teamEdit) {
      dispatch(teamsAction.updateTeam(updatedFormData, teamData.id));
    } else {
      dispatch(teamsAction.addTeam(formData));
    }

    onCancel(); // Close the form
  };

  return (
    <Paper elevation={3} style={{ padding: "20px", borderRadius: "10px" }}>
      <Typography variant="h6" gutterBottom>
        {teamEdit ? "Edit Team" : "Create New Team"}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {/* Left Section */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              {/* Left-aligned inputs */}
              <Grid item xs={6}>
                <TextField
                  label="Team Name"
                  name="team_name"
                  value={formData.team_name}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Organization Name"
                  name="organization_name"
                  value={formData.organization_name}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Domain Name"
                  name="domain_name"
                  value={formData.domain_name}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Email"
                  name="contact_email"
                  value={formData.contact_email}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>

              {/* Right-aligned inputs */}
              <Grid item xs={6}>
                <TextField
                  label="Contact Person"
                  name="contact_person"
                  value={formData.contact_person}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Phone"
                  name="contact_phone"
                  type="tel"
                  value={formData.contact_phone}
                  onChange={handleChange}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  }}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Team Code"
                  name="team_code"
                  value={formData.team_code}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Right Section */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                border: "1px solid #ccc",
                borderRadius: "10px",
                padding: "10px",
                maxHeight: "340px",
                overflowY: "auto",
              }}
            >
              <Typography variant="subtitle1" gutterBottom>
                Members
              </Typography>
              {/* <FormGroup>
                {userData.map((member) => (
                  <FormControlLabel
                    key={member.id}
                    control={
                      <Checkbox
                        checked={formData.member_id.includes(member.id)}
                        onChange={() => handleMemberToggle(member.id)}
                      />
                    }
                    label={member.username}
                  />
                ))}
              </FormGroup> */}

              <FormGroup>
                {userData
                  .filter((member) => formData.member_id.includes(member.id)) // Filter checked members
                  .map((member) => (
                    <FormControlLabel
                      key={member.id}
                      control={
                        <Checkbox
                          checked={true} // Since we're already filtering only checked ones
                          onChange={() => handleMemberToggle(member.id)}
                        />
                      }
                      label={member.username}
                    />
                  ))}
              </FormGroup>

            </Box>
          </Grid>
        </Grid>
 

        {/* Submit and Cancel Buttons */}
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item>
            <Button type="submit" variant="contained" color="primary">
              {teamEdit ? "Update" : "Submit"}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

TeamCreationForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  teamData: PropTypes.object,
  teamEdit: PropTypes.bool,
};

export default TeamCreationForm;
