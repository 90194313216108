import React, { useState, useEffect, useRef } from "react";
import { MaterialSelect } from "../../../common/controls/inputs/Material";
import { clone, uploadFileToS3 } from "../../Common/Utils";
import PictureGrid from "../../Survey/Components/DnD/index";
import TextArea from "../../../common/controls/inputs/TextArea";
import CancelIcon from "@mui/icons-material/Cancel";
import { Card } from "antd";
import { base64Image } from "../../Common/Utils";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import Prompt from "../../Common/ConfirmationPrompt";

import "./Layouts.css";
import { NavItem } from "react-bootstrap";

const origin = window.location.origin;
const imageSize = 1000000;
const TypeCardChoice = ({
  quesNo,
  quesType,
  setQuesType,
  value,
  onChange,
  onSave,
  ansTypes,
  children,
}) => {
  const {
    question = "",
    desc = "",
    settings = {},
    if_pictures_type = [],
  } = value;
  const {
      font,
      underline,
      bold,
      fontSize,
      italic,
      fontColor,
      layout,
      supersize,
      picturealign,
    } = settings,
    style = {
      fontFamily: font,
      fontSize,
      fontWeight: bold ? "bold" : "",
      fontStyle: italic ? "italic" : "",
      textDecoration: underline ? "underline" : "",
      color: fontColor,
    };
  const [imgData, setImgData] = useState(if_pictures_type);
  const [data, setData] = useState({ question, desc });
  const [err, setErr] = useState();
  const [alertMsg, setAlertMsg] = useState("");
  const [dialogValue, setDialogValue] = useState(if_pictures_type || "");
  const [img, setImg] = useState(dialogValue);
  const [newCard, setNewCard] = useState([
    { label: "", picture: "", id: 1, desc: "", name: "" },
  ]);
  // useEffect(() => {
  //   const elements = document.getElementsByName("question");
  //   let ele = elements.length ? elements[0] : elements;
  //   ele.style.setProperty("font-family", font || "Poppins", "important");
  // }, [font]);
  // useEffect(()=> {
  //   setDialogValue(if_pictures_type || '');
  //   setImg(if_pictures_type || '');
  // }, [if_pictures_type]);
  useEffect(() => {
    //console.log(if_pictures_type,"[{label:'' , picture:'', id:1,desc:'',name:''}]")
    if (if_pictures_type.length) {
      setNewCard(if_pictures_type);
    }
  }, []);
  //   useEffect(()=> {
  //     if(!value.hasOwnProperty('ans_options')){
  //         const {settings={}} = value;
  //         onChange({name:'ans_options', value: [{label:'', id:1}] });
  //         console.log("asdasdas",value)
  //     }
  // }, []);

  const beforeOnSave = () => {
    onSave && onSave();
  };
  const getImgData = (file, index) => {
    uploadFileToS3(file, (result) => {
      const clonedImgData = clone(imgData) || [];
      if (index > -1) {
        clonedImgData[index] = { base64: result, id: index + 1, label: "" };
      } else {
        clonedImgData.push({
          base64: result,
          id: clonedImgData.length + 1,
          label: "",
        });
      }
      setImgData(clonedImgData);
      onChange({ name: "if_pictures_type", value: clonedImgData });
    });
  };
  const deleteCard = (id, index) => {
    if (newCard) {
      const cloned = clone(newCard);
      cloned.splice(index, 1);
      setNewCard(cloned);
    }
  };
  const addCard = (e) => {
    const cloned = clone(newCard);
    cloned.push({
      label: "",
      picture: "",
      id: cloned.length + 1,
      desc: "",
      name: "",
    });
    setNewCard(cloned);
  };

  const updatePictureDetail = (e, index) => {
    if (e && e.target) {
      const clonedImgData = clone(imgData);
      clonedImgData[index][e.target.name] = e.target.value;
      setImgData(clonedImgData);
      onChange({ name: "if_pictures_type", value: clonedImgData });
    }
  };

  const onSelect = (e, index = -1) => {
    const ImageSize = 200000; //200kb
    if (e?.target?.files?.length) {
      const file = e.target.files[0];
      console.log(file);
      if (file?.size <= ImageSize) {
        getImgData(file, index);
      } else {
        setAlertMsg("Image size should be less than or equal to 200KB");
      }
    }
  };

  const onBlur = (e, index) => {
    if (e?.target) {
      e.target.value = e.target.value.trim();
      (e.target.name === "name" || e.target.name === "desc") &&
        handleChange(e, index);
      onChange(e.target);
    }
  };

  const handleChange = (e, index) => {
    if (e && e.target) {
      const cloned = clone(newCard);
      cloned[index][e.target.name] = e.target.value;
      setNewCard(cloned);
      //setData((data) => ({ ...data, [e.target.name]: e.target.value }));
    }
  };

  const GridItem = ({ item, index }) => {
    const [details, setDetails] = useState(item),
      editLabel = (e) => {
        if (e && e.target) {
          const cloned = clone(details);
          cloned[e.target.name] = e.target.value;
          setDetails(cloned);
        }
      };

    const blur = (e) => {
      updatePictureDetail(e, index);
    };

    const onCancel = () => {
      setImgData((imgData) => {
        imgData?.length && imgData.splice(index, 1);
        onChange({ name: "if_pictures_type", value: imgData });
        return imgData;
      });
    };

    return (
      <div
        key={"img_" + index}
        className={`imageUploadCard uploaded ${supersize ? "superSize" : ""}`}
      >
        <div className="cancel" onClick={onCancel}>
          <CancelIcon />
        </div>
        <div className="inner">
          <img src={item.base64} alt="arrow icon" />
          <AddPhotoAlternateIcon />
          <input
            type="file"
            id="img"
            name="img"
            accept="image/*"
            onChange={(e) => onSelect(e, index)}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>{String.fromCharCode(65 + index)}</div>
          {settings.show_label && (
            <input
              type="text"
              name="label"
              placeholder={"Type label"}
              value={details.label || ""}
              onChange={editLabel}
              onBlur={blur}
            />
          )}
        </div>
      </div>
    );
  };

  const StaticItem = (props) => {
    if (if_pictures_type.length) {
      return <div></div>;
    } else {
      return (
        <div className="imageUploadCard" style={{ height: 110, width: 100 }}>
          <div className="inner">
            <AddPhotoAlternateIcon />
            <input
              type="file"
              id="img"
              name="img"
              accept="image/*"
              onChange={onSelect}
            />
          </div>
        </div>
      );
    }
  };

  const CardComponent = ({ item, index }) => {
    const [cardname, setCardName] = useState(item["name"] || "");
    const [carddesc, setCardDesc] = useState(item["desc"] || "");
    const [cardimage, setCardImage] = useState(item["picture" || ""]);

    const onCardBlur = (e) => {
      if (e && e.target) {
        const cloned = clone(newCard);
        cloned[index][e.target.name] = e.target.value;
        setNewCard(cloned);
        onChange({ name: "if_pictures_type", value: cloned });
      }
    };

    const onChangeHandle = (e) => {
      if (e?.target?.files?.length) {
        const file = e.target.files[0];
        if (
          file.type.includes("jpeg") ||
          file.type.includes("png") ||
          file.type.includes("jpg")
        ) {
          if (file.size <= imageSize) {
            setErr(false);
            base64Image(file, (result) => {
              setCardImage(result);
              const cloned = clone(newCard);
              cloned[index][e.target.name] = result;
              setNewCard(cloned);
              onChange({ name: "if_pictures_type", value: cloned });
            });
          } else {
            setErr("Logo size should be less than or equal to 1MB");
          }
        } else {
          setErr("Uploaded file should be jpg or png");
        }
      }
    };
    useEffect(() => {}, [item]);
    return (
      <Card key={item.id}>
        {index !== 0 && (
          <div
            className="cancel"
            onClick={() => deleteCard(item.id, index, value)}
          >
            <CancelIcon />
          </div>
        )}
        {settings && settings.align == "bottom" ? (
          <div>
            <div className={"questionWrap"}>
              <div className="question">
                <span className={"num"} style={style}>
                  {item.id + "- "}
                </span>
                <TextArea
                  config={{
                    name: "name",
                    inputClass: "questionInputArea",
                    placeholder: "Name of card",
                    textAreaStyle: style,
                  }}
                  onBlur={onCardBlur}
                  value={cardname}
                  onChange={(e) => setCardName(e.target.value)}
                />
              </div>
            </div>

            <TextArea
            config={{
              name: "desc",
              inputClass: "questionInputArea",
              placeholder: "Text of card (optional)",
              textAreaStyle: style,
            }}
            onBlur={onCardBlur}
            value={carddesc}
            onChange={(e) => setCardDesc(e.target.value)}
          />
            {settings && settings.removepic ? (
              " "
            ) : (
              <div className="imageUploadWrap imageUploadItems">
                <div className="fileUploadInner">
                  <label className="imgWrapper">
                    <input
                      type="file"
                      name="picture"
                      onChange={onChangeHandle}
                      accept={"image/x-png, image/jpeg"}
                    />
                    <img
                      src={
                        cardimage.length
                          ? cardimage
                          : origin + "/images/SVG/upload.svg"
                      }
                      alt="upload icon"
                    />
                  </label>
                </div>
              </div>
            )}
          </div>
        ) : settings.align == "top" ? (
          <div>
            {" "}
            <div className={"questionWrap"}>
              {settings && settings.removepic ? (
                " "
              ) : (
                <div className="imageUploadWrap imageUploadItems">
                  <div className="fileUploadInner">
                    <label className="imgWrapper">
                      <input
                        type="file"
                        name="picture"
                        onChange={onChangeHandle}
                        accept={"image/x-png, image/jpeg"}
                      />
                      <img
                        src={
                          cardimage.length
                            ? cardimage
                            : origin + "/images/SVG/upload.svg"
                        }
                        alt="upload icon"
                      />
                    </label>
                  </div>
                </div>
              )}
              <div className="question">
                <span className={"num"} style={style}>
                  {item.id + "- "}
                </span>
                <TextArea
                  config={{
                    name: "name",
                    inputClass: "questionInputArea",
                    placeholder: "Name of card",
                    textAreaStyle: style,
                  }}
                  onBlur={onCardBlur}
                  value={cardname}
                  onChange={(e) => setCardName(e.target.value)}
                />
              </div>
            </div>
            <TextArea
            config={{
              name: "desc",
              inputClass: "questionInputArea",
              placeholder: "Text of card (optional)",
              textAreaStyle: style,
            }}
            onBlur={onCardBlur}
            value={carddesc}
            onChange={(e) => setCardDesc(e.target.value)}
          />
          </div>
        ) : (
          <div>
            <div className={"questionWrap"}>
              <div className="question">
                <span className={"num"} style={style}>
                  {item.id + "- "}
                </span>
                <TextArea
                  config={{
                    name: "name",
                    inputClass: "questionInputArea",
                    placeholder: "Name of card",
                    textAreaStyle: style,
                  }}
                  onBlur={onCardBlur}
                  value={cardname}
                  onChange={(e) => setCardName(e.target.value)}
                />
              </div>
            </div>
            {settings && settings.removepic ? (
              " "
            ) : (
              <div className="imageUploadWrap imageUploadItems">
                <div className="fileUploadInner">
                  <label className="imgWrapper">
                    <input
                      type="file"
                      name="picture"
                      onChange={onChangeHandle}
                      accept={"image/x-png, image/jpeg"}
                    />
                    <img
                      src={
                        cardimage.length
                          ? cardimage
                          : origin + "/images/SVG/upload.svg"
                      }
                      alt="upload icon"
                    />
                  </label>
                </div>
              </div>
            )}
             <TextArea
            config={{
              name: "desc",
              inputClass: "questionInputArea",
              placeholder: "Text of card (optional)",
              textAreaStyle: style,
            }}
            onBlur={onCardBlur}
            value={carddesc}
            onChange={(e) => setCardDesc(e.target.value)}
          />
          </div>
        )}

        {settings?.layout == 1 && children}
      </Card>
    );
  };
  console.log("ADADif_pictures_type", if_pictures_type);
  return (
    <div className="imageWrapInner imageCardWrap">
      <div className="imageCardList">
        {newCard &&
          newCard.map((item, index) => {
            return <CardComponent item={item} index={index} key={item.id} />;
          })}
      </div>

      <div className="btnGroup" style={{ paddingBottom: 0 }}>
        <button className="btnSave" onClick={beforeOnSave}>
          Save
        </button>
        <div className="createMultiple" name="ans_options" onClick={addCard}>
          <AddCircleOutlineIcon /> Add Card
        </div>
        <div className="answerTypeSelectBox">
          <MaterialSelect
            config={{
              isMenuWithContent: true,
              fieldClass: "answerInputType",
              menuItems: ansTypes,
              value: quesType,
              onChange: (e) => setQuesType(e.target.value),
            }}
          />
        </div>
      </div>
      {typeof err !== "undefined" && err.length ? (
        <span className={"invalid-feedback"} style={{ position: "unset" }}>
          {err}
        </span>
      ) : null}
      <Prompt
        show={alertMsg?.length}
        message={alertMsg}
        handler={() => setAlertMsg("")}
        singleBtn={true}
        btnLabel="OK"
      />
    </div>
  );
};

export default TypeCardChoice;
