import React, { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    Grid,
    Chip,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    CardHeader,
    Badge,
    styled,
    keyframes,
    IconButton,
} from "@mui/material";
import { Bell, Delete, SquareArrowOutUpRight } from "lucide-react";
import CustomerAction from "./CustomerAction";
import { CrmService } from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import { useNextMonthDisabled } from "@mui/x-date-pickers/internals";
import { callQueueActions } from "../../../actions/callQueueActions";

// Create a pulsating animation for high-priority customers
const pulseAnimation = keyframes`
0% { transform: scale(0.7); box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7); }
70% { transform: scale(1); box-shadow: 0 0 0 10px rgba(255, 0, 0, 0); }
100% { transform: scale(0.7); box-shadow: 0 0 0 0 rgba(255, 0, 0, 0); }
`;

const PriorityDot = styled("span")(({ theme }) => ({
    position: "absolute",
    top: "-4px",
    right: "-4px",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: "red",
    animation: `${pulseAnimation} 1.5s infinite`,
    zIndex: 1,
}));

const QueueTable = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [callQueueId, setCallQueueId] = useState(null);

    const { callQueue } = useSelector((state) => ({
        callQueue: state.callQueueLogic.callQueue
    }))

    const dispatch = useDispatch(); 
    // Function to calculate wait time in minutes
    const calculateWaitTime = (waitTime) => {
        if (!waitTime) return "N/A";

        const waitDate = new Date(waitTime);
        const now = new Date();

        const diffMs = now.getTime() - waitDate.getTime(); 
        if (diffMs < 0) return "N/A"; 

        const seconds = Math.floor((diffMs / 1000) % 60);
        const minutes = Math.floor((diffMs / (1000 * 60)) % 60);
        const hours = Math.floor(diffMs / (1000 * 60 * 60));

        let result = "";
        if (hours > 0) result += `${hours}hr `;
        if (minutes > 0) result += `${minutes}m `;
        if (seconds > 0 && hours === 0) result += `${seconds}s`; 

        return result.trim();
    };

    useEffect(() => {
        dispatch(callQueueActions.getCallQueue());
    }, [dispatch]);

    return (
        <Grid item xs={12} sm={8} md={6}>
        <Card sx={{ border: "1px solid #f0f0f0", borderRadius: 0 }}>
        <CardHeader
        sx={{
            ".MuiCardHeader-title": { fontSize: "0.9rem", fontWeight: "bold" },
        }}
        title="Customer Queue"
        action={
            <Chip
            icon={<Bell />}
            label="Alerts"
            size="small"
            color="error"
            variant="outlined"
            />
        }
        />
        <CardContent sx={{ padding: "8px !important" }}>
        <TableContainer component={Paper} elevation={0} sx={{ height: 300 }}>
        <Table size="small" stickyHeader>
        <TableHead>
        <TableRow>
        {["ID", "Customer", "Phone", "Wait Time", "Issue", "Priority", "Action"].map(
            (h) => (
                <TableCell key={h} sx={{ fontWeight: "bold", padding: "6px" }}>
                {h}
                </TableCell>
            )
        )}
        </TableRow>
        </TableHead>
        <TableBody>
        {callQueue?.map((customer) => (
            <TableRow key={customer.id} hover>
            <TableCell sx={{ padding: "6px" }}>{customer.id}</TableCell>
            <TableCell sx={{ padding: "6px" }}>{customer.customer_name}</TableCell>
            <TableCell sx={{ padding: "6px" }}>{customer.phone_number}</TableCell>
            <TableCell sx={{ padding: "6px" }}>
            {calculateWaitTime(customer?.wait_time)} 
            </TableCell>
            <TableCell sx={{ padding: "6px" }}>{customer.issue_type}</TableCell>
            <TableCell>
            <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            badgeContent={customer.waitTime > 30 ? <PriorityDot /> : null}
            >
            <Chip
            label={customer?.priority}
            size="small"
            color={"error"}
            variant="outlined"
            />
            </Badge>
            </TableCell>
            <TableCell sx={{ padding: "6px" }}>
            <IconButton onClick={() => {
                setCallQueueId(customer.id)
                setDialogOpen(true)
            }} color="primary" size="small">
            <SquareArrowOutUpRight />
            </IconButton>

            <IconButton onClick={() => dispatch(callQueueActions.deleteCallQueue(customer.id))} >
            <Delete size={23}/>
            </IconButton>
            </TableCell>
            </TableRow>
        ))}
        </TableBody>
        </Table>
        </TableContainer>
        </CardContent>
        </Card>

        <CustomerAction
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        customerData={{}}
        onPriorityChange={(id, newPriority) => console.log(`Priority for customer ${id} changed to ${newPriority}`)}
        onAssignUser={(id, user) => console.log(`Customer ${id} assigned to user ${user}`)}
        callQueueId={callQueueId}
        />
        </Grid>
    );
};

export default QueueTable;
