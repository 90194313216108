function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("development");
  localStorage.removeItem('auth');
  localStorage.removeItem('social_type');
  localStorage.removeItem('formName');
  localStorage.removeItem('surveyName');
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    console.log(data);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        //window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      console.log(data.message);
      return Promise.reject(error);
    }
    return data;
  });
}



export const commonService = {
  // http://178.63.60.87:5000
  // apiUrl:"https://vocbetaapp.articence.com",
  // apiUrl:"https://testvoc.articence.com",
  // apiUrl:"http://178.63.60.87:5000",
  apiUrl:
    localStorage.getItem("development") &&
    localStorage.getItem("development") === "true"
      ? "http://localhost:8000/"
      : "https://betavocapi.articence.com",
  handleResponse,
  logout,
};
