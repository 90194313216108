import { useEffect, useState } from "react";
import cn from "classnames";
import { ChartComponent } from "../Chart";

import { surveyActions } from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "../../../Common/Backdrop";

import autocolors from "chartjs-plugin-autocolors";

import Box from "@mui/material/Box";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ANS_TYPE } from "../../../Common/Constants";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  autocolors,
  Tooltip,
  Legend
);
const LexicalDataAnalysis = ({ isFormType }) => {
  const getFormattedLabel = (val) => {
    let label = "";
    ANS_TYPE.forEach((e) => {
      if (e.value === val) {
        label = e.label;
      }
    });
    return label;
  };

  const dispatch = useDispatch();

  const lexicalSurveyYear = useSelector(
    (state) => state.survey.lexicalSurveyYear
  );
  const lexicalSurveyMonth = useSelector(
    (state) => state.survey.lexicalSurveyMonth
  );
  const getDropList = () => {
    const year = new Date().getFullYear() - 20;
    return Array.from(new Array(50), (v, i) => (
      <option key={i} value={year + i}>
        {year + i}
      </option>
    ));
  };

  const surveyData = useSelector((state) => state.survey.lexicalItems);

  const lexicalQuestionId = useSelector(
    (state) => state.survey.lexicalQuestionId
  );

  const questionData = useSelector(
    (state) => state.survey.lexicalQuestionAnalysisData
  );

  const [showloader, setshowloader] = useState(false);

  const [chartData, setChartData] = useState([]);

  const [chartLabel, setChartLabel] = useState([]);

  const [cxLoading, setCxLoading] = useState({ cx1: false, cx2: false });

  const formatDate = (date) => {
    let arr_date = date.split("-");

    let months = [
      "",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[parseInt(arr_date[1])] + " " + arr_date[2];
  };
  useEffect(() => {
    let graphLabel = [];
    let graphArray = [];

    if (lexicalSurveyMonth !== "all") {
      surveyData &&
        surveyData.data &&
        Object.entries(surveyData.data).forEach(([key, value]) => {
          graphLabel.push(formatDate(key));
          graphArray.push(value);
        });
    } else {
      surveyData &&
        surveyData.data &&
        Object.entries(surveyData.data).forEach(([key, value]) => {
          graphLabel.push(key);
          graphArray.push(value);
        });
    }
    setChartData(graphArray);
    setChartLabel(graphLabel);
    setshowloader(false);
  }, [surveyData, lexicalSurveyMonth, lexicalSurveyYear]);

  return (
    <div className="answerAnalysisPage">
      <Backdrop show={showloader} />
      <div className="row">
        <div className="col-md-8 col-sm-12">
          <div className="row">
            <div className="col-md-7 col-sm-12">
              <div className={cn("", "analyseQuestionSelect")}>
                {questionData && questionData && questionData.length > 0 ? (
                  <Box
                    sx={{ maxWidth: 485, maxHeight: 55 }}
                    className="analysisCustomSelectBox"
                  >
                    <FormControl fullWidth>
                      <Select
                        name="question"
                        value={lexicalQuestionId || ""}
                        label=""
                        onChange={(e) => {
                          setshowloader(true);
                          let index = questionData.findIndex(
                            (x) => x.qus_id === parseInt(e.target.value)
                          );
                          dispatch(
                            surveyActions.setLexicalQuestionId(e.target.value)
                          );
                        }}
                        className="analysisCustomSelect"
                        MenuProps={{
                          classes: { paper: "selectPaperRoot" },
                        }}
                      >
                        {questionData &&
                          questionData &&
                          questionData.length > 0 &&
                          questionData.map((ques, index) => {
                            return (
                              <MenuItem
                                value={ques.qus_id}
                                key={index}
                                className="analysisMenuItem"
                              >
                                Q:{index + 1} {ques.question}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                ) : null}
              </div>
            </div>
            <div className="col-md-5 col-sm-12">
              {surveyData?.current_question_type ? (
                <div className={cn("analyseCard", "analyseQuestionType")}>
                  <span className="text-capitalize">
                    {surveyData &&
                      getFormattedLabel(surveyData?.current_question_type)}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="row chart-row mt-30 ">
        {chartData && chartData.length > 0 ? (
          <div className="col-sm-12 col-md-6 col-lg-8">
            <div className={cn("analyseCard", "analyseChart2")}>
              <div className="analyse-filter-box">
                <div className="title">Answers Submitted</div>
                <div className="select-items">
                  <div className="item">
                    <select
                      value={lexicalSurveyYear}
                      onChange={(e) => {
                        setshowloader(true);
                        setCxLoading({ cx1: true });
                        setTimeout(() => {
                          setCxLoading({ cx1: false });
                        }, 2000);
                        dispatch(surveyActions.setLexicalSurveyMonth("all"));
                        dispatch(
                          surveyActions.setLexicalSurveyYear(e.target.value)
                        );
                      }}
                    >
                      {getDropList()}
                    </select>
                  </div>
                  <div className="item">
                    <select
                      value={lexicalSurveyMonth}
                      onChange={(e) => {
                        setshowloader(true);
                        setCxLoading({ cx1: true });
                        setTimeout(() => {
                          setCxLoading({ cx1: false });
                        }, 2000);
                        dispatch(
                          surveyActions.setLexicalSurveyMonth(e.target.value)
                        );
                      }}
                    >
                      <option value="all">Select</option>

                      <option value="01">January</option>
                      <option value="02">February</option>
                      <option value="03">March</option>
                      <option value="04">April</option>
                      <option value="05">May</option>
                      <option value="06">June</option>
                      <option value="07">July</option>
                      <option value="08">August</option>
                      <option value="09">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                  </div>
                </div>
              </div>
              <div style={{ height: "380px" }}>
                {" "}
                {cxLoading.cx1 ? (
                  <p style={{ textAlign: "center", marginTop: 20 }}>
                    Loading...
                  </p>
                ) : (
                  <ChartComponent
                    text=""
                    info={chartData}
                    labels={chartLabel}
                  />
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LexicalDataAnalysis;
