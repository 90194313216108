import React from "react";
import { DndProvider } from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import GridWrapper from "./GridWrapper";
import { GridProvider } from "./GridContext";

const PictureGrid = (props) => {
    return <DndProvider backend={HTML5Backend}>
        <GridProvider {...props} >
            <GridWrapper {...props} />
        </GridProvider>
    </DndProvider>
};

export default PictureGrid;

