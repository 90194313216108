import React from 'react';
import ReactDOMServer from "react-dom/server";
import {setter} from "../EmbedPage";

const Standard = (props) => {
    const {url} = props;
    const contentStyle = {width:'100%', height:'100%', position:'relative', padding:'20px 0', boxSizing:'border-box'};
    const [codeStr, setCodeStr] = React.useState('');

    const surveyFrame  = () => {
        return <div style={contentStyle}>
                    <iframe id={"_iframe"} width="100%" height="100%"  style={{textAlign:'center', pointerEvents:'unset', border:'none'}} src={url} />
                </div>
        }

    React.useEffect(()=>{
        const str = ReactDOMServer.renderToStaticMarkup(surveyFrame());
        setter(str.replace("embed/survey_ans", "survey_ans"));
        setCodeStr(str);
    },[]);

    return <div dangerouslySetInnerHTML={{__html: codeStr.replace(/&amp;/g, "&")}}  style={{height:'calc(100% - 60px)'}}/>
};

export default Standard;
