// import React from "react";
// import PropTypes from "prop-types";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   TablePagination,
//   Paper,
//   TextField,
//   Tooltip
// } from "@mui/material";
// import CircularProgress from "@mui/material/CircularProgress";


// const DataTable = ({ columns, rows, tableType, loading }) => {
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(5);
//   const [searchQuery, setSearchQuery] = React.useState("");

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleSearch = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   const filteredRows = rows?.filter((row) =>
//     columns.some((column) =>
//       row[column]?.toString().toLowerCase().includes(searchQuery.toLowerCase())
//     )
//   );

//   return (
//     <div style={{ marginBottom: "20px" }}>
//       <div style={{ marginBottom: "10px", width: "100%" }}>
//         <TextField
//           variant="outlined"
//           size="small"
//           placeholder={`Search ${tableType}`}
//           value={searchQuery}
//           onChange={handleSearch}
//           fullWidth
//           style={{
//             backgroundColor: "#f1f1f1",
//             borderRadius: "5px",
//           }}
//         />
//       </div>
//       <Paper
//         elevation={4}
//         style={{
//           padding: "20px",
//           borderRadius: "15px",
//           backgroundColor: "#ffffff",
//         }}
//       >
//            {loading ? (
//         <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
//           <CircularProgress />
//         </div> ) : (
//         <TableContainer>
//           <Table>
//             <TableHead>
//               <TableRow style={{ backgroundColor: "#b2e0e7" }}>
//                 {columns.map((column, index) => (
//                   <TableCell
//                     key={index}
//                     style={{
//                       fontWeight: "bold",
//                       color: "#000",
//                       textAlign: "center",
//                       fontSize: "16px",
//                     }}
//                   >
//                     {column}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {filteredRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                 .map((row, rowIndex) => (
//                   <TableRow
//                     key={rowIndex}
//                     style={{
//                       backgroundColor: rowIndex % 2 === 0 ? "#f9f9f9" : "#fff",
//                     }}
//                   > {columns.map((column, colIndex) => (
//                     <TableCell
//                       key={colIndex}
//                       style={{ textAlign: "center", fontSize: "14px" }}
//                     >
//                           {column === ("Delegate_users" || "tags") && Array.isArray(row[column]) ? (
//                           <Tooltip title={row[column].slice(0).join(", ") || ""}>
//                             <span>{row[column][0]}...</span>
//                           </Tooltip>
//                         ) : (
//                           row[column] || "N/A"
//                         )}
//                       </TableCell>
//                     ))}
//                   </TableRow>
//                 ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//             )
// }
//         <TablePagination
//           component="div"
//           count={filteredRows?.length}
//           page={page}
//           onPageChange={handleChangePage}
//           rowsPerPage={rowsPerPage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//       </Paper>
//     </div>
//   );
// };

// DataTable.propTypes = {
//   columns: PropTypes.arrayOf(PropTypes.string).isRequired,
//   rows: PropTypes.arrayOf(PropTypes.object).isRequired,
//   tableType: PropTypes.string.isRequired,
// };

// export default DataTable;


import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TextField,
  Tooltip,
  CircularProgress,
  Typography,
  Button
} from "@mui/material";

const DataTable = ({ columns, rows, tableType, loading, hasSubTable, subTeams, onEditSubTeam, onDeleteSubTeam }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchQuery, setSearchQuery] = React.useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = rows?.filter((row) =>
    columns.some((column) =>
      row[column]?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div style={{ marginBottom: "20px" }}>
      <div style={{ marginBottom: "10px", width: "100%" }}>
        <TextField
          variant="outlined"
          size="small"
          placeholder={`Search ${tableType}`}
          value={searchQuery}
          onChange={handleSearch}
          fullWidth
          style={{ backgroundColor: "#f1f1f1", borderRadius: "5px" }}
        />
      </div>
      <Paper elevation={4} style={{ padding: "20px", borderRadius: "15px", backgroundColor: "#ffffff" }}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <CircularProgress />
          </div>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: "#b2e0e7" }}>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      style={{ fontWeight: "bold", color: "#000", textAlign: "center", fontSize: "16px" }}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                      <TableRow style={{ backgroundColor: rowIndex % 2 === 0 ? "#f9f9f9" : "#fff" }}>
                        {columns.map((column, colIndex) => (
                          <TableCell key={colIndex} style={{ textAlign: "center", fontSize: "14px" }}>
                            {column === ("Delegate_users" || "tags") && Array.isArray(row[column]) ? (
                              <Tooltip title={row[column].slice(0).join(", ") || ""}>
                                <span>{row[column][0]}...</span>
                              </Tooltip>
                            ) : (
                              row[column] || "N/A"
                            )}
                          </TableCell>
                        ))}
                      </TableRow>

                      {/* Sub-table (Always visible) */}
                      {hasSubTable && (
                        <TableRow style={{ backgroundColor: "#E2F3F5" }}>
                          <TableCell colSpan={columns.length} style={{ paddingLeft: "30px" }}>
                            <Paper elevation={2} style={{ padding: "10px", backgroundColor: "#f9f9f9" }}>
                              <Typography variant="subtitle1" style={{ fontWeight: "bold", marginBottom: "10px" }}>
                                Sub Teams
                              </Typography>
                              <Table size="small">
                                <TableHead>
                                  <TableRow style={{ backgroundColor: "#b2e0e7" }}>
                                    <TableCell style={{ fontWeight: "bold" }}>Sub Team Name</TableCell>
                                    <TableCell style={{ fontWeight: "bold" }}>Description</TableCell>
                                    <TableCell style={{ fontWeight: "bold" }}>Actions</TableCell>
                                    {/* <TableCell style={{ fontWeight: "bold" }}>Members</TableCell> */}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {subTeams.map((subTeam, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{subTeam.sub_team_name}</TableCell>
                                      <TableCell>{subTeam.description}</TableCell>
                                      {/* <TableCell>{subTeam.members}</TableCell> */}
                                      <TableCell>
                                        <Button
                                          variant="outlined"
                                          color="primary"
                                          size="small"
                                          onClick={() => onEditSubTeam(subTeam)}
                                          style={{ marginRight: "10px" }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          variant="outlined"
                                          color="secondary"
                                          size="small"
                                          onClick={() => onDeleteSubTeam(subTeam.id)}
                                        >
                                          Delete
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TablePagination
          component="div"
          count={filteredRows?.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

DataTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableType: PropTypes.string.isRequired,
  hasSubTable: PropTypes.bool,
  subTeams: PropTypes.array,
  onEditSubTeam: PropTypes.func,
  onDeleteSubTeam: PropTypes.func,
};

export default DataTable;

