import React, { useEffect, useState } from "react";
import { MaterialSelect } from "../../../../common/controls/inputs/Material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { Button, Modal } from "antd";

import "./PageLogic.scss";
import MultipleChoiceLogic from "./MultipleChoiceLogic";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FirstIcon from "../../../../assets/images/first.svg";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { dark } from "@mui/material/styles/createPalette";
import Icon1 from "../../../../assets/images/multipleicon.png";
const { Option } = Select;

const CustomNode = (props) => {
  const { id, content, inputs, data } = props;
  useEffect(() => {}, [data.qType]);
  return (
    <div style={{ background: "#717EC3", borderRadius: "10px" }}>
      <div className="main-content">
        <div className="icon">
          {data.qType === "Rating" ? (
            <img src="/images/SVG/rating.svg" alt="" />
          ) : (
            ""
          )}
          {data.qType === "Email" ? (
            <img src="/images/SVG/email.svg" alt="" />
          ) : (
            ""
          )}
          {data.qType === "Yes/No" ? (
            <img src="/images/SVG/yesNo.svg" alt="" />
          ) : (
            ""
          )}
          {data.qType === "Multiple" ? (
            <img src="/images/SVG/multiple.svg" alt="" />
          ) : (
            ""
          )}
          {data.qType === "Picture" ? (
            <img src="/images/SVG/picture.svg" alt="" />
          ) : (
            ""
          )}
          {data.qType === "Contact" ? (
            <img src="/images/SVG/contact.svg" alt="" />
          ) : (
            ""
          )}
          {data.qType === "DropDown" ? (
            <img src="/images/SVG/dropdown.svg" alt="" />
          ) : (
            ""
          )}
          {data.qType === "FileUpload" ? (
            <img src="/images/SVG/file.svg" alt="" />
          ) : (
            ""
          )}
          {/* <img src={Icon1} alt="" /> */}
        </div>
        <p className="paraContent">{content}</p>
        <Button
          onClick={() => {
            data.setMultipleChoiceId(id);
            data.setMultipleChoiceData(data);
            data.setActiveQuestionType("multiple");
          }}
          type="primary"
        >
          Set Next Question
        </Button>
      </div>
      <div style={{ marginTop: "20px" }}>
        {inputs.map((port) =>
          React.cloneElement(port, {
            style: { width: "50px", height: "25px", background: "#1B263B" },
          })
        )}
      </div>
    </div>
  );
};

export default CustomNode;
