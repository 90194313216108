import { useState } from "react";
import {
  TextField,
  Button,
  Chip,
  MenuItem,
  Select,
  Card,
  CardContent,
  InputAdornment,
  Box,
} from "@mui/material";
import { Cancel, LocalOffer } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { TagsAction } from "../../../actions/tagsAction";

export default function CallTagsForm({ setOpen }) {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState([]);
  const [description, setDescription] = useState("");
  const [callType, setCallType] = useState("Select Call Type");

  const handleAddTag = () => {
    if (tag && !tags.includes(tag)) {
      setTags([...tags, tag]);
      setTag("");
    }
  };

  const handleRemoveTag = (removedTag) => {
    setTags(tags.filter((t) => t !== removedTag));
  };

  const handleAddTagList = () => {
    if (!name || tags.length === 0 || description.trim() === "" || callType === "Select Call Type") {
      alert("Please fill all fields before adding the tag list.");
      return;
    }

    const payload = {
      list_name: name,
      tags: tags,
      description: description,
      is_approved: false, 
      call_tag_type: callType,
    };

    dispatch(TagsAction.addTags(payload));

    // Clear form after submission
    setTags([]);
    setName("");
    setDescription("");
    setCallType("Select Call Type");
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-xl shadow-md">
      <h3 className="font-bold mb-4">Create Tag List</h3>

      {/* Inputs Section */}
      <div className="flex flex-col gap-4 mb-4" style={{ display: "flex", gap: "15px", maxWidth: "55%", justifyContent: "space-between" }}>
        <TextField value={name} onChange={(e) => setName(e.target.value)} label="Enter Name" variant="outlined" fullWidth />
        
        <TextField value={description} onChange={(e) => setDescription(e.target.value)} label="Enter Description" variant="outlined" fullWidth />

        <TextField
          value={tag}
          onChange={(e) => setTag(e.target.value)}
          label="Enter a Tag"
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocalOffer color="action" />
              </InputAdornment>
            ),
          }}
        />
        
        <Button variant="contained" color="primary" onClick={handleAddTag}>
          Add Tag
        </Button>

        <Select value={callType} onChange={(e) => setCallType(e.target.value)} displayEmpty variant="outlined" fullWidth>
          <MenuItem value="Select Call Type" disabled>
            Select Call Type
          </MenuItem>
          <MenuItem value="INBOUND">Inbound Call</MenuItem>
          <MenuItem value="OUTBOUND">Outbound Call</MenuItem>
        </Select>
      </div>

      {/* Tag Display */}
      <div className="flex flex-wrap gap-2 mb-4">
        {tags.map((t, index) => (
          <Chip key={index} label={t} onDelete={() => handleRemoveTag(t)} deleteIcon={<Cancel />} color="primary" />
        ))}
      </div>

      {/* Add Tag List Button */}
      <Box>
        <Button style={{ width: "14%", marginRight: "10px" }} variant="contained" color="secondary" onClick={handleAddTagList} fullWidth>
          Add Tag List
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleClose} sx={{ marginRight: 2 }}>
          Cancel
        </Button>
      </Box>
    </div>
  );
}
