import React, { useState, useEffect } from "react";
import { Button, Typography, Tabs, Tab } from "@mui/material";
import DataTable from "../../../components/Tables/DataTable";
import CallTagsForm from "./CallTagsForm";
import { AdminService } from "../../../services/AdminService";
import { useDispatch } from "react-redux";
import { TagsAction } from "../../../actions/tagsAction";
import { CheckCircle } from "@mui/icons-material"

const mockData = [
  {
    list_name: "Call Disposition",
    call_tag_type: "Inbound",
    tags: ["Interested", "Not Interested", "Call Back Later"],
    status: true,
  },
  {
    list_name: "Lead Status",
    call_tag_type: "Outbound",
    tags: ["New Lead", "Follow-Up Required", "Qualified"],
    status: false,
  },
  {
    list_name: "Call Outcome",
    call_tag_type: "Inbound",
    tags: ["Appointment Set", "Deal Closed"],
    status: false,
  },
];

const CallTagsTable = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [tagsData, setTagsData] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const dispatch = useDispatch();

  const handleTabChange = (_, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleApprove = (data) => {
    // Simulate API call to update status
    // setTagsData((prevData) =>
    //   prevData.map((tag) =>
    //     tag.list_name === listName ? { ...tag, status: true } : tag
    //   )
    // );

    const payload = {
      list_name: data.list_name,
      //tags: data.tag,
      description: data.description,
      is_approved: true,
      call_tag_type: data.call_tag_type,
    };

    dispatch(TagsAction.editTag(payload, data.id));
  };

  useEffect(() => {
    const fetchTags = async () => {
      const data = await AdminService.getAllTags();      
      setTagsData(data?.data);
    };
    fetchTags();
  }, []);

  const columns = ["Name", "Call Type", "Tags", "Actions"];

  const getRows = (status) => {
    if (!Array.isArray(tagsData)) return [];
  
    return tagsData
      .filter((tag) => tag.is_approved === status) // Filter by approved status
      .map((tag) => ({
        Name: tag.list_name || "N/A",
        "Call Type": tag.call_tag_type || "N/A",
        Description: tag.description || "No description",
        Tags: Array.isArray(tag.tags) && tag.tags.length > 0 
        ? tag.tags.map(t => t.name).join(", ") // Extract tag names
        : "No Tags",
        Actions: !status ? (
          <Button
            variant="contained"
            size="medium"
            sx={{
              background: "linear-gradient(90deg, #1E88E5 0%, #1565C0 100%)",
              color: "white",
              fontWeight: "bold",
              padding: "8px 16px",
              textTransform: "none",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              boxShadow: "0px 4px 10px rgba(30, 136, 229, 0.3)",
              "&:hover": {
                background: "linear-gradient(90deg, #1565C0 0%, #0D47A1 100%)",
                boxShadow: "0px 6px 12px rgba(21, 101, 192, 0.4)",
              },
            }}
            onClick={() => handleApprove(tag)}
          >
            <CheckCircle sx={{ fontSize: 20 }} />
            Approve
          </Button>
        ) : <CheckCircle sx={{ fontSize: 20 }} />,
      }));
  };
  


  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <Button variant="contained" color="primary" onClick={() => setShowForm(true)}>
          Add Tags List
        </Button>
      </div>

      {showForm && (
        <div style={{ marginBottom: "20px" }}>
          <CallTagsForm setOpen={setShowForm} teamData={selectedTeam} />
        </div>
      )}

      <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary">
        <Tab label="Tags" />
        <Tab label="Pending Tags" />
      </Tabs>

      <div style={{ marginTop: "20px" }}>
        {tabIndex === 0 && (
          <>
            <Typography variant="h6">Tags</Typography>
            <DataTable columns={columns} rows={getRows(true)} tableType="Tags" />
          </>
        )}
        {tabIndex === 1 && (
          <>
            <Typography variant="h6">Pending Tags</Typography>
            <DataTable columns={columns} rows={getRows(false)} tableType="Tags" />
          </>
        )}
      </div>
    </div>
  );
};

export default CallTagsTable;
