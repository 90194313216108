import React, {useState} from 'react';
import {Header, MainMenu} from "../../Common/Header";
import {URL_VAL} from '../../Common/Constants';
import SurveySubHeader from "../../Survey/Components/SurveySubHeader";
import SocialLink from "./SocialLink";
import EmbedPage from "./EmbedPage";
import EmbedForm from "../../Form/Embed/EmbedPage"

const ShareLink = ({surveyID, isFormType})=>{
    const [active, setActive] = useState('CollectResponses');
    const [embedPage, setEmbedPage] = useState("");
    const widget = isFormType ? "/form_ans/":"/survey_ans/";
    const survey_url = window.location.origin + `${widget}${surveyID}&${URL_VAL}`;
    const onEmbedClickView =(type)=>{
        setEmbedPage(type);
    }
    const mobileView = true;

    return(
        <div className={`createSurveyBody ${mobileView ? 'mobileView':''}`}>
            {!embedPage.length ?<SocialLink onEmbedClickView={onEmbedClickView} surveyID={surveyID} survey_url={survey_url}/>:
                isFormType ? <EmbedForm surveyID={surveyID} embedType={embedPage} survey_url={survey_url}/> : <EmbedPage surveyID={surveyID} embedType={embedPage} survey_url={survey_url}/>}
        </div>
    )
};

export default ShareLink;
