import cn from "classnames";
import Select, { components } from "react-select";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { surveyActions } from "../../../../../actions";
import Input from "../../../../../common/controls/inputs/Input";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import ReactPaginate from "react-paginate";

import "./pagination.css";
import { Button } from "@mui/material";
import DialogBox from "../../../../Common/DialogBox";
import Label from "../../../../../common/controls/inputs/Label";

const FinalizeCategories = ({
  id,
  questionId,
  setPageNumber,
  updateSearch,
  filterCategory,
  category,
  page,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [text, setText] = useState("");
  const dispatch = useDispatch();
  const [checkedAll, setCheckedAll] = useState(false);
  const [finalize, setFinalize] = useState([]);
  const [searchText, setSearchText] = useState("");
  const percentage = 66;
  useEffect(() => {
    return () => {
      updateSearch("");
    };
  }, []);
  const CustomOption = (props) => {
    return (
      <div className="select__menu-list-item">
        <components.Option {...props}>
          <div>
            <span>
              {" "}
              <input
                type="checkbox"
                checked={props.isSelected}
                // className="checkbox__input"
                onChange={() => null}
              />{" "}
            </span>
            <span style={{ color: "black" }} className="ml-2">
              {" "}
              <label>{props.label}</label>
            </span>
          </div>
        </components.Option>
      </div>
    );
  };
  const [showSearch, setShowSearch] = useState(false);
  const answerData = useSelector((state) => state.survey.answerData);

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setPageNumber(selectedPage + 1);
    setCheckedAll(false);
  };
  const selectAll = () => {
    setCheckedAll(!checkedAll);
    const temp = [...finalize];
    let modifiedFinalizeData;
    if (checkedAll) {
      modifiedFinalizeData = temp.map((e, i) => ({
        ...e,
        checked: false,
      }));
    } else {
      modifiedFinalizeData = temp.map((e, i) => ({
        ...e,
        checked: true,
      }));
    }

    setFinalize(modifiedFinalizeData);
  };
  useEffect(() => {
    if (answerData && answerData.answers && answerData.answers.length > 0) {
      const temp = [...answerData.answers];
      const modified = temp.map((e, i) => ({
        ...e,
        checked: false,
      }));

      setFinalize(modified);
    } else {
      setFinalize([]);
    }
  }, [answerData]);
  useEffect(() => {
    dispatch(surveyActions.getCategoryInsightsByQuestion(id, questionId));
  }, [id, questionId]);
  const categoryInsightsByQuestion = useSelector(
    (state) => state.survey.categoryInsightsByQuestion
  );

  const submit = () => {
    // updateComboCategory;
    const temp = [...finalize];
    temp.forEach((e, i) => {
      e.Question = undefined;
      e.detail_id = undefined;
      e.outlier = undefined;
      e.tldr = undefined;
      e.qus_id = undefined;
      e.key = i;
      e.custom = e.categories;
      e.answer = e.answers[0].text;
      e.answers = undefined;
      e.assignedCategory = undefined;
    });
    const updatedObj = {
      params: {
        catGroups: temp,
        detail_id: id,
        qus_id: questionId,
        redo: null,
      },
    };

    dispatch(surveyActions.updateComboCategory(updatedObj));
    setCheckedAll(false);
  };

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 600,
    },
  });

  return (
    <>
      <div className="tabData-Wrap">
        <div className="contentbox">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              {" "}
              <div className="card finalize-card">
                <div className="finalizeCategoryHeading">
                  Number of Answers shown below
                </div>
                <div className="finalizeCategoryNumbers">
                  {categoryInsightsByQuestion &&
                    categoryInsightsByQuestion.data &&
                    categoryInsightsByQuestion.data.answers_shown}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              {" "}
              <div className="card finalize-card">
                <div className="finalizeCategoryHeading">
                  Number of Categorized Answers
                </div>
                <div className="finalizeCategoryNumbers">
                  {categoryInsightsByQuestion &&
                    categoryInsightsByQuestion.data &&
                    categoryInsightsByQuestion.data.categorized_answers_count}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              {" "}
              <div className="card finalize-card">
                <div className="finalizeCategoryHeading">
                  Number of Uncategorized Answers
                </div>
                <div className="finalizeCategoryNumbers">
                  {categoryInsightsByQuestion &&
                    categoryInsightsByQuestion.data &&
                    categoryInsightsByQuestion.data.uncategorized_answers_count}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9 col-sm-12 mb-3">
              {" "}
              <div className={cn("customButton rounded-none")}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleOpen();
                  }}
                >
                  Assign Categories
                </span>
              </div>
            </div>
            <div className="col-md-3 col-sm-12 mb-3">
              <div>
                {" "}
                <Select
                  hideSelectedOptions={false}
                  getOptionValue={(option) => option.label}
                  components={{ Option: CustomOption }}
                  closeMenuOnSelect={false}
                  isSearchable
                  value={category || ""}
                  isMulti
                  classNamePrefix="select"
                  onChange={(e) => {
                    setPageNumber(1);

                    filterCategory(e);
                  }}
                  options={[
                    {
                      label: "All Category",
                      value: "all_categories",
                    },
                    {
                      label: "No Category",
                      value: "no_categories",
                    },
                  ]}
                />
              </div>
            </div>
          </div>

          <table
            style={{ border: "1px solid #dee2e6" }}
            className="table table-borderless"
          >
            <thead className="thead-blue">
              <tr style={{ background: "rgba(24, 160, 251, 0.2)" }}>
                <th scope="col" style={{ width: "5%" }}>
                  {checkedAll ? (
                    <img
                      onClick={() => {
                        selectAll();
                      }}
                      style={{
                        width: "15px",
                        height: "15px",
                        cursor: "pointer",
                      }}
                      src="/images/tick.png"
                    ></img>
                  ) : (
                    <img
                      onClick={() => {
                        selectAll();
                      }}
                      style={{
                        width: "15px",
                        height: "15px",
                        cursor: "pointer",
                      }}
                      src="/images/untick.png"
                    ></img>
                  )}
                </th>
                <th scope="col" style={{ width: "70%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                    }}
                  >
                    <span style={{ height: "24px" }}>Answer</span>
                    {showSearch ? (
                      <span className="search-tooglebox">
                        <input
                          value={searchText}
                          onChange={(e) => {
                            setSearchText(e.target.value);
                          }}
                          type="text"
                        ></input>
                        <span
                          onClick={() => {
                            setPageNumber(1);

                            updateSearch(searchText);
                          }}
                          className="searchBtn"
                        >
                          {" "}
                          <SearchIcon />
                        </span>
                      </span>
                    ) : null}
                  </div>
                </th>

                <th scope="col" style={{ width: "20%" }}>
                  Custom Category
                </th>
                <th scope="col" style={{ width: "5%" }}>
                  <div
                    onClick={() => {
                      setShowSearch(!showSearch);
                    }}
                    className="finalizesearchBar"
                  >
                    {" "}
                    <div className="icon">
                      {" "}
                      <SearchIcon />
                    </div>
                  </div>
                </th>
              </tr>
              {finalize && finalize.length > 0
                ? finalize.map((value, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {value.checked ? (
                            <img
                              onClick={() => {
                                const temp = [...finalize];

                                temp[index].checked = !temp[index].checked;
                                setFinalize(temp);
                                setCheckedAll(false);
                              }}
                              style={{
                                width: "15px",
                                height: "15px",
                                cursor: "pointer",
                              }}
                              src="/images/tick.png"
                            ></img>
                          ) : (
                            <img
                              onClick={() => {
                                const temp = [...finalize];

                                temp[index].checked = !temp[index].checked;

                                // setCheckedAll(!checkedAll);
                                let modifiedFinalizeData = 0;

                                temp.forEach((e) => {
                                  if (e.checked) modifiedFinalizeData++;
                                });

                                if (modifiedFinalizeData === temp.length) {
                                  setCheckedAll(!checkedAll);
                                }

                                setFinalize(temp);
                              }}
                              style={{
                                width: "15px",
                                height: "15px",
                                cursor: "pointer",
                              }}
                              src="/images/untick.png"
                            ></img>
                          )}
                        </td>
                        <td>
                          <CustomWidthTooltip
                            title={
                              value.answers &&
                              value.answers.length > 0 &&
                              value.answers[0].text
                            }
                            placement="top-start"
                          >
                            <div className="tableCellText">
                              {value.answers &&
                                value.answers.length > 0 &&
                                value.answers[0].text}
                            </div>
                          </CustomWidthTooltip>
                        </td>

                        <td>
                          <div>
                            {value.categories && value.categories.length > 0
                              ? value.categories.map((val, i) => {
                                  return (
                                    <div className="darkButton mb-2" key={i}>
                                      {" "}
                                      <>
                                        <span>{val.word}</span>
                                        <button
                                          onClick={() => {
                                            const temp = [...finalize];
                                            const filtered = temp.map(
                                              (item, indx) => {
                                                if (indx === index) {
                                                  item.categories.splice(i, 1);
                                                }
                                                return item;
                                              }
                                            );
                                            dispatch(
                                              surveyActions.setShowPrompt(true)
                                            );

                                            setFinalize(filtered);
                                          }}
                                          className="memoryclose-btn"
                                        >
                                          <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 13 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g filter="url(#filter0_d_1831_5873)">
                                              <circle
                                                cx="6.5"
                                                cy="4.5"
                                                r="3.5"
                                                fill="white"
                                              />
                                            </g>
                                            <path
                                              d="M6.81485 4.50026L7.77799 3.53855C7.82017 3.49634 7.84387 3.43909 7.84387 3.37939C7.84387 3.31969 7.82017 3.26244 7.77799 3.22022C7.73582 3.17801 7.67861 3.1543 7.61896 3.1543C7.55931 3.1543 7.50211 3.17801 7.45993 3.22022L6.49903 4.18417L5.53812 3.22022C5.49595 3.17801 5.43874 3.1543 5.37909 3.1543C5.31945 3.1543 5.26224 3.17801 5.22006 3.22022C5.17789 3.26244 5.15419 3.31969 5.15419 3.37939C5.15419 3.43909 5.17789 3.49634 5.22006 3.53855L6.18321 4.50026L5.22006 5.46197C5.19907 5.48281 5.18241 5.5076 5.17103 5.53492C5.15966 5.56224 5.15381 5.59154 5.15381 5.62113C5.15381 5.65072 5.15966 5.68003 5.17103 5.70734C5.18241 5.73466 5.19907 5.75945 5.22006 5.78029C5.24089 5.80131 5.26566 5.81798 5.29295 5.82936C5.32025 5.84075 5.34953 5.8466 5.37909 5.8466C5.40866 5.8466 5.43794 5.84075 5.46523 5.82936C5.49253 5.81798 5.5173 5.80131 5.53812 5.78029L6.49903 4.81635L7.45993 5.78029C7.48075 5.80131 7.50553 5.81798 7.53282 5.82936C7.56012 5.84075 7.58939 5.8466 7.61896 5.8466C7.64853 5.8466 7.67781 5.84075 7.7051 5.82936C7.7324 5.81798 7.75717 5.80131 7.77799 5.78029C7.79899 5.75945 7.81565 5.73466 7.82702 5.70734C7.83839 5.68003 7.84425 5.65072 7.84425 5.62113C7.84425 5.59154 7.83839 5.56224 7.82702 5.53492C7.81565 5.5076 7.79899 5.48281 7.77799 5.46197L6.81485 4.50026Z"
                                              fill="#181818"
                                            />
                                            <defs>
                                              <filter
                                                id="filter0_d_1831_5873"
                                                x="0"
                                                y="0"
                                                width="13"
                                                height="13"
                                                filterUnits="userSpaceOnUse"
                                                colorInterpolationFilters="sRGB"
                                              >
                                                <feFlood
                                                  floodOpacity="0"
                                                  result="BackgroundImageFix"
                                                />
                                                <feColorMatrix
                                                  in="SourceAlpha"
                                                  type="matrix"
                                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                  result="hardAlpha"
                                                />
                                                <feOffset dy="2" />
                                                <feGaussianBlur stdDeviation="1.5" />
                                                <feComposite
                                                  in2="hardAlpha"
                                                  operator="out"
                                                />
                                                <feColorMatrix
                                                  type="matrix"
                                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                                />
                                                <feBlend
                                                  mode="normal"
                                                  in2="BackgroundImageFix"
                                                  result="effect1_dropShadow_1831_5873"
                                                />
                                                <feBlend
                                                  mode="normal"
                                                  in="SourceGraphic"
                                                  in2="effect1_dropShadow_1831_5873"
                                                  result="shape"
                                                />
                                              </filter>
                                            </defs>
                                          </svg>
                                        </button>
                                      </>
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </td>
                        <td></td>
                      </tr>
                    );
                  })
                : null}
            </thead>
          </table>

          <div className="table-footer">
            <div className="pagination-wrapper">
              {answerData && answerData.answerLength ? (
                <ReactPaginate
                  key="paginate1"
                  breakLabel="..."
                  forcePage={page - 1}
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={Math.ceil(answerData?.answerLength / 9)}
                  previousLabel="< previous"
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  subContainerClassName={"page-item"}
                  activeClassName={"active"}
                />
              ) : null}
            </div>

            <div className="btn-wrap">
              <span
                className={
                  finalize && finalize.length === 0
                    ? "customButton rounded-none customButtonDisabled"
                    : "customButton rounded-none"
                }
                onClick={submit}
              >
                Save
              </span>
            </div>
          </div>
        </div>
      </div>

      <DialogBox
        open={open}
        handleClose={handleClose}
        // handleOk={handleOk}
      >
        <div className="analyseModalbox">
          <div>
            <div className="analyseNotesHeader">
              <Label
                config={{
                  label: "Change Categories",
                  className: "notesDialogTitle",
                }}
              />
            </div>
            <div className="analyseDetailwrap">
              <div className="flexbox">
                {/* <div className="analyseLabel"></div> */}
                {/* //updateComboCategories */}
                <div className="analyseDetail">
                  <Input
                    value={text}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                    config={{
                      name: "Category",
                      type: "text",
                      className: "Title",
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="flexbox">
              <div className="buttonsWrap">
                <Button
                  onClick={() => {
                    const temp = [...finalize];

                    const filtered = temp.map((item, indx) => {
                      if (
                        item.checked &&
                        item.categories.findIndex((x) => x.word === text) === -1
                      ) {
                        item.categories = [
                          ...item.categories,
                          {
                            word: text,
                          },
                        ];
                      }
                      return item;
                    });
                    dispatch(surveyActions.setShowPrompt(true));
                    setFinalize(filtered);
                    setText("");

                    handleClose();
                  }}
                  type="primary"
                >
                  Assign Category to selected items
                </Button>{" "}
                <Button
                  onClick={() => {
                    const temp = [...finalize];
                    const filtered = temp.map((item, indx) => {
                      item.categories.map((items, index) => {
                        if (item.checked && items.word === text) {
                          item.categories.splice(index, 1);
                        }
                      });

                      return item;
                    });
                    dispatch(surveyActions.setShowPrompt(true));
                    setFinalize(filtered);
                    setText("");
                    handleClose();
                  }}
                  type="primary"
                >
                  Remove Category from selected items
                </Button>{" "}
                <Button
                  onClick={() => {
                    const temp = [...finalize];
                    const filtered = temp.map((item, indx) => {
                      if (item.checked) {
                        item.categories = [];
                      }
                      return item;
                    });
                    dispatch(surveyActions.setShowPrompt(true));
                    setFinalize(filtered);
                    setText("");
                    handleClose();
                  }}
                  type="primary"
                >
                  Remove All Category
                </Button>{" "}
                <Button type="default" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </DialogBox>
    </>
  );
};

export default FinalizeCategories;
