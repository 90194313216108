import { delegationConstants } from "../constants/delegationConstants";
import { DelegationService } from "../services/DelegationService";
import { swalAlert } from "../voc/Common/SwalAlert";

const addDelegation = (addDelegationData, Id) => {
  const request = () => ({
    type: delegationConstants.ADD_DELEGATION_REQUEST,
  });

  const success = (delegation) => ({
    type: delegationConstants.ADD_DELEGATION_SUCCESS,
    delegation,
  });

  const failure = (error) => ({ 
    type: delegationConstants.ADD_DELEGATION_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());
    DelegationService.addDelegation(addDelegationData, Id).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Delegation added successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const editDelegation = (addDelegationData, delegationId) => {
  
  const request = () => ({
    type: delegationConstants.EDIT_DELEGATION_REQUEST,
  });

  const success = (alerts) => ({
    type: delegationConstants.ADD_DELEGATION_SUCCESS,
    alerts,
  });

  const failure = (error) => ({
    type: delegationConstants.EDIT_DELEGATION_FAILURE,
    error,
  });
  return (dispatch) => {
    dispatch(request());
    DelegationService.editDelegation(addDelegationData, delegationId).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Delegation updated successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const deleteDelegation = (delegationId, userId) => {  
  const request = () => ({
    type: delegationConstants.DELETE_DELEGATION_REQUEST,
  });
  
  const success = (noteId) => ({
    type: delegationConstants.DELETE_DELEGATION_SUCCESS,
    noteId,
  });
  
  const failure = (error) => ({
    type: delegationConstants.DELETE_DELEGATION_FAILURE,
    error,
  });
  
  return (dispatch) => {
    dispatch(request());
    DelegationService.deleteDelegation(delegationId, userId).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Delegation deleted successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

export const delegationAction = {
  addDelegation,
  editDelegation,
  deleteDelegation
};