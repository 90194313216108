import React, { useState, useEffect } from 'react';
import {clone} from '../../Common/Utils';
import MultiChoice from '../../Survey/Components/MultipleChoice';
import { MaterialSelect } from '../../../common/controls/inputs/Material';
import TextArea from '../../../common/controls/inputs/TextArea';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import './Layouts.scss';

const TypeMultipleChoice = ({quesNo, quesType, setQuesType, value={}, onChange, onSave, ansTypes, children}) =>{
    const {settings={}} = value;
    const { font, underline, bold, fontSize, italic, fontColor, layout, require } = settings,
        style = { fontFamily: font, fontSize, fontWeight: bold ? 'bold' : '', fontStyle: italic ? 'italic' : '', textDecoration: underline ? 'underline' : '', color: fontColor };
    const [ques, setQues] = useState(value.question);
    const [desc, setDesc] = useState(value.desc);
    const [err, setErr] = useState();
    const beforeOnSave = () => {
        if(value.question.length < 1){
            setErr("Enter the question to proceed.");
            return;
        }
        setErr();
        onSave && onSave();
    };
    useEffect(()=> {
        setQues(value.question);
        setDesc(value.desc);
    }, [value.question, value.desc]);

    useEffect(()=> {
        if(!value.hasOwnProperty('ans_options')){
            const {settings={}} = value;
            onChange({name:'ans_options', value: [{label:'', id:1}] });
        }
    }, [quesNo]);

    useEffect(()=> {
        const elements = document.getElementsByName("question");
        let ele = elements.length ? elements[0] : elements;
        ele.style.setProperty('font-family', font||'Poppins', 'important');
    }, [font]);

    const onBlur = (e) => {
        if(e?.target){
            e.target.value = e.target.value.trim();
            e.target.name === "question" && setQues(e.target.value);
            e.target.name === "desc" && setDesc(e.target.value);
            onChange(e.target);
        }
    };

    const addChoice = (e) => {
        const cloned = clone(value);
        const {settings={}} = cloned;
        cloned.ans_options.push({label:'', id: cloned.ans_options.length + 1});
        onChange({name:'ans_options', value: cloned.ans_options});
    };

    const editChoice = (e, index) => {
        if(e && e.target){
            const cloned = clone(value);
            cloned.ans_options[index][e.target.name] = e.target.value;
            onChange({name:'ans_options', value: cloned.ans_options});
        }
    };

    const deleteChoice = (index) => {
        if(value.ans_options){
            const cloned = clone(value);
            cloned.ans_options.splice(index, 1);
            onChange({name:'ans_options', value: cloned.ans_options});
        }
    };


    return(
        <div className="imageWrapInner">
            <div className="questionWrap">
                <div className="question">
                    <span className={"num"} style={style}>{quesNo+"- "}</span>
                    <TextArea config={{ name: 'question', inputClass: 'questionInputArea', placeholder: 'Your elements here. Recall information@', textAreaStyle: style }} onBlur={onBlur} value={ques} onChange={(e)=> setQues(e.target.value)}/>
                        <span style={{color:"red", marginRight:'50px',display: 'none'}}>*</span>
                </div>
                <em><input type="text" name="desc" placeholder="Description (optional)" onBlur={onBlur} value={desc} onChange={(e)=> setDesc(e.target.value)}/></em>
            </div>
            {settings?.layout == 1 && children}
            <MultiChoice data={value} deleteChoice={deleteChoice} editChoice={editChoice}/>

            <div className="createMultiple" name="ans_options" onClick={addChoice}><AddCircleOutlineIcon/> Choice</div>
            <div className="btnGroup" style={{paddingBottom:0}}>
                <button className="btnSave" onClick={beforeOnSave}>Save</button>
                <div className='answerTypeSelectBox'>
                    <MaterialSelect config={{ isMenuWithContent: true, fieldClass: 'answerInputType', menuItems: ansTypes, value: quesType, onChange: (e)=> setQuesType(e.target.value) }} />
                </div>
            </div>
            {(typeof err !== "undefined" && err.length) ? <span className={"invalid-feedback"} style={{position:'unset'}}>{err}</span>:null}
        </div>
    )
};

export default TypeMultipleChoice
