import React, { useState, useEffect, useMemo, memo } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import {SURVEY_SUB_CATEGORIES, QUES_BANK_CAT} from '../Common/Constants';
import Dialogbox from '../Common/DialogBox'
import {MaterialSelect, SearchInput, Spinner } from "../../common/controls/inputs/Material";
import {clone} from '../Common/Utils';
import { workspaceActions, dashboardActions } from '../../actions';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import PageviewIcon from '@mui/icons-material/Pageview';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import TuneIcon from '@mui/icons-material/Tune';
import CancelIcon from '@mui/icons-material/Cancel';

const origin = window.location.origin;
const QuestionOver = ()=>{
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
           <HelpOutlineIcon onClick={handleClick} style={{cursor:"pointer"}}/>
            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} className={"questionOver"}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
             transformOrigin={{
                 vertical: 'top',
                 horizontal: 'right',
             }}
            >
                <Typography variant="h5">New feature!</Typography>
                <Typography variant="h6">This is a handy template you can use for your apps
                    (as a an onboarding tip feature for instance). Feel free to resize it,
                    change colours and modify the arrow position. </Typography>
            </Popover>
        </>
    );

}

const QuestionBank = ({ show, onCloseHandle, category, searchBy="", addBanksQuestion,experienceType, categories }) => {
    const placeholderStyle={
        fontFamily: 'Poppins',
        fontSize:'14',
        position:'absolute',
        top:5
    };
    const [exp, setExp] = useState(experienceType);
    const [keywords, setKeyword] = useState(searchBy);
    const [tags, setTags] = useState('');
    const [questionList, setQuestionList] = useState([]);
    const [serachVal, setSearchVal] = useState(searchBy);
    const [selectedQues, setQuestion] = useState([]);
    const [quesKeys, setKeys] = useState([]);
    const [cat, setCat] = useState(category);
    const {loading, questions} = useSelector(state => state.questionBank);
    const dispatch = useDispatch();

    useEffect(()=>{
        let surveyCat = cat;
        if(surveyCat === "All Categories"){
            surveyCat = "";
        }
        dispatch(workspaceActions.getQuestionBank(exp, surveyCat, tags, keywords));
    },[cat, keywords, tags]);

    useEffect(()=>{
        if(questions){
            setQuestionList(questions)
        }
    },[questions]);

    const handleOk = (value) => {
        onCloseHandle(false);

    };
    const changeHandler = (e) => {
        if(e &&  e.target && e.target.name){
            setSearchVal(e.target.value);
        }
    };
    const searchHandler = (e) => {
        setKeyword(e);

    };
    const questionHandler = (e, id) => {
        if(e){
            const obj = {
                question: e.question,
                q_type: e.question_type,
                ans_options: e.answer_options || [],
                settings:{layout:4}
            }
            setQuestion(selectedQues.concat([obj]));
            setKeys(quesKeys.concat([id]));
        }
    };
    const onCancel = (index) => {
        const quesArr = clone(selectedQues);
        const keyArr = clone(quesKeys);
        quesArr.splice(index, 1);
        keyArr.splice(index, 1);
        setQuestion(quesArr);
        setKeys(keyArr);

    };
    const addQueue = () => {
        addBanksQuestion && addBanksQuestion(selectedQues);
        onCloseHandle();
    };

    const onSelectTag = (e, t) => {
        e.stopPropagation();
        setTags(tags=> (`${tags.length ? (tags+','+t) : t}`));
    };

    const deleteTags = (i) => {
        let tagsArr = tags.split(',');
        tagsArr.splice(i, 1);
        setTags(tagsArr.join(','));

    };

    const Card = ({data={}})=>{
        const {id, question, tags=[], style={}} = data;
        const [hover, setHover] = useState(false);
        const ticked = quesKeys.includes(id) && !hover;
        return <div className="ques-card" style={style} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)} onClick={()=>questionHandler(data, id)}>
                {!ticked && <object data={origin+"/images/SVG/plusIcon.svg"} width="26" height="28" style={{pointerEvents:'none', width:20, visibility: hover?'unset':'hidden'}} ></object>}
                {ticked && <object data={origin+"/images/SVG/Tick box.svg"} width="26" height="28" style={{pointerEvents:'none', width:20}}></object>}
                <div style={{marginLeft:5}}>
                    <span className="ques-text">{question}</span>
                    <div style={{display:'flex', marginTop:25}}>
                        {tags.map((t, i) => {
                            const css = (i%2==0)? 'ques-card-common ques-exp' : 'ques-card-common ques-subCat';
                            return <span key={'qtg-'+i} className={css} style={{marginRight:10}} onClick={(e)=>onSelectTag(e, t)}>{t}</span>
                        })}
                    </div>
                </div>
            </div>
    };

    const AllQuestions = () => {
        let leftItems = [], rightItems=[];
        questionList.map((q,i) => {
            const card = <Card key={'q_'+i} data={q} />;
            i%2 ==0 ? leftItems.push(card) : rightItems.push(card);
        });
        if(loading){
            return <div className="ques-container"> <Spinner color="#D9D9D9" className="ques-bank-spinner" /> </div>
        }
        return <div className="ques-container">
                    <div className="quesInnerHead">
                        <div className="quesTagList">
                            {tags.length ? tags.split(',').map((t, i) => (<div className="tag" style={{marginRight:15}}>{t}<CancelIcon onClick={()=> {deleteTags(i)} }/></div>)) : null}
                        </div>
                        <div className="filter"><TuneIcon/></div>
                    </div>
                    <div className="ques-containerBody">
                        <div style={{width:'100%'}}> {leftItems} </div>
                        <div style={{width:'100%'}}> {rightItems} </div>
                    </div>

            </div>
    };

    const Queue = () => {
        const [hover, setHover] = useState(false);
        const disable = selectedQues.length < 1;
        return <div className="ques-queue-container">
            <div className="ques-queue-containerBody">
                {selectedQues.map((q, i) => {
                    return  <div key={"qbank_"+i} className="queue-card" onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
                        <span className="ques-preview-common" style={{fontSize:18,color:'#8A94A6', width:'100%' }}>{q.question}</span>
                        <div className={"cancelIcon"} onClick={()=>onCancel(i)}>
                            {/*<img src={origin+"/images/SVG/Vector.svg"} width="26" height="28"  alt="icon"/>*/}
                            <object data={origin+"/images/SVG/Vector.svg"} width="26" height="28" style={{transform: 'rotate(45deg)', pointerEvents:'none', width:20}} ></object>
                        </div>
                    </div>
                })
                }
            </div>
                <div className={cn(disable && "disabled", "queue-add-btn")}>
                    <span className="ques-preview-common" style={{color: disable ? "#AEB6CF":"#FFFFFF", fontSize:18}} onClick={addQueue}>Add Form Elements </span>
                </div>

        </div>
    };

    const onKeyPress = (e) => {
        e.key === 'Enter' && searchHandler(serachVal);
    };

    return <Dialogbox open={true} handleClose={onCloseHandle} handleOk={handleOk} dialogContentClass={'question-bank-dialog'}>
                <div className="dialogBoxQuestion" style={{display: 'flex'}}>
                    <div className="closeDailogNew" onClick={onCloseHandle}><HighlightOffTwoToneIcon/></div>
                    <div className="quesBank-left">
                        <div className="question-bank-header">
                            <span className="bank-title">Form Themes </span>
                            <div className="question-bank-search">
                                <div>
                                    <MaterialSelect config={{value: cat, menuItems:categories.map(f => f), fieldClass:"cust-select", onChange: (e)=> setCat(e.target.value) }} variant="filled"/>
                                    
                                    <SearchInput name="search_ques" value={serachVal} onChange={changeHandler} onSearch={searchHandler} className='question-search' placeholder="Search art form elements" placeholderStyle={placeholderStyle} onKeyPress={onKeyPress}/>
                                </div>
                            </div>
                        </div>
                        {/*<div style={{width:'100%', height:50, background:'#F3F4F8', marginTop:75}}></div>*/}
                        <AllQuestions/>
                    </div>

                    <div className="quesBank-right">
                        <div className="ques-preview-common ques-preview"> Preview</div>
                        <div className="ques-preview-common ques-queue"> Form Elements </div>
                        <Queue />

                    </div>

                </div>
            </Dialogbox>
};
const QuestionBankSetting = (props) =>{
    const [dailog, setDailog] = useState(false);
    const [cat, setCat] = useState("All Categories");
    const [val, setVal] = useState('');
    const [categories, setCategories] = useState([])
    const {loading, items} = useSelector(state => state.themes);
    const dispatch = useDispatch();

    useEffect(()=> {
        !loading && !items && dispatch(dashboardActions.getAllThemes());
    }, []);

    useEffect(() => {
        const {experienceType}=props;
        if(!loading && items && items.data){
            const arr = Object.keys(items.data[experienceType]);
            if(arr && arr.length){
                arr.unshift("All Categories")
                setCategories(arr);
            }
        }
    },[loading])

    const onClickHandle = (e) =>{
        setCat(e);
        setDailog(!dailog);
    }
    const onCloseHandle = () =>{
        setDailog(false);
    };

    const onKeyPress = (e) => {
        e.key === 'Enter' && setDailog(true);
    };

    return(
        <>
            <div className={"formatWrapper questionBankSetting"} ref={props.rightMenuActive}>
                <div className="head">Form Themes <QuestionOver/>
                    <div className='surveyMenuCloseBtn'>
                        <button onClick={()=>(props.rightMenuActive).current.classList.toggle('menu-active')}>
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_2441_8773)">
                                <circle cx="9.5" cy="8.5" r="7.5" fill="#18A0FB"/>
                                </g>
                                <g clipPath="url(#clip0_2441_8773)">
                                <path d="M13.6253 3.91797H5.37533C5.25377 3.91797 5.13719 3.96626 5.05123 4.05221C4.96528 4.13817 4.91699 4.25474 4.91699 4.3763V12.6263C4.91699 12.7479 4.96528 12.8644 5.05123 12.9504C5.13719 13.0363 5.25377 13.0846 5.37533 13.0846H13.6253C13.7469 13.0846 13.8635 13.0363 13.9494 12.9504C14.0354 12.8644 14.0837 12.7479 14.0837 12.6263V4.3763C14.0837 4.25474 14.0354 4.13817 13.9494 4.05221C13.8635 3.96626 13.7469 3.91797 13.6253 3.91797ZM10.417 12.168H5.83366V4.83464H10.417V12.168ZM13.167 12.168H11.3337V4.83464H13.167V12.168Z" fill="white"/>
                                </g>
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.44009 9.27037C8.51997 9.18815 8.51997 9.05486 8.44009 8.97264L7.49382 7.9987L8.44009 7.02476C8.51997 6.94254 8.51997 6.80924 8.44009 6.72703C8.36021 6.64481 8.2307 6.64481 8.15082 6.72703L7.05991 7.84983C6.98003 7.93205 6.98003 8.06535 7.05991 8.14756L8.15082 9.27037C8.2307 9.35259 8.36021 9.35259 8.44009 9.27037Z" fill="white" stroke="white" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <defs>
                                <filter id="filter0_d_2441_8773" x="0" y="0" width="19" height="19" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dy="1"/>
                                <feGaussianBlur stdDeviation="1"/>
                                <feComposite in2="hardAlpha" operator="out"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2441_8773"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2441_8773" result="shape"/>
                                </filter>
                                <clipPath id="clip0_2441_8773">
                                <rect width="11" height="11" fill="white" transform="translate(4 3)"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="searchOption">
                    <input type="text" value={val} onChange={(e)=> setVal(e.target.value)} onKeyPressCapture={onKeyPress}/>
                    <PageviewIcon onClick={()=> setDailog(true)}/>
                </div>
                <div className="items">
                    {categories?.length ?
                    <ul>
                        {categories.map((c,i) => (<li key={'cat-'+i} className={cat === c ? "active": ""} onClick={()=>onClickHandle(c)}>{c}</li>) )}
                    </ul> :
                        <div style={{textAlign:'center', marginTop:20}}><span>Loading...</span></div>}
                </div>
            </div>
            {dailog && <QuestionBank {...props} onCloseHandle={onCloseHandle} category={cat} searchBy={val} categories={categories}/>};
        </>
    )
}

export {QuestionBankSetting};
export default QuestionBank;
