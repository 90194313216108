import React from 'react';
import cn from 'classnames';
import Label from './Label';
import '../inputsFields.css';

const TextArea = (props) => {
    const { config: { label, name, className, labelClass, inputClass, textAreaStyle, placeholder, maxLength = 1000, minLength, min, max, autoComplete = 'off' }, value, readOnly = false, onChange, onTouchStart, onTouchEnd, onFocus, onBlur, isDisabled, onClick, onKeyPress, isError, error, qRef } = props;
    return (<label>
        <Label config={{ label, className: labelClass }} />
        <div className={className}>
            <textarea ref={qRef && qRef} className={inputClass} style={textAreaStyle} name={name} placeholder={placeholder} onChange={onChange} autoComplete={autoComplete} value={value} onBlur={onBlur} maxLength={maxLength} onFocus={onFocus}/>
            {isError && <div className={cn("invalid-feedback")}>{error}</div>}
        </div>
    </label>)
};

export default TextArea;