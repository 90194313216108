// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import DataTable from "../../../components/Tables/DataTable";
// import TeamCreationForm from "./TeamCreationForm";
// import { Button, Typography } from "@mui/material";
// import { AdminService } from "../../../services/AdminService";
// import { teamsAction } from "../../../actions/teamsAction";
// import { teamsConstants } from "../../../constants/teamsConstants";


// const TeamTable = () => {
//   const [showForm, setShowForm] = useState(false);
//   const [teamEdit, setTeamEdit] = useState(false);
//   const [isDelete, setIsDelete] = useState(false);
//   const [teams, setTeams] = useState([]); 
//   const [teamsData, setTeamsData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [selectedTeam, setSelectedTeam] = useState(null);

//   const dispatch = useDispatch(); 
//   const isTeamAdded = useSelector((state) => state.teamsLogic.isTeamAdded);


//   const columns = ["team_name","organization_name","domain_name", "contact_person","creator_name","Members_count", "Actions"];
//   const rows = [
//     { "Team Name": "Sales and Marketing", Description: "Handles Sales and client relation", Territories: "North" },
//     { "Team Name": "Technical", Description: "Develop and maintain the product", Territories: "East" },
//     // Add more rows as needed
//   ];

//     // Fetch teams from API
//     const fetchTeams = async () => {
//       setLoading(true);
//       try {
//         const data = await AdminService.getTeams();
//         setTeams(data?.data);      
//       } catch (error) {
//         console.error("Error fetching teams:", error);
//         setTeams([]); // Reset the table to empty if an error occurs
//       } finally {
//         setLoading(false);
//       }
//     };

//     useEffect(() => {
//       fetchTeams();

//       if (isTeamAdded || isDelete || (showForm == false)) {
//         fetchTeams();
//         dispatch({ type: teamsConstants.RESET_TEAM_ADDED }); // Reset flag after re-fetch
//       }
//     }, [isTeamAdded, isDelete, dispatch]);


//   const handleDeleteClick = async (teamId) => {
//     try {
//       await dispatch(teamsAction.deleteTeam(teamId)); 

//       // Fetch updated agents list after deletion
//       setIsDelete(true)
//       await fetchTeams();
//     } catch (error) {
//       console.error("Error deleting agent:", error);
//     }
//     finally {
//       setIsDelete(false);
//     }
//   };


//   const enhancedRows = teams?.map((row) => ({
//     ...row,
//     Members_count: Array.isArray(row.members) ? row.members.length : 0,    
//     Actions: (
//       <>
//         <Button
//           variant="outlined"
//           color="primary"
//           size="small"
//           onClick={() => {
//             setSelectedTeam(row); // Set the selected role for editing
//             //setOpenCreate(true); // Open the form
//             setShowForm(true);
//             setTeamEdit(true);
//           }}          
//           style={{ marginRight: "10px" }}
//         >
//           Edit
//         </Button>
//         <Button
//           variant="outlined"
//           color="secondary"
//           size="small"
//           onClick={() => handleDeleteClick(row.id)}
//         >
//           Delete
//         </Button>
//       </>
//     ),
//   }));

//   const handleAddTeam = () => {
//     setShowForm(true);
//     setSelectedTeam(null)
//   };

//   const handleCancelForm = () => {
//     setShowForm(false);
//   };

//   return (
//     <div>

//       {showForm && (
//         <div style={{ marginBottom: "20px" }}>
//           <TeamCreationForm 
//           onCancel={handleCancelForm}
//           teamData={selectedTeam}
//           teamEdit={teamEdit}
//            />
//         </div>
//       )}
//       <Typography variant="h6" gutterBottom>
//         Team Details
//       </Typography>
//       <DataTable columns={columns} rows={enhancedRows} loading={loading} tableType="Team" />
//       <div style={{ marginBottom: "20px" }}>
//         <Button variant="contained" color="primary" onClick={handleAddTeam}>
//           Add sub Team
//         </Button>
//       </div>
//       <DataTable columns={columns} rows={enhancedRows} loading={loading} tableType="Team" />

//     </div>
//   );
// };

// export default TeamTable;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../components/Tables/DataTable";
import TeamCreationForm from "./TeamCreationForm";
import { Button, Typography } from "@mui/material";
import { AdminService } from "../../../services/AdminService";
import { teamsAction } from "../../../actions/teamsAction";
import { teamsConstants } from "../../../constants/teamsConstants";
import SubTeamForm from "./SubTeamForm";

const TeamTable = () => {
  const [showForm, setShowForm] = useState(false);
  const [showSubTeamForm, setShowSubTeamForm] = useState(false);
  const [teamEdit, setTeamEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [teams, setTeams] = useState([]);
  const [subTeams, setsubTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedSubTeam, setSelectedSubTeam] = useState(null);

  const dispatch = useDispatch();
  const isTeamAdded = useSelector((state) => state.teamsLogic.isTeamAdded);

  const columns = ["team_name", "organization_name", "domain_name", "contact_person", "creator_name", "Members_count", "Actions"];

  const subTeamsData = [
    { name: "Sub Team A", leader: "Alice", members: 5 },
    { name: "Sub Team B", leader: "Bob", members: 3 },
  ]

  useEffect(() => {
    fetchTeams();
    fetchSubTeams();
    if (isTeamAdded || isDelete || showForm === false) {
      fetchTeams();
      dispatch({ type: teamsConstants.RESET_TEAM_ADDED });
    }
  }, [isTeamAdded, isDelete, dispatch]);

  const fetchTeams = async () => {
    setLoading(true);
    try {
      const data = await AdminService.getTeams();
      setTeams(data?.data);
    } catch (error) {
      console.error("Error fetching teams:", error);
      setTeams([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchSubTeams = async () => {
    setLoading(true);
    try {
      const data = await AdminService.getSubTeams();
      setsubTeams(data?.data);
    } catch (error) {
      console.error("Error fetching teams:", error);
      setsubTeams([]);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = async (teamId) => {
    try {
      await dispatch(teamsAction.deleteTeam(teamId));

      // Fetch updated agents list after deletion
      setIsDelete(true)
      await fetchTeams();
    } catch (error) {
      console.error("Error deleting agent:", error);
    }
    finally {
      setIsDelete(false);
    }
  };

  const handleDeleteSubTeam = async (subTeamId) => {
    try {
      await AdminService.deleteSubTeam(subTeamId);
      fetchSubTeams();
    } catch (error) {
      console.error("Error deleting sub-team:", error);
    }
  };

  const enhancedRows = teams?.map((row) => ({
    ...row,
    Members_count: Array.isArray(row.members) ? row.members.length : 0,
    Actions: (
      <>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            setSelectedTeam(row); // Set the selected role for editing
            //setOpenCreate(true); // Open the form
            setShowForm(true);
            setTeamEdit(true);
          }}
          style={{ marginRight: "10px" }}
        >
          Edit
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => handleDeleteClick(row.id)}
        >
          Delete
        </Button>
      </>
    ),
  }));


  const handleAddSubTeam = () => {
    setShowSubTeamForm(true);
    setSelectedSubTeam(null);
  };

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <Button variant="contained" color="primary" onClick={handleAddSubTeam}>
          Add Sub Team
        </Button>
      </div>

      {showSubTeamForm && (
        <SubTeamForm
          onCancel={() => setShowSubTeamForm(false)}
          onSubTeamAdded={fetchSubTeams}
          subTeamData={selectedSubTeam}
        />
      )}
      {showForm && <TeamCreationForm onCancel={() => setShowForm(false)} teamData={selectedTeam} teamEdit={teamEdit} />}

      <Typography variant="h6" gutterBottom>
        Team Details
      </Typography>

      <DataTable
        columns={columns}
        rows={enhancedRows}
        loading={loading}
        tableType="Team"
        hasSubTable={true}
        subTeams={subTeams}
        onEditSubTeam={(subTeam) => {
          setSelectedSubTeam(subTeam);
          setShowSubTeamForm(true);
        }}
        onDeleteSubTeam={handleDeleteSubTeam}
      />

    </div>
  );
};

export default TeamTable;
