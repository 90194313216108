import React, { useEffect, useState } from "react";
import Dialogbox from "../../Common/DialogBox";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { getUser, getLocalStorage } from "../../Common/Utils";
import { workspaceActions } from "../../../actions";
import { useDispatch } from "react-redux";
// import { useGoogleLogin } from "react-google-login";
import { useGoogleLogin } from '@react-oauth/google';
import TextEditor from "./Editor";
import { workspaceService } from "../../../services";

import {
  CLIENT_ID,
  API_KEY,
  DISCOVERY_DOCS,
  SCOPES,
} from "../../Common/Constants";
import { getAccessToken } from "../../../components/Email_Integration/Outlook";

const loadGoogleScript = () => {
  //loads the Google JavaScript Library
  (function () {
    const id = "google-js";
    const src = "https://apis.google.com/js/platform.js";

    //we have at least one script (React)
    const firstJs = document.getElementsByTagName("script")[0];

    //prevent script from loading twice
    if (document.getElementById(id)) {
      return;
    }
    const js = document.createElement("script");
    js.id = id;
    js.src = src;
    js.onload = window.onGoogleScriptLoad;
    firstJs.parentNode.insertBefore(js, firstJs);
  })();
};

const ComposeByGmail = ({
  surveyID,
  show,
  isSchedule,
  handleClose,
  records = [],
  emailType,
  email,
}) => {
  const [body, setBody] = useState({ subject: "", content: "" });
  const [gAuth, setGoogleAuth] = useState();
  const [gapi, setGapi] = useState();
  const [accessToken, setAccessToken] = useState();
  const [fromEmail, setFromEmail] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    //window.gapi is available at this point
    window.onGoogleScriptLoad = () => {
      const _gapi = window.gapi;
      setGapi(_gapi);
      _gapi.load("auth2", () => {
        (async () => {
          const _googleAuth = await _gapi.auth2.init({
            client_id: CLIENT_ID,
            api_key: API_KEY,
            discoveryDocs: DISCOVERY_DOCS,
            scope: SCOPES,
          });

          console.log("_googleAuth", _googleAuth);

          setGoogleAuth(_googleAuth);
        })();
      });
    };
    //ensure everything is set before loading the script
    loadGoogleScript();
  });
  useEffect(() => {
    gAuth && getToken();
  }, [gAuth]);

  const onSuccess = (response) => {
    const { code } = response;
    try {
      const params = {
        code: code,
      };
      workspaceService.oauth2TokenExchange(params).then((res) => {
        setAccessToken(res.data.access_token);
        setTimeout(() => {
          sendEmail(res.data.access_token, '');
        }, 100);
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const onFailure = (res) => {
    console.log("onFailure ", res);
  };

  const signIn = useGoogleLogin({
    onSuccess,
    onFailure,
    scope: SCOPES,
    flow: 'auth-code',
  });

  const getToken = () => {
    try {
      let googleA = window.gapi.auth2.getAuthInstance();

      let googleUser = googleA.currentUser.get();
      let authResp = googleUser.getAuthResponse(true);
      let profile = googleUser.getBasicProfile();

      if (authResp) {
        let access_token = authResp["access_token"];
        setAccessToken(authResp["access_token"]);
        setFromEmail(profile.getEmail());
      }
    } catch (e) {
      console.log("Access token not fond", e);
    }
  };

  const onChange = (e) => {
    const { name = "", value = "" } = e && e.target ? e.target : {};
    setBody((body) => ({ ...body, [name]: value }));
  };
  const reset = () => {
    setBody({ subject: "", content: "" });
  };
  const sendEmail = (token, senderEmail) => {
    if (!accessToken && !token) {
      signIn();
      return;
    }
    console.log("sendEmail", accessToken, token);
    try {
      const timezoneOffset = new Date().getTimezoneOffset() / 60;
      const params = {
        survey_id: surveyID,
        access_token: token ? token : accessToken,
        email_type: emailType,
        subject: body.subject,
        body: body.content,
        sender_email: email,
        sender_name: "",
        records: records,
        schedule: body.schedule,
        interval: body.interval,
        timezone: timezoneOffset,
      };
      dispatch(workspaceActions.shareSurveyByEmail(params));
      reset();
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Dialogbox
      open={show}
      rootClass={"addLogoDialog addPageTitleDialog"}
      handleClose={handleClose}
      dialogContentClass={"pageTitleDialogInner"}
    >
      <EmailBody
        body={body}
        setBody={setBody}
        onChange={onChange}
        handleClose={handleClose}
        sendEmail={sendEmail}
        signIn={signIn}
        isSchedule={isSchedule}
      />
    </Dialogbox>
  );
};



const ComposeByOutlook = ({
  surveyID,
  show,
  isSchedule,
  handleClose,
  records = [],
  emailType,
}) => {
  const auth = getLocalStorage("auth") || {};
  const [body, setBody] = useState({ subject: "", content: "" });
  const [token, setToken] = useState(auth.accessToken || "");
  const dispatch = useDispatch();
  useEffect(() => {
    getAccessToken((res, err) => {
      if (res) {
        setToken(res.accessToken);
      }
    });
  }, []);
  const reset = () => {
    setBody({ subject: "", content: "" });
  };
  const onChange = (e) => {
    const { name = "", value = "" } = e && e.target ? e.target : {};
    setBody((body) => ({ ...body, [name]: value }));
  };
  const sendEmail = () => {
    try {
      const timezoneOffset = new Date().getTimezoneOffset() / 60;
      const params = {
        survey_id: surveyID,
        access_token: token,
        email_type: emailType ? emailType : "",
        subject: body.subject,
        body: body.content,
        sender_email: auth.email || "anon@anonymous.com",
        sender_name: auth.name || "",
        records: records,
        schedule: body.schedule,
        interval: body.interval,
        timezone: timezoneOffset,
      };
      dispatch(workspaceActions.shareSurveyByEmail(params));
      reset();
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Dialogbox
      open={show}
      rootClass={"addLogoDialog addPageTitleDialog textEditorDialog"}
      handleClose={handleClose}
      dialogContentClass={"pageTitleDialogInner"}
    >
      <EmailBody
        body={body}
        onChange={onChange}
        handleClose={handleClose}
        setBody={setBody}
        sendEmail={sendEmail}
        isSchedule={isSchedule}
      />
    </Dialogbox>
  );
};

const EmailBody = ({ body, onChange, handleClose, sendEmail, setBody,signIn, isSchedule }) => {
  return (
    <div className="form">
      <Grid container spacing={2}>
        {isSchedule && (
          <Grid item xs={12}>
            <label>Schedule at</label>
            <TextField
              hiddenLabel
              id="filled-hidden-label-small"
              value={body.schedule}
              variant="outlined"
              size="small"
              fullWidth
              name="schedule"
              type="datetime-local"
              onChange={onChange}
            />
          </Grid>
        )}

        {isSchedule && (
          <Grid item xs={12}>
            <label>Interval (in minutes)</label>
            <TextField
              hiddenLabel
              id="filled-hidden-label-small"
              value={body.interval}
              variant="outlined"
              size="small"
              fullWidth
              name="interval"
              type="number"
              onChange={onChange}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <label>Subject</label>
          <TextField
            hiddenLabel
            id="filled-hidden-label-small"
            value={body.subject}
            variant="outlined"
            size="small"
            fullWidth
            name="subject"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <label>Content</label>
          {/* <TextField
                 hiddenLabel
                 id="filled-hidden-label-small"
                 value={body.content}
                 variant="outlined"
                 size="small"
                 fullWidth
                 name='content'
                 multiline = {true}
                 inputProps={{style:{height: 60}}}
                 onChange={onChange}
                 /> */}
          <div>
            {console.log("fdfdf", body.content)}
            <TextEditor
              editorValue={body.content}
              onChange={(newContent) => {
                console.log("sddsdsksdk", newContent);
                setBody((body) => ({ ...body, content: newContent }));
              }}
            />
          </div>
        </Grid>
      </Grid>
      <div className="btnGroup">
        <button onClick={handleClose} className="btn btnCancel">
          Cancel
        </button>
        <button onClick={() => sendEmail()} className="btn btnSave">
          Send
        </button>
      </div>
    </div>
  );
};

const Compose = (props) => {
  const usr = getUser();
  if (usr && usr.social_type === "gmail") {
    return (
      <ComposeByGmail
        {...props}
        emailType={usr.social_type}
        email={usr.email}
      />
    );
  } else {
    return <ComposeByOutlook {...props} emailType={usr.social_type} />;
  }
};

export { Compose };
