import { useEffect, useState } from "react";
import { Card, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../CSS/editCustomerDetails.css";
import { Header, MainMenu } from "../../Common/Header";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { crmActions } from "../../../actions";
import { crmConstants } from "../../../constants/crmConstants";
import { history } from "../../../helpers";
import { timezones } from "./timezones";

const EditCustomerDetails = () => {
  const singleCustomerData = useSelector((state) => state.crmLogic);
  console.log(singleCustomerData, "<==singleCustomerData");
  const customerData = singleCustomerData?.message?.data;
  const { id } = useParams();
  const dispatch = useDispatch();
  const type = useSelector((state) => state?.crmLogic?.type);

  useEffect(() => {
    if (id) {
      dispatch(crmActions.getCustomerDataById(id));
    }
  }, [dispatch, id]);

  const initialUserData = {
    personalInfo: {
      title: "Personal Information",
      fields: {
        firstName: customerData?.first_name || "",
        lastName: customerData?.last_name || "",
        email: customerData?.email || "",
        title: customerData?.title || "",
        country: customerData?.country || "",
        state: customerData?.state || "",
        timezone: customerData?.timezone || "",
      },
    },
    companyInfo: {
      title: "Company Information",
      fields: {
        company: customerData?.company || "",
        companyEmail: customerData?.company_name_for_emails || "",
        companyState: customerData?.company_state || "",
      },
    },
    contactInfo: {
      title: "Contact Information",
      fields: {
        emailConfidence: customerData?.email_confidence || "",
        seniority: customerData?.seniority || "",
        department: customerData?.departments || "",
        contactOwner: customerData?.contact_owner || "",
      },
    },
    phoneNumbers: {
      title: "Phone Numbers",
      fields: {
        firstPhone: customerData?.first_phone || "",
        workDirectPhone: customerData?.work_direct_phone || "",
      },
    },
  };

  const [formData, setFormData] = useState(initialUserData);

  useEffect(() => {
    setFormData(initialUserData);
  }, [customerData]);

  useEffect(() => {
    if (type === crmConstants.EDIT_CUSTOMER_SUCCESS) {
      history.push("/customerTable");
    }
  }, [type]);

  const handleInputChange = (section, field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        fields: {
          ...prevState[section].fields,
          [field]: value,
        },
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      first_name: formData.personalInfo.fields.firstName || "",
      last_name: formData.personalInfo.fields.lastName || "",
      title: formData.personalInfo.fields.title || "",
      country: formData.personalInfo.fields.country || "",
      state: formData.personalInfo.fields.state || "",
      timezone: formData.personalInfo.fields.timezone || "",
      company_name_for_emails: formData.companyInfo.fields.companyEmail || "",
      company: formData.companyInfo.fields.company || "",
      email: formData.personalInfo.fields.email || "",
      email_confidence: formData.contactInfo.fields.emailConfidence || "",
      seniority: formData.contactInfo.fields.seniority || "",
      departments: formData.contactInfo.fields.department || "",
      contact_owner: formData.contactInfo.fields.contactOwner || "",
      first_phone: formData.phoneNumbers.fields.firstPhone || "",
      work_direct_phone: formData.phoneNumbers.fields.workDirectPhone || "",
      // add other fields as needed...
    };
    trigger(payload, id);
  };

  const trigger = (payload, id) => {
    dispatch(crmActions.updateCustomerDataFormAPI(id, payload));
  };

  return (
    <>
      {/* <Header /> */}
      {/* <MainMenu /> */}
      <div className="container mt-3 info-form-container">
        <h2>Edit Customer Details</h2>
        <Form onSubmit={handleSubmit}>
          {Object.entries(formData).map(([sectionKey, section]) => (
            <Card className="mb-4 info-card" key={sectionKey}>
              <Card.Header>{section.title}</Card.Header>
              <Card.Body>
                {Object.entries(section.fields).map(([fieldKey, value]) => (
                  <FormSection
                    key={fieldKey}
                    sectionKey={sectionKey}
                    fieldKey={fieldKey}
                    value={value}
                    handleInputChange={handleInputChange}
                  />
                ))}
              </Card.Body>
            </Card>
          ))}
          <Button variant="primary" type="submit" className="save-btn">
            Save Changes
          </Button>
        </Form>
      </div>
    </>
  );
};

const FormSection = ({ sectionKey, fieldKey, value, handleInputChange }) => (
  <Form.Group className="mb-3" controlId={`form${fieldKey}`}>
    <Form.Label>
      {fieldKey.charAt(0).toUpperCase() +
        fieldKey.slice(1).replace(/([A-Z])/g, " $1")}
    </Form.Label>
    <Form.Control
      type="text"
      value={value}
      onChange={(e) => handleInputChange(sectionKey, fieldKey, e.target.value)}
    />
  </Form.Group>
);

export default EditCustomerDetails;
