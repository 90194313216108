import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Tooltip 
} from "@mui/material";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { dropDownActions } from "../../actions";
import { crmActions } from "../../actions";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "600px",
    borderRadius: "16px",
    padding: theme.spacing(2),
  },
}));

const CustomerCreationModal = ({ open, onClose , phone}) => {
  const dispatch = useDispatch();
  const countries = useSelector((state) => state?.dropDown?.country?.data);
  const states = useSelector((state) => state?.dropDown?.states?.data?.states);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    departments: "",
    mobilePhone:`${phone}` ,
    workPhone: "",
    country: "",
    state: "",
    city: "",
    annualRevenue: "",
  });

  const [stateCode, setStateCode] = useState("");
  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    dispatch(dropDownActions.fetchCountriesOptions());
  }, [dispatch]);

  useEffect(() => {
    if (countryCode) {
      dispatch(dropDownActions.fetchStatesOptions(countryCode));
    }
  }, [dispatch, countryCode]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isFormValid = Object.values(formData).every((value) => value.trim() !== "");

  const handleSubmit = () => {
    const customerData = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      company: formData.company,
      title: "", 
      account_owner: "", 
      account_team: "", 
      annual_revenue: formData.annualRevenue?.toString() || "0",
      city: formData.city,
      company_address: "", 
      company_city: "", 
      company_country: "", 
      company_phone: "", 
      company_state: "", 
      country: countryCode, 
      departments: formData.departments ? [formData.departments] : [], 
      facebook_url: "", 
      first_phone: formData.mobilePhone, 
      industry: "", 
      keywords: "", 
      linkedin_url: "", 
      mobile_phone: formData.mobilePhone,
      number_of_employees: 0, 
      state: stateCode, 
      technologies: "", 
      timezone: "Europe/Vienna", 
      twitter_url: "", 
      work_direct_phone: formData.workPhone,
    };
  
    console.log("Customer Data to be Submitted:", customerData);
  
    // Dispatch the action to add the customer
    dispatch(crmActions.addCustomer(customerData));
  
    // Close the modal after submission
    onClose();
  };
  

  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h6" fontWeight="bold">
          Create New Customer
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box component="form" noValidate sx={{marginTop: "10px"}}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label={<><span>First Name</span><span style={{ color: "red" }}> *</span></>}
                name="firstName"
                required
                fullWidth
                value={formData.firstName}
                onChange={handleChange}
                
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={<><span>Last Name</span><span style={{ color: "red" }}> *</span></>}
                name="lastName"
                fullWidth
                value={formData.lastName}
                onChange={handleChange}
                
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={<span>Email</span>}
                name="email"
                fullWidth
                value={formData.email}
                onChange={handleChange}
                
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={<><span>Company</span></>}
                name="company"
                fullWidth
                value={formData.company}
                onChange={handleChange}
                
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={<><span>Mobile Phone</span><span style={{ color: "red" }}> *</span></>}
                name="mobilePhone"
                required
                fullWidth
                value={formData.mobilePhone}
                onChange={handleChange}
                
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default CustomerCreationModal;
