import { territoryConstants } from "../constants/TerritoryConstants";
import { AdminService } from "../services/AdminService";
import { swalAlert } from "../voc/Common/SwalAlert";

const addTerritory = (TerritoryData, Id) => {
  const request = () => ({
    type: territoryConstants.ADD_TERRITORY_REQUEST,
  });

  const success = (territory) => ({
    type: territoryConstants.ADD_TERRITORY_SUCCESS,
    territory,
  });

  const failure = (error) => ({ 
    type: territoryConstants.ADD_TERRITORY_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    return AdminService.addTerritory(TerritoryData, Id) 
      .then((response) => {
        dispatch(success(response));

        swalAlert({
          icon: "success",
          title: "Territory added successfully!",
          showConfirmButton: true,
        });

        return response; 
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message?.toString();
        dispatch(failure(errorMessage));

        swalAlert({
          icon: "error",
          title: errorMessage,
          showConfirmButton: true,
        });

        throw error; 
      });
  };
};


const updateTerritory = (TerritoryData, territoryId) => {
  
  const request = () => ({
    type: territoryConstants.EDIT_TERRITORY_REQUEST,
  });

  const success = (alerts) => ({
    type: territoryConstants.ADD_TERRITORY_SUCCESS,
    alerts,
  });

  const failure = (error) => ({
    type: territoryConstants.EDIT_TERRITORY_FAILURE,
    error,
  });
  return (dispatch) => {
    dispatch(request());
    AdminService.updateTerritory(TerritoryData, territoryId).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Territory updated successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const deleteTerritory = (territoryId) => {  
  const request = () => ({
    type: territoryConstants.DELETE_TERRITORY_REQUEST,
  });
  
  const success = (noteId) => ({
    type: territoryConstants.DELETE_TERRITORY_SUCCESS,
    noteId,
  });
  
  const failure = (error) => ({
    type: territoryConstants.DELETE_TERRITORY_FAILURE, 
    error,
  });
  
  return (dispatch) => {
    dispatch(request());
    AdminService.deleteTerritory(territoryId).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Territory deleted successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const assignTerritory = (userId, TerritoryId) => {
  const request = () => ({
    type: territoryConstants.ASSIGN_TERRITORY_REQUEST,
  });

  const success = (assignment) => ({
    type: territoryConstants.ASSIGN_TERRITORY_SUCCESS,
    assignment,
  });

  const failure = (error) => ({
    type: territoryConstants.ASSIGN_TERRITORY_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());
    AdminService.AssignTerritory(userId, TerritoryId).then(
      (response) => {
        dispatch(success(response));
        swalAlert({
          icon: "success",
          title: "Territory assigned successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const multiAssignTerritory = (userId, TerritoryIds) => {
  const request = () => ({
    type: territoryConstants.ASSIGN_TERRITORY_REQUEST,
  });

  const success = () => ({
    type: territoryConstants.ASSIGN_TERRITORY_SUCCESS,
  });

  const failure = (error) => ({
    type: territoryConstants.ASSIGN_TERRITORY_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());
    AdminService.MultiAssignTerritory(userId, TerritoryIds).then(
      () => {
        dispatch(success());
        swalAlert({
          icon: "success",
          title: "Territories assigned successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

export const territoryAction = {
  addTerritory,
  updateTerritory,
  deleteTerritory,
  assignTerritory,
  multiAssignTerritory
};
