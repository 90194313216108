import React, { useState, useEffect, useRef } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MaterialSelect } from "../../../common/controls/inputs/Material";
import Prompt from "../../Common/ConfirmationPrompt";
import { async } from "q";
import api from "../../../../src/services/api";
import { clone } from "../../Common/Utils";
import FormControl from "@mui/material/FormControl";
import { Select } from "antd";
import MenuItem from "@mui/material/MenuItem";

const origin = window.location.origin;
const checkStyle = {
  position: "absolute",
  top: -9,
  right: -6,
  background: "#fff",
  borderRadius: "50%",
  zIndex: "1",
};

const buttonStyles = {
  contained: {
    backgroundColor: "#18A0FB",
    color: "#fff",
    border: "none",
  },
  outlined: {
    backgroundColor: "transparent",
    color: "#007bff",
    border: "1px solid #18A0FB",
  },
};

const PaymentChoice = ({
  onTypeSelect,
  quesNo,
  quesType,
  setQuesType,
  value,
  onChange,
  onSave,
  ansTypes,
  children,
}) => {
  const {
    question = "",
    desc = "",
    settings = {},
    if_payment_type = [],
  } = value;
  const {
      font,
      underline,
      bold,
      fontSize,
      italic,
      fontColor,
      layout,
      supersize,
      picturealign,
    } = settings,
    style = {
      fontFamily: font,
      fontSize,
      fontWeight: bold ? "bold" : "",
      fontStyle: italic ? "italic" : "",
      textDecoration: underline ? "underline" : "",
      color: fontColor,
    };
  const [selectedPic, setSelection] = useState([]);
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState([]);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [isChangeFieldfff, setIsChangeFieldfff] = React.useState(false);
  const [isChangeField, setIsChangeField] = React.useState(false);
  const [err, setErr] = useState({
    email: "",
    phone: "",
    methodArray: "",
    // selectedAmountErr:"",
    selectedPeriod: "",
    // Add other fields here as needed
  });
  const [amountId, setAmountId] = useState(1);
  const [paymentId, setPaymentId] = useState(1);
  const [selectedAmount, setSelectedAmount] = useState("");
  const [selectedAmountErr, setSelectedAmountErr] = useState("");
  const [selectedAmountOther, setSelectedAmountOther] = useState("");
  const [isInputVisible, setInputVisible] = useState(false); // State to control input field visibility
  const [payment, setPayment] = useState([
    {
      name: "",
      lastname: "",
      email: "",
      phone: "",
      amounts: {
        q_type: "Multiple",
        qus_id: 6,
        is_payment: true,
        question: "Amount",
        ans_options: [],
        paymentGroup: "MQhF0x",
        can_be_updated: true,
      },
      paymentMethods: {
        q_type: "Multiple",
        qus_id: 5,
        is_payment: true,
        question: "Payment Methods",
        ans_options: [],
        paymentGroup: "MQhF0x",
        can_be_updated: true,
      },
      period: {
        q_type: "Multiple",
        qus_id: 7,
        is_payment: true,
        question: "Period Type",
        ans_options: [],
        paymentGroup: "MQhF0x",
        can_be_updated: true,
      },
    },
  ]);
  useEffect(() => {
    console.log("testmtn", if_payment_type);
    if (if_payment_type && if_payment_type.length > 0) {
      setPayment(if_payment_type);
      setMethodArray(if_payment_type[0]?.paymentMethods?.ans_options);
      setPaymentArray(if_payment_type[0]?.amounts?.ans_options);
      setSelectedPeriod(if_payment_type[0]?.period?.ans_options);
    } else {
      setPayment([
        {
          name: "",
          lastname: "",
          email: "",
          phone: "",
          amounts: {
            q_type: "Multiple",
            qus_id: 6,
            is_payment: true,
            question: "Amount",
            ans_options: [],
            paymentGroup: "MQhF0x",
            can_be_updated: true,
          },
          paymentMethods: {
            q_type: "Multiple",
            qus_id: 5,
            is_payment: true,
            question: "Payment Methods",
            ans_options: [],
            paymentGroup: "MQhF0x",
            can_be_updated: true,
          },
          period: {
            q_type: "Multiple",
            qus_id: 7,
            is_payment: true,
            question: "Period Type",
            ans_options: [],
            paymentGroup: "MQhF0x",
            can_be_updated: true,
          },
        },
      ]);
      setMethodArray([]);
      setPaymentArray([]);
      setSelectedPeriod([]);
    }
  }, [if_payment_type]);
  const [amount, setAmount] = useState([]);
  const [period, setPeriod] = useState([
    { name: "p1", value: "One Time" },
    { name: "p2", value: "Recurring-Month" },
    { name: "p3", value: "Recurring-Year" },
  ]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [customizeAmount, setCustomizeAmount] = useState("");
  const [methodArray, setMethodArray] = useState([]);
  const [newAmount, setNewAmount] = useState(false);
  const [amount11, setAmount11] = useState(""); // State to store the entered amount
  const [amountsArray, setAmountsArray] = useState([]); // State to store the array of amounts
  const [paymentArray, setPaymentArray] = useState([]);
  const [open, setOpen] = useState({}),
    onDropHandle = (e, drop, isFilter1) => {
      setOpen({});
      setOpen({ [drop]: !open[drop] });
    };

  const wrapperRef = useRef(null);

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const onPaymentBlur = (e) => {
    if (e && e.target) {
      const cloned = clone(payment);
      console.log(cloned, "CLONED,<><><");
      cloned[0][e.target.name] = e.target.value;
      setPayment(cloned);
      onChange({ name: "if_payment_type", value: cloned });
    }
  };

  const handleAmount = (value) => {
    console.log("valuefafa", value);
    setSelectedAmount(value);
    const cloned = clone(payment);
    // cloned[0].amounts.push({label:value});
    console.log(cloned, "CDCDCD<><??>?><><>", value);
    cloned[0]["amounts"]["ans_options"] = value ? value : 0;
    setPayment(cloned);
    onChange({ name: "if_payment_type", value: cloned });
    // onChange({ name: "if_payment_type", value: [{ name: "", lastname: "", email: "", phone: "", amounts: cloned[0]["amounts"], paymentMethods: value, period: if_payment_type[0].period.ans_options }] });
  };

  const handleMethods = (value) => {
    const o = value;
    const newVar = [];
    let original = newVar.push(o);
    console.log(original, "VALUE,<><><");
    const cloned = clone(payment);
    cloned[0]["paymentMethods"]["ans_options"] = value;
    setPayment(cloned);
    onChange({
      name: "if_payment_type",
      value: cloned,
    });
  };

  const handlePeriod = (value, index) => {
    let pay = [...selectedPeriod];

    let methodExists = pay.some((item) => item.label === value);

    if (!methodExists) {
      pay.push({ label: value, id: index + 1 });
    } else {
      pay = pay.filter((item) => item.label !== value);
    }

    setSelectedPeriod(pay);

    const cloned = clone(payment);
    cloned[0]["period"]["ans_options"] = pay;

    onChange({
      name: "if_payment_type",
      value: cloned,
    });
  };

  const getPaymentMethods = async () => {
    const result = await api.get(`/getPaymentMethods`, {
      params: {
        jwt_authentication: localStorage.getItem("token"),
      },
    });
    setPaymentMethods(result?.data?.payment_methods);
    return result;
  };

  const changeField = () => {
    isChangeField ? setIsChangeField(false) : setIsChangeField(true);
  };

  const validateFields = () => {
    const newErrors = { ...err };
    let isValid = true;
    const errors = {};

    if (!methodArray.length) {
      newErrors.methodArray = "Please select at least one payment method.";
      isValid = false;
    } else {
      newErrors.methodArray = "";
    }

    if (!selectedPeriod.length) {
      newErrors.selectedPeriod = "Please select a period.";
      isValid = false;
    } else {
      newErrors.selectedPeriod = "";
    }

    setErr(newErrors);

    return isValid;
  };

  const beforeOnSave = () => {
    if (validateFields()) {
      // All fields are valid, proceed with saving
      onSave && onSave();
    } else {
      // There are validation errors, display them
      // You can choose to show the errors as needed
      console.log("Validation errors:", err);
    }
  };

  // const handleRemoveMethod = (value) =>{
  //  let selectedMethods = methodArray
  //  let newSelectedValue = selectedMethods.filter((item)=> {
  //     if(item !== value){
  //       return item
  //     }
  //   })

  //   setMethodArray(newSelectedValue)
  //  }

  const handleRemoveMethod = (value) => {
    // Remove the item from methodArray
    const newSelectedValue = methodArray.filter((item) => item !== value);

    // Set the updated methodArray
    setMethodArray(newSelectedValue);

    // Update the payment data and save it
    const cloned = clone(payment);
    cloned[0]["paymentMethods"]["ans_options"] = newSelectedValue;

    onChange({
      name: "if_payment_type",
      value: cloned,
    });
  };
  const handleRemovePayment = (value) => {
    const newSelectedValue = paymentArray.filter((item) => item !== value);

    setPaymentArray(newSelectedValue);

    const cloned = clone(payment);
    cloned[0]["amounts"]["ans_options"] = newSelectedValue;

    onChange({
      name: "if_payment_type",
      value: cloned,
    });
  };

  const handlePaymentChange = (value) => {
    let pay = [...methodArray];
    setPaymentId(paymentId + 1);
    let methodExists = pay.some((item) => item.label === value);
    if (!methodExists) {
      pay.push({ label: value, id: paymentId + 1 });
    }
    setMethodArray(pay);
    setIsChangeFieldfff(!isChangeFieldfff);
    handleMethods(pay);
  };

  const handleAmountChange = () => {
    setSelectedAmountErr(amount);
    if (amount.trim() !== "") {
      const numericAmount = parseFloat(amount); // Convert to a number
      setSelectedAmount(amount);
      if (!isNaN(numericAmount)) {
        setAmountId(amountId + 1);
        const pay = [...paymentArray];
        const methodExists = pay.some((item) => item.label === numericAmount);
        if (!methodExists) {
          pay.push({ label: numericAmount, id: amountId + 1 });
          setPaymentArray(pay);
          setIsChangeFieldfff(!isChangeFieldfff);
          handleAmount(pay);
        }
      }
      setInputVisible(false);
      setAmount("");
    }
  };

  const handleAmountChangeOther = () => {
    let value = { label: "other", id: amountId + 1 };
    setSelectedAmountOther(value);
    const pay = [...paymentArray];
    const methodExists = pay.some((item) => item.label === "other");
    if (!methodExists) {
      pay.push({ label: "other", id: amountId + 1 });
      setPaymentArray(pay);
      setIsChangeFieldfff(!isChangeFieldfff);
      handleAmount(pay);
    }
  };

  const handleAddAmount = () => {
    if (amount11.trim() !== "") {
      setAmountsArray([...amountsArray, amount11]);
      setAmount11(""); // Clear the input field after saving
    }
  };

  return (
    <div className="paymentForm">
      <h2 className="paymentForm__title">Payment</h2>
      <div className="paymentForm__controls--title">
        1. ENTER CONTACT INFORMATION
      </div>
      <div className="paymentForm__controls">
        <div className="paymentForm__control">
          <label style={style}>* First Name</label>
          <input
            style={style}
            type="text"
            placeholder="First Name"
            name="name"
            onBlur={onPaymentBlur}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          {/* { err.name ? <span className="error" style={{color:'red'}}>{err.name}</span> : null } */}
        </div>

        <div className="paymentForm__control">
          <label style={style}>* Last Name</label>
          <input
            style={style}
            type="text"
            placeholder="Last Name"
            name="lastname"
            onBlur={onPaymentBlur}
            value={lastname}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
          {/* { err.lastname ? <span className="error" style={{color:'red'}}>{err.lastname}</span> : null } */}
        </div>

        <div className="paymentForm__control">
          <label style={style}>* Email</label>
          <input
            style={style}
            type="email"
            placeholder="my@name.com"
            name="email"
            onBlur={onPaymentBlur}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <span className="paymentForm__control--text">
            You'll receive email updates from Articence. You can unsubscribe at
            any time.
          </span>
          {err.email ? (
            <span className="error" style={{ color: "red" }}>
              {err.email}
            </span>
          ) : null}
        </div>

        <div className="paymentForm__control">
          <label style={style}>Phone</label>
          <input
            style={style}
            type="tel"
            placeholder="1-499-345-7890"
            name="phone"
            onBlur={onPaymentBlur}
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
          <span className="paymentForm__control--text">
            By sharing your phone number, you give Articence permission to
            contact you with updates via phone and text.
          </span>
          {err.phone ? (
            <span className="error" style={{ color: "red" }}>
              {err.phone}
            </span>
          ) : null}
        </div>
      </div>

      <div className="paymentForm__controls--title">
        2. SELECT PAYMENT METHOD
      </div>
      <div className="paymentForm__controls">
        <div className="paymentForm__addButton">
          <div className="dropDownWrap">
            <FormControl>
              {paymentMethods && (
                <Select
                  value={"Add Method"}
                  onChange={(e) => {
                    handlePaymentChange(e);
                    setErr({ ...err, methodArray: "" });
                  }}
                >
                  {paymentMethods &&
                    paymentMethods.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                </Select>
              )}
            </FormControl>
          </div>
          {err.methodArray ? (
            <span className="error" style={{ color: "red" }}>
              {err.methodArray}
            </span>
          ) : null}
        </div>
      </div>

      <div className="paymentForm__buttonList">
        {methodArray &&
          methodArray.map((item) => {
            return (
              <button
                style={{ backgroundColor: settings.buttonfontColor }}
                className="defaultBtn"
                value={item}
              >
                {item.label}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="cancelIcon"
                  onClick={(e) => handleRemoveMethod(item)}
                >
                  <g filter="url(#filter0_d_8063_8090)">
                    <circle cx="10" cy="8" r="7" fill="white" />
                  </g>
                  <path
                    d="M10.7043 7.99957L12.8522 5.85634C12.9463 5.76227 12.9991 5.63467 12.9991 5.50163C12.9991 5.36859 12.9463 5.241 12.8522 5.14692C12.7582 5.05285 12.6306 5 12.4976 5C12.3646 5 12.237 5.05285 12.1429 5.14692L10 7.29515L7.85707 5.14692C7.76301 5.05285 7.63544 5 7.50241 5C7.36939 5 7.24182 5.05285 7.14776 5.14692C7.0537 5.241 7.00085 5.36859 7.00085 5.50163C7.00085 5.63467 7.0537 5.76227 7.14776 5.85634L9.29568 7.99957L7.14776 10.1428C7.10094 10.1893 7.06378 10.2445 7.03842 10.3054C7.01306 10.3663 7 10.4316 7 10.4975C7 10.5635 7.01306 10.6288 7.03842 10.6896C7.06378 10.7505 7.10094 10.8058 7.14776 10.8522C7.19419 10.899 7.24944 10.9362 7.31031 10.9616C7.37118 10.9869 7.43647 11 7.50241 11C7.56836 11 7.63365 10.9869 7.69452 10.9616C7.75539 10.9362 7.81063 10.899 7.85707 10.8522L10 8.70399L12.1429 10.8522C12.1894 10.899 12.2446 10.9362 12.3055 10.9616C12.3664 10.9869 12.4316 11 12.4976 11C12.5635 11 12.6288 10.9869 12.6897 10.9616C12.7506 10.9362 12.8058 10.899 12.8522 10.8522C12.8991 10.8058 12.9362 10.7505 12.9616 10.6896C12.9869 10.6288 13 10.5635 13 10.4975C13 10.4316 12.9869 10.3663 12.9616 10.3054C12.9362 10.2445 12.8991 10.1893 12.8522 10.1428L10.7043 7.99957Z"
                    fill="#18A0FB"
                  />
                  <defs>
                    <filter
                      id="filter0_d_8063_8090"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="2" />
                      <feGaussianBlur stdDeviation="1.5" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_8063_8090"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_8063_8090"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </button>
            );
          })}
      </div>

      <div className="paymentForm__controls--title">
        3. CHOOSE YOUR DONATION
      </div>
      <div className="paymentForm__addButton addAmountGroup">
        <button
          className="addAmountBtn"
          onClick={() => setInputVisible(!isInputVisible)}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.68169 1.625C7.25086 1.625 5.85216 2.10175 4.66246 2.99496C3.47277 3.88817 2.54551 5.15772 1.99796 6.64307C1.4504 8.12842 1.30713 9.76286 1.58628 11.3397C1.86542 12.9165 2.55443 14.365 3.56618 15.5018C4.57793 16.6386 5.86698 17.4128 7.27032 17.7265C8.67366 18.0401 10.1283 17.8792 11.4502 17.2639C12.7721 16.6487 13.902 15.6068 14.6969 14.27C15.4918 12.9332 15.9161 11.3616 15.9161 9.75384C15.9161 8.68635 15.729 7.62931 15.3654 6.64307C15.0019 5.65683 14.469 4.76072 13.7972 4.00588C13.1254 3.25105 12.3279 2.65228 11.4502 2.24377C10.5725 1.83526 9.63173 1.625 8.68169 1.625V1.625ZM8.68169 16.2569C7.53702 16.2569 6.41806 15.8755 5.46631 15.161C4.51455 14.4464 3.77275 13.4307 3.3347 12.2425C2.89666 11.0542 2.78205 9.74663 3.00536 8.48516C3.22867 7.22368 3.77988 6.06495 4.58928 5.15548C5.39868 4.246 6.42993 3.62665 7.5526 3.37572C8.67527 3.1248 9.83895 3.25358 10.8965 3.74579C11.954 4.23799 12.8579 5.0715 13.4938 6.14093C14.1298 7.21035 14.4692 8.46766 14.4692 9.75384C14.4692 11.4786 13.8595 13.1326 12.7741 14.3522C11.6887 15.5718 10.2166 16.2569 8.68169 16.2569V16.2569ZM11.5755 8.94096H9.40513V6.50231C9.40513 6.28672 9.32891 6.07996 9.19324 5.92751C9.05757 5.77507 8.87356 5.68942 8.68169 5.68942C8.48982 5.68942 8.30581 5.77507 8.17014 5.92751C8.03447 6.07996 7.95825 6.28672 7.95825 6.50231V8.94096H5.78792C5.59605 8.94096 5.41204 9.0266 5.27637 9.17905C5.1407 9.33149 5.06448 9.53825 5.06448 9.75384C5.06448 9.96943 5.1407 10.1762 5.27637 10.3286C5.41204 10.4811 5.59605 10.5667 5.78792 10.5667H7.95825V13.0054C7.95825 13.221 8.03447 13.4277 8.17014 13.5802C8.30581 13.7326 8.48982 13.8183 8.68169 13.8183C8.87356 13.8183 9.05757 13.7326 9.19324 13.5802C9.32891 13.4277 9.40513 13.221 9.40513 13.0054V10.5667H11.5755C11.7673 10.5667 11.9513 10.4811 12.087 10.3286C12.2227 10.1762 12.2989 9.96943 12.2989 9.75384C12.2989 9.53825 12.2227 9.33149 12.087 9.17905C11.9513 9.0266 11.7673 8.94096 11.5755 8.94096Z"
              fill="#18A0FB"
            />
          </svg>
          Add Amount
        </button>
        {isInputVisible && (
          <div className="paymentForm__control input-group">
            <input
              type="number"
              placeholder="Enter Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <button onClick={handleAmountChange}>Save Amount</button>
            <button onClick={handleAmountChangeOther}>Add Other</button>
          </div>
        )}
        {/* {err.selectedAmountErr ? (
          <span className="error" style={{ color: "red" }}>
            {err.selectedAmountErr}
          </span>
        ) : null} */}
      </div>
      <div className="paymentForm__buttonList">
        {paymentArray &&
          paymentArray.map((item, index) => (
            <button
              className="defaultBtn"
              style={{ backgroundColor: settings.buttonfontColor }}
              // style={buttonStyles[settings.buttonfontColor]}
              //  style={`defaultBtn${value.button === "outlined" ? " outlined" : ""}`}
              value={item.label}
              //  style={{props?.value?.button === "outlined" }}
              /* onClick={(e)=> handleAmount(e.target.value)} */
            >
              {item.label == "other" ? item.label : `$ ${item.label}`}
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cancelIcon"
                onClick={(e) => handleRemovePayment(item)}
              >
                <g filter="url(#filter0_d_8063_8090)">
                  <circle cx="10" cy="8" r="7" fill="white" />
                </g>
                <path
                  d="M10.7043 7.99957L12.8522 5.85634C12.9463 5.76227 12.9991 5.63467 12.9991 5.50163C12.9991 5.36859 12.9463 5.241 12.8522 5.14692C12.7582 5.05285 12.6306 5 12.4976 5C12.3646 5 12.237 5.05285 12.1429 5.14692L10 7.29515L7.85707 5.14692C7.76301 5.05285 7.63544 5 7.50241 5C7.36939 5 7.24182 5.05285 7.14776 5.14692C7.0537 5.241 7.00085 5.36859 7.00085 5.50163C7.00085 5.63467 7.0537 5.76227 7.14776 5.85634L9.29568 7.99957L7.14776 10.1428C7.10094 10.1893 7.06378 10.2445 7.03842 10.3054C7.01306 10.3663 7 10.4316 7 10.4975C7 10.5635 7.01306 10.6288 7.03842 10.6896C7.06378 10.7505 7.10094 10.8058 7.14776 10.8522C7.19419 10.899 7.24944 10.9362 7.31031 10.9616C7.37118 10.9869 7.43647 11 7.50241 11C7.56836 11 7.63365 10.9869 7.69452 10.9616C7.75539 10.9362 7.81063 10.899 7.85707 10.8522L10 8.70399L12.1429 10.8522C12.1894 10.899 12.2446 10.9362 12.3055 10.9616C12.3664 10.9869 12.4316 11 12.4976 11C12.5635 11 12.6288 10.9869 12.6897 10.9616C12.7506 10.9362 12.8058 10.899 12.8522 10.8522C12.8991 10.8058 12.9362 10.7505 12.9616 10.6896C12.9869 10.6288 13 10.5635 13 10.4975C13 10.4316 12.9869 10.3663 12.9616 10.3054C12.9362 10.2445 12.8991 10.1893 12.8522 10.1428L10.7043 7.99957Z"
                  fill="#18A0FB"
                />
                <defs>
                  <filter
                    id="filter0_d_8063_8090"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_8063_8090"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_8063_8090"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </button>
          ))}
      </div>
      <div className="paymentForm__buttonList">
        {period &&
          period.map((item, index) => {
            return (
              <button
                className="defaultBtn"
                name={item.name}
                value={item.value}
                onClick={(e) => handlePeriod(e.target.value)}
                style={{
                  backgroundColor: settings.buttonfontColor,
                  opacity:
                    selectedPeriod.findIndex((x) => x.label === item.value) !==
                    -1
                      ? 0.5
                      : 1.5,
                  // border: selectedPeriod.findIndex((x)=>x.label===item.value)!==-1 ? '2px solid #18A0FB' : '2px solid transparent', // Change background color conditionally
                  // boxShadow: selectedPeriod.findIndex((x)=>x.label===item.value)!==-1 ? '0 0 5px #18A0FB' : 'none', // Change text color conditionally
                }}
              >
                {item.value}
              </button>
            );
          })}
        {err.selectedPeriod ? (
          <span className="error" style={{ color: "red" }}>
            {err.selectedPeriod}
          </span>
        ) : null}
      </div>

      <div
        className="imageWrapInner btnGroup"
        style={{
          padding: "8px 0  0 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <button className="btnSave m-0" onClick={beforeOnSave}>
          Save
        </button>
        <div className="answerTypeSelectBox">
          <MaterialSelect
            config={{
              isMenuWithContent: true,
              fieldClass: "answerInputType",
              menuItems: ansTypes,
              value: quesType,
              onChange: (e) => {
                // onTypeSelect && onTypeSelect(e.target.value);

                setQuesType(e.target.value);
              },
            }}
          />
        </div>
      </div>
      {typeof err !== "undefined" && err.length ? (
        <span className={"invalid-feedback"} style={{ position: "unset" }}>
          {err}
        </span>
      ) : null}
      <Prompt
        show={alertMsg?.length}
        message={alertMsg}
        handler={() => setAlertMsg("")}
        singleBtn={true}
        btnLabel="OK"
      />
    </div>
  );
};
export default PaymentChoice;
