import React, { useEffect, useRef, useState } from "react";
import {
    Grid,
    Button,
    Box,
    TextField,
    IconButton,
    Dialog,
    DialogTitle,
    Typography,
    Stack,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import BackspaceIcon from "@mui/icons-material/Backspace";
import DialerSipIcon from "@mui/icons-material/DialerSip";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { CrmService } from "../../services";
import DialCommonList from "./DialCommonList";
import { deepOrange } from "@mui/material/colors";
import { customCallActions } from "../../actions/customCallActions";
import { useDispatch, useSelector } from "react-redux";
import CallModal from "../../voc/CRM/CustomerTable/CallModal";

const DialPad = ({ open, handleClose }) => {
    const [inputValue, setInputValue] = React.useState("");
    const ringtoneRef = useRef(null);
    const [tab, setTab] = React.useState(0);
    const [contactsData, setContactsData] = useState();
    const [callLogFilter, setCallLogFilter] = useState(10);
    const dispatch = useDispatch();

  const { callLogs } = useSelector((store) => ({
    callLogs: store?.customCall?.callLogs?.call_logs,
  }));

  const handleButtonClick = (value) => {
    setInputValue(inputValue + value);
  };

    const handleBackspace = () => {
        setInputValue(inputValue.slice(0, -1));
    };

    const handleClear = () => {
        setInputValue("");
    };

    const handleIncreaseCallLogLimit = () => {
        setCallLogFilter(callLogFilter + 10);
    };

    useEffect(() => {
        if (tab === 2)
            dispatch(
                customCallActions.getAllCallLogsWithFilter(`?per_page=${callLogFilter}`)
            );
    }, [tab, callLogFilter, dispatch]);

  useEffect(() => {
    (async () => {
      const data = await CrmService.getAllCustomers();

      setContactsData(data?.data?.customers);
    })();
  }, []);

  

    const handleMakeCall = () => {
        dispatch(customCallActions.makeCall(inputValue));
        handleClose();
    };

    const handleInputChange = (e) => {
        const value = e.target.value;

        // Allow only numbers
        if (/^\d*$/.test(value)) {
            setInputValue(value);
        }
    };

    return (
        <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        sx={{
            "& .MuiDialog-paper": {
                width: "520px", // Set fixed width
                height: "700px", // Set fixed height
                display: "flex", // To enable flex column behavior
                flexDirection: "column",
                justifyContent: "space-between", // Ensure content takes up remaining space
            },
        }}
        >
        <audio
        ref={ringtoneRef}
        src="../../assets/ringtone/ring.mp3"
        preload="auto"
        />
        <DialogTitle>
        <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        >
        <Typography variant="h6">
        {tab === 0 ? (
            <>Contacts</>
        ) : tab === 1 ? (
            <>Dial Pad</>
        ) : tab === 2 ? (
            <>Recents</>
        ) : null}
        </Typography>
        </Stack>
        </DialogTitle>

        <Box
        sx={{
            overflowY: "auto",
                flexGrow: 1,
                padding: 2,
        }}
        >
        {tab === 0 ? (
            <DialCommonList
            data={contactsData}
            listType="Contacts"
            avatarColor={deepOrange[500]}
            onInfoClick={(contact) => console.log("Contact clicked:", contact)}
            handleClose={handleClose}
            />
        ) : tab === 1 ? (
            <Box>
            <TextField
            fullWidth
            value={inputValue}
            //onChange={(e) => setInputValue(e.target.value)}
            onChange={(e) => handleInputChange(e)}
            placeholder="Enter number"
            InputProps={{
                style: { textAlign: "center", fontSize: "20px" },
            }}
            />
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, "*", 0, "#"].map((item) => (
                <Grid item xs={4} key={item}>
                <Button
                variant="contained"
                onClick={() => handleButtonClick(item)}
                sx={{
                    width: "100%",
                        height: "60px",
                        fontSize: "20px",
                        color: "#333",
                        backgroundColor: "#e0e0e0",
                        "&:hover": { backgroundColor: "#bdbdbd" },
                }}
                >
                {item}
                </Button>
                </Grid>
            ))}

            <Grid
            item
            xs={4}
            sx={{
                display: "flex",
                    justifyContent: "center",
            }}
            >
            <IconButton
            onClick={handleBackspace}
            sx={{
                minWidth: "60px",
                    minHeight: "60px",
                    backgroundColor: "#e0e0e0",
                    "&:hover": { backgroundColor: "#bdbdbd" },
            }}
            >
            <BackspaceIcon fontSize="large" />
            </IconButton>
            </Grid>
            <Grid
            item
            xs={4}
            sx={{
                display: "flex",
                    justifyContent: "center",
            }}
            >
            <IconButton
            sx={{
                minWidth: "60px",
                    minHeight: "60px",
                    backgroundColor: "#4caf50",
                    "&:hover": { backgroundColor: "#388e3c" },
            }}
            onClick={handleMakeCall}
            >
            <PhoneIcon fontSize="large" />
            </IconButton>
            </Grid>
            <Grid
            item
            xs={4}
            sx={{
                display: "flex",
                    justifyContent: "center",
            }}
            >
            <IconButton
            onClick={handleClear}
            sx={{
                minWidth: "60px",
                    minHeight: "60px",
                    backgroundColor: "#e0e0e0",
                    "&:hover": { backgroundColor: "#bdbdbd" },
            }}
            >
            C
            </IconButton>
            </Grid>
            </Grid>
            </Box>
        ) : tab === 2 ? (
            // Recent Call List
            <DialCommonList
            data={callLogs}
            listType="Recents"
            avatarColor={"#4caf50"}
            renderEmptyMessage="No recent calls found."
            handleClose={handleClose}
            handleIncreaseCallLogLimit={handleIncreaseCallLogLimit}
            />
        ) : (
            // <AiCall customerId={"128"} phoneNumber />
            <CallModal
            isVisible={true}
            customerId={147}
            phoneNumber={123}
            onClose={() => {}}
            />
        )}
        </Box>

        {/* Tabs section fixed at the bottom */}
        <Grid
        container
        sx={{
            borderTop: 1,
                padding: 1,
                position: "relative",
                width: "100%",
        }}
        >
        <Grid
        item
        xs={4}
        sx={{
            display: "flex",
                justifyContent: "center",
        }}
        >
        <IconButton
        onClick={() => setTab(0)}
        sx={{
            display: "flex",
                flexDirection: "column",
                alignItems: "center",
        }}
        >
        <ContactPhoneIcon />
        <Typography variant="body2">Contacts</Typography>
        </IconButton>
        </Grid>
        <Grid
        item
        xs={4}
        sx={{
            display: "flex",
                justifyContent: "center",
        }}
        >
        <IconButton
        onClick={() => setTab(1)}
        sx={{
            display: "flex",
                flexDirection: "column",
                alignItems: "center",
        }}
        >
        <DialerSipIcon />
        <Typography variant="body2">Keypad</Typography>
        </IconButton>
        </Grid>
        <Grid
        item
        xs={4}
        sx={{
            display: "flex",
                justifyContent: "center",
        }}
        >
        <IconButton
        onClick={() => setTab(2)}
        sx={{
            display: "flex",
                flexDirection: "column",
                alignItems: "center",
        }}
        >
        <ScheduleIcon />
        <Typography variant="body2">Recents</Typography>
        </IconButton>
        </Grid>
        </Grid>
        </Dialog>
    );
};

export default DialPad;
