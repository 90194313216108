import React, { useEffect, useState } from "react";

import TypePictureChoice from "./Components/TypePictureChoice";
import TypeMultipleChoice from "./Components/TypeMultiChoice";
import Drop from "./Components/Drop";
import RatingBox from "./Components/RatingBox";
import Mail from "./Components/Mail";
import Contact from "./Components/Contact";
import FileUpload from "./Components/FileUpload";
import FileInput from "../../../common/controls/inputs/InputFile";
import YesNo from "./Components/YesNo";
import Default from "./Components/Default";
import Help from "./Components/Help";
import cn from "classnames";
import TypeCardChoice from "../Preview/Components/TypeCardChoice";
import TypeDecoratorChoice from "../Preview/Components/TypeDecoratorchoice";
import TypePaymentChoice from "../Preview/Components/TypePaymentChoice";

const Image = ({ config: { imgPath, style = {}, autoSize } }) => {
  return (
    <div className={`imageInner rightSideImage `}>
      <img
        className={`${autoSize ? "autoSize" : ""}`}
        style={style}
        src={imgPath}
      />
    </div>
  );
};

const QuesTypeWiseContent = (props) => {
  const { quesType, question_picture_theme = "" } = props;
  const imageRight = <Image config={{ imgPath: question_picture_theme }} />;
  switch (quesType) {
    case "Picture":
      return (
        <div className={"ImageWrap"} style={{ height: "100%" }}>
          {" "}
          <TypePictureChoice {...props}>{imageRight}</TypePictureChoice>{" "}
        </div>
      );
    case "Rating":
      return (
        <div className={"ImageWrap"}>
          {" "}
          <RatingBox {...props}>{imageRight} </RatingBox>{" "}
        </div>
      );
    case "Multiple":
      return (
        <div className={"ImageWrap"} style={{ height: "100%" }}>
          {" "}
          <TypeMultipleChoice {...props}>{imageRight}</TypeMultipleChoice>{" "}
        </div>
      );
    case "Contact":
      return (
        <div className={"ImageWrap"}>
          {" "}
          <Contact {...props}>{imageRight}</Contact>{" "}
        </div>
      );
    case "Email":
      return (
        <div className={"ImageWrap"}>
          {" "}
          <Mail {...props}>{imageRight}</Mail>{" "}
        </div>
      );
    case "Yes/No":
      return (
        <div className={"ImageWrap"}>
          {" "}
          <YesNo {...props}>{imageRight}</YesNo>{" "}
        </div>
      );
    case "DropDown":
      return (
        <div className={"ImageWrap"}>
          {" "}
          <Drop {...props}>{imageRight}</Drop>{" "}
        </div>
      );
    case "FileUpload":
      return (
        <div className={"ImageWrap"}>
          {" "}
          <FileUpload {...props}>{imageRight}</FileUpload>{" "}
        </div>
      );
    case "Help":
      return (
        <div className={"ImageWrap"}>
          {" "}
          <Help {...props}>{imageRight}</Help>{" "}
        </div>
      );
      case "Card":
      return (
        <div className={"ImageWrap"} style={{ height: "100%" }}>
          {" "}
          <TypeCardChoice {...props}>{imageRight}</TypeCardChoice>{" "}
        </div>
      );
      case "Decorator":
        return (
          <div className={"ImageWrap"}>
            {" "}
            <TypeDecoratorChoice {...props}>{imageRight} </TypeDecoratorChoice>{" "}
          </div>
        );
        case "Payment":
        return (
          <div className={"ImageWrap"}>
            {" "}
            <TypePaymentChoice {...props}>{imageRight} </TypePaymentChoice>{" "}
          </div>
        );
    default:
      return <Default {...props}>{imageRight}</Default>;
  }
};

const getWrapper = (data, cn) => {
  const { settings = {} } = data || {};
  return `layoutWrap ${settings.autoAdjust ? "autoAdjustable" : ""} ${cn}`;
};

const LayoutOne = (props) => {
  const { showSubmitBtn } = props;
  const imageSizesAuto = !showSubmitBtn ? "imageLayoutAuto" : "";
  return (
    <div className={cn(getWrapper(props, "layout1"), imageSizesAuto)}>
      <div className={"layoutFull"} style={{ width: "100%", height: "100%" }}>
        <QuesTypeWiseContent {...props} />
      </div>
    </div>
  );
};
const LayoutTwo = (props) => {
  const {
    question_picture_theme = "",
    settings: { autoSize = false },
  } = props;
  return (
    <div className={getWrapper(props, "layout2")}>
      <div
        className={"questWrap"}
        style={{ width: "57%", height: "100%", float: "left" }}
      >
        <QuesTypeWiseContent {...props} />
      </div>
      <div
        className={"imgWrap"}
        style={{ width: "43%", height: "100%", float: "right" }}
      >
        <Image
          config={{
            autoSize: autoSize,
            style: { width: "313px", height: "255px", marginTop: "65px" },
            imgPath: question_picture_theme,
          }}
        />
      </div>
    </div>
  );
};
const LayoutThree = (props) => {
  const {
    question_picture_theme = "",
    settings: { autoSize = false },
  } = props;
  return (
    <div className={getWrapper(props, "layout3")}>
      <div
        className={"imgWrap"}
        style={{ width: "43%", height: "100%", float: "left" }}
      >
        <Image
          config={{
            autoSize: autoSize,
            style: {
              width: "313px",
              height: "255px",
              marginTop: "65px",
              float: "right",
            },
            imgPath: question_picture_theme,
          }}
        />
      </div>
      <div
        className={"questWrap"}
        style={{ width: "57%", height: "100%", float: "right" }}
      >
        <QuesTypeWiseContent {...props} />
      </div>
    </div>
  );
};
const LayoutFour = (props) => {
  return (
    <div className={getWrapper(props, "layout4")}>
      <div style={{ width: "100%", height: "100%" }}>
        <QuesTypeWiseContent {...props} />
      </div>
    </div>
  );
};
const LayoutFive = (props) => {
  const {
    question_picture_theme = "",
    q_type = "",
    showSubmitBtn,
    settings: { autoSize = false },
  } = props;
  const typeHelpStyling =
    !showSubmitBtn && q_type.toLowerCase() === "help" ? "helpLayout" : "";
  return (
    <div className={cn(getWrapper(props, "layout5"), typeHelpStyling)}>
      <div
        className={"imgWrap"}
        style={{ width: "43%", height: "100%", float: "left" }}
      >
        <Image
          config={{
            autoSize: autoSize,
            style: {
              width: "100%",
              height: "95%",
              margin: "10px 0px 0px 10px",
            },
            imgPath: question_picture_theme,
          }}
        />
      </div>
      <div
        className={"questWrap"}
        style={{ width: "57%", height: "100%", float: "right" }}
      >
        <QuesTypeWiseContent {...props} />
      </div>
    </div>
  );
};
const LayoutSix = (props) => {
  const {
    question_picture_theme = "",
    settings: { autoSize = false },
  } = props;
  return (
    <div className={getWrapper(props, "layout6")}>
      <div style={{ width: "100%", height: "100%" }}>
        <Image
          config={{
            autoSize: autoSize,
            style: {
              width: "100%",
              height: "100%",
              maxHeight: "200px",
              objectFit: "cover",
            },
            imgPath: question_picture_theme,
          }}
        />
        <div className="layout6Inner">
          <QuesTypeWiseContent {...props} />
        </div>
      </div>
    </div>
  );
};

// Right Alignment - Default Behavior
const LayoutDefault = (props) => {
  const {
    question_picture_theme = "",
    q_type = "",
    showSubmitBtn,
    settings: { autoSize = false },
  } = props;
  const typeHelpStyling =
    !showSubmitBtn && q_type.toLowerCase() === "help" ? "helpLayout" : "";
  const defaultStyling =
    showSubmitBtn && q_type.toLowerCase() !== "help"
      ? "layout0 layoutColumn"
      : "layout0";
  return (
    <div className={cn(getWrapper(props, defaultStyling), typeHelpStyling)}>
      <div
        className={"questWrap"}
        style={{ width: "43%", height: "100%", float: "left" }}
      >
        <QuesTypeWiseContent {...props} />
      </div>
      <div
        className={"ImageWrap"}
        style={{ width: "57%", height: "100%", float: "right" }}
      >
        <Image
          config={{
            autoSize: autoSize,
            style: { width: "97%", height: "95%", margin: "10px 0px 0px 0px" },
            imgPath: question_picture_theme,
          }}
        />
      </div>
    </div>
  );
};

const CardPreview = (props) => {
  const { layout, quesType } = props;
  switch (layout) {
    case 1:
      return <LayoutOne {...props} />;
    case 2:
      return <LayoutTwo {...props} />;
    case 3:
      return <LayoutThree {...props} />;
    case 4:
      return <LayoutFour {...props} />;
    case 5:
      return <LayoutFive {...props} />;
    case 6:
      return <LayoutSix {...props} />;
    default:
      return <LayoutDefault {...props} />;
  }
};

export default CardPreview;
