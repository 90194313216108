import { callRouteConstant } from "../constants/callRouteConstant";
import { CrmService } from "../services";
import { AgentsService } from "../services/AgentsService";
import { swalAlert } from "../voc/Common/SwalAlert";
import { callQueueActions } from "./callQueueActions";
import { customCallActions } from "./customCallActions";

// Global Variable Definations
const audioContext = new AudioContext();
const activeCallAudioSources = new Map();
const activeAiCalls = new Map();

function processAiCallResponse(val) {
    return (dispatch) => {
        console.log(val, "val-c");
        dispatch({type: callRouteConstant.SET_AI_RESPONSE, payload: val})
    }
}

// Call Forward
let callIntervals = new Map();

function callForward(callId) {
    console.log("call is forwarding", callId);
    
    return async (dispatch, getState) => {
        try {
            const { systemUsers } = getState().callRoute;
            const { session } = getState().customCall;
            const availableUsers = systemUsers.filter(user => user.status === "Available");
            const callerData = await CrmService.getCustomerDataByNo(callId);
            const customer = callerData?.data;
            const { callLogId } = session.get(callId) || {};

            dispatch(callQueueActions.getCallQueue());
            const getRandomUser = (users) => users[Math.floor(Math.random() * users.length)];

            const forwardCall = (user, id, queueId) => {
                dispatch(customCallActions.transferCallRequest(`sip:${user.extension}@pbx.articence.com`, id));
                if(activeAiCalls.has(id)) {
                    const activeCalls = activeAiCalls.get(id);
                    activeAiCalls.set(id, {
                        ...activeCalls,
                        extension: user.extension
                    })
                }

                console.log(activeAiCalls, "activeAiCalls");
                dispatch(customCallActions.StoreCallLogs(customer?.id || null, "INBOUND", "Transferred", id));
                if (queueId) dispatch(callQueueActions.deleteCallQueue(queueId));
                console.log(`Call forwarded to extension: ${user.extension}`);
            };

            if (availableUsers.length === 0) {
                const callQueue = getState().callQueueLogic.callQueue;
                const callQueueLive = callQueue?.filter((c) => c.priority === "High");

                console.log(callQueue, callQueueLive, "callQueue-22");
                dispatch(replaceAudioForCall(callId, `/audio/call_busy.mp3`));
                //callQueueLive.push({ callId, position: callQueue.length + 1 });

                const callToUpdate = callQueueLive.find(call => call.phone_number === callId);
                console.log(callToUpdate, "callToUpdate");
                if (callToUpdate) {
                    dispatch(callQueueActions.updateCallQueue( callToUpdate.id, { priority: "High" }));
                }

                const randomUser = getRandomUser(systemUsers);
                const teamId = JSON.parse(localStorage.getItem("user"))["team_id"];

                if (!callToUpdate) {
                    const queuePayload = {
                        customer_name: customer?.customer_name || "No data",
                        phone_number: callId,
                        wait_time: new Date().toISOString(),
                        assigned_user: null,
                        team_id: parseInt(teamId),
                        call_log_id: callLogId,
                    };
                    dispatch(callQueueActions.addCallQueue(queuePayload));
                }

                if (callIntervals.has(callId)) return console.log(`Interval already running for call ${callId}`);

                const intervalId = setInterval(() => {
                    const updatedCallQueue = getState().callQueueLogic.callQueue;
                    const updatedCallQueueLive = updatedCallQueue?.filter((c) => c.priority === "High");
                    dispatch(callQueueActions.getCallQueue());
                    const updatedSystemUsers = getState().callRoute.systemUsers;
                    const updatedAvailableUsers = updatedSystemUsers.filter(user => user.status === "Available");

                    console.log(updatedCallQueueLive, "callQueue");
                    // Update each caller with a position message
                    updatedCallQueueLive.forEach((call, index) => {
                        if (call.assigned_user !== null) {
                            dispatch(replaceAudioForCall(call.phone_number, `/audio/assigned.mp3`));
                        } else {
                            dispatch(replaceAudioForCall(call.phone_number, `/audio/call_queue_${index + 1}.mp3`));
                        }
                    });

                    
                    if (updatedAvailableUsers.length > 0) {
                        const sortedQueue = updatedCallQueueLive.sort((a, b) => (a.assigned_user === null ? 
                        1 : b.assigned_user === null ? -1 : 0));
                        const firstInQueue = sortedQueue.shift(); 
                        if (!firstInQueue) return;
                        if (firstInQueue.assigned_user === null) {
                            const newRandomUser = getRandomUser(updatedAvailableUsers);
                            forwardCall(newRandomUser, firstInQueue.phone_number, firstInQueue.id);
                            clearInterval(intervalId);
                            callIntervals.delete(firstInQueue.phone_number);

                        } else {
                            const getAssignedUser = (users) => users.filter((u) => firstInQueue.assigned_user === u.id)[0];
                            const assignedUser = getAssignedUser(updatedSystemUsers); 
                            console.log(assignedUser, "assignedUser-222");
                            if(assignedUser.status !== "Available") return;
                            forwardCall(assignedUser, firstInQueue.phone_number, firstInQueue.id) 
                            clearInterval(intervalId);
                            callIntervals.delete(firstInQueue.phone_number);
                        }

                    }
                }, 15000);

                callIntervals.set(callId, intervalId);
                return console.log(`Call ${callId} is in queue, checking for available users.`);
            }

            forwardCall(getRandomUser(availableUsers), callId);
        } catch (e) {
            console.log("Failed to Transfer the Call", e);
        }
    };
}

// Ai Audio Setup
function setAiAudioResponse(audioData, callId) {
    return async (dispatch) => {
        console.log("audioData", audioData);
        const { s3_url, transfer_call } = audioData;
        dispatch(replaceAudioForCall(callId, s3_url, transfer_call));
    };
}

function setSystemUserType (userType) {
   return (dispatch) => {
        dispatch({type: callRouteConstant.SET_USER_TYPE, payload: userType})
   }
}


function addActiveAiCalls(callId) {
    if (!activeAiCalls.has(
        callId
    )) {
        const callStartTime = new Date().toISOString();

        activeAiCalls.set(callId, {
            callId: callId,
            callStartTime: callStartTime,
            user: "AI",
            status: "Active",
            extension: "AI"
        });
    }
    return (dispatch) => {
        dispatch({ type: callRouteConstant.SET_AI_ACTIVE_CALL, payload: activeAiCalls}); 
    }; 
}



function getAiAgentByExtension(extension) {
    return async (dispatch) => {
        const res = await AgentsService.getAiAgentByExtension(extension);
        console.log(res.data, "getAiAgentByExtension");
        dispatch({ type: callRouteConstant.SET_AI_AGENT, payload: res.data})
    }
}

function removeActiveAiCalls(callId) {
    if (activeAiCalls.has(
        callId
    )) {
        activeAiCalls.delete(callId);
    }
    return (dispatch) => {
        dispatch({ type: callRouteConstant.SET_AI_ACTIVE_CALL, payload: activeAiCalls}); 
    }; 
}

function addActiveCalls(callId) {
    return (dispatch) => {
        const callStartTime = new Date().toISOString();
        const payload = {
            callId: callId,
            callStartTime: callStartTime
        }
        dispatch({ type: callRouteConstant.ADD_ACTIVE_CALL, payload: payload});
    };
}

export function removeActiveCalls(callId) {
    return (dispatch) => {
        dispatch({
            type: callRouteConstant.REMOVE_ACTIVE_CALL,
            payload: callId,
        });
    };
}

function addToCallerList(caller) {
    return (dispatch) => {
        dispatch({
            type: callRouteConstant.ADD_CALLER_LIST,
            payload: caller,
        });
    };
}

// Load Buffer for each Audio
async function loadAudioBuffer(audioUrl) {
    try {
        const response = await fetch(audioUrl);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const arrayBuffer = await response.arrayBuffer();
        return await audioContext.decodeAudioData(arrayBuffer);
    } catch (error) {
        console.error("error loading audio:", error);
        return null;
    }
}

// Play new Audio
function replaceAudioForCall(callId, newAudioUrl, transfer_call, role, isAiAgentActive) {
    return async (dispatch) => {
        try {
            console.log(`🔄 Replacing audio for call ${callId}...`);

            const callData = activeCallAudioSources.get(callId);
            if (!callData || !callData.sender) {
                console.error(`⚠️ No active call data or sender for ${callId}`);
                return;
            }

            // stop & disconnect initial source
            if (callData.currentSource) {
                callData.currentSource.stop();
                callData.currentSource.disconnect();
            }

            const newAudioBuffer = await loadAudioBuffer(newAudioUrl);
            if (!newAudioBuffer) return;

            const newSource = audioContext.createBufferSource();
            newSource.buffer = newAudioBuffer;
            newSource.connect(callData.mediaDestination);
            callData.currentSource = newSource;

            const newTrack = callData.mediaStream.getAudioTracks()[0];
            if (newTrack && callData.sender) {
                await callData.sender.replaceTrack(newTrack);
                console.log(`Replace track for ::  ${callId}`);
            }

            setTimeout(() => {
                newSource.start();
            },3000);

            newSource.onended = () => {
                console.log(transfer_call, "transfer_call");
                dispatch(processAiCallResponse(true));
                if (transfer_call === true) {
                    console.log("call onended");
                    dispatch(callForward(callId));
                } else if (isAiAgentActive === false && role === "Admin") {
                    console.log(isAiAgentActive, role, "role-agent");
                    dispatch(addActiveCalls(callId));
                    dispatch(callForward(callId));
                }
            };
            console.log(`▶️ New audio started for call ${callId}`);
        } catch (error) {
            console.error(`Error replacing audio for call ${callId}:`, error);
        }
    };
}
// Main Pick call function
function pickCall(call, role, isAiAgentActive) {
    return async (dispatch) => {
        let callId = call.remote_identity.uri.user;

        const callerData = await CrmService.getCustomerDataByNo(callId);
        const customer = callerData?.data;

        dispatch(
            customCallActions.StoreCallLogs(
                customer?.id || null,
                "INBOUND",
                "picked_by_ai",
                callId,
            ),
        );

        dispatch(
            addToCallerList({
                id: "1",
                user_name: "Lolzz",
                ext_no: "1001",
                customer_name: "krish",
                phone: "12345",
                status: "incoming",
            }),
        );

        try {
            console.log(`Picking up call by AI ${callId}...`);

            const mediaDestination = audioContext.createMediaStreamDestination();
            const mediaStream = mediaDestination.stream;

            const peerConnection = new RTCPeerConnection();
            const sender = peerConnection.addTrack(
                mediaStream.getAudioTracks()[0],
                mediaStream,
            );

            console.log(mediaStream, "medstream");

            activeCallAudioSources.set(callId, {
                mediaDestination,
                mediaStream,
                sender,
                currentSource: null,
            });

            call.answer({
                mediaConstraints: { audio: true, video: false },
                mediaStream,
            });

            dispatch(addActiveAiCalls(callId));
            dispatch(replaceAudioForCall(callId, "/audio/hello.mp3", false, role, isAiAgentActive));

        } catch (error) {
            console.error("❌ Error picking the call:", error);
        }
    };
}

// Dashboard API Calls
function getSystemUsers() {
    const failure = (error) => ({
        type: callRouteConstant.GET_SYSTEM_USERS_ERROR,
        payload: error,
    });

    return (dispatch) => {
        CrmService.getSystemUsers()
            .then((response) => {
                dispatch({
                    type: callRouteConstant.GET_SYSTEM_USERS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                swalAlert({
                    icon: "error",
                    title: error?.response?.data?.message?.toString(),
                    showConfirmButton: true,
                });
                //dispatch(failure(error));
            });
    };
}


export const callRouteAction = {
    callForward,
    addActiveCalls,
    removeActiveCalls,
    pickCall,
    getSystemUsers,
    setAiAudioResponse,
    removeActiveAiCalls,
    getAiAgentByExtension,
    processAiCallResponse,
    setSystemUserType
};
