import React, {useEffect, useState} from "react";
import DoneIcon from "@mui/icons-material/Done";

const YesNo = (props) =>{
    const {layout,children, qus_id, question, desc="", qno=1, srNo=1, settings={}, cardRef, quesType, onChange, qRefs, submit, btnText='Next', sliderView, onFocus} = props;
    const { font, underline, bold, fontSize, italic, fontColor, require } = settings,
        style = { fontFamily: font, fontSize, fontWeight: bold ? 'bold' : '', fontStyle: italic ? 'italic' : '', textDecoration: underline ? 'underline' : '', color: fontColor };
    
    const yesNoAns = (window.answeredData && window.answeredData[question]) ? window.answeredData[question][0].text : '';
    const [ans, setAns] = React.useState(yesNoAns);
    const [err, setErr] = React.useState('');

    useEffect(()=> {
        const elements = document.getElementsByName("question_"+qno);
        let ele = elements.length ? elements[0] : elements;
        ele.style.setProperty('font-family', font, 'important');
    }, [font]);

    const onNext = () => {
        if(btnText === 'Next'){
            cardRef && cardRef.current.next();
            (qRefs?.current?.length >=qno) && setTimeout(() => { qRefs.current[qno]?.focus() }, 10);
        }else{
            submit && submit();
        }
    };

    const triggerFocus = () => {
        onFocus && qno > 0 && onFocus(qno-1);
    };

    const saveChanges = (res) => {
        triggerFocus();
        if(require && res === ''){
            setErr('This is required.');
            return;
        }
        let obj = {
            "qus_id": qus_id,
            "q_type":quesType,
            "qus_text": question,
            "answers": [{ "text":res }]
        };
        setAns(res);
        onChange && onChange({name: qno, value:obj});
        setErr('');
    };

    return(
        <div className="imageWrapInner">
            <div className="ques">
            <h3 name={"question_"+qno} style={style}>{srNo}-{question}
                <span style={{color:"red", marginLeft:'5px',display: require?'unset':'none'}}>*</span>
            </h3>
                <em> {desc} </em>
            </div>
            {layout == 1 && children}
            <div className="multipleInner yesNoInner" ref={ (r)=> qRefs && (qRefs.current[qno-1] = r) } tabIndex={-1}>
                <div className={`item ${ans === 'Yes' ? "selected":""}`} onClick={()=>saveChanges("Yes")}>
                    <div className="Option">A</div>
                    <span style={{padding: '0px 75px', lineHeight: 2, color:'#18A0FB'}}>Yes</span>
                    {ans === 'Yes' && <div className="selectedIcon"><DoneIcon/></div>}
                </div>
                <div className={`item ${ans === 'No' ? "selected":""}`} onClick={()=>saveChanges("No")}>
                    <div className="Option">B</div>
                    <span style={{padding: '0px 75px', lineHeight: 2, color:'#18A0FB'}}>No</span>
                    {ans === 'No' && <div className="selectedIcon"><DoneIcon/></div>}
                </div>
            </div>
            { err !== "" ? <div> <p style={{color:'red'}}>{err}</p></div> : <div><p>&nbsp;</p></div> }

            { sliderView ? <div className="btnGroup">
                <button className="btnSave" onClick={onNext}>{btnText}</button>
            </div> : null}
        </div>
    )
};

export default YesNo;
