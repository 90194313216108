import React, { useState, useEffect } from "react";
import DataTable from "../../../components/Tables/DataTable";
import { Button, Typography } from "@mui/material";
import { AdminService } from "../../../services/AdminService";

const EscalationTable = () => {
  const [showForm, setShowForm] = useState(false);
  const [escalation, setEscalation] = useState([]);

  const columns = ["created_at", "manager_id", "customer_id", "reason","Actions"];
  const rows = [
    {
        Starts: "2025-01-23 09:00 AM",
        Ends: "2025-01-23 10:00 AM",
        Delegate_users: ["John Doe", "Steve Doe", "Stete Doe"],
        Call_routing: "True",
        Messaging: "True",
        Appointments: "True",
        Status: "Active",
        Actions: "View Details"
      },
      {
        Starts: "2025-01-23 11:00 AM",
        Ends: "2025-01-23 12:00 PM",
        Delegate_users: "Jane Smith",
        Call_routing: "True",
        Messaging: "False",
        Appointments: "True",
        Status: "Pending",
        Actions: "Reschedule"
      }
  ];

  useEffect(() => {
    const fetchEscalation = async () => {
      const data = await AdminService.getEscalations();
      setEscalation(data?.data);
    };
    fetchEscalation();
  }, []);
  

  const enhancedRows = escalation.map((row) => ({
    ...row,
    Actions: (
      <>
        {/* <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            setSelectedDel(row); // Set the selected role for editing
            // setOpenCreate(true); // Open the form
            // setOpenForm(true);
          }}          
          style={{ marginRight: "10px" }}
        >
          Edit
        </Button> */}
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => alert(`Delete ${row.Role_name}`)}
        >
          Delete
        </Button>
      </>
    ),
  }));


  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Escalation Details
      </Typography>
      <DataTable columns={columns} rows={enhancedRows} tableType="Escalation" />
    </div>
  );
};

export default EscalationTable;
