import { useState, useEffect } from "react";
import { X } from "lucide-react";
import AuthPrompt from "./AuthPrompt";
import useSocialAuth from "./useSocialAuth";

export default function AuthPopup({ initialVisible = false }) {
  const [visible, setVisible] = useState(initialVisible);
  const [showAuthPrompt, setShowAuthPrompt] = useState(false);
  const { initiateAuth, isAuthenticating } = useSocialAuth();

  // Check authentication status on mount
  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('auth'));
    const socialType = localStorage.getItem('social_type');
    
    if (authData && authData.provider !== socialType) {
      // Clear auth if provider doesn't match
      localStorage.removeItem('auth');
    }
  }, []);

  const handleAuthAction = () => {
    const authData = JSON.parse(localStorage.getItem('auth'));
    const socialType = localStorage.getItem('social_type') || 'google';
    
    if (authData && authData.access_token) {
      // Already authenticated
      performAction();
    } else {
      // Show auth prompt
      setShowAuthPrompt(true);
    }
  };

    const performAction = () => {
    // Your action that requires authentication
  };

  // Optional: Add useEffect to handle prop changes
  useEffect(() => {
    setVisible(initialVisible);
  }, [initialVisible]);

  if (!visible) return null;

  return (
    visible && (
      <div className="fixed bottom-5 right-5 bg-white shadow-lg rounded-2xl p-4 w-80 border border-gray-300">
        <div className="flex justify-between items-center">
          <button onClick={() => setVisible(false)} className="text-gray-500 hover:text-gray-700">
            <X size={20} />
          </button>
        </div>
        <p className="text-gray-600 text-sm mt-2">
        Sync your email to view and manage appointments seamlessly.
        </p>
        <div className="mt-4 flex gap-3">
          <button className="bg-gray-900 text-white px-4 py-2 rounded-lg w-full text-sm font-semibold" 
          style={{ backgroundColor: '#131f77' }} onClick={handleAuthAction}>
            Sync Up
          </button>
          {/* <button className="border border-gray-300 rounded-lg w-10 h-10 flex items-center justify-center">
            <img src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" alt="Google" className="w-5 h-5" />
          </button> */}
        </div>
        <AuthPrompt
        open={showAuthPrompt}
        onClose={() => setShowAuthPrompt(false)}
        onAuthenticate={initiateAuth}
        isLoading={isAuthenticating}
      />
      </div>
    )
  );
}
