import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Grid,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Box,
  Tooltip,
  CardHeader,
  IconButton 
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { Activity } from "lucide-react";
import { Visibility } from "@mui/icons-material";
import CallCenterDashboard from "../../CallCenter/CallDashboard";



const UserTable = ({ systemUsers, statusMap }) => {

  const [selectedUser, setSelectedUser] = useState(null);
  const [isUser, setUser] = useState(false);
  const navigate = useHistory();

  const handleViewClick = (user) => {
  setSelectedUser(user);
  setUser(true);
  
  navigate.push("/userDashboard", { state: { redirectUser: user } })
  };

// {isUser && <CallCenterDashboard user2={selectedUser} />}

  return (
    <Grid item xs={12} sm={8} md={6}>
      <Card
        sx={{
          border: "1px solid #f0f0f0",
          borderRadius: 0,
        }}
      >
        <CardHeader
          sx={{
            ".MuiCardHeader-title": {
              fontSize: "0.9rem",
              fontWeight: "bold",
            },
          }}
          title="Customer Agents"
          action={
            <Chip
              icon={<Activity />}
              label="Active"
              size="small"
              color="primary"
              variant="outlined"
            />
          }
        />

        <CardContent sx={{ padding: "8px !important" }}>
          <TableContainer component={Paper} elevation={0} sx={{ height: 300 }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  {["ID", "Name", "Extension", "Status", "Dept","role", "Calls", "Assigned", "View"].map(
                    (h) => (
                      <TableCell
                        key={h}
                        sx={{ fontWeight: "bold", padding: "6px" }}
                      >
                        {h}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {systemUsers?.map((user) => (
                  <TableRow key={user.id} hover>
                    <TableCell sx={{ padding: "6px" }}>{user.id}</TableCell>
                    <TableCell sx={{ padding: "6px" }}>
                      <Tooltip title={`${user.name} - ${user.department}`}>
                        <Box display="flex" alignItems="center">
                          {statusMap[user.status]?.icon}
                          {user.name}
                        </Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={{ padding: "6px" }}>{user.extension}</TableCell>
                    <TableCell sx={{ padding: "6px" }}>
                      <Chip
                        label={user.status}
                        size="small"
                        color={statusMap[user.status]?.color}
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell sx={{ padding: "6px" }}>
                      {user.department}
                    </TableCell>
                    
                    <TableCell sx={{ padding: "6px" }}>
                      {user.role
                      }
                    </TableCell>
                    <TableCell sx={{ padding: "6px" }}>
                      {user?.callsDuration} min
                    </TableCell>
                    <TableCell sx={{ padding: "6px" }}>
                      {user?.no_of_assignedCustomers}
                    </TableCell>
                    <TableCell sx={{ padding: "6px" }}>
                      <IconButton onClick={() => handleViewClick(user)}>
                        <Visibility />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default UserTable;
