import { teamsConstants } from "../constants/teamsConstants";

const initialState = {
  loading: false,
  isTeamAdded: false,
  teams: [],
  message: "",
};

export function teamsLogic(state = initialState, action) {
  switch (action.type) {
    case teamsConstants.ADD_TEAMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case teamsConstants.ADD_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        isTeamAdded: true, // Set success flag to true
        teams: [...state.teams, action.team], // Optional: update teams list in state if needed
      };
    case teamsConstants.ADD_TEAMS_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.error,
        type: "error",
      };
    case teamsConstants.RESET_TEAM_ADDED:
      return {
        ...state,
        isTeamAdded: false,
      };
    default:
      return state;
  }
}
