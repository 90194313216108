import api from "./api";
import devInstance from "./devapi";
import devapi from "./devapi"

//Roles
async function getRoles() {
    const result = await api.get(`/api/role/`);
    return result;
}

async function getRoleById(roleId) {
    const result = await api.get(`/api/role/${roleId}`);
    return result;
}

async function getMenuByRoleId(roleId) {
    const result = await api.get(`/api/role_menus/role/${roleId}`);
    return result;
}

async function updateRolesMenu(roleId, menuId, rolesData) {
    const result = await api.put(`/api/role_menus/role/${roleId}/menu/${menuId}`, rolesData);
    return result;
}

async function updateRoles(rolesData, roleId) {
    const result = await api.put(`/api/role/${roleId}`, rolesData);
    return result;
  }

//Territory
async function addTerritory(TerritoryData, Id) {
    const result = await api.post(`/api/territory/`, TerritoryData);
    return result;
}

async function AssignTerritory(userId, TerritoryId) {
    const result = await api.post(`/api/territory/assign/${userId}/${TerritoryId}`);
    return result;
}

async function MultiAssignTerritory(TerritoryId, assignedIds) {
    const result = await api.post(`/api/territory/assign/multiple/user/${TerritoryId}`, assignedIds);
    return result;
}
 
async function getTerritory() {
    const result = await api.get(`/api/territory/`);
    return result;
}

async function getTerritoryByUserId(userId) {
    const result = await api.get(`/api/territory/user/${userId}/territories`);
    return result;
}

async function getTerritoryId(TerritoryId) {
    const result = await api.get(`/api/territory/${TerritoryId}`);
    return result;
}

async function updateTerritory(TerritoryData, TerritoryId) {
    const result = await api.put(`/api/territory/${TerritoryId}`, TerritoryData);
    return result;
  }
  
async function deleteTerritory(TerritoryId) {
    const result = await api.delete(`/api/territory/${TerritoryId}`);
    return result;
  }

//Team
async function addTeam(TeamData) {
    const result = await api.post(`/api/teams/`, TeamData);
    return result;
}

async function getTeams() {
    const result = await api.get(`/api/teams/`);
    return result;
}

async function getTeamById(TeamId) {
    const result = await api.get(`/api/teams/${TeamId}`);
    return result;
}

async function updateTeam(TeamData, TeamId) {
    const result = await api.put(`/api/teams/${TeamId}`, TeamData);
    return result;
}

async function deleteTeam(TeamId) {
    const result = await api.delete(`/api/teams/${TeamId}`);
    return result;
}

async function addMembersToTeam(TeamData, TeamId) {
    const result = await api.post(`/api/teams/${TeamId}/members`, TeamData);
    return result;
}

//subteams
async function getSubTeams() {
    const result = await api.get(`/api/subteams/`);
    return result;
}

async function getSubTeamById(TeamId) {
    const result = await api.get(`/api/subteams/${TeamId}`);
    return result;
}

async function addSubTeam(TeamData) {
    const result = await api.post(`/api/subteams/`, TeamData);
    return result;
}

async function updateSubTeam(TeamId, TeamData) {
    const result = await api.put(`/api/subteams/${TeamId}`, TeamData);
    return result;
}

async function deleteSubTeam(TeamId) {
    const result = await api.delete(`/api/subteams/${TeamId}`);
    return result;
}

async function getSubTeamMembers(subTeamId) {
    const result = await api.get(`/api/subteams/${subTeamId}/members/`);
    return result;
}

async function addSubTeamMember(subTeamId, subTeamData) {
    const result = await api.post(`/api/subteams/${subTeamId}/members/`, subTeamData);
    return result;
}

async function deleteSubTeamMember(subTeamId,userId) {
    const result = await api.delete(`/api/subteams/${subTeamId}/members/${userId}`);
    return result;
}

//users
async function getUsers() {
    const result = await api.get(`api/users/`);
    return result;
}
async function pendingUsers(status) {
    const result = await api.get(`/api/users/invitation?status=${status}`);
    return result;
}

async function inviteUsers(userData) {
    const result = await api.post(`api/users/invite`, userData);
    return result;
}

async function revokeUsers(userData, id) {
    const result = await api.put(`/api/users/invitation/${id}`, userData);
    return result;
}

async function inviteBulkUsers(userData) {
    const result = await api.post(`/api/users/invite/bulk`, userData);
    return result;
}

async function aiAudioResponse(data) {
    const result = await api.post("/api/ai_audio_response", data)
    return result
}

// Escalation function with query parameters
async function addEscalation(callId, reason) {
    const result = await api.post(`/api/escalate/?call_id=${callId}&reason=${encodeURIComponent(reason)}`);
    return result;
  }

  async function getEscalations() {
    const result = await api.get(`/api/escalate/`);
    return result;
}

async function updateUserByAdmin(userId, payload) {
    const result = await api.post(`/api/users/update_by_admin/${userId}`, payload);
    return result;
}

async function updateExtension(Data) {
    const result = await api.post(`/update_extension`, Data);
    return result;
}

//tags
async function AddTags(Data) {
    const result = await api.post(`/tag_list`, Data);
    return result;
}

async function getTags(tagId) {
    const result = await api.get(`/tag_list?tag_list_id=${tagId}`);
    return result;
}
async function getAllTags() {
    const result = await api.get(`/tag_list`);
    return result;
}

async function updateTags(payload ,userId) {
    const result = await api.put(`/tag_list?tag_list_id=${userId}`, payload);
    return result;
}

async function deleteTag(Id) {
    const result = await api.delete(`/api/subteams/${Id}`);
    return result;
}

export const AdminService = {
    getRoles,
    getRoleById,
    getMenuByRoleId,
    addTerritory,
    getTerritory,
    getTerritoryId,
    updateTerritory,
    deleteTerritory,
    updateRoles,
    addTeam,
    getTeams,
    getTeamById,
    updateTeam,
    deleteTeam,
    updateRolesMenu,
    getUsers,
    inviteUsers,
    addMembersToTeam,
    getTerritoryByUserId,
    AssignTerritory,
    getSubTeams,
    getSubTeamById,
    addSubTeam,
    updateSubTeam,
    deleteSubTeam,
    aiAudioResponse,
    MultiAssignTerritory,
    addEscalation,
    getEscalations,
    updateUserByAdmin,
    inviteBulkUsers,
    getSubTeamMembers,
    addSubTeamMember,
    deleteSubTeamMember,
    pendingUsers,
    AddTags,
    getTags,
    updateTags,
    deleteTag,
    getAllTags,
    revokeUsers,
    updateExtension
  }