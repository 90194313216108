import React, {useState, useEffect} from 'react';
import DataTable from './ContactList';
import Personalize from "./Personalize";
import NoData from "./NoData";
import EmailCount from "./EmailCount";
import ChooseMethod from "./ChooseMethod";
import SocialLink from "./SocialLink";
import { useDispatch, useSelector } from 'react-redux';
import { emailActions, workspaceActions } from '../../../actions';
import { scrambleEmail } from '../../Common/Utils';
import { URL_VAL } from '../../Common/Constants';
import Backdrop from '../../Common/Backdrop';

const tableHeader = ['Email Address', 'First Name', 'Last Name', 'Phone Number'];
const  urlval = "ydkof244mjfhkih933klk30hgmn44$oj";
const headCells = [
    {
        id: 'email',
        numeric: false,
        disablePadding: true,
        label: 'Email Address',
    },
    {
        id: 'first name',
        numeric: false,
        disablePadding: false,
        label: 'First Name',
    },
    {
        id: 'last name',
        numeric: false,
        disablePadding: false,
        label: 'Last Name',
    },
    {
        id: 'phone',
        numeric: false,
        disablePadding: false,
        label: 'Phone Number',
    },
];
const SendEmail = ({surveyID, surveyData})=>{
    const [contactList, setContactList]  = useState(false);
    const [emailCountPage, setEmailCountPage]  = useState(false);
    const [chooseMethodPage, setChooseMethodPage]  = useState(false);
    const [personalizePage, setPersonalizePage]  = useState(false);
    const [uploadedData, setData]  = useState([]);
    const [headings, setHeading]  = useState([]);
    const [headerCell, setHeaderCell]  = useState([]);
    const [socialLinkPage, setSocialLinkPage]  = useState(false);
    const [people, setPeople]  = useState(100);
    const [showEmailLink, setEmailLink]  = useState(true);

    const dispatch = useDispatch();
    const {loading, success} = useSelector(state => state.bulkEmailCreate);
    const audience = useSelector(state => state.getAudience);
    const survey_url = window.location.origin + `/survey_ans/${surveyID}&${URL_VAL}`;

    useEffect(() => {
        dispatch(emailActions.getAudience(surveyID))
    }, [surveyID]);

    useEffect(()=>{
        if(audience?.item?.length){
            let arr_ob = [];
            audience.item.forEach(({id, email="", person_details={}, group}) => {
                if(group === undefined || group === null || group === 'none'){
                    arr_ob.push({
                        id,
                        email,
                        ...person_details
                    });
                }
            });
            setData(arr_ob);
            setEmailCountPage(true);
            setChooseMethodPage(true);
            setContactList(true);
            setPersonalizePage(true);
        }else{
            setData([]);
            setEmailCountPage(false);
            setChooseMethodPage(false);
            setContactList(false);
            setPersonalizePage(false);
        }
    }, [audience]);

    useEffect(()=> {
        if(headings?.length){
            const header = tableHeader.map((label,i)=>{
                let id = [];
                switch (label) {
                    case 'Email Address':
                        id = headings.filter( i => ['email', 'emails'].indexOf(i.trim().toLowerCase()) > -1);
                        break;
                    case 'First Name':
                        id = headings.filter( i => ['first', 'first name'].indexOf(i.trim().toLowerCase()) > -1);
                        break;
                    case 'Last Name':
                        id = headings.filter( i => ['last', 'last name'].indexOf(i.trim().toLowerCase()) > -1);
                        break;
                    case 'Phone Number':
                        id = headings.filter( i => ['phone','phone number'].indexOf(i.trim().toLowerCase()) > -1);
                        break;
                    default: break;
                }
                return {
                    id: id.length ? id[0].trim().toLowerCase() : 'empty'+i,
                    numeric: false,
                    disablePadding: i==0,
                    label,
                }
            });
            setHeaderCell(header);
        }
    }, [headings]);

    useEffect(() => {
        if(contactList && !loading && success){
            setPersonalizePage(true);
        }
    }, [loading, success]);

    const onNoDataSubmit = () =>{
        setEmailCountPage(true);
    };
    const onEmailCountSubmit = () =>{
        setChooseMethodPage(true);
    };
    const onCreateContactSubmit = (list) =>{
        if(list){
            setData(list);
        }
        setContactList(true);
    };
    const onContactListSubmit = (list) =>{
        const heads = headerCell?.length ? headerCell : headCells;
        let i =0;
        let records = [];
        for (i; i<list.length; i++){
            const item = list[i],
                email = item[heads[0].id],
                person_details = {
                    'first name' :  item[heads[1].id],
                    'last name' : item[heads[2].id],
                    'phone number': heads.length > 2 ? item[heads[3].id] : "",
                };

            const req = {
                email,
                group: "none",
                person_details,
                personal_link: survey_url+("/" + scrambleEmail(email)),
                survey_sent: false,
                responded: false
            };
            records.push(req);
        }

       const params = {
           survey_id:surveyID,
           records
       };
        dispatch(workspaceActions.bulkCreateEmailLinks(params, surveyID));
        setEmailLink(false);
    };

    const onPersonalizeSubmit = () =>{
        setEmailLink(true);
        setSocialLinkPage(true);
    };

    const onClickCreateCont = () => {
        setChooseMethodPage(false);
        setContactList(false);
        setPersonalizePage(false);
        setSocialLinkPage(false);
    };

    const closeHandler = () => {
        if(personalizePage){
            setPersonalizePage(false);
        } else if (contactList){
            setContactList(false);
        }
       // setContactList()
    };

    const mobileView = true;
    return(
       <div className={`createSurveyBody ${mobileView ? 'mobileView':''}`}>
           { audience.loading ?  <Backdrop show={audience.loading}/> : !emailCountPage ? <NoData onNoDataSubmit={onNoDataSubmit} surveyData={surveyData}/>:
               (!chooseMethodPage ? <EmailCount onEmailCountSubmit={onEmailCountSubmit} count={people} setCount={setPeople}/>:
                   (!contactList ? <ChooseMethod onCreateContactSubmit={onCreateContactSubmit} uploadedData={uploadedData} setData={setData} setHeading={setHeading}/>:
                       (!personalizePage ? <DataTable onContactListSubmit={onContactListSubmit} uploadedData={uploadedData} headings={headerCell?.length ? headerCell : headCells} processing={loading} onCancel={closeHandler} count={people}/>:
                           (!socialLinkPage ? <div><Personalize surveyID={surveyID} onPersonalizeSubmit={onPersonalizeSubmit} survey_url={survey_url} onClickCreateCont={onClickCreateCont} onCancel={closeHandler} setData={setData}/>
                                   {showEmailLink ? <SocialLink surveyID={surveyID} survey_url={survey_url} header={'Share Survey Link'}/>:null}
                               </div>:
                               <SocialLink surveyID={surveyID} survey_url={survey_url}/>))))
           }
       </div>
    )
}

export default SendEmail;
