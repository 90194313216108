import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";
import { AdminService } from "../../../services/AdminService";
import { territoryAction } from "../../../actions/territoryActions";
import { useDispatch } from "react-redux";
import { getUser } from "../../Common/Utils";

const TerritoryCreationForm = ({ onCancel, terEdit, terData }) => {
  const [formData, setFormData] = useState({
    name: "",
    delegate: "",
    type: "",
    members: [],
  }); 
  const [userData, setUserData] = useState([]);
  const dispatch = useDispatch();
  const [selectedUsers, setSelectedUsers] = useState([]);



  const membersList = [
    "John Doe",
    "Jane Smith",
    "Alice Brown",
    "Bob White",
    "Charlie Green",
  ];

  useEffect(() => {
    const fetchUsers = async () => {
      const data = await AdminService.getUsers();
      setUserData(data?.data);
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    if (terEdit && terData) {
      setFormData({
        name: terData.name || "",
        member_id: terData.member_id || [],
        type: terData.type || "",
      });
    }
  }, [terEdit, terData]);

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleMemberToggle = (member) => {
  //   setFormData((prev) => ({
  //     ...prev,
  //     members: prev.members.includes(member)
  //       ? prev.members.filter((m) => m !== member)
  //       : [...prev.members, member],
  //   }));
  // };

  const handleSubmit = (e) => { 
    e.preventDefault();
    const userDetails = getUser();

    setFormData({ name: "", delegate: "", type: "", members: [] });

    if (terEdit) {
      dispatch(territoryAction.updateTerritory(formData, terData.id));
    } else {
      dispatch(territoryAction.addTerritory(formData))
        .then((response) => {

          const territoryId = response?.data?.id; 

          if (territoryId) {
            setTimeout(() => {
              dispatch(territoryAction.assignTerritory(userDetails.user_id, territoryId));
            }, 1000);
          } else {
            console.error("Territory ID not found in response");
          }
        })
        .catch((error) => {
          console.error("Error adding territory:", error);
        });
    }

    onCancel();
};

const handleMemberToggle = (userId) => {
  setSelectedUsers((prevSelected) =>
    prevSelected.includes(userId)
      ? prevSelected.filter((id) => id !== userId)
      : [...prevSelected, userId]
  );
};

const handleAssign = async () => {
    const payload = { user_ids: selectedUsers };
    dispatch(territoryAction.multiAssignTerritory(terData.id, payload))

};

  return (
    <Paper elevation={3} style={{ padding: "20px", borderRadius: "10px" }}>
      <Typography variant="h6" gutterBottom>
      {terEdit ? "Edit Territory" : "Create New Territory"}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {/* Left Section */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Territory Name"
                  name="name"
                  value={terEdit ? terData.Name : formData.name}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  label="Delegate"
                  name="delegate"
                  value={formData.delegate}
                  onChange={handleChange}
                  select
                  fullWidth
                  required
                >
                  <MenuItem value="Manager">Manager</MenuItem>
                  <MenuItem value="Supervisor">Supervisor</MenuItem>
                  <MenuItem value="Employee">Employee</MenuItem>
                </TextField>
              </Grid> */}
              <Grid item xs={12}>
                <TextField
                  label="Type"
                  name="type"
                  value={terEdit ? terData.Type : formData.type}
                  onChange={handleChange}
                  //select
                  fullWidth
                  required
                >
                  {/* <MenuItem value="North">North</MenuItem>
                  <MenuItem value="South">South</MenuItem>
                  <MenuItem value="East">East</MenuItem>
                  <MenuItem value="West">West</MenuItem> */}
                </TextField>
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  label="Description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  fullWidth
                  required
                />
              </Grid> */}

            </Grid>
          </Grid>

          {/* Right Section */}
          <Grid item xs={12} md={6}>
      <Box
        sx={{
          border: "1px solid #ccc",
          borderRadius: "10px",
          padding: "10px",
          maxHeight: "340px",
          overflowY: "auto",
        }}
      >
        <Typography variant="subtitle1" gutterBottom>
          Members
        </Typography>
        <FormGroup>
          {userData.map((member, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={selectedUsers.includes(member.id)}
                  onChange={() => handleMemberToggle(member.id)}
                />
              }
              label={member.username}
            />
          ))}
        </FormGroup>
        <Button variant="contained" color="primary" onClick={handleAssign} sx={{ marginTop: 2 }}>
          Assign
        </Button>
      </Box>
    </Grid>
        </Grid>

        {/* Submit and Cancel Buttons */}
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item>
            <Button type="submit" variant="contained" color="primary">
            {terEdit ? "Update" : "Submit"}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

TerritoryCreationForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  terData: PropTypes.object,
  terEdit: PropTypes.bool,
};

export default TerritoryCreationForm;
