import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  TextField,
  DialogContentText,
  Typography,
  Box,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Professional success icon
import { AdminService } from "../../../services/AdminService";
import { swalAlert } from "../../Common/SwalAlert";

const InviteTeamPopup = ({ onClose }) => {
  const [permissionProfile, setPermissionProfile] = useState("");
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [invitePopupOpen, setInvitePopupOpen] = useState(true);

  const handleInvite = async () => {
    const trimmedEmail = email.trim();

    if (!trimmedEmail || !permissionProfile) {
      console.error("Please provide both an email and a permission profile");
      return;
    }

    try {
      await AdminService.inviteUsers({
        email: trimmedEmail,
        role_id: Number(permissionProfile),
      });
      console.log("Invite sent successfully");
      setInvitePopupOpen(false);
      setSuccessMessage(true);
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;

        if (status === 400) {
          console.error("User with this email already exists:", data.message);
          swalAlert({
            title: "A user with this email already exists.",
            text: "A user with this email already exists.",
            icon: "error",
            showConfirmButton: true,
        });
        }
    }
  }
  };

  return (
    <>
      {/* Invite Team Popup */}
      <Dialog open={invitePopupOpen} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Invite Your Team</DialogTitle>
        <DialogContent style={{ paddingTop: "10px" }}>
          <TextField
            select
            label="Set Permission Profile"
            fullWidth
            value={permissionProfile}
            onChange={(e) => setPermissionProfile(e.target.value)}
            style={{ marginBottom: "16px" }}
          >
            <MenuItem value="3">Manager</MenuItem>
            <MenuItem value="4">User</MenuItem>
          </TextField>

          <TextField
            label="Enter User Email"
            multiline
            rows={2}
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email address"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleInvite} color="primary" variant="contained">
            Invite
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Popup */}
      <Dialog
        open={successMessage}
        onClose={() => {
          setSuccessMessage(false);
          onClose();
        }}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <CheckCircleIcon sx={{ color: "green", fontSize: 32 }} />
          Invitation Sent
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center" sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Success!
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
              An invitation has been successfully sent to:
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mt: 1,
                fontWeight: "bold",
                color: "primary.main",
              }}
            >
              {email}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", pb: 2 }}>
          <Button
            onClick={() => {
              setSuccessMessage(false);
              onClose();
            }}
            variant="contained"
            color="primary"
            sx={{ px: 4, py: 1, fontWeight: "bold" }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InviteTeamPopup;
