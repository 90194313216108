import React, { useState, useEffect } from "react";
import { Header, SubHeaderSurvey, MainMenu } from "../Common/Header";
import Analyse from "./AnalyseResults";
import Tags from "./AnalyseResults/Tags";
import SurveySubHeader from "./Components/SurveySubHeader";
import NewCreateSurvery from "./DesignSurvey/NewCreateSurvery";
import { PreviewDialog } from "./Preview/Preview";
import CollectResponse from "./SendEmail/SendEmail";
import ShareLink from "./shareLink/ShareLink";
import { workspaceActions } from "../../actions/index";
import { useDispatch, useSelector } from "react-redux";
import PageLogic from "./DesignSurvey/PageLogic";
import { UPLOADED_SURVEY_TABS } from "../Common/Constants";
import { setLocalStorage, getLocalStorage } from "../Common/Utils";
import Summary from "./Summary/index";

const Survey = (props) => {
  const surveyID = Number(props?.match?.params?.id) || 0;
  const surveytabs = props?.location?.state?.menus;
  const [active, setActive] = useState(
    getLocalStorage("activeTab") || "Summary"
  );
  const { loading, res } = useSelector((state) => state.wsDetailsAuth);
  const [activeMenus, setActiveMenus] = useState();
  const [analysisTab, setAnalysisTab] = useState(getLocalStorage("activeSubTab") || "");
  const [mount, setMount] = useState(false);
  const [flexStart, setStart] = useState(false);
  const dispatch = useDispatch();
  setLocalStorage("isNewSurvey", false);

  useEffect(() => {
    surveyID && dispatch(workspaceActions.getWsDetailWithAuth(surveyID));
  }, [surveyID]);
  useEffect(() => {
    if (
      res?.survey_type?.toLowerCase() === "external" ||
      res?.survey_type?.toLowerCase() === "historical"
    ) {
      const activeIndex = UPLOADED_SURVEY_TABS.indexOf(getLocalStorage("activeTab"));
      setActiveMenus(UPLOADED_SURVEY_TABS);
      !mount && setActive(UPLOADED_SURVEY_TABS[activeIndex < 0 ? 1 : activeIndex]);
      setMount(true);
      setStart(true);
    }
  }, [res, mount]);

  useEffect(() => {
    if (props?.location?.state?.tab) {
      setActive(props.location.state.tab);
    }
    if (props?.location?.state?.menus) {
      setActiveMenus(props.location.state.menus);
    }
  }, [props?.location]);

  useEffect(() => {
    setLocalStorage("activeTab", active);
    if (analysisTab !== "") {
      setTimeout(() => {
        setAnalysisTab(null);
      });
    }
    setLocalStorage("activeSubTab", null);
  }, [active, analysisTab]);

  const handleClose = () => {
    setActive("DesignSurvey");
  };

  const activeComp = () => {
    switch (active) {
      case "Summary":
        return (
          <Summary
            surveyID={surveyID}
            setActiveTab={setActive}
            setAnalysisTab={setAnalysisTab}
          />
        );
      case "DesignSurvey":
        return (
          <NewCreateSurvery
            setActiveTab={setActive}
            setAnalysisTab={setAnalysisTab}
            surveyID={surveyID}
          />
        );
      case "PageLogic":
        return <PageLogic surveyID={surveyID} />;
      case "Preview":
        return (
          <PreviewDialog
            surveyID={surveyID}
            show={true}
            handleClose={handleClose}
            isPreview={true}
          />
        );
      case "CollectResponses":
        return res?.share_responses === "sendEmail" ? (
          <CollectResponse surveyID={surveyID} surveyData={res || {}} />
        ) : (
          <ShareLink surveyID={surveyID} />
        );
      case "AnalyseResults":
        return <Analyse surveyID={surveyID} analysisTab={analysisTab} />;
      case "Tags":
        return <Tags surveyID={surveyID} />;
      default:
        return;
    }
  };

  return (
    <div className={"CreateSurveyWrapper"}>
      <Header surveyID={surveyID} showEyeIcon={true} />
      <MainMenu />
      <SurveySubHeader
        surveyTitle={res?.survey_title || ""}
        activeMenu={active}
        surveyMenus={activeMenus}
        setActiveMenu={setActive}
        flexStart={flexStart}
      />
      {activeComp()}
    </div>
  );
};

export default Survey;
