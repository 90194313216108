import React,{useState, useEffect} from "react";
import TextArea from "../../../../common/controls/inputs/TextArea";

const  AnswerBox = ({settings, ans, setAns, qno, qRefs, onBlur, quesType, onFocus, setErr}) => {
    const {maxChar} = settings;
    const textAreaStyle = {height: quesType === "LongText"?150:100, marginTop:10};
    const triggerFocus = () => {
        onFocus && qno > 0 && onFocus(qno-1);
    };
    const onChange = (e) => {
        const {value} = e.target;
        if (maxChar && value.length > maxChar) {
            setErr(maxChar + " maximum number of characters being accepted");
            const str = value.slice(0, maxChar);
            setAns(str);
            return;
        }
        setErr("");
        setAns(e.target.value);
    }
    return <div className={"answerWrap"} style={{ display: 'flex', marginBottom:"20px" }}>
        <div style={{width:'100%'}}>
        {quesType === "LongText" ?
            <TextArea qRef={(r) => qRefs && (qRefs.current[qno - 1] = r)} config={{
                inputClass: 'answerInputArea',
                textAreaStyle
            }} value={ans} onChange={onChange} onBlur={onBlur} onFocus={triggerFocus}/>
            :<input ref={(r)=> qRefs && (qRefs.current[qno-1] = r)} className={"answerInputArea"} value={ans} onChange={onChange} onBlur={onBlur} onFocus={triggerFocus}/>
        }
            {maxChar && <span style={{float:"right", color:'#9b9696'}}>Max Characters : {maxChar}</span>}
        </div>
    </div>
}

const Default = (props) =>{
    const {quesType, layout,children, qus_id, question, desc, qno=1, settings={}, cardRef, onChange, qRefs, submit, btnText='Next', sliderView, onFocus, showSubmitBtn} = props;
    const { font, underline, bold, fontSize, italic, fontColor, require } = settings,
        style = { fontFamily: font, fontSize, fontWeight: bold ? 'bold' : '', fontStyle: italic ? 'italic' : '', textDecoration: underline ? 'underline' : '', color: fontColor };

    const [ans, setAns] = useState('');
    const [err, setErr] = useState('');

    useEffect(()=> {
        const elements = document.getElementsByName("question_"+qno);
        let ele = elements.length ? elements[0] : elements;
        ele.style.setProperty('font-family', font||'Poppins', 'important');
    }, [font]);

    const onNext = () => {
        if(btnText === 'Next'){
            cardRef && cardRef.current.next();
            (qRefs?.current?.length >=qno) && setTimeout(() => { qRefs.current[qno]?.focus() }, 10);
        }else{
            submit && submit();
        }
    };

    const onBlur = () => {
        let _ans = ans.trim();
        if(require && _ans === ''){
            setErr('This is required.');
        } else {
            let obj = {
                "qus_id": qus_id,
                "q_type":quesType,
                "qus_text": question,
                "answers": [{ "text":_ans }]
            };
            onChange && onChange({name: qno, value:obj});
            setErr('');
        }
        setAns(_ans);
    };

    return(
        <div className={"questionAnswerWrap longTextShortWrap"}>
            <div className="ques">
                <h3 name={"question_"+qno} style={style}>{question}
                    <span style={{color:"red", marginLeft:'5px',display: require?'unset':'none'}}>*</span>
                </h3>
               { <p> {desc || ''} </p>}
            </div>
            {layout == 1 && children}
            <AnswerBox settings={settings} ans={ans} setAns={setAns} qno={qno} qRefs={qRefs} onBlur={onBlur} quesType={quesType} onFocus={onFocus} setErr={setErr}/>
            { err !== "" ? <div> <p style={{color:'red'}}>{err}</p></div> : <div><p>&nbsp;</p></div> }

            { sliderView ? (btnText=== 'Submit' && !showSubmitBtn) ? null : <button className="btnSave" onClick={onNext}>{btnText}</button> : null}
        </div>
    )
}

export default Default;
