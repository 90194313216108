import React, { useContext } from "react";
import DragItem from "./DragItem";
import { Grid, GridItem } from "./Grid";
import GridContext from "./GridContext";


function GridWrapper({onSelect,className, GridRenderer, StaticRenderer, gridWrapStyle={}, gridStyle={}, canDrag}) {
  const { items, moveItem } = useContext(GridContext);

  return (
    <div style={{width:"100%"}}>
      <Grid className={className} style={gridWrapStyle}>
        {items.map((item, i) => (
          <DragItem key={item.id} id={item.id} onMoveItem={moveItem} canDrag={canDrag}>
            <GridItem gridStyle={gridStyle}>
               <GridRenderer item={item} index={i}/>
            </GridItem>
          </DragItem>
        ))}
          {StaticRenderer &&
          <DragItem key={'static_input'} id={'static_input'} >
              <GridItem gridStyle={gridStyle}>
                    <StaticRenderer />
              </GridItem>
          </DragItem>}
      </Grid>
    </div>
  );
}

export default GridWrapper;
