import React, { lazy, Suspense, useState } from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Box,
  Typography,
  Paper,
  InputBase,
  Stack,
  Button,
  Dialog,
  useMediaQuery
} from "@mui/material";
import { Search, Info as InfoIcon, Call } from "@mui/icons-material";
import { deepOrange } from "@mui/material/colors";

import { useDispatch, useSelector } from "react-redux";
import { customCallActions } from "../../actions/customCallActions";
import CallMissedIcon from "@mui/icons-material/CallMissed";
import CallMadeIcon from "@mui/icons-material/CallMade";
import { Grid } from "antd";
import { formatCallDuration, formatDateTime } from "../../helpers/helper";
import { customerEngagementActions } from "../../actions/customerEngagementActions";
import Backdrop from "../../voc/Common/Backdrop";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CallModal from "../../voc/CRM/CustomerTable/CallModal";
import { useHistory } from "react-router-dom";
import { AiCallActions } from "../../actions/AiCallActions";

const CallDetailsPopup = lazy(() => import("./CallDetails"));

const DialCommonList = ({
  data = [],
  onInfoClick,
  listType = "Contacts",
  avatarColor = deepOrange[500],
  renderEmptyMessage = "No items found.",
  handleClose,
  handleIncreaseCallLogLimit,
}) => {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [aiCall, setAiCall] = useState(false);
  const [callId, setCallId] = useState({
    id: null,
    phone_no: null,
  });
  const dispatch = useDispatch();
  const navigate = useHistory();

  function handleSetCallId(id, phone_no) {
    console.log(id, phone_no, "idddd");
    setAiCall(true);
    setCallId({
      id: id,
      phone_no: phone_no,
    });
  }

  // Filter logic based on search input value
  const filteredData = data?.filter((item) =>
    `${item.first_name} ${item.last_name}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  // Open popup with selected data
  const handleInfoClick = async (customer_id) => {
    setOpen(true);
    dispatch(customerEngagementActions.getCustomerEngagement(customer_id));
    dispatch(customerEngagementActions.getCallLogs(customer_id));
    dispatch(customerEngagementActions.getSummaryEngagement(customer_id));
    dispatch(customerEngagementActions.getEmailLogs(customer_id));
    dispatch(customerEngagementActions.getFeedbackResponses(customer_id));
  };

  const closePopup = () => {
    setOpen(false);
  };

  const isLoading = useSelector(
    (state) =>
      state?.customerEngagementLogic?.customerLoading ||
      state?.customerEngagementLogic?.summaryLoading ||
      state?.customerEngagementLogic?.logsLoading ||
      state?.customerEngagementLogic?.emailLoading ||
      state?.customerEngagementLogic?.feedbackLoading
  );
  const message = useSelector(
    (state) => state?.customerEngagementLogic?.message
  );

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        bgcolor: "background.paper",
        padding: 2,
      }}
    >
      <Box sx={{ width: "100%", maxWidth: 500, margin: "auto" }}>
        {/* Search Input */}
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            boxShadow: 3,
            "&:hover": { boxShadow: 5 },
            transition: "box-shadow 0.3s ease-in-out",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={`Search ${listType}`}
            inputProps={{ "aria-label": "search" }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <Search />
          </IconButton>
        </Paper>
      </Box>

      <List sx={{ width: "100%", height: "100%" }}>
        {filteredData.length > 0 ? (
          filteredData.map((item, index) => (
            <>
              <ListItem
                key={index}
                sx={{
                  borderRadius: "8px",
                  border: "1px solid",
                  borderColor: "divider",
                  marginBottom: 1,
                  width: "100%",
                  padding: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  "&:hover": { bgcolor: "action.hover" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: !isMobile ? "flex" : "block",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          bgcolor: avatarColor,
                          width: { xs: 40, sm: 50 },
                          height: { xs: 40, sm: 50 },
                        }}
                      >
                        {/* {item.first_name.charAt(0)} */}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1">
                          {item.first_name
                            ? item.first_name + item.last_name
                            : item?.customer_name || item?.phone_number}
                        </Typography>
                      }
                      secondary={
                        listType === "Contacts" ? (
                          <Typography variant="body2" color="text.secondary">
                            {item?.first_phone}
                            <br />
                            {item.company}
                          </Typography>
                        ) : (
                          <Typography variant="body2" color="text.secondary">
                            {formatCallDuration(item?.call_duration)} <br />
                            {formatDateTime(item?.call_end_time)}
                          </Typography>
                        )
                      }
                      sx={{
                        "& .MuiListItemText-primary": { fontWeight: "medium" },
                      }}
                    />
                  </Box>
                </Box>

                <Stack direction="row" spacing={1}>
                  <IconButton
                    // onClick={() =>
                    //   handleSetCallId(
                    //     item.customer_id ? item.customer_id : item?.id,
                    //     item?.first_phone ||
                    //       item?.mobile_phone ||
                    //       item?.phone_number
                    //   )
                    // }
                    onClick={() => dispatch(AiCallActions.makeAiCall())}
                    sx={{
                      color: "blueviolet",
                    }}
                  >
                    <AutoAwesomeIcon />
                  </IconButton>

                  {listType === "Recents" && (
                    <IconButton
                      edge="end"
                      aria-label="info"
                      sx={{ color: "primary.main" }}
                    >
                      {item?.call_type === "outbound" ? (
                        <CallMadeIcon />
                      ) : (
                        <CallMissedIcon color="error" />
                      )}
                    </IconButton>
                  )}

                  <IconButton
                    edge="end"
                    aria-label="info"
                    onClick={() => {
                      dispatch(
                        customCallActions.makeCall(
                          item?.first_phone ||
                            item?.mobile_phone ||
                            item?.phone_number,
                          item?.country,
                          navigate
                        )
                      );
                      handleClose();
                    }}
                    sx={{ color: "primary.main" }}
                  >
                    <Call />
                  </IconButton>
                  {listType === "Contacts" && (
                    <IconButton
                      edge="end"
                      aria-label="info"
                      onClick={() => handleInfoClick(item.id)}
                      sx={{ color: "primary.main" }}
                    >
                      <InfoIcon />
                    </IconButton>
                  )}
                </Stack>
              </ListItem>
            </>
          ))
        ) : (
          <Typography
            variant="body1"
            color="text.secondary"
            align="center"
            sx={{ mt: 4 }}
          >
            {renderEmptyMessage}
          </Typography>
        )}

        {listType === "Recents" && filteredData.length > 0 && (
          <Box sx={{ marginTop: 2, textAlign: "center" }}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ cursor: "pointer", fontWeight: "medium" }}
              onClick={handleIncreaseCallLogLimit}
            >
              Load More
            </Typography>
          </Box>
        )}
      </List>

      <Suspense fallback={<Backdrop show={true} />}>
        {isLoading ? (
          <Backdrop show={isLoading} message={message} />
        ) : (
          <CallDetailsPopup open={open} onClose={closePopup} />
        )}
      </Suspense>

      <Dialog
        open={aiCall}
        onClose={() => setAiCall(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CallModal
          isVisible={true}
          customerId={callId.id}
          phoneNumber={callId.phone_no}
          onClose={() => setAiCall(false)}
        />
      </Dialog>
    </Box>
  );
};

export default DialCommonList;
