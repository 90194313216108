import Swal from "sweetalert2";
import { customCallConstant } from "../constants/customCallConstant";
import { formatDate } from "../helpers/dateFormat";
import { CrmService } from "../services";
import { swalAlert } from "../voc/Common/SwalAlert";
import { getUser } from "../voc/Common/Utils";
import { callQueueActions } from "./callQueueActions";

let callEndTime;
const activeSession = new Map();

function registerSIP(ua, message, status) {
    if (status === "registration_failed") {
        console.log(message, "msggg");

        Swal.fire({
            icon: "error",
            title: "SIP Registration failed",
            text: message,
            showConfirmButton: true,
        });
    }

    return {
        type: customCallConstant.REGISTER_SIP,
        payload: { ua, sipMsg: message, sipStatus: status },
    };
}

// const activeSession = new Map();

// function getSession(session) {

    //   if (!activeSession)

        //   return { type: customCallConstant.GET_SESSION, payload: session };
    // }

function getAudioRef(audioRef) {
    return { type: customCallConstant.GET_AUDIO_REF, payload: audioRef };
}

function makeCall(number, country, navigate) {
    const usr = getUser();

    if (!usr.extension_number || !usr.extension_password) {
        return () => {
            Swal.fire({
                title: "Voip Details Not Found!",
                text: "Please update your voip details to make or recive calls.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, update it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate("/user-setting");
                }
            });
        };
    }

    return (dispatch, getState) => {
        const state = getState();
        const { ua } = state.customCall;

        const options = {
            mediaConstraints: { audio: true, video: false },
        };

        let prefix = "";

        if (country === "IN") {
            prefix = "011";
        } else if (country === "US") {
            prefix = "1";
        }

        if (ua) {
            ua.call(`sip:${prefix}${number}@pbx.articence.com`, options);
            dispatch({ type: customCallConstant.MAKE_CALL, payload: number });
        }
    };
}


function setIncommingCall(call, sessionRef, callType) {
    const callerNumber = call.remote_identity.uri.user;

    if (!activeSession.has(callerNumber)) {
        activeSession.set(callerNumber, {
            callerNumber,
            session: sessionRef,
            callType,
        });
    }

    return {
        type: customCallConstant.SET_INCOMING_CALL,
        payload: { call, callerNumber, callType },
    };
}


function removeSession(callerNumber) {
    return (dispatch) => {
        if (activeSession.has(callerNumber)) {
            activeSession.delete(callerNumber);
            dispatch(dispatch(callQueueActions.deleteCallQueue(callerNumber)));
        }
    }
}

function muteCallRequest() {
    return (dispatch, getState) => {
        const state = getState();
        const { incomingCall } = state.customCall;

        if (incomingCall) {
            incomingCall.mute({
                audio: true,
                video: false,
            });
            dispatch({ type: customCallConstant.MUTE_CALL });
        } else {
            console.error("No incoming call to mute");
        }
    };
}

function unMuteCallRequest() {
    return (dispatch, getState) => {
        const state = getState();
        const { incomingCall } = state.customCall;

        if (incomingCall) {
            incomingCall.unmute({
                audio: true,
                video: false,
            });
            dispatch({ type: customCallConstant.UNMUTE_CALL });
        } else {
            console.error("No incoming call to mute");
        }
    };
}

function acceptCallRequest() {
    return { type: customCallConstant.ACCEPT_CALL };
}

function holdCallRequest() {
    return (dispatch, getState) => {
        const state = getState();
        const incomingCall = state?.customCall?.incomingCall;

        if (!incomingCall) {
            console.error("No active call to hold.");
            return;
        }

        try {
            console.log("Attempting to hold the call...");
            incomingCall.hold({
                useUpdate: false,
            });
            dispatch({ type: customCallConstant.HOLD_CALL });
        } catch (error) {
            console.error("Error while holding the call:", error);
        }
    };
}

function unHoldCallRequest() {
    return (dispatch, getState) => {
        const state = getState();
        const incomingCall = state?.customCall?.incomingCall;

        if (!incomingCall) {
            console.error("No active call to unhold.");
            return;
        }

        try {
            console.log("Attempting to unhold the call...");
            incomingCall.unhold({
                useUpdate: false,
            });

            dispatch({ type: customCallConstant.UNHOLD_CALL });
        } catch (error) {
            console.error("Error while unholding the call:", error);
        }
    };
}

function transferCallRequest(target, callId) {
    return (dispatch) => {
        const { session } = activeSession.get(callId);


        console.log(session, "call-tansfer-session");

        try {
            if (session) {
                session.current.refer(target);
                dispatch({ type: customCallConstant.TRANSFER_CALL });
            }
        } catch {
            console.error("Error while transferring the call");
        }
    };
}

function endCallRequest() {
    return (dispatch) => {
        dispatch({ type: customCallConstant.END_CALL });
    };
}

function progressCall() {
    return { type: customCallConstant.PROGRESS_CALL };
}

function declineCallRequest() {
    return (dispatch, getState) => {
        const state = getState();

        try {
            const { incomingCall } = state.customCall;
            if (incomingCall) {
                incomingCall.terminate();
                dispatch({ type: customCallConstant.DECLINE_CALL });
            }
        } catch (e) {
            dispatch({ type: customCallConstant.DECLINE_CALL });
            console.error("Error while declining the call", e);
        }
    };
}

function startCaptureVoice(callId) {
    console.log("Voice capture started");

    const start_time = new Date().toISOString();

    return async (dispatch, getState) => {
        const { session } = activeSession.get(callId);

        console.log(session, "call-session");

        // const { session } = state.customCall;

        const sessionRef = session?.current?.connection;

        if (sessionRef) {
            const remoteStream = new MediaStream();

            sessionRef.getReceivers().forEach((receiver) => {
                if (receiver.track && receiver.track.kind === "audio") {
                    remoteStream.addTrack(receiver.track);
                }
            });

            if (remoteStream.getTracks().length === 0) {
                console.warn("No audio tracks available for recording.");
                return;
            }

            try {
                const remoteMediaRecorder = new MediaRecorder(remoteStream, {
                    mimeType: "audio/webm",
                });

                const audioChunks = [];
                remoteMediaRecorder.ondataavailable = (event) => {
                    if (event.data && event.data.size > 0) {
                        audioChunks.push(event.data);
                    }
                };

                remoteMediaRecorder.start(1000); // Record chunks every 1 second

                // Save MediaRecorder and audioChunks to session
                session.current.mediaRecorder = remoteMediaRecorder;
                session.current.audioChunks = audioChunks;

                console.log("MediaRecorder started:", remoteMediaRecorder);

                dispatch({
                    type: customCallConstant.START_CAPTURE_VOICE,
                    payload: start_time,
                });
            } catch (error) {
                console.error("Failed to start MediaRecorder:", error);
            }
        } else {
            console.error("No session connection available for capturing voice.");
        }
    };
}

function stopCaptureVoice(callId) {
    console.log("Stopping voice capture");
    return async (dispatch, getState) => {
        const state = getState();
        const { session, callLogId } = activeSession.get(callId);

        // const { session } = state.customCall;
        const { customerVoiceCaptureStartTime } = state.customCall;
        if (session?.current?.mediaRecorder) {
            const mediaRecorder = session.current.mediaRecorder;
            const audioChunks = session.current.audioChunks;

            if (mediaRecorder.state !== "inactive") {
                return new Promise((resolve, reject) => {
                    mediaRecorder.onstop = () => {
                        console.log("MediaRecorder stopped");

                        if (audioChunks.length === 0) {
                            console.error("No audio chunks recorded.");
                            reject(new Error("No audio chunks recorded"));
                            return;
                        }

                        // Combine audio chunks into a single Blob
                        const audioBlob = new Blob(audioChunks, { type: "audio/webm" });

                        // Clear MediaRecorder and chunks from session
                        session.current.mediaRecorder = null;
                        session.current.audioChunks = null;

                        console.log(callLogId, "callLogIdInStopCapture");
                        resolve({ audioBlob, customerVoiceCaptureStartTime, callLogId });
                    };

                    // Stop the MediaRecorder
                    mediaRecorder.stop();
                    dispatch({ type: customCallConstant.STOP_CAPTURE_VOICE });
                    console.log("Stopping MediaRecorder...");
                });
            } else {
                console.warn("MediaRecorder is not active.");
            }
        } else {
            console.error("MediaRecorder not found in session.");
        }
    };
}

function setTranscription(data) {
    return {
        type: customCallConstant.TRANSCRIPTION,
        payload: {
            transcription: data.generated_response,
            message: data.transcript,
        },
    };
}



const recordedAudioMap = new Map();

function captureAgentVoice(callId) {
 console.log("Voice capture started for callId:", callId);

    return async (dispatch, getState) => {
        const { session } = activeSession.get(callId);
        const sessionRef = session?.current?.connection;

        if (sessionRef) {
            const remoteStream = new MediaStream();

            sessionRef.getReceivers().forEach((receiver) => {
                if (receiver.track && receiver.track.kind === "audio") {
                    remoteStream.addTrack(receiver.track);
                }
            });

            if (remoteStream.getTracks().length === 0) {
                console.warn("No audio tracks available for recording.");
                return;
            }

            try {
                const mediaRecorder = new MediaRecorder(remoteStream, {
                    mimeType: "audio/webm",
                });

                const audioChunks = [];

                mediaRecorder.ondataavailable = (event) => {
                    console.log("Audio data available:", event.data.size);
                    if (event.data && event.data.size > 0) {
                        audioChunks.push(event.data);
                    }
                };

                mediaRecorder.start(1000); // Record in 1-second chunks

                // Store MediaRecorder and audioChunks in the Map
                recordedAudioMap.set(callId, { mediaRecorder, audioChunks });

                console.log(`Recording started for callId: ${callId}`);

                dispatch({
                    type: customCallConstant.START_CAPTURE_VOICE,
                });
            } catch (error) {
                console.error("Failed to start MediaRecorder:", error);
            }
        } else {
            console.error("No session connection available for capturing voice.");
        }
    };
}

let sequence_number = 1;
const stopAgentVoice = (callId) => {
    console.log(`Stopping voice capture for callId: ${callId}`);

    return async (dispatch, getState) => {
        if (!recordedAudioMap.has(callId)) {
            console.warn(`No active recording found for callId: ${callId}`);
            return;
        }
        const state = getState();
        const { agentVoiceCaptureStartTime } = state.customCall;

        const { mediaRecorder, audioChunks } = recordedAudioMap.get(callId);

        if (!mediaRecorder || mediaRecorder.state !== "recording") {
            console.warn(`MediaRecorder is not active for callId: ${callId}`);
            return;
        }

        mediaRecorder.onstop = async () => {
            console.log(`MediaRecorder stopped for callId: ${callId}`);

            if (audioChunks.length === 0) {
                console.error(`No audio chunks recorded for callId: ${callId}`);
                return;
            }

            const agentAudioBlob = new Blob(audioChunks, { type: "audio/webm" });

            // Remove from the Map after stopping
            recordedAudioMap.delete(callId);

            console.log(`Captured audio for callId: ${callId}`, agentAudioBlob);

            try {
                const { callLogId } = activeSession.get(callId);

                const formData = new FormData();
                formData.append("file", agentAudioBlob, "recording.webm");
                formData.append("call_log_id", callLogId);
                formData.append("start_time", new Date().toISOString().slice(0,16));
                formData.append("speaker", "agent");
                formData.append("sequence_number", sequence_number);

                // Upload audio
                const audioUrl = await CrmService.uploadAudio(formData);

                // Dispatch transcription update
                dispatch(setTranscription(audioUrl.data));
                sequence_number += 1;
            } catch (error) {
                console.error("Error uploading audio:", error);
            }
        };

        console.log(`Stopping MediaRecorder for callId: ${callId}...`);
        mediaRecorder.stop();
        dispatch({ type: customCallConstant.STOP_CAPTURE_VOICE });
    };
};
function getStartTime() {
    return async (dispatch) => {
        const date = new Date();
        const time = formatDate(date);
        dispatch({ type: customCallConstant.GET_CALL_START_TIME, payload: time });
    };
}

function StoreCallLogs(customerId, callType, callStatus, callId) {

    console.log(activeSession, 'callLogActivesession');
    return async (dispatch) => {
        const callStartTime = formatDate(new Date());

        const payload = {
            phone_number: callId,
            call_start_time: callStartTime,
            call_end_time: callStartTime,
            call_status: callStatus,
            call_duration: 0,
            recording: true,
            call_type: callType,
            customer_id: customerId,
        };

        try {
            const response = await CrmService.createCallLogs(payload);
            
            const callLogId = response.data.id;

            if(activeSession.has(callId)) {
                activeSession.set(callId, {
                    ...activeSession.get(callId),
                    callLogId: callLogId 
                })
                dispatch({
                    type: customCallConstant.STORE_CALL_SESSION,
                    payload: activeSession,
                });
            }

            dispatch({
                type: customCallConstant.STORE_CALL_LOGS,
                payload: { callLogId, callId },
            });
        } catch (e) {
            console.log(e);
        }
    };
}

function updateCallLogs(callStatus, customerId) {
    return async (dispatch, getState) => {
        const state = getState();

        const { callStartTime } = state.customCall;
        const { callType } = state.customCall;

        try {
            const callLogId = state.customCall.callLogId;
            const firstPhone =
                state.customCall.firstPhone || state.customCall.callerNumber;

            callEndTime = formatDate(new Date());

            const duration = new Date(callEndTime) - new Date(callStartTime);
            const callDuration = duration / 1000;

            const payload = {
                phone_number: firstPhone,
                call_status: callStatus,
                call_duration: callDuration,
                call_end_time: callEndTime,
                recording: true,
                call_type: callType,
            };

            //const payloadPost = {
            //    phone_number: firstPhone,
            //    call_start_time: callEndTime,
            //    call_end_time: callEndTime,
            //    call_status: callStatus,
            //    call_duration: 0,
            //    recording: true,
            //    call_type: callType,
            //    customer_id: customerId,
            //};

            if (callStartTime !== null) {
                payload.call_start_time = callStartTime;
            }

            CrmService.updateCallLogs(payload, callLogId);
            //if (callLogId == undefined) {
            //    CrmService.createCallLogs(payloadPost);
            //} else {
            //}
            //CrmService.updateCallLogs(payload, callLogId)
        } catch (e) {
            console.log(e);
        } finally {
            dispatch({ type: customCallConstant.GET_CALL_START_TIME, payload: null });
        }
    };
}

function getAllCallLogsWithFilter(filter) {
    return async (dispatch, getState) => {
        try {
            const response = await CrmService.getAllCallLogsWithFilter(filter);
            dispatch({
                type: customCallConstant.GET_CALL_LOGS,
                payload: response.data,
            });
        } catch (e) {
            console.log(e);
        }
    };
}

function getAllEmailLogsWithFilter(filter) {
  return async (dispatch, getState) => {
    const user = getUser(); // Get the user

    if (!user) {
      console.warn("No user found, skipping API call");
      return; // Exit early if no user
    }

    try {
      const response = await CrmService.getAllEmailsHistory(filter);
      dispatch({
        type: customCallConstant.GET_ALL_EMAILS,
        payload: response.data,
      });
    } catch (e) {
      console.log(e);
    }
  };
}

function getCallDashboardData(filter) {
  return async (dispatch, getState) => {
    const user = getUser(); // Get user details

    if (!user) {
      console.warn("No user found, skipping API call");
      return; // Exit early if no user
    }

    try {
      const response = await CrmService.getDashboardData(filter);
      dispatch({
        type: customCallConstant.GET_DASHBOARD_DATA,
        payload: response.data,
      });
    } catch (e) {
      console.log("Error fetching dashboard data:", e);
    }
  };
}


function getCustomerStatus(status) {
    return { type: customCallConstant.GET_CUSTOMER_STATUS, payload: status };
}

function getCustomerData(id) {
    return async (dispatch, getState) => {
        try {
            const response = await CrmService.getCustomerDataById(id);
            console.log(response);
            dispatch({
                type: customCallConstant.GET_CUSTOMER_DATA,
                payload: response.data,
            });
        } catch (e) {
            console.log(e);
        }
    };
}

function getCallSummary(callId) {
    const request = () => ({
        type: customCallConstant.GET_CALL_SUMMARY,
    });

    const success = (callSummary) => ({
        type: customCallConstant.GET_CALL_SUMMARY_SUCCESS,
        callSummary: callSummary,
    });

    const failure = () => ({
        type: customCallConstant.GET_CALL_SUMMARY_FAILURE,
    });

    return (dispatch) => {
        dispatch(request());

        CrmService.getCallSummary(callId).then(
            (data) => {
                dispatch(success(data.data));
            },
        ).catch ((error) => {
            dispatch(failure());
            swalAlert({
                icon: "error",
                title: error?.response?.data?.detail?.toString(), 
                showConfirmButton: true,
            });
        });
    };
}

export const customCallActions = {
    registerSIP,
    makeCall,
    getAudioRef,
    setIncommingCall,
    acceptCallRequest,
    holdCallRequest,
    unHoldCallRequest,
    transferCallRequest,
    progressCall,
    declineCallRequest,
    endCallRequest,
    muteCallRequest,
    StoreCallLogs,
    updateCallLogs,
    unMuteCallRequest,
    startCaptureVoice,
    stopCaptureVoice,
    setTranscription,
    // getSession,
    getAllCallLogsWithFilter,
    getCallDashboardData,
    getCustomerStatus,
    getCustomerData,
    getAllEmailLogsWithFilter,
    getCallSummary,
    getStartTime,
    captureAgentVoice,
    stopAgentVoice,
    removeSession
};
