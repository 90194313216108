import { subscriptionConstants } from "../constants";
import { subscriptionService } from "../services";
import { swalAlert } from "../voc/Common/SwalAlert";

const request = (message) => ({
  type: subscriptionConstants.GETPLANDATAREQUEST,
  message,
});

const success = (message) => ({
  type: subscriptionConstants.GETPLANDATASUCCESS,
  message,
});

const errors = (message) => ({
  type: subscriptionConstants.GETPLANDATAERROR,
  message,
});

const featuresRequest = (message) => ({
  type: subscriptionConstants.GETFEATURESREQUEST,
  message,
});

const featuresSuccess = (message) => ({
  type: subscriptionConstants.GETFEATURESSUCCESS,
  message,
});

const featuresErrors = (message) => ({
  type: subscriptionConstants.GETFEATURESERROR,
  message,
});
const getSubscriptionPlanData = (planType) => {
  return (dispatch) => {
    dispatch(request());

    subscriptionService.getPlanData(planType).then(
      (alerts) => {
        // console.log("dsdsds", alerts);
        dispatch(success(alerts));
      },
      (error) => {
        dispatch(errors(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getFeaturesData = (planType) => {
  return (dispatch) => {
    dispatch(featuresRequest());

    subscriptionService.getFeaturesData(planType).then(
      (alerts) => {
        dispatch(featuresSuccess(alerts));
      },
      (error) => {
        dispatch(featuresErrors(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

export const subscriptionActions = {
  getSubscriptionPlanData,
  getFeaturesData,
};
