import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Chip,
    Tooltip,
    Box,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import PhoneMissedIcon from "@mui/icons-material/PhoneMissed";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import { CrmService } from "../../../services";
import { useSelector } from "react-redux";

const CallStatusPopup = ({ open, onClose, ongoingCalls, queuedCalls }) => {
    //const [callerList, setCallerList] = useState([]);
    const activeAICalls = useSelector((state) =>  state.callRoute.activeAICalls || []);
    const [callDuration, setCallDuration] = useState();

    useEffect(() => {
        const interval = setInterval(() => {
            setCallDuration((prevDuration) => {
                const updatedDuration = []
                Array.from(activeAICalls.entries())?.map(([value]) => {
                    updatedDuration[value?.callId] = Math.floor(Date.now() - value?.callStartTime) / 1000; 
                })
                return updatedDuration;
            });
            
        }, 1000);
            
        return () => clearInterval(interval); 
    },[activeAICalls])


    const statusMap = {
        Ongoing: {
            icon: <PhoneInTalkIcon color="primary" sx={{ mr: 1 }} />,
            color: "primary",
        },
        Incoming: {
            icon: <PhoneCallbackIcon color="secondary" sx={{ mr: 1 }} />,
            color: "secondary",
        },
        missed: {
            icon: <PhoneMissedIcon color="error" sx={{ mr: 1 }} />,
            color: "error",
        },
    };

    return (
        <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        sx={{
            "& .MuiDialog-paper": {
                borderRadius: "1rem",
                padding: 2,
            },
        }}
        >
        {/* Header */}
        <DialogTitle
        sx={{
            display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "bold",
        }}
        >
        <Typography variant="h6" fontWeight={600}>
        Call Status
        </Typography>
        <IconButton onClick={onClose}>
        <CloseIcon />
        </IconButton>
        </DialogTitle>

        <DialogContent>
        <TableContainer component={Paper} elevation={0} sx={{ height: 300 }}>
        <Table size="small" stickyHeader>
        <TableHead>
        <TableRow>
        {[
            "System User",
            "Ext No.",
            "Customer Name",
            "Phone",
            "Duration",
            "Status",
        ].map((h) => (
            <TableCell
            key={h}
            sx={{ fontWeight: "bold", padding: "6px" }}
            >
            {h}
            </TableCell>
        ))}
        </TableRow>
        </TableHead>
        <TableBody>
        {
            Array.from(activeAICalls.entries())?.map(([key, value]) => (
                    <TableRow key={key} hover>
                    <TableCell>
                    {value?.user}
                    </TableCell>
                
                    <TableCell>
                    {value?.extension}
                    </TableCell>
                
                    <TableCell>
                    {value?.customer}
                    </TableCell>

                    <TableCell>
                    {value?.callId}
                    </TableCell>

                    <TableCell>
                    {callDuration[value?.callId] || 0}
                    </TableCell>
                    </TableRow>
            )) 
        }
        </TableBody>
        </Table>
        </TableContainer>
        </DialogContent>
        </Dialog>
    );
};

export default CallStatusPopup;



        //{Array.isArray(callerList) && callerList?.map((call, index) => (
        //    <TableRow key={index} hover>
        //    <TableCell sx={{ padding: "6px" }}>
        //    <Tooltip title={call?.user_name}>
        //    <Box display="flex" alignItems="center">
        //    <PersonIcon sx={{ mr: 1, color: "gray" }} />
        //    {call?.user_name}
        //    </Box>
        //    </Tooltip>
        //    </TableCell>
        //
        //    <TableCell sx={{ padding: "6px" }}>
        //    <Chip
        //    label={call?.ext_no}
        //    size="small"
        //    variant="outlined"
        //    />
        //    </TableCell>
        //
        //    <TableCell sx={{ padding: "6px" }}>
        //    <Tooltip title={`Phone: ${call?.phone_number}`}>
        //    <Box display="flex" alignItems="center">
        //    <PhoneIcon sx={{ mr: 1, color: "green" }} />
        //    {call?.customer_name}
        //    </Box>
        //    </Tooltip>
        //    </TableCell>
        //
        //    <TableCell sx={{ padding: "6px" }}>{call?.phone_number}</TableCell>
        //    <TableCell sx={{ padding: "6px" }}>12 s</TableCell>
        //
        //    <TableCell sx={{ padding: "6px" }}>
        //    <Chip
        //    label={
        //        call?.call_status?.charAt(0)?.toUpperCase() +
        //        call?.call_status?.slice(1)
        //    }
        //    size="small"
        //    color={statusMap[call?.call_status]?.color}
        //    icon={statusMap[call?.call_status]?.icon}
        //    />
        //    </TableCell>
        //    </TableRow>
        //))}
        ///*}
