import cn from "classnames";
import React, { useEffect, useState, useRef } from "react";
import { BarChart } from "../Chart";
import { surveyActions } from "../../../../actions";
import MenuItems from "./MenuItems";
import { Line, Bar } from "react-chartjs-2";

import { ChartComponent } from "../Chart";
import MenuItems1 from "./MenuItems1";
import colorLib from "@kurkle/color";
import Facebook from "../../../../assets/images/Facebooks.png";
import Whatsapp from "../../../../assets/images/WhatsApp.png";
import Twitter from "../../../../assets/images/Twitter.png";
import Reddit from "../../../../assets/images/reddit.jpeg";
import { useSelector, useDispatch } from "react-redux";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import "./dropdown.css";
import "./customDropdown.scss";

const data = (labels, data) => {
  return {
    labels,
    datasets: [
      {
        label: "Uploaded Date",
        data,
        borderColor: CHART_COLORS.red,
        backgroundColor: transparentize(CHART_COLORS.red, 0.5),
        pointStyle: "circle",
        pointRadius: 10,
        pointHoverRadius: 15,
      },
    ],
  };
};

const transparentize = (value, opacity) => {
  var alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return colorLib(value).alpha(alpha).rgbString();
};

const CHART_COLORS = {
  red: "rgb(255, 99, 132)",
  orange: "rgb(255, 159, 64)",
  yellow: "rgb(255, 205, 86)",
  green: "rgb(75, 192, 192)",
  blue: "rgb(54, 162, 235)",
  purple: "rgb(153, 102, 255)",
  grey: "rgb(201, 203, 207)",
};
const SurveyEffectiveness = () => {
  const surveyEffectivenessChannel = useSelector(
    (state) => state.survey.surveyEffectivenessChannel
  );
  const surveyEffectivenessSocialGroup = useSelector(
    (state) => state.survey.surveyEffectivenessSocialGroup
  );
  const [cxLoading, setCxLoading] = useState({ cx1: false, cx2: false });

  const [chartData, setChartData] = useState([]);
  const [chartLabel, setChartLabel] = useState([]);

  const [menu, setMenu] = useState([]);

  const [menu1, setMenu1] = useState([]);
  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const getLabel = () => {
    if (surveyEffectivenessAbandonedSocialGroup === "all") {
      if (surveyEffectivenessAbandonedChannel === "email_link") {
        return "Email Link";
      } else if (surveyEffectivenessAbandonedChannel === "email_response") {
        return "Email Sharing";
      } else if (surveyEffectivenessAbandonedChannel === "socials_link") {
        return "Social Link";
      } else if (surveyEffectivenessAbandonedChannel === "link_sharing") {
        return "Link Sharing";
      }
    } else if (surveyEffectivenessAbandonedSocialGroup === "whatsapp") {
      return "WhatsApp";
    } else if (surveyEffectivenessAbandonedSocialGroup === "full_embed") {
      return "Full Embed";
    } else if (surveyEffectivenessAbandonedSocialGroup === "standard_embed") {
      return "Standard Embed";
    } else if (surveyEffectivenessAbandonedSocialGroup === "slider_embed") {
      return "Slider Embed";
    } else if (surveyEffectivenessAbandonedSocialGroup === "sliderTop_embed") {
      return "Slider Top Embed";
    } else {
      return capitalize(surveyEffectivenessAbandonedSocialGroup);
    }
  };
  const surveyEffectivenessData = useSelector(
    (state) => state.survey.surveyEffectivenessData
  );
  const surveyEffectivenessAbandonedData = useSelector(
    (state) => state.survey.surveyEffectivenessAbandonedData
  );
  const surveyEffectivenessAbandonedChannel = useSelector(
    (state) => state.survey.surveyEffectivenessAbandonedChannel
  );
  const surveyEffectivenessAbandonedSocialGroup = useSelector(
    (state) => state.survey.surveyEffectivenessAbandonedSocialGroup
  );
  const surveyEffectivenessAbandonedCountry = useSelector(
    (state) => state.survey.surveyEffectivenessAbandonedCountry
  );

  const surveyEffectivenessMonth = useSelector(
    (state) => state.survey.surveyEffectivenessMonth
  );
  const surveyEffectivenessYear = useSelector(
    (state) => state.survey.surveyEffectivenessYear
  );
  const dispatch = useDispatch();
  const surveyEffectivenessCountry = useSelector(
    (state) => state.survey.surveyEffectivenessCountry
  );

  const [barChartLabel, setBarChartLabel] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const gettitle = () => {
    if (surveyEffectivenessSocialGroup === "all") {
      if (surveyEffectivenessChannel === "email_link") {
        return "Email Link";
      } else if (surveyEffectivenessChannel === "email_response") {
        return "Email Sharing";
      } else if (surveyEffectivenessChannel === "socials_link") {
        return "Social Link";
      } else if (surveyEffectivenessChannel === "link_sharing") {
        return "Link Sharing";
      }
    } else {
      return "";
    }
  };
  const geticon = () => {
    if (surveyEffectivenessSocialGroup === "all") {
      return "";
    } else if (surveyEffectivenessSocialGroup === "whatsapp") {
      return "WhatsApp";
    } else if (surveyEffectivenessSocialGroup === "linkedin") {
      return "LinkedIn";
    } else {
      return capitalizeFirstLetter(surveyEffectivenessSocialGroup);
    }
  };
  const gettitle1 = () => {
    if (surveyEffectivenessAbandonedSocialGroup === "all") {
      if (surveyEffectivenessAbandonedChannel === "email_link") {
        return "Email Link";
      } else if (surveyEffectivenessAbandonedChannel === "email_response") {
        return "Email Sharing";
      } else if (surveyEffectivenessAbandonedChannel === "socials_link") {
        return "Social Link";
      } else if (surveyEffectivenessAbandonedChannel === "link_sharing") {
        return "Link Sharing";
      }
    } else {
      return "";
    }
  };
  const geticon1 = () => {
    if (surveyEffectivenessAbandonedSocialGroup === "all") {
      return "";
    } else if (surveyEffectivenessAbandonedSocialGroup === "whatsapp") {
      return "WhatsApp";
    } else if (surveyEffectivenessAbandonedSocialGroup === "linkedin") {
      return "LinkedIn";
    } else {
      return capitalizeFirstLetter(surveyEffectivenessAbandonedSocialGroup);
    }
  };
  useEffect(() => {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const gettitle = () => {
      if (surveyEffectivenessSocialGroup === "all") {
        if (surveyEffectivenessChannel === "email_link") {
          return "Email Link";
        } else if (surveyEffectivenessChannel === "email_response") {
          return "Email Sharing";
        } else if (surveyEffectivenessChannel === "socials_link") {
          return "Social Link";
        } else if (surveyEffectivenessChannel === "link_sharing") {
          return "Link Sharing";
        }
      } else {
        return "";
      }
    };
    const geticon = () => {
      if (surveyEffectivenessSocialGroup === "all") {
        return "";
      } else if (surveyEffectivenessSocialGroup === "whatsapp") {
        return "WhatsApp";
      } else if (surveyEffectivenessSocialGroup === "linkedin") {
        return "LinkedIn";
      } else {
        return capitalizeFirstLetter(surveyEffectivenessSocialGroup);
      }
    };

    const gettitle1 = () => {
      if (surveyEffectivenessAbandonedSocialGroup === "all") {
        if (surveyEffectivenessAbandonedChannel === "email_link") {
          return "Email Link";
        } else if (surveyEffectivenessAbandonedChannel === "email_response") {
          return "Email Sharing";
        } else if (surveyEffectivenessAbandonedChannel === "socials_link") {
          return "Social Link";
        } else if (surveyEffectivenessAbandonedChannel === "link_sharing") {
          return "Link Sharing";
        }
      } else {
        return "";
      }
    };
    const geticon1 = () => {
      if (surveyEffectivenessAbandonedSocialGroup === "all") {
        return "";
      } else if (surveyEffectivenessAbandonedSocialGroup === "whatsapp") {
        return "WhatsApp";
      } else if (surveyEffectivenessAbandonedSocialGroup === "linkedin") {
        return "LinkedIn";
      } else {
        return capitalizeFirstLetter(surveyEffectivenessAbandonedSocialGroup);
      }
    };
    const menuItems = [
      {
        title: surveyEffectivenessChannel === "all" ? "Channel" : gettitle(),
        icon: geticon(),
        value: "",
        submenu: [
          {
            title: "Email Link",
            value: "email_link",
            icon: "",
          },

          {
            title: "Social Link",
            value: "socials_link",
            icon: "",

            submenu: [
              {
                icon: "Facebook",
                value: "facebook",
                title: "",
              },
              {
                icon: "WhatsApp",
                value: "whatsapp",
                title: "",
              },
              {
                icon: "Twitter",
                value: "twitter",
                title: "",
              },
              {
                icon: "Reddit",
                value: "reddit",
                title: "",
              },
              {
                icon: "LinkedIn",
                value: "linkedin",
                title: "",
              },
            ],
          },
          {
            title: "Link Sharing",
            value: "link_sharing",
            icon: "",
          },
        ],
      },
    ];
    // const menuLinks = menuItems.map((menu, index) => {
    //   const depthLevel = 0;
    //   return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
    // });
    setMenu(menuItems);

    const menuItems1 = [
      {
        title:
          surveyEffectivenessAbandonedChannel === "all"
            ? "Channel"
            : gettitle1(),
        icon: geticon1(),
        value: "",
        submenu: [
          {
            title: "Email Link",
            value: "email_link",
            icon: "",
          },

          {
            title: "Social Link",
            value: "socials_link",
            icon: "",

            submenu: [
              {
                icon: "Facebook",
                value: "facebook",
                title: "",
              },
              {
                icon: "WhatsApp",
                value: "whatsapp",
                title: "",
              },
              {
                icon: "Twitter",
                value: "twitter",
                title: "",
              },
              {
                icon: "Reddit",
                value: "reddit",
                title: "",
              },
              {
                icon: "LinkedIn",
                value: "linkedin",
                title: "",
              },
            ],
          },
          {
            title: "Link Sharing",
            value: "link_sharing",
            icon: "",
          },
        ],
      },
    ];
    const menuLinks1 = menuItems1.map((menu, index) => {
      const depthLevel = 0;
      return <MenuItems1 items={menu} key={index} depthLevel={depthLevel} />;
    });
    setMenu1(menuLinks1);
  }, [
    surveyEffectivenessChannel,
    surveyEffectivenessSocialGroup,
    surveyEffectivenessAbandonedChannel,
    surveyEffectivenessAbandonedSocialGroup,
  ]);

  const [country, setcountry] = useState([]);
  const [abandonedCountry, setAbandonedCountry] = useState([]);

  useEffect(() => {
    let countryData = [];
    let label = [];
    let data = [];
    surveyEffectivenessAbandonedData &&
      surveyEffectivenessAbandonedData["answers_count"] &&
      Object.entries(surveyEffectivenessAbandonedData["answers_count"]).forEach(
        ([key, value]) => {
          countryData.push({
            label: key + " " + value,
            value: key,
          });
        }
      );
    surveyEffectivenessAbandonedData &&
      surveyEffectivenessAbandonedData.question_graph &&
      surveyEffectivenessAbandonedData.question_graph.length > 0 &&
      surveyEffectivenessAbandonedData.question_graph.map((x) => {
        label.push(x.question_text);
        data.push(x.per);
      });
    setBarChartLabel(label);
    setBarChartData(data);
    setAbandonedCountry(countryData);
  }, [surveyEffectivenessAbandonedData]);
  const formatDate = (date) => {
    let arr_date = date.split("-");

    let months = [
      "",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[parseInt(arr_date[1])] + " " + arr_date[2];
  };
  useEffect(() => {
    let infoArray = [];
    let labelArray = [];
    let countryData = [];
    surveyEffectivenessData &&
      surveyEffectivenessData["answers_count"] &&
      Object.entries(surveyEffectivenessData["answers_count"]).forEach(
        ([key, value]) => {
          countryData.push({
            label: key + " " + value,
            value: key,
          });
        }
      );
    setcountry(countryData);
    if (surveyEffectivenessMonth !== "all") {
      surveyEffectivenessData &&
        surveyEffectivenessData.dates_graph &&
        Object.entries(surveyEffectivenessData.dates_graph).forEach(
          ([key, value]) => {
            labelArray.push(formatDate(key));
            infoArray.push(value);
          }
        );
    } else {
      surveyEffectivenessData &&
        surveyEffectivenessData.dates_graph &&
        Object.entries(surveyEffectivenessData.dates_graph).forEach(
          ([key, value]) => {
            labelArray.push(key);
            infoArray.push(value);
          }
        );
    }
    setChartData(infoArray);
    setChartLabel(labelArray);
  }, [
    surveyEffectivenessData,
    surveyEffectivenessYear,
    surveyEffectivenessMonth,
  ]);
  const getDropList = () => {
    const year = new Date().getFullYear();
    return Array.from(new Array(50), (v, i) => (
      <option key={i} value={year + i}>
        {year + i}
      </option>
    ));
  };
  const FormatData = (data) => {
    switch (data) {
      case "socials_link":
        return "Social Link";
      case "link_sharing":
        return "Link Sharing";
      case "email_response":
        return "Email Response";
      case "email_link":
        return "Email Link";
      default:
        return "";
    }
  };

  const typeField = useRef();
  const typeField1 = useRef();

  const subDropdown = useRef();
  const subDropdown1 = useRef();

  //   window.onclick = function(event) {
  //     if (!event.target.matches('.dropdown__btn')) {

  //       var dropdowns = document.getElementsByClassName("dropdown__list");
  //       var i;
  //       for (i = 0; i < dropdowns.length; i++) {
  //         var openDropdown = dropdowns[i];
  //         if (openDropdown.classList.contains('list--active')) {
  //           openDropdown.classList.remove('list--active');
  //         }
  //       }
  //     }
  //   }

  return (
    <div className="surveyEffectiveness">
      <div className={cn("analyseCard")}>
        <div className="analyse-filter-box">
          <div className="title"> User seen but not responsed </div>
          <div className="analyse-filter-wrapper">
            <div className="select-items">
              <div className="select-dropdown">
                <div className="dropdown__menu--upload">
                  <button
                    className="dropdown__btn"
                    onClick={() => {
                      typeField.current.classList.toggle("list--active");
                      subDropdown.current.classList.remove("active");
                    }}
                  >
                    {geticon() === "Facebook" ? (
                      <img
                        className="ml-5"
                        style={{ width: "25px", height: "25px" }}
                        src={Facebook}
                      ></img>
                    ) : geticon() === "Reddit" ? (
                      <img
                        className="ml-5"
                        style={{ width: "25px", height: "25px" }}
                        src={Reddit}
                      ></img>
                    ) : geticon() === "LinkedIn" ? (
                      <img
                        className="ml-5"
                        style={{ width: "25px", height: "25px" }}
                        src={
                          window.location.origin + "/images/SVG/linkedin.svg"
                        }
                      ></img>
                    ) : geticon() === "WhatsApp" ? (
                      <img
                        className="ml-5"
                        style={{ width: "25px", height: "25px" }}
                        src={Whatsapp}
                      ></img>
                    ) : geticon() === "Twitter" ? (
                      <img
                        className="ml-5"
                        style={{ width: "25px", height: "25px" }}
                        src={Twitter}
                      ></img>
                    ) : null}
                    {geticon() === "Full_embed" ? (
                      <span>Full Embed</span>
                    ) : null}
                    {geticon() === "Standard_embed" ? (
                      <span>Standard Embed</span>
                    ) : null}
                    {geticon() === "Slider_embed" ? (
                      <span>Slider Embed</span>
                    ) : null}
                    {geticon() === "SliderTop_embed" ? (
                      <span>Slider Top Embed</span>
                    ) : null}
                    {surveyEffectivenessChannel === "all"
                      ? "Channel"
                      : gettitle()}
                    <KeyboardArrowDownRoundedIcon />
                  </button>

                  <ul className="dropdown__list" ref={typeField}>
                    <li
                      onClick={() => {
                        dispatch(
                          surveyActions.setSurveyEffectivenessSocialGroupFilter(
                            "all"
                          )
                        );
                        dispatch(
                          surveyActions.setSurveyEffectivenessChannelFilter(
                            "email_link"
                          )
                        );
                        typeField.current.classList.remove("list--active");
                      }}
                      className="dropdown__list--item"
                    >
                      <div className="title">Email Link</div>
                      <span className="delete-icon"></span>
                    </li>
                    <li
                      className="dropdown__list--item"
                      onClick={() => {
                        subDropdown.current.classList.toggle("active");
                      }}
                    >
                      <div className="title">Social Link</div>
                      <span className="delete-icon">
                        <KeyboardArrowRightRoundedIcon />
                      </span>

                      <div className="subDropdown" ref={subDropdown}>
                        <div className="innerPopup">
                          <div className="items">
                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setSurveyEffectivenessChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setSurveyEffectivenessSocialGroupFilter(
                                    "facebook"
                                  )
                                );

                                typeField.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              {" "}
                              <img
                                className="ml-5"
                                style={{ width: "25px", height: "25px" }}
                                src={Facebook}
                              ></img>
                            </div>
                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setSurveyEffectivenessChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setSurveyEffectivenessSocialGroupFilter(
                                    "whatsapp"
                                  )
                                );

                                typeField.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <img
                                className="ml-5"
                                style={{ width: "25px", height: "25px" }}
                                src={Whatsapp}
                              ></img>
                            </div>
                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setSurveyEffectivenessChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setSurveyEffectivenessSocialGroupFilter(
                                    "twitter"
                                  )
                                );

                                typeField.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <img
                                className="ml-5"
                                style={{ width: "25px", height: "25px" }}
                                src={Twitter}
                              ></img>
                            </div>
                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setSurveyEffectivenessChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setSurveyEffectivenessSocialGroupFilter(
                                    "reddit"
                                  )
                                );

                                typeField.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <img
                                className="ml-5"
                                style={{ width: "25px", height: "25px" }}
                                src={Reddit}
                              ></img>
                            </div>
                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setSurveyEffectivenessChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setSurveyEffectivenessSocialGroupFilter(
                                    "linkedin"
                                  )
                                );

                                typeField.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <img
                                className="ml-5"
                                style={{ width: "25px", height: "25px" }}
                                src={
                                  window.location.origin +
                                  "/images/SVG/linkedin.svg"
                                }
                              ></img>
                            </div>

                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setSurveyEffectivenessChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setSurveyEffectivenessSocialGroupFilter(
                                    "full_embed"
                                  )
                                );

                                typeField.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <span style={{ width: "25px", height: "25px" }}>
                                Full Embed
                              </span>
                            </div>

                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setSurveyEffectivenessChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setSurveyEffectivenessSocialGroupFilter(
                                    "standard_embed"
                                  )
                                );

                                typeField.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <span style={{ width: "25px", height: "25px" }}>
                                Standard Embed
                              </span>
                            </div>

                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setSurveyEffectivenessChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setSurveyEffectivenessSocialGroupFilter(
                                    "slider_embed"
                                  )
                                );

                                typeField.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <span style={{ width: "25px", height: "25px" }}>
                                Slider Embed
                              </span>
                            </div>

                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setSurveyEffectivenessChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setSurveyEffectivenessSocialGroupFilter(
                                    "sliderTop_embed"
                                  )
                                );

                                typeField.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <span style={{ width: "25px", height: "25px" }}>
                                Slider Top Embed
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li
                      onClick={() => {
                        dispatch(
                          surveyActions.setSurveyEffectivenessSocialGroupFilter(
                            "all"
                          )
                        );
                        dispatch(
                          surveyActions.setSurveyEffectivenessChannelFilter(
                            "link_sharing"
                          )
                        );
                        typeField.current.classList.remove("list--active");
                      }}
                      className="dropdown__list--item"
                    >
                      <div className="title">Link Sharing</div>
                      <span className="delete-icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="item">
                <select
                  onChange={(e) => {
                    dispatch(
                      surveyActions.setSurveyEffectivenessCountryFilter(
                        e.target.value
                      )
                    );
                  }}
                  value={surveyEffectivenessCountry}
                >
                  <option value={"all"}>All</option>;
                  {country && country.length > 0
                    ? country.map((value, index) => {
                        return (
                          <option value={value.value}>{value.label}</option>
                        );
                      })
                    : null}
                </select>
              </div>
            </div>

            <div className="select-items">
              <div className="item">
                <select
                  value={surveyEffectivenessYear}
                  onChange={(e) => {
                    // setshowloader(true);
                    setCxLoading({ cx2: true });
                    setTimeout(() => {
                      setCxLoading({ cx2: false });
                    }, 2000);
                    dispatch(
                      surveyActions.setSurveyEffectivenessSurveyMonth("all")
                    );

                    dispatch(
                      surveyActions.setSurveyEffectivenessSurveyYear(
                        e.target.value
                      )
                    );
                  }}
                >
                  {getDropList()}
                </select>
              </div>
              <div className="item">
                <select
                  value={surveyEffectivenessMonth}
                  onChange={(e) => {
                    // setshowloader(true);
                    setCxLoading({ cx2: true });
                    setTimeout(() => {
                      setCxLoading({ cx2: false });
                    }, 2000);
                    dispatch(
                      surveyActions.setSurveyEffectivenessSurveyMonth(
                        e.target.value
                      )
                    );
                  }}
                >
                  <option value="all">Select</option>

                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className={cn("", "analyseChart2")}>
          <div style={{ height: "300px" }}>
            {cxLoading.cx2 ? (
              <p style={{ textAlign: "center", marginTop: 20 }}>Loading...</p>
            ) : (
              <Line
                data={data(chartLabel, chartData)}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,

                  scales: {
                    y: {
                      ticks: {
                        stepSize: 1,
                      },

                      min: 0,
                    },
                  },
                  plugins: {
                    legend: {
                      position: "top",
                      align: "middle",
                      padding: {
                        top: 4,
                        bottom: -20,
                      },
                    },
                  },
                }}
              />
            )}{" "}
          </div>
        </div>
      </div>
      <div className={cn("analyseCard")}>
        <div className="analyse-filter-box">
          <div className="title">Abandoned User</div>
          <div className="analyse-filter-wrapper">
            <div className="select-items">
              <div className="select-dropdown">
                <div className="dropdown__menu--upload">
                  <button
                    className="dropdown__btn"
                    onClick={() => {
                      typeField1.current.classList.toggle("list--active");
                      subDropdown1.current.classList.remove("active");
                    }}
                  >
                    {geticon1() === "Facebook" ? (
                      <img
                        className="ml-5"
                        style={{ width: "25px", height: "25px" }}
                        src={Facebook}
                      ></img>
                    ) : geticon1() === "Reddit" ? (
                      <img
                        className="ml-5"
                        style={{ width: "25px", height: "25px" }}
                        src={Reddit}
                      ></img>
                    ) : geticon1() === "LinkedIn" ? (
                      <img
                        className="ml-5"
                        style={{ width: "25px", height: "25px" }}
                        src={
                          window.location.origin + "/images/SVG/linkedin.svg"
                        }
                      ></img>
                    ) : geticon1() === "WhatsApp" ? (
                      <img
                        className="ml-5"
                        style={{ width: "25px", height: "25px" }}
                        src={Whatsapp}
                      ></img>
                    ) : geticon1() === "Twitter" ? (
                      <img
                        className="ml-5"
                        style={{ width: "25px", height: "25px" }}
                        src={Twitter}
                      ></img>
                    ) : null}

                    {geticon1() === "Full_embed" ? (
                      <span>Full Embed</span>
                    ) : null}
                    {geticon1() === "Standard_embed" ? (
                      <span>Standard Embed</span>
                    ) : null}
                    {geticon1() === "Slider_embed" ? (
                      <span>Slider Embed</span>
                    ) : null}
                    {geticon1() === "SliderTop_embed" ? (
                      <span>Slider Top Embed</span>
                    ) : null}
                    {surveyEffectivenessAbandonedChannel === "all"
                      ? "Channel"
                      : gettitle1()}
                    <KeyboardArrowDownRoundedIcon />
                  </button>

                  <ul className="dropdown__list" ref={typeField1}>
                    <li
                      onClick={() => {
                        dispatch(
                          surveyActions.setSurveyEffectivenessAbandonedSocialGroupFilter(
                            "all"
                          )
                        );
                        dispatch(
                          surveyActions.setSurveyEffectivenessAbandonedChannelFilter(
                            "email_link"
                          )
                        );
                        typeField1.current.classList.remove("list--active");
                      }}
                      className="dropdown__list--item"
                    >
                      <div className="title">Email Link</div>
                      <span className="delete-icon"></span>
                    </li>
                    <li
                      className="dropdown__list--item"
                      onClick={() => {
                        subDropdown1.current.classList.toggle("active");
                      }}
                    >
                      <div className="title">Social Link</div>
                      <span className="delete-icon">
                        <KeyboardArrowRightRoundedIcon />
                      </span>

                      <div className="subDropdown" ref={subDropdown1}>
                        <div className="innerPopup">
                          <div className="items">
                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setSurveyEffectivenessAbandonedChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setSurveyEffectivenessAbandonedSocialGroupFilter(
                                    "facebook"
                                  )
                                );
                                typeField1.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              {" "}
                              <img
                                className="ml-5"
                                style={{ width: "25px", height: "25px" }}
                                src={Facebook}
                              ></img>
                            </div>
                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setSurveyEffectivenessAbandonedChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setSurveyEffectivenessAbandonedSocialGroupFilter(
                                    "whatsapp"
                                  )
                                );
                                typeField1.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <img
                                className="ml-5"
                                style={{ width: "25px", height: "25px" }}
                                src={Whatsapp}
                              ></img>
                            </div>
                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setSurveyEffectivenessAbandonedChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setSurveyEffectivenessAbandonedSocialGroupFilter(
                                    "twitter"
                                  )
                                );
                                typeField1.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <img
                                className="ml-5"
                                style={{ width: "25px", height: "25px" }}
                                src={Twitter}
                              ></img>
                            </div>
                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setSurveyEffectivenessAbandonedChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setSurveyEffectivenessAbandonedSocialGroupFilter(
                                    "reddit"
                                  )
                                );
                                typeField1.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <img
                                className="ml-5"
                                style={{ width: "25px", height: "25px" }}
                                src={Reddit}
                              ></img>
                            </div>
                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setSurveyEffectivenessAbandonedChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setSurveyEffectivenessAbandonedSocialGroupFilter(
                                    "linkedin"
                                  )
                                );
                                typeField1.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <img
                                className="ml-5"
                                style={{ width: "25px", height: "25px" }}
                                src={
                                  window.location.origin +
                                  "/images/SVG/linkedin.svg"
                                }
                              ></img>
                            </div>

                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setSurveyEffectivenessAbandonedChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setSurveyEffectivenessAbandonedSocialGroupFilter(
                                    "full_embed"
                                  )
                                );
                                typeField1.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <span style={{ width: "25px", height: "25px" }}>
                                Full Embed
                              </span>
                            </div>

                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setSurveyEffectivenessAbandonedChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setSurveyEffectivenessAbandonedSocialGroupFilter(
                                    "standard_embed"
                                  )
                                );
                                typeField1.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <span style={{ width: "25px", height: "25px" }}>
                                Standard Embed
                              </span>
                            </div>

                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setSurveyEffectivenessAbandonedChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setSurveyEffectivenessAbandonedSocialGroupFilter(
                                    "slider_embed"
                                  )
                                );
                                typeField1.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <span style={{ width: "25px", height: "25px" }}>
                                Slider Embed
                              </span>
                            </div>

                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setSurveyEffectivenessAbandonedChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setSurveyEffectivenessAbandonedSocialGroupFilter(
                                    "sliderTop_embed"
                                  )
                                );
                                typeField1.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <span style={{ width: "25px", height: "25px" }}>
                                Slider Top Embed
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li
                      onClick={() => {
                        dispatch(
                          surveyActions.setSurveyEffectivenessAbandonedSocialGroupFilter(
                            "all"
                          )
                        );
                        dispatch(
                          surveyActions.setSurveyEffectivenessAbandonedChannelFilter(
                            "link_sharing"
                          )
                        );
                        typeField1.current.classList.remove("list--active");
                      }}
                      className="dropdown__list--item"
                    >
                      <div className="title">Link Sharing</div>
                      <span className="delete-icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="item">
                <select
                  onChange={(e) => {
                    dispatch(
                      surveyActions.setSurveyEffectivenessAbandonedCountryFilter(
                        e.target.value
                      )
                    );
                  }}
                  value={surveyEffectivenessAbandonedCountry}
                >
                  <option value={"all"}>All</option>;
                  {abandonedCountry && abandonedCountry.length > 0
                    ? abandonedCountry.map((value, index) => {
                        return (
                          <option value={value.value}>{value.label}</option>
                        );
                      })
                    : null}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div style={{ height: "300px" }}>
          <Bar
            options={{
              tooltips: {
                enabled: true,
                mode: "label",
                callbacks: {
                  title: function (tooltipItems, data) {
                    var idx = tooltipItems[0].index;
                    return "Title:" + data.labels[idx]; //do something with title
                  },
                  label: function (tooltipItems, data) {
                    return tooltipItems.xLabel + " €";
                  },
                },
              },

              scales: {
                x: {
                  ticks: {
                    callback: function (value, index) {
                      return "Q " + parseInt(index + 1); //truncate
                    },
                  },
                },
              },
              responsive: true,
              maintainAspectRatio: false,
            }}
            data={{
              labels: barChartLabel,

              datasets: [
                {
                  label: `Number of Response By ${getLabel(
                    surveyEffectivenessAbandonedChannel
                  )}
                `,
                  data: barChartData,
                  backgroundColor: transparentize(CHART_COLORS.red, 0.5),
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SurveyEffectiveness;
