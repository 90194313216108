import React, {useState, useRef, useEffect} from 'react';
import {Header, MainMenu} from '../Common/Header';
import Backdrop from '../Common/Backdrop';
import {TemplateList} from "./ListView";
import {TemplateGrid} from "./GridView";
import {getUser, clone, getLocalStorage} from '../Common/Utils';
import Dialogbox from "../Common/DialogBox";
import Input from "../../common/controls/inputs/Input";
import { useDispatch, useSelector } from 'react-redux';
// import {workspaceActions, dashboardActions } from '../../actions';
import {workspaceService} from '../../services';
import { useOutsideAlerter } from "../Common/Header";
import {Dropdown, RenameDialog} from './Component';
import {MaterialSelect } from "../../common/controls/inputs/Material";
import {history} from "../../helpers";
import GridViewIcon from "@mui/icons-material/GridView";
import ListIcon from "@mui/icons-material/List";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from '@mui/icons-material/Search';
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import styled from "styled-components";
import WidgetsRoundedIcon from '@mui/icons-material/WidgetsRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { create } from 'lodash';

const Theme = styled.div`
    margin:0;
     .experience {
        padding: 0 12px;
        margin-bottom: 10px;
         div {
            margin:0;
            min-width: 100%;
         }
         .MuiInput-root {
            margin:0 !important
         }
         .MuiInput-underline {
            border: 1px solid #18A0FB;
            border-radius: 3px;
            padding: 5px 15px 0px;
            .MuiSelect-select{
                background-color: transparent;
            }
            :before {
                border-bottom: none;
            }
         }
     }
`;

const TemplateGalleryMenu = ({themes={}, menuItems, categories, onSelectTheme, selectedTheme, cat, onSelectCat, onHandleView, panelActive, backdrop}) =>{
    const dropDownConfig= {name: "theme", size:"small", value:selectedTheme, menuItems, fieldClass:'experience',onChange:onSelectTheme};
    const inputRef = useRef();
    const [categoryList, setCategoryList] = useState(categories);
    useEffect(()=>{
        setCategoryList(categories);
    }, [categories])
    const Categories = () => {
        return Object.keys(categoryList).map(k => {
            const obj = categoryList[k];
            return <li key={k}><a className={ k === cat.name ? "active" : ""} onClick={()=>{onSelectCat(k,obj)}}>{k}{obj.most_popular ? <em className="tag">Most Popular</em>: null}</a></li>
        })
    };
    const onKeyPress = (e) => {
        if(e.key === 'Enter'){
            searching(e);
        }
    };
    const searching = (e) => {
        e && e.stopPropagation();
        let obj = clone(categories);
        const keyword = inputRef?.current?.value||"";
        if(keyword !== ""){
            obj={};
            const catArr = Object.keys(categories).filter(c => c?.toLowerCase()?.includes(keyword.toLowerCase()));
            catArr.forEach(c => {
                obj[c] = categories[c]
            });
        };
        setCategoryList(obj);
    };

    return(
        <div className="templateGalleryMenu" ref={panelActive}>
            <div className="menuClose">
                <button onClick={()=>{panelActive.current.classList.remove('panel-active');backdrop.current.classList.remove('active');}}><CloseRoundedIcon/></button>
            </div>
            <div className="headSection">
                <h1>Template Gallery</h1>
                <HelpOutlineIcon/>
            </div>
            <div className="searchWrap">
                <input ref={inputRef} type="text" placeholder={"Search Template"} onKeyPress={onKeyPress} />
                <div className="searchIcon" onClick={searching}>
                    <SearchIcon/>
                </div>
            </div>
            <div className="listing">
                <h2>Choose Theme</h2>
                <Theme>
                    <MaterialSelect config={dropDownConfig}/>
                </Theme>
                <h2>All Categories</h2>
                {/* <ul> <Categories/> </ul> */}
                
                {
                    Object.keys(categoryList) && Object.keys(categoryList).length > 0 ? (<ul><Categories /></ul>) : 
                    (<div className='emptyResult'>No Data Found!</div>)
                }
                
            </div>
        </div>
    )
};
const CreateContactDailog = ({onCloseHandle, handleOk, templateDialog, onHandleView}) =>{
    const [title, setTitle] = useState(templateDialog.title);
    return(
        <Dialogbox open={true} handleClose={onCloseHandle} handleOk={handleOk} dialogContentClass={'createContactInner'} rootClass="uploadDialog">
            <div className="templateDialogInner">
                <div className="row">
                    <div className="col-md-12">
                        <Input config={{ label: 'Give it a name', className: '', labelClass: 'label', name: 'username', placeholder: 'Enter' }} value={title} onChange={(e)=>{setTitle(e.target.value)}}/>
                    </div>
                    <div className="col-md-12">
                        <Input config={{ label: 'What are you creating?', className: '', labelClass: 'label', name: 'username', placeholder: 'Enter' }} isDisabled={true} value={templateDialog.category} onChange={()=>{}} />
                    </div>
                </div>
                <div className="btnGroup">
                    <button className="btn btnDefault" onClick={onCloseHandle}>Cancel</button>
                    <button className="btn" onClick={()=>handleOk(title)}>Continue</button>
                </div>
            </div>
        </Dialogbox>
    )
};

const Template = (props) => {
    const [templateDialog, setTemplateDialog] = useState({dailog:false, title: "", category:""});
    const [listView, setListView] = useState(false);
    const [ws_name, setWsName] = useState("temp");
    const [themes, setThemes] = useState({});
    const {loading, items} = useSelector(state => state.themes);
    const [menuItems, setMenuItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedTheme, setTheme] = useState(menuItems[0]||"");
    const [cat, setCat] = useState({});
    const [catName, setCatName] = useState('');
    const [templates, setTemplates] = useState([]);
    const dispatch = useDispatch();
    const tts = useSelector(state => state.templateToSurvey);
    useEffect(()=> {
        // dispatch(dashboardActions.getAllThemes({with_templates: true}));
    }, []);

    useEffect(()=> {
        if(!tts.loading && tts.success){
            history.push("/");
        }
    }, [tts]);

    useEffect(()=> {
        if(!loading && items && items.data){
            setThemes(items.data);
        }
    }, [loading]);

    useEffect(()=>{
        const list = Object.keys(themes)||[];
        if(list.length ){
            const name = list[0];
            const catList = Object.keys(themes[name]);
            catList.length && themes[name][catList[0]].templates && setTemplates(themes[name][catList[0]].templates);
            catList.length && setCat({...themes[name][catList[0]], name:catList[0]});
            setTheme(name);
            setCategories(themes[name]);
        }
        setMenuItems(list);
    }, [themes]);

    const onSelectTheme = (e) => {
        if(e && e.target && e.target.value){
            const value = e.target.value;
            const catList = Object.keys(themes[value]);
            catList.length && themes[value][catList[0]].templates && setTemplates(themes[value][catList[0]].templates);
            catList.length && setCat({...themes[value][catList[0]], name:catList[0]});
            setTheme(value);
            setCategories(themes[value]);
        }
    };

    const onSelectCat = (name, value) =>{
        setCat({...value, name});
        setCatName(name);
        value.templates && setTemplates(value.templates)
    };

    const onShowListView = () =>{
        setListView(true);
    };
    const onShowGridView = () =>{
        setListView(false);
    };
    const onCloseHandle = () =>{
        setTemplateDialog({dailog:false, title: "", category:""});
    };
    const createSurvey = (title) =>{
        const {pos=-1} = templateDialog;
        setTemplateDialog({dailog:false, title: "", category:""});
        if(pos > -1){
            const {default_WS_id} = getUser();
            const wsId = getLocalStorage('workspaceID');
            const param = {
                "template_id": templates[pos].id,
                "workspace_id": wsId || default_WS_id,
                "title": title
            };
            // dispatch(workspaceActions.createSurveyFromTemplate(param));
        }
    };

    const dashboardPanel = useRef();
    const backdropOverlay = useRef();

    return(
        <>
            {/* <Header/> */}
            {/* <MainMenu dashboardMenu={true}/> */}
            <div className="mainPageBody">
                <div className="backdrop-overlay" ref={backdropOverlay} onClick={()=>{dashboardPanel.current.classList.remove('panel-active');backdropOverlay.current.classList.remove('active')}}></div>

                <div className="dashboardPanel">
                    <button className="panel-btn" onClick={()=>{dashboardPanel.current.classList.add('panel-active');backdropOverlay.current.classList.add('active')}}><WidgetsRoundedIcon/></button>
                </div>

                <TemplateGalleryMenu themes={themes} menuItems={menuItems} onSelectTheme={onSelectTheme} categories={categories} selectedTheme={selectedTheme} cat={cat} onSelectCat={onSelectCat} panelActive={dashboardPanel} backdrop={backdropOverlay}/>
                <div className="mainPageBodyScroller">
                    {
                        !listView ?<TemplateGrid {...props} templates={templates} category={cat} setTemplateDialog={setTemplateDialog}/>:
                            <TemplateList {...props} templates={templates}/>
                    }
                </div>
                <Backdrop show={loading || tts.loading}/>
                {templateDialog.dailog && <CreateContactDailog onCloseHandle={onCloseHandle} onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              createSurvey()
                            }
                          }} handleOk={createSurvey} templateDialog={templateDialog}/>}
            </div>
        </>
    )
};

export default Template;