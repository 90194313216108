import React, { useEffect, useState } from "react";
import { MaterialSelect } from "../../../../common/controls/inputs/Material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import "./PageLogic.scss";
import { pageLogicActions } from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";

import FirstIcon from "../../../../assets/images/first.svg";
import FormControl from "@mui/material/FormControl";
import { Select } from "antd";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useParams } from "react-router";
import { swalAlert } from "../../../../voc/Common/SwalAlert";

const { Option } = Select;

const NextQuestion = (props) => {
  const dispatch = useDispatch();
  const [defaultValue, setDefaultValue] = useState("");
  const { data, links, random } = props;
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const { id } = useParams();
  const [nextQuestInd, setNextQuestInd] = useState(null);

  useEffect(() => {
    setDisplayDropdown(false);
    console.log("calleduseeffect", links, data);
    for (let i = 0; i < links.length; i++) {
      if (links[i].data.index === data.index) {
        setNextQuestInd(links[i].data.nextInd);

        setDefaultValue(data.questDesc[links[i].data.nextInd].quest);
      }
    }
    console.log("calleduseeffect2", defaultValue);

    setDisplayDropdown(true);
  }, [data.ans, data.index, data.qType, random]);
  function handleQuestChange(value) {
    let newIdx = data.questDesc.findIndex((x) => x.quest === value);
    setNextQuestInd(newIdx);
    setDefaultValue(value);
  }

  function binarySearchInCondFlow(arr, lower, upper, obj) {
    if (upper >= lower) {
      var mid = lower + (upper - lower) / 2;
      if ((upper - lower) % 2 === 1) {
        mid -= 0.5;
      }
      //console.log(mid);
      // If the element is present at the middle
      // itself
      //console.log(data.condFlow);
      //console.log(data.condFlow[0]);

      // There will only be 1 string in this object's option array
      if (
        arr[mid]["index"] === obj["index"] &&
        arr[mid]["option"][0] === obj["option"][0]
      )
        return mid;

      //console.log(data.condFlow);
      //console.log(data.condFlow[0]);
      // If element is smaller than mid, then
      // it can only be present in left subarray
      if (
        arr[mid]["index"] > obj["index"] ||
        arr[mid]["nextInd"] > obj["nextInd"]
      )
        return binarySearchInCondFlow(arr, lower, mid - 1, obj);

      // Else the element can only be present
      // in right subarray
      return binarySearchInCondFlow(arr, mid + 1, upper, obj);
    }
    //console.log(lower);
    return lower;
  }

  const handleOk = () => {
    if (nextQuestInd !== null) {
      //console.log("OK SELECTED");

      const obj = {
        index: data.index,
        option: ["Next Question"],
        nextInd: nextQuestInd,
      };
      const newCondFlow = data.condFlow.slice();
      // .filter((x) => x.index !== data.index)
      // .filter((x) => x.nextInd !== nextQuestInd);
      var arr = newCondFlow;
      console.log("djdsjsjd", arr, obj);
      // for (let i = 0; i < arr.length; i++) {
      //   if (
      //     arr[i].index === obj.index &&
      //     arr[i].option[0] === "Next question"
      //   ) {
      //     console.log("hret");
      //     arr.splice(i, 0);
      //     break;
      //   }
      // }
      arr = arr.filter(
        (item) =>
          !(item.index === obj.index && item.option[0] === "Next question")
      );

      console.log("test1", arr, obj);

      arr.push(obj);
      //console.log("finalarr--->", arr);
      var newLinks = [];

      arr.forEach((e) => {
        if (e.option[0] === "Next Question") {
          newLinks.push({
            input: `${data.questDesc[e.index].quest}`,
            output: `${data.questDesc[e.nextInd].quest}`,

            data: {
              optArr: ["Next question"],
              index: e.index,
              nextInd: e.nextInd,
            },
          });
        } else {
          newLinks.push({
            input: `${data.questDesc[e.index].quest}`,
            output: `${data.questDesc[e.nextInd].quest}`,

            data: {
              optArr: e.option,
              index: e.index,
              nextInd: e.nextInd,
            },
          });
        }
      });
      data.setCondFlow(arr);

      //console.log("finallinks--->", links);

      dispatch(
        pageLogicActions.updatePageLogicData({
          detail_id: id,
          links: newLinks,
        })
      );
      setTimeout(() => {
        dispatch(pageLogicActions.getPageLogicData(id));
      }, [1000]);

      data.setNextQuestionData(null);

      data.setActiveQuestionType("");
    } else {
      swalAlert({
        icon: "error",
        title: "Please Select Next Question".toString(),
        showConfirmButton: true,
      });
    }
  };

  function renderQuest() {
    return data.questDesc.slice(data.index + 1).map((elem, index) => {
      return (
        <Option
          selected={elem.selected === elem.quest}
          key={index}
          value={elem.quest}
        >
          {elem.quest}
        </Option>
      );
    });
  }

  return (
    <div style={{ background: "#717EC3", borderRadius: "10px" }}>
      <div className="pageLogic-wrapper">
        <div className="title-wrapper">Page Logic</div>
        <div className="content-wrapper">
          <div className="dropdown-title">
            <h2>
              Edit Logic
              <img src={FirstIcon} alt="" />
            </h2>
            <button
              onClick={() => {
                data.setNextQuestionData(null);

                data.setActiveQuestionType("");
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4099 12.0002L17.7099 7.71019C17.8982 7.52188 18.004 7.26649 18.004 7.00019C18.004 6.73388 17.8982 6.47849 17.7099 6.29019C17.5216 6.10188 17.2662 5.99609 16.9999 5.99609C16.7336 5.99609 16.4782 6.10188 16.2899 6.29019L11.9999 10.5902L7.70994 6.29019C7.52164 6.10188 7.26624 5.99609 6.99994 5.99609C6.73364 5.99609 6.47824 6.10188 6.28994 6.29019C6.10164 6.47849 5.99585 6.73388 5.99585 7.00019C5.99585 7.26649 6.10164 7.52188 6.28994 7.71019L10.5899 12.0002L6.28994 16.2902C6.19621 16.3831 6.12182 16.4937 6.07105 16.6156C6.02028 16.7375 5.99414 16.8682 5.99414 17.0002C5.99414 17.1322 6.02028 17.2629 6.07105 17.3848C6.12182 17.5066 6.19621 17.6172 6.28994 17.7102C6.3829 17.8039 6.4935 17.8783 6.61536 17.9291C6.73722 17.9798 6.86793 18.006 6.99994 18.006C7.13195 18.006 7.26266 17.9798 7.38452 17.9291C7.50638 17.8783 7.61698 17.8039 7.70994 17.7102L11.9999 13.4102L16.2899 17.7102C16.3829 17.8039 16.4935 17.8783 16.6154 17.9291C16.7372 17.9798 16.8679 18.006 16.9999 18.006C17.132 18.006 17.2627 17.9798 17.3845 17.9291C17.5064 17.8783 17.617 17.8039 17.7099 17.7102C17.8037 17.6172 17.8781 17.5066 17.9288 17.3848C17.9796 17.2629 18.0057 17.1322 18.0057 17.0002C18.0057 16.8682 17.9796 16.7375 17.9288 16.6156C17.8781 16.4937 17.8037 16.3831 17.7099 16.2902L13.4099 12.0002Z"
                  fill="#8A94A6"
                />
              </svg>
            </button>
          </div>
          {console.log("sddsjsdkksd", defaultValue)}
          <div className="dropdown-wrapper">
            <FormControl fullWidth sx={{ m: 1, maxHeight: 32 }} size="small">
              {displayDropdown && (
                <Select value={defaultValue} onChange={handleQuestChange}>
                  {renderQuest()}
                </Select>
              )}
            </FormControl>
          </div>

          <div className="btn-wrap">
            <button onClick={handleOk} className="blue-btn">
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NextQuestion;
