import '../surveyStyle.css';
import Layouts from './QuestionLayout/Layouts';

const Question = (props) => {
    const {settings = {} } = props.value || {};
    return <div>
        { props?.experienceType?.length ? <div className='experienceTypeContainer'>
        <div className='experienceTypeLabel'>{props.experienceType}</div>
      </div> : null}
      <div className={`questionWrapper layout${settings.layout? settings.layout:""}`}>
        {props.isSaving && <div style={{position:'absolute', right:10, top:5, letterSpacing: 2, color: '#8A94A6'}}>saving...</div>}
        <Layouts {...props}/>
      </div>
    </div>
        
        
};
export default Question;
