import { callRouteConstant } from "../constants/callRouteConstant";

const initialState = {
    activeCalls: new Map(), // Tracks ongoing calls
    callQueue: [], // Tracks queued calls
    callerList: [],
    isAiResponse: true
};

export function callRoute(state = initialState, action) {
    switch (action.type) {
    
        case callRouteConstant.SET_AI_ACTIVE_CALL:
            return {...state, activeAICalls: action.payload}

        case callRouteConstant.SET_AI_AGENT:
            return {...state, aiAgent: action.payload}

        case callRouteConstant.SET_AI_RESPONSE:
            return {...state, isAiResponse: action.payload}

        case callRouteConstant.ADD_ACTIVE_CALL:
            const {callId, callStartTime} = action.payload;
            if (!state.activeCalls.has(
                callId
            )) {
                state.activeCalls.set(callId, {
                    callId,
                    callStartTime
                });
            }

            return { ...state, activeCalls: state.activeCalls };


        case callRouteConstant.REMOVE_ACTIVE_CALL:
            state.activeCalls.delete(callId);
            return { ...state, activeCalls: action.payload}

        case callRouteConstant.ADD_TO_QUEUE:
            return { ...state, callQueue: [...state.callQueue, action.payload] };

        case callRouteConstant.REMOVE_FROM_QUEUE:
            return { ...state, callQueue: state.callQueue.slice(1) };

            // dashboard api calls
        case callRouteConstant.GET_SYSTEM_USERS:
            return { ...state, systemUsers: action.payload };

        case callRouteConstant.GET_SYSTEM_USERS_ERROR:
            return { ...state, systemUsersError: action.payload };

        case callRouteConstant.ADD_CALLER_LIST:
            return { ...state, callerList: [...state.callerList, action.payload] };

        default:
            return state;
    }
}
