import { useState, useRef, useEffect } from "react";

import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { $generateHtmlFromNodes } from "@lexical/html";

import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import ToolbarPlugin from "./plugins/CollapsibleToolbar";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { CollapsibleContainerNode } from "./plugins/CollapsiblePlugin/CollapsibleContainerNode";
import { CollapsibleContentNode } from "./plugins/CollapsiblePlugin/CollapsibleContentNode";
import { CollapsibleTitleNode } from "./plugins/CollapsiblePlugin/CollapsibleTitleNode";
import ActionsPlugin from "./plugins/ActionsPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import CollapsiblePlugin from "./plugins/CollapsiblePlugin/index";
import Backdrop from "../../Common/Backdrop";
import { useSelector, useDispatch } from "react-redux";
import { TableContext } from "./plugins/TablePlugin";
import TableCellActionMenuPlugin from "./plugins/TableActionMenuPlugin";
import TableCellResizer from "./plugins/TableCellResizer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { CLEAR_HISTORY_COMMAND } from "lexical";
import { surveyActions } from "../../../actions";

function Placeholder() {
  return (
    <div className="editor-placeholder">
      Play around with the Markdown plugin...
    </div>
  );
}

export default function Editor({ setHtml, setJson, json, random }) {
  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);
  const Trigger = useSelector((state) => state.survey.trigger);
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [showloader, setshowloader] = useState(false);
  const editorStateRef = useRef();
  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  useEffect(() => {
    // if (Trigger === "help") {
    console.log("calleduseefect");

    const timer = setTimeout(() => {
      ref.current.dispatchEvent(
        new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1,
        })
      );
      // setshowloader(false);
      dispatch(surveyActions.setTriggerType(null));
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
    // }
  }, [random]);

  const desiredUpdate = {
    editorState: {
      root: {
        children: [
          {
            children: [
              {
                detail: 0,
                format: 0,
                mode: "normal",
                style: "",
                text: "",
                type: "text",
                version: 1,
              },
            ],
            direction: "ltr",
            format: "",
            indent: 0,
            type: "paragraph",
            version: 1,
          },
        ],
        direction: "ltr",
        format: "",
        indent: 0,
        type: "root",
        version: 1,
      },
    },
    lastSaved: 1656765599382,
    source: "Playground",
    version: "0.3.6",
  };
  const UpdatePlugin = () => {
    const [editor] = useLexicalComposerContext();

    const onButtonClick = () => {
      console.log("called");
      const editorState = editor.parseEditorState(
        JSON.stringify(
          Trigger === "help"
            ? desiredUpdate.editorState
            : json && json !== ""
            ? json
            : undefined
        )
      );
      editor.setEditorState(editorState);
      if (Trigger === "help")
        editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined);
    };

    return (
      <button ref={ref} onClick={onButtonClick}>
        Update editor state
      </button>
    );
  };
  const editorConfig = {
    editorState: json ? JSON.stringify(json) : undefined,
    theme: ExampleTheme,
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
      CollapsibleContainerNode,
      CollapsibleContentNode,
      CollapsibleTitleNode,
      HorizontalRuleNode,
    ],
  };
  return (
    <>
      <Backdrop show={showloader} />
      <LexicalComposer initialConfig={editorConfig}>
        <TableContext>
          {/* {console.log("skdkksdksd", editorConfig)}*/}
          <div className="editor-container" style={{ maxWidth: "100%" }}>
            <ToolbarPlugin />
            <TablePlugin />
            <TableCellResizer />
            <div
              className="editor-inner"
              style={{ border: "2px solid #e7e7e7", padding: "0px 20px 0px" }}
            >
              <RichTextPlugin
                contentEditable={
                  // <ContentEditable className="editor-input" ref={onRef} />
                  <div className="editor-input">
                    <div className="editor" ref={onRef}>
                      <ContentEditable className={"editor-input"} />
                    </div>
                  </div>
                }
                placeholder={<Placeholder />}
              />
              <OnChangePlugin
                onChange={(editorState, editor) => {
                  editorStateRef.current = editorState;
                  // const html = $generateHtmlFromNodes(editorState, null);
                  // console.log("sdjsdjsd", html);

                  editor.update(() => {
                    const rawHTML = $generateHtmlFromNodes(editor, null);
                    // const editorStateTextString = editorState.read(() =>
                    //   $getRoot().getTextContent()
                    // );
                    // console.log("dskkdsk", rawHTML);
                    setHtml(rawHTML);
                    setJson(editorState.toJSON());
                    // setTemplateVariables(editorStateTextString);
                  });
                }}
              />
              <div style={{ display: "none" }}>
                <UpdatePlugin />
              </div>

              {/* <button
              onClick={() => {
                console.log("Sjsdjds", editorConfig, editorStateRef);
                const editorState = editorStateRef.current;
                // const htmlString = $generateHtmlFromNodes(
                //   editorStateRef.current,
                //   null
                // );

                const json = editorState.toJSON();

                console.log("skksdksd", JSON.stringify(json));
              }}
            >
              Hello
            </button> */}
              <HorizontalRulePlugin />

              <CollapsiblePlugin />
              <AutoFocusPlugin />
              <ListPlugin />
              <TablePlugin />
              <LinkPlugin />
              <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
              <CodeHighlightPlugin />
            </div>
            <ActionsPlugin />
          </div>
          {floatingAnchorElem && (
            <>
              <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} />
            </>
          )}
        </TableContext>
      </LexicalComposer>
    </>
  );
}
