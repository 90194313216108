import api from "./api";
import axios from "axios";
import { getLocalAccessToken } from "../helpers";
import devInstance from "./devapi";
import { getUser } from "../voc/Common/Utils";
import { jwtDecode } from "jwt-decode";


async function getAllCustomers() {
  const result = await api.get(`/voc_crm/customers/`);
  return result;
}
async function getCallAllCustomers(queryParams = "") {
  const result = await api.get(`/voc_crm/customers/${queryParams}`);
  return result;
}

async function getAllScheduledCalls(customerId) {
  const result = await api.get(
    `/voc_crm/customers/${customerId}/scheduled-calls/`
  );
  return result;
}

async function getCallsLogsByCustomerId(customerId) {
  const result = await api.get(
    `/voc_crm/call/logs/?per_page=100&current_page=1${
      customerId !== undefined ? `&customer_ids=${customerId}` : ""
    }`
  );
  return result;
}

async function getAllCallLogsWithFilter(filter) {
  const result = await api.get(`/voc_crm/call/logs/${filter}`);
  return result; 
}


// async function getDashboardData(filter) {

//   const user = JSON.parse(localStorage.getItem("user"));
//   const token = user?.access_token;

//   const decodedToken = jwtDecode(token);
//   const role = decodedToken.role
  
//   let query = `/voc_crm/call/dashboard${filter}`;

//   if (role === "Admin") {
//     if (!filter || filter === "?") {
//       query = `/voc_crm/call/dashboard?user_id=${user.user_id}`;
//     } else {
//       query += `&user_id=${user.user_id}`;
//     }
//   }
//   const result = await api.get(query);
//   return result;
// }

async function getDashboardData(filter) {
  const result = await api.get(`/voc_crm/call/dashboard${filter}`);
  return result;
}

async function addCustomer(customerInfo) {
  const result = await api.post(`/voc_crm/customers/`, customerInfo);
  return result;
}

async function updateCustomerDataFormAPI(customerId, updateCustomerPayload) {
  const result = await api.put(
    `/voc_crm/customers/${customerId}`,
    updateCustomerPayload
  );
  return result;
}

async function getCustomerDataById(customerId) {
  const result = await api.get(`/voc_crm/customers/${customerId}`);
  return result;
}

async function getCustomerDataByNo(no) {
  const result = await api.get(`/voc_crm/customers/?phone_number=${no}`);
  return result;
}

async function deleteCustomer(customerId) {
  const result = await api.delete(`/voc_crm/customers/${customerId}`);
  return result;
}

// API calls for CSV unique data from CSV
async function uploadCustomers(csvData, userID) {
  // const result = await api.post(`/voc_crm/upload_customers?user_id=${userID}`, csvData);
  const result = await api.post(`/voc_crm/customers/upload`, csvData);
  return result;
}

async function uploadDuplicateData(duplicateCSVData, customerId) {
  const result = await api.post(
    `/voc_crm/customers/${customerId}`,
    duplicateCSVData
  );
  return result;
}

//API Call for notes sections
async function getAllNotes(id) {
  const result = await api.get(`/voc_crm/customers/${id}/notes`);
  return result;
}

// async function addNotes(addNotesData, Id) {
//   const result = await api.post(`/voc_crm/customers/${Id}/notes`, addNotesData);
//   return result;
// }

async function editNotes(customerId, notesId, editNotesPayload) {
  const result = await api.put(
    `/voc_crm/customers/${customerId}/notes/${notesId}`,
    editNotesPayload
  );
  return result;
}

async function deleteNotes(customerId, notesId) {
  const result = await api.delete(
    `/voc_crm/customers/${customerId}/notes/${notesId}`
  );
  return result;
}

//below API for sending email
async function getEmailDetails(surveyId) {
  const result = await api.get(`/getAudience?survey_id=${surveyId}`);
  return result;
}

// made change
// const sendEmail = async (params, customerId) => {
//   const result = await api.post(`/voc_crm/customers/send-email`, params);
//   return result;
// };
const sendEmail = async (params, customerId) => {
  const user = getUser();
  const endpoint =
  user?.social_type === "outlook"
    ? "/voc_crm/customers/send-outlook-email"
    : "/voc_crm/customers/send-email";
  const result = await api.post(endpoint, params);
  return result;
};

async function getSummaryResponse(customerId) {
  const result = await api.get(
    `/voc_crm/summary_response?customer_id=${customerId}`
  );
  return result;
}

async function addNotes(addNotesData, Id) {
  const result = await api.post(`/voc_crm/customers/${Id}/notes`, addNotesData);
  return result;
}

const createCallLogs = async (payload) => {
  const result = await api.post(`/voc_crm/call/logs/`, payload);
  return result;
};

const updateCallLogs = async (payload, Id) => {
  const result = await api.put(`/voc_crm/call/logs/${Id}`, payload);
  return result;
};

const uploadAudio = async (payload) => {
  const result = await api.post(`/uploadAudioFile`, payload);
  return result;
};

const transcribeAudio = async (url) => {
  const result = await api.post(`/transcribe`, url);
  return result;
};

async function getEmailsHistory(customerId) {
  const result = await api.get(
    `/voc_crm/email_logs?per_page=5&current_page=1&customer_id=${customerId}`
  );
  return result;
}

async function getAllEmailsHistory(filter) {
  const result = await api.get(`/voc_crm/email_logs${filter}`);
  return result;
}

async function getFeedbackResponses(customerId) {
  const result = await api.get(
    `/voc_crm/customers/eventsbyemail/${customerId}`
  );
  return result;
}

async function addBulkCustomers(customerInfo) {
  const result = await api.post(`/voc_crm/bulk/customers`, customerInfo);
  return result;
}

async function sendSMTPMail(params) {
  const result = await api.post(`/voc_crm/customers/send-smtp-email`, params);
  return result;
}

async function getCallSummary(callId) {
  const result = await api.get(`/voc_crm/call/${callId}/get_summary`);
  return result;
}


async function getSystemUsers() {
  const result = await api.get(`/api/dashboard/system_users`);
  return result;
}

async function getQueueCustomers() {
  const result = await api.get(`/api/dashboard/customer_queue`);
  return result;
}

export const CrmService = {
  getAllCustomers,
  getAllScheduledCalls,
  uploadCustomers,
  getAllNotes,
  addCustomer,
  addNotes,
  uploadDuplicateData,
  getCustomerDataById,
  getCustomerDataByNo,
  updateCustomerDataFormAPI,
  getEmailDetails,
  editNotes,
  deleteCustomer,
  deleteNotes,
  sendEmail,
  getCallsLogsByCustomerId,
  getSummaryResponse,
  createCallLogs,
  updateCallLogs,
  uploadAudio,
  transcribeAudio,
  getEmailsHistory,
  getFeedbackResponses,
  addBulkCustomers,
  getDashboardData,
  sendSMTPMail,
  getAllCallLogsWithFilter,
  getAllEmailsHistory,
  getCallSummary,
  getSystemUsers,
  getQueueCustomers,
  getCallAllCustomers
};
