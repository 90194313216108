import React, { useEffect, useState } from "react";
import { Router, Route, Switch, Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactGA from "react-ga4"; // Analytics library
import { history } from "./helpers"; // Custom history object
import { alertActions } from "./actions"; // Redux actions
import Login from "./voc/login/Login";
import SignUp from "./voc/Signup/Signup";
import TeamRegister from "./voc/teamRegister/TeamRegister";
import ForgetPassword from "./voc/forgetPassword/ForgetPassword";
import TokenVerify from "./voc/forgetPassword/TokenVerify";
import Profile from "./voc/Signup/Profile";
import Confirmation from "./voc/Signup/ConfirmPage";
import AddCustomer from "./voc/CRM/CustomerTable/AddCustomer";
import ViewNotes from "./voc/CRM/CustomerTable/ViewNotes";
import MuiTable from "./voc/Table/CommonCustomerTable";
import VoiceAgentsTable from "./voc/Table/VoiceAgentsTable";
import VoiceRulesTable from "./voc/Table/VoiceRulesTable";
import UserSetting from "./voc/UserSettings/UserSetting";
import CustomerTable from "./voc/CRM/CustomerTable/CustomerTable";
import ScheduledCallsTable from "./voc/CRM/CustomerTable/ScheduledCallsTable";
import EditCustomerDetails from "./voc/CRM/CustomerTable/EditCustomerDetails";
import MoreCustomerDetails from "./voc/CRM/CustomerTable/MoreCustomerDetails";
import VideoCall from "./voc/CRM/CustomerTable/VideoCall";
import IncomingCallModal from "./components/Dialer/IncommingCallModal";
import VocCalendar from "./voc/CRM/Calendar/Calendar";
import CallCenterDashboard from "./components/CallCenter/CallDashboard";
import IntegrationForm from "./voc/CRM/Integration/IntegrationForm";
import StepForm from "./voc/CRM/Integration/StepForm";
import { MainMenu, Header } from "./voc/Common/Header"; // Your MainMenu component
import RoleTable from "./voc/CRM/Roles/RoleTable";
import UsersAndTeams from "./voc/CRM/users/UsersAndTeams";
import TeamTable from "./voc/CRM/Team/TeamTable";
import ManagerTable from "./voc/CRM/Manager/managerTable";
import TerritoryTable from "./voc/CRM/Territory/TerritoryTable";
import DelegationTable from "./voc/CRM/Delegation/DelegationTable";
import { TagsTable } from "./components/Tables/TagsTable";
import CallTagsTable from "./voc/CRM/Tags/CallTagsTable";
import Subscription from "./voc/Survey/Subscription";
import QuestionsAnswers from "./voc/Signup/QuestionsAnswers";
import Template from "./voc/Dashboards/Template";
import ScratchForm from "./voc/Form/ScratchForm";
import Scratch from "./voc/Survey/Scratch";
import Survey from "./voc/Survey/index";
import Form from "./voc/Form/index";
import SurveyAnswer from "./voc/Survey/SurveyAnswer";
import FormSubmit from "./voc/Form/FormSubmit";
import SIPDashboard from "./components/Admin/SIPDashboard";
import MainDashboard from "./voc/Dashboards/MainDashboard";
import UploadHistoricalSurvey from "./voc/UploadSurvey/HistoricalSurvey"
import CallActivity from "./voc/CRM/Callsummary/CallActivity";
import EscalationTable from "./voc/CRM/Escalation/EscalationTable";
import AllCallLogs from "./components/CallCenter/CallLogs";
import {useMediaQuery} from "@mui/material";

const loading = () => null;

function App() {
    const alert = useSelector((state) => state.alert);
    const dispatch = useDispatch();
    const location = useLocation();
    const [role, setRole] = useState();
    const [roleName, setRoleName] = useState();
    const user = JSON.parse(localStorage.getItem("user"));
  const searchParams = new URLSearchParams(window.location.search);
  const inviteType = searchParams.get("invitetype");

  // useEffect(() => {
  //   const hasRefreshed = sessionStorage.getItem("hasRefreshed");

  //   if (!hasRefreshed) {
  //     sessionStorage.setItem("hasRefreshed", "true");
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 10);
  //   }
  // }, []);

      // Define routes that should not display the MainMenu
      const noSidebarRoutes = [
        "/login",
        "/signup",
        "/TeamRegister",
        "/forgetPassword",
        "/resetpassword",
        "/confirmation",
        "/profile",
        "/questions",
    ];

    const currentPath = location.pathname;
    if (!user && !noSidebarRoutes.includes(currentPath)) {
      window.location.href = "/login"
    }
 

    useEffect(() => { 
        const hasRefreshed = sessionStorage.getItem("hasRefreshed");
        const inviteRefreshed = sessionStorage.getItem("inviteRefreshed");
      
        if (inviteType && (inviteType === "gmail" || inviteType === "outlook")) {
          if (!hasRefreshed || !inviteRefreshed) {
            sessionStorage.setItem("hasRefreshed", "true");
            sessionStorage.setItem("inviteRefreshed", "true");
            setTimeout(() => {
              window.location.reload();
            }, 10);
          }
        }
      }, []);


    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        const token = user?.access_token;
        if(user?.role_name){
            setRoleName(user?.role_name)
          }
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setRole(decodedToken.role);
            } catch (error) {
                console.error("Invalid token:", error);
            }
        }

        // if (user && (role === null || role === undefined)) {
        //     history.push("/login");
        // }

    }, []);


    useEffect(() => {
        // Clear alert on location change
        history.listen((location) => {
            dispatch(alertActions.clear());
        });

        const TRACKING_ID = "G-XCGDN5X1FM";
        ReactGA.initialize(TRACKING_ID);
        ReactGA.send({ hitType: "pageview", page: "/" });
    }, [dispatch]);



    const isNoSidebarRoute =
        !(
            user?.social_type === "outlook" &&
            noSidebarRoutes.includes(location.pathname)
        ) &&
        noSidebarRoutes.includes(location.pathname) &&
        !user?.isProfileCreated;
    console.log("location.pathname", location.pathname);

    const isMobile = useMediaQuery("(max-width:600px)");

    return (
        <Router history={history}>
        {role !== "Admin" && 
            <IncomingCallModal />
        } 
        <div
        style={{ height: "100vh", display: "flex", flexDirection: "column" }}
        >
        {!isNoSidebarRoute && (
            <Header style={{ position: "sticky", top: 0, zIndex: 1000 }} />
        )}

        <div style={{ display: "flex", flex: 1, overflow: "auto" }}>
        {!isNoSidebarRoute && (
            <div style={{ width: "5%", transition: "width 0.3s ease" }}>
            <MainMenu />
            </div>
        )}

        <div
        style={{
            flexGrow: 1,
                marginLeft: isNoSidebarRoute ? "0" : "0",
                transition: "margin 0.3s ease",
                width: isMobile ? "100%" : "auto" 
        }}
        >
        <React.Suspense fallback={<div>Loading...</div>}>
        <Switch>
        <Route path="/login" component={Login} />
        <Route path="/signup" component={SignUp} />
        <Route path="/TeamRegister" component={TeamRegister} />
        <Route path="/forgetPassword" component={ForgetPassword} />
        <Route path="/resetpassword" component={TokenVerify} />
        <Route path="/confirmation" component={Confirmation} />
        <Route path="/profile" component={Profile} />
        <Route path="/callLogs" component={AllCallLogs} />
        <Route
        exact
        path="/"
        component={
            roleName == "Admin" ? SIPDashboard : CallCenterDashboard
        }
        />
        <Route path="/user-setting" component={UserSetting} />
        <Route path="/customerTable" component={CustomerTable} />
        <Route path="/add-customer" component={AddCustomer} />
        <Route
        path="/scheduled-calls"
        component={ScheduledCallsTable}
        />
        <Route path="/viewEdit/:id" component={EditCustomerDetails} />
        <Route path="/muiTable" component={MuiTable} />
        <Route path="/viewNotes/:id" component={ViewNotes} />
        <Route path="/viewAgents" component={VoiceAgentsTable} />
        <Route path="/viewRules" component={VoiceRulesTable} />
        <Route path="/integration" component={IntegrationForm} />
        <Route path="/ExternalCustomer" component={StepForm} />
        <Route path="/calendar" component={VocCalendar} />
        <Route
        path="/moreCustomerDetails/:id"
        component={MoreCustomerDetails}
        />
        <Route path="/videocall/:roomId" component={VideoCall} />
        {/* <Route path="/test" component={SIPDashboard} /> */}
        <Route path="/roles" component={RoleTable} />
                <Route path="/userDashboard" component={CallCenterDashboard} />
        <Route path="/users" component={UsersAndTeams} />
        <Route path="/team" component={TeamTable} />
        <Route path="/manager" component={ManagerTable} />
        <Route path="/territory" component={TerritoryTable} />
        <Route path="/delegation" component={DelegationTable} />
        <Route path="/escalation" component={EscalationTable} />
        <Route path="/tags" component={CallTagsTable} />
        <Route path="/subscription" component={Subscription} />
        <Route path="/questions" component={QuestionsAnswers} />
                <Route exact path="/dashboard" component={MainDashboard} />
                <Route exact path="/CallActivity" component={CallActivity} />
                <Route
                  exact
                  path="/upload/historical"
                  component = {UploadHistoricalSurvey}
                />
        <Route path="/template" component={Template} />
        <Route exact path="/scratch" component={Scratch} />
        <Route exact path="/scratchForm" component={ScratchForm} />
        <Route path="/scratch/survey/:id" component={Survey} />
        <Route path="/scratch/form/:id" component={Form} />
        <Route
        path="/survey_ans/:id/:personal"
        component={SurveyAnswer}
        />
        <Route path="/survey_ans/:id" component={SurveyAnswer} />
        <Route path="/form_ans/:id/:personal" component={FormSubmit} />
        <Route path="/form_ans/:id" component={FormSubmit} />

        <Redirect from="*" to="/" />
        </Switch>
        </React.Suspense>
        </div>
        </div>
        </div>
        </Router>
    );
}

export default App;
