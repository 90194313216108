import React from 'react';
import PageLogic from '../../Survey/DesignSurvey/PageLogic';
import AgentsList from './AgentList';
import Diagram, { useSchema, createSchema } from "beautiful-react-diagrams";
import "beautiful-react-diagrams/styles.css";
import DynamicWorkflow from './DynamicWorkflow';



export default function CallActivity() {
  const [schema, { onChange }] = useSchema({});
  
  return (
    <div style={{ padding: '20px' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Call Activity</h2>
      <div style={{ display: 'flex', gap: '20px' }}>
        {/* Left Section - Agents List */}
        <div style={{ flex: '0.3', background: '#f4f4f4', padding: '15px', borderRadius: '8px' }}>
          <AgentsList />
        </div>
        
        {/* Right Section - Page Logic */}
        <div style={{ flex: '0.7', background: '#fff', padding: '15px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
        <h1 style={{ textAlign: "center" }}>Customer Service Workflow</h1>
        <DynamicWorkflow agentType="flight-booking"/>
        </div>
      </div>
    </div>
  );
}

