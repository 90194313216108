import React, { useState, useEffect, useRef } from 'react';
import {clone, shuffle} from '../../../Common/Utils';
import CheckCircle from '@mui/icons-material/CheckCircle';

const origin = window.location.origin;
const checkStyle = {
    position: 'absolute',
    top: -9,
    right: -6,
    background: '#fff',
    borderRadius: '50%',
    zIndex:'1',
};

const PictureChoice = (props) =>{
    const {layout,children, qus_id, question, desc="", if_pictures_type=[], qno=1, settings={}, cardRef, quesType, onChange, qRefs, submit, btnText='Next', sliderView, onFocus, showSubmitBtn} = props;
    const { font, underline, bold, fontSize, italic, fontColor,  multiple_selection, require, randomize, supersize } = settings,
        style = { fontFamily: font, fontSize, fontWeight: bold ? 'bold' : '', fontStyle: italic ? 'italic' : '', textDecoration: underline ? 'underline' : '', color: fontColor };

    const [picOptions, setPicOptions] = useState(if_pictures_type);
    const [selectedPic, setSelection] = useState([]);
    const [shuffled, setShuffling] = React.useState(false);
    const [err, setErr] = useState('');

    useEffect(()=>{
        if(randomize && shuffled === false && if_pictures_type && if_pictures_type.length){
           setPicOptions(shuffle(if_pictures_type));
            setShuffling(true);
        }
    }, [shuffled]);

    useEffect(()=> {
        const elements = document.getElementsByName("question_"+qno);
        let ele = elements.length ? elements[0] : elements;
        ele.style.setProperty('font-family', font||'Poppins', 'important');
    }, [font]);

    const onNext = () => {
        if(btnText === 'Next'){
            cardRef && cardRef.current.next();
            (qRefs?.current?.length >=qno) && setTimeout(() => { qRefs.current[qno]?.focus() }, 10);
        }else{
            submit && submit();
        }
    };

    
    const triggerFocus = () => {
        onFocus && qno > 0 && onFocus(qno-1);
    };

    const onSelect = (c, res) => {
        triggerFocus();
        const cloned = clone(selectedPic);
        if(multiple_selection){
            const idx = selectedPic.indexOf(c);
            (idx > -1) ? cloned.splice(idx,1) : cloned.push(c);
        } else {
            if(cloned.length === 0){
                cloned.push(c);
            } else {
                cloned[0] = c;
            }
        }
        setSelection(cloned);
        saveChanges(cloned);
    };

    const saveChanges = (selections) => {
        if(require && selections.length === 0){
            setErr('This is required.');
            return;
        }
        if(selections.length){
            const answers = selections.map((p) => {
                return {
                    id: picOptions[p].id || p,
                    text: picOptions[p].label,
                    base64: picOptions[p].base64,
                }
            });
            let obj = {
                "qus_id": qus_id,
                "q_type":quesType,
                "qus_text": question,
                answers
            };
            onChange && onChange({name: qno, value:obj});
        }
        setErr('');
    };

    const Pictures = () => {
        return picOptions.map((p, i)=>{
            const sno = String.fromCharCode(65+i);
            return <div key={"pic-"+i} className={`imageUpload uploaded ${supersize ? 'superSize':''}`} onClick={()=>onSelect(i)}>
                {selectedPic.indexOf(i) > -1 ? <div style={checkStyle}><CheckCircle/></div> : null}
                <div className="inner">
                    <img src={p.base64} alt="arrow icon" />
                </div>
                <div style={{display: 'flex', alignItems:"center"}}>
                    <div>{sno}</div>
                    {settings.show_label && <input type="text" value={p.label} />}
                </div>
            </div>
        });
    };

    return(
        <div className="imageWrapInner">
            <div className="ques">
                <h3 name={"question_"+qno} style={style}>{question}
                    <span style={{color:"red", marginLeft:'5px',display: require?'unset':'none'}}>*</span>
                </h3>
                <p> {desc} </p>
            </div>

            {layout == 1 && children}
            <div className="imageUploadWrap imageUploadItems" style={settings.horizontal ? {}: {display:'block'}} ref={ (r)=> qRefs && (qRefs.current[qno-1] = r) } tabIndex={-1} >
                <Pictures />
            </div>
            { err !== "" ? <div> <p style={{color:'red'}}>{err}</p></div> : <div><p>&nbsp;</p></div> }

            { sliderView ? (btnText=== 'Submit' && !showSubmitBtn) ? null : <div className="btnGroup btnPadding">
                <button className="btnSave" onClick={onNext}>{btnText}</button>
            </div> : null}
        </div>
    )
}
export default PictureChoice;
