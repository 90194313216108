import React, { useState, useEffect, useMemo } from "react";
import Dialogbox from "../Common/DialogBox";
import { base64Image, containsAnyLetters, clone } from "../Common/Utils";
import { useDispatch, useSelector } from "react-redux";
import { workspaceActions } from "../../actions/index";

import Grid from "@mui/material/Grid";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import TextField from "@mui/material/TextField";
import { pink } from "@mui/material/colors";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import Prompt from "../Common/ConfirmationPrompt";

const origin = window.location.origin;
const imageSize = 1000000;
const AddLogo = (props) => {
  const { surveyData = {}, setSurveyData, setSubmitted } = props;
  const { loading, res } = useSelector((state) => state.wsDetails);
  const [show, setDialog] = useState(false),
    [dialogValue, setDialogValue] = useState(surveyData.page_logo || ""),
    [img, setImg] = useState(dialogValue),
    [err, setErr] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setDialogValue(surveyData.page_logo || "");
    setImg(surveyData.page_logo || "");
  }, [surveyData]);

  useEffect(() => {
    if (!loading && res && !res.hasOwnProperty("quest_desc")) {
      setDialogValue("");
      setImg("");
    }
  }, [loading, res]);

  const onChangeHandle = (e) => {
      if (e?.target?.files?.length) {
        const file = e.target.files[0];
        if (
          file.type.includes("jpeg") ||
          file.type.includes("png") ||
          file.type.includes("jpg")
        ) {
          if (file.size <= imageSize) {
            setErr(false);
            base64Image(file, (result) => {
              setImg(result);
            });
          } else {
            setErr("Logo size should be less than or equal to 1MB");
          }
        } else {
          setErr("Uploaded file should be jpg or png");
        }
      }
    },
    handleClose = () => {
      setImg(dialogValue);
      setDialog(false);
    },
    onHandleOpen = () => {
      setDialog(!show);
    },
    onHandleSave = (e, isImageDelete) => {
      e?.preventDefault();
      const imgData = isImageDelete ? "" : img;
      if (isImageDelete || (imgData && imgData.length)) {
        setSubmitted && setSubmitted(true);
        setSurveyData &&
          setSurveyData((surveyData) => ({
            ...surveyData,
            page_logo: imgData,
          }));
        dispatch(
          workspaceActions.createQuestDesc({
            ...surveyData,
            page_logo: imgData,
          })
        );
        handleClose();
      }
    };

  const CancelLogo = () => {
    const [showPromt, setPrompt] = useState(false);
    const onClick = (e) => {
      e?.preventDefault();
      dialogValue?.length ? setPrompt(true) : setImg("");
    };
    return (
      <>
        <Tooltip title="Delete Logo">
          <div
            style={{
              background: "black",
              color: "#fff",
              borderRadius: "100%",
              width: 17,
              height: 17,
              position: "absolute",
              right: 3,
              top: 3,
              lineHeight: 1.1,
              fontSize: 15,
              zIndex: 10,
              cursor: "pointer",
              display: img?.length ? "block" : "none",
            }}
            onClick={onClick}
          >
            {" "}
            x{" "}
          </div>
        </Tooltip>
        <Prompt
          show={showPromt}
          message="Are you sure to delete the logo?"
          handler={(e) => {
            e && onHandleSave(null, true);
            setPrompt(false);
          }}
        />
      </>
    );
  };

  return (
    <>
      <Tooltip title="Add Logo">
        <div
          className={`addLogo ${!show && dialogValue && "logoAdded"}`}
          onClick={onHandleOpen}
        >
          {!show && dialogValue ? (
            <img src={dialogValue} alt="defaultLogo" />
          ) : (
            <>
              Logo <ControlPointIcon />
            </>
          )}
        </div>
      </Tooltip>
      <Dialogbox
        open={show}
        rootClass={"addLogoDialog"}
        handleClose={handleClose}
        dialogContentClass={"addLogoDialogInner"}
      >
        <div
          className="closeDailogNew"
          onClick={handleClose}
          style={{ textAlign: "end", cursor: "pointer" }}
        >
          <HighlightOffTwoToneIcon />
        </div>
        <div className="form">
          <div className="fileUploadInner">
            <div className="imgWrapper fileUpload">
              <input
                type="file"
                onChange={onChangeHandle}
                accept={"image/x-png, image/jpeg"}
                onClick={(e) => (e.currentTarget.value = null)}
              />
              <img
                src={img.length ? img : origin + "/images/SVG/upload.svg"}
                alt="upload icon"
              />
              <CancelLogo />
            </div>
            <h4>Click or drag upload your logo</h4>
            {err.length ? (
              <p style={{ color: "red" }}>{err}</p>
            ) : (
              <p>Size 1MB jpg png </p>
            )}
          </div>
          <div className="btn--wrap">
            {img.length ? (
              <button
                onClick={handleClose}
                className="btn btnCancel"
                style={{
                  border: "1px solid #18a0fb",
                  background: "#fff",
                  color: "#000",
                  marginRight: 10,
                }}
              >
                Cancel
              </button>
            ) : null}
            {img.length ? (
              <button className="btnSave" onClick={onHandleSave}>
                Save
              </button>
            ) : null}
          </div>
        </div>
      </Dialogbox>
    </>
  );
};
const AddTitle = (props) => {
  const { surveyData = {}, pageInfo = {}, setPageInfo, setSubmitted } = props;
  const { loading, res } = useSelector((state) => state.wsDetails);
  const [show, setDialog] = useState(false),
    [page, setPage] = useState(pageInfo),
    [err, setErr] = useState({}),
    [dialogValue, setDialogValue] = useState(""),
    [footerText, setFooterText] = useState(false),
    [introText, setIntroText] = useState(false),
    [thankYouText, setThankYouText] = useState(false);

  const fieldsFilled =
    page?.title || page?.footer || page?.intro || page?.thankYou;

  const dispatch = useDispatch();

  useEffect(() => {
    setPage(pageInfo);
    setFooterText(pageInfo?.footer?.length);
    setIntroText(pageInfo?.intro?.length);
    setThankYouText(pageInfo?.thankYou?.length);
  }, [pageInfo, show]);

  useEffect(() => {
    if (!loading && res && !res.hasOwnProperty("quest_desc")) {
      setPageInfo({});
      setPage({});
    }
  }, [loading, res]);

  const onChangeHandle = (e) => {
      const { name, value, type, checked } = (e && e.target) || {};
      let v = value;
      if (name === "title" && v.split(" ").length > 25) {
        v = v.slice(0, v.length - 1);
      }
      setPage((page) => ({
        ...page,
        [name]: type == "checkbox" ? checked : v,
      }));
    },
    handleBlur = (e) => {
      if (e?.target) {
        let v = e.target.value.trim();
        if (e.target.name === "title" && v.split(" ").length > 25) {
          setErr((err) => ({
            ...err,
            [e.target.name]: "It must contains 25 words only",
          }));
          return;
        }
        const flag = containsAnyLetters(v);
        setErr((err) => ({ ...err, [e.target.name]: v ? !flag : false }));
        onChangeHandle(e);
      }
    },
    handleClose = (v) => {
      setFooterText(false);
      setIntroText(false);
      setThankYouText(false);
      setPage({});
      setErr({});
      setDialog(v || !show);
    },
    onHandleOpen = () => {
      setDialogValue("");
      handleClose();
    },
    onHandleSave = (e, errObj) => {
      // console.log("hh",Object.keys(_err).length);
      e.preventDefault();
      const _err = errObj || err;
      if (
        Object.keys(_err).length === 0 ||
        (!_err.title && !_err.footer && !_err.intro)
      ) {
        setSubmitted && setSubmitted(true);
        setPageInfo && setPageInfo((pageInfo) => ({ ...pageInfo, ...page }));
        dispatch(
          workspaceActions.createQuestDesc({ ...surveyData, page_info: page })
        );
        handleClose(false);
      }
    },
    onHandleFooter = () => {
      setFooterText(!footerText);
    },
    onHandleIntro = () => {
      setIntroText(!introText);
    },
    onHandleThankYou = () => {
      setThankYouText(!thankYouText);
    },
    onKeyPress = (e) => {
      if (e && e.key === "Enter") {
        console.log("e", e.target.value);
        let errObj = clone(err);
        if (
          page?.title?.trim() !== "" ||
          page?.footer?.trim() !== "" ||
          page?.intro?.trim() !== "" ||
          page?.thankYou?.trim() !== ""
        ) {
          const v = e.target.value.trim();
          const flag = containsAnyLetters(v);
          if (e.target.name === "title" && v.split(" ").length > 25) {
            errObj[e.target.name] = "It must contains 25 words only";
          } else {
            errObj[e.target.name] = v.length ? !flag : false;
          }
          setErr(errObj);
          onHandleSave(e, errObj);
        }
      }
    },
    isSingleQuesFormat =
      surveyData.format && surveyData.format === "SingleQuestion";
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const errStyle = { bottom: "-16px", top: "unset", left: "unset" };
  return (
    <>
      <Tooltip title="Add Page Title">
        <div
          className={`addLogo pageTitle ${
            !show && page?.pageInfo && "titleAdded"
          }`}
          onClick={onHandleOpen}
        >
          {!show && pageInfo?.title ? (
            pageInfo?.title || ""
          ) : (
            <>
              Page Title <ControlPointIcon />
            </>
          )}
        </div>
      </Tooltip>
      <Dialogbox
        open={show}
        rootClass={"addLogoDialog addPageTitleDialog"}
        handleClose={handleClose}
        dialogContentClass={"addLogoDialogInner pageTitleDialogInner"}
      >
        <div className="form">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <label>Page Title</label>
              <TextField
                hiddenLabel
                id="filled-hidden-label-small"
                value={page?.title || ""}
                variant="outlined"
                size="small"
                fullWidth
                name="title"
                onChange={onChangeHandle}
                onKeyPress={onKeyPress}
                onBlur={handleBlur}
                inputProps={{ spellCheck: true }}
              />
              {err.title ? (
                <span className="invalid-feedback" style={errStyle}>
                  {err.title === true ? "Invalid Page Title" : err.title}{" "}
                </span>
              ) : null}
            </Grid>
            {footerText ? (
              <Grid item xs={12}>
                <label>Footer Text</label>
                {isSingleQuesFormat && (
                  <div className="rightText">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={page?.repeatFooter}
                            color="success"
                            name="repeatFooter"
                            onChange={onChangeHandle}
                          />
                        }
                        label="Repeat every page"
                      />
                    </FormGroup>
                  </div>
                )}
                <TextField
                  hiddenLabel
                  id="filled-hidden-label-small"
                  value={page?.footer || ""}
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="footer"
                  onChange={onChangeHandle}
                  onKeyPress={onKeyPress}
                  onBlur={handleBlur}
                  inputProps={{ maxLength: "250", spellCheck: true }}
                />
                {err.footer ? (
                  <span className="invalid-feedback" style={errStyle}>
                    Invalid Footer Text
                  </span>
                ) : null}
              </Grid>
            ) : null}
            {introText ? (
              <Grid item xs={12}>
                <label>Introduction Text</label>
                {isSingleQuesFormat && (
                  <div className="rightText">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={page?.showIntroOnce}
                            color="success"
                            name="showIntroOnce"
                            onChange={onChangeHandle}
                          />
                        }
                        label="Show only once time"
                      />
                    </FormGroup>
                  </div>
                )}
                <TextField
                  hiddenLabel
                  id="filled-hidden-label-small"
                  value={page?.intro || ""}
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="intro"
                  onChange={onChangeHandle}
                  onKeyPress={onKeyPress}
                  onBlur={handleBlur}
                  inputProps={{ maxLength: "250", spellCheck: true }}
                />
                {err.intro ? (
                  <span className="invalid-feedback " style={errStyle}>
                    Invalid Introduction Text
                  </span>
                ) : null}
              </Grid>
            ) : null}
            {thankYouText ? (
              <Grid item xs={12}>
                <label>Thank You Text</label>

                <TextField
                  hiddenLabel
                  id="filled-hidden-label-small"
                  value={page?.thankYou || ""}
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="thankYou"
                  onChange={onChangeHandle}
                  onKeyPress={onKeyPress}
                  onBlur={handleBlur}
                  inputProps={{ maxLength: "250", spellCheck: true }}
                />
                {err.thankYou ? (
                  <span className="invalid-feedback " style={errStyle}>
                    Invalid Thank You Text
                  </span>
                ) : null}
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <div className="otherSectionButton">
                {!footerText && (
                  <button onClick={onHandleFooter}>
                    <ControlPointIcon /> Footer
                  </button>
                )}
                {!introText && (
                  <button onClick={onHandleIntro}>
                    <ControlPointIcon /> Introduction
                  </button>
                )}
                {!thankYouText && (
                  <button onClick={onHandleThankYou}>
                    <ControlPointIcon /> Thank You Message
                  </button>
                )}
              </div>
            </Grid>
          </Grid>
          <div className="btnGroup">
            <button onClick={() => setDialog(false)} className="btn btnCancel">
              Cancel
            </button>
            <button
              disabled={!fieldsFilled}
              onClick={onHandleSave}
              className="btn btnSave"
            >
              Save
            </button>
          </div>
        </div>
      </Dialogbox>
    </>
  );
};

export { AddLogo, AddTitle };
