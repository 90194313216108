import AnswersAnalysis from "./AnswersAnalysis";
import AudienceAnalysis from "./AudienceAnalysis";
import DataRaw from "./DataRaw";
import SurveyEffectiveness from "./SurveyEffectiveness";
import Tags from "./Tags/index";
import GetNewRecommendations from "./Tags/GetNewRecommendations";
import AlertDashboard from "./AlertsDashboard";
import LexicalDataAnalysis from "./LexicalDataAnalysis";
import LexicalAudienceAnalysis from "./LexicalAudienceAnalysis";
import LexicalEffectiveness from "./LexicalEffectiveness";

import QueryBuilder from "./QueryBuilder";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const AnalyseResults = ({ active, isFormType }) => {
  // const { name = "" } = active || {};
  const [name, setName] = useState("");

  const activeMenu = useSelector((state) => state.survey.activeMenu);

  useEffect(() => {
    if (
      active.name === "AnswerAnalysis" ||
      active.name === "AudienceAnalysis" ||
      active.name === "DataRaw" ||
      active.name === "SurveyEffectiveness" ||
      active.name === "Querybuilder" ||
      active.name === "AlertsDashboard"
    ) {
      setName(active.name);
      return 0;
    }
    if (activeMenu === "GetNewRecommendations") {
      setName("GetNewRecommendations");
    } else if (activeMenu === "Tags") {
      setName("Tags");
    } else {
      setName(active.name);
    }
  }, [activeMenu, active]);

  return (
    <div className="analyseResultsWrap">
      {(() => {
        switch (name) {
          case "AudienceAnalysis":
            return <AudienceAnalysis isFormType={isFormType} />;
          case "SurveyEffectiveness":
            return <SurveyEffectiveness />;
          case "DataRaw":
            return <DataRaw />;
          case "Tags":
            return <Tags />;
          case "GetNewRecommendations":
            return <GetNewRecommendations />;
          case "Querybuilder":
            return <QueryBuilder />;
          case "AlertsDashboard":
            return <AlertDashboard />;
          case "LexicalDataAnalysis":
            return <LexicalDataAnalysis />;
          case "LexicalAudienceAnalysis":
            return <LexicalAudienceAnalysis />;
          case "LexicalEffectiveness":
            return <LexicalEffectiveness />;
          default:
            return <AnswersAnalysis isFormType={isFormType} />;
        }
      })()}
    </div>
  );
};

export default AnalyseResults;
