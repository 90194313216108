import { tagsConstants } from "../constants/tagsConstants";
import { AdminService } from "../services/AdminService";
import { swalAlert } from "../voc/Common/SwalAlert";

const addTags = (addTagsData, Id) => {
  const request = () => ({
    type: tagsConstants.ADD_TAGS_REQUEST,
  });

  const success = (TAGS) => ({
    type: tagsConstants.ADD_TAGS_SUCCESS,
    TAGS,
  });

  const failure = (error) => ({ 
    type: tagsConstants.ADD_TAGS_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());
    AdminService.AddTags(addTagsData, Id).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Tag added successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const editTag = (addTagsData, Id) => {
  
  const request = () => ({
    type: tagsConstants.EDIT_TAGS_REQUEST,
  });

  const success = (alerts) => ({
    type: tagsConstants.ADD_TAGS_SUCCESS,
    alerts,
  });

  const failure = (error) => ({
    type: tagsConstants.EDIT_TAGS_FAILURE,
    error,
  });
  return (dispatch) => {
    dispatch(request());
    AdminService.updateTags(addTagsData, Id).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "TAGS Approved successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const deleteTag = (Id) => {  
  const request = () => ({
    type: tagsConstants.DELETE_TAGS_REQUEST,
  });
  
  const success = (noteId) => ({
    type: tagsConstants.ADD_TAGS_SUCCESS,
    noteId,
  });
  
  const failure = (error) => ({
    type: tagsConstants.EDIT_TAGS_FAILURE,
    error,
  });
  
  return (dispatch) => {
    dispatch(request());
    AdminService.deleteTag(Id).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Tag deleted successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

export const TagsAction = {
  addTags,
  editTag,
  deleteTag
};
