import React, { useState, useEffect } from 'react';
import TextArea from '../../../common/controls/inputs/TextArea';
import { MaterialSelect } from '../../../common/controls/inputs/Material';

import {clone} from '../../Common/Utils';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import './Layouts.scss';


const Drop = ({quesNo, quesType, setQuesType, value={}, onChange, onSave, ansTypes, children}) =>{
    const [choice, setChoice] = React.useState(false);
    const defaultProps = { options: [], className:"autoSelectWrap" };
    const {settings={}} = value;
    const { font, underline, bold, fontSize, italic, fontColor, layout,require } = settings,
        style = { fontFamily: font, fontSize, fontWeight: bold ? 'bold' : '', fontStyle: italic ? 'italic' : '', textDecoration: underline ? 'underline' : '', color: fontColor };
    const [ques, setQues] = useState(value.question);
    const [desc, setDesc] = useState(value.desc);
    const [err, setErr] = useState();
    const beforeOnSave = () => {
        if(value.question.length < 1){
            setErr("Enter the question to proceed.");
            return;
        }
        setErr();
        onSave && onSave();
    };
    useEffect(()=> {
        setQues(value.question);
        setDesc(value.desc);
    }, [value.question, value.desc]);

    useEffect(()=> {
        const elements = document.getElementsByName("question");
        let ele = elements.length ? elements[0] : elements;
        ele.style.setProperty('font-family', font||'Poppins', 'important');
    }, [font]);

    const onBlur = (e) => {
        if(e?.target){
            e.target.value = e.target.value.trim();
            e.target.name === "question" && setQues(e.target.value);
            e.target.name === "desc" && setDesc(e.target.value);
            onChange && onChange(e.target);
        }
    };


    const getChoiceCount = () => {
        let {dropdownList=''} = value;
        const list = dropdownList.split('\n');
        return list.length;
    };

    const onSelectChoice =() =>{
        setChoice(!choice);
    };

    const onUpdate = (list="") => {
        const cloned = clone(value);
        let str = sorting(cloned, list);
        cloned['dropdownList'] = str.trim();
        onChange({name: 'dropdownList', value:cloned.dropdownList});
        setChoice(!choice);
    };

    return(
        <div className="imageWrapInner">
            <div className="questionWrap">
                <div className="question">
                    <span className={"num"} style={style}>{quesNo+"- "}</span>
                    <TextArea config={{ name: 'question', inputClass: 'questionInputArea', placeholder: 'Your elements here. Recall information@', textAreaStyle: style }}  onBlur={onBlur} value={ques} onChange={(e)=> setQues(e.target.value)}/>
                        <span style={{color:"red", marginRight:'50px',display: 'none'}}>*</span>
                </div>
                <em><input type="text" name="desc" placeholder="Description (optional)" onBlur={onBlur} value={desc} onChange={(e)=> setDesc(e.target.value)}/></em>
            </div>
            {settings?.layout == 1 && children}
            <div className=" dropDownInner">
                <div className="selectWrap">
                    <div className="selectInner">
                        <Autocomplete
                            readOnly
                            {...defaultProps}
                            id="disable-close-on-select"
                            disableCloseOnSelect
                            onOpen = {() => setChoice(true)}
                            renderInput={(params) => (
                                <TextField {...params} label="Type or select an option" variant="standard" />
                            )}
                        />
                    </div>
                    <div className='answerTypeSelectBox'>
                        <MaterialSelect config={{ isMenuWithContent: true, fieldClass: 'answerInputType', menuItems: ansTypes, value: quesType, onChange: (e)=> setQuesType(e.target.value) }} />
                    </div>
                </div>
                <div className="choiceList">
                    <div className="createMultiple">
                        <div className="btnChoice" onClick={onSelectChoice}>
                            <AddCircleOutlineIcon/> Choice
                        </div>
                        {choice && <EditChoice value={value} onUpdate={onUpdate}/> }
                    </div>
                    <div className="number">{getChoiceCount()} option[s] in list</div>
                </div>
            </div>

            <div className="btnGroup" style={{paddingBottom:0}}>
                <button className="btnSave" onClick={beforeOnSave}>Save</button>
            </div>
            {(typeof err !== "undefined" && err.length) ? <span className={"invalid-feedback"} style={{position:'unset'}}>{err}</span>:null}
        </div>
    )
};

const sorting = (value, s)=>{
    if(value?.settings?.sorting){
        let v = s.split('\n').sort();
        return v.join('\n');
    }
    return s;
};

const EditChoice = ({value={}, onUpdate}) => {
    const [list, setList] = React.useState(value.dropdownList);
    useEffect(()=> {
        setList(value.dropdownList);
    },[value.dropdownList]);

    useEffect(()=> {
        const str = sorting(value, list);
        setList(str);
    },[value.settings.sorting]);

    const onEdit = (e) => {
        setList(e?.target.value);
    };
    return (
        <div className="choiceDrop">
            <em>Write or paste your choices below. Each choice must be on a separate line.</em>
            <hr style={{margin: '2px 0'}}/>
            <textarea style={{marginTop: 10, paddingTop: 5, fontSize:16}} name="list" value={list} onChange={onEdit}></textarea>
            <button className="btnSave" onClick={()=>onUpdate(list)}>Save</button>
        </div>
    );
}

export default Drop;
