import React, { useState } from "react";
import "rsuite/dist/rsuite.css";
import decorator from "../../../../assets/images/decorator.svg";
import decorator1 from "../../../../assets/images/decorator1.svg";
import decorator2 from "../../../../assets/images/decorator2.svg";
import CardBottomImage from "../../../../assets/images/card-bg-bottom.svg";

const TypeDecoratorChoice = (props) => {
  const {
    layout,
    children,
    qus_id,
    question,
    desc = "",
    if_pictures_type = [],
    qno = 1,
    settings = {},
    cardRef,
    quesType,
    onChange,
    qRefs,
    submit,
    btnText = "Next",
    sliderView,
    onFocus,
    showSubmitBtn,
  } = props;
  const {
      font,
      underline,
      bold,
      fontSize,
      italic,
      fontColor,
      multiple_selection,
      require,
      randomize,
      supersize,
    } = settings,
    style = {
      fontFamily: font,
      fontSize,
      fontWeight: bold ? "bold" : "",
      fontStyle: italic ? "italic" : "",
      textDecoration: underline ? "underline" : "",
      color: fontColor,
    };

  return (
    <div className="decoratorImage">
      <div className="imageBottom">
        {props?.decoratorTheme == "decorator1" ? (
          <img src={decorator}></img>
        ) : props?.decoratorTheme == "decorator2" ? (
          <img src={decorator1}></img>
        ) : props?.decoratorTheme == "decorator3" ? (
          <img src={decorator2}></img>
        ) : props?.decoratorTheme == "" || null ? (
          ""
        ) : (
          <img src={props.decoratorTheme}></img>
        )}
        {/* <img src={props.value ? props.value.decoratorTheme : {CardBottomImage}}></img> */}
      </div>
    </div>
  );
};

export default TypeDecoratorChoice;
