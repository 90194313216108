import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';

const AuthPrompt = ({
  open,
  onClose,
  onAuthenticate,
  isLoading = false
}) => {
  // Determine social type from localStorage
  const socialType = localStorage.getItem('social_type') || 'google';
  
  const getProviderName = () => {
    switch(socialType) {
      case 'google': return 'Google';
      case 'microsoft': return 'Microsoft Outlook';
      default: return 'the service';
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="auth-dialog-title"
      sx={{width:"40%", height:"35%"}}
    >
      <DialogTitle id="auth-dialog-title">Authentication Required</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You need to authenticate with {getProviderName()} to continue this action.
          Do you want to authenticate now?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button 
          onClick={() => onAuthenticate(socialType)} 
          disabled={isLoading}
          color="primary"
          variant="contained"
          startIcon={isLoading ? <CircularProgress size={20} /> : null}
        >
          {isLoading ? 'Authenticating...' : 'Authenticate'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuthPrompt;