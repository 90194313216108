import React from "react";
import { history } from '../../../helpers';

const NoData = ({onNoDataSubmit, surveyData={}})=>{

    const onCancel = () => {
        history.push({pathname: '/scratch', state:{surveyName:surveyData.survey_title||"", surveyData}});
    };

    return(
        <div className={`sendEmailBody`}>
            { <div className="head">
                <div className="icon"><img src={origin + "/images/SVG/sendemailicon.svg"} alt="link"/></div>
                Email Contacts
            </div>}
            <div className="sendEmailInner">
                <div className="noData">
                    <div className="leftNoData">
                        <img src={origin+"/images/SVG/nodata.svg"} alt="link"/>
                    </div>
                    <div className="rightNoData">
                        <h3>No Contacts yet?</h3>
                        <p>Add Email and other details.</p>
                        <div className="btnGroup">
                            <button className="btn btnDefault" onClick={onNoDataSubmit}>Import Contacts</button>
                            <button className="btn" onClick={onCancel}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default NoData;
