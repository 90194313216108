export const territoryConstants = {
    // Get all territories
    GET_ALL_TERRITORIES_REQUEST: "GET_ALL_TERRITORIES_REQUEST",
    GET_ALL_TERRITORIES_SUCCESS: "GET_ALL_TERRITORIES_SUCCESS",
    GET_ALL_TERRITORIES_FAILURE: "GET_ALL_TERRITORIES_FAILURE",
  
    // Add territory
    ADD_TERRITORY_REQUEST: "ADD_TERRITORY_REQUEST",
    ADD_TERRITORY_SUCCESS: "ADD_TERRITORY_SUCCESS",
    ADD_TERRITORY_FAILURE: "ADD_TERRITORY_FAILURE",
    RESET_TERRITORY_ADDED: "RESET_TERRITORY_ADDED",
  
    // Edit territory
    EDIT_TERRITORY_REQUEST: "EDIT_TERRITORY_REQUEST",
    EDIT_TERRITORY_SUCCESS: "EDIT_TERRITORY_SUCCESS",
    EDIT_TERRITORY_FAILURE: "EDIT_TERRITORY_FAILURE",
    RESET_TERRITORY_UPDATED: "RESET_TERRITORY_UPDATED",
  
    // Delete territory
    DELETE_TERRITORY_REQUEST: "DELETE_TERRITORY_REQUEST",
    DELETE_TERRITORY_SUCCESS: "DELETE_TERRITORY_SUCCESS",
    DELETE_TERRITORY_FAILURE: "DELETE_TERRITORY_FAILURE",

    ASSIGN_TERRITORY_REQUEST: "ASSIGN_TERRITORY_REQUEST",
    ASSIGN_TERRITORY_SUCCESS: "ASSIGN_TERRITORY_SUCCESS",
    ASSIGN_TERRITORY_FAILURE: "ASSIGN_TERRITORY_FAILURE"
  };
  