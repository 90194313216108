import React, { useState, useEffect } from 'react';
import { Modal, Box, Grid, Tooltip, Typography, IconButton, TextField, Button, Select, MenuItem, FormControl, Chip, Checkbox, ListItemText, Autocomplete,Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle } from "@mui/material";
import moment from "moment";
import CloseIcon from '@mui/icons-material/Close';
import { CrmService } from "../../../services";
import { appointmentService } from '../../../services/AppointmentService';
import ComposeByGmail from '../CustomerTable/EmailSetupCustomerTable/ComposeByGmail';
import { getUser } from '../../../helpers';



const peopleOptions = ["John", "Jane", "Alice", "Bob", "Mary"];

const CalendarModal = ({ open, onClose, onSave, onDelete, formValues, setFormValues, selectedAppointment, editAppointments, appointments, onSaveTrigger, saveSuccess, }) => {

  const [customerData, setCustomerData] = useState(null)
  const [organizerData, setOrganizerData] = useState(null)
  const [error, setError] = useState(""); 
  const [nameError, setNameError] = useState("");
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [editData, setEditData] = useState(null);
  const [peopleSearch, setPeopleSearch] = useState("");
  const [isComposeOpen, setIsComposeOpen] = useState(false);
  const [isNotificationPopupOpen, setIsNotificationPopupOpen] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);


  const mandatoryFields = ["title", "organizerId", "location", "start", "end", "people", "description"];
  const user = JSON.parse(localStorage.getItem("user"));
  const auth = JSON.parse(localStorage.getItem("auth"));


  // Validation for mandatory fields
  useEffect(() => {
    const allMandatoryFilled = mandatoryFields.every(
      (field) => formValues[field] && formValues[field].toString().trim() !== ""
    );
    setIsSaveEnabled(allMandatoryFilled && !error);
  }, [formValues, error]);


  const handleSaveClick = () => {

    if (!isSaveEnabled) return;

    if (error) return;
    const newAppointment = {
      title: formValues.title,
      organizer_id: formValues.organizerId,
      location: formValues.location,
      start_time: new Date(formValues.start).toISOString().replace("Z", "+00:00"),
      end_time: new Date(formValues.end).toISOString().replace("Z", "+00:00"),
      attendees: formValues.peopleIds,
      //appointment_source: formValues.source,
      description: formValues.description,
    };
    setSaveClicked(true);

    // const newSocialAppointment = {
    //   access_token: auth.access_token,
    //   refresh_token: auth.refresh_token,
    //   title: formValues.title,
    //   organizer_id: formValues.organizerId,
    //   location: formValues.location,
    //   start_time: new Date(formValues.start).toISOString().replace("Z", "+00:00"),
    //   end_time: new Date(formValues.end).toISOString().replace("Z", "+00:00"),
    //   attendees: formValues.peopleIds,
    //   //appointment_source: formValues.source,
    //   description: formValues.description
    // };
    setIsComposeOpen(true);

    if (!user || user.social_type === null) {
      onSave(newAppointment); // Directly save if `social_type` is null
      setError("");
      return;
    }

    // if(auth.access_token){
    //   onSave(newSocialAppointment);
    //   setError("");
    //   return;
    // }

    //onSave(newAppointment);
    onSaveTrigger();
    //setIsNotificationPopupOpen(true);
       setError("");
  };

  useEffect(() => {
    if (saveClicked && auth && (auth.access_token || auth.refresh_token)) {
      const newSocialAppointment = {
        access_token: auth.access_token,
        refresh_token: auth.refresh_token,
        title: formValues.title,
        organizer_id: formValues.organizerId,
        location: formValues.location,
        start_time: new Date(formValues.start).toISOString().replace("Z", "+00:00"),
        end_time: new Date(formValues.end).toISOString().replace("Z", "+00:00"),
        attendees: formValues.peopleIds,
        //appointment_source: formValues.source,
        description: formValues.description,
      };
      
      onSave(newSocialAppointment);
      setError("");
      setSaveClicked(false);  
    }  
  }, [saveClicked, auth?.access_token, auth?.refresh_token]);

  useEffect(() => {
    if (saveSuccess) {
      setIsNotificationPopupOpen(true); // Show popup only on successful save
    }
  }, [saveSuccess]);

  const handleNotificationResponse = (response) => {
    setIsNotificationPopupOpen(false);
    if (response === "yes") {
      //setIsComposeOpen(true); // Open the compose email dialog
    }
  };

  useEffect(() => {
    (async () => {
      const data = await CrmService.getAllCustomers();       
      setCustomerData(data?.data?.customers);
    })();
  }, []);

  useEffect(() => {
    if (!editAppointments) {
      (async () => {
        const data = await appointmentService.getUserExtension();
        setOrganizerData(data?.data);
      })();
    }
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     const data = await appointmentService.getAppointmentsById(selectedAppointment.id);
  //     setEditData(data?.data);

  //   })();
  // }, []);

  const handlePeopleChange = (event) => {
    const selectedNames = event.target.value;
    const selectedPeople = customerData.filter((customer) => selectedNames.includes(customer.first_name));
    const selectedIds = selectedPeople.map((person) => person.id);

    setFormValues({
      ...formValues,
      people: selectedNames, // Display selected names
      peopleIds: selectedIds, // Store IDs for `to_name` in `newAppointment`
    });
  };

  // const handleEndDateChange = (e) => {
  //   const newEndDate = new Date(e.target.value);
  //   setFormValues({ ...formValues, end: newEndDate });

  //   // Validation: Check if start date is after end date
  //   if (new Date(formValues.start) > newEndDate) {
  //     setError("End date cannot be earlier than start date");
  //   } else {
  //     setError(""); // Clear the error if validation passes
  //   }
  // };

  const handleStartDateChange = (e) => {
    const newStartDate = new Date(e.target.value);
    setFormValues({ ...formValues, start: newStartDate });

    // Validation: Check if end date is earlier than start date
    if (formValues.end && newStartDate > new Date(formValues.end)) {
      setError("End date cannot be earlier than start date");
    } else {
      setError(""); // Clear the error if validation passes
    }
  };

  const handleEndDateChange = (e) => {
    const newEndDate = new Date(e.target.value);
    setFormValues({ ...formValues, end: newEndDate });

    // Validation: Check if end date is earlier than start date
    if (formValues.start && new Date(formValues.start) > newEndDate) {
      setError("End date cannot be earlier than start date");
    } else {
      setError(""); // Clear the error if validation passes
    }
  };

  const handleClose = () => {
    onClose();
    setError('');
  };

  const toggleCompose = () => {
    setIsComposeOpen((prev) => !prev); // Toggle the state
  };
  


  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={(theme) => ({
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          // Apply styles only on screens smaller than 600px
          [theme.breakpoints.down('sm')]: {
            height: '90%', // 90% height for screens smaller than 600px
            overflowY: 'auto', // Enable vertical scrolling for small screens
          },
        })}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: 'grey.500',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" gutterBottom>{selectedAppointment ? "Edit Appointment" : "Add Appointment"}</Typography>
        <Grid container spacing={1}>
          {/* Title Input */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={
                <>
                  {"Title"}
                  <Typography component="span" color="error" style={{ marginLeft: 4 }}>
                    *
                  </Typography>
                </>
              }
              margin="normal"
              value={formValues.title}
              onChange={(e) => {
                const newTitle = e.target.value;

                // Check for duplicate title
                const isDuplicate = appointments.some(
                  (appointment) => appointment.title.toLowerCase() === newTitle.trim().toLowerCase()
                );

                if (isDuplicate) {
                  setNameError("Title already exists");
                } else {
                  setNameError(""); // Clear the error if no duplicate
                }

                setFormValues({ ...formValues, title: newTitle });
              }}
              error={!!nameError}
              helperText={nameError}
            />
          </Grid>



          {/* Organizer Input */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              select
              label={
                <>
                  {"Organizer"}
                  <Typography component="span" color="error" style={{ marginLeft: 4 }}>
                    *
                  </Typography>
                </>
              }
              margin="normal"
              name="Organizer"
              value={formValues.Organizer}
              onChange={(e) => {
                const selectedCustomer = organizerData.find((customer) => customer.Name === e.target.value);
                setFormValues({
                  ...formValues,
                  Organizer: e.target.value,
                  organizerId: selectedCustomer.id,
                });
              }}
            >
              {organizerData?.map((customer) => (
                <MenuItem key={customer.id} value={customer.Name}>
                  {customer.Name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Start Date & Time */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="datetime-local"
              label={
                <>
                  {"Start Date & Time"}
                  <Typography component="span" color="error" style={{ marginLeft: 4 }}>
                    *
                  </Typography>
                </>
              }
              margin="normal"
              value={moment(formValues.start).format("YYYY-MM-DDTHH:mm")}
              onChange={handleStartDateChange}
            />

          </Grid>

          {/* End Date & Time */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="datetime-local"
              label={
                <>
                  {"End Date & Time"}
                  <Typography component="span" color="error" style={{ marginLeft: 4 }}>
                    *
                  </Typography>
                </>
              }
              margin="normal"
              value={moment(formValues.end).format("YYYY-MM-DDTHH:mm")}
              onChange={handleEndDateChange}
              error={Boolean(error)}
              helperText={error}
            />
          </Grid>

          {/* Location */}
          <Grid item xs={12} >
            <TextField
              fullWidth
              label={
                <>
                  {"Location"}
                  <Typography component="span" color="error" style={{ marginLeft: 4 }}>
                    *
                  </Typography>
                </>
              }
              margin="dense"
              value={formValues.location}
              onChange={(e) => setFormValues({ ...formValues, location: e.target.value })}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="people-search"
              value={formValues.people}
              onChange={(event, newValue) => {
                // Handle the selection change by filtering customers based on the selected first names
                const selectedPeople = customerData.filter((customer) =>
                  newValue.includes(customer.first_name)
                );
                const selectedIds = selectedPeople.map((person) => person.id);

                setFormValues({
                  ...formValues,
                  people: newValue, // Display selected first names
                  peopleIds: selectedIds, // Store IDs for `to_name` in `newAppointment`
                });
              }}
              options={customerData?.map((customer) => customer.first_name) || []} // Only search based on first_name
              getOptionLabel={(option) => option} // Display the first_name in the dropdown
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <>
                      {"People"}
                      <Typography component="span" color="error" style={{ marginLeft: 4 }}>
                        *
                      </Typography>
                    </>
                  }
                  margin="normal"
                />
              )}
              renderOption={(props, option) => {
                // Find the customer by first_name
                const customer = customerData.find((cust) => cust.first_name === option);

                // Ensure a unique key by combining customer.id and customer.first_name
                return (
                  <li {...props} key={`${customer.id}-${customer.first_name}`}>
                    <Checkbox checked={formValues.people.includes(option)} />
                    <ListItemText primary={option} />
                  </li>
                );
              }}
              filterOptions={(options, state) => {
                return options.filter((option) =>
                  option.toLowerCase().includes(state.inputValue.toLowerCase())
                );
              }}
              noOptionsText="No matching people"
              disableCloseOnSelect
            />
          </Grid>

          {/* Description */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={
                <>
                  {"Description"}
                  <Typography component="span" color="error" style={{ marginLeft: 4 }}>
                    *
                  </Typography>
                </>
              }
              multiline
              rows={4}
              margin="dense"
              value={formValues.description}
              onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
            />
          </Grid>

          {/* Action Buttons */}
          <Grid item xs={12}>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
              <Tooltip
                title={!isSaveEnabled ? "Please fill all mandatory fields" : ""}
                placement="top"
              >
                <span>
                  <Button
                    variant="contained"
                    onClick={handleSaveClick}
                    disabled={!isSaveEnabled}
                  >
                    Save
                  </Button>
                </span>
              </Tooltip>
              {selectedAppointment && (
                <Button variant="outlined" color="error" onClick={onDelete}>
                  Delete
                </Button>
              )}
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>


        {/* Notification Popup */}
      <Dialog
        open={isNotificationPopupOpen}
        onClose={() => setIsNotificationPopupOpen(false)}
        aria-labelledby="notification-dialog-title"
        aria-describedby="notification-dialog-description"
      >
        <DialogTitle id="notification-dialog-title">Send Notification</DialogTitle>
        <DialogContent>
          <DialogContentText id="notification-dialog-description">
            Do you want to send a notification to the peoples?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleNotificationResponse("no")} color="primary">
            No
          </Button>
          <Button onClick={() => handleNotificationResponse("yes")} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

        {isComposeOpen && (
          <ComposeByGmail
            surveyID={123}
            show={isComposeOpen}
            handleClose={toggleCompose}
            records={[]}
            emailType={user.social_type}
            email={user.email}
            customerId={456}
            customers={formValues.peopleIds.map((id) => [id, 5.5])}
            usr={user.social_type}
            isAppointment={true}
            subjectApp="Follow-Up on Appointment" 
            contentApp="Dear Customer, Thank you for scheduling an appointment. Here are the details..." 
            scheduleApp={(() => {
              const now = new Date();
              now.setMinutes(now.getMinutes() + 2); // Add 2 minutes
              const offset = 5.5 * 60; // IST is UTC+5:30
              const localTime = new Date(now.getTime() + offset * 60 * 1000);
              return localTime.toISOString().slice(0, 16).replace('T', 'T');
            })()}            />
        )}
      </Box>
    </Modal>
  );
};

export default CalendarModal;
