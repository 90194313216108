import cn from "classnames";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Label from "../../../../common/controls/inputs/Label";
import DialogBox from "../../../Common/DialogBox";
import Input from "../../../../common/controls/inputs/Input";
import { Button } from "antd";
import Alert from "@mui/material/Alert";
import { swalAlert } from "../../../../voc/Common/SwalAlert";
import axios from "../../../../services/api";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { commonService } from "../../../../services/CommonService";
import "../style.css";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import CancelIcon from "@mui/icons-material/Cancel";
const Notes = ({ setDialog, question, categorization, type }) => {
  const apiUrl = commonService.apiUrl;
  const { id } = useParams();
  const [category, setcategory] = useState("");
  const [Errors, setErrors] = useState({});
  const [showEmail, setShowEmail] = useState(false);
  const longSurveyData = useSelector((state) => state.survey.longSurveyItems);
  const longTextAudienceSummary = useSelector(
    (state) => state.survey.longTextAudienceSummary
  );
  const longTextSummary = useSelector((state) => state.survey.longTextSummary);
  const longQuestionId = useSelector((state) => state.survey.longQuestionId);
  const [text, setText] = useState("");
  const [email, setEmail] = useState([]);
  const [title, settitle] = useState("");
  const [value, setvalue] = useState("");
  useEffect(() => {
    if (categorization === "category" && type === "answer") {
      let pieLabel = [];
      longSurveyData &&
        longSurveyData["long_answer_chart_response"] &&
        Object.entries(longSurveyData["long_answer_chart_response"]).forEach(
          ([key, value]) => {
            pieLabel.push(key);
          }
        );
      setcategory(pieLabel[0]);
    } else if (categorization === "longTextSummary" && type === "answer") {
      if (longTextSummary?.data && longTextSummary.data.length > 0) {
        setcategory(longTextSummary?.data[0]?.text);
      } else {
        setcategory([]);
      }
    } else if (type === "audience") {
      if (
        longTextAudienceSummary?.data &&
        longTextAudienceSummary.data.length > 0
      ) {
        setcategory(longTextAudienceSummary?.data[0]?.text);
      } else {
        setcategory([]);
      }
    }
  }, [longTextSummary, categorization, longTextAudienceSummary]);
  const submit = async () => {
    setErrors(validate());
    if (Object.keys(validate()).length === 0) {
      const result = await axios.post(`/questionLevelAnalysisAssignQuestion`, {
        survey_id: id,
        qus_id: longQuestionId,
        assignees: email,
        title: title,
        value: value,
        category: category,
        notes: notes,
        survey_link: window.location.href,
        tld_summary: {},
      });
      console.log("sdjsdjsd", result);
      if (result.data.message) {
        swalAlert({
          icon: "success",
          title: "Email has been sent!".toString(),
          showConfirmButton: true,
        });
        setErrors({});
        setnotes("");
        setEmail([]);
        settitle("");
        setvalue("");
        handleClose();
      }
    }
  };
  const validate = (values) => {
    const errors = {};

    if (!email.length > 0) {
      errors.email = "Email Required";
    }
    if (!title) {
      errors.title = "Title Required";
    }
    if (!value) {
      errors.value = "Value Required";
    }
    if (!notes) {
      errors.notes = "Notes Required";
    }
    if (!category) {
      errors.category =
        "You have to have answers before you can categorize or categorisation not done";
    }
    return errors;
  };
  const [notes, setnotes] = useState("");
  const handleClose = () => {
    setDialog(false);
  };
  const handleOk = (value) => {
    setDialog(false);
  };
  const validateEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <DialogBox
      open={true}
      handleClose={handleClose}
      handleOk={handleOk}
      dialogContentClass={"analyseNotes"}
    >
      <div className="analyseModalbox">
        <div>
          <div className="analyseNotesHeader">
            <Label config={{ label: "Notes", className: "notesDialogTitle" }} />
            <div className="cancelIcon" onClick={handleClose}>
              <CancelIcon />
            </div>
          </div>
          <div className="analyseDetailwrap">
            <div className="flexbox">
              <div className="analyseLabel">Question</div>
              <div className="analyseDetail">
                <div className="shortText">
                  <p>{question}</p>
                </div>
              </div>
            </div>
            {/* <div className="flexbox">
              <div className="analyseLabel">TLD Summary</div>
              <div className="analyseDetail">
                <ul className="summayList">
                  <li>Date</li>
                  <li>Mail ID</li>
                  <li>Country</li>
                  <li>Type excercise</li>
                </ul>
              </div>
            </div> */}
            <div className="flexbox">
              <div className="analyseLabel">Category</div>
              <div className="analyseDetail">
                <ul className="category-list">
                  {longTextSummary &&
                  longTextSummary.data &&
                  categorization === "longTextSummary" &&
                  type === "answer" &&
                  longTextSummary.data.length > 0
                    ? longTextSummary.data.map((value, index) => {
                        return (
                          <li
                            onClick={() => {
                              setcategory(value.text);
                              setActiveIndex(index);
                            }}
                            style={{ cursor: "pointer" }}
                            className={index === activeIndex ? "active" : ""}
                          >
                            {value.text}
                          </li>
                        );
                      })
                    : null}
                  {longTextAudienceSummary &&
                  longTextAudienceSummary.data &&
                  type === "audience" &&
                  longTextAudienceSummary.data.length > 0
                    ? longTextAudienceSummary.data.map((value, index) => {
                        return (
                          <li
                            onClick={() => {
                              setcategory(value.text);
                              setActiveIndex(index);
                            }}
                            style={{ cursor: "pointer" }}
                            className={index === activeIndex ? "active" : ""}
                          >
                            {value.text}
                          </li>
                        );
                      })
                    : null}
                  {longSurveyData &&
                    longSurveyData["long_answer_chart_response"] &&
                    categorization === "category" &&
                    type === "answer" &&
                    Object.entries(
                      longSurveyData["long_answer_chart_response"]
                    ).map((value, index) => {
                      return (
                        <li
                          onClick={() => {
                            setcategory(value[0]);
                            setActiveIndex(index);
                          }}
                          style={{ cursor: "pointer" }}
                          className={index === activeIndex ? "active" : ""}
                        >
                          {value[0]}
                        </li>
                      );
                    })}
                </ul>

                {Errors && Errors.category ? (
                  <p style={{ color: "red" }}>{Errors.category}</p>
                ) : null}
              </div>
            </div>
            <div className="flexbox">
              <div className="analyseLabel">Assignees</div>
              <div className="analyseDetail">
                <div className="emailField">
                  <div className="items">
                    {email && email.length > 0
                      ? email.map((value, index) => {
                          return (
                            <div className="emailfield-box">
                              <input disabled={true} value={value}></input>
                              <button
                                className="cancelIcon"
                                onClick={() => {
                                  const temp = [...email];

                                  const filter = temp.filter(
                                    (item, i) => i !== index
                                  );

                                  setEmail(filter);
                                }}
                              >
                                x
                              </button>
                            </div>
                          );
                        })
                      : null}

                    <div className="notes_btn">
                      <button
                        onClick={() => {
                          setShowEmail(!showEmail);
                        }}
                        className="add-email-btn"
                      >
                        <svg
                          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                          focusable="false"
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                          data-testid="ControlPointIcon"
                          aria-label="Add Questions Type"
                          data-mui-internal-clone-element="true"
                        >
                          <path
                            d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
                            fill="#18A0FB"
                          ></path>
                        </svg>
                        Add Email
                      </button>
                    </div>
                    {showEmail ? (
                      <div className="email-input-wrap">
                        <div className="input-fields">
                          <input
                            value={text}
                            onChange={(e) => {
                              setErrors({});
                              setText(e.target.value);
                            }}
                            placeholder="Please Enter Email"
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                if (!validateEmail(e.target.value)) {
                                  setErrors({
                                    email: "Please Enter Vaild Email",
                                  });
                                  return 0;
                                }
                                const indx = email.findIndex(
                                  (x) => x === e.target.value
                                );
                                if (indx === -1) {
                                  setEmail(email.concat(e.target.value));
                                  e.target.value = "";
                                  setText("");
                                  setShowEmail(!showEmail);
                                } else {
                                  e.target.value = "";
                                  setText("");
                                  setShowEmail(!showEmail);
                                }
                              }
                            }}
                            type="text"
                          ></input>

                          <button
                            onClick={() => {
                              if (!validateEmail(text)) {
                                setErrors({
                                  email: "Please Enter Vaild Email",
                                });
                                return 0;
                              }
                              const indx = email.findIndex((x) => x === text);
                              if (indx === -1) {
                                setEmail(email.concat(text));
                                setText("");
                                setShowEmail(!showEmail);
                              } else {
                                setText("");
                                setShowEmail(!showEmail);
                              }
                            }}
                            className="ml-2 email-save-btn"
                          >
                            Save
                          </button>

                          <button
                            onClick={() => {
                              setText("");
                              setErrors({});
                              setShowEmail(!showEmail);
                            }}
                            className="cancelIcon"
                          >
                            x
                          </button>
                        </div>

                        <div className="error-fields">
                          {Errors && Errors.email ? (
                            <p style={{ color: "red" }}>{Errors.email}</p>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* <Input
                    config={{
                      name: "Email",
                      type: "email",
                      className: "Title",
                    }}
                  /> */}
                </div>
                <p>Assignee will be notified by email</p>
              </div>
            </div>
            <div className="flexbox">
              <div className="analyseLabel">Title</div>
              <div className="analyseDetail">
                <Input
                  value={title}
                  onChange={(e) => {
                    setErrors({});
                    settitle(e.target.value);
                  }}
                  config={{
                    name: "title",
                    type: "text",
                    className: "Title",
                  }}
                />
                {Errors && Errors.title ? (
                  <p style={{ color: "red" }}>{Errors.title}</p>
                ) : null}
              </div>
            </div>
            <div className="flexbox">
              <div className="analyseLabel">Value</div>
              <div className="analyseDetail">
                <Input
                  value={value}
                  onChange={(e) => {
                    setErrors({});

                    setvalue(e.target.value);
                  }}
                  config={{
                    name: "value",
                    type: "text",
                    className: "Value",
                  }}
                />
                {Errors && Errors.value ? (
                  <p style={{ color: "red" }}>{Errors.value}</p>
                ) : null}
              </div>
            </div>
            <div className="flexbox">
              <div className="analyseLabel">Notes</div>
              <div className="analyseDetail">
                <Input
                  value={notes}
                  onChange={(e) => {
                    setErrors({});

                    setnotes(e.target.value);
                  }}
                  config={{
                    name: "notes",
                    type: "text",
                    className: "notes",
                  }}
                />
                {Errors && Errors.notes ? (
                  <p style={{ color: "red" }}>{Errors.notes}</p>
                ) : null}
                <p>
                  Don't write sensitive information here like social security
                  number etc.
                </p>
              </div>
            </div>
            <div className="flexbox">
              <div className="buttonsWrap">
                <Button onClick={submit} type="primary">
                  Save
                </Button>{" "}
                <Button type="default" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DialogBox>
  );
};

export default Notes;
