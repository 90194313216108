import { roleConstants } from "../constants/roleConstants";
import { AdminService } from "../services/AdminService";
import { swalAlert } from "../voc/Common/SwalAlert";

const addRoles = (RolesData, Id) => {
  const request = () => ({
    type: roleConstants.ADD_ROLE_REQUEST,
  });

  const success = (agents) => ({
    type: roleConstants.ADD_ROLE_SUCCESS,
    agents,
  });

  const failure = (error) => ({ 
    type: roleConstants.ADD_ROLE_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());
    AdminService.addRoles(RolesData, Id).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Role added successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const updateRole = (RolesData, roleId) => {
  
  const request = () => ({
    type: roleConstants.EDIT_ROLE_REQUEST,
  });

  const success = (alerts) => ({
    type: roleConstants.EDIT_ROLE_SUCCESS,
    alerts,
  });

  const failure = (error) => ({
    type: roleConstants.EDIT_ROLE_FAILURE,
    error,
  });
  return (dispatch) => {
    dispatch(request());
    AdminService.updateRoles(RolesData, roleId).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Role updated successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const deleteRole = (roleId) => {  
  const request = () => ({
    type: roleConstants.DELETE_ROLE_REQUEST,
  });
  
  const success = (noteId) => ({
    type: roleConstants.DELETE_ROLE_SUCCESS,
    noteId,
  });
  
  const failure = (error) => ({
    type: roleConstants.DELETE_ROLE_FAILURE,
    error,
  });
  
  return (dispatch) => {
    dispatch(request());
    AdminService.deleteRole(roleId).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Role deleted successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

export const rolesAction = {
  addRoles,
  updateRole,
  deleteRole
};
