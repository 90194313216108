import { audienceConstants } from "../constants";
import { surveyService } from "../services";
import { swalAlert } from "../voc/Common/SwalAlert";

const setAudienceType = (audienceType) => {
  return (dispatch) => {
    dispatch({
      type: audienceConstants.GETAUDIENCE_TYPE,
      audienceType,
    });
  };
};

const setLexicalAudienceType = (audienceType) => {
  return (dispatch) => {
    dispatch({
      type: audienceConstants.GETLEXICALAUDIENCE_TYPE,
      audienceType,
    });
  };
};

const resetReducer = () => {
  return {
    type: audienceConstants.GETAUDIENCE_RESET,
  };
};
const setSurveyStatus = (surveyStatus) => {
  return (dispatch) => {
    dispatch({
      type: audienceConstants.GETSURVEYSTATUS,
      surveyStatus,
    });
  };
};

const setLexicalSurveyStatus = (surveyStatus) => {
  return (dispatch) => {
    dispatch({
      type: audienceConstants.GETLEXICALSURVEYSTATUS,
      surveyStatus,
    });
  };
};

const setQuestionId = (questionId) => {
  return (dispatch) => {
    dispatch({
      type: audienceConstants.GETQUESTION_ID,
      questionId,
    });
  };
};
const getAudience = (audienceType, questionId, surveyStatus, surveyId) => {
  const request = () => ({ type: audienceConstants.GETAUDIENCE_REQUEST });
  const success = (audience) => ({
    type: audienceConstants.GETAUDIENCE_SUCCESS,
    audience,
  });
  const failure = (error) => ({
    type: audienceConstants.GETAUDIENCE_FAILURE,
    error,
  });
  if (audienceType === "anon") {
    surveyStatus = "";
  }
  // if (audienceType === "mail_user") {
  //   surveyStatus = "email_sent";
  // }

  if (audienceType === "social_channel" && surveyStatus === "email_sent") {
    surveyStatus = "";
  }
  if (
    audienceType === "social_channel" &&
    surveyStatus === "response_received"
  ) {
    surveyStatus = "";
  }
  return (dispatch) => {
    dispatch(request());

    surveyService
      .getAudienceDetails(audienceType, questionId, surveyStatus, surveyId)
      .then(
        (survey) => {
          dispatch(success(survey));
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
  };
};

const getAudienceLexical = (
  audienceType,
  questionId,
  surveyStatus,
  surveyId
) => {
  const request = () => ({
    type: audienceConstants.GETLEXICALAUDIENCE_REQUEST,
  });
  const success = (audience) => ({
    type: audienceConstants.GETLEXICALAUDIENCE_SUCCESS,
    audience,
  });
  const failure = (error) => ({
    type: audienceConstants.GETLEXICALAUDIENCE_FAILURE,
    error,
  });
  if (audienceType === "anon") {
    surveyStatus = "";
  }

  if (audienceType === "social_channel" && surveyStatus === "email_sent") {
    surveyStatus = "";
  }
  if (
    audienceType === "social_channel" &&
    surveyStatus === "response_received"
  ) {
    surveyStatus = "";
  }
  return (dispatch) => {
    dispatch(request());

    surveyService
      .getLexicalAudienceDetails(
        audienceType,
        questionId,
        surveyStatus,
        surveyId
      )
      .then(
        (survey) => {
          dispatch(success(survey));
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
  };
};

export const audienceActions = {
  setAudienceType,
  getAudience,
  getAudienceLexical,
  setLexicalSurveyStatus,
  setLexicalAudienceType,
  setSurveyStatus,
  setQuestionId,
  resetReducer,
};
