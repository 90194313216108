import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Grid,
  TextField,
  Button,
  MenuItem,
  Typography,
  ListItemText,
  Chip,
  Checkbox,
  FormControlLabel,
  Tooltip,
  IconButton,
  FormControl,
  Select,
} from "@mui/material";
import {
  DatePicker,
  TimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CloseIcon from "@mui/icons-material/Close";
import { RulesService } from "../../../services/RulesService";
import { AgentsService } from "../../../services/AgentsService";
import {useMediaQuery} from "@mui/material";

const RulesModal = ({
  open,
  onClose,
  handleSubmit,
  handleRulesSubmit,
  editAIRule,
  setEditAIRule,
  selectedRuleId,
}) => {
  const [editData, setEditData] = useState(null);
  const [rulesData, setRulesData] = useState([]);
  const [nameError, setNameError] = useState("");
  const [dateError, setDateError] = useState("");
  const [Agents, setAgents] = useState(null);
  const [ruleData, setRuleData] = useState({
    name: "",
    condition: "",
    action: "",
    ai_agent: "",
    sp_para: "",
    from_date: "",
    to_date: "",
    start_time: "",
    end_time: "",
    active_days: {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    },
    notification: [],
  });
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const conditionOptions = ["Time of Day", "Language", "Custom"];
  const actionOptions = ["Answer Call", "Transfer Call", "Record Call"];
  const aiAgentOptions = [
    "Conversational AI",
    "Virtual agents",
    "AI assistants",
    "AI CSAT",
    "Intelligent call routing",
  ];
  const notificationOptions = ["Email", "Text"];

  const rows = {
    rule_id: 12,
    name: "Rule 1",
    condition: "Condition 1",
    action: "Action 1",
    ai_agent: "Agent A",
    sp_para: "Parameter A",
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setRuleData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  //Name duplicate check
  useEffect(() => {
    const fetchRules = async () => {
      const data = await RulesService.getRules();
      setRulesData(data?.data?.call_handling_rules || []);
    };

    fetchRules();
  }, []);

  const validateRuleName = (name) => {
    // Check if the name already exists
    const isNameExists = rulesData.some(
      (rule) => rule.name.toLowerCase() === name.toLowerCase()
    );
    if (isNameExists) {
      setNameError("Rule name already exists");
      return false;
    }

    setNameError(""); // Clear error if name is valid
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (editAIRule) {
      setEditData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      setRuleData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleNotificationChange = (event) => {
    const { value } = event.target;

    // Ensure value is always an array
    const updatedNotifications =
      typeof value === "string" ? value.split(",") : value;

    if (editAIRule) {
      setEditData((prevData) => ({
        ...prevData,
        notification: updatedNotifications,
      }));
    } else {
      setRuleData((prevData) => ({
        ...prevData,
        notification: updatedNotifications,
      }));
    }
  };

  const areAllSelected = Object.values(ruleData?.active_days || {}).every(Boolean);

  const handleSelectAll = () => {
    const newActiveDays = Object.keys(ruleData?.active_days || {}).reduce((acc, day) => {
      acc[day] = !areAllSelected; // Toggle all checkboxes
      return acc;
    }, {});
  
    setRuleData((prev) => ({
      ...prev,
      active_days: newActiveDays,
    }));
  };

  const handleDayChange = (day) => {
    setRuleData((prevData) => ({
      ...prevData,
      active_days: {
        ...prevData.active_days,
        [day]: !prevData.active_days[day],
      },
    }));
  };

  const handleDateChange = (name, value) => {
    if (name === "to_date") {
      const fromDate = editAIRule ? editData?.from_date : ruleData.from_date;
      if (value < fromDate) {
        setDateError("To Date cannot be earlier than From Date");
        return;
      } else {
        setDateError(""); // Clear error if the value is valid
      }
    }

    // Update the state based on the context
    if (editAIRule) {
      setEditData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      setRuleData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (open && editAIRule) {
      const fetchRuleData = async () => {
        try {
          const data = await RulesService.getRuleById(selectedRuleId);
          setEditData(data?.data);
        } catch (error) {
          console.error("Error fetching agent data:", error);
        }
      };

      fetchRuleData();
    }
  }, [open, setRuleData]);

  useEffect(() => {
    if (editData) {
      // Initialize active_days as false for all days
      const initialActiveDays = {
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
      };

      // Safeguard for undefined active_days
      if (editData.active_days) {
        editData.active_days.forEach((day) => {
          initialActiveDays[day] = true;
        });
      }

      // Update ruleData with editData values
      setRuleData({
        name: editData.name,
        condition: editData.condition,
        action: editData.action,
        ai_agent: editData.ai_agent,
        sp_para: editData.sp_para,
        from_date: editData.from_date,
        to_date: editData.to_date,
        start_time: editData.start_time,
        end_time: editData.end_time,
        active_days: initialActiveDays,
        notification: editData.notification || [],
      });
    }
  }, [editData]);

  const handleSubmitData = () => {
    const isNameValid = validateRuleName(ruleData.name);
    if (!editAIRule && !isNameValid) return;

    if (editAIRule) {
      //const updatedEditData = { ...editData, status: agentData.status };

      handleSubmit(ruleData);
      setEditData(null);
      setRuleData("");
    } else {
      handleSubmit(ruleData);
    }
    setEditData(false);

    onClose();
  };

  useEffect(() => {
    if (open) {
      (async () => {
        const data = await AgentsService.getAgents();
        setAgents(data?.data?.ai_agent);
      })();
    }
  }, [open]);

  useEffect(() => {
    const isAllFieldsFilled =
      ruleData.name &&
      ruleData.condition &&
      ruleData.notification &&
      ruleData.action &&
      ruleData.ai_agent &&
      ruleData.from_date &&
      ruleData.to_date &&
      ruleData.start_time &&
      ruleData.end_time &&
      Object.values(ruleData.active_days).some((day) => day);

    setIsButtonEnabled(isAllFieldsFilled);
  }, [ruleData]);

  useEffect(() => {
    if (open) {
      setRuleData({
        name: "",
        condition: "",
        action: "",
        ai_agent: "",
        sp_para: "",
        from_date: null,
        to_date: null,
        start_time: null,
        end_time: null,
        active_days: {
          Monday: false,
          Tuesday: false,
          Wednesday: false,
          Thursday: false,
          Friday: false,
          Saturday: false,
          Sunday: false,
        },
        notification: [],
      });
    }
  }, [open]);

  const handleClose = () => {
    setEditAIRule(false);
    onClose();
    setNameError("");
    setDateError("");
  };

  console.log("nameError", nameError);
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          //width: 580,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          maxHeight: isMobile ? "90%" : "100%",
          overflowY: isMobile ? "auto" : "none",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "grey.500",
          }}
        >
          <CloseIcon />
        </IconButton>
        {editAIRule ? (
          <h2>Edit Call Handling Rule</h2>
        ) : (
          <h2>Create Call Handling Rule</h2>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={
                <>
                  {"Rule Name"}
                  <Typography
                    component="span"
                    color="error"
                    style={{ marginLeft: 4 }}
                  >
                    *
                  </Typography>
                </>
              }
              name="name"
              value={editAIRule == false ? ruleData.name : editData?.name}
              onChange={handleChange}
              placeholder="Enter rule name"
              variant="outlined"
              InputLabelProps={editAIRule ? { shrink: true } : ""}
              onBlur={() =>
                validateRuleName(editAIRule == false ? ruleData.name : "")
              }
              error={editAIRule == false ? Boolean(nameError) : ""}
              helperText={editAIRule == false ? nameError : ""}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              select
              label={
                <>
                  {"Condition"}
                  <Typography
                    component="span"
                    color="error"
                    style={{ marginLeft: 4 }}
                  >
                    *
                  </Typography>
                </>
              }
              name="condition"
              value={
                editAIRule == false
                  ? ruleData.condition
                  : editData?.condition || ruleData.condition
              }
              onChange={handleChange}
              variant="outlined"
            >
              {conditionOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              select
              label={
                <>
                  {"Action"}
                  <Typography
                    component="span"
                    color="error"
                    style={{ marginLeft: 4 }}
                  >
                    *
                  </Typography>
                </>
              }
              name="action"
              value={
                editAIRule == false
                  ? ruleData.action
                  : editData?.action || ruleData.action
              }
              onChange={handleChange}
              variant="outlined"
            >
              {actionOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              select
              label={
                <>
                  {"Agent"}
                  <Typography
                    component="span"
                    color="error"
                    style={{ marginLeft: 4 }}
                  >
                    *
                  </Typography>
                </>
              }
              name="ai_agent"
              value={
                editAIRule == false
                  ? ruleData.ai_agent
                  : editData?.ai_agent || ruleData.ai_agent
              }
              onChange={handleChange}
              variant="outlined"
            >
              {Agents?.map((option) => (
                <MenuItem key={option.id} value={option.agent_name}>
                  {option.agent_name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={6}>
              <DatePicker
                required
                label={
                  <>
                    {"From Date"}
                    <Typography
                      component="span"
                      color="error"
                      style={{ marginLeft: 4 }}
                    >
                      *
                    </Typography>
                  </>
                }
                value={
                  editAIRule == false
                    ? ruleData.from_date
                    : editData?.from_date || ruleData.from_date
                }
                onChange={(newValue) => handleDateChange("from_date", newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
              {dateError && (
                <Typography color="error" variant="caption">
                  {dateError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                required
                label={
                  <>
                    {"To Date"}
                    <Typography
                      component="span"
                      color="error"
                      style={{ marginLeft: 4 }}
                    >
                      *
                    </Typography>
                  </>
                }
                value={
                  editAIRule == false
                    ? ruleData.to_date
                    : editData?.to_date || ruleData.to_date
                }
                onChange={(newValue) => handleDateChange("to_date", newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={6}>
              <TimePicker
                required
                label={
                  <>
                    {"Start Time"}
                    <Typography
                      component="span"
                      color="error"
                      style={{ marginLeft: 4 }}
                    >
                      *
                    </Typography>
                  </>
                }
                value={
                  editAIRule == false
                    ? ruleData.start_time
                    : editData?.start_time || ruleData.start_time
                }
                onChange={(newValue) =>
                  handleDateChange("start_time", newValue)
                }
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={6}>
              <TimePicker
                required
                label={
                  <>
                    {"End Time"}
                    <Typography
                      component="span"
                      color="error"
                      style={{ marginLeft: 4 }}
                    >
                      *
                    </Typography>
                  </>
                }
                value={
                  editAIRule == false
                    ? ruleData.end_time
                    : editData?.end_time || ruleData.end_time
                }
                onChange={(newValue) => handleDateChange("end_time", newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
          </LocalizationProvider>

          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ fontWeight: "600" }}>
              Select Active Days <span style={{ color: "#cd4949" }}>*</span>
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={areAllSelected}
                  size="small"
                  onChange={handleSelectAll}
                />
              }
              label={<Typography variant="body2">Select All</Typography>}
            />
            <Grid container spacing={1}>
              {Object.keys(ruleData?.active_days || {}).map((day) => (
                <Grid item key={day}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ruleData?.active_days[day]}
                        size="small"
                        onChange={() => handleDayChange(day)}
                      />
                    }
                    label={<Typography variant="body2">{day}</Typography>}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Specific Parameters (if applicable)"
              name="sp_para"
              value={
                editAIRule == false
                  ? ruleData.sp_para
                  : editData?.sp_para || ruleData.sp_para
              }
              onChange={handleChange}
              placeholder="e.g., 9 AM - 5 PM, English"
              variant="outlined"
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              select
              label={
                <>
                  { "Notification" }
                  <Typography component="span" color="error" style={{ marginLeft: 4 }}>
                    *
                  </Typography>
                </>}
              name="notification"
              value={editAIRule == false ?  ruleData.notification : editData?.notification || ruleData.notification}
              onChange={handleChange}
              variant="outlined"
            >
              {notificationOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid> */}

          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              sx={{
                margin: 0,
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <div id="notification-select-label">Notification</div>
              <Select
                labelId="notification-select-label"
                multiple
                sx={{
                  width: "210px", // Sets the Select width
                }}
                value={
                  Array.isArray(
                    editAIRule === false
                      ? ruleData?.notification
                      : editData?.notification || ruleData?.notification
                  )
                    ? editAIRule === false
                      ? ruleData?.notification
                      : editData?.notification || ruleData?.notification
                    : []
                }
                onChange={handleNotificationChange}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {notificationOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    <Checkbox
                      checked={(Array.isArray(
                        editAIRule === false
                          ? ruleData?.notification
                          : editData?.notification || ruleData?.notification
                      )
                        ? editAIRule === false
                          ? ruleData?.notification
                          : editData?.notification || ruleData?.notification
                        : []
                      ).includes(option)}
                    />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Tooltip
              title={!isButtonEnabled ? "Please fill the required fields" : ""}
            >
              <span>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  // onClick={() => {
                  //   handleSubmit(ruleData);
                  //   handleRulesSubmit(ruleData);
                  //   onClose();
                  // }}
                  onClick={handleSubmitData}
                  disabled={!isButtonEnabled}
                >
                  {editAIRule ? "Update Rule" : "Create Rule"}
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default RulesModal;
