import cn from "classnames";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ANS_TYPE, LINKS } from "../../../../Common/Constants";
import Swal from "sweetalert2";

import NewRecommendations from "./GetNewRecommendations";
import FinalizeCategories from "./FinalizeCategories";
import AiRecommendations from "./AiRecommendations";
import { surveyActions } from "../../../../../actions";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { swalAlert } from "../../../../Common/SwalAlert";
import { history } from "../../../../../helpers";

const Tags = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [categoryFilter, setCategoryFilter] = useState("");
  const filterCategory = (filter) => {
    setCategoryFilter(filter);
  };
  const prompt = useSelector((state) => state.survey.prompt);
  console.log("dsmdsdsm", prompt);
  const getFormattedLabel = (val) => {
    let label = "";
    ANS_TYPE.forEach((e) => {
      if (e.value === val) {
        label = e.label;
      }
    });
    return label;
  };
  const [questionId, setQuestionId] = useState(null);
  const updateComboCategories = useSelector(
    (state) => state.survey.updateComboCategories
  );

  const categorizationQuestionId = useSelector(
    (state) => state.survey.categorizationQuestionId
  );

  const sentimentFilter = useSelector((state) => state.survey.sentimentFilter);
  const [search, setSearch] = useState("");
  const updateSearch = (text) => {
    setSearch(text);
  };
  useEffect(() => {
    setQuestionId(parseInt(categorizationQuestionId));
  }, [categorizationQuestionId]);
  const [page, setPage] = useState(1);
  const categoryInsightsByQuestion = useSelector(
    (state) => state.survey.categoryInsightsByQuestion
  );

  const getCustomCategory = useSelector(
    (state) => state.survey.getCustomCategory
  );
  useEffect(() => {
    if (questionId !== null)
      dispatch(surveyActions.getCategoryInsightsByQuestion(id, questionId));
  }, [questionId]);
  const [categorizationType, setCategorizationType] = useState(
    "getNewRecommendations"
  );
  const questionData = useSelector(
    (state) => state.survey.questionAnalysisData
  );

  const answerData = useSelector((state) => state.survey.answerData);

  const [question, setQuestion] = useState([]);
  const [questionType, setQuestionType] = useState("");
  // const answerData = useSelector((state) => state.survey.answerData);

  const percentage = 60;
  useEffect(() => {
    if (questionId !== null) {
      if (categorizationType === "finalizeCategories") {
        if (categoryFilter.length > 0) {
          let filterArray = categoryFilter.map((e) => {
            return e.value;
          });

          dispatch(
            surveyActions.getAnswerData(
              questionId,
              id,
              page,
              search,
              filterArray
            )
          );
        } else {
          dispatch(surveyActions.getAnswerData(questionId, id, page, search));
        }
      } else {
        dispatch(surveyActions.getAnswerData(questionId, id, page, ""));
      }
    }
  }, [
    questionId,
    page,
    sentimentFilter,
    categorizationType,
    search,
    updateComboCategories,
    categoryFilter,
  ]);

  useEffect(() => {
    if (questionId !== null)
      dispatch(surveyActions.getCustomCategory(id, questionId));
  }, [questionId, id]);
  useEffect(() => {
    return () => {
      dispatch(surveyActions.setActiveMenu("Tags"));
    };
  }, []);
  useEffect(() => {
    let ques = [];

    questionData?.long?.forEach((e) => {
      ques.push(e);
    });
    // questionData.others.forEach((e) => {
    //   ques.push(e);
    // });
    setQuestion(ques);
    if (ques.length > 0) {
      setQuestionType(ques[0]["question_type"]);
      // setQuestionId(ques[0].qus_id);
    }
  }, [questionData]);

  const setPageNumber = (pageNumber) => {
    setPage(pageNumber);
  };
  const removeZeroesFromDecimal = (text) => {
    return Number.parseFloat(text.toFixed(2));
  };
  return (
    <>
      {" "}
      <div className="getNewRecommendationsPage">
        <div className="customStyle">
          <div className="row">
            <div className="col-md-8 col-sm-12">
              <div className="row">
                <div className="col-md-7 col-sm-12">
                  <div className={cn("analyseCard")}>
                    <div className="item">
                      {/* <select
                        value={questionId}
                        onChange={(e) => {
                          let index = question.findIndex(
                            (x) => x.qus_id === parseInt(e.target.value)
                          );
                          setQuestionId(parseInt(e.target.value));

                          setQuestionType(question[index].question_type);
                        }}
                        className="select-question"
                      >
                        {question && question.length > 0
                          ? question.map((value, index) => {
                              return (
                                <option value={value.qus_id}>
                                  {value.question}
                                </option>
                              );
                            })
                          : null}
                      </select> */}
                      <Box
                        sx={{ maxWidth: 485, maxHeight: 55 }}
                        className="analysisCustomSelectBox"
                      >
                        <FormControl fullWidth>
                          <Select
                            value={questionId || ""}
                            onChange={(e) => {
                              let index = question.findIndex(
                                (x) => x.qus_id === parseInt(e.target.value)
                              );
                              setQuestionId(parseInt(e.target.value));

                              setQuestionType(question[index].question_type);
                            }}
                            label=""
                            className="analysisCustomSelect"
                            MenuProps={{
                              classes: { paper: "selectPaperRoot" },
                            }}
                          >
                            {question && question.length > 0
                              ? question.map((value, index) => {
                                  return (
                                    <MenuItem
                                      key={index}
                                      value={value.qus_id}
                                      className="analysisMenuItem"
                                    >
                                      {value.question}
                                    </MenuItem>
                                  );
                                })
                              : null}
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 col-sm-12">
                  <div className={cn("analyseCard", "analyseQuestionType")}>
                    <span className="text-capitalize">
                      {questionType ? getFormattedLabel(questionType) : ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card common-space">
              <div className="title">Building Insights</div>
              <div className="progress-box-list">
                <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <div
                      onClick={() => {
                        if (!prompt) {
                          dispatch(surveyActions.setShowPrompt(false));
                          setCategorizationType("aiRecommendations");
                        }
                        if (
                          prompt &&
                          categorizationType === "finalizeCategories"
                        ) {
                          Swal.fire({
                            title:
                              "Assigned Categories will get removed if not saved!",
                            text: "Do you still want to continue?",
                            showDenyButton: true,
                            showCancelButton: true,
                            icon: "warning",

                            confirmButtonText: "Yes",
                            denyButtonText: "No",
                            customClass: {
                              actions: "my-actions",
                              cancelButton: "order-1 right-gap",
                              confirmButton: "order-2",
                              denyButton: "order-3",
                            },
                          }).then((result) => {
                            if (result.isConfirmed) {
                              dispatch(surveyActions.setShowPrompt(false));
                              setCategorizationType("aiRecommendations");
                            } else if (result.isDenied) {
                            }
                          });
                        }
                        if (
                          prompt &&
                          categorizationType === "getNewRecommendations"
                        ) {
                          dispatch(surveyActions.setShowPrompt(false));
                          setCategorizationType("aiRecommendations");
                        }
                      }}
                      className="progressBarStyles"
                    >
                      <CircularProgressbar
                        value={
                          categoryInsightsByQuestion &&
                          categoryInsightsByQuestion.data &&
                          categoryInsightsByQuestion.data
                            .ai_recommended_categories_percent &&
                          removeZeroesFromDecimal(
                            categoryInsightsByQuestion.data
                              .ai_recommended_categories_percent
                          )
                        }
                        strokeWidth={5}
                        text={`${
                          categoryInsightsByQuestion &&
                          categoryInsightsByQuestion.data &&
                          categoryInsightsByQuestion.data
                            .ai_recommended_categories_percent &&
                          removeZeroesFromDecimal(
                            categoryInsightsByQuestion.data
                              .ai_recommended_categories_percent
                          )
                        }%`}
                        styles={buildStyles({
                          pathColor: "green",
                        })}
                      />
                    </div>
                    <div className="mt-2">
                      <span className="insightTitle">AI Recommendations</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div
                      onClick={() => {
                        if (!prompt) {
                          dispatch(surveyActions.setShowPrompt(false));

                          setCategorizationType("finalizeCategories");
                        }
                        if (
                          prompt &&
                          categorizationType === "getNewRecommendations"
                        ) {
                          dispatch(surveyActions.setShowPrompt(false));

                          setCategorizationType("finalizeCategories");
                        }

                        if (
                          prompt &&
                          categorizationType === "aiRecommendations"
                        ) {
                          Swal.fire({
                            title:
                              "Assigned Categories will get removed if not saved!",
                            text: "Do you still want to continue?",
                            showDenyButton: true,
                            showCancelButton: true,
                            icon: "warning",

                            confirmButtonText: "Yes",
                            denyButtonText: "No",
                            customClass: {
                              actions: "my-actions",
                              cancelButton: "order-1 right-gap",
                              confirmButton: "order-2",
                              denyButton: "order-3",
                            },
                          }).then((result) => {
                            if (result.isConfirmed) {
                              dispatch(surveyActions.setShowPrompt(false));

                              setCategorizationType("finalizeCategories");
                            } else if (result.isDenied) {
                            }
                          });
                        }
                      }}
                      className="progressBarStyles"
                    >
                      <CircularProgressbar
                        value={
                          categoryInsightsByQuestion &&
                          categoryInsightsByQuestion.data &&
                          categoryInsightsByQuestion.data
                            .categorized_data_percent &&
                          removeZeroesFromDecimal(
                            categoryInsightsByQuestion.data
                              .categorized_data_percent
                          )
                        }
                        strokeWidth={5}
                        text={`${
                          categoryInsightsByQuestion &&
                          categoryInsightsByQuestion.data &&
                          categoryInsightsByQuestion.data
                            .categorized_data_percent &&
                          removeZeroesFromDecimal(
                            categoryInsightsByQuestion.data
                              .categorized_data_percent
                          )
                        }%`}
                        styles={buildStyles({
                          pathColor: "green",
                        })}
                      />
                    </div>
                    <div className="mt-2">
                      <span className="insightTitle">Answers Categorized</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div
                      onClick={() => {
                        if (!prompt) {
                          dispatch(surveyActions.setShowPrompt(false));

                          setCategorizationType("getNewRecommendations");
                        }
                        if (
                          prompt &&
                          categorizationType === "aiRecommendations"
                        ) {
                          Swal.fire({
                            title:
                              "Assigned Categories will get removed if not saved!",
                            text: "Do you still want to continue?",
                            showDenyButton: true,
                            showCancelButton: true,
                            icon: "warning",

                            confirmButtonText: "Yes",
                            denyButtonText: "No",
                            customClass: {
                              actions: "my-actions",
                              cancelButton: "order-1 right-gap",
                              confirmButton: "order-2",
                              denyButton: "order-3",
                            },
                          }).then((result) => {
                            if (result.isConfirmed) {
                              dispatch(surveyActions.setShowPrompt(false));

                              setCategorizationType("getNewRecommendations");
                            } else if (result.isDenied) {
                            }
                          });
                        }

                        if (
                          prompt &&
                          categorizationType === "finalizeCategories"
                        ) {
                          Swal.fire({
                            title:
                              "Assigned Categories will get removed if not saved!",
                            text: "Do you still want to continue?",
                            showDenyButton: true,
                            showCancelButton: true,
                            icon: "warning",

                            confirmButtonText: "Yes",
                            denyButtonText: "No",
                            customClass: {
                              actions: "my-actions",
                              cancelButton: "order-1 right-gap",
                              confirmButton: "order-2",
                              denyButton: "order-3",
                            },
                          }).then((result) => {
                            if (result.isConfirmed) {
                              dispatch(surveyActions.setShowPrompt(false));

                              setCategorizationType("getNewRecommendations");
                            } else if (result.isDenied) {
                            }
                          });
                        }
                      }}
                      className="progressBarStyles"
                    >
                      <CircularProgressbar
                        value={
                          categoryInsightsByQuestion &&
                          categoryInsightsByQuestion.data &&
                          categoryInsightsByQuestion.data.new_answers_percent &&
                          removeZeroesFromDecimal(
                            categoryInsightsByQuestion.data.new_answers_percent
                          )
                        }
                        strokeWidth={5}
                        text={`${
                          categoryInsightsByQuestion &&
                          categoryInsightsByQuestion.data &&
                          categoryInsightsByQuestion.data.new_answers_percent &&
                          removeZeroesFromDecimal(
                            categoryInsightsByQuestion.data.new_answers_percent
                          )
                        }%`}
                        styles={buildStyles({
                          pathColor: "green",
                        })}
                      />
                    </div>
                    <div className="mt-2">
                      <span className="insightTitle">New Answers</span>
                      <span>
                        {categoryInsightsByQuestion &&
                          categoryInsightsByQuestion.data &&
                          categoryInsightsByQuestion.data.new_answers}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="cart-tabs-wrapper">
                <div className="tab-link">
                  <span
                    onClick={() => {
                      if (!prompt) {
                        dispatch(surveyActions.setShowPrompt(false));

                        dispatch(surveyActions.setSentimentFilter([]));
                        setPageNumber(1);

                        setCategorizationType("getNewRecommendations");
                      }
                      if (
                        prompt &&
                        categorizationType === "finalizeCategories"
                      ) {
                        Swal.fire({
                          title:
                            "Assigned Categories will get removed if not saved!",
                          text: "Do you still want to continue?",
                          showDenyButton: true,
                          showCancelButton: true,
                          icon: "warning",

                          confirmButtonText: "Yes",
                          denyButtonText: "No",
                          customClass: {
                            actions: "my-actions",
                            cancelButton: "order-1 right-gap",
                            confirmButton: "order-2",
                            denyButton: "order-3",
                          },
                        }).then((result) => {
                          if (result.isConfirmed) {
                            dispatch(surveyActions.setShowPrompt(false));

                            dispatch(surveyActions.setSentimentFilter([]));
                            setPageNumber(1);

                            setCategorizationType("getNewRecommendations");
                          } else if (result.isDenied) {
                          }
                        });
                      }
                      if (
                        prompt &&
                        categorizationType === "aiRecommendations"
                      ) {
                        Swal.fire({
                          title:
                            "Assigned Categories will get removed if not saved!",
                          text: "Do you still want to continue?",
                          showDenyButton: true,
                          showCancelButton: true,
                          icon: "warning",

                          confirmButtonText: "Yes",
                          denyButtonText: "No",
                          customClass: {
                            actions: "my-actions",
                            cancelButton: "order-1 right-gap",
                            confirmButton: "order-2",
                            denyButton: "order-3",
                          },
                        }).then((result) => {
                          if (result.isConfirmed) {
                            dispatch(surveyActions.setShowPrompt(false));

                            dispatch(surveyActions.setSentimentFilter([]));
                            setPageNumber(1);

                            setCategorizationType("getNewRecommendations");
                          } else if (result.isDenied) {
                          }
                        });
                      }
                    }}
                    className={
                      categorizationType === "getNewRecommendations"
                        ? "insightSpan"
                        : "lightSpan linkButton"
                    }
                  >
                    Get New Recommendations
                  </span>
                </div>
                <div className="tab-link">
                  <span
                    onClick={() => {
                      if (!prompt) {
                        dispatch(surveyActions.setShowPrompt(false));

                        setCategorizationType("aiRecommendations");
                      }
                      if (
                        prompt &&
                        categorizationType === "finalizeCategories"
                      ) {
                        Swal.fire({
                          title:
                            "Assigned Categories will get removed if not saved!",
                          text: "Do you still want to continue?",
                          showDenyButton: true,
                          showCancelButton: true,
                          icon: "warning",

                          confirmButtonText: "Yes",
                          denyButtonText: "No",
                          customClass: {
                            actions: "my-actions",
                            cancelButton: "order-1 right-gap",
                            confirmButton: "order-2",
                            denyButton: "order-3",
                          },
                        }).then((result) => {
                          if (result.isConfirmed) {
                            dispatch(surveyActions.setShowPrompt(false));

                            setCategorizationType("aiRecommendations");
                          } else if (result.isDenied) {
                          }
                        });
                      }

                      if (
                        prompt &&
                        categorizationType === "getNewRecommendations"
                      ) {
                        dispatch(surveyActions.setShowPrompt(false));

                        setCategorizationType("aiRecommendations");
                      }
                    }}
                    // className="lightSpan ml-5"
                    className={
                      categorizationType === "aiRecommendations"
                        ? "insightSpan"
                        : "lightSpan linkButton"
                    }
                  >
                    AI Recommendations
                  </span>
                </div>
                <div className="tab-link">
                  <span
                    onClick={() => {
                      if (!prompt) {
                        dispatch(surveyActions.setShowPrompt(false));

                        dispatch(surveyActions.setSentimentFilter([]));
                        setPageNumber(1);
                        setCategorizationType("finalizeCategories");
                      }
                      if (
                        prompt &&
                        categorizationType === "aiRecommendations"
                      ) {
                        Swal.fire({
                          title:
                            "Assigned Categories will get removed if not saved!",
                          text: "Do you still want to continue?",
                          showDenyButton: true,
                          showCancelButton: true,
                          icon: "warning",

                          confirmButtonText: "Yes",
                          denyButtonText: "No",
                          customClass: {
                            actions: "my-actions",
                            cancelButton: "order-1 right-gap",
                            confirmButton: "order-2",
                            denyButton: "order-3",
                          },
                        }).then((result) => {
                          if (result.isConfirmed) {
                            dispatch(surveyActions.setShowPrompt(false));

                            dispatch(surveyActions.setSentimentFilter([]));
                            setPageNumber(1);
                            setCategorizationType("finalizeCategories");
                          } else if (result.isDenied) {
                          }
                        });
                      }

                      if (
                        prompt &&
                        categorizationType === "getNewRecommendations"
                      ) {
                        dispatch(surveyActions.setShowPrompt(false));

                        dispatch(surveyActions.setSentimentFilter([]));
                        setPageNumber(1);
                        setCategorizationType("finalizeCategories");
                      }
                    }}
                    // className="lightSpan "
                    className={
                      categorizationType === "finalizeCategories"
                        ? "insightSpan"
                        : "lightSpan linkButton"
                    }
                  >
                    Finalize Categories
                  </span>
                </div>
              </div>
              {categorizationType === "getNewRecommendations" ? (
                <NewRecommendations
                  answerData={answerData}
                  page={page}
                  setPageNumber={setPageNumber}
                  questionId={questionId}
                  customCategory={getCustomCategory}
                />
              ) : null}
              {categorizationType === "aiRecommendations" ? (
                <AiRecommendations
                  updateSearch={updateSearch}
                  questionId={questionId}
                />
              ) : null}
              {categorizationType === "finalizeCategories" ? (
                <FinalizeCategories
                  id={id}
                  page={page}
                  questionId={questionId}
                  setPageNumber={setPageNumber}
                  updateSearch={updateSearch}
                  category={categoryFilter}
                  filterCategory={filterCategory}
                />
              ) : null}
              <span onClick={()=>history.push(LINKS.ai_models)} style={{padding: '0 0 20px 20px', color:"#18A0FB", cursor:'pointer'}}> Learn more, how to create customized AI Models?</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tags;
