import React from "react";
import ComposeByGmail from "./ComposeByGmail";
import ComposeByOutlook from "./ComposeByOutlook";
import { getUser } from "../../../../helpers";

const Compose = (props) => {
  console.log(props, "<==props");
  const usr = getUser();
  // if (usr && usr.social_type === "gmail") {
  // //   console.log(usr, "<===usr usr");
  // //   console.log(usr.email, "<===email");
  // return (
  //   <ComposeByGmail {...props} emailType={usr.social_type} email={usr.email} usr={usr.social_type} />
  // );
  // } else {
  //   return <ComposeByOutlook {...props} emailType={usr.social_type} />;
  // }

  if (usr) {
    if (usr.social_type === "gmail") {
      return (
        <ComposeByGmail
          {...props}
          emailType={usr.social_type}
          email={usr.email}
          usr={usr.social_type}
        />
      );
    } else if (usr.social_type === "outlook") {
      return (
        <ComposeByOutlook
          {...props}
          emailType={usr.social_type}
        />
      );
    }
  }
};

export default Compose;
