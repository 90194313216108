import React, { useState } from "react";
import { Box, Typography, IconButton, List, ListItemText, TextField, Card, CardContent, Avatar, useTheme } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";

const agents = [
  { id: 1, text: "AI Agent", icon: "🤖", bgColor: "#B0C4DE" },
  { id: 2, text: "Appointment Agent", icon: "📅", bgColor: "#A0D6B4" },
  { id: 3, text: "Email Agent", icon: "📧", bgColor: "#C0C0A0" },
  { id: 4, text: "Support Agent", icon: "🛠️", bgColor: "#FFD700" },
  { id: 5, text: "Sales Agent", icon: "💰", bgColor: "#FFA07A" },
];

const AgentsList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const theme = useTheme();

  const filteredAgents = agents.filter((agent) =>
    agent.text.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ width: 300, bgcolor: "white", p: 2, borderRight: "1px solid #ccc", height: "100vh", overflowY: "auto" }}>
      {/* Header */}
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Agents List
        </Typography>
        <IconButton size="small" sx={{ color: theme.palette.primary.main }}>
          <AddCircleOutlineIcon fontSize="small" />
        </IconButton>
      </Box>

      {/* Search Bar */}
      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search agents..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon sx={{ color: theme.palette.text.secondary, mr: 1 }} />,
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "25px",
            },
          }}
        />
      </Box>

      {/* Agents List */}
      <List>
        {filteredAgents.map((agent) => (
          <Card
            key={agent.id}
            sx={{
              mb: 2,
              borderRadius: "10px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.2s, box-shadow 0.2s",
              "&:hover": {
                transform: "translateY(-2px)",
                boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
              },
            }}
          >
            <CardContent sx={{ display: "flex", alignItems: "center", p: 2 }}>
              <Avatar sx={{ bgcolor: agent.bgColor, mr: 2, width: 40, height: 40 }}>
                {agent.icon}
              </Avatar>
              <ListItemText
                primary={agent.text}
                secondary={`ID: ${agent.id}`}
                sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
              />
            </CardContent>
          </Card>
        ))}
      </List>
    </Box>
  );
};

export default AgentsList;