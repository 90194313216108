import React, {useEffect, useState} from "react";
import {base64Image} from '../../../Common/Utils';
import PopUp from '../../../Common/ConfirmationPrompt';


const fileIcon = origin+"/images/file-icon.jpg";
const fileSize = 2000000;
const FileUpload = (props) =>{
    const {layout,children, qus_id, question, desc="", qno=1, srNo=1, settings={}, cardRef, quesType, onChange, qRefs, submit, btnText='Next', sliderView, onFocus} = props;
    const { font, underline, bold, fontSize, italic, fontColor, require,allowParsing } = settings,
        style = { fontFamily: font, fontSize, fontWeight: bold ? 'bold' : '', fontStyle: italic ? 'italic' : '', textDecoration: underline ? 'underline' : '', color: fontColor };

    const [uploadedFile, setFile] = useState("");
    const [isImage, setFileType] = useState(false);
    const [fileName, setFileName] = useState('');
    const [err, setErr] = useState('');

    useEffect(()=> {
        const elements = document.getElementsByName("question_"+qno);
        let ele = elements.length ? elements[0] : elements;
        ele.style.setProperty('font-family', font, 'important');
    }, [font]);

    const onSelect = (e) => {
        if(e?.target?.files?.length ){
            const file = e.target.files[0];
            let fileExt = file.name.split('.');
            let allowExt = allowParsing?["pdf","docx","png", "jpg", "jpeg"]:["pdf","docx"]
            console.log("aa",file)
            if(allowExt.includes(fileExt[1])){
                if(file.size <= fileSize) {
                base64Image(file, (result) => {
                    setErr(false);
                    setFileType(file ?.type.includes('image'));
                    setFileName(file.name);
                    setFile(result);
                    saveChanges(file.name, result);
                });
            } else {
                setErr('File size should not exceed 2MB')
            }
        }
    else{
        const msg = allowParsing?"pdf, docx, jpg or png file":"pdf or docx file";
        setErr('Use only ' + msg )
    }
        }
    };

    const triggerFocus = () => {
        onFocus && qno > 0 && onFocus(qno-1);
    };

    const onNext = () => {
        if(btnText === 'Next'){
            cardRef && cardRef.current.next();
            (qRefs?.current?.length >=qno) && setTimeout(() => { qRefs.current[qno]?.focus() }, 10);
        }else{
            submit && submit();
        }
    };

    const saveChanges = (fName, fVal ) => {
        console.log("",uploadedFile)
        if(require && fVal && fVal === ''){
            setErr('This is required.');
            return;
        }
        if(fVal){
            let obj = {
                "qus_id": qus_id,
                "q_type":quesType,
                "qus_text": question,
                "answers": [{id: fName, text: fVal}]
            };
            onChange && onChange({name: qno, value:obj});
        }
        setErr('');
    };

    const onClick = (event) => {
        event.currentTarget.value = null;
    };

    return(
        <div className="imageWrapInner">
            <div className="ques">
            <h3 name={"question_"+qno} style={style}>{srNo}-{question}
                <span style={{color:"red", marginLeft:'5px',display: require?'unset':'none'}}>*</span>
            </h3>
                <em> {desc} </em>
            </div>
            {layout == 1 && children}

            {uploadedFile.length ? <div className="imageUpload uploaded" >
                <div className="inner">
                    <img src={isImage ? uploadedFile : fileIcon} alt="arrow icon"/>
                    <input type="file" onChange={onSelect}/>
                </div>
                <div style={{textAlign:'center'}}> {fileName} </div>
            </div> : null}

            <div className="fileUploadInner" ref={ (r)=>qRefs && (qRefs.current[qno-1] = r) } tabIndex={-1}>
                {uploadedFile.length ? null :
                    <div className="fileUpload">
                        <img className={"uploadIcon"} src={origin+"/images/SVG/upload.svg"} alt="upload icon"/>
                        <input type="file" onChange={onSelect} onFocus={triggerFocus} onClick={onClick}/>
                    </div>
                }
                <h4>Choose your file or drag here </h4>
                <p>Size limit: 2MB</p>
            </div>

            { sliderView ? <div className="btnGroup">
                <button className="btnSave" onClick={onNext}>{btnText}</button>
            </div> : null}
            <PopUp show={err.length} message={err} handler={()=> setErr("")} singleBtn={true} btnLabel="OK"/>
        </div>
    )
};

export default FileUpload;
