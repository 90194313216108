import React, { useState, useEffect, useRef } from "react";
import { Header, SubHeaderSurvey, MainMenu } from "../../Common/Header";
import SurveySubHeader from "../Components/SurveySubHeader";
import {
  SURVEY_SETTINGS_MENU,
  ANS_TYPE,
  ANALYSE_RESULTS_MENU,
} from "../../Common/Constants";
import { Appearance, QuestionsQueue } from "./Common/Component";
import Format from "./Format";
import LeftMenu from "./LeftMenu";
import Question from "./Question";
import { QuestionBankSetting } from "./QuestionDialog";
import { clone } from "../../Common/Utils";

import { useDispatch, useSelector } from "react-redux";
import { workspaceActions } from "../../../actions/index";
import AlertOption from "./SurveyOption";
import Backdrop from "../../Common/Backdrop";
import Prompt from "../../Common/ConfirmationPrompt";
const DefaultQuestion = {
  question: "",
  q_type: ANS_TYPE[0].value,
  settings: { layout: 4 },
};

const NewCreateSurvey = (props) => {
  //const surveyID = Number(props?.match?.params?.id) || 0;
  const { surveyID } = props;
  const [active, setActive] = useState("DesignSurvey");
  const [queue, setQueue] = useState([DefaultQuestion]);
  const [quesIndex, setQuesIndex] = useState(0);
  const [quesData, setQuesData] = useState(queue[quesIndex]);
  const [selectedMenu, setMenu] = useState(
    SURVEY_SETTINGS_MENU[SURVEY_SETTINGS_MENU.default].id
  );
  const [quesType, setQuesType] = useState(DefaultQuestion.q_type);
  const { loading = false } = useSelector((state) => state.quesDesc);
  const { loading: wsLoading, res: wsResult } = useSelector(
    (state) => state.wsDetailsAuth
  );
  const [surveyData, setSurveyData] = useState({
    format: "Classic",
    detail_id: surveyID,
  });
  const [pageInfo, setPageInfo] = useState({
    title: "",
    intro: "",
    footer: "",
    showIntroOnce: true,
    repeatFooter: true,
  });
  const [ansTypes, setAnsTypes] = useState(ANS_TYPE);
  const [isSaving, setSaving] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [hasLinks, setLinks] = useState(false);
  const [promptConfig, setPrompt] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    //surveyID && dispatch(workspaceActions.getWsDetail(surveyID));
  }, [surveyID]);

  useEffect(() => {
    setLoading(wsLoading);
    if (wsLoading === false && !submitted) {
      setLoading(true);
      const {
        survey_title,
        audience_responses,
        quest_desc = {},
        survey_bg_theme,
        estimated_time,
        cookies
      } = wsResult || {};
      const {
        detail_id,
        format = "",
        page_logo = "",
        page_info = {},
        questions_list = [],
      } = quest_desc;
      if (audience_responses === "anon") {
        const ans_type = ANS_TYPE.filter(
          (t) => ["Email", "Contact"].indexOf(t.value) < 0
        );
        setAnsTypes(ans_type);
      }
      if (detail_id) {
        setTimeout(() => {
          questions_list?.length && setQueue(questions_list);
          setSurveyData({
            detail_id,
            format,
            page_logo,
            survey_bg_theme,
            estimated_time,
            cookies
          });
          setPageInfo(page_info);
          setQuesIndex(0);
          questions_list?.length && setQuesData(questions_list[0]);
        }, 500);
      } else {
        setQueue([DefaultQuestion]);
        setSurveyData({ format: "Classic", detail_id: surveyID });
        setPageInfo({
          title: "",
          intro: "",
          footer: "",
          showIntroOnce: true,
          repeatFooter: true,
        });
        //setQuesIndex(0);
        setQuesData(DefaultQuestion);
      }
      setLoading(false);
    }
    if (!wsLoading && wsResult) {
      setSubmitted(false);
    }
  }, [wsLoading]);

  useEffect(() => {
    setLoading(loading);
    setSaving(loading);
  }, [loading]);

  useEffect(() => {
    const cloned = clone(quesData);
    cloned["q_type"] = quesType;
    setQuesData(cloned);
  }, [quesType]);

  useEffect(() => {
    const cloned = clone(queue);
    if (quesIndex === cloned.length) {
      cloned.push(quesData);
    } else {
      cloned[quesIndex] = quesData;
    }
    setQueue(cloned);
  }, [quesData]);

  useEffect(() => {
    const { quest_desc } = wsResult || {};
    if (quest_desc && quest_desc?.links?.length) {
      setLinks(true);
    }
  }, [wsResult]);
  const goTo = (tab, menu) => {
    props.setActiveTab(() => {
      menu && props.setAnalysisTab(menu);
      return tab;
    });
  };
  const onClick = (e) => {
    e &&
      e.id === SURVEY_SETTINGS_MENU.pageLogic.id &&
      props.setActiveTab &&
      props.setActiveTab("PageLogic");
    e &&
      e.id === SURVEY_SETTINGS_MENU.tags.id &&
      goTo("AnalyseResults", ANALYSE_RESULTS_MENU[4]);

    e && e.id && setMenu(e.id);
  };

  const onTypeSelect = (e, ignoreLinks = false) => {
    if (!ignoreLinks && hasLinks) {
      setPrompt({ show: true, action: onTypeSelect, value: [e, true] });
      return;
    }
    const obj = clone(DefaultQuestion);
    obj["q_type"] = e.value;
    setQuesIndex(queue.length);
    setQuesData(obj);
  };

  const onQuesSelect = (i) => {
    setQuesIndex(i);
    setQuesData(queue[i]);
    setQuesType(queue[i].q_type);
  };

  const onSettingChange = ({ name, value }) => {
    if (name) {
      const cloned = clone(quesData);
      cloned.settings[name] = value;
      setQuesData(cloned);
    }
  };

  const handleKebab = (pos, action, ignoreLinks = false) => {
    if (!ignoreLinks && hasLinks) {
      setPrompt({
        show: true,
        action: handleKebab,
        value: [pos, action, true],
      });
      return;
    }
    let cloned = clone(queue),
      i = pos;
    switch (action) {
      case "delete":
          if (cloned.length > 1) {
            cloned.splice(pos, 1);
            i = pos === cloned.length ? pos - 1 : pos;
            createQuestDesc(cloned, true);
          }
        break;
      case "duplicate":
        {
          const obj = cloned[pos];
          if (pos === cloned.length - 1) {
            cloned.push(obj);
            i = cloned.length - 1;
          } else {
            cloned.splice(pos + 1, 0, obj);
            i = pos + 1;
          }
        }
        break;
      case "moveUp":
          if (pos > 0) {
            let obj = cloned[pos - 1];
            cloned[pos - 1] = cloned[pos];
            cloned[pos] = obj;
            i = pos - 1;
          }
        break;
      case "moveDown":
          if (pos < cloned.length - 1) {
            let obj = cloned[pos];
            cloned[pos] = cloned[pos + 1];
            cloned[pos + 1] = obj;
            i = pos + 1;
          }
        break;
        default: console.log("default");
    }

    setQueue(cloned);
    setTimeout(() => {
      setQuesIndex(i);
      setQuesData(cloned[i]);
    }, 100);
    //createQuestDesc(cloned);
  };

  const addBanksQuestion = (qb = []) => {
    let cloned = clone(queue);
    cloned = cloned.concat(qb);
    setQueue(cloned);
  };

  const rightMenu = useRef();

  const settings = () => {
    switch (selectedMenu) {
      case SURVEY_SETTINGS_MENU.appearance.id:
        return (
          <Appearance
            quesType={quesData.q_type}
            data={quesData}
            onSettingChange={onSettingChange}
            handleChange={onChange}
            rightMenuActive={rightMenu}
          />
        );
      case SURVEY_SETTINGS_MENU.format.id:
        return (
          <Format
            data={surveyData}
            setData={setSurveyData}
            setSubmitted={setSubmitted}
            rightMenuActive={rightMenu}
          />
        );
      case SURVEY_SETTINGS_MENU.quesBank.id:
        return (
          <QuestionBankSetting
            addBanksQuestion={addBanksQuestion}
            rightMenuActive={rightMenu}
            experienceType={wsResult?.experience_type || ""}
          />
        );
      case SURVEY_SETTINGS_MENU.options.id:
        return (
          <AlertOption
            quesType={quesData.q_type}
            data={quesData}
            onChange={onChange}
            rightMenuActive={rightMenu}
            onSave={onSave}
          />
        );
      default:
        return null;
    }
  };

  const onChange = (e, s = {}) => {
    let target = e;
    if (e && e.target) {
      target = e.target;
    }
    const cloned = clone(quesData);
    cloned[target.name] = target.value;
    cloned["settings"] = Object.assign(cloned.settings, s);
    setQuesData(cloned);
  };

  const promptHandler = (e) => {
    if (e) {
      setLinks(false);
      promptConfig.action(...promptConfig.value);
    }
    setPrompt({});
  };

  const onSave = () => {
    setLoading(true);
    setSubmitted(true);
    createQuestDesc(queue);
  };

  const createQuestDesc = (_queue, emptyLink=false) => {
    const finalData = clone(surveyData);
    finalData["questions_list"] = _queue;
    finalData["page_info"] = pageInfo;
    if (!hasLinks || emptyLink) {
      finalData["links"] = [];
    }
    dispatch(workspaceActions.createQuestDesc(finalData));
  };

  const leftMenu = useRef();

  return (
    <div className="createSurveyBody">
      <div className="surveyLeftMenuBtn">
        <button
          onClick={() => leftMenu.current.classList.toggle("menu-active")}
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="24" height="25" rx="3" fill="#F9F9F9" />
            <path
              d="M18.8753 5.41797H6.12533C5.93746 5.41797 5.7573 5.4926 5.62446 5.62543C5.49162 5.75827 5.41699 5.93844 5.41699 6.1263V18.8763C5.41699 19.0642 5.49162 19.2443 5.62446 19.3772C5.7573 19.51 5.93746 19.5846 6.12533 19.5846H18.8753C19.0632 19.5846 19.2434 19.51 19.3762 19.3772C19.509 19.2443 19.5837 19.0642 19.5837 18.8763V6.1263C19.5837 5.93844 19.509 5.75827 19.3762 5.62543C19.2434 5.4926 19.0632 5.41797 18.8753 5.41797V5.41797ZM13.917 18.168H6.83366V6.83464H13.917V18.168ZM18.167 18.168H15.3337V6.83464H18.167V18.168Z"
              fill="#AEB6CF"
            />
            <path
              d="M9.33986 14.9075C9.22004 14.7842 9.22004 14.5842 9.33986 14.4609L10.7593 13L9.33986 11.5391C9.22004 11.4158 9.22004 11.2158 9.33986 11.0925C9.45968 10.9692 9.65395 10.9692 9.77377 11.0925L11.4101 12.7767C11.53 12.9 11.53 13.1 11.4101 13.2233L9.77377 14.9075C9.65395 15.0308 9.45968 15.0308 9.33986 14.9075Z"
              fill="#AEB6CF"
              fillRule="evenodd"
              clipRule="evenodd"
              stroke="#AEB6CF"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
      <LeftMenu onClick={(e) => onClick(e)} leftMenuActive={leftMenu} />
      <QuestionsQueue
        queue={queue}
        position={quesIndex}
        data={quesData}
        onChange={onChange}
        surveyData={surveyData}
        setSurveyData={setSurveyData}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        onTypeSelect={onTypeSelect}
        onQuesSelect={onQuesSelect}
        handleKebab={handleKebab}
        ansTypes={ansTypes}
        isLoading={isLoading}
        setSubmitted={setSubmitted}
      />
      <Question
        isSaving={isSaving}
        quesNo={quesIndex + 1}
        quesType={quesData.q_type}
        setQuesType={setQuesType}
        value={isLoading ? DefaultQuestion : quesData}
        onChange={onChange}
        onSave={onSave}
        onSettingChange={onSettingChange}
        ansTypes={ansTypes}
        experienceType={wsResult?.experience_type || ""}
      />
      <div>{settings()}</div>
      <Backdrop show={wsLoading || isLoading} />
      <div className="surveyRightMenuBtn">
        <button
          onClick={() => rightMenu.current.classList.toggle("menu-active")}
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="24" height="25" rx="3" fill="#F9F9F9" />
            <path
              d="M18.8753 5.41797H6.12533C5.93746 5.41797 5.7573 5.4926 5.62446 5.62543C5.49162 5.75827 5.41699 5.93844 5.41699 6.1263V18.8763C5.41699 19.0642 5.49162 19.2443 5.62446 19.3772C5.7573 19.51 5.93746 19.5846 6.12533 19.5846H18.8753C19.0632 19.5846 19.2434 19.51 19.3762 19.3772C19.509 19.2443 19.5837 19.0642 19.5837 18.8763V6.1263C19.5837 5.93844 19.509 5.75827 19.3762 5.62543C19.2434 5.4926 19.0632 5.41797 18.8753 5.41797V5.41797ZM13.917 18.168H6.83366V6.83464H13.917V18.168ZM18.167 18.168H15.3337V6.83464H18.167V18.168Z"
              fill="#AEB6CF"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.33986 14.9075C9.22004 14.7842 9.22004 14.5842 9.33986 14.4609L10.7593 13L9.33986 11.5391C9.22004 11.4158 9.22004 11.2158 9.33986 11.0925C9.45968 10.9692 9.65395 10.9692 9.77377 11.0925L11.4101 12.7767C11.53 12.9 11.53 13.1 11.4101 13.2233L9.77377 14.9075C9.65395 15.0308 9.45968 15.0308 9.33986 14.9075Z"
              fill="#AEB6CF"
              stroke="#AEB6CF"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
      <Prompt
        show={!!promptConfig.show}
        handler={promptHandler}
        message={"Page logic need to be recreated"}
        btnLabel={"OK"}
        cancelBtnLbl={"Cancel"}
      />
    </div>
  );
};

export default NewCreateSurvey;
