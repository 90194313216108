import React, {useState} from 'react';
import "rsuite/dist/rsuite.css";

import { Rate } from 'rsuite';
import FrownIcon from '@rsuite/icons/legacy/FrownO';
import MehIcon from '@rsuite/icons/legacy/MehO';
import SmileIcon from '@rsuite/icons/legacy/SmileO';
import HeartIcon from '@rsuite/icons/legacy/Heart';
import ThumbsUp from '@rsuite/icons/legacy/ThumbsUp';

const renderCharacter = (value, index) => {
    // unselected character
    if (value < index + 1) {
        return <MehIcon />;
    }
    if (value < 3) {
        return <FrownIcon />;
    }
    if (value < 4) {
        return <MehIcon />;
    }
    return <SmileIcon/>;
};

const Ratings = ({value:{settings} = {}, count, onChange}) => {
    const getRatingValue = (e) => {
        onChange && onChange(e);
    };
    const Rating = ({max, type, color}) => {
        switch (type){
            case 'Smily':
                return <Rate max={Number(max)} renderCharacter={renderCharacter} style={{color}} value={count} onChange={getRatingValue}/>;
            case 'Heart':
                return <Rate max={Number(max)} character={<HeartIcon />} style={{color}} value={count} onChange={getRatingValue}/>;
            case 'Thumsup':
                return <Rate max={Number(max)} character={<ThumbsUp fill="#37ab3c"/>} style={{color}} value={count} onChange={getRatingValue}/>;
            default:  return <Rate max={Number(max)} style={{color}} value={count} onChange={getRatingValue}/>;
        }
    };

    return <div style={{margin:"20px 0"}}>
            <Rating max={settings?.rating?.value || 5} color={settings?.rating?.color} type={settings?.rating?.type || ''} />
        </div>
};

export default Ratings;
