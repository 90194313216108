import React, { useState, useEffect } from "react";
import ReactFlagsSelect from "react-flags-select";

const CountryFlagSelect = (props) => {
    const {value="US", name, onChange, fullWidth=false, selectedSize=14, optionsSize=14,
        searchable=false, showSelectedLabel=true, disabled=false, className=""} = props;
    const [selected, setSelected] = useState(value);
    useEffect(()=> {
        setSelected(value)
    }, [value]);

    const onSelect = (code) => {
        setSelected(code);
        onChange && onChange({name, value: code});
    };

    return <ReactFlagsSelect className={className}
                             selected={selected}
                             fullWidth={fullWidth}
                             selectedSize={selectedSize}
                             optionsSize={optionsSize}
                             searchable={searchable}
                             showSelectedLabel={showSelectedLabel}
                             disabled={disabled}
                             onSelect={onSelect}
    />;
};

export default CountryFlagSelect;