import React, { useEffect, useState } from "react";

import InputLabel from "@mui/material/InputLabel";
import "./PageLogic.scss";
import { useDispatch, useSelector } from "react-redux";

import { pageLogicActions } from "../../../../actions";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FirstIcon from "../../../../assets/images/first.svg";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useParams } from "react-router";
import { swalAlert } from "../../../../voc/Common/SwalAlert";

const MultipleChoiceLogic = (props) => {
  const dispatch = useDispatch();

  const { data, links, random } = props;
  const { id } = useParams();
  console.log("fdfd1", data, links);

  const [activeQuestion, setActiveQuestion] = useState("");
  const [activeAnswer, setActiveAnswer] = useState([]);

  const [conditions, setConditions] = useState([]);
  useEffect(() => {
    setActiveQuestion(data.questDesc[data.index].quest);
    var arr = [];
    data.ans.forEach((opt) => {
      var obj = {
        index: data.index,
        option: [opt],
        nextInd: null,
      };
      arr.push(obj);
    });
    // setConditions(arr);
    let answerArray = [];
    data.ans.forEach((e) => {
      answerArray.push({
        ans: e,
        optionArray: data.questDesc.slice(data.index + 1).map((value) => {
          return value.quest;
        }),
      });
    });

    for (let i = 0; i < answerArray.length; i++) {
      for (let j = 0; j < links.length; j++) {
        for (let k = 0; k < links[j].data.optArr.length; k++) {
          if (answerArray[i].ans === links[j].data.optArr[k]) {
            answerArray[i].selected = links[j].output;
          }
        }
      }
    }

    for (let m = 0; m < answerArray.length; m++) {
      var arr = arr.slice();
      let newIdx = data.questDesc.findIndex(
        (x) => x.quest === answerArray[m].selected
      );
      console.log("sdsjsd", arr, newIdx);
      arr[m].nextInd = newIdx;
    }
    setConditions(arr);

    console.log("answerArray  : ", answerArray);
    setActiveAnswer(answerArray);
  }, [data.ans, data.index, data.qType, random]);

  const handleChange1 = (e, index) => {
    //console.log("handleChange1", e.target.value);

    const list = [...activeAnswer];
    // const optArr = list[0].optionArray;
    //console.log("handleChange1 activeAnswer", activeAnswer);
    let alreadyExist = false;
    list.forEach((k) => {
      if (k.selected === e.target.value) {
        alreadyExist = false;
      }
    });
    if (!alreadyExist) {
      list[index]["selected"] = e.target.value;
      var arr = conditions.slice();
      let newIdx = data.questDesc.findIndex((x) => x.quest === e.target.value);
      arr[index].nextInd = newIdx;
      console.log("sdksdksd", arr);
      setConditions(arr);

      setActiveAnswer(list);
    } else {
      // alert("Already Selected Option");
      swalAlert({
        icon: "error",
        title: "Already Selected Option".toString(),
        showConfirmButton: true,
      });

      return;
    }
  };
  const reset = () => {
    if (data.questDesc && data.questDesc.length > 0) {
      // setActiveAnswer([]);
      setActiveQuestion(data.questDesc[data.index].quest);
      // setActiveAnswer({

      //   pageLogicarr[0].ans
      // });
      let answerArray = [];
      data.ans.forEach((e) => {
        answerArray.push({
          ans: e,
          optionArray: data.questDesc.slice(data.index + 1).map((value) => {
            return value.quest;
          }),
        });
      });
      //console.log("Sfdf", answerArray);
      setActiveAnswer([]);
      setTimeout(() => {
        setActiveAnswer(answerArray);
      }, 1);
      var arr = [];
      data.ans.forEach((opt) => {
        var obj = {
          index: data.index,
          option: [opt],
          nextInd: null,
        };
        arr.push(obj);
      });
      //console.log("In USEEFFECT with data.ans");
      //console.log("customnode-->", arr);
      //console.log(data.ans);
      setConditions(arr);
    }
  };
  function getDictionary() {
    return validateDictionary("ABCDEFGHIJKLMNOPQRSTUVWXYZ");

    function validateDictionary(dictionary) {
      for (let i = 0; i < dictionary.length; i++) {
        if (
          dictionary.indexOf(dictionary[i]) !==
          dictionary.lastIndexOf(dictionary[i])
        ) {
          console.log(
            "Error: The dictionary in use has at least one repeating symbol:",
            dictionary[i]
          );
          return undefined;
        }
      }
      return dictionary;
    }
  }
  function numberToEncodedLetter(number) {
    //Takes any number and converts it into a base (dictionary length) letter combo. 0 corresponds to an empty string.
    //It converts any numerical entry into a positive integer.
    if (isNaN(number)) {
      return undefined;
    }
    number = Math.abs(Math.floor(number));

    const dictionary = getDictionary();
    let index = number % dictionary.length;
    let quotient = number / dictionary.length;
    let result;

    if (number <= dictionary.length) {
      return numToLetter(number);
    } //Number is within single digit bounds of our encoding letter alphabet

    if (quotient >= 1) {
      //This number was bigger than our dictionary, recursively perform this function until we're done
      if (index === 0) {
        quotient--;
      } //Accounts for the edge case of the last letter in the dictionary string
      result = numberToEncodedLetter(quotient);
    }

    if (index === 0) {
      index = dictionary.length;
    } //Accounts for the edge case of the final letter; avoids getting an empty string

    return result + numToLetter(index);

    function numToLetter(number) {
      //Takes a letter between 0 and max letter length and returns the corresponding letter
      if (number > dictionary.length || number < 0) {
        return undefined;
      }
      if (number === 0) {
        return "";
      } else {
        return dictionary.slice(number - 1, number);
      }
    }
  }

  // useEffect(() => {
  //   var arr = [];
  //   data.ans.forEach((opt) => {
  //     var obj = {
  //       index: data.index,
  //       option: [opt],
  //       nextInd: null,
  //     };
  //     arr.push(obj);
  //   });
  //   setConditions(arr);
  // }, [data.ans, data.index, data.qType]);

  function equals(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  }

  // Returns index of where we can put the obj if an obj with the same index
  // and ansOpt field does not exist yet. Or if it does exist, it returns the
  // index of that object
  function binarySearchInCondFlow(arr, lower, upper, obj) {
    //console.log("binarySearchInCondFlow",obj);
    if (upper >= lower) {
      var mid = lower + (upper - lower) / 2;
      if ((upper - lower) % 2 === 1) {
        mid -= 0.5;
      }
      //console.log(mid);
      // If the element is present at the middle
      // itself
      //console.log(data.condFlow);
      //console.log(data.condFlow[0]);
      if (
        arr[mid]["index"] === obj["index"] &&
        equals(arr[mid]["option"], obj["option"])
      )
        return mid;

      //console.log(data.condFlow);
      //console.log(data.condFlow[0]);
      // If element is smaller than mid, then
      // it can only be present in left subarray
      if (
        arr[mid]["index"] > obj["index"] ||
        arr[mid]["nextInd"] > obj["nextInd"]
      )
        return binarySearchInCondFlow(arr, lower, mid - 1, obj);

      // Else the element can only be present
      // in right subarray
      return binarySearchInCondFlow(arr, mid + 1, upper, obj);
    }
    //console.log(lower);
    return lower;
  }

  const handleOk = () => {
    //const obj = {index: data.index, option: ansOpt, nextInd: nextQuestInd};

    const index = activeAnswer.findIndex((x) => x.selected === undefined);
    if (index !== -1) {
      swalAlert({
        icon: "error",
        title: "Please select all the conditions".toString(),
        showConfirmButton: true,
      });
      return 0;
    }
    var condArr = [];
    //console.log("conditionarrayinok", conditions);
    for (let i = 0; i < conditions.length; i++) {
      var shouldPush = true;
      var ind = 0;
      for (let j = 0; j < i; j++) {
        if (j !== i && conditions[j].nextInd === conditions[i].nextInd) {
          shouldPush = false;
          ind = j;
          break;
        }
      }

      // if (data.qType === "Rating") {
      shouldPush = true;
      // }
      if (shouldPush) {
        condArr.push(conditions[i]);
      } else {
        condArr[ind].option.push(conditions[i].option[0]);
      }
    }

    //console.log("IN HANDLE OK", condArr);
    //console.log(condArr);
    var arr = [...data.condFlow];

    arr = arr.filter((elem) => {
      return data.index !== elem.index;
    });

    for (let i = 0; i < condArr.length; i++) {
      const obj = condArr[i];
      if (arr.length > 0) {
        const condFlowIndex = binarySearchInCondFlow(
          arr,
          0,
          arr.length - 1,
          obj
        );
        // We get obj with same index field
        if (condFlowIndex === arr.length) {
          arr.push(obj);
        } else if (
          arr[condFlowIndex].index === obj.index &&
          equals(arr[condFlowIndex].option, obj.option)
        ) {
          // The user is redoing the conditional flow for this option; so we just overwrite the next question
          // for the already existing condition
          arr[condFlowIndex].nextInd = obj.nextInd;
        } else {
          arr.splice(condFlowIndex, 0, obj);
        }
      } else {
        arr.push(obj);
      }
    }

    //console.log("finalarr", arr);

    var newLinks = [];

    arr.forEach((e) => {
      newLinks.push({
        input: `${data.questDesc[e.index].quest}`,
        output: `${data.questDesc[e.nextInd].quest}`,

        data: {
          optArr: e.option,
          index: e.index,
          nextInd: e.nextInd,
        },
      });
    });
    //console.log("finalLink", newLinks);
    data.setCondFlow(arr);

    dispatch(
      pageLogicActions.updatePageLogicData({
        detail_id: id,
        links: newLinks,
      })
    );
    setTimeout(() => {
      dispatch(pageLogicActions.getPageLogicData(id));
    }, [1000]);

    data.setMultipleChoiceData(null);
    data.setMultipleChoiceId("");
    data.setActiveQuestionType("");
  };

  return (
    <div style={{ background: "#717EC3", borderRadius: "10px" }}>
      <div className="pageLogic-wrapper">
        <div className="title-wrapper">Page Logic</div>
        <div className="content-wrapper">
          <div className="dropdown-title">
            <h2>
              Edit Logic
              <img src={FirstIcon} alt="" />
            </h2>
            <button
              onClick={() => {
                data.setMultipleChoiceData(null);
                data.setMultipleChoiceId("");
                data.setActiveQuestionType("");
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4099 12.0002L17.7099 7.71019C17.8982 7.52188 18.004 7.26649 18.004 7.00019C18.004 6.73388 17.8982 6.47849 17.7099 6.29019C17.5216 6.10188 17.2662 5.99609 16.9999 5.99609C16.7336 5.99609 16.4782 6.10188 16.2899 6.29019L11.9999 10.5902L7.70994 6.29019C7.52164 6.10188 7.26624 5.99609 6.99994 5.99609C6.73364 5.99609 6.47824 6.10188 6.28994 6.29019C6.10164 6.47849 5.99585 6.73388 5.99585 7.00019C5.99585 7.26649 6.10164 7.52188 6.28994 7.71019L10.5899 12.0002L6.28994 16.2902C6.19621 16.3831 6.12182 16.4937 6.07105 16.6156C6.02028 16.7375 5.99414 16.8682 5.99414 17.0002C5.99414 17.1322 6.02028 17.2629 6.07105 17.3848C6.12182 17.5066 6.19621 17.6172 6.28994 17.7102C6.3829 17.8039 6.4935 17.8783 6.61536 17.9291C6.73722 17.9798 6.86793 18.006 6.99994 18.006C7.13195 18.006 7.26266 17.9798 7.38452 17.9291C7.50638 17.8783 7.61698 17.8039 7.70994 17.7102L11.9999 13.4102L16.2899 17.7102C16.3829 17.8039 16.4935 17.8783 16.6154 17.9291C16.7372 17.9798 16.8679 18.006 16.9999 18.006C17.132 18.006 17.2627 17.9798 17.3845 17.9291C17.5064 17.8783 17.617 17.8039 17.7099 17.7102C17.8037 17.6172 17.8781 17.5066 17.9288 17.3848C17.9796 17.2629 18.0057 17.1322 18.0057 17.0002C18.0057 16.8682 17.9796 16.7375 17.9288 16.6156C17.8781 16.4937 17.8037 16.3831 17.7099 16.2902L13.4099 12.0002Z"
                  fill="#8A94A6"
                />
              </svg>
            </button>
          </div>
          <div className="dropdown-wrapper">
            <FormControl fullWidth sx={{ m: 1, maxHeight: 32 }} size="small">
              <InputLabel id="demo-simple-select-label">
                {activeQuestion}
              </InputLabel>

              <Select
                disabled
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                IconComponent={() => <ExpandMoreRoundedIcon />}
              ></Select>
            </FormControl>
          </div>

          <div className="checkbox-container">
            <div>
              <input
                type="checkbox"
                className="checkbox-input"
                id="if"
                checked
              />
              <label className="checkbox-label" htmlFor="if">
                If
              </label>
            </div>
          </div>

          <div className="addCondition">
            <svg
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="ControlPointIcon"
              aria-label="Add Questions Type"
              data-mui-internal-clone-element="true"
            >
              <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
            </svg>
            <p>Add Condition</p>
          </div>

          <div className="goto">
            <p>Then</p>
            <div className="checkbox-container">
              <input
                type="checkbox"
                className="checkbox-input"
                id="if"
                checked
              />
              <label className="checkbox-label" htmlFor="if">
                Go to
              </label>
            </div>
          </div>

          <div className="default-input-fields">
            {activeAnswer && activeAnswer.length > 0
              ? activeAnswer.map((val, index) => {
                  return (
                    <div className="fields-wrapper">
                      <div className="age-field">
                        <p>{numberToEncodedLetter(index + 1)}:</p>
                        <p>
                          {data.qType === "Picture" ? (
                            <img
                              style={{ width: "50px", height: "50px" }}
                              src={val.ans}
                            />
                          ) : (
                            val.ans
                          )}
                        </p>
                      </div>
                      <div className="select-field">
                        <select
                          onChange={(e) => handleChange1(e, index)}
                          className="dropdownButton"
                        >
                          {val.optionArray.map((value, index) => {
                            return (
                              <>
                                {index === 0 ? (
                                  <option value="none" selected disabled hidden>
                                    Select an Option
                                  </option>
                                ) : null}

                                <option
                                  id={index}
                                  selected={val.selected === value}
                                  value={value}
                                >
                                  {index + 1}- {value}?
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
          {/* <div className="btn-wrap">
            <button onClick={reset} className="blue-btn">
              Reset
            </button>
          </div> */}
          <div className="btn-wrap">
            <button onClick={handleOk} className="blue-btn">
              Done
            </button>
          </div>
          {/*<div className="estimatedTime-wrapper">
            <h2>Estimated time</h2>
            <p>How long will it take to finish this question?</p>
            <div className="input-group">
              <input type="text" placeholder="Estimated time to finish" />
              <button>Min</button>
            </div>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default MultipleChoiceLogic;
