import { delegationConstants } from "../constants/delegationConstants";

const initialState = {
  loading: false,
  isDelegationAdded: false,
  delegations: [], // Replace agents with delegations
  message: "",
};

export function delegationLogic(state = initialState, action) {
  switch (action.type) {
    case delegationConstants.ADD_DELEGATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case delegationConstants.ADD_DELEGATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isDelegationAdded: true, // Set success flag to true
        delegations: [...state.delegations, action.delegation], // Update delegations list in state
      };
    case delegationConstants.ADD_DELEGATION_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.error,
        type: "error",
      };
    case delegationConstants.RESET_DELEGATION_ADDED:
      return {
        ...state,
        isDelegationAdded: false, // Reset the success flag
      };
    default:
      return state;
  }
}