import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CodeIcon from "@mui/icons-material/Code";
import copy from 'copy-to-clipboard';
import Dialogbox from "../../Common/DialogBox";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import ReactDOMServer from 'react-dom/server'
import {URL_VAL} from '../../Common/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { emailActions, workspaceActions } from '../../../actions';
import Standard from "./Standard";
import Slider from "./Slider";
import SliderTop from "./SliderTop";
import Full from "./Full";

let codeString = "";
const getter = () => {
    return codeString;
};
const setter = (codeStr) => {
    codeString = codeStr;
};
const CodeDialog = ({onCloseHandle, type, surveyID}) =>{
    const [copyBtnLabel, setCopyBtnLabel] = React.useState("Copy Code")
    const htmlCode = getter();
    const code =  typeof htmlCode === 'string' ? htmlCode : ReactDOMServer.renderToString(htmlCode);
    const rootClass = ['sliderTop', 'slider'].indexOf(type)>-1 ? "codeGenerateDialog uploadDialog" : 'uploadDialog';
    const onCopyCode = () => {
        copy(code);
        setCopyBtnLabel("copied");
        setTimeout(()=>{
            setCopyBtnLabel("Copy Code");
        }, 500);
    };
    return(
        <Dialogbox open={true} handleClose={onCloseHandle} dialogContentClass={'createContactInner'} rootClass={rootClass} rootStyle={{height:550}}>
            <div className="closeDailogNew" onClick={onCloseHandle}><HighlightOffTwoToneIcon/></div>
            <div className="createContactDialog codeCopyDialog">
                <h3>Grab your embed code</h3>
                <p>Place the code in your page's HTML where you want your survey form to appear.</p>
                <pre>
                        <code>{code}</code>
                     </pre>
                <div className="btnGroup">
                    <button className="btn" onClick={onCloseHandle}>Cancel</button>
                    <button className="btn btnSubmit" onClick={onCopyCode} style={{width:116}}>{copyBtnLabel}</button>
                </div>
            </div>

        </Dialogbox>
    )
}
const EmbedSideBar = ({embedType="", surveyID, changeEmbedMode, btnText, setBtnText}) =>{
    const [openGetCode, setGetCode] = React.useState(false);
    const [showMode, setMode] = React.useState(!!embedType.length);
    const [open, openDesign] = React.useState(false);
    const [btnTxt, setBtnTxt] = React.useState(btnText);
    const [invalidTxt, setInvalidTxt] = React.useState(false);
    const dispatch = useDispatch();
    React.useEffect(()=>{
        ['slider','sliderTop'].indexOf(embedType)<0 && openDesign(false);
    },[embedType]);
    const onGetCodeClick = () =>{
        setGetCode(true);
        const group = embedType+"_embed";
        const link = getLink(surveyID, embedType);
        const req = {
            email: group.toUpperCase(),
            group,
            person_details: {name: group.toUpperCase()},
            personal_link: link.replace('embed/form_ans', 'form_ans'),
            survey_sent: false,
            responded: false
        };
        dispatch(workspaceActions.bulkCreateEmailLinks({
            survey_id: surveyID,
            records: [req]
        }));
    };
    const onCloseHandle = () =>{
        setGetCode(false);
    };
    const selectEmbedType = (t) => {
        changeEmbedMode(t);
    };
    const onBtnTextChange = () => {
        if(btnTxt?.length){
            setBtnText && setBtnText(btnTxt);
        }else {
            setInvalidTxt(true);
        }
    };
    const onEnterKeyPress = (e) => {
        if(e?.key === "Enter"){
            onBtnTextChange();
        }
    };

    return(
        <div className="embedSidebar">
            <div className="head">
                <div className="icon"><CodeIcon/></div>
                <h3>Embed in a web page</h3>
            </div>
            <div className="embedSideBody">
                <ul>
                    <li className={showMode ? "active":""}>
                        <a onClick={()=>{openDesign(false);setMode(!showMode)}}>Embed Mode <ArrowForwardIosIcon/></a>
                        <ul className={"dropDownMode"}>
                            <li className={embedType === "standard" ? "active":""}>
                                <a onClick={()=>selectEmbedType("standard")}>
                                    <img src={origin+"/images/SVG/standered.svg"} alt="link"/>
                                    <h3>Standard</h3>
                                </a>
                            </li>
                            <li className={embedType === "full" ? "active":""}>
                                <a onClick={()=>selectEmbedType("full")}>
                                    <img src={origin+"/images/SVG/full.svg"} alt="link"/>
                                    <h3>Full</h3>
                                </a>
                            </li>
                            <li className={embedType === "slider" ? "active":""}>
                                <a onClick={()=>selectEmbedType("slider")}>
                                    <img src={origin+"/images/SVG/slider.svg"} alt="link"/>
                                    <h3>Slider</h3>
                                </a>
                            </li>
                            <li className={embedType === "sliderTop" ? "active":""}>
                                <a onClick={()=>selectEmbedType("sliderTop")}>
                                    <img src={origin+"/images/SVG/sliderTop.svg"} alt="link"/>
                                    <h3>Slider Top</h3>
                                </a>
                            </li>
                        </ul>
                    </li>
                    {/*<li><a>Design <ArrowForwardIosIcon/></a></li>*/}
                    <li className={open ? "active":""} >
                        <a onClick={()=>{openDesign(!open)}} className={['slider','sliderTop'].indexOf(embedType)<0 ? "disable":""}>Design <ArrowForwardIosIcon/></a>
                        <ul className={"dropDownMode textPropertyEmbedWrap"}>
                            <div style={{height:140, display:'grid', padding:'0 20px', borderBottom:'1px solid #ccc'}}>
                                <div style={{display:'flex'}}><span style={{color:'#575757', height:10}}>Button Label</span><p style={{color:'red'}}>&nbsp;*</p></div>
                                <input type="text" style={{width:'100%', borderColor:'#ccc', borderRadius:4, height:42}} value={btnTxt} onChange={(e)=> {setBtnTxt(e.target.value); setInvalidTxt(false)}} onKeyDownCapture={onEnterKeyPress}/>
                                {invalidTxt ? <span style={{color:'red'}}>Button text is required</span>:null}
                                {/*<TextEditor/>*/}
                                <div style={{width:'100%', textAlign:'right'}}>
                                    <button style={{width: 75, height: 35, borderRadius: 5}} onClick={onBtnTextChange}> Save </button>
                                </div>
                            </div>
                        </ul>
                    </li>
                </ul>
                <button className="btn" onClick={onGetCodeClick}>
                    Get the code
                </button>
                {openGetCode ? <CodeDialog onCloseHandle={onCloseHandle} type={embedType} surveyID={surveyID}/>:null}
                <div className="text">
                    Find out more in our <a>Help Center</a>
                </div>
            </div>
        </div>
    )
};
const EmbedView = ({surveyID, mode, btnTxt}) =>{
    return(
        <div className="embedView">
            <div className={`embedViewInner ${mode}`} id="survey_view">
                {mode === "standard" && <div className="bar"></div>}
                <Iframe surveyID={surveyID} mode={mode} btnTxt={btnTxt}/>
                {mode === "standard" && <div className="bar"></div>}
            </div>
        </div>
    )
};

const getLink = (surveyID, mode) => (window.location.origin + `/embed/form_ans/${surveyID}&${URL_VAL}/grp=${mode}_embed`);

const Iframe = ({surveyID, mode, btnTxt}) => {
    const url = getLink(surveyID, mode);
    switch (mode){
        case 'standard': return <Standard url={url}/>;
        case 'slider': return <Slider btnText={btnTxt} url={url}/>;
        case 'sliderTop': return <SliderTop btnText={btnTxt} url={url}/>
        default: return <Full url={url}/>
    }
};

const EmbedPage = (props) =>{
    const [mode, setMode] = React.useState(props.embedType);
    const [btnText, setBtnText] = React.useState("Example Form");
    return(
        <div className="embedPageWrap">
            <EmbedSideBar {...props} embedType={mode} changeEmbedMode={setMode} btnText={btnText} setBtnText={setBtnText}/>
            <EmbedView {...props} mode={mode} btnTxt={btnText}/>
        </div>
    )
}

export default EmbedPage;
export {setter};
