export const roleConstants = {
    // get all ROLES
    GET_ALL_ROLES_REQUEST: "GET_ALL_ROLES_REQUEST",
    GET_ALL_ROLES_SUCCESS: "GET_ALL_ROLES_SUCCESS",
    GET_ALL_ROLES_FAILURE: "GET_ALL_ROLES_FAILURE",
  
    // add ROLE
    ADD_ROLE_REQUEST: "ADD_ROLE_REQUEST",
    ADD_ROLE_SUCCESS: "ADD_ROLE_SUCCESS",
    ADD_ROLE_FAILURE: "ADD_ROLE_FAILURE",
    RESET_ROLE_ADDED: "RESET_ROLE_ADDED",
  
    // edit ROLE
    EDIT_ROLE_REQUEST: "EDIT_ROLE_REQUEST",
    EDIT_ROLE_SUCCESS: "EDIT_ROLE_SUCCESS",
    EDIT_ROLE_FAILURE: "EDIT_ROLE_FAILURE",
    RESET_ROLE_UPDATED: "RESET_ROLE_UPDATED",
  
    // delete ROLE
    DELETE_ROLE_REQUEST: "DELETE_ROLE_REQUEST",
    DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
    DELETE_ROLE_FAILURE: "DELETE_ROLE_FAILURE",
  };
  