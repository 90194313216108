import cn from "classnames";
import React, { useEffect, useState, useRef } from "react";

import { surveyActions } from "../../../../actions";

import { Bar } from "react-chartjs-2";
import Box from "@mui/material/Box";
import { ANS_TYPE } from "../../../Common/Constants";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import colorLib from "@kurkle/color";
import Facebook from "../../../../assets/images/Facebooks.png";
import Whatsapp from "../../../../assets/images/WhatsApp.png";
import Twitter from "../../../../assets/images/Twitter.png";
import Reddit from "../../../../assets/images/reddit.jpeg";
import { useSelector, useDispatch } from "react-redux";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import "./dropdown.css";
import "./customDropdown.scss";

const transparentize = (value, opacity) => {
  var alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return colorLib(value).alpha(alpha).rgbString();
};

const CHART_COLORS = {
  red: "rgb(255, 99, 132)",
  orange: "rgb(255, 159, 64)",
  yellow: "rgb(255, 205, 86)",
  green: "rgb(75, 192, 192)",
  blue: "rgb(54, 162, 235)",
  purple: "rgb(153, 102, 255)",
  grey: "rgb(201, 203, 207)",
};
const SurveyEffectiveness = () => {
  const lexicalQuestionId = useSelector(
    (state) => state.survey.lexicalQuestionId
  );
  const [showloader, setshowloader] = useState(false);

  const getFormattedLabel = (val) => {
    let label = "";
    ANS_TYPE.forEach((e) => {
      if (e.value === val) {
        label = e.label;
      }
    });
    return label;
  };
  const questionData = useSelector(
    (state) => state.survey.lexicalQuestionAnalysisData
  );
  const surveyData = useSelector((state) => state.survey.lexicalItems);

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const getLabel = () => {
    if (lexicalSurveyEffectivenessAbandonedSocialGroup === "all") {
      if (lexicalSurveyEffectivenessAbandonedChannel === "email_link") {
        return "Email Link";
      } else if (
        lexicalSurveyEffectivenessAbandonedChannel === "email_response"
      ) {
        return "Email Sharing";
      } else if (
        lexicalSurveyEffectivenessAbandonedChannel === "socials_link"
      ) {
        return "Social Link";
      } else if (
        lexicalSurveyEffectivenessAbandonedChannel === "link_sharing"
      ) {
        return "Link Sharing";
      }
    } else if (lexicalSurveyEffectivenessAbandonedSocialGroup === "whatsapp") {
      return "WhatsApp";
    } else if (
      lexicalSurveyEffectivenessAbandonedSocialGroup === "full_embed"
    ) {
      return "Full Embed";
    } else if (
      lexicalSurveyEffectivenessAbandonedSocialGroup === "standard_embed"
    ) {
      return "Standard Embed";
    } else if (
      lexicalSurveyEffectivenessAbandonedSocialGroup === "slider_embed"
    ) {
      return "Slider Embed";
    } else if (
      lexicalSurveyEffectivenessAbandonedSocialGroup === "sliderTop_embed"
    ) {
      return "Slider Top Embed";
    } else {
      return capitalize(lexicalSurveyEffectivenessAbandonedSocialGroup);
    }
  };

  const lexicalSurveyEffectivenessAbandonedData = useSelector(
    (state) => state.survey.surveyLexicalEffectivenessAbandonedData
  );
  const lexicalSurveyEffectivenessAbandonedChannel = useSelector(
    (state) => state.survey.surveyLexicalEffectivenessAbandonedChannel
  );
  const lexicalSurveyEffectivenessAbandonedSocialGroup = useSelector(
    (state) => state.survey.surveyLexicalEffectivenessAbandonedSocialGroup
  );
  const lexicalSurveyEffectivenessAbandonedCountry = useSelector(
    (state) => state.survey.surveyLexicalEffectivenessAbandonedCountry
  );

  const dispatch = useDispatch();

  const [barChartLabel, setBarChartLabel] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const [abandonedCountry, setAbandonedCountry] = useState([]);
  const gettitle1 = () => {
    if (lexicalSurveyEffectivenessAbandonedSocialGroup === "all") {
      if (lexicalSurveyEffectivenessAbandonedChannel === "email_link") {
        return "Email Link";
      } else if (
        lexicalSurveyEffectivenessAbandonedChannel === "email_response"
      ) {
        return "Email Sharing";
      } else if (
        lexicalSurveyEffectivenessAbandonedChannel === "socials_link"
      ) {
        return "Social Link";
      } else if (
        lexicalSurveyEffectivenessAbandonedChannel === "link_sharing"
      ) {
        return "Link Sharing";
      }
    } else {
      return "";
    }
  };
  const geticon1 = () => {
    if (lexicalSurveyEffectivenessAbandonedSocialGroup === "all") {
      return "";
    } else if (lexicalSurveyEffectivenessAbandonedSocialGroup === "whatsapp") {
      return "WhatsApp";
    } else if (lexicalSurveyEffectivenessAbandonedSocialGroup === "linkedin") {
      return "LinkedIn";
    } else {
      return capitalizeFirstLetter(
        lexicalSurveyEffectivenessAbandonedSocialGroup
      );
    }
  };
  useEffect(() => {
    let countryData = [];
    let label = [];
    let data = [];
    lexicalSurveyEffectivenessAbandonedData &&
      lexicalSurveyEffectivenessAbandonedData["answers_count"] &&
      Object.entries(
        lexicalSurveyEffectivenessAbandonedData["answers_count"]
      ).forEach(([key, value]) => {
        countryData.push({
          label: key + " " + value,
          value: key,
        });
      });
    lexicalSurveyEffectivenessAbandonedData &&
      lexicalSurveyEffectivenessAbandonedData.question_graph &&
      lexicalSurveyEffectivenessAbandonedData.question_graph.length > 0 &&
      lexicalSurveyEffectivenessAbandonedData.question_graph.map((x) => {
        label.push(x.label);
        data.push(x.per);
      });
    setBarChartLabel(label);
    setBarChartData(data);
    setAbandonedCountry(countryData);
  }, [lexicalSurveyEffectivenessAbandonedData]);

  const typeField1 = useRef();

  const subDropdown1 = useRef();

  return (
    <div className="surveyEffectiveness">
      <div className="row">
        <div className="col-md-8 col-sm-12">
          <div className="row">
            <div className="col-md-7 col-sm-12">
              <div className={cn("", "analyseQuestionSelect")}>
                {questionData && questionData && questionData.length > 0 ? (
                  <Box
                    sx={{ maxWidth: 485, maxHeight: 55 }}
                    className="analysisCustomSelectBox"
                  >
                    <FormControl fullWidth>
                      <Select
                        name="question"
                        value={lexicalQuestionId || ""}
                        label=""
                        onChange={(e) => {
                          setshowloader(true);

                          dispatch(
                            surveyActions.setLexicalQuestionId(e.target.value)
                          );
                        }}
                        className="analysisCustomSelect"
                        MenuProps={{
                          classes: { paper: "selectPaperRoot" },
                        }}
                      >
                        {questionData &&
                          questionData &&
                          questionData.length > 0 &&
                          questionData.map((ques, index) => {
                            return (
                              <MenuItem
                                value={ques.qus_id}
                                key={index}
                                className="analysisMenuItem"
                              >
                                Q:{index + 1} {ques.question}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                ) : null}
              </div>
            </div>
            <div className="col-md-5 col-sm-12">
              {surveyData?.current_question_type ? (
                <div className={cn("analyseCard", "analyseQuestionType")}>
                  <span className="text-capitalize">
                    {surveyData &&
                      getFormattedLabel(surveyData?.current_question_type)}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className={cn("analyseCard", "mt-5")}>
        <div className="analyse-filter-box">
          <div className="title">User Response</div>
          <div className="analyse-filter-wrapper">
            <div className="select-items">
              <div className="select-dropdown">
                <div className="dropdown__menu--upload">
                  <button
                    className="dropdown__btn"
                    onClick={() => {
                      typeField1.current.classList.toggle("list--active");
                      subDropdown1.current.classList.remove("active");
                    }}
                  >
                    {geticon1() === "Facebook" ? (
                      <img
                        className="ml-5"
                        style={{ width: "25px", height: "25px" }}
                        src={Facebook}
                      ></img>
                    ) : geticon1() === "Reddit" ? (
                      <img
                        className="ml-5"
                        style={{ width: "25px", height: "25px" }}
                        src={Reddit}
                      ></img>
                    ) : geticon1() === "LinkedIn" ? (
                      <img
                        className="ml-5"
                        style={{ width: "25px", height: "25px" }}
                        src={
                          window.location.origin + "/images/SVG/linkedin.svg"
                        }
                      ></img>
                    ) : geticon1() === "WhatsApp" ? (
                      <img
                        className="ml-5"
                        style={{ width: "25px", height: "25px" }}
                        src={Whatsapp}
                      ></img>
                    ) : geticon1() === "Twitter" ? (
                      <img
                        className="ml-5"
                        style={{ width: "25px", height: "25px" }}
                        src={Twitter}
                      ></img>
                    ) : null}

                    {geticon1() === "Full_embed" ? (
                      <span>Full Embed</span>
                    ) : null}
                    {geticon1() === "Standard_embed" ? (
                      <span>Standard Embed</span>
                    ) : null}
                    {geticon1() === "Slider_embed" ? (
                      <span>Slider Embed</span>
                    ) : null}
                    {geticon1() === "SliderTop_embed" ? (
                      <span>Slider Top Embed</span>
                    ) : null}
                    {lexicalSurveyEffectivenessAbandonedChannel === "all"
                      ? "Channel"
                      : gettitle1()}
                    <KeyboardArrowDownRoundedIcon />
                  </button>

                  <ul className="dropdown__list" ref={typeField1}>
                    <li
                      onClick={() => {
                        dispatch(
                          surveyActions.setLexicalSurveyEffectivenessAbandonedSocialGroupFilter(
                            "all"
                          )
                        );
                        dispatch(
                          surveyActions.setLexicalSurveyEffectivenessAbandonedChannelFilter(
                            "email_link"
                          )
                        );
                        typeField1.current.classList.remove("list--active");
                      }}
                      className="dropdown__list--item"
                    >
                      <div className="title">Email Link</div>
                      <span className="delete-icon"></span>
                    </li>
                    <li
                      className="dropdown__list--item"
                      onClick={() => {
                        subDropdown1.current.classList.toggle("active");
                      }}
                    >
                      <div className="title">Social Link</div>
                      <span className="delete-icon">
                        <KeyboardArrowRightRoundedIcon />
                      </span>

                      <div className="subDropdown" ref={subDropdown1}>
                        <div className="innerPopup">
                          <div className="items">
                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setLexicalSurveyEffectivenessAbandonedChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setLexicalSurveyEffectivenessAbandonedSocialGroupFilter(
                                    "facebook"
                                  )
                                );
                                typeField1.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              {" "}
                              <img
                                className="ml-5"
                                style={{ width: "25px", height: "25px" }}
                                src={Facebook}
                              ></img>
                            </div>
                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setLexicalSurveyEffectivenessAbandonedChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setLexicalSurveyEffectivenessAbandonedSocialGroupFilter(
                                    "whatsapp"
                                  )
                                );
                                typeField1.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <img
                                className="ml-5"
                                style={{ width: "25px", height: "25px" }}
                                src={Whatsapp}
                              ></img>
                            </div>
                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setLexicalSurveyEffectivenessAbandonedChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setLexicalSurveyEffectivenessAbandonedSocialGroupFilter(
                                    "twitter"
                                  )
                                );
                                typeField1.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <img
                                className="ml-5"
                                style={{ width: "25px", height: "25px" }}
                                src={Twitter}
                              ></img>
                            </div>
                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setLexicalSurveyEffectivenessAbandonedChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setLexicalSurveyEffectivenessAbandonedSocialGroupFilter(
                                    "reddit"
                                  )
                                );
                                typeField1.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <img
                                className="ml-5"
                                style={{ width: "25px", height: "25px" }}
                                src={Reddit}
                              ></img>
                            </div>
                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setLexicalSurveyEffectivenessAbandonedChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setLexicalSurveyEffectivenessAbandonedSocialGroupFilter(
                                    "linkedin"
                                  )
                                );
                                typeField1.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <img
                                className="ml-5"
                                style={{ width: "25px", height: "25px" }}
                                src={
                                  window.location.origin +
                                  "/images/SVG/linkedin.svg"
                                }
                              ></img>
                            </div>
                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setLexicalSurveyEffectivenessAbandonedChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setLexicalSurveyEffectivenessAbandonedSocialGroupFilter(
                                    "full_embed"
                                  )
                                );

                                typeField1.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <span style={{ width: "25px", height: "25px" }}>
                                Full Embed
                              </span>
                            </div>

                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setLexicalSurveyEffectivenessAbandonedChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setLexicalSurveyEffectivenessAbandonedSocialGroupFilter(
                                    "standard_embed"
                                  )
                                );

                                typeField1.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <span style={{ width: "25px", height: "25px" }}>
                                Standard Embed
                              </span>
                            </div>

                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setLexicalSurveyEffectivenessAbandonedChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setLexicalSurveyEffectivenessAbandonedSocialGroupFilter(
                                    "slider_embed"
                                  )
                                );

                                typeField1.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <span style={{ width: "25px", height: "25px" }}>
                                Slider Embed
                              </span>
                            </div>

                            <div
                              className="list-item"
                              onClick={() => {
                                dispatch(
                                  surveyActions.setLexicalSurveyEffectivenessAbandonedChannelFilter(
                                    "socials_link"
                                  )
                                );

                                dispatch(
                                  surveyActions.setLexicalSurveyEffectivenessAbandonedSocialGroupFilter(
                                    "sliderTop_embed"
                                  )
                                );

                                typeField1.current.classList.remove(
                                  "list--active"
                                );
                              }}
                            >
                              <span style={{ width: "25px", height: "25px" }}>
                                Slider Top Embed
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li
                      onClick={() => {
                        dispatch(
                          surveyActions.setLexicalSurveyEffectivenessAbandonedSocialGroupFilter(
                            "all"
                          )
                        );
                        dispatch(
                          surveyActions.setLexicalSurveyEffectivenessAbandonedChannelFilter(
                            "link_sharing"
                          )
                        );
                        typeField1.current.classList.remove("list--active");
                      }}
                      className="dropdown__list--item"
                    >
                      <div className="title">Link Sharing</div>
                      <span className="delete-icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="item">
                <select
                  onChange={(e) => {
                    dispatch(
                      surveyActions.setLexicalSurveyEffectivenessAbandonedCountryFilter(
                        e.target.value
                      )
                    );
                  }}
                  value={lexicalSurveyEffectivenessAbandonedCountry}
                >
                  <option value={"all"}>All</option>;
                  {abandonedCountry && abandonedCountry.length > 0
                    ? abandonedCountry.map((value, index) => {
                        return (
                          <option value={value.value}>{value.label}</option>
                        );
                      })
                    : null}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div style={{ height: "300px" }}>
          <Bar
            options={{
              tooltips: {
                enabled: true,
                mode: "label",
                callbacks: {
                  title: function (tooltipItems, data) {
                    var idx = tooltipItems[0].index;
                    return "Title:" + data.labels[idx]; //do something with title
                  },
                  label: function (tooltipItems, data) {
                    return tooltipItems.xLabel + " €";
                  },
                },
              },

              // scales: {
              //   x: {
              //     ticks: {
              //       callback: function (value, index) {
              //         return "Q " + parseInt(index + 1); //truncate
              //       },
              //     },
              //   },
              // },
              responsive: true,
              maintainAspectRatio: false,
            }}
            data={{
              labels: barChartLabel,

              datasets: [
                {
                  label: `Number of Response By ${getLabel(
                    lexicalSurveyEffectivenessAbandonedChannel
                  )}
                `,
                  data: barChartData,
                  backgroundColor: transparentize(CHART_COLORS.red, 0.5),
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SurveyEffectiveness;
