import React from "react";
import styled from "styled-components";

export const Grid = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
`;

const GridItemWrapper = styled.div`
  flex: ${({cols}) => `0 0 ${(100/cols)}%`};
  display: flex;
  justify-content: center;
  align-items: stretch;
  box-sizing: border-box;
  border: 1px solid #fff;

  :before {
    content: "";
    display: table;
    padding-top: 100%;
  }
 
`;

export const GridItem = ({ forwardedRef, ...props }) => {
   return <GridItemWrapper ref={forwardedRef} {...props} style={props.gridStyle} />
};
