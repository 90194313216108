import React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const EmailCount = ({onEmailCountSubmit, count, setCount}) =>{

    const handleChange = (event) => {
        setCount(event.target.value);
    };
    return(
        <div className={`sendEmailBody`}>
            {<div className="head">
                <div className="icon"><img src={origin + "/images/SVG/sendemailicon.svg"} alt="link"/></div>
                Email Contacts
            </div>}
            <div className="sendEmailInner">
                <div className={"peopleCount"}>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p> */}
                    <h3>How many people do you want to send survey? </h3>
                    <FormControl sx={{ m: 1, minWidth:"100%" }} size="small" className={"selectBox"}>
                        <Select
                            value={count}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value={10}>0 - 10</MenuItem>
                            <MenuItem value={50}>10 - 50</MenuItem>
                            <MenuItem value={100}>50 - 100</MenuItem>
                            <MenuItem value={500}>100 - 500</MenuItem>
                            <MenuItem value={5000}>500 - more</MenuItem>
                        </Select>
                    </FormControl>
                    <div className="btnGroup">
                        <div className="btn btnDefault" onClick={onEmailCountSubmit}>Continue</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailCount;
