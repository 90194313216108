import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  Title,
} from "chart.js";
import { Line, Doughnut, Bar } from "react-chartjs-2";
import colorLib from "@kurkle/color";
import { Chart as GoogleChart } from "react-google-charts";
import "./style.css";
import { useEffect, useState } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Tooltip,
  Legend,
  Title
);

const transparentize = (value, opacity) => {
  var alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return colorLib(value).alpha(alpha).rgbString();
};

const CHART_COLORS = {
  red: "rgb(255, 99, 132)",
  orange: "rgb(255, 159, 64)",
  yellow: "rgb(255, 205, 86)",
  green: "rgb(75, 192, 192)",
  blue: "rgb(54, 162, 235)",
  purple: "rgb(153, 102, 255)",
  grey: "rgb(201, 203, 207)",
};

const data = (labels, data) => {
  return {
    labels,
    datasets: [
      {
        label: "Number Of Responses",
        data,
        borderColor: CHART_COLORS.red,
        backgroundColor: transparentize(CHART_COLORS.red, 0.5),
        pointStyle: "circle",
        pointRadius: 10,
        pointHoverRadius: 15,
      },
    ],
  };
};

const ChartComponent = ({ labels, info, text }) => {
  return (
    <Line
      data={data(labels, info)}
      options={{
        responsive: true,
        maintainAspectRatio: false,

        scales: {
          y: {
            ticks: {
              stepSize: 1,
            },

            min: 0,
          },
          x: {
            ticks: {
              maxTicksLimit: 31,
            },
          },
        },
        plugins: {
          legend: {
            position: "top",
            align: "middle",
            padding: {
              top: 4,
              bottom: -20,
            },
          },

          title: {
            display: true,
            text: text,
            align: "start",
            font: {
              size: 16,
            },

            responsive: true,
            animation: {
              animateScale: true,
            },
          },
        },
      }}
    />
  );
};
function dynamicColors() {
  var r = Math.floor(Math.random() * 255);
  var g = Math.floor(Math.random() * 255);
  var b = Math.floor(Math.random() * 255);
  return "rgba(" + r + "," + g + "," + b + ", 0.5)";
}
function poolColors(a) {
  var pool = [];
  for (let i = 0; i < a; i++) {
    pool.push(dynamicColors());
  }
  return pool;
}
const config = (data, label) => {
  return {
    labels: label,
    datasets: [
      {
        label: "# of Votes",
        data: data,
        backgroundColor: poolColors(data.length),
        borderColor: "#FFF",
        borderWidth: 2,
      },
    ],
  };
};

const DoughnutComponent = ({ text, data, label }) => {
  return (
    <div className="doughnut">
      <Doughnut
        data={config(data, label)}
        // options={{ plugins: { legend: { position: 'bottom', align: 'center' } } }}
        options={{
          responsive: true,
          maintainAspectRatio: true,

          plugins: {
            maintainAspectRatio: false,
            legend: { position: "bottom", align: "center" },
            title: {
              display: true,
              text: text,
              align: "start",
              color: "#8A94A6",
              font: {
                size: 18,
              },
              padding: {
                top: 10,
                bottom: 5,
              },
              responsive: true,
              animation: {
                animateScale: true,
              },
            },
          },
        }}
      />
    </div>
  );
};

const GoogleGeoChart = () => {
  return (
    <div>
      <GoogleChart
        width={"952px"}
        height={"527px"}
        chartType="GeoChart"
        data={[
          ["City", "Population", "Area"],
          ["Rome", 2761477, 1285.31],
          ["Milan", 1324110, 181.76],
          ["Naples", 959574, 117.27],
        ]}
        options={{
          region: "IT",
          displayMode: "markers",
          colorAxis: { colors: ["green", "blue"] },
        }}
        // Note: you will need to get a mapsApiKey for your project.
        // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
        mapsApiKey="YOUR_KEY_HERE"
        rootProps={{ "data-testid": "2" }}
      />
    </div>
  );
};

const BarChart = ({
  config: {
    position = "bottom",
    align = "center",
    titleDisplay = true,
    text = "",
    labels = [],
    backgroundColor = "",
    dataSet = [],
    height = "50%",
    width = "100%",
    onHoverLabel,
  } = {},
}) => {
  const options = {
      responsive: true,
      plugins: {
        legend: {
          position,
          align,
        },
        title: {
          display: titleDisplay,
          text,
        },
      },
    },
    data = {
      labels,
      datasets: [
        {
          label: onHoverLabel,
          data: dataSet,
          backgroundColor,
        },
      ],
    };

  return <Bar options={options} data={data} width={width} height={height} />;
};

const latLonToOffsets = (latitude, longitude, mapWidth, mapHeight) => {
    const radius = mapWidth / (2 * Math.PI),
      FE = 180, // false easting
      lonRad = degreesToRadians(longitude + FE),
      x = lonRad * radius,
      latRad = degreesToRadians(latitude),
      verticalOffsetFromEquator =
        radius * Math.log(Math.tan(Math.PI / 4 + latRad / 2)),
      y = mapHeight / 2 - verticalOffsetFromEquator;
    return { x, y };
  },
  degreesToRadians = (degrees) => {
    return (degrees * Math.PI) / 180;
  },
  renderPointer = (x, y, pointersContainer) => {
    const pointer = document.createElement("span");
    pointer.classList.add("pointer");
    pointer.style.position = "absolute";
    pointer.style.left = `${x}px`;
    pointer.style.top = `${y}px`;
    pointersContainer && pointersContainer.appendChild(pointer);
  };

const CustomChart = ({ audienceData = {} }) => {
  const [pointer, setPointer] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);

  let [showInfoWindow, setShowInfoWindow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const latitudeNY = 28.7041,
        longitudeNY = 77.1025,
        // Melbourne
        latitudeML = -37.867579,
        longitudeML = 145.048621,
        { clientWidth, clientHeight } =
          document.querySelector(".map img") || {},
        pointersContainer = document.querySelector("#pointers-container"),
        mapWidth = clientWidth,
        mapHeight = clientHeight;
      let map1 = [];
      const { data = [] } = audienceData || {};

      data.forEach(({ state = [] }) => {
        state.forEach(
          ({ latitude: lat = 0, longitude: lon = 0, state: city = "" }) => {
            if (lat && lon && city) {
              map1.push({ lat, lon, city });
            }
          }
        );
      });

      const arr = map1.map(({ lat, lon, city }) => {
        const { x, y } = latLonToOffsets(lat, lon, mapWidth, mapHeight);
        return { x, y, city };
      });
      setPointer(arr);
    }, 500);
  }, [audienceData]);

  return (
    <div className="map">
      <img src="/images/Mercator-projection.jpg" alt="world map" />
      <div id="pointers-container">
        {pointer && pointer.length > 0
          ? pointer.map((value, index) => {
              return (
                <>
                  {showInfoWindow && activeIndex === index ? (
                    <div
                      style={{
                        position: "absolute",
                        left: `${value.x - 46}px`,
                        top: `${value.y - 37}px`,
                        borderRadius: "10px",
                        padding: "10px",

                        background: "#FFFFFF",
                      }}
                      className="box"
                    >
                      <span
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {value.city}
                      </span>
                    </div>
                  ) : null}

                  <span
                    className="pointer"
                    onMouseOver={() => {
                      setShowInfoWindow(true);
                      setActiveIndex(index);
                    }}
                    onMouseOut={() => {
                      setShowInfoWindow(false);
                      setActiveIndex(null);
                    }}
                    style={{
                      position: "absolute",
                      left: `${value.x}px`,
                      top: `${value.y}px`,
                    }}
                  ></span>
                </>
              );
            })
          : null}
      </div>
    </div>
  );
};

export {
  ChartComponent,
  DoughnutComponent,
  GoogleGeoChart,
  BarChart,
  CustomChart,
};
