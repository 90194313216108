import React, { useEffect, useState, useRef } from "react";
import { PreviewDialog } from "./Preview/Preview";
import { useDispatch, useSelector } from "react-redux";
import { workspaceActions } from "../../actions/index";
import { workspaceService, emailService } from "../../services/index";
import { URL_VAL } from "../Common/Constants";
import { unscrambleEmail, cookieAccepted } from "../Common/Utils";
import CookieBanner from "../Common/CookieBanner";
import AlertPopup from "../Common/ConfirmationPrompt";
import {clone} from "../Common/Utils";
import DialogBox from "../Common/DialogBox";

const info_data = {
  address: {},
  userDetails: {
    group: "",
  },
};

const dummyAddress = {
  country_code: "",
  country_name: "",
  city: "",
  postal: "",
  latitude: 0,
  longitude: 0,
  IPv4: "0.0.0.0",
  state: "",
  state_code: "",
  flag: "",
  emoji_flag: "",
  emoji_unicode: "",
};

const FormSubmit = (props) => {
  
  const [surveyID, setSurveyID] = useState();
  // const[paymentquestions,setPaymentQuestions]=useState([])
  const [data, setData] = useState({});
  const [rawData, setRawData] = useState([]);
  const [info, setInfo] = useState(info_data);
  const [respBy, setRespBy] = useState({ email: "", group: "" });
  const [userAddress, setUserAddress] = useState({});
  const [basicAddress, setBasicAddress] = useState(dummyAddress);
  const [pageNotFound, setPageNotFound] = useState(false);
  const [err, setError] = useState("");
  const [validation, setValidation] = useState("");
  const { loading, success ,payres} = useSelector((state) => state.addData);
  const [showPopup, setPopup] = useState(false);
  const [isDemo, setDemo] = useState("");
  const [validateData,setValidateData] = useState([]);
  const [isCookiesEnable, setCookiesState] = useState(false);
  const [isPaymentTrue, setIsPaymentTrue] = useState(false);
  const [payment_info, setPaymentInfo] = useState(null);
  const { wsloading, res = {} } = useSelector((state) => state["wsDetails"]);

  const dispatch = useDispatch();

  useEffect(() => {
    let s_id = 0;
    let grpName = "";
    let hasGrp = false;
    let em = "";
    if (props?.match?.params) {
      const { id, personal } = props.match.params;
      if (id && id.includes(URL_VAL) && id.split("&")[1] === URL_VAL) {
        const u = id.split("&" + URL_VAL);
        s_id = u.length ? parseInt(u[0], 10) : parseInt(id, 10);
      } else if (id) {
        setPageNotFound(true);
        return;
      }

      if (id && personal) {
        s_id &&
          emailService.getAudience(s_id).then((res) => {
            if (res && res.data && res.data.audience) {
              const audi = res.data.audience;
              let matched = false;
              for (let i = 0; i < audi.length; i++) {
                const splitLink = audi[i].personal_link.split(id + "/");
                if (splitLink[splitLink.length - 1] === personal) {
                  matched = true;
                }
              }
              if (!matched && !props.ignoreUrlChk) {
                setPageNotFound(true);
                return;
              }
            }
          });
        hasGrp = personal.includes("grp=");
        if (hasGrp) {
          const g = personal.split("grp=");
          grpName = g.length > 1 ? g[1] : g[0];
        } else {
          const e = unscrambleEmail(personal);
          em = e && e.length ? e : "";
        }
      }
    }
    setRespBy((res) => ({ email: em, group: grpName }));
    setSurveyID(s_id);
    setInfo((info) => ({
      ...info,
      userDetails: { ...info.userDetails, group: grpName, Email: em },
    }));
  }, [props.match.params]);

  useEffect(() => {
    workspaceService.getUserAddress().then((res) => {
      if (res && res.country_name !== "") {
        const address = {
          country_code: res.country_code,
          country_name: res.country_name,
          city: res.city,
          postal: res.postal,
          latitude: res.latitude,
          longitude: res.longitude,
          IPv4: res.ip,
          state: res.region,
          state_code: res.region_code,
          flag: res.flag,
          emoji_flag: res.emoji_flag,
          emoji_unicode: res.emoji_unicode,
        };
        setUserAddress(address);
        setBasicAddress({
          country_code: res.country_code,
          country_name: res.country_name,
          flag: res.flag,
          emoji_flag: res.emoji_flag,
          emoji_unicode: res.emoji_unicode,
        });
        surveyID && dispatch(workspaceActions.getWsDetail(surveyID));
      }
    });
  }, [surveyID]);

  useEffect(() => {
    if (!wsloading && res) {
      setValidateData(res?.quest_desc)
      setCookiesState(res?.cookies);
    }
  }, [wsloading, res]);

  const onChange = (e) => {
    const { name, value, error = "" } = e;
    if (error && error.length) {
      setValidation(error);
      return;
    }
    if (value && ["Contact", "Email"].indexOf(value.q_type) > -1) {
      setInfo((info) => ({
        ...info,
        userDetails: {
          ...info.userDetails,
          [value.q_type]: value.answers[0].text,
        },
      }));
    }
    setData((data) => ({ ...data, [name]: value }));
  };

  const checkEmptyAns = (item) => {
    let isEmpty = true;
    if (item && item.answers && item.answers.length) {
      item.answers.forEach((a) => {
        let str = a.hasOwnProperty("text") ? String(a.text) : "";
        if (
          item.q_type === "Picture" &&
          str === "" &&
          a.hasOwnProperty("base64")
        ) {
          str = a.base64.length ? "base64Image" : "";
        }
        if (str.trim() != "") {
          isEmpty = false;
        }
      });
    }
    return isEmpty;
  };

  const validationForAmountPeriodAndMethod = () => {

    let errorFlag = false;
  
    const paymentQuestions = validateData?.questions_list?.filter(
      (question) => question.is_payment === true
    );
  
    if (!paymentQuestions || paymentQuestions.length === 0) {

      return false;
    }

    else{
      const questions = data?.paymentquestions || [];
  
      const hasAnswers = (question) => question.answers.length === 0;
    
      if (questions.length === 0) {
        setError("Select All Fields. First Name, Email, Contact, Payment Amounts, Methods and Period Types");
        errorFlag = true;
        return errorFlag;
      }
    
      questions.forEach((question) => {
        console.log(question,"questionquestion<><><??questionquestion")
        if (question.qus_text === "First Name" && question.is_payment) {
          if (hasAnswers(question)) {
            setError("Enter First Name");
            errorFlag = true;
          }
        } else if (question.qus_text === "Email" && question.is_payment) {
          if (hasAnswers(question)) {
            setError("Enter Email");
            errorFlag = true;
          }
        } else if (question.qus_text === "Contact" && question.is_payment) {
          if (hasAnswers(question)) {
            setError("Enter Phone number");
            errorFlag = true;
          }
        } else if (question.qus_text === "Amount" && question.is_payment) {
          if (hasAnswers(question)) {
            setError("Select amount");
            errorFlag = true;
          }
        } else if (question.qus_text === "Period Type" && question.is_payment) {
          if (hasAnswers(question)) {
            setError("Select period type");
            errorFlag = true;
          }
        }
      });
    
      if (!errorFlag) {
        setError("");
      }
    
      return errorFlag;
    }
  
  };


  const paymentProceed = (PaymentData) =>{
    const emailQuestion = PaymentData.find((question) => question.qus_text === 'Email');
    const phoneQuestion = PaymentData.find((question) => question.qus_text === 'Contact');
    const firstNameQuestion = PaymentData.find((question) => question.qus_text === 'First Name');
    const lastNameQuestion = PaymentData.find((question) => question.qus_text === 'Last Name');
    const amountQuestion = PaymentData.find((question) => question.q_type === 'Multiple' && question.qus_text === 'Amount');
    const periodQuestion = PaymentData.find((question) => question.q_type === 'Multiple' && question.qus_text === 'Period Type');
    const paymentMethodQuestion = PaymentData.find((question) => question.q_type === 'Multiple' && question.qus_text === 'Payment Methods');
    const emailAnswer = emailQuestion ? emailQuestion.answers.map((answer) => answer.text) : [];
    const serviceCharge =
    typeof amountQuestion.includeServiceCharge === "boolean"
      ? amountQuestion.includeServiceCharge
      : null;
    const serviceChargePercent = amountQuestion.serviceChargePercent ? amountQuestion.serviceChargePercent : null;
    const phoneAnswer = phoneQuestion ? phoneQuestion.answers.map((answer) => answer.text) : [];
    const firstNameAnswer = firstNameQuestion ? firstNameQuestion.answers.map((answer) => answer.text) : [];
    const lastNameAnswer = lastNameQuestion ? lastNameQuestion.answers.map((answer) => answer.text) : [];
    const amountAnswers = amountQuestion ? amountQuestion.answers.map((answer) => answer.text) : [];
    const amountConverted = amountAnswers[0];
    const periodAnswers = periodQuestion ? periodQuestion.answers.map((answer) => answer.text) : [];
    const paymentMethodAnswers = paymentMethodQuestion ? paymentMethodQuestion.answers.map((answer) => answer.text) : [];
 
      const payment_info = {
        amount: parseFloat(amountConverted) || null,
        payment_method: paymentMethodAnswers[0] || null,
        period_type: periodAnswers[0] || null,
        email: emailAnswer[0] || null,
        phone: phoneAnswer[0] || null,
        firstName: firstNameAnswer[0] || null,
        lastName: lastNameAnswer[0] || null,
        service_charge: serviceCharge,
        serviceChargePercent : serviceChargePercent,
      };
      return payment_info
    }

  const onSubmit = (e) => {
    if(validationForAmountPeriodAndMethod()){
      return
    }
    if (props.ignoreUrlChk) {
      return;
    }
    if (validation && validation.length) {
      setError(validation);
      setValidation("");
      return;
    }
    const cloned =clone(data) 
    let paymentInfo = null; 
    if (cloned.paymentquestions) {
        let PaymentData = cloned?.paymentquestions;
        paymentInfo = paymentProceed(PaymentData); 
        setIsPaymentTrue(true)
    }

    delete cloned["paymentquestions"]

    const answer_data = Object.values(cloned);
    
    // if (paymentInfo === "googlepay") {
    //   setGooglePayLoaded(true)
    
    //   // renderGooglePayButton();
      
    // }
  
    if (data && data.paymentquestions && data.paymentquestions.length > 0)
    {
      data.paymentquestions.forEach((e) => {
                answer_data.push(e)
       })
    }
    console.log(Object.values(data), data,answer_data,"FormSubmit")
    const requiredQues = rawData.filter(
      (r) => r?.settings?.require && !r.isHidden
    );
    let noEmptyRequiredQues = true;
    requiredQues.forEach((rq) => {
      let i = 0;
      for (i; i < answer_data.length; i++) {
        const ans = answer_data[i];
        if (rq.qus_id === ans.qus_id && !checkEmptyAns(ans)) {
          break;
        }
      }
      if (i === answer_data.length) {
        setError("Answer the all required questions!");
        noEmptyRequiredQues = false;
      }
    });
    if (noEmptyRequiredQues) {
      const add =
        isCookiesEnable? userAddress : basicAddress;
      const finalData = {
        detail_id: surveyID,
        answer_data,
        info_data: { ...info, address: add },
        paymentInfo,
      };

      if (isDemo) {
        setPopup(true);
        return;
      }
      dispatch(workspaceActions.addData(finalData));
      reset();
    }
  };

  const reset = () => {
    setData({});
  };

  const PageNotFound = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <div>
          <span style={{ fontSize: "6em" }}>Page Not Found</span>
          <p style={{ fontSize: "2em" }}>
            Invalid Url: Check shared survey url
          </p>
        </div>
      </div>
    );
  };

  return pageNotFound ? (
    <PageNotFound />
  ) : (
    <>
      {isCookiesEnable ? <CookieBanner /> : null}
      <PreviewDialog
        surveyID={surveyID}
        show={true}
          error={err}
          Cloneddata={data}
        setError={setError}
          setRawData={setRawData}
        onChange={onChange}
        onSubmit={onSubmit}
        submitted={success}
        paymentres={payres}
        isPaymentTrue={isPaymentTrue}
        responseBy={respBy}
        inline={props.inline}
        userAddress={userAddress}
        className="surveyAnswerPreview"
        setDemo={setDemo}
        previewFull
      />
      <AlertPopup
        show={showPopup}
        message="You can't answer a demo Form."
        singleBtn={true}
        btnLabel="OK"
        handler={() => setPopup(false)}
      />
    </>
  );
};

export default FormSubmit;
