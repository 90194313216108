import { useState, useEffect, useRef } from "react";
import Dropdown from "./Dropdown1";
import { useDispatch } from "react-redux";
import { surveyActions } from "../../../../actions";
import Facebook from "../../../../assets/images/Facebooks.png";
import Whatsapp from "../../../../assets/images/WhatsApp.png";
import Twitter from "../../../../assets/images/Twitter.png";
import Reddit from "../../../../assets/images/reddit.jpeg";

const MenuItems = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);
  const dispatch = useDispatch();
  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}
    >
      {items.submenu && items.icon ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.icon === "Facebook" ? (
              <img
                className="ml-5"
                style={{ width: "25px", height: "25px" }}
                src={Facebook}
              ></img>
            ) : items.icon === "WhatsApp" ? (
              <img
                className="ml-5"
                style={{ width: "25px", height: "25px" }}
                src={Whatsapp}
              ></img>
            ) : items.icon === "Reddit" ? (
              <img
                className="ml-5"
                style={{ width: "25px", height: "25px" }}
                src={Reddit}
              ></img>
            ) : items.icon === "LinkedIn" ? (
              <img
                className="ml-5"
                style={{ width: "25px", height: "25px" }}
                src={window.location.origin + "/images/SVG/linkedin.svg"}
              ></img>
            ) : items.icon === "Twitter" ? (
              <img
                className="ml-5"
                style={{ width: "25px", height: "25px" }}
                src={Twitter}
              ></img>
            ) : null}
            {items.title}
            {depthLevel > 0 ? (
              <span className="head"></span>
            ) : (
              <span className="arrow" />
            )}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : !items.icon && items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.icon === "Facebook" ? (
              <img
                className="ml-5"
                style={{ width: "25px", height: "25px" }}
                src={Facebook}
              ></img>
            ) : items.icon === "WhatsApp" ? (
              <img
                className="ml-5"
                style={{ width: "25px", height: "25px" }}
                src={Whatsapp}
              ></img>
            ) : items.icon === "Reddit" ? (
              <img
                className="ml-5"
                style={{ width: "25px", height: "25px" }}
                src={Reddit}
              ></img>
            ) : items.icon === "LinkedIn" ? (
              <img
                className="ml-5"
                style={{ width: "25px", height: "25px" }}
                src={window.location.origin + "/images/SVG/linkedin.svg"}
              ></img>
            ) : items.icon === "Twitter" ? (
              <img
                className="ml-5"
                style={{ width: "25px", height: "25px" }}
                src={Twitter}
              ></img>
            ) : null}
            {items.title}
            {depthLevel > 0 ? (
              <span className="head"></span>
            ) : (
              <span className="arrow" />
            )}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : (
        <div
          onClick={() => {
            if (
              items.icon === "Facebook" ||
              items.icon === "WhatsApp" ||
              items.icon === "Twitter" ||
              items.icon === "LinkedIn" ||
              items.icon === "Reddit"
            ) {
              dispatch(
                surveyActions.setSurveyEffectivenessAbandonedChannelFilter(
                  "socials_link"
                )
              );

              dispatch(
                surveyActions.setSurveyEffectivenessAbandonedSocialGroupFilter(
                  items.value
                )
              );
            } else {
              dispatch(
                surveyActions.setSurveyEffectivenessAbandonedSocialGroupFilter(
                  "all"
                )
              );
              dispatch(
                surveyActions.setSurveyEffectivenessAbandonedChannelFilter(
                  items.value
                )
              );
            }
          }}
          className="custom"
        >
          {items.icon === "Facebook" ? (
            <img
              className="ml-5"
              style={{ width: "25px", height: "25px" }}
              src={Facebook}
            ></img>
          ) : items.icon === "Reddit" ? (
            <img
              className="ml-5"
              style={{ width: "25px", height: "25px" }}
              src={Reddit}
            ></img>
          ) : items.icon === "LinkedIn" ? (
            <img
              className="ml-5"
              style={{ width: "25px", height: "25px" }}
              src={window.location.origin + "/images/SVG/linkedin.svg"}
            ></img>
          ) : items.icon === "WhatsApp" ? (
            <img
              className="ml-5"
              style={{ width: "25px", height: "25px" }}
              src={Whatsapp}
            ></img>
          ) : items.icon === "Twitter" ? (
            <img
              className="ml-5"
              style={{ width: "25px", height: "25px" }}
              src={Twitter}
            ></img>
          ) : null}
          {items.title}
        </div>
      )}
    </li>
  );
};

export default MenuItems;
