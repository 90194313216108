import { useState, useEffect } from "react";
import { ANALYSE_RESULTS_MENU } from "../../Common/Constants";
import { isFormType, setLocalStorage } from "../../Common/Utils";
import { useDispatch, useSelector } from "react-redux";
import AnalyseResults from "./AnalyseResults";
import AnalyseResultsMenu from "./AnalyseResultsMenu";
import { audienceActions, surveyActions } from "../../../actions/index";
const Analyse = ({ surveyID, type, analysisTab }) => {
  const questionId = useSelector((state) => state.audience.questionId);
  const longSurveyYear = useSelector((state) => state.survey.longSurveyYear);
  const longSurveyMonth = useSelector((state) => state.survey.longSurveyMonth);
  const surveyEffectivenessYear = useSelector(
    (state) => state.survey.surveyEffectivenessYear
  );
  const surveyEffectivenessMonth = useSelector(
    (state) => state.survey.surveyEffectivenessMonth
  );
  const lexicalSurveyMonth = useSelector(
    (state) => state.survey.lexicalSurveyMonth
  );
  const lexicalSurveyYear = useSelector(
    (state) => state.survey.lexicalSurveyYear
  );
  const surveyMonth = useSelector((state) => state.survey.surveyMonth);
  const surveyYear = useSelector((state) => state.survey.surveyYear);
  const aiFilter = useSelector((state) => state.survey.aiFilter);
  const surveyEffectivenessChannel = useSelector(
    (state) => state.survey.surveyEffectivenessChannel
  );
  const surveyEffectivenessSocialGroup = useSelector(
    (state) => state.survey.surveyEffectivenessSocialGroup
  );

  const surveyEffectivenessAbandonedChannel = useSelector(
    (state) => state.survey.surveyEffectivenessAbandonedChannel
  );
  const surveyEffectivenessAbandonedSocialGroup = useSelector(
    (state) => state.survey.surveyEffectivenessAbandonedSocialGroup
  );

  const lexicalSurveyEffectivenessAbandonedChannel = useSelector(
    (state) => state.survey.surveyLexicalEffectivenessAbandonedChannel
  );
  const lexicalSurveyEffectivenessAbandonedSocialGroup = useSelector(
    (state) => state.survey.surveyLexicalEffectivenessAbandonedSocialGroup
  );

  const surveyEffectivenessCountry = useSelector(
    (state) => state.survey.surveyEffectivenessCountry
  );
  const surveyEffectivenessAbandonedCountry = useSelector(
    (state) => state.survey.surveyEffectivenessAbandonedCountry
  );

  const lexicalSurveyEffectivenessAbandonedCountry = useSelector(
    (state) => state.survey.surveyLexicalEffectivenessAbandonedCountry
  );

  const audienceAiFilter = useSelector(
    (state) => state.survey.audienceAiFilter
  );
  const audienceStateFilter = useSelector(
    (state) => state.survey.audienceStateFilter
  );

  const lexicalAudienceStateFilter = useSelector(
    (state) => state.survey.lexicalAudienceStateFilter
  );

  const lexicalAudienceType = useSelector(
    (state) => state.audience.lexicalAudienceType
  );

  const audienceType = useSelector((state) => state.audience.audienceType);
  const longQuestionId = useSelector((state) => state.survey.longQuestionId);
  const otherQuestionId = useSelector((state) => state.survey.otherQuestionId);

  const lexicalQuestionId = useSelector(
    (state) => state.survey.lexicalQuestionId
  );

  const surveyStatus = useSelector((state) => state.audience.surveyStatus);
  const lexicalSurveyStatus = useSelector(
    (state) => state.audience.lexicalSurveyStatus
  );

  const rawDataQuestionId = useSelector(
    (state) => state.survey.rawDataQuestionId
  );
  const rawDataProfanity = useSelector(
    (state) => state.survey.rawDataProfanity
  );
  const rawDataSorting = useSelector((state) => state.survey.rawDataSorting);
  const rawDataPageFilter = useSelector(
    (state) => state.survey.rawDataPageFilter
  );

  const alertQuestionId = useSelector((state) => state.survey.alertQuestionId);
  const alertProfanity = useSelector((state) => state.survey.alertProfanity);
  const alertSorting = useSelector((state) => state.survey.alertSorting);
  const alertPageFilter = useSelector((state) => state.survey.alertPageFilter);

  const questionData = useSelector(
    (state) => state.survey.questionAnalysisData
  );

  const LexicalQuestionData = useSelector(
    (state) => state.survey.lexicalQuestionAnalysisData
  );
  const alertQuestionsData = useSelector(
    (state) => state.survey.alertQuestionsData
  );

  const rawDataSearching = useSelector(
    (state) => state.survey.rawDataSearching
  );

  const alertSearching = useSelector((state) => state.survey.alertSearching);

  const alertStatus = useSelector((state) => state.survey.alertStatus);

  const rawDataSentiments = useSelector(
    (state) => state.survey.rawDataSentiments
  );
  const alertSentiment = useSelector((state) => state.survey.alertSentiment);

  const [active, setActive] = useState(analysisTab || ANALYSE_RESULTS_MENU[0]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(surveyActions.getQuestionsAnalysis(surveyID));
  }, [surveyID]);
  useEffect(() => {
    dispatch(surveyActions.getLexicalQuestionsAnalysis(surveyID));
  }, [surveyID]);

  useEffect(() => {
    dispatch(surveyActions.getQuestionsAlerts(surveyID));
  }, [surveyID]);

  useEffect(()=>{
    setLocalStorage("activeSubTab", active);
  }, [active]);

  useEffect(() => {
    if (questionData !== null) {
      dispatch(
        surveyActions.getSurveyEffectivenessAbandoned(
          surveyID,
          surveyEffectivenessAbandonedChannel,
          surveyEffectivenessAbandonedCountry,
          surveyEffectivenessAbandonedSocialGroup,
          true
        )
      );
    }
  }, [
    surveyEffectivenessAbandonedChannel,
    surveyEffectivenessAbandonedCountry,
    surveyID,
    questionData,
    surveyEffectivenessAbandonedSocialGroup,
  ]);

  useEffect(() => {
    if (LexicalQuestionData !== null && lexicalQuestionId !== null) {
      dispatch(
        surveyActions.getLexicalSurveyEffectivenessAbandoned(
          surveyID,
          lexicalSurveyEffectivenessAbandonedChannel,
          lexicalSurveyEffectivenessAbandonedCountry,
          lexicalSurveyEffectivenessAbandonedSocialGroup,
          lexicalQuestionId
        )
      );
    }
  }, [
    lexicalSurveyEffectivenessAbandonedChannel,
    lexicalSurveyEffectivenessAbandonedCountry,
    lexicalSurveyEffectivenessAbandonedSocialGroup,
    surveyID,
    lexicalQuestionId,
    LexicalQuestionData,
  ]);

  useEffect(() => {
    if (questionData !== null) {
      dispatch(
        surveyActions.getSurveyEffectiveness(
          surveyID,
          surveyEffectivenessChannel,
          surveyEffectivenessCountry,
          surveyEffectivenessSocialGroup,
          surveyEffectivenessYear,
          surveyEffectivenessMonth,
          false
        )
      );
    }
  }, [
    surveyEffectivenessChannel,
    surveyEffectivenessCountry,
    surveyID,
    surveyEffectivenessSocialGroup,
    surveyEffectivenessMonth,
    questionData,
    surveyEffectivenessYear,
  ]);
  useEffect(() => {
    if (rawDataQuestionId !== null && questionData !== null) {
      dispatch(
        surveyActions.questionLevelRawData(
          rawDataQuestionId,
          rawDataProfanity,
          rawDataSentiments,
          rawDataSearching,
          rawDataSorting,
          rawDataPageFilter,
          surveyID
        )
      );
    }
  }, [
    rawDataQuestionId,
    rawDataProfanity,
    rawDataSentiments,
    rawDataSearching,
    rawDataSorting,
    rawDataPageFilter,
    questionData,

    surveyID,
  ]);

  useEffect(() => {
    if (alertQuestionId !== null && questionData !== null) {
      dispatch(
        surveyActions.questionLevelAlerts(
          alertQuestionId,
          alertProfanity,
          alertSentiment,
          alertSearching,
          alertSorting,
          alertPageFilter,
          surveyID,
          alertStatus
        )
      );
    }
  }, [
    alertQuestionId,
    alertProfanity,
    alertSentiment,
    alertSearching,
    alertSorting,
    alertPageFilter,
    questionData,
    alertQuestionsData,
    alertStatus,
    surveyID,
  ]);

  useEffect(() => {
    if (longQuestionId !== null && questionData !== null) {
      dispatch(
        surveyActions.getLongTextSummary(longQuestionId, aiFilter, surveyID)
      );
    }
  }, [longQuestionId, aiFilter, surveyID, questionData]);

  useEffect(() => {
    if (questionId !== null && questionData !== null) {
      dispatch(
        surveyActions.getLongTextAudienceSummary(
          questionId,
          audienceAiFilter,
          audienceStateFilter,
          surveyID
        )
      );
    }
  }, [
    questionId,
    audienceAiFilter,
    audienceStateFilter,
    surveyID,
    questionData,
  ]);

  useEffect(() => {
    if (otherQuestionId !== null && questionData !== null)
      dispatch(
        surveyActions.getSurveyOthers(
          otherQuestionId,
          surveyYear,
          surveyMonth,
          surveyID
        )
      );
  }, [
    otherQuestionId,
    surveyMonth,
    surveyYear,
    otherQuestionId,
    surveyID,
    questionData,
  ]);

  useEffect(() => {
    if (lexicalQuestionId !== null && LexicalQuestionData !== null)
      dispatch(
        surveyActions.getSurveyLexical(
          lexicalQuestionId,
          lexicalSurveyYear,
          lexicalSurveyMonth,
          surveyID
        )
      );
  }, [
    lexicalQuestionId,
    lexicalSurveyMonth,
    lexicalSurveyYear,
    lexicalQuestionId,
    surveyID,
    LexicalQuestionData,
  ]);

  useEffect(() => {
    if (questionId !== null && questionData !== null)
      dispatch(
        audienceActions.getAudience(
          audienceType,
          questionId,
          surveyStatus,
          surveyID
        )
      );
  }, [audienceType, questionId, surveyStatus, surveyID, questionData]);

  useEffect(() => {
    if (lexicalQuestionId !== null && LexicalQuestionData !== null)
      dispatch(
        audienceActions.getAudienceLexical(
          lexicalAudienceType,
          lexicalQuestionId,
          lexicalSurveyStatus,
          surveyID
        )
      );
  }, [
    lexicalAudienceType,
    lexicalQuestionId,
    lexicalSurveyStatus,
    surveyID,
    LexicalQuestionData,
  ]);
  useEffect(() => {
    if (longQuestionId !== null && questionData !== null) {
      dispatch(
        surveyActions.getSurveyLong(
          longQuestionId,
          longSurveyYear,
          longSurveyMonth,
          surveyID
        )
      );
    }
  }, [longQuestionId, longSurveyMonth, longSurveyYear, surveyID, questionData]);

  useEffect(() => {
    return () => {
      dispatch(surveyActions.resetReducer());
      dispatch(audienceActions.resetReducer());
    };
  }, []);
  return (
    <div className="analyseResultsBody">
      <AnalyseResultsMenu active={active} setActive={setActive} />
      <AnalyseResults active={active} isFormType={isFormType(type)} />
    </div>
  );
};

export default Analyse;
