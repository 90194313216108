import React, {useState} from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import {clone} from '../../Common/Utils';
import RandomGrid from './DnD/index';

const MultipleChoice = ({data, deleteChoice, editChoice, readOnly=false}) => {
    const {ans_options=[], settings={} } = data;

    const Option = ({item, index}) => {
        const [option, setOption] = useState(item);
        const onChange = (e) => {
            if(e && e.target){
                const cloned = clone(option);
                cloned[e.target.name] = e.target.value;
                setOption(cloned);
            }
        };
        const onBlur = (e) => {
            editChoice(e, index);
        };

        return <div key={'multi_'+index} className="item" style={settings.horizontal ? {marginTop: 10, width:'100%'} : {width:'100%'}}>
            <div className="Option">{String.fromCharCode(65+index)}</div>
            <input type="text" name="label" value={option.label} placeholder={'choice'} readOnly={readOnly} onChange={onChange} onBlur={onBlur} spellCheck={true}/>
            { index !==0 &&  <div className="cancel" onClick={()=>deleteChoice(index)}><CancelIcon/></div> }
        </div>
    };

    return <div className={`multipleInner ${settings.horizontal ? "horizontal":"vertical"}`} style={settings.horizontal ? {display: 'inherit'} : {}}>
        <RandomGrid items={ans_options} GridRenderer={Option} gridStyle={settings.horizontal?{height: 50,width: "24%"}:{height: 50,width: "51%"}} canDrag={settings.randomize} gridWrapStyle={settings.horizontal?{display:'flex'}:{}}/>
    </div>
};

export default MultipleChoice;
