import React, { useState, useEffect } from "react";
import {
  PublicClientApplication,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { MicrosoftLoginButton } from "react-social-login-buttons";
import { setLocalStorage, getLocalStorage } from "../../voc/Common/Utils";

import "./socialLogin.css";

// Configuration
//const MS_CLIENT_ID = "1c8d3638-0314-474a-9dab-f8740644912a";
const MS_CLIENT_ID = "05f8de0d-f2ba-4128-b516-b830c99d6098";
//const MS_REDIRECT_URI = "http://localhost:3000";
const MS_REDIRECT_URI = "https://testvoc.articence.com";
const MS_SCOPES = ["mail.send", "Contacts.Read", "offline_access", "Calendars.ReadWrite", "User.Read"];
//const AUTHORITY = "https://login.microsoftonline.com/241acc2f-5659-4390-9160-fcde536b90fe";
const AUTHORITY = "https://login.microsoftonline.com/common";

const LOGIN_REQUEST = {
  scopes: MS_SCOPES,
};

// Initialize MSAL instance
const pca = new PublicClientApplication({
  auth: {
    clientId: MS_CLIENT_ID,
    redirectUri: MS_REDIRECT_URI,
    authority: AUTHORITY,
  },
  cache: {
    cacheLocation: "sessionStorage", 
    storeAuthStateInCookie: true,
  },
});

const Outlook = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [outlookAccessToken, setAccessToken] = useState(null);

  const searchParams = new URLSearchParams(window.location.search);
  const inviteType = searchParams.get("invitetype");

  useEffect(() => {
    // Handle redirect login flow
    pca.handleRedirectPromise()
      .then((response) => {
        if (response) {
          console.log("Login successful via redirect:", response);
          handleLoginResponse(response);
        } else {
          console.log("No redirect response available.");
        }
      })
      .catch((error) => {
        console.error("Error during redirect handling:", error);
      });
  }, []);

  const handleLoginResponse = (loginResponse) => {
    const { accessToken, account = {} } = loginResponse;
    const { tenantId, username, name } = account;

    const userObj = {
      name,
      email: username,
      accountId: tenantId,
      type: "outlook",
    };

    setIsLoggedIn(true);
    setAccessToken(accessToken);
    setLocalStorage("auth", { ...userObj, account, accessToken });

    if (props.onSuccess) {
      props.onSuccess(userObj);
    }
  };

  const signInClickHandler = () => {
    console.log("Initiating Outlook login...");

    pca.loginPopup(LOGIN_REQUEST)
      .then((loginResponse) => {
        console.log("Login successful via popup:", loginResponse);
        handleLoginResponse(loginResponse);
      })
      .catch((error) => {
        console.error("Login failed:", error);
        if (error instanceof InteractionRequiredAuthError) {
          console.log("Interaction required, retrying with popup...");
          pca.loginPopup(LOGIN_REQUEST).then(handleLoginResponse);
        }
      });
  };

  const SignInButton = () => (
    <MicrosoftLoginButton 
    onClick={signInClickHandler} 
    />
  );

  useEffect(() => {
    if (inviteType === "outlook") {
      const timer = setTimeout(() => {
        const button = document.getElementById("microsoft-login-btn");
        if (button) {
          button.click(); 
        }
      }, 2000); 

      return () => clearTimeout(timer); 
    }
  }, [inviteType]);

  return props.showOnlyIcon ? (
    <span
      id="microsoft-login-btn"
      className="msalIconCircle"
      onClick={signInClickHandler}
      style={props.style || {}}
    >
      <img src="images/msLogo.png" className="msalIcon" alt="Microsoft Icon" />
    </span>
  ) : (
    <SignInButton id="microsoft-login-btn" />
  );
};

const getAccessToken = (cb) => {
  const { account = {} } = getLocalStorage("auth") || {};
  const request = { ...LOGIN_REQUEST, account };

  pca.acquireTokenSilent(request)
    .then((response) => {
      console.log("Access token acquired silently:", response);
      cb(response);
      
    })
    .catch((error) => {
      console.error("Silent token acquisition failed:", error);
      if (isInteractionRequired(error)) {
        pca.acquireTokenPopup(request).then(cb);
      } else {
        cb(null, error);
      }
    });
};

const isInteractionRequired = (err) => {
  const errorMessage = err.message || "";
  return (
    errorMessage.includes("consent_required") ||
    errorMessage.includes("interaction_required") ||
    errorMessage.includes("login_required") ||
    errorMessage.includes("no_account_in_silent_request")
  );
};

const getCalendarEvents = (accessToken, cb) => {
  const userMicro = localStorage.getItem("user");
  fetch("https://graph.microsoft.com/v1.0/me/events", {
    headers: {
      Authorization: `Bearer ${userMicro.access_token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      const events = data.value.map((event) => ({
        id: event.id,
        title: event.subject,
        start: new Date(event.start.dateTime),
        end: new Date(event.end.dateTime),
      }));
      cb(events, null);
    })
    .catch((error) => {
      console.error("Error fetching calendar events:", error);
      cb(null, error);
    });
};

export { getAccessToken, getCalendarEvents };
export default Outlook;
