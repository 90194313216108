import React from "react";
import Diagram, { useSchema, createSchema } from "beautiful-react-diagrams";
import "beautiful-react-diagrams/styles.css";

// Custom Node Component
const CustomNode = ({ id, data }) => {
  return (
    <div
      style={{
        padding: "10px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        background: "#fff",
        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        textAlign: "center",
        width: "150px",
      }}
    >
      <img
        src={data.image}
        alt={data.name}
        style={{ width: "50px", height: "50px", borderRadius: "50%" }}
      />
      <h4>{data.name}</h4>
      <p>{data.description}</p>
    </div>
  );
};

// Agent Data
const agentData = {
  "ticket-generation": {
    name: "Ticket Generation Agent",
    description: "Handles ticket generation for events",
    steps: [
      {
        id: "customer",
        name: "Customer",
        description: "Requests ticket generation",
        image: "https://via.placeholder.com/50",
      },
      {
        id: "ticket-agent",
        name: "Ticket Agent",
        description: "Generates the ticket",
        image: "https://via.placeholder.com/50",
      },
      {
        id: "confirmation",
        name: "Confirmation",
        description: "Sends confirmation to the customer",
        image: "https://via.placeholder.com/50",
      },
    ],
    links: [
      { input: "customer", output: "ticket-agent" },
      { input: "ticket-agent", output: "confirmation" },
    ],
  },
  "flight-booking": {
    name: "Flight Booking Agent",
    description: "Handles flight ticket booking",
    steps: [
      {
        id: "customer",
        name: "Customer",
        description: "Requests flight booking",
        image: "https://via.placeholder.com/50",
      },
      {
        id: "flight-agent",
        name: "Flight Agent",
        description: "Books the flight",
        image: "https://via.placeholder.com/50",
      },
      {
        id: "payment",
        name: "Payment",
        description: "Processes payment",
        image: "https://via.placeholder.com/50",
      },
      {
        id: "confirmation",
        name: "Confirmation",
        description: "Sends booking confirmation",
        image: "https://via.placeholder.com/50",
      },
    ],
    links: [
      { input: "customer", output: "flight-agent" },
      { input: "flight-agent", output: "payment" },
      { input: "payment", output: "confirmation" },
    ],
  },
  "email-sending": {
    name: "Email Sending Agent",
    description: "Handles email notifications",
    steps: [
      {
        id: "trigger",
        name: "Trigger",
        description: "Event triggers email sending",
        image: "https://via.placeholder.com/50",
      },
      {
        id: "email-agent",
        name: "Email Agent",
        description: "Sends the email",
        image: "https://via.placeholder.com/50",
      },
      {
        id: "status",
        name: "Status",
        description: "Checks email delivery status",
        image: "https://via.placeholder.com/50",
      },
    ],
    links: [
      { input: "trigger", output: "email-agent" },
      { input: "email-agent", output: "status" },
    ],
  },
};

// Function to generate schema dynamically
const generateSchema = (agentType) => {
  const agent = agentData[agentType];
  if (!agent) return createSchema({ nodes: [], links: [] });

  const nodes = agent.steps.map((step, index) => ({
    id: step.id,
    content: step.name,
    coordinates: [index * 200, 100], // Adjust coordinates for horizontal flow
    render: CustomNode,
    data: {
      image: step.image,
      name: step.name,
      description: step.description,
    },
  }));

  const links = agent.links;

  return createSchema({ nodes, links });
};

const DynamicWorkflow = ({ agentType }) => {
  const initialSchema = generateSchema(agentType);
  const [schema, { onChange }] = useSchema(initialSchema);

  return (
    <div
      style={{
        flex: "0.7",
        background: "#fff",
        padding: "15px",
        borderRadius: "8px",
        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
      }}
    >
      <h2>{agentData[agentType]?.name || "Select an Agent"}</h2>
      <p>{agentData[agentType]?.description || ""}</p>
      <div style={{ height: "500px", overflow: "auto" }}>
        <Diagram schema={schema} onChange={onChange} />
      </div>
    </div>
  );
};

export default DynamicWorkflow;