import { surveyConstants } from "../constants";
import { surveyService } from "../services";
import { audienceActions } from "./index";
import { swalAlert } from "../voc/Common/SwalAlert";

const setSurveyYear = (surveyYear) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETSURVEY_YEAR,
      surveyYear,
    });
  };
};

const setLexicalSurveyYear = (surveyYear) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETLEXICALSURVEY_YEAR,
      surveyYear,
    });
  };
};

const setLexicalSurveyMonth = (surveyMonth) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETLEXICALSURVEY_MONTH,
      surveyMonth,
    });
  };
};
const resetReducer = () => {
  return {
    type: surveyConstants.GETSURVEY_RESET,
  };
};

const setShowPrompt = (prompt) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.SHOWPROMPT,
      prompt,
    });
  };
};
const setAiRecommendationProcessingStatus = (status) => {
  return (dispatch) => {
    return dispatch({
      type: surveyConstants.AIRECOMMENDATIONSPROCESSINGSTATUS,
      status,
    });
  };
};

const setAiRecommendationQuestionId = (questionId) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.AIRECOMMENDATIONSQUESTIONID,
      questionId,
    });
  };
};

const setActiveMenu = (menu) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.ACTIVE_MENU,
      menu,
    });
  };
};

const setCategorizationQuestionId = (questionId) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.Tags_QUESTIONID,
      questionId,
    });
  };
};

const setSurveyEffectivenessCountryFilter = (country) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETSURVEYEFFECTIVENESS_COUNTRYFILTER,
      country,
    });
  };
};

const setSurveyEffectivenessChannelFilter = (channel) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETSURVEYEFFECTIVENESS_CHANNELFILTER,
      channel,
    });
  };
};
const setSurveyEffectivenessSocialGroupFilter = (channel) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETSURVEYEFFECTIVENESS_SOCIALGROUPFILTER,
      channel,
    });
  };
};

const setSurveyEffectivenessAbandonedCountryFilter = (country) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETSURVEYEFFECTIVENESSABANDONED_COUNTRYFILTER,
      country,
    });
  };
};

const setSurveyEffectivenessAbandonedChannelFilter = (channel) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETSURVEYEFFECTIVENESSABANDONED_CHANNELFILTER,
      channel,
    });
  };
};
const setSurveyEffectivenessAbandonedSocialGroupFilter = (channel) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETSURVEYEFFECTIVENESSABANDONED_SOCIALGROUPFILTER,
      channel,
    });
  };
};

const setLexicalSurveyEffectivenessAbandonedCountryFilter = (country) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETLEXICALSURVEYEFFECTIVENESSABANDONED_COUNTRYFILTER,
      country,
    });
  };
};

const setLexicalSurveyEffectivenessAbandonedChannelFilter = (channel) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETLEXICALSURVEYEFFECTIVENESSABANDONED_CHANNELFILTER,
      channel,
    });
  };
};
const setLexicalSurveyEffectivenessAbandonedSocialGroupFilter = (channel) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETLEXICALSURVEYEFFECTIVENESSABANDONED_SOCIALGROUPFILTER,
      channel,
    });
  };
};

const setAiFilter = (filter) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETSURVEY_AIFILTER,
      filter,
    });
  };
};
const setRawDataPageFilter = (filter) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETRAWDATAPAGE_FILTER,
      filter,
    });
  };
};
const setAlertPageFilter = (filter) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETALERTPAGE_FILTER,
      filter,
    });
  };
};

const setAlertStatus = (filter) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETALERT_STATUS,
      filter,
    });
  };
};

const setAudienceStateFilter = (filter) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETAUDIENCE_STATEFILTER,
      filter,
    });
  };
};

const setLexicalAudienceStateFilter = (filter) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETLEXICALAUDIENCE_STATEFILTER,
      filter,
    });
  };
};

const setProfanitySearchFilter = (filter) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.RAWDATASEARCHING,
      filter,
    });
  };
};
const setRawDataQuestionId = (questionId) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.RAWDATAQUESTION_ID,
      questionId,
    });
  };
};

const setAlertQuestionId = (questionId) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.ALERTQUESTION_ID,
      questionId,
    });
  };
};

const setAlertProfanity = (filter) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.ALERTPROFANITY,
      filter,
    });
  };
};

const setRawDataProfanity = (filter) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.RAWDATAPROFANITY,
      filter,
    });
  };
};

const setAlertSentiment = (filter) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.ALERTSENTIMENT,
      filter,
    });
  };
};

const setRawDataSentiment = (filter) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.RAWDATASENTIMENT,
      filter,
    });
  };
};
const setRawDataSorting = (filter) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.RAWDATASORTING,
      filter,
    });
  };
};

const setAlertSorting = (filter) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.ALERTSORTING,
      filter,
    });
  };
};

const setAudienceAiFilter = (filter) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETAUDIENCE_AIFILTER,
      filter,
    });
  };
};
const setSurveyMonth = (surveyMonth) => {
  return (dispatch) => {
    return dispatch({
      type: surveyConstants.GETSURVEY_MONTH,
      surveyMonth,
    });
  };
};
const setLongSurveyYear = (longSurveyYear) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETLONGSURVEY_YEAR,
      longSurveyYear,
    });
  };
};
const setLongSurveyMonth = (longSurveyMonth) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETLONGSURVEY_MONTH,
      longSurveyMonth,
    });
  };
};

const setSurveyEffectivenessSurveyYear = (year) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETSURVEYEFFECTIVENESS_YEAR,
      year,
    });
  };
};
const setSurveyEffectivenessSurveyMonth = (month) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETSURVEYEFFECTIVENESS_MONTH,
      month,
    });
  };
};

const setSentimentFilter = (filter) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETSENTIMENTFILTER,
      filter,
    });
  };
};
const setProfanityFilter = (filter) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETSENTIMENTFILTER,
      filter,
    });
  };
};
const setShortAnswerFilter = (filter) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETSHORTANSWERFILTER,
      filter,
    });
  };
};
const setAllFilter = (filter) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETALLFILTER,
      filter,
    });
  };
};
const setOtherQuestionId = (questionId) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETOTHERQUESTION_ID,
      questionId,
    });
  };
};

const setTriggerType = (trigger) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETHELPTYPETRIGGER,
      trigger,
    });
  };
};

const setLongQuestionId = (questionId) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETLONGQUESTION_ID,
      questionId,
    });
  };
};
const setLexicalQuestionId = (questionId) => {
  return (dispatch) => {
    dispatch({
      type: surveyConstants.GETLEXICALQUESTION_ID,
      questionId,
    });
  };
};
const getCategory = (questionId, surveyId) => {
  const request = () => ({ type: surveyConstants.GETCATEGORY_REQUEST });
  const success = (survey) => ({
    type: surveyConstants.GETCATEGORY_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.GETCATEGORY_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService.getCategoriesApi(questionId, surveyId).then(
      (survey) => {
        dispatch(success(survey));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const shareAlert = (alertId, type) => {
  const request = () => ({
    type: surveyConstants.GETSHAREALERT_REQUEST,
  });
  const success = (survey) => ({
    type: surveyConstants.GETSHAREALERT_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.GETSHAREALERT_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService.getShareAlertApi(alertId, type).then(
      (survey) => {
        dispatch(success(survey));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const respondAlert = (to, shareType, alertId, type, title) => {
  const request = () => ({
    type: surveyConstants.GETRESPONDALERT_REQUEST,
  });
  const success = (survey) => ({
    type: surveyConstants.GETRESPONDALERT_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.GETRESPONDALERT_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService.getRespondAlertApi(to, shareType, alertId, type, title).then(
      (survey) => {
        dispatch(success(survey));
        swalAlert({
          icon: "success",
          title: "Alert Sent",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getAiRecommendationsStatuses = (questionId, surveyId) => {
  const request = () => ({
    type: surveyConstants.AIRECOMMENDATIONSSTATUS_REQUEST,
  });
  const success = (survey) => ({
    type: surveyConstants.AIRECOMMENDATIONSSTATUS_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.AIRECOMMENDATIONSSTATUS_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService.getAiRecommendationsStatus(questionId, surveyId).then(
      (survey) => {
        dispatch(success(survey));
        // dispatch(surveyActions.setProce);
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const updateCustomCategory = (params) => {
  const request = () => ({
    type: surveyConstants.UPDATECUSTOMCATEGORIES_REQUEST,
  });
  const success = (survey) => ({
    type: surveyConstants.UPDATECUSTOMCATEGORIES_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.UPDATECUSTOMCATEGORIES_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());
    var obj = params;
    surveyService.updateCustomCategoriesApi(params).then(
      (survey) => {
        // alert("Custom Categories Added");
        swalAlert({
          icon: "success",
          title: "Custom Categories Added".toString(),
          showConfirmButton: true,
        });
        dispatch(success(survey));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getCustomCategory = (surveyId, questionId) => {
  const request = () => ({
    type: surveyConstants.GETCUSTOMCATEGORIES_REQUEST,
  });
  const success = (survey) => ({
    type: surveyConstants.GETCUSTOMCATEGORIES_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.GETCATEGORY_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService.getCustomCategories(questionId, surveyId).then(
      (survey) => {
        dispatch(success(survey));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const updateCategory = (params) => {
  const request = () => ({ type: surveyConstants.UPDATECATEGORY_REQUEST });
  const success = (survey) => ({
    type: surveyConstants.UPDATECATEGORY_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.UPDATECATEGORY_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService.updateCategoriesApi(params).then(
      (survey) => {
        dispatch(success(survey));
        swalAlert({
          icon: "success",
          title: "Categories Assigned Successfully".toString(),
          showConfirmButton: true,
        });
        // alert("Categories Assigned Successfully");
        dispatch(surveyActions.setActiveMenu("Tags"));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
        dispatch(surveyActions.setActiveMenu("Tags"));
      }
    );
  };
};

const updateComboCategory = (params) => {
  const request = () => ({ type: surveyConstants.UPDATECOMBOCATEGORY_REQUEST });
  const success = (survey) => ({
    type: surveyConstants.UPDATECOMBOCATEGORY_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.UPDATECOMBOCATEGORY_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService.updateComboCategoriesApi(params).then(
      (survey) => {
        dispatch(success(survey));
        swalAlert({
          icon: "success",
          title: "Categories Assigned Successfully".toString(),
          showConfirmButton: true,
        });
        // dispatch(surveyActions.setActiveMenu("Tags"));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
        // dispatch(surveyActions.setActiveMenu("Tags"));
      }
    );
  };
};

const addCategory = (params) => {
  const request = () => ({ type: surveyConstants.ADDCATEGORY_REQUEST });
  const success = (survey) => ({
    type: surveyConstants.ADDCATEGORY_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.ADDCATEGORY_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService.addCategoriesApi(params).then(
      (survey) => {
        dispatch(success(survey));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getSurveyOthers = (questionId, surveyYear, surveyMonth, surveyId) => {
  const request = () => ({ type: surveyConstants.GETSURVEY_REQUEST });
  const success = (survey) => ({
    type: surveyConstants.GETSURVEY_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.GETSURVEY_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService
      .getSurveyDetails(questionId, surveyYear, surveyMonth, surveyId)
      .then(
        (survey) => {
          dispatch(success(survey));
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
  };
};

const getSurveyLexical = (questionId, surveyYear, surveyMonth, surveyId) => {
  const request = () => ({ type: surveyConstants.GETLEXICALSURVEY_REQUEST });
  const success = (survey) => ({
    type: surveyConstants.GETLEXICALSURVEY_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.GETLEXICALSURVEY_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService
      .getLexicalSurveyDetails(questionId, surveyYear, surveyMonth, surveyId)
      .then(
        (survey) => {
          dispatch(success(survey));
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
  };
};

const getAnswerData = (questionId, surveyId, page, search, categoryFilter) => {
  const request = () => ({ type: surveyConstants.GETANSWERDATA_REQUEST });
  const success = (survey) => ({
    type: surveyConstants.GETANSWERDATA_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.GETANSWERDATA_FAILURE,
    error,
  });

  return (dispatch, getState) => {
    dispatch(request());
    let profanity;
    let short_question;
    let sentiment = [];
    if (
      getState().survey.sentimentFilter.findIndex(
        (x) => x.value === "profanity"
      ) === -1
    ) {
      profanity = false;
    } else {
      profanity = true;
    }
    if (
      getState().survey.sentimentFilter.findIndex(
        (x) => x.value === "shortAnswers"
      ) === -1
    ) {
      short_question = "all";
    } else {
      short_question = true;
    }
    if (
      getState().survey.sentimentFilter.findIndex(
        (x) => x.value === "positive"
      ) !== -1
    ) {
      sentiment.push("positive");
    } else {
      sentiment = sentiment.filter((item, i) => item !== "positive");
    }

    if (
      getState().survey.sentimentFilter.findIndex(
        (x) => x.value === "very positive"
      ) !== -1
    ) {
      sentiment.push("very positive");
    } else {
      sentiment = sentiment.filter((item, i) => item !== "very positive");
    }

    if (
      getState().survey.sentimentFilter.findIndex(
        (x) => x.value === "extremely positive"
      ) !== -1
    ) {
      sentiment.push("extremely positive");
    } else {
      sentiment = sentiment.filter((item, i) => item !== "extremely positive");
    }
    if (
      getState().survey.sentimentFilter.findIndex(
        (x) => x.value === "negative"
      ) !== -1
    ) {
      sentiment.push("negative");
    } else {
      sentiment = sentiment.filter((item, i) => item !== "negative");
    }

    if (
      getState().survey.sentimentFilter.findIndex(
        (x) => x.value === "very negative"
      ) !== -1
    ) {
      sentiment.push("very negative");
    } else {
      sentiment = sentiment.filter((item, i) => item !== "very negative");
    }

    if (
      getState().survey.sentimentFilter.findIndex(
        (x) => x.value === "extremely negative"
      ) !== -1
    ) {
      sentiment.push("extremely negative");
    } else {
      sentiment = sentiment.filter((item, i) => item !== "extremely negative");
    }

    if (
      getState().survey.sentimentFilter.findIndex(
        (x) => x.value === "neutral"
      ) !== -1
    ) {
      sentiment.push("neutral");
    } else {
      sentiment = sentiment.filter((item, i) => item !== "neutral");
    }
    surveyService
      .getAnswerData(
        questionId,
        surveyId,
        page,
        profanity,
        sentiment,
        short_question,
        search,
        categoryFilter
      )
      .then(
        (survey) => {
          dispatch(success(survey));
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
  };
};

const getSurveyLong = (
  questionId,
  longSurveyYear,
  longSurveyMonth,
  surveyId
) => {
  const request = () => ({ type: surveyConstants.GETLONGSURVEY_REQUEST });
  const success = (survey) => ({
    type: surveyConstants.GETLONGSURVEY_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.GETLONGSURVEY_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService
      .getSurveyDetails(questionId, longSurveyYear, longSurveyMonth, surveyId)
      .then(
        (survey) => {
          dispatch(success(survey));
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
  };
};

const getSurveyEffectiveness = (
  surveyId,
  channel,
  country,
  social,
  surveyEffectivenessYear,
  surveyEffectivenessMonth,
  getAbandoned
) => {
  const request = () => ({
    type: surveyConstants.GETSURVEYEFFECTIVENESS_REQUEST,
  });
  const success = (survey) => ({
    type: surveyConstants.GETSURVEYEFFECTIVENESS_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.GETSURVEYEFFECTIVENESS_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService
      .getSurveyEffectivenessData(
        surveyId,
        channel,
        country,
        social,
        surveyEffectivenessYear,
        surveyEffectivenessMonth,
        getAbandoned
      )
      .then(
        (survey) => {
          dispatch(success(survey));
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
  };
};

const getSurveyEffectivenessAbandoned = (
  surveyId,
  channel,
  country,
  social,
  getAbandoned
) => {
  const request = () => ({
    type: surveyConstants.GETSURVEYEFFECTIVENESSABANDONED_REQUEST,
  });
  const success = (survey) => ({
    type: surveyConstants.GETSURVEYEFFECTIVENESSABANDONED_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.GETSURVEYEFFECTIVENESSABANDONED_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService
      .getSurveyEffectivenessAbandonedData(
        surveyId,
        channel,
        country,
        social,
        getAbandoned
      )
      .then(
        (survey) => {
          dispatch(success(survey));
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
  };
};

const getLexicalSurveyEffectivenessAbandoned = (
  surveyId,
  channel,
  country,
  social,
  questionId
) => {
  const request = () => ({
    type: surveyConstants.GETLEXICALSURVEYEFFECTIVENESSABANDONED_REQUEST,
  });
  const success = (survey) => ({
    type: surveyConstants.GETLEXICALSURVEYEFFECTIVENESSABANDONED_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.GETLEXICALSURVEYEFFECTIVENESSABANDONED_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService
      .getLexicalSurveyEffectivenessAbandonedData(
        surveyId,
        channel,
        country,
        social,
        questionId
      )
      .then(
        (survey) => {
          dispatch(success(survey));
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
  };
};
const postAiRecommendations = (
  surveyId,
  questionId,
  refineCategories,
  discoverRelations,
  customList,
  redo
) => {
  const request = () => ({
    type: surveyConstants.AIRECOMMENDATIONSPOST_REQUEST,
  });
  const success = (survey) => ({
    type: surveyConstants.AIRECOMMENDATIONSPOST_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.AIRECOMMENDATIONSPOST_FAILURE,
    error,
  });

  return (dispatch, getState) => {
    dispatch(request());
    let profanity;
    let short_question;
    let sentiment = [];
    if (
      getState().survey.sentimentFilter.findIndex(
        (x) => x.value === "profanity"
      ) === -1
    ) {
      profanity = false;
    } else {
      profanity = true;
    }
    if (
      getState().survey.sentimentFilter.findIndex(
        (x) => x.value === "shortAnswers"
      ) === -1
    ) {
      short_question = "all";
    } else {
      short_question = true;
    }
    if (
      getState().survey.sentimentFilter.findIndex(
        (x) => x.value === "positive"
      ) !== -1
    ) {
      sentiment.push("positive");
    } else {
      sentiment = sentiment.filter((item, i) => item !== "positive");
    }
    if (
      getState().survey.sentimentFilter.findIndex(
        (x) => x.value === "negative"
      ) !== -1
    ) {
      sentiment.push("negative");
    } else {
      sentiment = sentiment.filter((item, i) => item !== "negative");
    }
    if (
      getState().survey.sentimentFilter.findIndex(
        (x) => x.value === "neutral"
      ) !== -1
    ) {
      sentiment.push("neutral");
    } else {
      sentiment = sentiment.filter((item, i) => item !== "neutral");
    }
    let newRedo;
    if (redo === "merge") {
      newRedo = false;
    } else {
      newRedo = true;
    }
    localStorage.setItem("question", questionId);
    surveyService
      .postAiRecommended(
        surveyId,
        questionId,
        refineCategories,
        discoverRelations,
        customList,
        profanity,
        short_question,
        sentiment,
        newRedo
      )
      .then(
        (survey) => {
          dispatch(
            surveyActions.setAiRecommendationQuestionId(
              localStorage.getItem("question")
            )
          );
          dispatch(surveyActions.setAiRecommendationProcessingStatus(false));

          dispatch(surveyActions.setAiRecommendationProcessingStatus(true));
          swalAlert({
            icon: "success",
            title: survey.data.message.toString(),
            showConfirmButton: true,
          });

          dispatch(surveyActions.setActiveMenu("Tags"));
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
          dispatch(surveyActions.setActiveMenu("Tags"));
        }
      );
  };
};

const getAiRecommendations = (surveyId, questionId, search, pageNumber) => {
  const request = () => ({
    type: surveyConstants.AIRECOMMENDATIONS_REQUEST,
  });
  const success = (survey) => ({
    type: surveyConstants.AIRECOMMENDATIONS_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.AIRECOMMENDATIONS_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService
      .getRecommendedAi(surveyId, questionId, search, pageNumber)
      .then(
        (survey) => {
          // dispatch(
          //   success({
          //     data: {
          //       answers: [
          //         {
          //           bold: [
          //             "free time",
          //             "best hobby",
          //             "I",
          //             "mind",
          //             "me",
          //             "better mental wellbeing",
          //             "meditation retreat",
          //           ],
          //           new_bold: [
          //             "a better mental wellbeing",
          //             "free time",
          //             "best hobby",
          //             "meditating and spending my free time",
          //             "My best hobby",
          //             "my free time",
          //             "I",
          //             "mental wellbeing",
          //             "my mind",
          //             "better mental wellbeing",
          //             "meditation retreat",
          //             "meditation retreats",
          //           ],
          //           sentence:
          //             "My best hobby is meditating and spending my free time at meditation retreats. I think it has really helped me understand my mind and its complexities and in ensuring a better mental wellbeing.",
          //           sentiment: [
          //             "positive",
          //             {
          //               negative: 0.0014,
          //               neutral: 0.0326,
          //               positive: 0.9661,
          //             },
          //           ],
          //         },
          //         {
          //           bold: [
          //             "favourite thing",
          //             "new place",
          //             "trip",
          //             "I",
          //             "me",
          //             "spending time",
          //             "solo travelling",
          //             "pet dog",
          //             "year",
          //             "him",
          //             "bliss",
          //             "family",
          //           ],
          //           new_bold: [
          //             "favourite thing",
          //             "new place",
          //             "trips",
          //             "I",
          //             "spending time",
          //             "solo travelling",
          //             "pet dog",
          //             "the bliss",
          //             "named Cookie and spending time",
          //             "a pet dog",
          //             "him",
          //             "time",
          //             "my family",
          //             "new places",
          //             "my favourite things",
          //           ],
          //           sentence:
          //             "I have a pet dog named Cookie and spending time with him is one of my favourite things to do. I also love to travel and I often plan trips with my family. But solo travelling gives me the bliss that I crave for. Every year I look forward to exploring new places and catching up on some lifetime memories.",
          //           sentiment: [
          //             "positive",
          //             {
          //               negative: 0.001,
          //               neutral: 0.0079,
          //               positive: 0.991,
          //             },
          //           ],
          //         },
          //         {
          //           bold: [
          //             "free time",
          //             "football",
          //             "analytical thinking skill",
          //             "creativity",
          //             "me",
          //             "I",
          //             "high school",
          //             "captain of my college team",
          //           ],
          //           new_bold: [
          //             "free time",
          //             "football",
          //             "my free time",
          //             "analytical thinking skill",
          //             "I",
          //             "high school",
          //             "captain of my college team",
          //             "my creativity and analytical thinking skills",
          //           ],
          //           sentence:
          //             "I have played football since high school and I have also been the captain of my college team. I love playing football in my free time as it helps me unwind, relax as well as boosts my creativity and analytical thinking skills.",
          //           sentiment: [
          //             "positive",
          //             {
          //               negative: 0.0013,
          //               neutral: 0.0178,
          //               positive: 0.9808,
          //             },
          //           ],
          //         },
          //       ],
          //       recommendations: [
          //         {
          //           level: "Level0/#9e0142",
          //           word: "hobby free",
          //         },
          //         {
          //           level: "Level0/#9e0142",
          //           word: "time",
          //         },
          //       ],
          //     },
          //   })
          // );
          dispatch(success(survey.data));
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
  };
};
const getLongTextSummary = (questionId, aiFilter, surveyId) => {
  const request = () => ({ type: surveyConstants.GETLONGTEXT_REQUEST });
  const success = (survey) => ({
    type: surveyConstants.GETLONGTEXT_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.GETLONGTEXT_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService.showAllLongTextSummary(questionId, aiFilter, surveyId).then(
      (survey) => {
        dispatch(success(survey));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getLongTextAudienceSummary = (
  questionId,
  aiFilter,
  audienceStateFilter,
  surveyId
) => {
  const request = () => ({ type: surveyConstants.GETLONGTEXTAUDIENCE_REQUEST });
  const success = (survey) => ({
    type: surveyConstants.GETLONGTEXTAUDIENCE_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.GETLONGTEXTAUDIENCE_FAILURE,
    error,
  });

  return (dispatch, getState) => {
    dispatch(request());

    surveyService
      .showAudienceLongTextSummary(
        questionId,
        aiFilter,
        audienceStateFilter,
        surveyId,
        getState().audience.audienceType,
        getState().audience.surveyStatus
      )
      .then(
        (survey) => {
          dispatch(success(survey));
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
  };
};

const getCategoryInsights = (surveyId) => {
  const request = () => ({ type: surveyConstants.CATEGORYINSIGHTS_REQUEST });
  const success = (survey) => ({
    type: surveyConstants.CATEGORYINSIGHTS_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.CATEGORYINSIGHTS_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService.getCategorizationInsights(surveyId).then(
      (survey) => {
        dispatch(success(survey));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getCategoryInsightsByQuestion = (surveyId, questionId) => {
  const request = () => ({
    type: surveyConstants.CATEGORYINSIGHTSBYQUESTION_REQUEST,
  });
  const success = (survey) => ({
    type: surveyConstants.CATEGORYINSIGHTSBYQUESTION_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.CATEGORYINSIGHTSBYQUESTION_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService
      .getCategorizationInsightsByQuestion(surveyId, questionId)
      .then(
        (survey) => {
          dispatch(success(survey));
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
  };
};
const questionLevelRawData = (
  rawDataQuestionId,
  rawDataProfanity,
  rawDataSentiments,
  rawDataSearching,
  rawDataSorting,
  rawDataPageFilter,
  surveyId
) => {
  const request = () => ({
    type: surveyConstants.GETRAWDATA_REQUEST,
  });
  const success = (survey) => ({
    type: surveyConstants.GETRAWDATA_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.GETRAWDATA_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService
      .getQuestionLevelRawData(
        rawDataQuestionId,
        rawDataProfanity,
        rawDataSentiments,
        rawDataSearching,
        rawDataSorting,
        rawDataPageFilter,
        surveyId
      )
      .then(
        (survey) => {
          dispatch(success(survey));
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
  };
};

const questionLevelAlerts = (
  alertQuestionId,
  alertProfanity,
  alertSentiments,
  alertSearching,
  alertSorting,
  alertPageFilter,
  surveyId,
  alertStatus
) => {
  const request = () => ({
    type: surveyConstants.GETALERTDATA_REQUEST,
  });
  const success = (survey) => ({
    type: surveyConstants.GETALERTDATA_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.GETALERTDATA_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService
      .getQuestionLevelAlertData(
        alertQuestionId,
        alertProfanity,
        alertSentiments,
        alertSearching,
        alertSorting,
        alertPageFilter,
        surveyId,
        alertStatus
      )
      .then(
        (survey) => {
          dispatch(success(survey));
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
  };
};

const getLexicalQuestionsAnalysis = (surveyId) => {
  const request = () => ({
    type: surveyConstants.GETLEXICALQUESTIONANALYSIS_REQUEST,
  });
  const success = (survey) => ({
    type: surveyConstants.GETLEXICALQUESTIONANALYSIS_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.GETLEXICALQUESTIONANALYSIS_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService.getLexicalSurveyQuestions(surveyId).then(
      (survey) => {
        dispatch(success(survey));
        // dispatch()
        if (survey && survey.data && survey.data.length > 0) {
          dispatch(setLexicalQuestionId(survey.data[0].qus_id));
        }
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getQuestionsAnalysis = (surveyId) => {
  const request = () => ({ type: surveyConstants.GETQUESTIONANALYSIS_REQUEST });
  const success = (survey) => ({
    type: surveyConstants.GETQUESTIONANALYSIS_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.GETQUESTIONANALYSIS_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService.getSurveyQuestions(surveyId).then(
      (survey) => {
        dispatch(success(survey));
        // dispatch()
        if (survey && survey.data && survey.data.long.length > 0) {
          dispatch(setLongQuestionId(survey.data.long[0].qus_id));
          dispatch(audienceActions.setQuestionId(survey.data.long[0].qus_id));
          dispatch(setRawDataQuestionId(survey.data.long[0].qus_id));
        }
        if (
          survey &&
          survey.data &&
          survey.data.long.length === 0 &&
          survey &&
          survey.data &&
          survey.data.others.length > 0
        ) {
          dispatch(setRawDataQuestionId(survey.data.others[0].qus_id));
          dispatch(audienceActions.setQuestionId(survey.data.others[0].qus_id));

          dispatch(setRawDataQuestionId(survey.data.others[0].qus_id));
        }
        if (survey && survey.data && survey.data.others.length > 0) {
          dispatch(setOtherQuestionId(survey.data.others[0].qus_id));
        }
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getQuestionsAlerts = (surveyId) => {
  const request = () => ({ type: surveyConstants.GETQUESTIONALERTS_REQUEST });
  const success = (survey) => ({
    type: surveyConstants.GETQUESTIONALERTS_SUCCESS,
    survey,
  });
  const failure = (error) => ({
    type: surveyConstants.GETQUESTIONALERTS_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    surveyService.getAlertQuestions(surveyId).then(
      (survey) => {
        dispatch(success(survey));
        // dispatch()
        survey.data.questions.length &&
          dispatch(setAlertQuestionId(survey.data.questions[0].qus_id));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

export const surveyActions = {
  setSurveyYear,
  setSurveyMonth,
  setLongSurveyYear,
  getSurveyLexical,
  getLongTextAudienceSummary,
  setLongSurveyMonth,
  setAudienceStateFilter,
  getQuestionsAnalysis,
  setLongQuestionId,
  setOtherQuestionId,
  setTriggerType,
  getSurveyOthers,
  setRawDataQuestionId,
  setRawDataSentiment,
  setRawDataSorting,
  setAlertQuestionId,
  setAlertSentiment,
  setAlertSorting,
  getSurveyLong,
  getAnswerData,
  getSurveyEffectiveness,
  setProfanitySearchFilter,
  getLongTextSummary,
  setRawDataPageFilter,
  setAlertPageFilter,

  setSurveyEffectivenessCountryFilter,
  setSurveyEffectivenessChannelFilter,
  setAudienceAiFilter,
  setSurveyEffectivenessSocialGroupFilter,
  setRawDataProfanity,
  setAlertProfanity,
  setLexicalQuestionId,
  questionLevelRawData,
  setAiFilter,
  getAiRecommendations,
  getCategoryInsights,
  getCategoryInsightsByQuestion,
  setSentimentFilter,
  setProfanityFilter,
  setAllFilter,
  setShortAnswerFilter,
  postAiRecommendations,
  getCategory,
  setActiveMenu,
  updateCategory,
  getLexicalQuestionsAnalysis,
  addCategory,
  updateCustomCategory,
  updateComboCategory,
  getCustomCategory,
  setCategorizationQuestionId,
  setLexicalAudienceStateFilter,
  getAiRecommendationsStatuses,
  setAiRecommendationQuestionId,
  setAiRecommendationProcessingStatus,
  setSurveyEffectivenessAbandonedCountryFilter,
  setSurveyEffectivenessAbandonedChannelFilter,
  setSurveyEffectivenessAbandonedSocialGroupFilter,
  setLexicalSurveyEffectivenessAbandonedCountryFilter,
  setLexicalSurveyEffectivenessAbandonedChannelFilter,
  setLexicalSurveyEffectivenessAbandonedSocialGroupFilter,

  getSurveyEffectivenessAbandoned,
  setSurveyEffectivenessSurveyYear,
  setSurveyEffectivenessSurveyMonth,
  setShowPrompt,
  resetReducer,
  getQuestionsAlerts,
  questionLevelAlerts,
  shareAlert,
  setAlertStatus,
  respondAlert,
  setLexicalSurveyYear,
  setLexicalSurveyMonth,
  setLexicalQuestionId,
  getLexicalSurveyEffectivenessAbandoned,
};
