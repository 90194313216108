import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";

const ManagerDelegationPopup = ({ open, onClose, manager }) => {
  const handleSend = () => {
    alert("Delegation message sent!");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Manager Delegation</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" gutterBottom>
          Delegate tasks to another manager
        </Typography>
        <FormControl fullWidth margin="normal">
          <div>Select Manager</div>
          <Select>
            <MenuItem value="1">Manager A</MenuItem>
            <MenuItem value="2">Manager B</MenuItem>
            <MenuItem value="3">Manager C</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Delegation Message"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSend} color="primary" variant="contained">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManagerDelegationPopup;
