import { territoryConstants } from "../constants/TerritoryConstants";

const initialState = {
  loading: false,
  isTerritoryAdded: false,
  territory: [],
  message: "",
};

export function territoryLogic(state = initialState, action) {
  switch (action.type) {
    case territoryConstants.ADD_TERRITORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case territoryConstants.ADD_TERRITORY_SUCCESS:
      return {
        ...state, 
        loading: false,
        isTerritoryAdded: true, // Set success flag to true
        territory: [...state.territory, action.territory], // Optional: update agents list in state if needed
      };
    case territoryConstants.ADD_TERRITORY_FAILURE:
      return {
        ...state,
        loading: false, 
        message: action.error,
        type: "error",
      };
      case territoryConstants.RESET_TERRITORY_ADDED:
  return {
    ...state,
    isTerritoryAdded: false,
  };

    default:
      return state;
  }
}
