import React, { useState, useEffect } from 'react';
import {clone, shuffle} from '../../../Common/Utils';
import DoneIcon from '@mui/icons-material/Done';

const TypeMultipleChoice = (props) =>{
    const {layout, children, qus_id, question, desc="", qno=1, srNo=1, settings={}, ans_options=[], cardRef, quesType, onChange, qRefs, submit, btnText='Next', sliderView, onFocus } = props;
    const { font, underline, bold, fontSize, italic, fontColor, multiple_selection, require, randomize } = settings,
        style = { fontFamily: font, fontSize, fontWeight: bold ? 'bold' : '', fontStyle: italic ? 'italic' : '', textDecoration: underline ? 'underline' : '', color: fontColor };

    const [select, setSelect] = React.useState({[qus_id]:[]});
    const [err, setErr] = React.useState('');
    const [shuffled, setShuffling] = React.useState(false);
    const [ansOptions, setAnsOptions] = React.useState([]);

    useEffect(()=>{
        setAnsOptions(ans_options);
    }, [ans_options]);

    useEffect(()=>{
        const answerChoice = (window.answeredData && window.answeredData[question]) ? window.answeredData[question] : {};
        let ob = {[qus_id]:[]};
        if(answerChoice.length){
            ob = answerChoice.reduce( (obj, item) => Object.assign(obj, { [qus_id]: obj[qus_id] ? obj[qus_id].push(item.id ? (item.id-1) : item.id) : [item.id ? (item.id-1) : item.id] }), {});
        }
        setSelect(ob);
    }, [qus_id]);

    useEffect(()=>{
        if(randomize && shuffled === false && ans_options && ans_options.length){
           setAnsOptions(shuffle(ans_options));
            setShuffling(true);
        }

    }, [shuffled])

    useEffect(()=> {
        const elements = document.getElementsByName("question_"+qno);
        let ele = elements.length ? elements[0] : elements;
        ele.style.setProperty('font-family', font, 'important');
    }, [font]);

    const onHandleSelect = (e)=>{
        triggerFocus();
        let cloned = clone(select);
        cloned[qus_id] = cloned[qus_id] || [];
        if(multiple_selection){
            const idx = select[qus_id].indexOf(e);
            (idx > -1) ? cloned[qus_id].splice(idx,1) : cloned[qus_id].push(e);
        } else {
            if(cloned[qus_id].length === 0){
                cloned[qus_id].push(e);
            } else {
                cloned[qus_id][0] = e;
            }
        }
        setSelect(cloned);
        saveChanges(cloned[qus_id]);
    };

    const onNext = () => {
        if(btnText === 'Next'){
            cardRef && cardRef.current.next();
            (qRefs?.current?.length >=qno) && setTimeout(() => { qRefs.current[qno]?.focus() }, 10);
        }else{
            submit && submit();
        }
    };

    const saveChanges = (list) => {
        if(require && list.length === 0){
            setErr('This is required.');
            return;
        }
        if(list.length){
            const answers = list.map((i) => {
                return {
                    id: ansOptions[i].id || i,
                    text: ansOptions[i].label,
                }
            });
            let obj = {
                "qus_id": qus_id,
                "q_type":quesType,
                "qus_text": question,
                answers
            };
            onChange && onChange({name: qno, value:obj});
        }
        setErr('');
    };

    const triggerFocus = () => {
        onFocus && qno > 0 && onFocus(qno-1);
    };

    const Choices = () => {
        return ansOptions.map((a, i)=>{
            return <div key={"ch-"+i} className={`item ${select[qus_id]?.indexOf(i) > -1 ? "selected":""}`} onClick={(e)=>onHandleSelect(i)}>
                <div className="Option">{String.fromCharCode(65+i)}</div>
               {/* <input type="text" value={a.label} disabled title={a.label}/>*/}
                <p>{a.label}</p>
                {select[qus_id]?.indexOf(i) > -1 && <div className="selectedIcon"><DoneIcon/></div>}
            </div>
        });
    };

    return(
        <div className="imageWrapInner">
            <div className="ques">
            <h3 name={"question_"+qno} style={style}>{srNo}-{question}
                <span style={{color:"red", marginLeft:'5px',display: require?'unset':'none'}}>*</span>
            </h3>
                <em> {desc} </em>
            </div>
            {layout == 1 && children}
            <div className={`multipleInner ${settings.horizontal ? "horizontal":"vertical"}`} style={settings.horizontal ? {}: {display:'block'}} ref={ (r)=> qRefs && (qRefs.current[qno-1] = r) } tabIndex={-1}>
                <Choices/>
            </div>
            { err !== "" ? <div> <p style={{color:'red'}}>{err}</p></div> : <div><p>&nbsp;</p></div> }

            { sliderView ? <div className="btnGroup">
                <button className="btnSave" onClick={onNext}>{btnText}</button>
            </div> : null}
        </div>
    )
};

export default TypeMultipleChoice
