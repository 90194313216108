export const customCallConstant = {
    // GET DATA
    REGISTER_SIP: "REGISTER_SIP",  
    GET_SESSION: "GET_SESSION",
    GET_AUDIO_REF: "GET_AUDIO_REF",
    MAKE_CALL: "MAKE_CALL",
    SET_INCOMING_CALL: "SET_INCOMING_CALL",
    ACCEPT_CALL: "ACCEPT_CALL",
    HOLD_CALL: "HOLD_CALL",
    UNHOLD_CALL: "UNHOLD_CALL",
    MUTE_CALL: "MUTE_CALL",
    UNMUTE_CALL: "UNMUTE_CALL",
    TRANSFER_CALL: "TRANSFER_CALL",
    PROGRESS_CALL: "PROGRESS_CALL",
    END_CALL: "END_CALL",
    DECLINE_CALL: "DECLINE_CALL",
    START_CAPTURE_VOICE: "START_CAPTURE_VOICE",
    STOP_CAPTURE_VOICE: "STOP_CAPTURE_VOICE",
    TRANSCRIPTION: "TRANSCRIPTION",
    STORE_CALL_LOGS: "STORE_CALL_LOGS",
    UPDATE_CALL_LOGS: "UPDATE_CALL_LOGS",
    GET_CALL_LOGS: "GET_CALL_LOGS",
    GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
    GET_CUSTOMER_STATUS: "GET_CUSTOMER_STATUS",
    GET_CUSTOMER_DATA: "GET_CUSTOMER_DATA",
    GET_ALL_EMAILS: "GET_ALL_EMAILS",
    GET_CALL_SUMMARY: "GET_CALL_SUMMARY",
    GET_CALL_SUMMARY_SUCCESS: "GET_CALL_SUMMARY_SUCCESS",
    GET_CALL_START_TIME: "GET_CALL_START_TIME",
    GET_CALL_SUMMARY_FAILURE: "GET_CALL_SUMMARY_FAILURE",
    CAPTURE_AGENT_VOICE: "CAPTURE_AGENT_VOICE",
    STOP_CAPTURE_AGENT_VOICE: "STOP_CAPTURE_AGENT_VOICE",
    STORE_CALL_SESSION: "STORE_CALL_SESSION"
};
