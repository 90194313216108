export const callRouteConstant = {
    ADD_ACTIVE_CALL: "ADD_ACTIVE_CALL",
    REMOVE_ACTIVE_CALL: "REMOVE_ACTIVE_CALL",
    ADD_TO_QUEUE: "ADD_TO_QUEUE",
    REMOVE_FROM_QUEUE: "REMOVE_FROM_QUEUE",
    GET_SYSTEM_USERS: "GET_SYSTEM_USERS",
    GET_SYSTEM_USERS_ERROR: "GET_SYSTEM_USERS_ERROR",
    ADD_CALLER_LIST: "ADD_CALLER_LIST",
    SET_AI_AUDIO: "SET_AI_AUDIO",
    SET_AI_ACTIVE_CALL:'SET_AI_ACTIVE_CALL',
    SET_AI_AGENT: 'SET_AI_AGENT',
    SET_AI_RESPONSE: 'SET_AI_RESPONSE',
    SET_USER_TYPE: "SET_USER_TYPE"
};

