// import React from 'react';
// import ReactDOMServer from "react-dom/server";
// import {setter} from "./EmbedPage";

// const Standard = (props) => {
//     const {url} = props;
//     const contentStyle = {width:'100%', height:'100%', position:'relative', padding:'20px 0', boxSizing:'border-box'};
//     const [codeStr, setCodeStr] = React.useState('');

//     const surveyFrame  = () => {
//         return <div style={contentStyle}>
//             <iframe id={"_iframe"} width="100%" height="100%"  style={{textAlign:'center', pointerEvents:'unset', border:'none'}} src={url} />
//         </div>
//     }

//     React.useEffect(()=>{
//         const str = ReactDOMServer.renderToStaticMarkup(surveyFrame());
//         setter(str.replace("embed/survey_ans", "survey_ans"));
//         setCodeStr(str);
//     },[]);

//     return <div dangerouslySetInnerHTML={{__html: codeStr.replace(/&amp;/g, "&")}}  style={{height:'calc(100% - 60px)'}}/>
// };

// export default Standard;
import React, { useState, useRef, useEffect } from "react";
import { setter } from "./EmbedPage";

const Standard = (props) => {
  const ref = useRef();
  const [height, setHeight] = useState("0px");
  const [width, setWidth] = useState("0px");

  const { url } = props;
  const contentStyle = {
    width: "100%",
    height: "100%",
    position: "relative",
    padding: "20px 0",
    boxSizing: "border-box",
  };
  const getDOMString = (hi, wi) => {
    const src = url.replace("embed/survey_ans", "survey_ans");

    return `
    <div style=" width: "100%";
    height: "100%";
    position: "relative";
    padding: "20px 0";
    boxSizing: "border-box"">
    <iframe
      id="articenceFormIFrame-232323285892056"
      title="Form"
      onload="window.parent.scrollTo(0,0)"
      allowtransparency="true"
      allowfullscreen="true"
      src=${src}
      frameborder="0"
      style="min-width:100%;max-width:100%;height:${hi};border:none;text-align:center; pointer-events:unset"
      scrolling="no"
    >
    </iframe>
    </div>
   `;
  };
  const onLoad = () => {
    setTimeout(() => {
      setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
      setWidth(window.innerWidth + "px");

      setter(
        getDOMString(
          ref.current.contentWindow.document.body.scrollHeight + "px",
          window.innerWidth + "px"
        )
      );
    }, [2000]);
  };
  const src = url.replace("embed/survey_ans", "survey_ans");

  useEffect(() => {
    onLoad();
  }, []);
  React.useEffect(() => {}, []);
  return (
    <div style={contentStyle}>
      <iframe
        ref={ref}
        onLoad={onLoad}
        className="w-full"
        id="iframe"
        //   style={{width:""}}
        width={"100%"}
        src={src}
        height={height}
        scrolling="no"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default Standard;
