import React, { useEffect, useState } from "react";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Tooltip,
    Button,
    Box,
} from "@mui/material";
import { Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { customCallActions } from "../../actions/customCallActions";
import CallSummary from "../Dialer/CallSummary";
import {useMediaQuery} from "@mui/material";
import Backdrop from "../../voc/Common/Backdrop";

const AllCallLogs = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [callLogFilter, setCallLogFilter] = useState(100);
    const [openSummary, setSummaryOpen] = useState(false);

    const { callLogs } = useSelector((store) => ({
        callLogs: store?.customCall?.callLogs?.call_logs,
    }));

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            customCallActions.getAllCallLogsWithFilter(`?per_page=${callLogFilter}`)
        );
    }, [callLogFilter, dispatch]);

    const columns = callLogs?.length > 0 ? Object.keys(callLogs[0]) : [];


    function handleOpenSummary(callId) {
        dispatch(customCallActions.getCallSummary(callId));
        setSummaryOpen(true);
    }

    const loading = useSelector((state) => state?.customCall?.loading);

    const handleCloseSummary = () => {
        setSummaryOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Paginate rows safely
    const paginatedRows =
        callLogs?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        ) ?? [];

    const hasData = callLogs && callLogs.length > 0;

    const isMobile = useMediaQuery("(max-width:600px)");

    return (
        <Paper sx={{ p: 2, ml: isMobile ? 0 : 6 }}>
        <Box my={2} p={2} display={"flex"} justifyContent={"space-between"}>
        <Typography.Title level={4}>Call Logs</Typography.Title>
        </Box>

        <TableContainer
        sx={{
            maxHeight: 400,
                overflowY: "auto",
        }}
        >
        <Table stickyHeader size="small">
        <TableHead>
        <TableRow>
        {hasData ? (
            <>
            {columns?.map((col) => (
                <TableCell
                key={col}
                sx={{ fontWeight: "bold", borderBottom: "1px solid #ddd" }}
                >
                {col.charAt(0).toUpperCase() + col.slice(1)}
                </TableCell>
            ))}
            <TableCell
            sx={{ fontWeight: "bold", borderBottom: "1px solid #ddd" }}
            >
            Summary
            </TableCell>
            </>
        ) : (
            <TableCell align="center">No Data Available</TableCell>
        )}
        </TableRow>
        </TableHead>

        <TableBody>
        {!hasData ? (
            <TableRow>
            <TableCell colSpan={columns.length + 1} align="center">
            No data found
            </TableCell>
            </TableRow>
        ) : (
            paginatedRows.map((row, index) => (
                <TableRow key={index} hover>
                {columns.map((col) => (
                    <TableCell
                    key={col}
                    sx={{ borderBottom: "1px solid #eee" }}
                    >
                    {col === "text" && row[col] ? (
                        <Tooltip title={row[col]} arrow>
                        <span>
                        {row[col].length > 16
                            ? `${row[col].slice(0, 16)}...`
                            : row[col]}
                        </span>
                        </Tooltip>
                    ) : row[col] !== undefined && row[col] !== null ? (
                        row[col]
                    ) : (
                        "N/A"
                    )}
                    </TableCell>
                ))}
                <TableCell sx={{ borderBottom: "1px solid #eee" }}>
                <Button
                variant="outlined"
                size="small"
                onClick={() => handleOpenSummary(row.id)}
                >
                View
                </Button>
                </TableCell>
                </TableRow>
            ))
        )}
        </TableBody>
        </Table>
        </TableContainer>

        {hasData && (
            <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={callLogs?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            />
        )}

        {/* Loader */}
        {loading ? (
            <Backdrop show={loading} message="Loading call summary..." />
        ) : (
            <CallSummary open={openSummary} handleClose={handleCloseSummary} />
        )}

        {/* Placeholder for CallSummary */}
        {/* <CallSummary open={openSummary} handleClose={handleCloseSummary} /> */}
        </Paper>
    );
};

export default AllCallLogs;
