import React, { lazy, Suspense, useEffect, useState} from "react";
import { Box, Grid, Paper, Typography, Avatar, TextField, styled, Button } from "@mui/material";
import { PhoneMissed, Group, GroupAdd, AccessTime } from "@mui/icons-material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { CrmService } from "../../services";
import { getBusinessStatus } from "../../helpers/getBizHours";
import { Header, MainMenu } from "../../voc/Common/Header";
import { useDispatch, useSelector } from "react-redux";
import { customCallActions } from "../../actions/customCallActions";
import { useHistory } from "react-router-dom";
import CallDetailsPopup from "../Dialer/CallDetails";
import Backdrop from "../../voc/Common/Backdrop";
import DynamicLoader from "../../common/DynamicLoader";
import { customerEngagementActions } from "../../actions/customerEngagementActions";
import DashboardFilter from "./DashboardFilter";
import { getUser } from "../../voc/Common/Utils";
import { AdminService } from "../../services/AdminService";
import { jwtDecode } from "jwt-decode"; 
import PhoneIcon from "@mui/icons-material/Phone";
import DialPad from "../Dialer/DialerModal";
import {useMediaQuery} from "@mui/material"
import { useLocation } from "react-router-dom";




const CustomerStatus = lazy(() => import("./CustomerStatus"));
const CallVolume = lazy(() => import("./CallVolume"));
const EmailQueueChart = lazy(() => import("./EmailQueueChart"));
const RecentCalls = lazy(() => import("./RecentCalls"));

const CallButton = styled(Button)({
  background: "linear-gradient(to bottom, #33ccff, #0099ff)", 
  borderRadius: "20px",
  color: "white",
  padding: "8px 16px",
  fontSize: "18px",
  fontWeight: "bold",
  textTransform: "none",
  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  "&:hover": {
    background: "linear-gradient(to bottom, #0099ff, #007acc)",
  },
});

const CallCenterDashboard = () => {
  const [customerDetails, setCustomerDetails] = React.useState([]);
  const [callLogs, setCallLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dashboardFilter, setDashboardFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [role, setRole] = useState("");
  const [openEngagementModal, setOpenEngagementModal] = useState(false);
  const [filter, setFilter] = useState({
    per_page: 5,
    current_page: 1,
  });
  const [toggleDate, setToggleDate] = useState("today_date"); // today_date || date_range
  const location = useLocation();
  const navigate = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));
  const [openDialer, setOpenDialer] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  
  const redirectuserId = location?.state?.state?.redirectUser?.id;
  const redirectUserName = location?.state?.state?.redirectUser?.name

  useEffect(() => {
    if (!user) {
      navigate.push("/login"); // Redirect to login if user is null
    }
  }, [user, navigate]);

  const dispatch = useDispatch();

  const customerStats = {
    total: 16,
    active: 12,
    inactive: 4,
  };

  const handleOnChangeFilter = (event) => {
    const { name, value } = event.target;

    // Update global filter
    setDashboardFilter((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Synchronize email filter with global filter
    setEmailFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleOnChangeEmailFilter = (event) => {
    const { name, value } = event.target;
    setEmailFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function buildQueryParams(filters) {
    const params = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });
    return `?${params.toString()}`;
  }

  useEffect(() => {
    setDashboardFilter("");
    setEmailFilter("");
  }, [toggleDate]);

//   useEffect(() => {
//     const user = JSON.parse(localStorage.getItem("user"));    
//     const token = user?.access_token;
    
//     if (token) {
//       try {
//         const decodedToken = jwtDecode(token);
//         setRole(decodedToken.role);      
//       } catch (error) {
//         console.error("Invalid token:", error);
//       }
//     }

//     if (user?.isProfileCreated == true && role == "User") {
//         const TeamId = user.team_id; 
//         const payload = {
//             email_ids: [user.email] 
//         };

//         AdminService.addMembersToTeam(payload, TeamId)
//             .then(response => {
//                 console.log("User added to team:", response.data);
//             })
//             .catch(error => {
//                 console.error("Error adding user to team:", error);
//             });
//     }
// }, []);

  useEffect(() => {
    let filterQuery = buildQueryParams(dashboardFilter);

    if (redirectuserId) {
      filterQuery += `${filterQuery ? "&" : "?"}user_id=${redirectuserId}`;
    }

    dispatch(customCallActions.getCallDashboardData(filterQuery));
  }, [dashboardFilter, dispatch, redirectuserId]);

  useEffect(() => {
    let filterQuery = buildQueryParams(emailFilter);

    if (redirectuserId) {
      filterQuery += `${filterQuery ? "&" : "?"}user_id=${redirectuserId}`;
    }

    dispatch(
      customCallActions.getAllEmailLogsWithFilter(
        filterQuery + `&sortby=opened_count`
      )
    );
  }, [emailFilter, dispatch, redirectuserId]);

    useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));    
    const token = user?.access_token;
    
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setRole(decodedToken.role);      
      } catch (error) {
        console.error("Invalid token:", error);
      }
    }
  }, [])

  const { dashboardData } = useSelector((state) => ({
    dashboardData: state.customCall?.dashboardData,
  }));

  const userDashboardData = role === "User" ? dashboardData?.[0] : dashboardData;
  

  useEffect(() => {
    const user = getUser();

    if (!user) {
        console.warn("No user found, skipping API calls");
        return; // Stop execution if user is not available
    }
    
    const filterQuery = buildQueryParams(dashboardFilter);

    (async () => {
      try {
        //const data = await CrmService.getAllCustomers();
        // const allCallLogs = await CrmService.getAllCallLogsWithFilter(
        //   `${filterQuery}&per_page=${filter.per_page}&current_page=${filter.current_page}`
        // );

        let customersApiUrl = "";
        if (redirectuserId) {
          customersApiUrl = `?user_id=${redirectuserId}`;
        }

        const data = await CrmService.getCallAllCustomers(customersApiUrl);

        let apiUrl = `${filterQuery}&per_page=${filter.per_page}&current_page=${filter.current_page}`;
        if (redirectuserId) {
          apiUrl += `&user_id=${redirectuserId}`;
        }
  
        const allCallLogs = await CrmService.getAllCallLogsWithFilter(apiUrl);

        setCustomerDetails(data?.data?.customers);
        setCallLogs(allCallLogs?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Stop loading once all APIs have been processed
      }
    })();
  }, [dashboardFilter]);

  // Assuming `customerDetails` contains the customer information
  const agentStatusData = customerDetails?.reduce((acc, customer) => {
    const businessStatus = getBusinessStatus(customer.timezone).status;

    // Map statuses to their display names and colors
    const statusMapping = {
      within: { name: "Available", color: "#4CAF50" },
      lunch: { name: "Lunch Break", color: "#FFC107" },
      outside: { name: "Away", color: "#F44336" },
    };

    const status = statusMapping[businessStatus];

    if (status) {
      const existingStatus = acc.find((item) => item.name === status.name);

      if (existingStatus) {
        existingStatus.value++;
      } else {
        acc.push({ name: status.name, value: 1, color: status.color });
      }
    }

    return acc;
  }, []);

  // total number of active customers
  const activeCustomers = customerDetails?.filter((customer) => {
    const businessStatus = getBusinessStatus(customer.timezone).status;
    return businessStatus === "within";
  }).length;

  // active customer ratio
  const activeCustomerRatio = (
    (activeCustomers / customerStats.total) *
    100
  ).toFixed(2);

  // Pie Chart Function
  const redirectCustomerStatus = (status) => {
    dispatch(customCallActions.getCustomerStatus(status));
    navigate.push("/customerTable");
  };

  // Open popup with selected data
  const handleInfoClick = async (customer_id) => {
    setOpenEngagementModal(true);
    dispatch(customerEngagementActions.getCustomerEngagement(customer_id));
    dispatch(customerEngagementActions.getCallLogs(customer_id));
    dispatch(customerEngagementActions.getSummaryEngagement(customer_id));
  };

  const isLoading = useSelector(
    (state) =>
      state?.customerEngagementLogic?.customerLoading ||
      state?.customerEngagementLogic?.summaryLoading ||
      state?.customerEngagementLogic?.logsLoading
  );
  const message = useSelector(
    (state) => state?.customerEngagementLogic?.message
  );

  if (loading) {
    return <Backdrop show={loading} />;
  }

  const handleCallClick = () => {
    setOpenDialer(true);
  };


  return (
    <Box
      sx={
        {
          // bgcolor: "#EFF3EA80",
        }
      }
    >
      {/* <Header /> 
      <MainMenu /> */}

      <Box sx={{ px: 4, pt: 3 }}>
        {/* Filter Options */}
        <DashboardFilter
          dashboardFilter={dashboardFilter}
          handleOnChangeFilter={handleOnChangeFilter}
          toggleDate={toggleDate}
          setToggleDate={setToggleDate}
          redirectName={redirectUserName}
        />
        <CallButton startIcon={<PhoneIcon />} onClick={handleCallClick} sx={{margin: isMobile ? "10px" : ""}}>
      Call Now
    </CallButton>
      </Box>
      <Box sx={{ flexGrow: 1, px: 5, mt: 3 }}>
        {/* Top Stats Cards */}
        <Grid container spacing={3} sx={{ mb: 3 }}>
          {[
            {
              title: "Total Customers",
              value: userDashboardData?.dashboard?.["no_of_customers"] ?? 0,
              icon: <Group style={{ color: "#2c7fff" }} />, // Ensure icon color contrasts with avatarBg
              color: "#2c7fff",
              textColor: "#fff",
              subtext: "+2.5% from last week",
              avatarBg: "#fff",
            },
            {
              title: "Active Customers",
              value: activeCustomers,
              icon: <GroupAdd style={{ color: "#fff" }} />,
              color: "#fff",
              subtext: `${activeCustomerRatio}% active rate`,
              avatarBg: "#4CAF50",
            },
            {
              title: "Missed Calls Today",
              value: userDashboardData?.dashboard?.["missed_calls_today"] ?? 0,
              icon: <PhoneMissed style={{ color: "#fff" }} />,
              color: "#fff",
              avatarBg: "#F44336",
              subtext: `+${
                userDashboardData?.dashboard?.["missed_calls_last_hour"] ?? 0
              } in last hour`,
            },
            {
              title: "Avg Response Time",
              value: userDashboardData?.dashboard?.["average_duration"] ?? 0,
              icon: <AccessTime style={{ color: "#fff" }} />,
              color: "#fff",
              avatarBg: "#FFC107",
              subtext: "-30s from yesterday",
            },
          ].map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  borderRadius: 1,
                  backgroundColor: `${item.color}`,
                  boxShadow: 0,
                  border: "1px solid #E0E0E0",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        bgcolor: item.avatarBg,
                        width: 40,
                        height: 40,
                        borderRadius: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mb: 2,
                      }}
                    >
                      {item.icon}
                    </Box>
                    <Typography color={item.textColor} variant="body2">
                      {item.title}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Typography
                        variant="h3"
                        color={item.textColor}
                        sx={{ fontWeight: 600, whiteSpace: "nowrap" }}
                      >
                        {item.value}
                      </Typography>
                      <Typography
                        variant="body2"
                        color={item.textColor || "textSecondary"}
                      >
                        {item.subtext}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={3}>
          <Suspense fallback={<DynamicLoader show={true} />}>
            <RecentCalls
              callLogs={callLogs}
              handleInfoClick={handleInfoClick}
            />
          </Suspense>

          <Suspense fallback={<DynamicLoader show={true} />}>
            <EmailQueueChart
              getSelectedData={handleInfoClick}
              globalFilter={dashboardFilter}
              handleOnChangeEmailFilter={handleOnChangeEmailFilter}
              emailFilter={emailFilter}
            />
          </Suspense>

          <Suspense fallback={<Backdrop show={true} />}>
            {isLoading ? (
              <Backdrop show={isLoading} message={message} />
            ) : (
              <CallDetailsPopup
                open={openEngagementModal}
                onClose={() => setOpenEngagementModal(false)}
              />
            )}
          </Suspense>
        </Grid>

        {/* Charts Section */}
        <Grid container spacing={3} sx={{ my: 1 }}>
          <Suspense fallback={<DynamicLoader show={true} />}>
            <CallVolume dashboardData={userDashboardData} />
          </Suspense>
          {/* Customer status */}

          <Suspense fallback={<DynamicLoader show={true} />}>
            <CustomerStatus
              agentStatusData={agentStatusData}
              redirectCustomerStatus={redirectCustomerStatus}
            />
          </Suspense>
        </Grid>

        {openDialer && (
        <Suspense fallback={<p>Loading</p>}>
          <DialPad open={openDialer} handleClose={() => setOpenDialer(false)} />
        </Suspense>
      )}
      </Box>
    </Box>
  );
};

export default CallCenterDashboard;
