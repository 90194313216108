import React, { useMemo, useState } from "react";
import {
  Paper,
  Typography,
  Box,
  Grid,
  Avatar,
  Tooltip,
  Divider,
} from "@mui/material";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import {
  Users,
  UserCheck,
  UserX,
  Clock,
  TrendingUp,
  TrendingDown,
  PhoneCall,
  BarChart2,
  Percent,
  Clock10Icon,
  UserMinus,
} from "lucide-react";
import CallStatusPopup from "./CallStatus";

const SIPGrid = ({ userStats }) => {
  const [openStatusModal, setOpenStatusModal] = useState(false);

  // Example Data
  const ongoingCalls = [
    {
      systemUsername: "John Doe",
      extension: "1004",
      customerName: "Alice Brown",
      customerPhone: "+1234567890",
      status: "ongoing",
    },
    {
      systemUsername: "Jane Smith",
      extension: "1003",
      customerName: "David Green",
      customerPhone: "+9876543210",
      status: "ongoing",
    },
  ];

  const queuedCalls = [
    {
      systemUsername: "Mike Johnson",
      extension: "1002",
      customerName: "Chris White",
      customerPhone: "+1122334455",
      status: "incoming",
    },
  ];

  const statsConfig = useMemo(
    () => [
      {
        icon: <Users size={24} color="#333" />,
        title: "Total Users",
        value: userStats.total,
        subtext: "System Users",
        color: "rgba(33, 150, 243, 0.1)",
        avatarBg: "rgba(33, 150, 243, 0.2)",
        textColor: "primary.main",
        trend: {
          value: 2 + " Joined Recently",
          icon: <TrendingUp color="green" size={16} />,
          color: "green",
        },
        additionalMetrics: [
          {
            label: "Dept Distribution",
            value: Object.entries(userStats.departmentBreakdown)
              .map(([dept, count]) => `${dept}: ${count}`)
              .join(", "),
          },
        ],
      },
      {
        icon: <UserCheck size={24} color="green" />,
        title: "Available",
        value: userStats.available,
        subtext: "Ready State",
        color: "rgba(76, 175, 80, 0.1)",
        avatarBg: "rgba(76, 175, 80, 0.2)",
        textColor: "success.main",
        trend: {
          value: userStats.away + " Not Available",
          icon: <UserMinus color="red" size={16} />,
          color: "red",
        },
        additionalMetrics: [
          {
            icon: <BarChart2 size={16} />,
            label: "Availability %",
            value: `${userStats.userStatusPercentage.available}%`,
          },
          {
            icon: <PhoneCall size={16} />,
            label: "Pending Calls",
            value: userStats.pendingCalls,
          },
        ],
      },
      {
        icon: <UserX size={24} color="red" />,
        title: "Busy Users",
        value: userStats.busy,
        subtext: "On Calls",
        color: "rgba(244, 67, 54, 0.1)",
        avatarBg: "rgba(244, 67, 54, 0.2)",
        textColor: "error.main",
        trend: {
          value: -2.3,
          icon: <TrendingDown color="red" size={16} />,
          color: "red",
        },
        additionalMetrics: [
          {
            icon: <Percent size={16} />,
            label: "Busy %",
            value: `${userStats.userStatusPercentage.busy}%`,
          },
          {
            icon: <Clock size={16} />,
            label: "Max Call Time",
            value: `${userStats.maxCallDuration} min`,
          },
        ],
      },
      {
        icon: <Clock size={24} color="#333" />,
        title: "Call Statistics",
        value: userStats.activeCalls,
        subtext: "Active Calls",
        color: "rgba(255, 152, 0, 0.1)",
        avatarBg: "rgba(255, 152, 0, 0.2)",
        textColor: "green",
        trend: {
          value: userStats.pendingCalls + " On Queue",
          icon: <Clock10Icon color="orange" size={16} />,
          color: "orange",
        },
        chartData: [
          {
            name: "Active Calls",
            value: userStats.activeCalls,
            color: "#77B254",
          },
          {
            name: "Call Queue",
            value: userStats.pendingCalls,
            color: "orange",
          },
        ],
        onClick: () => setOpenStatusModal(true)
      },
    ],
    [userStats]
  );

  return (
    <Grid container spacing={3}>
      {statsConfig.map((stat, idx) => (
        <Grid item xs key={idx}>
          <Paper
            elevation={0}
            sx={{
              p: 3,
              borderRadius: 0,
              border: `1px solid ${stat.avatarBg}`,
              transition: "transform 0.2s",
              height: "100%",
              "&:hover": {
                transform: "scale(1.03)",
              },
            }}
          >
            <Box
              onClick={stat.onClick}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid
                container
                spacing={1}
                sx={{
                  width: "100%",
                }}
              >
                <Grid item xs={7}>
                  <Tooltip title={stat.title}>
                    <Avatar
                      sx={{
                        bgcolor: stat.avatarBg,
                        width: 48,
                        height: 48,
                        mb: 2,
                      }}
                    >
                      {stat.icon}
                    </Avatar>
                  </Tooltip>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 0.5 }}
                  >
                    {stat.title}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography
                      variant="h4"
                      color={stat.textColor}
                      sx={{ fontWeight: 700, lineHeight: 1 }}
                    >
                      {stat.value}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {stat.subtext}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mt: 1,
                      color: stat.trend.color,
                    }}
                  >
                    {stat.trend.icon}
                    <Typography
                      variant="body2"
                      sx={{
                        ml: 0.5,
                        color: stat.trend.color,
                        fontWeight: 600,
                      }}
                    >
                      {stat.trend.value}
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={5}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  {stat.chartData && (
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart>
                        <Pie
                          data={stat.chartData}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="40%"
                          outerRadius={40}
                          fill="#8884d8"
                          label
                        >
                          {stat.chartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  )}

                  {stat.additionalMetrics &&
                    stat.additionalMetrics.map((metric, midx) => (
                      <Box
                        key={midx}
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        {metric.icon}
                        <Tooltip title={metric.label}>
                          <Typography variant="body2" color="text.secondary">
                            {metric.value}
                          </Typography>
                        </Tooltip>
                      </Box>
                    ))}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      ))}
      <CallStatusPopup
        open={openStatusModal}
        onClose={() => setOpenStatusModal(false)}
        ongoingCalls={ongoingCalls}
        queuedCalls={queuedCalls}
      />
    </Grid>
  );
};

export default SIPGrid;
