import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Grid,
  Paper,
} from "@mui/material";
import api from "../../../services/api";
import { swalAlert } from "../../Common/SwalAlert";

const updateManager = async (id, data) => {
  if (!id) {
    console.error("Manager ID is missing. Cannot update manager.");
    return null;
  }

  try {
    const result = await api.put(`/api/users/update_by_admin/${id}`, data);

    swalAlert({
      icon: "success",
      title: "Manager updated successfully!",
      showConfirmButton: true,
    });

    return result.data;
  } catch (error) {
    console.error("Error updating manager:", error);
    return null;
  }
};

const ManagerForm = ({ manager, onCancel, refreshManagers }) => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    user_role: "",
    status: "",
    team: "",
    territory: "",
  });

  // Load manager data when the component mounts
  useEffect(() => {
    if (manager) {
      setFormData({
        username: manager.username || "",
        email: manager.email || "",
        user_role: manager.user_role || "Manager",
        status: manager.status || "Active",
        team: manager.Team || "",
        territory: manager.Territory || "",
      });
    }
  }, [manager]); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Updated Manager Data:", formData);
    const response = await updateManager(manager.id, formData);
    if (response) {
      console.log("Manager updated successfully!");
      refreshManagers();
      onCancel(); // Hide the form after a successful update
    }
  };

  return (
    <Container sx={{ mt: 4, textAlign: "left" }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h5" gutterBottom>
          Edit Manager Details
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>User Role</InputLabel>
                <Select
                  label="User Role"
                  name="user_role"
                  value={formData.user_role}
                  onChange={handleChange}
                >
                  <MenuItem value="1">Admin</MenuItem>
                  <MenuItem value="4">User</MenuItem>
                  <MenuItem value="3">Manager</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                >
                  <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                  <MenuItem value="Suspended">Suspended</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Team"
                name="team"
                value={formData.team}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Territory"
                name="territory"
                value={formData.territory}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" size="large" sx={{ mr: 2 }}>
                Update Manager
              </Button>
              <Button variant="outlined" color="secondary" size="large" onClick={onCancel}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default ManagerForm;
