import React, {useEffect, useState} from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Table from "./Table";
import {clone} from '../../Common/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { emailActions, workspaceActions } from '../../../actions';
import {Compose} from './ComposeForm';
import Backdrop from '../../Common/Backdrop';


const headCells = [
    {
        id: 'email',
        numeric: false,
        disablePadding: true,
        label: 'Email Address',
    },
    {
        id: 'firstName',
        numeric: false,
        disablePadding: false,
        label: 'First Name',
    },
    {
        id: 'lastName',
        numeric: false,
        disablePadding: false,
        label: 'Last Name',
    },
    {
        id: 'personal_link',
        numeric: false,
        disablePadding: false,
        label: 'Personalize links',
    },
    {
        id: 'email_opened_count',
        numeric: false,
        disablePadding: false,
        label: 'Email Open Count',
    },
    {
        id: 'survey_sent',
        numeric: false,
        disablePadding: false,
        label: 'Sent Status',
    }
];


const Personalize = ({surveyID, onPersonalizeSubmit, survey_url, onCancel, onClickCreateCont, setData}) =>{
    const [rows, setRows] = useState([]);
    const [showCompose, setCompose] = useState(false);
    const [showSchedule, setSchedule] = useState(false);
    const [selectedRows, setSelectedRow] = useState({});
    const audience = useSelector(state => state.getAudience);
    const shareSurvey = useSelector(state => state.shareSurveyByEmail);
    const dispatch = useDispatch();
    const selected_rows =  rows.filter(r=> (r.survey_sent !== 'Sent' && r.survey_sent !== 'Scheduled' && r.selected));

   /* useEffect(() => {
        audience?.item?.length && dispatch(emailActions.getAudience(surveyID))
    }, [surveyID]);*/

    useEffect(() => {
        if(!shareSurvey.loading && shareSurvey.success && selected_rows.length){
            onPersonalizeSubmit && onPersonalizeSubmit();
        }
    }, [shareSurvey]);


    useEffect(()=>{
        if(audience?.item?.length){
            let arrObj = [];
            audience.item.forEach(({id, email="", person_details={}, personal_link="", survey_sent, is_survey_scheduled,email_opened_count,  group}) => {
                if(group === undefined || group === null || group === 'none'){
                    arrObj.push({
                        id,
                        email,
                        firstName: person_details['first name'] || "",
                        lastName: person_details['last name'] || "",
                        phoneNumber: person_details['phone number'] || person_details['phone'] || "",
                        personal_link,
                        survey_sent: survey_sent ? "Sent": is_survey_scheduled ? "Scheduled" : "Pending",
                        email_opened_count,
                    });
                }
            });
            setRows(arrObj);
            arrObj.length < 1 && setSelectedRow([])

        }
    }, [audience]);

    const onRowSelect = (row, index, checked) => {
        if(row && (row.survey_sent === 'Sent' || row.survey_sent === 'Scheduled')){
            return;
        }
        const clonedRows = clone(rows);
        const idx = clonedRows.findIndex(x=> x.id === row.id);
        clonedRows[idx]['selected'] = checked
        setRows(clonedRows);


    };

    const onAllSelect = (e) => {
        const {checked} = e?.target;
        const clonedRows = clone(rows);
        const alterRows = clonedRows.map((r,i)=>{
            if(r.survey_sent === 'Sent' || r.survey_sent === 'Scheduled'){
                return r;
            }
            
            r['selected'] = checked;
            return r;
        });
        setRows(alterRows);
    };

    const handleDelete = (e) => {
        const cloned = clone(rows);
        const ids = {"email_link_ids": [e.id]};
        const index = rows.findIndex(v => v.id === e.id);
        if (index > -1) {
            cloned.splice(index, 1);
            setRows(cloned);
            dispatch(workspaceActions.deleteEmailLinks(ids));
        }
    };
    const onCreateContact = () => {
        const cookedData = rows.map(r=>({email:r.email, 'first name':r.firstName, 'last name':r.lastName, 'phone number':r.phoneNumber}))
        setData(cookedData);
        onClickCreateCont();
    };

    const active = selected_rows.length ? ' active' : '';
    return(
        <div className={`sendEmailBody`}>
            { <div className="head">
                <div className="icon"><img src={origin + "/images/SVG/sendemailicon.svg"} alt="link"/></div>
                Email Contacts
            </div>}
            <div className="sendEmailInner">
                <div className={"personalizeInner"}>
                    <h2><FormControlLabel control={<Checkbox name="replace" defaultChecked color="success" />} label="Personalize links"/></h2>
                    <div className="create" onClick={onCreateContact}><AddCircleOutlineIcon/> Create Contacts</div>
                    <p>Customise links to include the email of the survey recipients.<br/>
                        By personalising links for each recipient you can track if they have seen the survey and answered it</p>
                    <div className="link"><a onClick={()=> window.open(survey_url, '_blank')}>{survey_url}</a></div>

                    <div className="tableWrap">
                        <Table rows={rows} headCells={headCells} onRowSelect={onRowSelect} hasDeleteBtn={true} onDelete={handleDelete} loading={audience.loading} onAllSelect={onAllSelect}/>
                        <div><button className={"saveBtn"+active} onClick={()=>setCompose(true)}>Send Survey</button></div>
                        <div><button className={"saveBtn btnScheduleSurvey"+active} onClick={()=>setSchedule(true)}>Schedule Survey</button></div>
                        {/*<button className="saveBtn cancelBtn" onClick={onCancel} style={{left:180}}>Cancel</button>*/}
                    </div>
                </div>
            </div>
            <Compose surveyID={surveyID} show={showCompose} handleClose={()=> setCompose(false)} records={selected_rows || []} />
            <Compose surveyID={surveyID} isSchedule="1" show={showSchedule} handleClose={()=> setSchedule(false)} records={selected_rows || []} />
            <Backdrop show={shareSurvey.loading}/>
        </div>
    )
};
export default Personalize;
