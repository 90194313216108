import React from 'react';
import ReactDOMServer from "react-dom/server";
import {setter} from "../EmbedPage";

const btnStyle =  {
    border: '1px solid rgb(24, 160, 251)',
    background: 'rgb(24, 160, 251)',
    padding: '10px 35px',
    borderRadius:30,
    margin: "0 auto",
    cursor: 'pointer',
    color:"white",
    whiteSpace: "nowrap"
};
const barStyle =  {
    background: '#f4f4f4',
    padding: 10,
    marginBottom:20,
    width:"100%",
};
const barNewStyle =  {
    background: '#f4f4f4',
    padding: 10,
    marginBottom:40,
    width:"70%",
};

const Slider = (props) => {
    const {btnText="", url} = props;
    const parentStyle = {
        display:'none',
        position: "absolute",
        right: "0",
        top: "0",
        width: "100%",
        height: "100%",
        textAlign: "center",
        background: "#e6e6e6",
        zIndex: 1000
    };
    const cancelBtnStyle = {
        fontSize: "16px",
        cursor: "pointer",
        width: "25px",
        height: "25px",
        backgroundColor: "#c6c6c6",
        color:'#fff',
        borderRadius: "100%",
        fontFamily:'monospace',
        fontWeight:500,
        lineHeight:'1.4',
        position:'absolute',
        right:30,
        top:25
    };
    const contentStyle = {width:'100%', height:'100%', position:'absolute', padding:20, display:'flex', boxSizing:'border-box'};
    const [codeStr, setCodeStr] = React.useState('');

    const surveyButton  = () => {
        return <div>
            <button style={btnStyle}> {btnText}</button>
            <div id="embed" style={parentStyle}>
                <div style={contentStyle}>
                    <iframe id={"_iframe"} width="100%" height="100%"  style={{textAlign:'center', pointerEvents:'unset', border:'none'}} src={url} />
                    <span style={cancelBtnStyle} title={"Close"}>x</span>
                </div>
            </div>
        </div>
    };

    React.useEffect(()=>{
        const t = ReactDOMServer.renderToStaticMarkup(surveyButton());
        const m = t.split("<button");
        const str1 = m.join('<button onclick="document.getElementById(\'embed\').style.display = \'block\';"');
        const str2 = str1.split('<span');
        const str = str2.join('<span onclick="document.getElementById(\'embed\').style.display = \'none\'; document.getElementById(\'_iframe\').src=document.getElementById(\'_iframe\').src;"');
        setter(str.replace("embed/survey_ans", "survey_ans"));
        setCodeStr(str);
    },[btnText]);

    return <div className="startSurveySlider" style={{position:'relative', height: '100%'}}>
        <div style={barStyle}></div>
        <div style={barStyle}></div>
        <div style={barStyle}></div>
        <div style={barStyle}></div>
        <div style={barStyle}></div>
        <div style={barNewStyle}></div>
        <div  dangerouslySetInnerHTML={{__html: codeStr.replace(/&amp;/g, "&")}} />
    </div>
};

export default Slider;
