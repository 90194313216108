import React, { useState, useEffect } from "react";
import DataTable from "../../../components/Tables/DataTable";
import TerritoryCreationForm from "./TerritoryCreationForm";
import { Button, Typography } from "@mui/material";
import { AdminService } from "../../../services/AdminService";
import { territoryAction } from "../../../actions/territoryActions";
import { useDispatch, useSelector } from "react-redux";
import { territoryConstants } from "../../../constants/TerritoryConstants";
import { getUser } from "../../Common/Utils";


const TerritoryTable = () => {
  const [showForm, setShowForm] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedTer, setSelectedTer] = useState(null);
  const [territory, setTerritory] = useState(null);
  const [terEdit, setTerEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const isTerAdded = useSelector((state) => state.territoryLogic.isTerAdded);
  const user = getUser();

  const fetchTerritory = async () => {
    setLoading(true);    
    try {
      //const response = await AdminService.getTerritory();
      const response = await AdminService.getTerritoryByUserId(user?.user_id);
      
      const territories = response?.data || [];

      if (response?.data?.message === "Territory not found for user") {
        territories = [];
      }
      setTerritory(territories);
    } catch (error) {
      console.error("Error fetching territories:", error);
      setTerritory([]); // Reset if an error occurs
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTerritory();

    if (isTerAdded || isDelete) {
      fetchTerritory();
      dispatch({ type: territoryConstants.RESET_TERRITORY_ADDED }); // Reset flag after re-fetch
    }
  }, [isTerAdded, isDelete, dispatch]);

  const columns = ["Name", "Type", "Actions"];
  const data = [
    { user_id: "4" , Name : "North Territories",  Type : "North"},
    { user_id: "6", Name : "East Territories",   Type : "East"},
  ];

  const handleDeleteClick = async (terId) => {
    try {
      await dispatch(territoryAction.deleteTerritory(terId)); 

      // Fetch updated agents list after deletion
      await fetchTerritory();
      setIsDelete(true)
    } catch (error) {
      console.error("Error deleting agent:", error);
    }
 
  };



  const enhancedRows = territory?.map((row) => ({
    ...row,
    Actions: (
      <>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            setSelectedTer(row); // Set the selected role for editing
            setTerEdit(true); // Open the form
            setShowForm(true);
          }}
          style={{ marginRight: "10px" }}
        >
          Edit
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => handleDeleteClick(row.id)}
        >
          Delete
        </Button>
      </>
    ), 
  }));

  const handleAddTerritory = () => {
    setShowForm(true);
  };

  const handleCancelForm = () => {
    setShowForm(false);
  };

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <Button variant="contained" color="primary" onClick={handleAddTerritory}>
          Add Territory
        </Button>
      </div>
      {showForm && (
        <div style={{ marginBottom: "20px" }}>
          <TerritoryCreationForm 
          onCancel={handleCancelForm} 
          terEdit={terEdit}
          terData={selectedTer}/>
        </div>
      )}
      <Typography variant="h6" gutterBottom>
        Territory Details
      </Typography>
      <DataTable columns={columns} rows={enhancedRows} tableType="Territory" />
    </div>
  );
}; 

export default TerritoryTable;
