import React, { useEffect, useState } from "react";
import Ratings from "../../Components/Ratings";

const RatingBox = (props) => {
  const {
    layout,
    children,
    qus_id,
    question,
    desc = "",
    qno = 1,
    srNo = 1,
    settings = {},
    ans_options = [],
    cardRef,
    quesType,
    onChange,
    qRefs,
    submit,
    btnText = "Next",
    sliderView,
    onFocus,
  } = props;
  const { font, underline, bold, fontSize, italic, fontColor, require } =
      settings,
    style = {
      fontFamily: font,
      fontSize,
      fontWeight: bold ? "bold" : "",
      fontStyle: italic ? "italic" : "",
      textDecoration: underline ? "underline" : "",
      color: fontColor,
    };

  const answerRating = (window.answeredData && window.answeredData[question]) ? window.answeredData[question][0].text : 0;
  const [ratingValue, setRating] = useState(answerRating);
  const [err, setErr] = useState("");

  useEffect(() => {
    const elements = document.getElementsByName("question_" + qno);
    let ele = elements.length ? elements[0] : elements;
    ele.style.setProperty("font-family", font, "important");
  }, [font]);

  const onNext = () => {
    if (btnText === "Next") {
      cardRef && cardRef.current.next();
      qRefs?.current?.length >= qno &&
        setTimeout(() => {
          qRefs.current[qno]?.focus();
        }, 10);
    } else {
      submit && submit();
    }
  };

  const triggerFocus = () => {
    onFocus && qno > 0 && onFocus(qno - 1);
  };

  const onSelect = (e) => {
    triggerFocus();
    setRating(e);
    if (require && e === 0) {
      setErr("This is required.");
      return;
    }
    if (e) {
      let obj = {
        qus_id: qus_id,
        q_type: quesType,
        qus_text: question,
        answers: [{ text: e }],
      };
      onChange && onChange({ name: qno, value: obj });
    }
    setErr("");
  };

  return (
    <div className="imageWrapInner">
      <div className="ques">
        <h3 name={"question_" + qno} style={style}>
          {srNo}-{question}
          <span
            style={{
              color: "red",
              marginLeft: "5px",
              display: require ? "unset" : "none",
            }}
          >
            *
          </span>
        </h3>
        <em> {desc} </em>
      </div>
      {layout == 1 && children}
      <div
        className="ratingInner"
        ref={(r) => qRefs && (qRefs.current[qno - 1] = r)}
        tabIndex={-1}
      >
        <Ratings value={{ settings }} onChange={onSelect} count={ratingValue} />
      </div>
      {err !== "" ? (
        <div>
          {" "}
          <p style={{ color: "red" }}>{err}</p>
        </div>
      ) : (
        <div>
          <p>&nbsp;</p>
        </div>
      )}

      {sliderView ? (
        <div className="btnGroup">
          <button className="btnSave" onClick={onNext}>
            {btnText}
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default RatingBox;
