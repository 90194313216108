import React, { useState, useRef, useEffect } from "react";
import { setter } from "./EmbedPage";

const Full = (props) => {
  const ref = useRef();
  const [height, setHeight] = useState("0px");
  const [width, setWidth] = useState("0px");

  const { url } = props;

  const getDOMString = (hi, wi) => {
    const src = url.replace("embed/survey_ans", "survey_ans");

    return `
    <iframe
      id="articenceFormIFrame-232323285892056"
      title="Form"
      onload="window.parent.scrollTo(0,0)"
      allowtransparency="true"
      allowfullscreen="true"
      src=${src}
      frameborder="0"
      style="min-width:100%;max-width:100%;height:${hi};border:none;"
      scrolling="no"
    >
    </iframe>
   `;
  };
  const onLoad = () => {
    setTimeout(() => {
      setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
      setWidth(window.innerWidth + "px");

      setter(
        getDOMString(
          ref.current.contentWindow.document.body.scrollHeight + "px",
          window.innerWidth + "px"
        )
      );
    }, [2000]);
  };
  const src = url.replace("embed/survey_ans", "survey_ans");

  useEffect(() => {
    onLoad();
  }, []);
  React.useEffect(() => {}, []);
  return (
    <iframe
      ref={ref}
      onLoad={onLoad}
      className="w-full"
      id="iframe"
      //   style={{width:""}}
      width={"100%"}
      src={src}
      height={height}
      scrolling="no"
      frameBorder="0"
    ></iframe>
  );
};

export default Full;
