import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import axios from "axios";
import Swal from "sweetalert2";
import { swalAlert } from "../../voc/Common/SwalAlert";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Header } from "../Common/Header";
import { useDispatch, useSelector } from "react-redux";
import "./Subscription.scss";
import { subscriptionActions } from "../../actions";
import { useState, useEffect } from "react";
import { authHeader } from "../../helpers";
import { commonService } from "../../services/CommonService";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Subscription = () => {
  const dispatch = useDispatch();
  const [planType, setPlanType] = React.useState("yearly");
  const [disable, setDisable] = React.useState(true);

  const [selectedPlan, setSelectedPlan] = React.useState("");
  const subscription = useSelector((state) => state.subscription.items);
  const features = useSelector((state) => state.subscription.data);
  const [selectedResponse, setSelectedResponse] = React.useState("");

  React.useEffect(() => {
    dispatch(subscriptionActions.getSubscriptionPlanData(planType));
    dispatch(subscriptionActions.getFeaturesData(planType));
  }, [planType]);

  React.useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    // if (subscription && subscription.length > 0) {
    //   setSelectedPlan(subscription[0].price_id);
    //   if (!query.get("success") && !query.get("cancelled")) {
    //     localStorage.setItem("planName", subscription[0].plan_name);
    //   }
    // }
  }, [subscription]);
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");
  function AbbreviateNumbers(value) {
    var newValue = value;
    if (value >= 1000) {
      var suffixes = ["", "k", "m", "b", "t"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue = "";
      var shortNum = "";
      for (var precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat(
          (suffixNum != 0
            ? value / Math.pow(1000, suffixNum)
            : value
          ).toPrecision(precision)
        );
        var dotLessShortValue = (shortValue + "").replace(
          /[^a-zA-Z 0-9]+/g,
          ""
        );
        if (dotLessShortValue.length <= 2) {
          break;
        }
      }
      if (shortValue % 1 != 0) shortNum = shortValue.toFixed(1);
      newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
  }
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  const Message = ({ message }) => (
    <section>
      <p>{message}</p>
    </section>
  );
  const Logo = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="14px"
      height="16px"
      viewBox="0 0 14 16"
      version="1.1"
    >
      <defs />
      <g id="Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="0-Default"
          transform="translate(-121.000000, -40.000000)"
          fill="#E184DF"
        >
          <path
            d="M127,50 L126,50 C123.238576,50 121,47.7614237 121,45 C121,42.2385763 123.238576,40 126,40 L135,40 L135,56 L133,56 L133,42 L129,42 L129,56 L127,56 L127,50 Z M127,48 L127,42 L126,42 C124.343146,42 123,43.3431458 123,45 C123,46.6568542 124.343146,48 126,48 L127,48 Z"
            id="Pilcrow"
          />
        </g>
      </g>
    </svg>
  );
  const [value, setValue] = React.useState(0);
  const SuccessDisplay = ({ sessionId }) => {
    return (
      <>
        <div className="subscriptionPage">
          {/* <Header /> */}
          <div className="subscriptionWrapper subscriptionSuccess">
            <div className="subscriptionSuccess-info">
              <CheckCircleOutlineRoundedIcon />
              <h3 className="subscriptionSuccess-message">
                Subscription to{" "}
                {localStorage.getItem("planName") !== undefined
                  ? localStorage.getItem("planName")
                  : ""}{" "}
                plan successful!
              </h3>
            </div>

            <div className="buttonWrapper">
              <form action="/" method="GET">
                <button id="checkout-and-portal-button" type="submit">
                  Continue
                </button>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  };
  const handleChange = (event, newValue) => {
    if (newValue == 0) {
      setPlanType("yearly");
    } else {
      setPlanType("monthly");
    }
    setValue(newValue);
    setSelectedResponse("");
    setSelectedPlan("");
    setDisable(true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const index =
      subscription && subscription.findIndex((x) => x.is_current_plan === true);
    var currentPlan;
    if (index !== -1) {
      currentPlan = subscription[index].plan_name;
    }

    if (
      localStorage.getItem("planName") !== undefined &&
      localStorage.getItem("planName").toLowerCase() === "enterprise"
    ) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Please Contact our sales team for Enterprise Plan!",
        showConfirmButton: true,
        // timer: 1500,
      }).then(function () {
        window.location =
          "http://3.131.90.90/survey_ans/1728&ydkof244mjfhkih933klk30hgmn44$oj";
      });

      return 0;
    }
    if (
      subscription &&
      subscription.findIndex((x) => x.is_current_plan === true) !== -1 &&
      currentPlan.toLowerCase() !== "free"
    ) {
      const apiUrl = commonService.apiUrl;

      let formData = new FormData();
      formData.set("price_id", selectedPlan);
      let user = JSON.parse(localStorage.getItem("user"));
      let options = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.access_token,

          "content-type": "application/x-www-form-urlencoded",
        },
        data: formData,
        url: `${apiUrl}/updatePlanSubscription`,
      };
      axios(options)
        .then((res) => {
          // res.redirect();
          // if (res.data.redirect_url) {
          //   window.location.href = res.data.redirect_url;
          // }
          // alert("Subscription Plan Updated");
          swalAlert({
            icon: "success",
            title: "Subscription Plan Updated".toString(),
            showConfirmButton: true,
          });
          dispatch(subscriptionActions.getSubscriptionPlanData(planType));
          dispatch(subscriptionActions.getFeaturesData(planType));
          // console.log("yeh we have", res.data);
        })
        .catch((er) => {
          console.log("no data sorry ", er);
        });
    } else {
      const apiUrl = commonService.apiUrl;

      let formData = new FormData();
      formData.set("price_id", selectedPlan);
      let user = JSON.parse(localStorage.getItem("user"));
      let options = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.access_token,

          "content-type": "application/x-www-form-urlencoded",
        },
        data: formData,
        url: `${apiUrl}/createCheckoutSession`,
      };
      axios(options)
        .then((res) => {
          // res.redirect();
          if (res.data.already_has_plan) {
            let options1 = {
              method: "POST",
              headers: {
                Authorization: "Bearer " + user.access_token,

                "content-type": "application/x-www-form-urlencoded",
              },
              data: formData,
              url: `${apiUrl}/updatePlanSubscription`,
            };
            axios(options1)
              .then((res) => {
                // res.redirect();
                // if (res.data.redirect_url) {
                //   window.location.href = res.data.redirect_url;
                // }
                // alert("Subscription Plan Updated");
                swalAlert({
                  icon: "success",
                  title: "Subscription Plan Updated".toString(),
                  showConfirmButton: true,
                });
                dispatch(subscriptionActions.getSubscriptionPlanData(planType));
                dispatch(subscriptionActions.getFeaturesData(planType));
                // console.log("yeh we have", res.data);
              })
              .catch((er) => {
                console.log("no data sorry ", er);
              });
          } else {
            if (res.data.redirect_url) {
              window.location.href = res.data.redirect_url;
            }
          }

          // console.log("yeh we have", res.data);
        })
        .catch((er) => {
          console.log("no data sorry ", er);
        });
    }
  };
  const ProductDisplay = () => {
    return (
      <div className="subscriptionPage">
        {/* <Header /> */}
        <div className="subscriptionWrapper">
          <Box sx={{ width: "100%" }}>
            <Box className="subscriptionTabs">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Yearly" {...a11yProps(0)} />
                <Tab label="Monthly" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0} className="subscriptionPanel">
              <div className="subscriptionCardWrapper">
                {subscription && subscription.length > 0
                  ? subscription.map((value, index) => {
                      return (
                        <div
                          onClick={() => {
                            if (value.plan_name.toLowerCase() === "free") {
                              return 0;
                            }
                            if (value.is_current_plan) {
                              return 0;
                            }
                            setDisable(false);
                            setSelectedPlan(value.price_id);
                            setSelectedResponse(
                              value.main_category["Number of total responses"]
                            );
                            localStorage.setItem("planName", value.plan_name);
                          }}
                          style={{ cursor: "pointer" }}
                          className={
                            value.is_current_plan
                              ? "subscriptionCard currentCard"
                              : selectedPlan === value.price_id
                              ? "subscriptionCard subscriptionSelected"
                              : "subscriptionCard"
                          }
                        >
                          <h2 className="subscriptionTitle">
                            {value.plan_name}
                          </h2>
                          <p className="subscriptionDescription">
                            {" "}
                            {value.description}
                          </p>
                          <div
                            style={{
                              display:
                                value.plan_name === "Free" ||
                                value.plan_name === "Enterprise"
                                  ? "none"
                                  : "",
                            }}
                            className="subscriptionPrice"
                          >
                            <h4>
                              <span
                                style={{
                                  textDecoration: "line-through",
                                }}
                              >
                                {value.plan_price_with_currency}
                              </span>
                              <span> {value.net_price_with_currency}</span>
                            </h4>
                            <p>a {planType === "monthly" ? "month" : "year"}</p>
                          </div>
                          <ul className="subscriptionCard-list">
                            <li className="subscriptionCard-listItem">
                              <CheckCircleOutlineRoundedIcon />{" "}
                              {AbbreviateNumbers(
                                value.main_category["Number of total responses"]
                              )}{" "}
                              responses
                            </li>
                            <li className="subscriptionCard-listItem">
                              <CheckCircleOutlineRoundedIcon />{" "}
                              {value.main_category["Number of surveys"]} Number
                              Of Surveys
                            </li>
                            <li className="subscriptionCard-listItem">
                              <CheckCircleOutlineRoundedIcon />{" "}
                              {value.main_category["Questons per survey"]}{" "}
                              Questions per survey
                            </li>
                          </ul>
                          {/* {console.log("cmmccm", value.is_current_plan)} */}
                          {/* <button className="blue-btn">See more</button> */}

                          <>
                            {" "}
                            {value.is_current_plan ? (
                              <div className="subscriptionBadge">
                                Current plan
                              </div>
                            ) : null}
                            {selectedPlan === value.price_id ? (
                              <div className="homeIcon">
                                <svg
                                  width="87"
                                  height="87"
                                  viewBox="0 0 87 87"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="43.5"
                                    cy="43.5"
                                    r="43.5"
                                    fill="#18A0FB"
                                  />
                                  <path
                                    d="M57.8335 36.3339L47.0835 26.9097C46.098 26.0283 44.8223 25.541 43.5002 25.541C42.178 25.541 40.9023 26.0283 39.9168 26.9097L29.1668 36.3339C28.5977 36.8429 28.1436 37.4672 27.8347 38.1654C27.5257 38.8636 27.3691 39.6196 27.3752 40.3831V56.0422C27.3752 57.4678 27.9415 58.8349 28.9495 59.8429C29.9575 60.8509 31.3246 61.4172 32.7502 61.4172H54.2502C55.6757 61.4172 57.0429 60.8509 58.0509 59.8429C59.0589 58.8349 59.6252 57.4678 59.6252 56.0422V40.3651C59.6287 39.6047 59.4708 38.8522 59.162 38.1572C58.8531 37.4623 58.4003 36.8409 57.8335 36.3339V36.3339ZM47.0835 57.8339H39.9168V48.8756C39.9168 48.4004 40.1056 47.9447 40.4416 47.6087C40.7776 47.2727 41.2333 47.0839 41.7085 47.0839H45.2918C45.767 47.0839 46.2227 47.2727 46.5587 47.6087C46.8947 47.9447 47.0835 48.4004 47.0835 48.8756V57.8339ZM56.0418 56.0422C56.0418 56.5174 55.8531 56.9731 55.5171 57.3091C55.1811 57.6451 54.7253 57.8339 54.2502 57.8339H50.6668V48.8756C50.6668 47.45 50.1005 46.0829 49.0925 45.0749C48.0845 44.0669 46.7174 43.5006 45.2918 43.5006H41.7085C40.283 43.5006 38.9158 44.0669 37.9078 45.0749C36.8998 46.0829 36.3335 47.45 36.3335 48.8756V57.8339H32.7502C32.275 57.8339 31.8193 57.6451 31.4833 57.3091C31.1473 56.9731 30.9585 56.5174 30.9585 56.0422V40.3651C30.9588 40.1108 31.0133 39.8593 31.1184 39.6277C31.2234 39.396 31.3766 39.1893 31.5677 39.0214L42.3177 29.6151C42.6446 29.3279 43.065 29.1695 43.5002 29.1695C43.9354 29.1695 44.3557 29.3279 44.6827 29.6151L55.4327 39.0214C55.6238 39.1893 55.777 39.396 55.882 39.6277C55.987 39.8593 56.0415 40.1108 56.0418 40.3651V56.0422Z"
                                    fill="white"
                                  />
                                </svg>
                              </div>
                            ) : null}
                          </>
                        </div>
                      );
                    })
                  : null}
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} className="subscriptionPanel">
              <div className="subscriptionCardWrapper">
                {subscription && subscription.length > 0
                  ? subscription.map((value, index) => {
                      return (
                        <div
                          onClick={() => {
                            if (value.plan_name.toLowerCase() === "free") {
                              return 0;
                            }
                            if (value.is_current_plan) {
                              return 0;
                            }
                            setDisable(false);
                            setSelectedPlan(value.price_id);
                            setSelectedResponse(
                              value.main_category["Number of total responses"]
                            );
                            localStorage.setItem("planName", value.plan_name);
                          }}
                          style={{ cursor: "pointer" }}
                          className={
                            value.is_current_plan
                              ? "subscriptionCard currentCard"
                              : selectedPlan === value.price_id
                              ? "subscriptionCard subscriptionSelected"
                              : "subscriptionCard"
                          }
                        >
                          <h2 className="subscriptionTitle">
                            {value.plan_name}
                          </h2>
                          <p className="subscriptionDescription">
                            {" "}
                            {value.description}
                          </p>
                          <div
                            style={{
                              display:
                                value.plan_name === "Free" ||
                                value.plan_name === "Enterprise"
                                  ? "none"
                                  : "",
                            }}
                            className="subscriptionPrice"
                          >
                            <h4>
                              <span
                                style={{
                                  textDecoration: "line-through",
                                }}
                              >
                                {value.plan_price_with_currency}
                              </span>
                              <span> {value.net_price_with_currency}</span>
                            </h4>
                            <p>a {planType === "monthly" ? "month" : "year"}</p>
                          </div>
                          <ul className="subscriptionCard-list">
                            <li className="subscriptionCard-listItem">
                              <CheckCircleOutlineRoundedIcon />{" "}
                              {AbbreviateNumbers(
                                value.main_category["Number of total responses"]
                              )}{" "}
                              responses
                            </li>
                            <li className="subscriptionCard-listItem">
                              <CheckCircleOutlineRoundedIcon />{" "}
                              {value.main_category["Number of surveys"]} Number
                              Of Surveys
                            </li>
                            <li className="subscriptionCard-listItem">
                              <CheckCircleOutlineRoundedIcon />{" "}
                              {value.main_category["Questons per survey"]}{" "}
                              Questions per survey
                            </li>
                          </ul>
                          {value.is_current_plan ? (
                            <div className="subscriptionBadge">
                              Current plan
                            </div>
                          ) : null}
                          {selectedPlan === value.price_id ? (
                            <div className="homeIcon">
                              <svg
                                width="87"
                                height="87"
                                viewBox="0 0 87 87"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="43.5"
                                  cy="43.5"
                                  r="43.5"
                                  fill="#18A0FB"
                                />
                                <path
                                  d="M57.8335 36.3339L47.0835 26.9097C46.098 26.0283 44.8223 25.541 43.5002 25.541C42.178 25.541 40.9023 26.0283 39.9168 26.9097L29.1668 36.3339C28.5977 36.8429 28.1436 37.4672 27.8347 38.1654C27.5257 38.8636 27.3691 39.6196 27.3752 40.3831V56.0422C27.3752 57.4678 27.9415 58.8349 28.9495 59.8429C29.9575 60.8509 31.3246 61.4172 32.7502 61.4172H54.2502C55.6757 61.4172 57.0429 60.8509 58.0509 59.8429C59.0589 58.8349 59.6252 57.4678 59.6252 56.0422V40.3651C59.6287 39.6047 59.4708 38.8522 59.162 38.1572C58.8531 37.4623 58.4003 36.8409 57.8335 36.3339V36.3339ZM47.0835 57.8339H39.9168V48.8756C39.9168 48.4004 40.1056 47.9447 40.4416 47.6087C40.7776 47.2727 41.2333 47.0839 41.7085 47.0839H45.2918C45.767 47.0839 46.2227 47.2727 46.5587 47.6087C46.8947 47.9447 47.0835 48.4004 47.0835 48.8756V57.8339ZM56.0418 56.0422C56.0418 56.5174 55.8531 56.9731 55.5171 57.3091C55.1811 57.6451 54.7253 57.8339 54.2502 57.8339H50.6668V48.8756C50.6668 47.45 50.1005 46.0829 49.0925 45.0749C48.0845 44.0669 46.7174 43.5006 45.2918 43.5006H41.7085C40.283 43.5006 38.9158 44.0669 37.9078 45.0749C36.8998 46.0829 36.3335 47.45 36.3335 48.8756V57.8339H32.7502C32.275 57.8339 31.8193 57.6451 31.4833 57.3091C31.1473 56.9731 30.9585 56.5174 30.9585 56.0422V40.3651C30.9588 40.1108 31.0133 39.8593 31.1184 39.6277C31.2234 39.396 31.3766 39.1893 31.5677 39.0214L42.3177 29.6151C42.6446 29.3279 43.065 29.1695 43.5002 29.1695C43.9354 29.1695 44.3557 29.3279 44.6827 29.6151L55.4327 39.0214C55.6238 39.1893 55.777 39.396 55.882 39.6277C55.987 39.8593 56.0415 40.1108 56.0418 40.3651V56.0422Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                          ) : null}
                        </div>
                      );
                    })
                  : null}
              </div>
            </TabPanel>
          </Box>
          <div className="subscriptionFooter">
            <h2 className="subscriptionFooterTitle">
              How many response a month do you need?
            </h2>
            <ul className="subscriptionResponse-list">
              {subscription && subscription.length > 0
                ? subscription.map((value, index) => {
                    return (
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (value.plan_name.toLowerCase() === "free") {
                            return 0;
                          }
                          if (value.is_current_plan) {
                            return 0;
                          }
                          setDisable(false);
                          setSelectedPlan(value.price_id);
                          localStorage.setItem("planName", value.plan_name);
                          setSelectedResponse(
                            value.main_category["Number of total responses"]
                          );
                        }}
                        className={
                          selectedResponse ===
                          value.main_category["Number of total responses"]
                            ? "subscriptionResponse-listItem selectedResponse"
                            : "subscriptionResponse-listItem"
                        }
                      >
                        {AbbreviateNumbers(
                          value.main_category["Number of total responses"]
                        )}
                      </li>
                    );
                  })
                : null}
            </ul>
            <p>
              A response is counted when someone submits your survey. We'll
              email you well before you reach your plan's response limit, and
              you can adjust how many response
            </p>
            <p>
              Need a custom solution?{" "}
              <a href="http://3.131.90.90/survey_ans/1727&ydkof244mjfhkih933klk30hgmn44$oj">
                Contact our sales team
              </a>
            </p>
          </div>
          <div className="subscriptionAccordionTable">
            <Accordion>
              <AccordionSummary
                className="accordionHead"
                style={{ pointerEvents: "none" }}
              >
                <Typography>Features Overview</Typography>
              </AccordionSummary>
            </Accordion>
            {features && features.length > 0
              ? features.map((val, i) => {
                  return (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<AddRoundedIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        className="accordionHead"
                      >
                        <Typography>{val.category_name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails className="accordionBody">
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell></TableCell>
                                {subscription && subscription.length > 0
                                  ? subscription.map((value, index) => {
                                      return (
                                        <TableCell align="center">
                                          {value.plan_name}
                                        </TableCell>
                                      );
                                    })
                                  : null}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {val.features.map((row) => (
                                <TableRow
                                  key={row.feature_name}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.feature_name}
                                  </TableCell>
                                  {/* {console.log("rohan", Object.entries(row))} */}
                                  {Object.entries(row)
                                    .slice(1)
                                    .map(([key, value], i) => (
                                      <TableCell align="center">
                                        {value ? "Yes" : "No"}
                                      </TableCell>
                                    ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              : null}
          </div>

          <div className="buttonWrapper">
            <form
              // action="http://3.131.90.90:5001/createCheckoutSession"
              // method="POST"
              onSubmit={handleSubmit}
            >
              {/* <input type="hidden" name="user_id" value={"188"} /> */}

              <input type="hidden" name="price_id" value={selectedPlan} />
              <button
                // id="checkout-and-portal-button"
                type="submit"
                style={{
                  opacity: `${disable ? 0.5 : 1}`,
                  cursor: disable ? "default" : "pointer",
                }}
                disabled={disable}
              >
                Continue
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  };
  if (!success && message === "") {
    return <ProductDisplay />;
  } else if (success && sessionId !== "") {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }
};

export default Subscription;
