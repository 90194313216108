import React, { useState, useEffect, useRef } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MaterialSelect } from "../../../../common/controls/inputs/Material";
import Prompt from "../../../Common/ConfirmationPrompt";
import { async } from "q";
import api from "../../../../../src/services/api";
import { clone } from "../../../Common/Utils";
import { useDispatch, useSelector } from "react-redux";
import { workspaceService } from "../../../../services";

const origin = window.location.origin;
const checkStyle = {
  position: "absolute",
  top: -9,
  right: -6,
  background: "#fff",
  borderRadius: "50%",
  zIndex: "1",
};
const isPreview = true;
const PaymentChoice = (props) => {
  const {
    layout,
    children,
    qus_id,
    question,
    quesData = [],
    desc = "",
    if_pictures_type = [],
    if_payment_type = [],
    qno = 1,
    settings = {},
    cardRef,
    quesType,
    onChange,
    qRefs,
    submit,
    btnText = "Next",
    sliderView,
    onFocus,
    showSubmitBtn,
    Cloneddata,
    visitorId,
    surveyId,
    responseBy,
    userAddress,
    logicPayment,
  } = props;
  const { loading, res = {} } = useSelector(
    (state) => state[isPreview ? "wsDetailsAuth" : "wsDetails"]
  );
  const [trackedIDs, setTrackedID] = useState([]);

  const [selectedPeriod, setSelectedPeriod] = useState([]);
  const [selectedAmountToShow, setSelectedAmountToShow] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState([]);
  const [selectedOtherAmount, setSelectedOtherAmount] = useState(false);
  const [otherAmountItem, setOtherAmountItem] = useState([]);
  const [serviceYes, setServiceYes] = useState(null);
  const [newstyle, setNewStyle] = useState([]);
  
  const createEventsTracker = (trigger) => {
    const cloned = clone(payment);
    let index = cloned.findIndex(
      (x) => x.qus_text === trigger && x.paymentGroup === props.paymentGroup
    );
    let qus_id = cloned[index].qus_id;
    if (trackedIDs.indexOf(String(qus_id)) < 0 && qus_id) {
      const params = {
        detail_id: surveyId,
        visitor_id: visitorId + 1,
        last_click: qus_id,
        user_details: { ...responseBy, address: userAddress },
      };
      workspaceService.createTrackEvents(params).then((res) => {
        if (res.status === 200) {
          trigger !== "survey started" && setTrackedID(trigger);
        }
      });
    }
  };

  const handlePeriod = (value, item) => {
    let pay = [{ text: value, ans_id: item.ans_id, id: item.ans_id }];
    setSelectedPeriod(pay);

    const cloned = clone(payment);
    let index = cloned.findIndex(
      (x) =>
        x.qus_text === "Period Type" && x.paymentGroup === props.paymentGroup
    );
    cloned[index]["answers"] = pay;
    setPayment(cloned);
    onChange && onChange({ name: "paymentquestions", value: cloned });
  };
  useEffect(() => {
    if (
      Cloneddata &&
      Cloneddata.paymentquestions &&
      Cloneddata.paymentquestions.length > 0
    ) {
      setPayment(Cloneddata.paymentquestions);
    }
    else{
      let payment = [];
      logicPayment?.forEach((e) => {
        if (e.is_payment) {
          payment.push({
            qus_id: e.qus_id,
            q_type: e.q_type,
            qus_text: e.question,
            answers: [],
            is_payment: true,
            paymentGroup: e.paymentGroup,
          });
        }
      });
      setPayment(payment);
    }
  }, [Cloneddata]);

  const handleAmount = (value, item) => {
    if (value === "other") {
      setSelectedOtherAmount(true);
      setOtherAmountItem(item);
      return;
    }
    console.log(value, "item--->", item);
    setSelectedAmountToShow(parseFloat(value));
    let pay = [{ text: parseInt(value), ans_id: item.ans_id, id: item.ans_id }];
    setSelectedAmount(pay);
    
    const cloned = clone(payment);
    let index = cloned.findIndex(
      (x) => x.qus_text === "Amount" && x.paymentGroup === props.paymentGroup
    );
    console.log(cloned,"sdsd<?<>?>?clonedsd", index);
    cloned[index]["answers"] = pay;
    setPayment(cloned);
    onChange && onChange({ name: "paymentquestions", value: cloned });
  };

  const handleNoClick = () => {
    setServiceYes(false);
    let pay = { includeServiceCharge: false, serviceChargePercent: null };
    const cloned = clone(payment);
    let index = cloned.findIndex(
      (x) => x.qus_text === "Amount" && x.paymentGroup === props.paymentGroup
    );
    let newService = cloned[index];
    let clickCheckPayment = {
      ...newService,
      ...pay,
    };
    cloned[index] = clickCheckPayment;
    setPayment(cloned);
    onChange && onChange({ name: "paymentquestions", value: cloned });
  };

  const handleYesClick = () => {
    let pay = { includeServiceCharge: true, serviceChargePercent: 0 }; // Initialize with 0
  
    // Check if selectedMethod is not empty and exists in serviceCharges
    if (Array.isArray(selectedMethod) && selectedMethod.length > 0) {
      pay.serviceChargePercent = serviceCharges[selectedMethod[0].text];
    } else {
      console.log("Selected method not found in serviceCharges:", selectedMethod);
    }
    setServiceYes(true);
    const cloned = clone(payment);
    let index = cloned.findIndex(
      (x) => x.qus_text === "Amount" && x.paymentGroup === props.paymentGroup
    );
    let newService = cloned[index];
    let clickCheckPayment = {
      ...newService,
      ...pay,
    };
    cloned[index] = clickCheckPayment;
    setPayment(cloned);
    onChange && onChange({ name: "paymentquestions", value: cloned });
  };
  const handleOtherAmountChange = () => {
    if (otheramount.trim() !== "") {
      // Create a new array with the selected amount and replace "other"
      const updatedAmountToCheck = amounttocheck.map((item) => {
        if (item.label === "other") {
          return {
            label: parseFloat(otheramount), // Convert the input to a number
            id: otherAmountItem?.id,
            ans_id: otherAmountItem?.ans_id,
          };
        }
        return item;
      });

      // Update the state with the new array
      setAmounttocheck(updatedAmountToCheck);

      // Reset the "other" input field and selectedOtherAmount state
      setOtherAmount("");
      setSelectedOtherAmount(false);

      // Handle any other logic you need, e.g., updating the parent component state
      console.log(otheramount, "otheramount<><>???><><", updatedAmountToCheck);
    }
  };

  const handleMethod = (value, item) => {
    let pay = [{ text: value, ans_id: item.ans_id, id: item.ans_id }];
    setSelectedMethod(pay);
    const cloned = clone(payment);
    let index = cloned.findIndex(
      (x) =>
        x.qus_text === "Payment Methods" &&
        x.paymentGroup === props.paymentGroup
    );
    cloned[index]["answers"] = pay;
    setPayment(cloned);
    onChange && onChange({ name: "paymentquestions", value: cloned });
  };
  const {
      font,
      underline,
      bold,
      fontSize,
      italic,
      fontColor,
      multiple_selection,
      require,
      randomize,
      supersize,
    } = settings,
    style = {
      fontFamily: font,
      fontSize,
      fontWeight: bold ? "bold" : "",
      fontStyle: italic ? "italic" : "",
      textDecoration: underline ? "underline" : "",
      color: fontColor,
    };
  const [selectedPic, setSelection] = useState([]);
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isServiceCharge, setIsServiceCharge] = useState(false);
  const [serviceCharges, setServiceCharges] = useState({});
  const [errorMessage, setErrorMessage] = useState({
    email: "",
    phone: "",
  });
  const [alertMsg, setAlertMsg] = useState("");
  const [isChangeField, setIsChangeField] = React.useState(false);
  const [err, setErr] = useState("");
  const [payment, setPayment] = useState([]);
  const [otheramount, setOtherAmount] = useState([]);
  const [amounttocheck, setAmounttocheck] = useState([]);
  const [paymentMethodstocheck, setPaymentMethodstocheck] = useState([]);
  const [periodtocheck, setPeriodtocheck] = useState([]);
  const [period, setPeriod] = useState([
    { name: "p1", value: "One Time" },
    { name: "p2", value: "Recurring-Month" },
    { name: "p3", value: "Recurring-Year" },
  ]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [customizeAmount, setCustomizeAmount] = useState("");
  const [data, setData] = useState([]);

  const [open, setOpen] = useState({}),
    onDropHandle = (e, drop, isFilter1) => {
      setOpen({});
      setOpen({ [drop]: !open[drop] });
    };

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (!loading && res?.id) {
      window["answeredData"] = null;
      const { quest_desc = {} } = res || {};
      const { questions_list = [] } = quest_desc || {};
      setData(questions_list || []);
    }
  }, [res]);

  useEffect(() => {
    if (quesData && quesData.length) {
      dataToCheck(quesData);
      let payment = [];
      quesData.forEach((e) => {
        if (e.is_payment) {
          payment.push({
            qus_id: e.qus_id,
            q_type: e.q_type,
            qus_text: e.question,
            answers: [],
            is_payment: true,
            paymentGroup: e.paymentGroup,
          });
        }
      });

      //setPayment(payment);
    }
  }, [quesData]);

  // useEffect(( )=>{
  //   console.log(isPreview,"res><><?>?<>",logicPayment)
   
  // },[logicPayment])

  useEffect(() => {
    getServiceCharges();
  }, []);

  const getServiceCharges = async () => {
    try {
      const response = await api.get("/getServiceCharges", {
        params: {
          jwt_authentication: localStorage.getItem("token"),
        },
      });
  
      const { data } = response;
  
      if (data && data?.data && data?.data.service_charge ) {
        setServiceCharges(data?.data?.service_charge);
         console.log(data.data.service_charge,"FETCHINGTHEAPI")
      }
    } catch (error) {
      console.error("Error fetching service charges:", error);
    }
  };

  const validationCheck = (value, type) => {
    if (type === "email") {
      if (value.trim() === "") {
        setEmail(value);
        setErrorMessage({
          ...errorMessage,
          email: "Required Field",
        });
      } else if (
        !value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
      ) {
        setEmail(value);
        setErrorMessage({
          ...errorMessage,
          email: "Enter a valid email address",
        });
      } else {
        setEmail(value);
        setErrorMessage({
          ...errorMessage,
          email: "",
        });
      }
    } else {
      if (value.trim() === "") {
        setPhone(value);
        setErrorMessage({
          ...errorMessage,
          phone: "Required Field",
        });
      } else if (
        !value.match(
          /^(?:(?:\+1|1)[ -]?)?(?:\(\d{3}\)|\d{3})(?:[ -]?)?\d{3}(?:[ -]?)\d{4}$/
        )
      ) {
        setPhone(value);
        setErrorMessage({
          ...errorMessage,
          phone: "Enter a valid phone address",
        });
      } else {
        setPhone(value);
        setErrorMessage({
          ...errorMessage,
          phone: "",
        });
      }
    }
  };

  const dataToCheck = (data) => {
    {
      data &&
        data.map((item) => {
          if (item.paymentGroup === props.paymentGroup) {
            if (item.question == "Payment Methods") {
              if (item?.settings) {
                const {
                  font,
                  underline,
                  bold,
                  fontColor,
                  italic,
                  buttonfontColor,
                } = item.settings;
                setNewStyle({
                  font,
                  underline,
                  bold,
                  fontColor,
                  italic,
                  buttonfontColor,
                });
              }
              if (item?.settings?.servicecharge) {
                setIsServiceCharge(true);
              } else {
                setIsServiceCharge(false);
              }
              setPaymentMethodstocheck(item.ans_options);
            }
            if (item.question == "Amount") {
              setAmounttocheck(item.ans_options);
            }
            if (item.question == "Period Type") {
              setPeriodtocheck(item.ans_options);
            }
          }
        });
    }
  };

  const onPaymentBlur = (e) => {
    if (e && e.target) {
      const cloned = clone(payment);
      let index = cloned.findIndex(
        (x) =>
          x.qus_text === e.target.name && x.paymentGroup === props.paymentGroup
      );
      cloned[index]["answers"] = [{ text: e.target.value }];
      setPayment(cloned);
      onChange && onChange({ name: "paymentquestions", value: cloned });
    }
  };

  const onNext = () => {
    if (btnText === "Next") {
      cardRef && cardRef.current.next();
      qRefs?.current?.length >= qno &&
        setTimeout(() => {
          qRefs.current[qno]?.focus();
        }, 10);
    } else {
      console.log(submit, "Submit<><<>ThisS", onChange);
      submit && submit();
    }
  };

  const commonInputStyle = {
    fontFamily: newstyle.font,
    textDecoration: newstyle.underline ? "underline" : "none",
    fontWeight: newstyle.bold ? "bold" : "normal",
    color: newstyle.fontColor,
    fontStyle: newstyle.italic ? "italic" : "normal",
  };

  return (
    <div className="paymentForm">
      <h2 className="paymentForm__title">Payment</h2>
      <div className="paymentForm__controls">
        <div className="paymentForm__control">
          <label style={commonInputStyle}>First Name</label>
          <input
            style={commonInputStyle}
            onClick={() => {
              createEventsTracker("First Name");
            }}
            type="text"
            placeholder="First Name"
            name="First Name"
            onBlur={onPaymentBlur}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="paymentForm__control">
          <label style={commonInputStyle}>Last Name</label>
          <input
            style={commonInputStyle}
            onClick={() => {
              createEventsTracker("Last Name");
            }}
            type="text"
            placeholder="Last Name"
            name="Last Name"
            onBlur={onPaymentBlur}
            value={lastname}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>

        <div className="paymentForm__control">
          <label style={commonInputStyle}>Email</label>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <input
              style={commonInputStyle}
              onClick={() => {
                createEventsTracker("Email");
              }}
              type="email"
              placeholder="my@name.com"
              name="Email"
              onBlur={onPaymentBlur}
              value={email}
              onChange={(e) => validationCheck(e.target.value, "email")}
            />
            <p style={{ color: "red" }}>{errorMessage.email}</p>
          </div>
        </div>

        <div className="paymentForm__control">
          <label style={commonInputStyle}>Phone</label>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <input
              onClick={() => {
                createEventsTracker("Contact");
              }}
              style={commonInputStyle}
              type="tel"
              placeholder="1-499-345-7890"
              name="Contact"
              onBlur={onPaymentBlur}
              value={phone}
              onChange={(e) => validationCheck(e.target.value, "phone")}
            />
            <p style={{ color: "red" }}>{errorMessage.phone}</p>
          </div>
        </div>
      </div>
      <div className="paymentForm__previewFlex">
        <div className="">
          <div className="paymentForm__controls--title">
            1. SELECT PAYMENT METHOD
          </div>
          <div className="paymentForm__buttonList">
            {paymentMethodstocheck &&
              paymentMethodstocheck.map((item) => {
                return (
                  <button
                    className="defaultBtn"
                    value={item.label}
                    onClick={(e) => {
                      createEventsTracker("Payment Methods");

                      handleMethod(e.target.value, item);
                    }}
                    style={{
                      backgroundColor: newstyle.buttonfontColor,
                      opacity:
                        selectedMethod.findIndex(
                          (x) => x.text === item.label
                        ) !== -1
                          ? 0.5
                          : 1.5,
                      // border: selectedMethod.findIndex((x)=>x.text===item.label)!==-1 ? '2px solid #18A0FB' : '2px solid transparent',
                      // boxShadow: selectedMethod.findIndex((x)=>x.text===item.label)!==-1 ? '0 0 5px #18A0FB' : 'none',
                    }}
                  >
                    {item.label}
                  </button>
                );
              })}
          </div>

          <div className="paymentForm__controls--title">
            2. CHOOSE YOUR DONATION
          </div>
          <div className="paymentForm__buttonList">
            {amounttocheck &&
              amounttocheck.map((item) => {
                return (
                  <button
                    className="defaultBtn"
                    value={item.label}
                    onClick={(e) => {
                      createEventsTracker("Amount");

                      handleAmount(e.target.value, item);
                    }}
                    style={{
                      backgroundColor: newstyle.buttonfontColor,
                      opacity:
                        selectedAmount.findIndex(
                          (x) => x.text === item.label
                        ) !== -1
                          ? 0.5
                          : 1.5,
                      // border: selectedAmount.findIndex((x)=>x.text===item.label)!==-1 ? '2px solid #18A0FB' : '2px solid transparent',
                      // boxShadow: selectedAmount.findIndex((x)=>x.text===item.label)!==-1 ? '0 0 5px #18A0FB' : 'none',
                    }}
                  >
                    {item.label == "other" ? item.label : `$ ${item.label}`}
                  </button>
                );
              })}
            {selectedOtherAmount && selectedOtherAmount ? (
              <div className="addAmountBtn">
                <input
                  type="number"
                  placeholder="Enter amount"
                  value={otheramount}
                  onChange={(e) => setOtherAmount(e.target.value)}
                />
                <button onClick={handleOtherAmountChange}>Save Amount</button>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="paymentForm__buttonList">
            {periodtocheck?.length &&
              periodtocheck.map((item) => {
                
                if (
                  selectedMethod.findIndex((x) => x.text === "Credit Card (bambora)") !== -1 &&
                  (item.label === "Recurring-Month" || item.label === "Recurring-Year")
                ) {
                  // Don't render the "recurringmonth" and "recurringyear" buttons
                  return null;
                }
                return(  <button className="defaultBtn" name={item.label} value={item.label}
                  onClick={(e) => {
                    createEventsTracker("Period Type")
                    handlePeriod(e.target.value, item)
                  }}
                style={{
                  backgroundColor : newstyle.buttonfontColor,
                  opacity: selectedPeriod.findIndex((x) => x.text === item.label) !== -1 ? 0.5 : 1.5,
                  // border: selectedPeriod.findIndex((x)=>x.text===item.label)!==-1 ? '2px solid #18A0FB' : '2px solid transparent', // Change background color conditionally
                  // boxShadow: selectedPeriod.findIndex((x)=>x.text===item.label)!==-1 ? '0 0 5px #18A0FB' : 'none', // Change text color conditionally
               }}>
                    {item.label}
                  </button>
                );
              })}
          </div>
        </div>
        {/* this is the code for the service charge this will be conditionally implemented */}
        {isServiceCharge && (
          <div className="paymentForm__serviceCharge">
            <div className="paymentForm__buttonList">
              <h2>Includes Service Charge</h2>
              <div className="buttonGroup">
                <button
                  className="defaultBtn"
                  onClick={(e) => {
                    //  createEventsTracker("ServiceCharge")
                    handleYesClick();
                  }}
                  style={{
                    backgroundColor: newstyle.buttonfontColor,
                    opacity: serviceYes ? 0.5 : 1.5,
                    // border: serviceYes ? '#18A0FB' : '', // Change background color conditionally
                    // boxShadow: serviceYes ? 'white' : '', // Change text color conditionally
                  }}
                  value={"Yes"}
                >
                  <span>A</span>
                  {"Yes"}
                </button>
                <button
                  className="defaultBtn"
                  style={{
                    backgroundColor: newstyle.buttonfontColor,
                    opacity: serviceYes == false ? 0.5 : 1.5,
                    // border: serviceYes == false ? '2px solid #18A0FB' : '2px solid transparent', // Change background color conditionally
                    // boxShadow: serviceYes == false ? '0 0 5px #18A0FB' : 'none', // Change text color conditionally
                  }}
                  onClick={(e) => {
                    handleNoClick();
                  }}
                  value={"No"}
                >
                  <span>B</span>
                  {"No"}
                </button>
              </div>
              {selectedMethod.length > 0 && isServiceCharge ? (
  <p>
    {serviceCharges[selectedMethod[0].text]
      ? `${serviceCharges[selectedMethod[0].text]}% will be added, i.e., ${
          selectedAmountToShow
            ? `${(selectedAmountToShow * (1 + serviceCharges[selectedMethod[0].text] / 100)).toFixed(2)} will be deducted`
            : ""
        }`
      : ""}
  </p>
) : (
  ""
)}           
            </div>
          </div>
        )}
      </div>


      {layout == 1 && children}

      {err !== "" ? (
        <div>
          {" "}
          <p style={{ color: "red" }}>{err}</p>
        </div>
      ) : (
        <div>
          <p>&nbsp;</p>
        </div>
      )}

      {sliderView ? (
        btnText === "Submit" && !showSubmitBtn ? null : (
          <div className="btnGroup btnPadding">
            <button className="btnSave" onClick={onNext}>
              {btnText}
            </button>
          </div>
        )
      ) : null}
    </div>
  );
};
export default PaymentChoice;
