import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import { AdminService } from "../../../services/AdminService";
import { useDispatch } from "react-redux";
import { teamsAction } from "../../../actions/teamsAction";
import { swalAlert } from "../../Common/SwalAlert";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";

const SubTeamForm = ({ onCancel, onSubTeamUpdated, subTeamData }) => {
  const [subTeam, setSubTeam] = useState({ sub_team_name: "", description: "" });
  const [userData, setUserData] = useState([]); // Available users
  const [teamMembers, setTeamMembers] = useState([]); // Current team members
  const [selectedMembers, setSelectedMembers] = useState([]); // Selected users

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUsers = async () => {
      const data = await AdminService.getUsers();
      setUserData(data?.data || []);
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    if (subTeamData) {
      setSubTeam(subTeamData);
    }
  }, [subTeamData]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      if (subTeam.id) {
        const data = await AdminService.getSubTeamMembers(subTeam.id);
        setTeamMembers(data?.data?.members || []);
      }
    };
    fetchTeamMembers();
  }, [subTeam.id]);

  const handleChange = (e) => {
    setSubTeam({ ...subTeam, [e.target.name]: e.target.value });
  };

  const handleMemberSelection = (email) => {
    setSelectedMembers((prevSelected) =>
      prevSelected.includes(email)
        ? prevSelected.filter((e) => e !== email)
        : [...prevSelected, email]
    );
  };

  const filteredUsers = userData.filter(
    (user) => !teamMembers.some((member) => member.email === user.email)
  );
  

  const handleAddMembers = async () => {
    if (selectedMembers.length === 0) {
      swalAlert({ icon: "warning", title: "Please select at least one member." });
      return;
    }
  
    try {
      const response = await AdminService.addSubTeamMember(subTeam.id, selectedMembers);
      if (response) {
        swalAlert({ icon: "success", title: "Members added successfully!" , showConfirmButton: true,});
  
        // Refresh the team members list
        const updatedMembers = await AdminService.getSubTeamMembers(subTeam.id);
        setTeamMembers(updatedMembers?.data?.members || []);
        setSelectedMembers([]); // Clear selected members
      }
    } catch (error) {
      swalAlert({ icon: "error", title: "Failed to add members. Please try again." });
      console.error("Error adding members:", error);
    }
  };

  const handleSubmit = async () => {
    const payload = {
      sub_team_name: subTeam.sub_team_name,
      description: subTeam.description,
    };

    if (subTeam.id) {
      dispatch(teamsAction.updateSubTeam(subTeam.id, payload));
    } else {
      dispatch(teamsAction.addSubTeam(payload));
    }
    onCancel();
  };

  const handleDeleteMember = async (userId) => {
    const confirm = await Swal.fire({
      title: "Are you sure?",
      text: "You want to remove this member from the sub-team?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove",
      cancelButtonText: "Cancel",
    });
  
    if (confirm.isConfirmed) {
      try {
        const response = await AdminService.deleteSubTeamMember(subTeam.id, userId);
        if (response) {
          swalAlert({ icon: "success", title: "Member deleted successfully!", showConfirmButton: true });
  
          // Refresh the team members list
          const updatedMembers = await AdminService.getSubTeamMembers(subTeam.id);
          setTeamMembers(updatedMembers?.data?.members || []);
        }
      } catch (error) {
        swalAlert({ icon: "error", title: "Failed to remove member. Please try again." });
        console.error("Error deleting member:", error);
      }
    }
  };
  

  return (
    <Card sx={{ p: 3, backgroundColor: "#fff", borderRadius: "8px", boxShadow: 3 }}>
      <CardContent>
        <Grid container spacing={3}>
          {/* Left Section: Sub-team info */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Sub-Team Details
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <TextField
              fullWidth
              label="Sub Team Name"
              name="sub_team_name"
              value={subTeam.sub_team_name}
              onChange={handleChange}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={subTeam.description}
              onChange={handleChange}
              sx={{ mb: 2 }}
            />
            <Box mt={2}>
              <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mr: 2 }}>
                {subTeam.id ? "Update" : "Submit"}
              </Button>
              <Button variant="outlined" onClick={onCancel}>
                Cancel
              </Button>
            </Box>
          </Grid>

          {subTeam.id && (
  <>
    {/* Middle Section: Select Users */}
    <Grid item xs={12} md={4}> 
      <Typography variant="h6" gutterBottom>
        Select users to add
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <List sx={{ maxHeight: 250, overflow: "auto", border: "1px solid #ddd", borderRadius: "4px" }}>
        {filteredUsers.map((user) => (
          <ListItem key={user.id} button onClick={() => handleMemberSelection(user.email)}>
            <Checkbox checked={selectedMembers.includes(user.email)} />
            <ListItemText primary={`${user.firstname} ${user.lastname}`} secondary={user.email} />
          </ListItem>
        ))}
      </List>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleAddMembers}
        sx={{ mt: 2, width: "100%" }}
      >
        Add Selected Members
      </Button>
    </Grid>

    {/* Right Section: Team Members */}
    <Grid item xs={12} md={4}>
      <Typography variant="h6" gutterBottom>
        Team Members
      </Typography>
      <Divider sx={{ mb: 2 }} />

      <List sx={{ maxHeight: 250, overflow: "auto", border: "1px solid #ddd", borderRadius: "4px" }}>
        {teamMembers.length > 0 ? (
          teamMembers.map((member) => (
            <ListItem
              key={member.email}
              secondaryAction={
                <IconButton edge="end" color="error" onClick={() => handleDeleteMember(member.user_id)}>
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText primary={`${member.first_name}`} secondary={member.email} />
            </ListItem>
          ))
        ) : (
          <Typography variant="body2" align="center" sx={{ p: 2 }}>
            No members added yet.
          </Typography>
        )}
      </List>
    </Grid>
  </>
)}

        </Grid>
      </CardContent>
    </Card>
  );
};

export default SubTeamForm;
