import cn from "classnames";
import "./dropdown.css";
import { useState, useEffect, useRef } from "react";
import MenuItems from "./MenuItems";
import ReactPaginate from "react-paginate";
import "./pagination.css";
import Dialogbox from "../../../../Common/DialogBox";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { surveyActions } from "../../../../../actions";
import { useParams } from "react-router";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { swalAlert } from "../../../../Common/SwalAlert";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import "../../SurveyEffectiveness/customDropdown.scss";

const GetNewRecommendations = ({
  answerData,
  setPageNumber,
  questionId,
  customCategory,
  page,
}) => {
  const [menu, setMenu] = useState([]);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [act, setAct] = useState("replace");
  const categoryInsightsByQuestion = useSelector(
    (state) => state.survey.categoryInsightsByQuestion
  );
  const onChange = (e) => {
    const { name, checked } = e.target;
    if (name === "replace" && !checked) {
      setAct("merge");
      return;
    }
    if (name === "merge" && !checked) {
      setAct("replace");
      return;
    }
    setAct(name);
  };
  const allFilter = useSelector((state) => state.survey.allFilter);
  const sentimentFilter = useSelector((state) => state.survey.sentimentFilter);
  const [discoverRelations, setDiscoverRelations] = useState(3);
  const [refineCategories, setRefineCategories] = useState(3);
  const [aiRecommendedCategory, setAiRecommendedCategory] = useState(true);
  const [showCategory, setShowCategory] = useState(false);
  const [categoryText, setCategoryText] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const { id } = useParams();
  const getAnswerType = (answer, text) => {
    //     Step 1: Sort the given data in increasing order.
    // Step 2: Count the number of observations.
    // Step 3: If the number of observations is odd use median formula: Median = [(n + 1)/2]th term
    // Step 4: If the number of observations is even use median formula: Median = [(n/2)th term + (n/2 + 1)th term]/2

    var answerArray = [];

    for (let i = 0; i < answer.length; i++) {
      answerArray.push(answer[i].answers[0].text.toString().length);
    }

    // First we sort
    // the array

    var sortedAnswerArray = answerArray.sort();

    var n = sortedAnswerArray.length;

    // check for
    // even case
    var median;
    if (n % 2 != 0) {
      median = sortedAnswerArray[(n + 1) / 2];
    } else {
      median =
        (sortedAnswerArray[Math.floor((n - 1) / 2)] +
          sortedAnswerArray[n / 2]) /
        2;
    }

    if (median < text.toString().length) {
      return "long";
    } else {
      return "short";
    }
  };
  const updateCustomCategory = useSelector(
    (state) => state.survey.updateCustomCategory
  );
  useEffect(() => {
    const menuItems = [
      {
        title: "Select All",
        value: "all",
        submenu: [
          {
            title: "Profanity",
            value: "profanity",
          },
          {
            title: "Short Answers",
            value: "shortAnswers",
          },
          {
            title: "Sentiment",
            value: "sentiment",

            submenu: [
              {
                value: "positive",
                title: "Positive",
              },
              {
                value: "negative",
                title: "Negative",
              },
              {
                value: "neutral",
                title: "Neutral",
              },
            ],
          },
        ],
      },
    ];
    const menuLinks = menuItems.map((menu, index) => {
      const depthLevel = 0;
      return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
    });
    setMenu(menuLinks);
  }, []);

  useEffect(() => {
    if (
      customCategory !== null &&
      customCategory.customCat &&
      customCategory.customCat.length > 0
    ) {
      const temp = [...customCategory.customCat[0].categories];
      const categoryPrefilled = [];
      temp.forEach((e) => {
        categoryPrefilled.push({
          word: e,
        });
      });
      setCategoryData(categoryPrefilled);
    }
  }, [customCategory]);
  const handleOk = (act) => {
    if (aiRecommendedCategory) {
      dispatch(
        surveyActions.postAiRecommendations(
          id,
          questionId,
          refineCategories,
          discoverRelations,
          [],
          act
        )
      );
    } else {
      if (categoryData.length === 0) {
        swalAlert({
          icon: "error",
          title: "Please Add Custom Categories".toString(),
          showConfirmButton: true,
        });
        return 0;
      }
      dispatch(
        surveyActions.postAiRecommendations(
          id,
          questionId,
          refineCategories,
          discoverRelations,
          categoryData.map((x) => {
            return x.word;
          }),
          act
        )
      );
    }
  };
  const onCloseHandle = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    if (questionId !== null)
      dispatch(surveyActions.getCustomCategory(id, questionId));
  }, [updateCustomCategory]);
  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setPageNumber(selectedPage + 1);
  };

  const infoboxActive = useRef();

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 600,
    },
  });

  const typeField = useRef();
  const subDropdown = useRef();

  window.onclick = (event) => {
    if (!event.target.matches(".dropdown__btn")) {
      var sharedowns = document.getElementsByClassName("dropdown__list");
      var subDropdown = document.getElementById("multilevelDropdown");
      var i;
      for (i = 0; i < sharedowns.length; i++) {
        var openSharedown = sharedowns[i];
        if (openSharedown?.classList?.contains("list--active")) {
          openSharedown?.classList?.remove("list--active");
        }
      }

      if (subDropdown?.classList?.contains("active")) {
        subDropdown?.classList?.remove("active");
      }
    }
  };

  return (
    <>
      <Dialogbox
        open={modalOpen}
        handleClose={onCloseHandle}
        handleOk={handleOk}
        dialogContentClass={"createContactInner"}
        rootClass="uploadDialog"
      >
        <div className="closeDailogNew" onClick={onCloseHandle}>
          <HighlightOffTwoToneIcon />
        </div>
        <div className="createContactDialog">
          <h3>Ai Recommendations</h3>
          <div className="radioGroup">
            <FormControlLabel
              control={
                <Checkbox
                  name="replace"
                  checked={act === "replace"}
                  color="success"
                  onChange={onChange}
                />
              }
              label="Replace Current"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="merge"
                  checked={act === "merge"}
                  color="success"
                  onChange={onChange}
                />
              }
              label="Merge Existing"
            />
          </div>
          <button className="btn" onClick={() => handleOk(act)}>
            Continue
          </button>
        </div>
      </Dialogbox>{" "}
      <div className="tabData-Wrap">
        <div className="headBox">
          <div className="title">Get New Category</div>
          <div className="item">
            <button
              className="setting-btn"
              onClick={() => {
                infoboxActive.current.classList.toggle("infobox-active");
              }}
            >
              <span className="setting-title">
                <img src={window.location.origin + "/images/settingblue.png"} />{" "}
                Categories
              </span>
            </button>
            <div className="setting-infobox" ref={infoboxActive}>
              <div className="itembox">
                <div className="range-items">
                  <div className="range-item">
                    <label htmlFor="DiscoverRelations" className="form-label">
                      Discover Relations
                    </label>
                    <input
                      style={{ padding: "0px !important" }}
                      value={discoverRelations}
                      onChange={(e) => {
                        setDiscoverRelations(e.target.value);
                      }}
                      type="range"
                      className="form-range"
                      id="DiscoverRelations"
                    />
                  </div>
                  <div className="range-item">
                    <label htmlFor="RefineCategories" className="form-label">
                      Refine Categories
                    </label>
                    <input
                      value={refineCategories}
                      onChange={(e) => {
                        setRefineCategories(e.target.value);
                      }}
                      type="range"
                      className="form-range"
                      id="RefineCategories"
                    />
                  </div>
                </div>
              </div>
              <div className="itembox">
                <ul className="radio-list">
                  <li
                    onClick={() => {
                      setAiRecommendedCategory(!aiRecommendedCategory);
                    }}
                    className="radio-item"
                  >
                    <span className="option">
                      <input
                        checked={aiRecommendedCategory}
                        className="inputfield"
                        type="radio"
                      />
                      <label>Get AI Recommended Categories</label>
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      setAiRecommendedCategory(!aiRecommendedCategory);
                    }}
                    className="radio-item"
                  >
                    <span className="option">
                      <input
                        checked={!aiRecommendedCategory}
                        className="inputfield"
                        type="radio"
                      />
                      <label>Use your own categories</label>
                    </span>
                  </li>
                </ul>
                <div className="categories-list">
                  {!showCategory && !aiRecommendedCategory ? (
                    <button
                      onClick={() => {
                        setShowCategory(true);
                      }}
                      className="add-category-btn"
                    >
                      <svg
                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="ControlPointIcon"
                        aria-label="Add Questions Type"
                        data-mui-internal-clone-element="true"
                      >
                        <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
                      </svg>
                      Add Category
                    </button>
                  ) : null}

                  {showCategory && !aiRecommendedCategory ? (
                    <div className="times-fieldbox">
                      <input
                        value={categoryText}
                        onChange={(e) => {
                          setCategoryText(e.target.value);
                        }}
                        type="text"
                        placeholder=""
                      />
                      <button
                        onClick={() => {
                          if (!categoryText.length > 0) {
                            return 0;
                          }
                          let temp = [...categoryData];

                          const idx = temp.findIndex(
                            (x) => x.word === categoryText
                          );
                          if (idx === -1) {
                            temp = [...temp, { word: categoryText }];
                            setCategoryData(temp);
                          }
                          setCategoryText("");
                          setShowCategory(false);
                        }}
                        className="btn"
                      >
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.0002 1.83398C9.18717 1.83398 7.41489 2.3716 5.90744 3.37885C4.39999 4.38609 3.22507 5.81773 2.53127 7.49272C1.83747 9.16771 1.65594 11.0108 2.00964 12.789C2.36333 14.5671 3.23637 16.2005 4.51835 17.4825C5.80034 18.7644 7.43368 19.6375 9.21184 19.9912C10.99 20.3449 12.8331 20.1634 14.5081 19.4695C16.1831 18.7757 17.6147 17.6008 18.622 16.0934C19.6292 14.5859 20.1668 12.8136 20.1668 11.0007C20.1668 9.79687 19.9297 8.60487 19.4691 7.49272C19.0084 6.38057 18.3332 5.37004 17.482 4.51884C16.6308 3.66764 15.6202 2.99242 14.5081 2.53176C13.3959 2.07109 12.204 1.83398 11.0002 1.83398ZM11.0002 18.334C9.54977 18.334 8.13195 17.9039 6.92598 17.0981C5.72002 16.2923 4.78009 15.147 4.22505 13.807C3.67001 12.467 3.52478 10.9925 3.80774 9.56999C4.0907 8.14746 4.78913 6.84079 5.81472 5.8152C6.8403 4.78962 8.14698 4.09118 9.5695 3.80823C10.992 3.52527 12.4665 3.67049 13.8065 4.22553C15.1465 4.78058 16.2918 5.72051 17.0976 6.92647C17.9034 8.13243 18.3335 9.55025 18.3335 11.0007C18.3335 12.9456 17.5609 14.8108 16.1856 16.1861C14.8103 17.5614 12.9451 18.334 11.0002 18.334ZM14.6668 10.084H11.9168V7.33398C11.9168 7.09087 11.8203 6.85771 11.6483 6.6858C11.4764 6.51389 11.2433 6.41732 11.0002 6.41732C10.7571 6.41732 10.5239 6.51389 10.352 6.6858C10.1801 6.85771 10.0835 7.09087 10.0835 7.33398V10.084H7.3335C7.09038 10.084 6.85723 10.1806 6.68532 10.3525C6.51341 10.5244 6.41683 10.7575 6.41683 11.0007C6.41683 11.2438 6.51341 11.4769 6.68532 11.6488C6.85723 11.8207 7.09038 11.9173 7.3335 11.9173H10.0835V14.6673C10.0835 14.9104 10.1801 15.1436 10.352 15.3155C10.5239 15.4874 10.7571 15.584 11.0002 15.584C11.2433 15.584 11.4764 15.4874 11.6483 15.3155C11.8203 15.1436 11.9168 14.9104 11.9168 14.6673V11.9173H14.6668C14.9099 11.9173 15.1431 11.8207 15.315 11.6488C15.4869 11.4769 15.5835 11.2438 15.5835 11.0007C15.5835 10.7575 15.4869 10.5244 15.315 10.3525C15.1431 10.1806 14.9099 10.084 14.6668 10.084Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>
                  ) : null}

                  <div className="categories-data">
                    {categoryData && categoryData.length > 0
                      ? categoryData.map((value, indx) => {
                          return (
                            <span>
                              {value.word}
                              <button
                                onClick={() => {
                                  if (aiRecommendedCategory) {
                                    return 0;
                                  }
                                  const temp = [...categoryData];
                                  const filtered = temp.filter(
                                    (x) => x.word !== value.word
                                  );
                                  setCategoryData(filtered);
                                }}
                                className="category-closebtn"
                              >
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g filter="url(#filter0_d_2195_9364)">
                                    <circle cx="7" cy="5" r="4" fill="white" />
                                  </g>
                                  <path
                                    d="M7.35216 4.99979L8.42612 3.92817C8.47315 3.88113 8.49957 3.81734 8.49957 3.75082C8.49957 3.6843 8.47315 3.6205 8.42612 3.57346C8.37909 3.52643 8.3153 3.5 8.24879 3.5C8.18228 3.5 8.1185 3.52643 8.07146 3.57346L7 4.64758L5.92854 3.57346C5.8815 3.52643 5.81772 3.5 5.75121 3.5C5.6847 3.5 5.62091 3.52643 5.57388 3.57346C5.52685 3.6205 5.50043 3.6843 5.50043 3.75082C5.50043 3.81734 5.52685 3.88113 5.57388 3.92817L6.64784 4.99979L5.57388 6.0714C5.55047 6.09463 5.53189 6.12225 5.51921 6.15269C5.50653 6.18313 5.5 6.21578 5.5 6.24876C5.5 6.28173 5.50653 6.31438 5.51921 6.34482C5.53189 6.37526 5.55047 6.40289 5.57388 6.42611C5.5971 6.44952 5.62472 6.46811 5.65516 6.48079C5.68559 6.49347 5.71824 6.5 5.75121 6.5C5.78418 6.5 5.81682 6.49347 5.84726 6.48079C5.87769 6.46811 5.90532 6.44952 5.92854 6.42611L7 5.352L8.07146 6.42611C8.09468 6.44952 8.12231 6.46811 8.15274 6.48079C8.18318 6.49347 8.21582 6.5 8.24879 6.5C8.28176 6.5 8.31441 6.49347 8.34484 6.48079C8.37528 6.46811 8.4029 6.44952 8.42612 6.42611C8.44953 6.40289 8.46811 6.37526 8.48079 6.34482C8.49347 6.31438 8.5 6.28173 8.5 6.24876C8.5 6.21578 8.49347 6.18313 8.48079 6.15269C8.46811 6.12225 8.44953 6.09463 8.42612 6.0714L7.35216 4.99979Z"
                                    fill="#18A0FB"
                                  />
                                  <defs>
                                    <filter
                                      id="filter0_d_2195_9364"
                                      x="0"
                                      y="0"
                                      width="14"
                                      height="14"
                                      filterUnits="userSpaceOnUse"
                                      colorInterpolationFilters="sRGB"
                                    >
                                      <feFlood
                                        floodOpacity="0"
                                        result="BackgroundImageFix"
                                      />
                                      <feColorMatrix
                                        in="SourceAlpha"
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha"
                                      />
                                      <feOffset dy="2" />
                                      <feGaussianBlur stdDeviation="1.5" />
                                      <feComposite
                                        in2="hardAlpha"
                                        operator="out"
                                      />
                                      <feColorMatrix
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                      />
                                      <feBlend
                                        mode="normal"
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow_2195_9364"
                                      />
                                      <feBlend
                                        mode="normal"
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow_2195_9364"
                                        result="shape"
                                      />
                                    </filter>
                                  </defs>
                                </svg>
                              </button>
                            </span>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
              <div className="itembox">
                {!aiRecommendedCategory && (
                  <button
                    onClick={() => {
                      if (aiRecommendedCategory) {
                        swalAlert({
                          icon: "error",
                          title:
                            "Please Choose Your Own Categories!".toString(),
                          showConfirmButton: true,
                        });
                        return 0;
                      }
                      dispatch(
                        surveyActions.updateCustomCategory({
                          params: {
                            detail_id: {
                              detailId: id,
                              qus_id: questionId,
                              categories: categoryData.map((x) => {
                                return x.word;
                              }),
                            },
                          },
                        })
                      );
                    }}
                    className="btn-save"
                  >
                    Save
                  </button>
                )}
                {aiRecommendedCategory && (
                  <button
                    onClick={() => {
                      if (
                        categoryInsightsByQuestion.data
                          .ai_recommended_categories_percent === 0
                      ) {
                        if (aiRecommendedCategory) {
                          dispatch(
                            surveyActions.postAiRecommendations(
                              id,
                              questionId,
                              refineCategories,
                              discoverRelations,
                              [],
                              "current"
                            )
                          );
                        } else {
                          if (categoryData.length === 0) {
                            swalAlert({
                              icon: "error",
                              title: "Please Add Custom Categories".toString(),
                              showConfirmButton: true,
                            });
                            return 0;
                          }
                          dispatch(
                            surveyActions.postAiRecommendations(
                              id,
                              questionId,
                              refineCategories,
                              discoverRelations,
                              categoryData.map((x) => {
                                return x.word;
                              }),
                              "current"
                            )
                          );
                        }
                      } else {
                        setModalOpen(true);
                      }
                    }}
                    className={`btn-save ${
                      answerData && answerData.answers.length === 0
                        ? "customButtonDisabled"
                        : ""
                    }`}
                  >
                    Submit
                  </button>
                )}
              </div>

              <div className="setting-infobox-pointer"></div>
            </div>
          </div>
        </div>
        <div className="contentbox">
          <div className="menubox-wrap">
            {" "}
            {/* <Select
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                getOptionValue={(option) => option.label}
                formatOptionLabel={formatOptionLabel}
                defaultValue={{
                    label: "All",
                    value: "/images/Tickbox.png",
                }}
                options={[
                    {
                    label: "Profanity",
                    value: "/images/Tickbox.png",
                    },
                    {
                    label: "Short Answers",
                    value: "/images/Tickbox.png",
                    },
                    {
                    label: "Sentiment",
                    value: "/images/Tickbox.png",
                    },
                ]}
            /> */}
            {/* {menu && menu.length > 0 ? <ul className="menus">{menu}</ul> : null} */}
            <div className="select-dropdown select-dropdown-checkbox">
              <div className="dropdown__menu--upload">
                <button
                  className="dropdown__btn"
                  onClick={() => {
                    typeField.current.classList.toggle("list--active");
                  }}
                >
                  {console.log(
                    "jsdjdskjdsjkkds",
                    sentimentFilter.findIndex(
                      (item) => item.value === "profanity"
                    ) === -1 ||
                      sentimentFilter.findIndex(
                        (item) => item.value === "shortAnswers"
                      ) === -1 ||
                      sentimentFilter.findIndex(
                        (item) => item.value === "sentiment"
                      ) === -1 ||
                      sentimentFilter.findIndex(
                        (item) => item.value === "positive"
                      ) === -1 ||
                      sentimentFilter.findIndex(
                        (item) => item.value === "negative"
                      ) === -1 ||
                      sentimentFilter.findIndex(
                        (item) => item.value === "neutral"
                      ) === -1
                  )}
                  {/* <Checkbox size="small" /> */}
                  {sentimentFilter.findIndex(
                    (item) => item.value === "profanity"
                  ) === -1 ||
                  sentimentFilter.findIndex(
                    (item) => item.value === "shortAnswers"
                  ) === -1 ||
                  // sentimentFilter.findIndex(
                  //   (item) => item.value === "sentiment"
                  // ) === -1 ||
                  sentimentFilter.findIndex(
                    (item) => item.value === "positive"
                  ) === -1 ||
                  sentimentFilter.findIndex(
                    (item) => item.value === "negative"
                  ) === -1 ||
                  sentimentFilter.findIndex(
                    (item) => item.value === "very positive"
                  ) === -1 ||
                  sentimentFilter.findIndex(
                    (item) => item.value === "extremely positive"
                  ) === -1 ||
                  sentimentFilter.findIndex(
                    (item) => item.value === "very negative"
                  ) === -1 ||
                  sentimentFilter.findIndex(
                    (item) => item.value === "extremely negative"
                  ) === -1 ||
                  sentimentFilter.findIndex(
                    (item) => item.value === "neutral"
                  ) === -1 ? (
                    <img
                      onClick={() => {
                        dispatch(
                          surveyActions.setSentimentFilter([
                            ...sentimentFilter,
                            { value: "profanity" },
                            { value: "shortAnswers" },
                            { value: "sentiment" },
                            { value: "positive" },
                            { value: "negative" },
                            { value: "very positive" },
                            { value: "extremely positive" },

                            { value: "very negative" },
                            { value: "extremely negative" },

                            { value: "neutral" },
                          ])
                        );
                        setPageNumber(1);

                        dispatch(surveyActions.setAllFilter("all"));
                      }}
                      style={{ width: "15px", height: "13px" }}
                      src="/images/untick.png"
                    ></img>
                  ) : (
                    <img
                      onClick={() => {
                        setPageNumber(1);

                        dispatch(surveyActions.setSentimentFilter([]));
                        dispatch(surveyActions.setAllFilter("negative"));
                      }}
                      style={{ width: "15px", height: "13px" }}
                      src="/images/tick.png"
                    ></img>
                  )}
                  Select All
                  <KeyboardArrowDownRoundedIcon />
                </button>

                <ul className="dropdown__list" ref={typeField}>
                  <li
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        sentimentFilter.findIndex(
                          (item) => item.value === "profanity"
                        ) === -1
                      ) {
                        dispatch(
                          surveyActions.setSentimentFilter([
                            ...sentimentFilter,
                            { value: "profanity" },
                          ])
                        );
                        dispatch(surveyActions.setAllFilter("profanity"));
                      } else {
                        const temp = [...sentimentFilter];
                        let filter;

                        filter = temp.filter(
                          (item, i) => item.value !== "profanity"
                        );
                        setPageNumber(1);

                        dispatch(surveyActions.setSentimentFilter(filter));
                      }
                    }}
                    className="dropdown__list--item"
                  >
                    <div className="title">
                      {sentimentFilter.findIndex(
                        (item) => item.value === "profanity"
                      ) === -1 ? (
                        <img
                          onClick={() => {
                            setPageNumber(1);

                            dispatch(
                              surveyActions.setSentimentFilter([
                                ...sentimentFilter,
                                { value: "profanity" },
                              ])
                            );
                          }}
                          style={{ width: "15px", height: "13px" }}
                          src="/images/untick.png"
                        ></img>
                      ) : (
                        <img
                          onClick={() => {
                            const temp = [...sentimentFilter];
                            let filter;
                            if ("profanity" === "sentiment") {
                              filter = temp.filter(
                                (item, i) => item.value !== "profanity"
                              );
                              filter = temp.filter(
                                (item, i) => item.value !== "positive"
                              );
                              filter = temp.filter(
                                (item, i) => item.value !== "negative"
                              );
                              filter = temp.filter(
                                (item, i) => item.value !== "neutral"
                              );
                              filter = temp.filter(
                                (item, i) => item.value !== "very positive"
                              );
                              filter = temp.filter(
                                (item, i) => item.value !== "extremely positive"
                              );
                              filter = temp.filter(
                                (item, i) => item.value !== "very negative"
                              );
                              filter = temp.filter(
                                (item, i) => item.value !== "extremely negative"
                              );
                            } else {
                              filter = temp.filter(
                                (item, i) => item.value !== "profanity"
                              );
                            }
                            setPageNumber(1);

                            dispatch(surveyActions.setSentimentFilter(filter));
                          }}
                          style={{ width: "15px", height: "13px" }}
                          src="/images/tick.png"
                        ></img>
                      )}
                      Profanity
                    </div>
                    <span className="delete-icon"></span>
                  </li>
                  <li
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        sentimentFilter.findIndex(
                          (item) => item.value === "shortAnswers"
                        ) === -1
                      ) {
                        dispatch(
                          surveyActions.setSentimentFilter([
                            ...sentimentFilter,
                            { value: "shortAnswers" },
                          ])
                        );
                        setPageNumber(1);

                        dispatch(surveyActions.setAllFilter("shortAnswers"));
                      } else {
                        const temp = [...sentimentFilter];
                        let filter;

                        filter = temp.filter(
                          (item, i) => item.value !== "shortAnswers"
                        );
                        setPageNumber(1);

                        dispatch(surveyActions.setSentimentFilter(filter));
                      }
                    }}
                    className="dropdown__list--item"
                  >
                    <div className="title">
                      {/* <Checkbox size="small" /> */}
                      {sentimentFilter.findIndex(
                        (item) => item.value === "shortAnswers"
                      ) === -1 ? (
                        <img
                          onClick={() => {
                            setPageNumber(1);

                            dispatch(
                              surveyActions.setSentimentFilter([
                                ...sentimentFilter,
                                { value: "shortAnswers" },
                              ])
                            );
                          }}
                          style={{ width: "15px", height: "13px" }}
                          src="/images/untick.png"
                        ></img>
                      ) : (
                        <img
                          onClick={() => {
                            const temp = [...sentimentFilter];
                            let filter;
                            if ("shortAnswers" === "sentiment") {
                              filter = temp.filter(
                                (item, i) => item.value !== "shortAnswers"
                              );
                              filter = temp.filter(
                                (item, i) => item.value !== "positive"
                              );
                              filter = temp.filter(
                                (item, i) => item.value !== "negative"
                              );
                              filter = temp.filter(
                                (item, i) => item.value !== "neutral"
                              );
                              filter = temp.filter(
                                (item, i) => item.value !== "very positive"
                              );
                              filter = temp.filter(
                                (item, i) => item.value !== "extremely positive"
                              );
                              filter = temp.filter(
                                (item, i) => item.value !== "very negative"
                              );
                              filter = temp.filter(
                                (item, i) => item.value !== "extremely negative"
                              );
                            } else {
                              filter = temp.filter(
                                (item, i) => item.value !== "shortAnswers"
                              );
                            }
                            setPageNumber(1);

                            dispatch(surveyActions.setSentimentFilter(filter));
                          }}
                          style={{ width: "15px", height: "13px" }}
                          src="/images/tick.png"
                        ></img>
                      )}
                      Short Answers
                    </div>
                    <span className="delete-icon"></span>
                  </li>
                  <li
                    className="dropdown__list--item"
                    onClick={(e) => {
                      e.stopPropagation();
                      subDropdown.current.classList.toggle("active");
                    }}
                  >
                    <div className="title">
                      <Checkbox size="small" style={{ opacity: "0" }} />
                      Sentiment
                    </div>
                    <span className="delete-icon">
                      <KeyboardArrowRightRoundedIcon />
                    </span>
                    <div
                      className="subDropdown"
                      ref={subDropdown}
                      id="multilevelDropdown"
                    >
                      <ul>
                        <li
                          onClick={(e) => {
                            e.stopPropagation();
                            setPageNumber(1);

                            if (
                              sentimentFilter.findIndex(
                                (item) => item.value === "positive"
                              ) === -1
                            ) {
                              dispatch(
                                surveyActions.setSentimentFilter([
                                  ...sentimentFilter,
                                  { value: "positive" },
                                ])
                              );
                              dispatch(surveyActions.setAllFilter("positive"));
                            } else {
                              setPageNumber(1);

                              const temp = [...sentimentFilter];

                              const filter = temp.filter(
                                (item, i) => item.value !== "positive"
                              );

                              dispatch(
                                surveyActions.setSentimentFilter(filter)
                              );
                            }
                          }}
                          className="dropdown__list--item"
                        >
                          <div className="subDropdown-title">
                            <div>
                              {"positive" !== "sentiment" ? (
                                <span>
                                  {" "}
                                  {sentimentFilter.findIndex(
                                    (item) => item.value === "positive"
                                  ) === -1 ? (
                                    <img
                                      onClick={() => {
                                        setPageNumber(1);

                                        dispatch(
                                          surveyActions.setSentimentFilter([
                                            ...sentimentFilter,
                                            { value: "positive" },
                                          ])
                                        );
                                      }}
                                      style={{
                                        width: "15px",
                                        height: "13px",
                                      }}
                                      src="/images/untick.png"
                                    ></img>
                                  ) : (
                                    <img
                                      onClick={() => {
                                        setPageNumber(1);

                                        const temp = [...sentimentFilter];
                                        let filter;

                                        filter = temp.filter(
                                          (item, i) => item.value !== "positive"
                                        );

                                        dispatch(
                                          surveyActions.setSentimentFilter(
                                            filter
                                          )
                                        );
                                      }}
                                      style={{
                                        width: "15px",
                                        height: "13px",
                                      }}
                                      src="/images/tick.png"
                                    ></img>
                                  )}
                                </span>
                              ) : (
                                <span className="ml-4"></span>
                              )}
                            </div>
                            Positive
                          </div>
                          <span className="delete-icon"></span>
                        </li>

                        <li
                          onClick={(e) => {
                            e.stopPropagation();
                            setPageNumber(1);

                            if (
                              sentimentFilter.findIndex(
                                (item) => item.value === "very positive"
                              ) === -1
                            ) {
                              dispatch(
                                surveyActions.setSentimentFilter([
                                  ...sentimentFilter,
                                  { value: "very positive" },
                                ])
                              );
                              dispatch(
                                surveyActions.setAllFilter("very positive")
                              );
                            } else {
                              setPageNumber(1);

                              const temp = [...sentimentFilter];

                              const filter = temp.filter(
                                (item, i) => item.value !== "very positive"
                              );

                              dispatch(
                                surveyActions.setSentimentFilter(filter)
                              );
                            }
                          }}
                          className="dropdown__list--item"
                        >
                          <div className="subDropdown-title">
                            <div>
                              {"very positive" !== "sentiment" ? (
                                <span>
                                  {" "}
                                  {sentimentFilter.findIndex(
                                    (item) => item.value === "very positive"
                                  ) === -1 ? (
                                    <img
                                      onClick={() => {
                                        setPageNumber(1);

                                        dispatch(
                                          surveyActions.setSentimentFilter([
                                            ...sentimentFilter,
                                            { value: "very positive" },
                                          ])
                                        );
                                      }}
                                      style={{
                                        width: "15px",
                                        height: "13px",
                                      }}
                                      src="/images/untick.png"
                                    ></img>
                                  ) : (
                                    <img
                                      onClick={() => {
                                        setPageNumber(1);

                                        const temp = [...sentimentFilter];
                                        let filter;

                                        filter = temp.filter(
                                          (item, i) =>
                                            item.value !== "very positive"
                                        );

                                        dispatch(
                                          surveyActions.setSentimentFilter(
                                            filter
                                          )
                                        );
                                      }}
                                      style={{
                                        width: "15px",
                                        height: "13px",
                                      }}
                                      src="/images/tick.png"
                                    ></img>
                                  )}
                                </span>
                              ) : (
                                <span className="ml-4"></span>
                              )}
                            </div>
                            Very Positive
                          </div>
                          <span className="delete-icon"></span>
                        </li>

                        <li
                          onClick={(e) => {
                            e.stopPropagation();
                            setPageNumber(1);

                            if (
                              sentimentFilter.findIndex(
                                (item) => item.value === "extremely positive"
                              ) === -1
                            ) {
                              dispatch(
                                surveyActions.setSentimentFilter([
                                  ...sentimentFilter,
                                  { value: "extremely positive" },
                                ])
                              );
                              dispatch(
                                surveyActions.setAllFilter("extremely positive")
                              );
                            } else {
                              setPageNumber(1);

                              const temp = [...sentimentFilter];

                              const filter = temp.filter(
                                (item, i) => item.value !== "extremely positive"
                              );

                              dispatch(
                                surveyActions.setSentimentFilter(filter)
                              );
                            }
                          }}
                          className="dropdown__list--item"
                        >
                          <div className="subDropdown-title">
                            <div>
                              {"extremely positive" !== "sentiment" ? (
                                <span>
                                  {" "}
                                  {sentimentFilter.findIndex(
                                    (item) =>
                                      item.value === "extremely positive"
                                  ) === -1 ? (
                                    <img
                                      onClick={() => {
                                        setPageNumber(1);

                                        dispatch(
                                          surveyActions.setSentimentFilter([
                                            ...sentimentFilter,
                                            { value: "extremely positive" },
                                          ])
                                        );
                                      }}
                                      style={{
                                        width: "15px",
                                        height: "13px",
                                      }}
                                      src="/images/untick.png"
                                    ></img>
                                  ) : (
                                    <img
                                      onClick={() => {
                                        setPageNumber(1);

                                        const temp = [...sentimentFilter];
                                        let filter;

                                        filter = temp.filter(
                                          (item, i) =>
                                            item.value !== "extremely positive"
                                        );

                                        dispatch(
                                          surveyActions.setSentimentFilter(
                                            filter
                                          )
                                        );
                                      }}
                                      style={{
                                        width: "15px",
                                        height: "13px",
                                      }}
                                      src="/images/tick.png"
                                    ></img>
                                  )}
                                </span>
                              ) : (
                                <span className="ml-4"></span>
                              )}
                            </div>
                            Extremely Positive
                          </div>
                          <span className="delete-icon"></span>
                        </li>

                        <li
                          onClick={(e) => {
                            e.stopPropagation();
                            setPageNumber(1);

                            if (
                              sentimentFilter.findIndex(
                                (item) => item.value === "negative"
                              ) === -1
                            ) {
                              dispatch(
                                surveyActions.setSentimentFilter([
                                  ...sentimentFilter,
                                  { value: "negative" },
                                ])
                              );
                              dispatch(surveyActions.setAllFilter("negative"));
                            } else {
                              setPageNumber(1);

                              const temp = [...sentimentFilter];

                              const filter = temp.filter(
                                (item, i) => item.value !== "negative"
                              );

                              dispatch(
                                surveyActions.setSentimentFilter(filter)
                              );
                            }
                          }}
                          className="dropdown__list--item"
                        >
                          <div className="subDropdown-title">
                            {/* <Checkbox size="small" /> */}
                            {"negative" !== "sentiment" ? (
                              <span>
                                {" "}
                                {sentimentFilter.findIndex(
                                  (item) => item.value === "negative"
                                ) === -1 ? (
                                  <img
                                    onClick={() => {
                                      setPageNumber(1);

                                      dispatch(
                                        surveyActions.setSentimentFilter([
                                          ...sentimentFilter,
                                          { value: "negative" },
                                        ])
                                      );
                                    }}
                                    style={{
                                      width: "15px",
                                      height: "13px",
                                    }}
                                    src="/images/untick.png"
                                  ></img>
                                ) : (
                                  <img
                                    onClick={() => {
                                      setPageNumber(1);

                                      const temp = [...sentimentFilter];
                                      let filter;

                                      filter = temp.filter(
                                        (item, i) => item.value !== "negative"
                                      );

                                      dispatch(
                                        surveyActions.setSentimentFilter(filter)
                                      );
                                    }}
                                    style={{
                                      width: "15px",
                                      height: "13px",
                                    }}
                                    src="/images/tick.png"
                                  ></img>
                                )}
                              </span>
                            ) : (
                              <span className="ml-4"></span>
                            )}
                            Negative
                          </div>
                          <span className="delete-icon"></span>
                        </li>

                        <li
                          onClick={(e) => {
                            e.stopPropagation();
                            setPageNumber(1);

                            if (
                              sentimentFilter.findIndex(
                                (item) => item.value === "very negative"
                              ) === -1
                            ) {
                              dispatch(
                                surveyActions.setSentimentFilter([
                                  ...sentimentFilter,
                                  { value: "very negative" },
                                ])
                              );
                              dispatch(
                                surveyActions.setAllFilter("very negative")
                              );
                            } else {
                              setPageNumber(1);

                              const temp = [...sentimentFilter];

                              const filter = temp.filter(
                                (item, i) => item.value !== "very negative"
                              );

                              dispatch(
                                surveyActions.setSentimentFilter(filter)
                              );
                            }
                          }}
                          className="dropdown__list--item"
                        >
                          <div className="subDropdown-title">
                            {/* <Checkbox size="small" /> */}
                            {"very negative" !== "sentiment" ? (
                              <span>
                                {" "}
                                {sentimentFilter.findIndex(
                                  (item) => item.value === "very negative"
                                ) === -1 ? (
                                  <img
                                    onClick={() => {
                                      setPageNumber(1);

                                      dispatch(
                                        surveyActions.setSentimentFilter([
                                          ...sentimentFilter,
                                          { value: "very negative" },
                                        ])
                                      );
                                    }}
                                    style={{
                                      width: "15px",
                                      height: "13px",
                                    }}
                                    src="/images/untick.png"
                                  ></img>
                                ) : (
                                  <img
                                    onClick={() => {
                                      setPageNumber(1);

                                      const temp = [...sentimentFilter];
                                      let filter;

                                      filter = temp.filter(
                                        (item, i) =>
                                          item.value !== "very negative"
                                      );

                                      dispatch(
                                        surveyActions.setSentimentFilter(filter)
                                      );
                                    }}
                                    style={{
                                      width: "15px",
                                      height: "13px",
                                    }}
                                    src="/images/tick.png"
                                  ></img>
                                )}
                              </span>
                            ) : (
                              <span className="ml-4"></span>
                            )}
                            Very Negative
                          </div>
                          <span className="delete-icon"></span>
                        </li>

                        <li
                          onClick={(e) => {
                            e.stopPropagation();
                            setPageNumber(1);

                            if (
                              sentimentFilter.findIndex(
                                (item) => item.value === "extremely negative"
                              ) === -1
                            ) {
                              dispatch(
                                surveyActions.setSentimentFilter([
                                  ...sentimentFilter,
                                  { value: "extremely negative" },
                                ])
                              );
                              dispatch(
                                surveyActions.setAllFilter("extremely negative")
                              );
                            } else {
                              setPageNumber(1);

                              const temp = [...sentimentFilter];

                              const filter = temp.filter(
                                (item, i) => item.value !== "extremely negative"
                              );

                              dispatch(
                                surveyActions.setSentimentFilter(filter)
                              );
                            }
                          }}
                          className="dropdown__list--item"
                        >
                          <div className="subDropdown-title">
                            {/* <Checkbox size="small" /> */}
                            {"extremely negative" !== "sentiment" ? (
                              <span>
                                {" "}
                                {sentimentFilter.findIndex(
                                  (item) => item.value === "extremely negative"
                                ) === -1 ? (
                                  <img
                                    onClick={() => {
                                      setPageNumber(1);

                                      dispatch(
                                        surveyActions.setSentimentFilter([
                                          ...sentimentFilter,
                                          { value: "extremely negative" },
                                        ])
                                      );
                                    }}
                                    style={{
                                      width: "15px",
                                      height: "13px",
                                    }}
                                    src="/images/untick.png"
                                  ></img>
                                ) : (
                                  <img
                                    onClick={() => {
                                      setPageNumber(1);

                                      const temp = [...sentimentFilter];
                                      let filter;

                                      filter = temp.filter(
                                        (item, i) =>
                                          item.value !== "extremely negative"
                                      );

                                      dispatch(
                                        surveyActions.setSentimentFilter(filter)
                                      );
                                    }}
                                    style={{
                                      width: "15px",
                                      height: "13px",
                                    }}
                                    src="/images/tick.png"
                                  ></img>
                                )}
                              </span>
                            ) : (
                              <span className="ml-4"></span>
                            )}
                            Extremely Negative
                          </div>
                          <span className="delete-icon"></span>
                        </li>

                        <li
                          onClick={(e) => {
                            e.stopPropagation();
                            setPageNumber(1);

                            if (
                              sentimentFilter.findIndex(
                                (item) => item.value === "neutral"
                              ) === -1
                            ) {
                              dispatch(
                                surveyActions.setSentimentFilter([
                                  ...sentimentFilter,
                                  { value: "neutral" },
                                ])
                              );
                              dispatch(surveyActions.setAllFilter("neutral"));
                            } else {
                              setPageNumber(1);

                              const temp = [...sentimentFilter];

                              const filter = temp.filter(
                                (item, i) => item.value !== "neutral"
                              );

                              dispatch(
                                surveyActions.setSentimentFilter(filter)
                              );
                            }
                          }}
                          className="dropdown__list--item"
                        >
                          <div className="subDropdown-title">
                            {/* <Checkbox size="small" /> */}
                            {"neutral" !== "sentiment" ? (
                              <span>
                                {" "}
                                {sentimentFilter.findIndex(
                                  (item) => item.value === "neutral"
                                ) === -1 ? (
                                  <img
                                    onClick={() => {
                                      setPageNumber(1);

                                      dispatch(
                                        surveyActions.setSentimentFilter([
                                          ...sentimentFilter,
                                          { value: "neutral" },
                                        ])
                                      );
                                    }}
                                    style={{
                                      width: "15px",
                                      height: "13px",
                                    }}
                                    src="/images/untick.png"
                                  ></img>
                                ) : (
                                  <img
                                    onClick={() => {
                                      setPageNumber(1);

                                      const temp = [...sentimentFilter];
                                      let filter;

                                      filter = temp.filter(
                                        (item, i) => item.value !== "neutral"
                                      );

                                      dispatch(
                                        surveyActions.setSentimentFilter(filter)
                                      );
                                    }}
                                    style={{
                                      width: "15px",
                                      height: "13px",
                                    }}
                                    src="/images/tick.png"
                                  ></img>
                                )}
                              </span>
                            ) : (
                              <span className="ml-4"></span>
                            )}
                            Neutral
                          </div>
                          <span className="delete-icon"></span>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <table className="table table-borderless">
              <thead className="thead-blue">
                <tr style={{ background: "rgba(24, 160, 251, 0.2)" }}>
                  <th scope="col" style={{ width: "50%" }}>
                    Answer
                  </th>
                  <th scope="col">Profanity</th>
                  <th scope="col">Answers Type</th>
                  <th scope="col">Sentiment</th>
                </tr>
                {answerData && answerData.answers.length > 0
                  ? answerData.answers.map((value, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <CustomWidthTooltip
                              title={
                                value.answers &&
                                value.answers.length > 0 &&
                                value.answers[0].text
                              }
                              placement="top-start"
                            >
                              <div className="tableCellText">
                                {value.answers &&
                                  value.answers.length > 0 &&
                                  value.answers[0].text}
                              </div>
                            </CustomWidthTooltip>
                          </td>
                          <td>
                            {value.profanity ? (
                              <img
                                className="mr-2"
                                src={
                                  window.location.origin +
                                  "/images/trueicon.png"
                                }
                              ></img>
                            ) : (
                              <img
                                className="mr-2"
                                src={
                                  window.location.origin +
                                  "/images/Ellipse694.png"
                                }
                              ></img>
                            )}

                            {value.profanity.toString()}
                          </td>
                          <td>
                            {" "}
                            {value.answer_type === "short" ? (
                              <img
                                className="mr-2"
                                src={
                                  window.location.origin +
                                  "/images/no-entry.png"
                                }
                              ></img>
                            ) : (
                              <img
                                className="mr-2"
                                src={
                                  window.location.origin +
                                  "/images/shorttext.png"
                                }
                              ></img>
                            )}
                            {value.answer_type}
                          </td>
                          <td>
                            <span className="profanityStylePositive">
                              {" "}
                              {value.sentiment}
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </thead>
            </table>
          </div>

          <div className="table-footer">
            <div className="pagination-wrapper">
              {answerData && answerData.answerLength ? (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="next >"
                  forcePage={page - 1}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={Math.ceil(answerData?.answerLength / 9)}
                  previousLabel="< previous"
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  subContainerClassName={"page-item"}
                  activeClassName={"active"}
                />
              ) : null}
            </div>
            <div className="btn-wrap">
              <span
                onClick={() => {
                  if (
                    categoryInsightsByQuestion.data
                      .ai_recommended_categories_percent === 0
                  ) {
                    if (aiRecommendedCategory) {
                      dispatch(
                        surveyActions.postAiRecommendations(
                          id,
                          questionId,
                          refineCategories,
                          discoverRelations,
                          [],
                          "current"
                        )
                      );
                    } else {
                      if (categoryData.length === 0) {
                        swalAlert({
                          icon: "error",
                          title: "Please Add Custom Categories".toString(),
                          showConfirmButton: true,
                        });
                        return 0;
                      }
                      dispatch(
                        surveyActions.postAiRecommendations(
                          id,
                          questionId,
                          refineCategories,
                          discoverRelations,
                          categoryData.map((x) => {
                            return x.word;
                          }),
                          "current"
                        )
                      );
                    }
                  } else {
                    setModalOpen(true);
                  }
                }}
                className={
                  answerData && answerData.answers.length === 0
                    ? cn("customButton rounded-none customButtonDisabled")
                    : cn("customButton rounded-none")
                }
              >
                Get AI Recommended Categories
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GetNewRecommendations;
