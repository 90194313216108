import React, { useEffect, useState, useRef, useMemo } from "react";
import Tooltip from "@mui/material/Tooltip";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Dialogbox from "../../Common/DialogBox";
import CancelIcon from "@mui/icons-material/Cancel";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import CardPreview from "./PreviewCard";
import Backdrop from "../../Common/Backdrop";
import Alert from "../../Common/ConfirmationPrompt";

import { SURVEY_BG_THEME } from "../../Common/Constants";
import { useDispatch, useSelector } from "react-redux";
import { workspaceActions, emailActions } from "../../../actions";
import { workspaceService } from "../../../services/index";
import { Carousel, Radio } from "antd";
import { clone } from "../../Common/Utils";
import "./FormPreview.scss";

const origin = window.location.origin;

const paymentOriginalObject = {
  question: "",
  q_type: "Payment",
  can_be_updated: true,
  surveyId: "",
  visitorId: "",
  settings: {
    layout: 4,
  },
};

const IntroCard = ({ survey, cardRef }) => {
  return (
    <div className="introCard">
      <div className="logoTitle ">
        {survey?.page_logo?.length ? (
          <div className="logo">
            <img src={survey.page_logo} alt="Default Logo" />
          </div>
        ) : null}
        {survey?.page_info?.title?.length ? (
          <div className="title">{survey.page_info.title}</div>
        ) : null}
      </div>
      {survey?.page_info?.intro?.length ? (
        <div className="description">{survey.page_info.intro}</div>
      ) : null}
      <div className="groupBottom">
        <button
          className="btn"
          onClick={() => {
            cardRef && cardRef.current.next();
          }}
        >
          Start Form
        </button>
        {/*<div className="time">
          <AccessTimeFilledIcon /> Takes 45 sec
        </div>*/}
      </div>
    </div>
  );
};
const FinalSubmitCard = ({ survey = {}, onSubmit }) => {
  const { page_info = {} } = survey;
  return (
    <div className="finalSubmitCard">
      <button className="btn submit" onClick={onSubmit}>
        Submit
      </button>
      {!page_info?.repeatFooter && page_info?.footer?.length ? (
        <div className="footerContent">
          <p>{page_info?.footer}</p>
        </div>
      ) : null}
    </div>
  );
};

const Slider = (props) => {
  const {
    quesData = [],
    survey,
    onSubmit,
    showIntroCard,
    showSubmitCard,
    showSubmitBtn,
  } = props;
  const cardRef = useRef();
  const nextEl = document.getElementsByClassName("slick-next");
  const prevEl = document.getElementsByClassName("slick-prev");

  useEffect(() => {
    if (prevEl && prevEl.length) {
      prevEl[0].style.display = "none";
    }
  }, []);

  const beforeChange = (from, to) => {
    const cardsLen =
      cardRef?.current?.innerSlider?.track?.props?.children?.length ||
      quesData.length;
    if (nextEl && nextEl.length) {
      nextEl[0].style.display = to === cardsLen - 1 ? "none" : "block";
    }
    if (prevEl && prevEl.length) {
      prevEl[0].style.display = to === 0 ? "none" : "block";
    }
  };

  return (
    <div className={"slider"}>
      <Carousel
        dots={false}
        loop={false}
        arrows
        ref={cardRef}
        beforeChange={beforeChange}
      >
        {showIntroCard && survey?.page_info?.title?.length ? (
          <IntroCard survey={survey} cardRef={cardRef} />
        ) : null}
        {QuestionCards({ ...props, cardRef, showSubmitCard })}
        {showSubmitCard && showSubmitBtn ? (
          <FinalSubmitCard survey={survey} onSubmit={onSubmit} />
        ) : null}
      </Carousel>
    </div>
  );
};

const QuestionCards = (props) => {
  const {
    quesData = [],
    survey,
    cardRef,
    onChange,
    qRefs,
    sliderView,
    onSubmit,
    showSubmitCard,
    onFocus,
    visitorId,
    surveyId,
    userAddress,
    responseBy,
    showSubmitBtn,
    Cloneddata,
    isPreview,
  } = props;
  const { loading, res = {} } = useSelector(
    (state) => state[isPreview ? "wsDetailsAuth" : "wsDetails"]
  );
  const quesLen = quesData.filter((q) => !q.isHidden);

  let quesIndex = 0;
  let count = 0;
  let counter = 1;
  let newQ = [];
  quesData.length &&
    quesData.map((item) => {
      if (item.is_payment) {
      } else if (item.parent && !item.isHidden) {
        newQ.push({
          ...paymentOriginalObject,
          paymentGroup: item.paymentGroup,
         quesData: res?.quest_desc?.questions_list?.filter(
            (x) => x.is_payment === true && x.paymentGroup===item.paymentGroup
          ),
  
        });
       
      } else {
        newQ.push(item);
      }
    });
   let logicPayment = [];
   newQ.filter((x)=>x.isHidden !== true).forEach((pay)=>{
    if(pay.paymentGroup){
      pay.quesData.forEach((e)=>{
        logicPayment.push(e)
      })
    
    }
   })
  return newQ.map((q, i) => {
    q.visitorId = visitorId;
    q.surveyId = surveyId;
    q.userAddress = userAddress;
    q.responseBy = responseBy;
    const { settings = {}, q_type } = q || {};
    if (!q.isHidden) {
      quesIndex++;
      const submit =
        sliderView && !showSubmitCard && quesIndex === quesLen.length
          ? onSubmit
          : null;
      const btnText =
        sliderView && !showSubmitCard && quesIndex === quesLen.length
          ? "Submit"
          : "Next";
      if (q_type !== "Help" && q_type !== "Decorator") {
        count++;
      }
      return (
        <CardPreview
          key={"sr_" + i}
          {...q}
          layout={settings?.layout}
          isRequired={settings.require}
          quesType={q_type}
          qno={quesIndex}
          srNo={q_type === "Help" || q_type === "Decorator" ? 0 : count}
          cardRef={cardRef}
          onChange={onChange}
          Cloneddata={Cloneddata}
          qRefs={qRefs}
          submit={submit}
          btnText={btnText}
          sliderView={sliderView}
          onFocus={onFocus}
          showSubmitBtn={showSubmitBtn}
          logicPayment={logicPayment}
        />
      );
    }
  });
};

const Body = (props) => {
  const {
    quesData,
    survey = {},
    desktopView,
    sliderView,
    isLoading,
    showSubmitBtn,
    isPaymentTrue,
    paymentres,
    Cloneddata,
    isPreview,
  } = props;
  const { page_logo = "", page_info = {} } = survey;
  const [isSuccess, setSuccess] = useState(false);
  const [group, setGroup] = useState("");
  const { loading, success } = useSelector((state) => state.addData);
  const qRefs = useRef([]);

  useEffect(() => {
    setSuccess(success);
  }, [success]);

  useEffect(() => {
    const g = window.location.href.split("grp=");
    const grpName = g.length > 1 ? g[1] : g[0];
    setGroup(grpName);
  }, []);
  const getTheme = () => {
    const key = desktopView ? "web" : "tablet";
    const thm = SURVEY_BG_THEME[key][survey.survey_bg_theme];
    if (survey.survey_bg_theme.includes("base64")) {
      return survey.survey_bg_theme;
    }
    return origin + "/images/themes/" + thm;
  };
  const themeStyle = survey?.survey_bg_theme?.length
    ? { backgroundImage: `url(${getTheme()})` }
    : {};
  const showHeader =
    survey?.format === "Classic" ||
    (survey?.format === "SingleQuestion" && !page_info?.showIntroOnce);
  const showFooter =
    survey?.format === "Classic" ||
    (survey?.format === "SingleQuestion" && page_info?.repeatFooter);
  return (
    <div
      className={`mainBodyPre ${showSubmitBtn ? "" : "mainFullWidth"} ${
        desktopView ? "desktop" : "mobile"
      } ${
        group === "full_embed" || group === "standard_embed"
          ? "border-0 w-100"
          : ""
      } `}
      style={themeStyle}
    >
      {showHeader &&
      (page_logo?.length ||
        page_info?.title?.length ||
        page_info?.intro?.length) ? (
        <div className="bodyHead">
          <div className="logoTitleTime">
            {page_logo?.length ? (
              <div className="logo">
                <img src={page_logo} alt="Default Logo" />
              </div>
            ) : null}
            {page_info?.title?.length ? (
              <div className="title">{page_info.title}</div>
            ) : null}
            {/*<div className="time">
              <AccessTimeFilledIcon /> Takes 45 sec
            </div>*/}
          </div>
          {page_info?.intro?.length ? (
            <div className="description"> {page_info.intro || ""} </div>
          ) : null}
        </div>
      ) : null}
      <div
        className={`bodyInner ${
          sliderView ? "sliderWrap" : "preview-formWrap"
        }`}
      >
        {sliderView ? (
          <Slider
            {...props}
            showIntroCard={!showHeader}
            showSubmitCard={!showFooter && page_info?.footer?.trim().length}
          />
        ) : (
          <QuestionCards {...props} qRefs={qRefs} />
        )}
      </div>

      {showFooter ? (
        <div className="bodyFooter">
          {sliderView || !showSubmitBtn ? null : (
            <div className="footerBtnWrap">
              <button
                className="btn submit"
                /* onClick={() => onSubmit()} */ onClick={props.onSubmit}
              >
                Submit
              </button>
            </div>
          )}
          {page_info?.footer?.length ? (
            <div className="footerContent">
              {" "}
              <p>{page_info.footer}</p>{" "}
            </div>
          ) : null}
        </div>
      ) : null}

      <Backdrop show={isLoading || loading} />
      {isSuccess && alert("details submitted")}
    </div>
  );
};

const PreviewDialog = (props) => {
  const {
    surveyID,
    show,
    handleClose,
    isPreview,
    onChange,
    onSubmit,
    submitted = false,
    paymentres = "",
    isPaymentTrue,
    inline = false,
    responseBy,
    userAddress,
    setRawData,
    error,
    setError,
    setDemo,
    Cloneddata,
  } = props;
  {
    console.log(props, "PROPSSS<><><><<>");
  }
  const { loading, res = {} } = useSelector(
    (state) => state[isPreview ? "wsDetailsAuth" : "wsDetails"]
  );
  const [visitorId, setVisitorId] = useState("");
  const [trackedIDs, setTrackedID] = useState([]);
  const [apiCalled, setApiCalled] = useState(true);
  const dispatch = useDispatch();
  const [answerData, setAnswerData] = useState({});
  const [data, setData] = useState([]);
  const [linkedData, setLinkData] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [link, setLink] = useState([]);
  const [surveyDetail, setSurveyDetail] = useState({});
  const [desktopView, setDesktopView] = useState(true);
  const [noThankyou, setNoThankyou] = useState(false);

  useEffect(() => {
    //surveyID && dispatch(workspaceActions.getWsDetail(surveyID));
  }, [surveyID, show]);

  useEffect(() => {
    if (paymentres && paymentres?.data?.redirect_url) {
      setNoThankyou(true);
      let url = paymentres?.data?.redirect_url || "";
      window.location.href = url;
    }
  }, [paymentres]);

  useEffect(() => {
    if (submitted) {
      console.log(submitted, "PreviewCard", props);
      createEventsTracker("survey submitted");
      if (responseBy.email.length || responseBy.group.length) {
        dispatch(
          emailActions.updatePersonResponse(
            surveyID,
            responseBy.email.length ? responseBy.email : responseBy.group,
            submitted
          )
        );
      }
    }
  }, [submitted]);

  useEffect(() => {
    if (!loading && res?.id) {
      window["answeredData"] = null;
      const {
        survey_title,
        quest_desc = {},
        survey_bg_theme,
        is_submittable,
        is_demo,
      } = res || {};
      const {
        detail_id,
        format = "",
        page_logo = "",
        page_info = {},
        questions_list = [],
        links = [],
      } = quest_desc || {};
      setSurveyDetail({
        detail_id,
        survey_title,
        page_logo,
        page_info,
        format,
        survey_bg_theme,
      });
      setDemo && setDemo(is_demo);
      setData(questions_list || []);
      // till here
      setQuestionList(questions_list || []);
      setLink(links || []);
      setRawData && setRawData(questions_list || []);
    }
  }, [res]);

  useEffect(() => {
    if (!isPreview && surveyID && apiCalled && !loading && res && res.id) {
      workspaceService
        .getTrackEventsLastVisitorID({ detail_id: surveyID, _limit: 2 })
        .then((response) => {
          if (response.status === 200) {
            let vID = response.data["visitor_id"];
            const newVisitorId = vID + 1;
            newVisitorId != 0 &&
              createEventsTracker("survey started", newVisitorId);
            setApiCalled(false);
            setTimeout(() => setVisitorId(vID), 100);
          }
        });
    }
  }, [surveyID, apiCalled, visitorId, loading, res]);

  useEffect(() => {
    if (link && link.length) {
      pageLogic();
    }
  }, [link, answerData]);

  const matchAnsOption = (opt, ans = []) => {
    const pos = ans.findIndex((f) => opt.includes(f.text));
    return pos > -1;
  };

  const pageLogic = () => {
    let qObj = {};
    let cloned = clone(linkedData.length ? linkedData : data);
     cloned=cloned.map((x,index)=>{
      if(x.is_payment || x.parent){
        x.qus_id = index + 1 
        if(x.question == "Payment"){
          x.question = `${x.question} ${x.paymentGroup}`
        }  
  }
  return x
}).filter((newVal)=> !!newVal)
    cloned.forEach((d) => {
      delete d.isHidden;
      qObj[d.question] = { ...d, ["seq_no"]: d.qus_id };
    });

    const unhideQues = { [link[0].input]: true };
    const sortedLink = link.sort((a, b) => a.data.index - b.data.index);

    for (let i = 0; i < sortedLink.length; i++) {
      const l = sortedLink[i];
 if (
        !qObj[l.input]["isHidden"] &&
        (String(l.data.optArr[0]).toLowerCase() === "next question" ||
          matchAnsOption(l?.data?.optArr, answerData[l.input]))
      ) {
        qObj[l.output]["isHidden"] = false;
        qObj[l.output]["seq_no"] = l.data.index + 1;
        unhideQues[[l.output]] = true;
      } else {
        if (!unhideQues[[l.output]]) {
         qObj[l.output]["isHidden"] = true;
        }
      }
    }
    const arr = Object.values(qObj);
    const sortedArr = arr.sort((a, b) => a.seq_no - b.seq_no);
    setLinkData(arr);
    setData(sortedArr);
    setRawData && setRawData(arr || []);
  };

  const createEventsTracker = (trigger, id) => {
    const params = {
      detail_id: surveyID,
      visitor_id: id || visitorId + 1,
      last_click: trigger,
      user_details: { ...responseBy, address: userAddress },
    };
    workspaceService.createTrackEvents(params).then((res) => {
      if (res.status === 200) {
        trigger !== "survey started" && setTrackedID(trigger);
      }
    });
  };

  const onHandleDesktopView = () => {
      setDesktopView(true);
    },
    onHandleMobileView = () => {
      setDesktopView(false);
    };

  const triggerOnFocus = (index) => {
    const { qus_id, question } = data[index];
    if (!isPreview) {
      if (trackedIDs.indexOf(String(qus_id)) < 0 && qus_id) {
        createEventsTracker(String(qus_id));
      } else {
        console.log("Question id " + qus_id + " already tracked.");
      }
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e;
    const ansData = {
      ...answerData,
      [value.qus_text]: value.answers,
    };
    setAnswerData(ansData);
    window["answeredData"] = ansData;
    onChange && onChange(e);
  };

  const Head = () => {
    return (
      <div
        className={
          !res?.is_submittable ? "headerPre mainFullWidth" : "headerPre"
        }
      >
        <div className="leftSide">
          <div className="arrow1" onClick={handleClose}>
            <img src={origin + "/images/SVG/left-arrow.svg"} alt="arrow icon" />
          </div>
          {/* <div className="logoHead">
                 <img src={origin+"/images/logo.png"} alt="logo icon"/>
                 </div>*/}
        </div>
        <h2>{isPreview ? "Final Preview" : surveyDetail.survey_title}</h2>
        {handleClose ? (
          <div className="cancelIcon" onClick={handleClose}>
            <CancelIcon />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const commonComp = useMemo(() => {
    return (
      <>
        {console.log(paymentres, "paymentrespaymentres")}
        {isPreview ? <Head /> : null}
        {res?.stop && !isPreview ? (
          <ThanksCard stop={true} greeting={false} />
        ) : submitted && !noThankyou ? (
          <ThanksCard />
        ) : (
          <Body
            userAddress={userAddress}
            responseBy={responseBy}
            visitorId={visitorId}
            surveyId={surveyID}
            isLoading={loading}
            isPaymentTrue={isPaymentTrue}
            paymentres={paymentres}
            sliderView={surveyDetail?.format === "SingleQuestion"}
            quesData={data}
            links={link}
            survey={surveyDetail}
            desktopView={desktopView}
            onChange={onInputChange}
            Cloneddata={Cloneddata}
            onSubmit={onSubmit}
            onFocus={triggerOnFocus}
            showSubmitBtn={res?.is_submittable}
            isPreview={isPreview}
          />
        )}
        {isPreview ? (
          <div className="footerPre">
            <div className="btnGroup">
              <div
                className={`btn ${desktopView && "btnDefault"}`}
                onClick={onHandleDesktopView}
              >
                Desktop
              </div>
              <div
                className={`btn ${!desktopView && "btnDefault"}`}
                onClick={onHandleMobileView}
              >
                Mobile
              </div>
            </div>
          </div>
        ) : null}
        <Alert
          show={!!error?.length}
          singleBtn={true}
          btnLabel="Ok"
          message={error}
          handler={() => setError("")}
        />{" "}
      </>
    );
  });

  return inline ? (
    <div className={"previewDialog"}>
      {" "}
      <div className={"previewDialogInner"}> {commonComp}</div>{" "}
    </div>
  ) : (
    <Dialogbox
      open={show}
      //   rootClass={"previewDialog"}
      rootClass={`previewDialog previewThankyou ${
        props.previewFull ? "previewDialogFull" : ""
      }`}
      handleClose={handleClose}
      dialogContentClass={"previewDialogInner"}
    >
      {commonComp}
    </Dialogbox>
  );
};

const ThanksCard = ({ stop = false, greeting = true }) => {
  const { loading, res = {} } = useSelector((state) => state["wsDetails"]);
  return (
    <div className="previewContainer">
      <div className="previewInfo">
        <svg
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="CheckCircleOutlineRoundedIcon"
        >
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.88-11.71L10 14.17l-1.88-1.88a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7c.39-.39.39-1.02 0-1.41-.39-.39-1.03-.39-1.42 0z"></path>
        </svg>
        {greeting ? (
          <h2>
            {res &&
            res.quest_desc &&
            res.quest_desc.page_info &&
            res.quest_desc.page_info.thankYou
              ? res.quest_desc.page_info.thankYou
              : "Thank You"}
          </h2>
        ) : null}
        {stop ? (
          <>
            <p>Thanks for helping us with your feedback.</p>
            <p>At this moment we are not collecting any more response.</p>
          </>
        ) : (
          <p>Form has been submitted.</p>
        )}
      </div>
    </div>
  );
};

const Preview = (props) => {
  const { surveyID, inline = false } = props;
  const [show, setDialog] = useState(false);

  const handleClose = () => {
      setDialog(false);
    },
    onHandleOpen = () => {
      setDialog(!show);
    };
  return (
    <>
      <Tooltip title="Preview">
        <RemoveRedEyeIcon onClick={onHandleOpen} />
      </Tooltip>
      <PreviewDialog
        show={show}
        handleClose={handleClose}
        surveyID={surveyID}
        isPreview={true}
        inline={inline}
      />
    </>
  );
};

export { PreviewDialog, Preview };
