import React, { useState, useEffect } from "react";
import { Button,Typography } from "@mui/material";
import DataTable from "../../../components/Tables/DataTable";
//import CreateManagerForm from "./CreateManagerForm";
import ManagerDelegationPopup from "./ManagerDelegationPopup"; // Import the new popup component
import { AdminService } from "../../../services/AdminService";
import ManagerForm from "./ManagerForm";

const ManagerTable = () => {
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelegate, setOpenDelegate] = useState(false); // State to control delegation popup
  const [selectedManager, setSelectedManager] = useState(null);
  const [managers, setManagers] = useState(null);

  //const columns = ["Manager_Name", "Team", "Delegation_status", "Actions"];
  const columns = ["username", "email", "role_name", "status", "date_created", "Team", "Territory", "Actions"];


  const rows = [
    {
      manager_id: "1",
      Manager_Name: "John Doe",
      Team: "Sales",
      Delegation_status: "None"
    },
    {
      manager_id: "2",
      Manager_Name: "Jane Smith",
      Team: "Marketing",
      Delegation_status: "None"
    },
    {
      manager_id: "3",
      Manager_Name: "Mike Johnson",
      Team: "Engineering",
      Delegation_status: "None"
    },
  ];

  const fetchManagers = async () => {
    try {
      const response = await AdminService.getUsers();
      const filteredManagers = response?.data?.filter(user => user.user_role === 3);
      setManagers(filteredManagers || []);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchManagers(); 
  }, []);

  const enhancedRows = managers?.map((row) => ({
    ...row,
    Actions: (
      <>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            setSelectedManager(row);
            setOpenCreate(true);
          }}
          style={{ marginRight: "10px" }}
        >
          Edit
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => alert(`Delete ${row.Manager_Name}`)}
          style={{ marginRight: "10px" }}
        >
          Delete
        </Button>
        {/* <Button
          variant="contained"
          size="small"
          sx={{
            backgroundColor: "#1595a3",
            color: "white",
            "&:hover": {
              backgroundColor: "#4fbdc1",
            },
          }}
          onClick={() => {
            setSelectedManager(row);
            setOpenDelegate(true);
          }}
        >
          Delegate
        </Button> */}
      </>
    ),
  }));

  return (
    <div>
      {openCreate && (
        <ManagerForm
        manager={selectedManager}
          onCancel={() => {
            setOpenCreate(false);
            setSelectedManager(null);
          }}
          refreshManagers={fetchManagers}
        />
      )}
      <Typography variant="h5" gutterBottom>
        Manager Details
      </Typography>

      <ManagerDelegationPopup
        open={openDelegate}
        onClose={() => setOpenDelegate(false)}
        manager={selectedManager}
      />

      <DataTable columns={columns} rows={enhancedRows} tableType="Manager" />
    </div>
  );
};

export default ManagerTable;
