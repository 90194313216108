import { pageLogicConstants } from "../constants";
import { pageLogicServices } from "../services";
import {workspaceActions} from "./index"
import { swalAlert } from "../voc/Common/SwalAlert";

const request = (message) => ({ type: pageLogicConstants.REQUEST, message });

const success = (message) => ({ type: pageLogicConstants.SUCCESS, message });

const errors = (message) => ({ type: pageLogicConstants.ERROR, message });

const clear = () => ({ type: pageLogicConstants.CLEAR });

const getPageLogicData = (surveyId) => {
  return (dispatch) => {
    dispatch(request());

    pageLogicServices.getPageLogic(surveyId).then(
      (alerts) => {
        dispatch(success(alerts));
      },
      (error) => {
        dispatch(errors(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const updatePageLogicData = (obj) => {
  const {detail_id} = obj
  return (dispatch) => {
    dispatch(request());

    pageLogicServices.updatePageLogic(obj).then(
      (alerts) => {
        detail_id && dispatch(workspaceActions.getWsDetailWithAuth(detail_id))
        window.location.reload()
        // dispatch(success(alerts));
        // dispatch(getPageLogicData());
      },
      (error) => {
        dispatch(errors(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

export const pageLogicActions = {
  getPageLogicData,
  updatePageLogicData,
};
