import React, { useRef, useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import RefreshIcon from "@mui/icons-material/Refresh";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import GridViewIcon from "@mui/icons-material/GridView";
import ListIcon from "@mui/icons-material/List";

import ListView from "./ListView";
import GridView from "./GridView";

import { useDispatch, useSelector } from "react-redux";
import {workspaceActions } from '../../actions';
import { getUser, clone, setLocalStorage } from "../Common/Utils";
import { Dropdown, RenameDialog, ContactIcon } from "./Component";
import ConfirmationDialog from "../Common/ConfirmationPrompt";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Tooltip from "@mui/material/Tooltip";
import { useOutsideAlerter } from "../Common/Header";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import WidgetsRoundedIcon from "@mui/icons-material/WidgetsRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Settings from "../UserSettings/Settings";
import { LINKS } from "../Common/Constants";
import { history } from "../../helpers";
import DialPad from "../../components/Dialer/DialerModal";
import { Button, Menu, MenuItem } from "@mui/material";
import { useHistory } from "react-router-dom";
import { setDialog } from "../../actions/dialogActions";
import DialogBox from "../Common/DialogBox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {useMediaQuery} from "@mui/material";

const default_ws = "My Workspace";

const Filters = ({
  ws_name,
  onShowListView,
  onShowGridView,
  listView,
  selectedWorkspace,
  setWsName,
  filterActive,
  backdrop,
}) => {
  const filterMap = {
    select: "Select",
    completion: "Completion",
    question: "Question",
    response: "Response",
    date_created: "Date created",
    last_updated: "Last updated",
    alphabetical: "Alphabetical",
  };
  const { team_id, user_id } = getUser();
  const dispatch = useDispatch();
  const [filter1, setFilter1] = useState(filterMap.select);
  const [filter2, setFilter2] = useState(filterMap.select);
  const [openRename, setRenameDialog] = useState("");
  const [err, setErr] = useState("");
  const [open, setOpen] = useState({}),
    onDropHandle = (e, drop, isFilter1) => {
      setOpen({});
      setOpen({ [drop]: !open[drop] });
    };
  const wrapperRef = useRef(null);
  const [showDialog, setShowDialog] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [surveyName, setSurveyName] = useState("");
  const [dialogType, setDialogType] = useState("");
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState({ survey: null, form: null });

  useOutsideAlerter(wrapperRef, setOpen);

  const onSelectFilter = (filterBy, isFilter2) => {
    if (isFilter2) {
      setFilter2(filterMap[filterBy]);
      setFilter1(filterMap.select);
    } else {
      setFilter1(filterMap[filterBy]);
      setFilter2(filterMap.select);
    }
    dispatch(workspaceActions.getAllWSDetails(selectedWorkspace, filterBy));
    setOpen({});
  };
  const kebabHandler = (pos, action) => {
    switch (action) {
      case "Rename":
        setRenameDialog(ws_name);
        break;
    }
  };
  const renameHandler = (e) => {
    setRenameDialog("");
    if (e && e !== "") {
      const param = {
        id: selectedWorkspace,
        name: e,
        status: "Active",
      };
      // dispatch(workspaceActions.updateWorkspace(param, user_id)).then(res=>{
      //     if(res){
      //         setWsName(e);
      //     }
      // });
    } else {
      setErr("");
    }
  };

  // Handle menu opening
  const handleMenuOpen = (event, type) => {
    setAnchorEl((prev) => ({ ...prev, [type]: event.currentTarget }));
  };

  // Handle menu closing
  const handleMenuClose = (type) => {
    setAnchorEl((prev) => ({ ...prev, [type]: null }));
  };

  // Handle redirection based on selection
  // const handleSelection = (type, path) => {
  //   history.push(`/${path}`); // Redirects to the corresponding route
  //   handleMenuClose(type); // Close the dropdown
  // }


  // const handleSelection = (type, path) => {
  //   if (path === "scratch") {
  //     setShowDialog(true); 
  //   } else if (path === "form"){
  //     history.push(`/${path}`);
  //   }
  //   handleMenuClose(type);
  // };
  
  // // Handle dialog OK button click
  // const handleOk = () => {
  //   if (surveyName.trim()) {
  //     localStorage.setItem("surveyName", surveyName); // Store in local storage
  //     history.push("/scratch"); // Redirect to /scratch
  //     setShowDialog(false); // Close the dialog
  //   }
  // };

  const handleSelection = (type, path) => {
    if (path === "scratch" || path === "scratchForm") {
      setDialogType(type); // Set dialog type (survey or form)
      setShowDialog(true);
    } else {
      history.push(`/${path}`);
    }
    handleMenuClose(type);
  };

  const handleOk = () => {
    if (inputValue.trim()) {
      const storageKey = dialogType === "survey" ? "surveyName" : "formName";
      const redirectPath = dialogType === "survey" ? "/scratch" : "/scratchForm";

      localStorage.setItem(storageKey, inputValue);
      history.push(redirectPath);
      setShowDialog(false);
      setInputValue(""); // Reset input field
    }
  };
  const isMobile = useMediaQuery("(max-width:600px)");

 
  return (
    <div className="filterPage" ref={filterActive}>
      <div className="menuClose">
        <button
          onClick={() => {
            document.body.style.overflow = "unset";
            filterActive.current.classList.remove("filter-active");
            backdrop.current.classList.remove("active");
          }}
        >
          <CloseRoundedIcon />
        </button>
      </div>
      <div className="leftFilter">
        <button
          className="btn"
          style={{ cursor: "default", pointerEvents: "none" }}
        >
          {ws_name}
        </button>
        <div className="More" style={{ cursor: "pointer" }}>
          {ws_name === "My Workspace" ? (
            []
          ) : (
            <Dropdown menus={["Rename"]} handleKebab={kebabHandler} />
          )}
        </div>
        <RenameDialog
          open={openRename !== ""}
          title="Rename your workspace"
          name="Workspace"
          value={openRename}
          wsHandler={renameHandler}
        />
      </div>
      
      {/* Buttons with Dropdown Menus */}
      <div className="buttonGroup" style={{ display: "flex", gap: "15px", marginLeft: "10px", marginBottom: isMobile ? "15px" : "0px" }}> 
        
        {/* Survey Button */}
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleMenuOpen(e, "survey")}
            endIcon={<ExpandMoreIcon />}
          >
            Create Survey
          </Button>
          <Menu
            anchorEl={anchorEl.survey}
            open={Boolean(anchorEl.survey)}
            onClose={() => handleMenuClose("survey")}
          >
            <MenuItem onClick={() => handleSelection("survey", "scratch")}>
              Create from Scratch
            </MenuItem>
            <MenuItem onClick={() => handleSelection("survey", "template")}>
              Create from Template
            </MenuItem>
          </Menu>
        </div>

        {/* Form Button */}
        <div>
          <Button
            variant="contained"
            color="secondary"
            onClick={(e) => handleMenuOpen(e, "form")}
            endIcon={<ExpandMoreIcon />}
          >
            Create Form
          </Button>
          <Menu
            anchorEl={anchorEl.form}
            open={Boolean(anchorEl.form)}
            onClose={() => handleMenuClose("form")}
          >
            <MenuItem onClick={() => handleSelection("form", "scratchForm")}>
              Create from Scratch
            </MenuItem>
            <MenuItem onClick={() => handleSelection("form", "template")}>
              Create from Template
            </MenuItem>
          </Menu>
        </div>

      </div>

      <Dialog open={showDialog} onClose={() => setShowDialog(false)}         
      sx={{
          "& .MuiDialog-paper": {
            width: "500px", // Adjust width
            height: "200px", // Adjust height
            padding: "20px", // Add some padding for better spacing
            overflowY: "hidden"
          },
        }}>
        <DialogTitle>{dialogType === "survey" ? "Enter Survey Name" : "Enter Form Name"}</DialogTitle>
        <DialogContent sx={ {overflowY: "hidden"}}>
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={dialogType === "survey" ? "Enter Survey Name" : "Enter Form Name"}
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleOk} variant="contained" color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <div className="rightFilter">
        <div className="dropdownGroup">
          <div className="dropDownWrap">
            <button onClick={(e) => onDropHandle(e, "completion")}>
              {filter1} <ExpandMoreIcon />{" "}
            </button>
            {open.completion && (
              <ul className={"drop"} ref={wrapperRef}>
                <li onClick={() => onSelectFilter("completion")}>
                  <div className="emptyDiv"></div>
                  {filterMap.completion}
                </li>
                <li onClick={() => onSelectFilter("question")}>
                  <div className="emptyDiv"></div>
                  {filterMap.question}
                </li>
                <li onClick={() => onSelectFilter("response")}>
                  <div className="emptyDiv"></div>
                  {filterMap.response}
                </li>
              </ul>
            )}
          </div>
          <div className="dropDownWrap">
            <button onClick={(e) => onDropHandle(e, "date")}>
              {filter2} <ExpandMoreIcon />{" "}
            </button>
            {open.date && (
              <ul className={"drop"} ref={wrapperRef}>
                <li onClick={() => onSelectFilter("date_created", true)}>
                  <div className="emptyDiv"></div>
                  {filterMap.date_created}
                </li>
                <li onClick={() => onSelectFilter("last_updated", true)}>
                  <div className="emptyDiv"></div>
                  {filterMap.last_updated}
                </li>
                <li onClick={() => onSelectFilter("alphabetical", true)}>
                  <div className="emptyDiv"></div>
                  {filterMap.alphabetical}
                </li>
              </ul>
            )}
          </div>
        </div>

        <div className="gridListWrap">
          <button
            className={`btnGrid ${!listView && "active"}`}
            onClick={onShowGridView}
          >
            <GridViewIcon /> Grid
          </button>
          <button
            className={`btnList ${listView && "active"}`}
            onClick={onShowListView}
          >
            <ListIcon /> List
          </button>
        </div>
      </div>
    </div>
  );
};

const TemplateGalleryMenu = ({
  workspaceList,
  setLoader,
  setWsName,
  setWsForHeader,
  selectedWorkspace,
  panelActive,
  backdrop,
  wsLoading,
  setLoading,
}) => {
  const { team_id, user_id } = getUser();
  const [workspaces, setWorkspaces] = React.useState([]);
  const [openFor, setOpen] = React.useState("");
  const [err, setErr] = useState("");
  const [selectedWS, selectWS] = React.useState(0);
  const [openConfirmPrompt, SetConfirmPrompt] = React.useState(false);
  const [deletedWSid, setdeletedWSid] = React.useState(0);
  const dispatch = useDispatch();
  const searchingRef = React.useRef();

  React.useEffect(() => {
    const clonedList = clone(workspaceList);
    setWorkspaces(clonedList);
  }, [workspaceList]);

  React.useEffect(() => {
    if (selectedWS > -1 && workspaces.length) {
      let w = workspaces[selectedWS];
      //setWsName(w.name === "default" ? default_ws : w.name);
      selectWS(w.name);
    }
    // setLocalStorage("workspaceID", w.id);
  }, [selectedWS]);

  const wsHandler = (e) => {
    setOpen("");
    if (e && e !== "") {
      let api = "",
        param = {};
      if (openFor === "new") {
        api = "createWorkspace";
        param = {
          name: e,
          team_id: team_id,
          status: "Active",
          experience_type: "default_experience",
          user_id: user_id,
        };
      } else {
        api = "updateWorkspace";
        param = {
          id: deletedWSid || workspaces[selectedWS].id,
          name: e,
          status: "Active",
        };
      }
      setLoading(true);
      api !== "" && dispatch(workspaceActions[api](param, user_id)).then(resp=>{
          setLoading(false);
          if(resp && openFor !== "new"){
               setWsName(e);
          }
      });
    } else {
      setErr("");
    }
  };

  const onSelectWorkspace = (ws, index, searching = false) => {
    if (selectedWorkspace != ws.id || searching) {
      selectWS(index);
      dispatch(workspaceActions.getAllWSDetails(ws.id));
      setWsForHeader(ws.id);
      setWsName(ws.name);
      setWsName(ws.name === "default" ? default_ws : ws.name);
    }
    setLocalStorage("workspaceID", ws.id);
  };

  const kebabHandler = (pos, action) => {
    let cloned = clone(workspaces);
    setdeletedWSid(cloned[pos].id);
    switch (action) {
      case "Rename":
        {
          setOpen(workspaces[pos].name);
        }
        break;
      case "Delete":
        {
          if (pos > -1) {
            SetConfirmPrompt(true);
          }
        }
        break;
      case "Duplicate":
        {
          setLoading(true);
          // dispatch(workspaceActions.duplicateWorkspace(workspaces[pos].id, user_id));
        }
        break;
      case "Move Up":
        {
          if (pos > 1) {
            const currentSurvey = cloned[pos];
            let obj = cloned[pos - 1];
            cloned[pos - 1] = cloned[pos];
            cloned[pos] = obj;
            let i = pos - 1;
            setWorkspaces(cloned);
            selectWS(i);
            // dispatch(workspaceActions.setWsPriority({workspace_id:currentSurvey.id, new_priority:obj.priority}, user_id))
          }
        }
        break;
      case "Move Down":
        {
          if (pos < cloned.length - 1) {
            const currentSurvey = cloned[pos];

            let obj = cloned[pos];
            cloned[pos] = cloned[pos + 1];
            cloned[pos + 1] = obj;
            let i = pos + 1;
            setWorkspaces(cloned);
            selectWS(i);
            // dispatch(workspaceActions.setWsPriority({workspace_id:currentSurvey.id, new_priority:cloned[pos].priority}, user_id))
          }
        }
        break;
    }
  };

  const searchWorkspace = (e) => {
    e && e.stopPropagation();
    const keyword = searchingRef?.current?.value || "";
    const list = workspaceList.filter((w) => {
      /*if(w.name === "default"){
                return default_ws.toLowerCase().includes(keyword.toLowerCase());
            }*/
      return w.name.toLowerCase().includes(keyword.toLowerCase());
    });
    onSelectWorkspace(list.length ? list[0] : { id: -1 }, 0, true);
    setWorkspaces(list);
  };

  const promptHandler = (e) => {
    SetConfirmPrompt(false);
    if (e) {
      setLoader(true);
       dispatch(workspaceActions.deleteWorkspace(deletedWSid, user_id)).then(result=>{
           if(result){
               setWsName(workspaceList[0].name);
               setWsForHeader(workspaceList[0].id);
           }
       });
    }
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      searchWorkspace(e);
    }
  };

  const WorkspaceList = () => {
    return workspaces.map((w, i) => {
      const isDefault = w["is_default"]; //w.name === 'default';
      const kebabMenus = isDefault
        ? ["Duplicate"]
        : ["Rename", "Duplicate", "Move Up", "Move Down", "Delete"];
      i == 1 && kebabMenus.splice(2, 1);
      i == 1 && i == workspaces.length - 1 && kebabMenus.splice(2, 1);
      i == workspaces.length - 1 && kebabMenus.splice(3, 1);
      return (
        <li
          key={"pvt-" + i}
          className={w.id === selectedWorkspace ? "active" : ""}
          onClick={() => onSelectWorkspace(w, i)}
        >
          <p>
            <Tooltip title={w.name}>
              <span style={{ cursor: "pointer" }}>{w.name}</span>
            </Tooltip>
            <em>{w.survey_count || 0}</em>
          </p>
          {w.id === selectedWorkspace ? (
            <div className="more">
              <Dropdown
                name="workspace"
                pos={i}
                menus={kebabMenus}
                handleKebab={kebabHandler}
              />
            </div>
          ) : null}
        </li>
      );
    });
  };
  const newWorkspaceTitle = openFor === "new" ? "" : openFor;

  return (
    <div
      className="templateGalleryMenu DashboardSidebarViews"
      ref={panelActive}
    >
      <div className="menuClose">
        <button
          onClick={() => {
            document.body.style.overflow = "unset";
            panelActive.current.classList.remove("panel-active");
            backdrop.current.classList.remove("active");
          }}
        >
          <CloseRoundedIcon />
        </button>
      </div>
      <div className="searchWrap"> 
        <input
          ref={searchingRef}
          type="text"
          placeholder={"Find workspace & Articence"}
          onKeyPress={onKeyPress}
        />
        <div className="searchIcon" onClick={searchWorkspace}>
          <SearchIcon />
        </div>
      </div>
      <div className="listing">
        <div className="privateArea">
        <div className="privateHead">
                        <div className="drop"><KeyboardArrowDownIcon/></div>
                        <h2>Private</h2>
                        <Tooltip title="Add New"><div className="add" onClick={()=>setOpen("new")}><ControlPointIcon/></div></Tooltip>
                    </div>
                    <div className="privateBody">
                        <ul>
                            {wsLoading ? null : workspaces.length ?<WorkspaceList />:
                                <div style={{textAlign:'center'}}><span>No data found!</span></div>}
                        </ul>
                    </div>
                    <div className="profileAccount">
                        {/*<h2><strong>User Name</strong>account</h2>
                        <h4>Response collected</h4>*/}
                        <div className="response" style={{marginTop:20}}>
                            <button className="btn" style={{border: '1px solid #ccc'}}>Notification</button>
                            {/*<div className="slider"><div className="value" style={{width:"20%"}}></div></div>
                            <p>20/100<em>Reset on 9 july</em></p>*/}
                        </div>
                    </div>
          <div className="helpArea">
            <div
              className="help"
              onClick={() => window.open(LINKS.help_main_dashboard, "_blank")}
            >
              <HelpOutlineIcon />
              Help
              <div className="arrow">
                <KeyboardArrowRightIcon />
              </div>
            </div>
            <div
              className="contactUs"
              onClick={() =>
                window.open(LINKS.contact_main_dashboard, "_blank")
              }
            >
              <ContactIcon />
              Contact
              <div className="arrow arrowContact">
                <KeyboardArrowRightIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
      {openFor.length > 0 ? (
        <RenameDialog
          title={openFor !== "new" ? "Rename your workspace" : undefined}
          open={openFor !== ""}
          name="workspace"
          value={newWorkspaceTitle}
          wsHandler={wsHandler}
        />
      ) : null}
      <ConfirmationDialog show={openConfirmPrompt} handler={promptHandler} />
    </div>
  );
};

const DashboardViews = (props) => {
  const [listView, setListView] = React.useState(false);
  const [ws_name, setWsName] = React.useState(default_ws);

  const onShowListView = () => {
    setListView(true);
  };
  const onShowGridView = () => {
    setListView(false);
  };

  const dashboardPanel = useRef();
  const dashboardFilter = useRef();
  const backdropOverlay = useRef();

  return (
    <>
      <div className="mainPageBody">
        <div
          className="backdrop-overlay"
          ref={backdropOverlay}
          onClick={() => {
            document.body.style.overflow = "unset";
            dashboardPanel.current.classList.remove("panel-active");
            dashboardFilter.current.classList.remove("filter-active");
            backdropOverlay.current.classList.remove("active");
          }}
        ></div>

        <div className="dashboardPanel">
          <button
            className="panel-btn"
            onClick={() => {
              document.body.style.overflow = "hidden";
              dashboardPanel.current.classList.add("panel-active");
              backdropOverlay.current.classList.add("active");
            }}
          >
            <WidgetsRoundedIcon />
          </button>
        </div>

        <TemplateGalleryMenu
          {...props}
          setWsName={setWsName}
          panelActive={dashboardPanel}
          backdrop={backdropOverlay}
        />
        {props.showUserSetting ? (
          <Settings settingsTab={props.settingsTab} />
        ) : (
          <div className="mainPageBodyScroller">
            <Filters
              {...props}
              ws_name={ws_name}
              setWsName={setWsName}
              onShowListView={onShowListView}
              onShowGridView={onShowGridView}
              listView={listView}
              filterActive={dashboardFilter}
              backdrop={backdropOverlay}
            />
            {!listView ? <GridView {...props} /> : <ListView {...props} />}
          </div>
        )}

        {props.showUserSetting ? null : (
          <div className="dashboardFilter">
            <button
              className="filter-btn"
              onClick={() => {
                document.body.style.overflow = "hidden";
                dashboardFilter.current.classList.add("filter-active");
                backdropOverlay.current.classList.add("active");
              }}
            >
              <FilterListRoundedIcon />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardViews;
export { TemplateGalleryMenu };
