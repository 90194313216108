import React, {useState,useEffect, useRef, useMemo, memo} from 'react';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import StarIcon from '@mui/icons-material/Star';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';

import { Rate } from 'rsuite';
import ThumbsUp from '@rsuite/icons/legacy/ThumbsUp';
import MehIcon from '@rsuite/icons/legacy/MehO';
import HeartIcon from '@rsuite/icons/legacy/Heart';
import {clone, getUser} from '../../Common/Utils';
import Prompt from "../../Common/ConfirmationPrompt";

const origin = window.location.origin;
const SENTIMENTS = [
    'Negative',
    'Positive',
    'Neutral',
    'Extremely Negative',
    'Extremely Positive',
    'Profanity',
];

const RatingIconByType = ({type, color, max}) => {
    const [count, setCount] = useState(Number(max));
    useEffect(()=>{
        setCount(Number(max));
    }, [max]);
        switch (type){
            case 'Smily':
                return <Rate max={count} readOnly={true} defaultValue={count} size="xs" character={<MehIcon />} style={{color}}/>;
            case 'Heart':
                return <Rate max={count} readOnly={true} defaultValue={count} size="xs" character={<HeartIcon />} style={{color}}/>;
            case 'Thumsup':
                return <Rate max={count} readOnly={true} defaultValue={count} size="xs" character={<ThumbsUp/>} style={{color}}/>;
            default:  return <Rate max={count} readOnly={true} defaultValue={count} size="xs" style={{color}} />;
        }
};


const AlertOption = ({data, quesType, onChange, rightMenuActive, onSave}) =>{
    const [alert, setAlert] = useState({});
    const [stmt, setStmt] = useState({});
    const [notify, setNotify] = useState([]);
    const [choice, setChoice] = useState([]);
    const [show, setShow] = useState({});
    const [hasPhone, setPhone] = useState(false);
    const [err, setErr] = useState('');

    useEffect(()=> {
        reset();
        const s = ['LongText', 'ShortText'], ot = ['Email', 'Yes/No', 'Contact', 'FileUpload'];
        const isSentiment = s.indexOf(quesType) > -1;
        const isOtherType = ot.indexOf(quesType) > -1;
        setShow({other: isOtherType, stmt: isSentiment});
    }, [quesType]);

    useEffect(()=>{
        const {alertOptions = {} } = data;
        setAlert(alertOptions);
    }, [data]);

    useEffect(()=>{
        const {mobile, alert_mobile} = getUser() || {};
        if((mobile && mobile.length) || (alert_mobile && alert_mobile.length)){
            setPhone(true);
        }
    }, []);

    const reset = () => {
        setChoice([]);
        setNotify([]);
        setStmt([]);
    };

    const onOptionSelect = (e) => {
        const cloned = show.stmt ? clone(stmt): clone(choice);
        const idx = cloned.indexOf(e);
        idx > -1 ? cloned.splice(idx, 1) : cloned.push(e);
        show.stmt ? setStmt(cloned): setChoice(cloned);
    };

    const addAlert = (type="answerChoice") => {
        const keys = show.stmt ? clone(stmt): clone(choice);
        const values = clone(notify);

        let result = keys.reduce(function (result, field, index) {
            result[field] = values;
            return result;
        }, {});

        const clonedAlert = clone(alert);
        clonedAlert[type] = Object.assign(clonedAlert[type]||{}, result||{});
        onChange && onChange({name:'alertOptions', value: clonedAlert});
        reset();
    };

    const deleteAlert = (key) => {
        const cloned = clone(alert),
        name = show.stmt ? 'sentiments' : 'answerChoice';
        delete cloned[name][key];
        onChange && onChange({name:'alertOptions', value: cloned});
    };

    const Sentiments = () => {
        return <div>
            <h3>Sentiments</h3>
            <div className="items setAlert">
                <h5 onClick={()=>addAlert('sentiments')} style={{cursor:'pointer',display:"inline-block"}}><ControlPointIcon/> Set Alerts</h5>
                <div className="itemsInner">
                    {SENTIMENTS.map((k,i) => {
                        const checked = stmt.indexOf(k) > -1 ? <div className="cancelIcon"><CheckCircle/></div> : null;
                        return <div key={'sentiment-' + i} className="item" onClick={() => {onOptionSelect(k)}}> {k} {checked}</div>
                    })}
                </div>

            </div>
        </div>
    };

    const AnswerChoices = () => {
        const choiceByType = () => {
            let items = [], comp = null, options = [];
            const {settings={}, ans_options=[], dropdownList="", if_pictures_type=[]} = data||{};

           if(quesType === 'Rating'){
               const {type, color, value=5} = settings.rating || {};
                options = Array.from({length: value}, (_, i) => i + 1);
                comp = (i) => (<RatingIconByType key={"rt_"+i} type={type} color={color} max={i}/>);
           } else if(quesType === 'DropDown'){
                const list = dropdownList.length ? dropdownList.split('\n') : [];
                options = list;
               comp = (i) => (<div>{i}</div>);
           } else {
               const len = quesType === 'Picture' ? if_pictures_type.length : ans_options.length ;
               options = Array.from({length: len}, (_, i) => String.fromCharCode(65+i));
               comp = (i) => (<div style={{fontSize: 16}}>{i}</div>);
            }

           if(comp){
               for (let i = 0; i<options.length; i++){
                   const v = options[i];
                   const checked = choice.indexOf(v) > -1 ? <div className="cancelIcon"><CheckCircle/></div> : null;
                   const o =  <div key={'ch_'+i}className="item" onClick={()=>{onOptionSelect(v)}}> {comp(v)} {checked}</div>;
                   items.push(o);
               }
           }
           return items;
        };

        return <div>
            <h3>Answer Choices</h3>
            <div className="items setAlert">
                <h5 onClick={()=>addAlert('answerChoice')} style={{cursor:'pointer', display:"inline-block"}}><ControlPointIcon/> Set Alerts</h5>
                <div className="itemsInner">
                    {choiceByType()}
                </div>

            </div>
        </div>
    };

    const onNotify = (e) => {
        if(e && e === 'txtMsg' && !hasPhone){
            setErr("Mobile number is required to send the text message, Set alert mobile number in the Profile Setting.");
            return ;
        }
        const cloned = clone(notify);
        const idx = cloned.indexOf(e);
        idx > -1 ? cloned.splice(idx, 1) : cloned.push(e);
        setNotify(cloned);
    };

    const Notify = useMemo( () => {
        const getStyle = (t) => (notify.indexOf(t) > -1 ? { border: '3px solid #18A0FB'}: {});
        return <div className="items notifyAlertGroup">
            <div className="groupInner">
                <h5><img src={origin+"/images/SVG/notify.svg"} alt="Notify"/>Notify me</h5>
                <div className="itemsInner">
                    <div className="item" style={getStyle('txtMsg')} onClick={()=>onNotify('txtMsg')}><img src={origin+"/images/SVG/whatup.svg"} alt="whatup"/> </div>
                    <div className="item" style={getStyle('gmail')} onClick={()=>onNotify('gmail')}><img src={origin+"/images/SVG/gmail.svg"} alt="gmail"/></div>
                </div>
            </div>
            <div className="groupInner">
                <h5><img src={origin+"/images/SVG/alert.svg"} alt="Alert"/>Show alert</h5>
                <div className="itemsInner">
                    <div className="item" style={getStyle('showAlert')} onClick={()=>onNotify('showAlert')}><img src={origin+"/images/SVG/tv.svg"} alt="tv"/></div>
                </div>
            </div>
        </div>
    }, [notify]);

    const CurrentAlerts = useMemo(() => {

        const selectedAlert = () => {
            const {answerChoice, sentiments} = alert;
            const record = show.stmt ? sentiments : answerChoice;
            if(record){
                return Object.keys(record).map(key => {
                    const notificationIcons = record[key]||[];
                    let val = key;
                    if(!show.stmt && quesType === 'Rating'){
                        const {settings={}} = data||{};
                        const {type, color} = settings.rating || {};
                        val = <RatingIconByType key={"rtg_"+key} type={type} color={color} max={key}/>;
                    }
                    return <div key={"sop-"+key} className="item">{val}
                        <div className="Alert">
                            {notificationIcons.includes('txtMsg') && <div className="alertItem"><img src={origin+"/images/SVG/whatup.svg"} alt="whatUp"/></div>}
                            {notificationIcons.includes('gmail') && <div className="alertItem"><img src={origin+"/images/SVG/gmail.svg"} alt="gmail"/></div>}
                            {notificationIcons.includes('showAlert') && <div className="alertItem"><img src={origin+"/images/SVG/tv.svg"} alt="tv"/></div>}
                        </div>
                        <div className="cancelIcon" onClick={()=> deleteAlert(key)}><CancelIcon/></div>
                    </div>
                });

            }
            return [];
        };

        return <div className="items currentAlert">
            <h5><img src={origin+"/images/SVG/currentAlert.svg"} alt="currentAlert"/>Current Alerts</h5>
            <div className="itemsInner">
                {selectedAlert()}
            </div>

        </div>
    }, [alert]);

    const ChooseOtherOpt = () => {
        return <div className="optionInner" style={{height: 300}}>
            <div style={{position: 'absolute', bottom: '50%', left: 15}}>
                <h3>Choose another question type</h3>
            </div>
        </div>
    };
    const enbaleSave = Object.keys(alert)?.length; 
    return(
        <div className={"formatWrapper optionSetting"} ref={rightMenuActive}>
            <div className="head">Options
                <div className='surveyMenuCloseBtn'>
                    <button onClick={()=>rightMenuActive.current.classList.toggle('menu-active')}>
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_2441_8773)">
                            <circle cx="9.5" cy="8.5" r="7.5" fill="#18A0FB"/>
                            </g>
                            <g clipPath="url(#clip0_2441_8773)">
                            <path d="M13.6253 3.91797H5.37533C5.25377 3.91797 5.13719 3.96626 5.05123 4.05221C4.96528 4.13817 4.91699 4.25474 4.91699 4.3763V12.6263C4.91699 12.7479 4.96528 12.8644 5.05123 12.9504C5.13719 13.0363 5.25377 13.0846 5.37533 13.0846H13.6253C13.7469 13.0846 13.8635 13.0363 13.9494 12.9504C14.0354 12.8644 14.0837 12.7479 14.0837 12.6263V4.3763C14.0837 4.25474 14.0354 4.13817 13.9494 4.05221C13.8635 3.96626 13.7469 3.91797 13.6253 3.91797ZM10.417 12.168H5.83366V4.83464H10.417V12.168ZM13.167 12.168H11.3337V4.83464H13.167V12.168Z" fill="white"/>
                            </g>
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.44009 9.27037C8.51997 9.18815 8.51997 9.05486 8.44009 8.97264L7.49382 7.9987L8.44009 7.02476C8.51997 6.94254 8.51997 6.80924 8.44009 6.72703C8.36021 6.64481 8.2307 6.64481 8.15082 6.72703L7.05991 7.84983C6.98003 7.93205 6.98003 8.06535 7.05991 8.14756L8.15082 9.27037C8.2307 9.35259 8.36021 9.35259 8.44009 9.27037Z" fill="white" stroke="white" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <defs>
                            <filter id="filter0_d_2441_8773" x="0" y="0" width="19" height="19" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="1"/>
                            <feGaussianBlur stdDeviation="1"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2441_8773"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2441_8773" result="shape"/>
                            </filter>
                            <clipPath id="clip0_2441_8773">
                            <rect width="11" height="11" fill="white" transform="translate(4 3)"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </button>
                </div>
            </div>
            {
                show.other ? <ChooseOtherOpt/> :
                    <div className="optionInner">
                            { show.stmt ? <Sentiments /> : <AnswerChoices />}
                            {Notify}
                            {CurrentAlerts}
                            <div style={{padding: '10px', float: 'right'}}>
                                <button className={`btnSave ${enbaleSave?'':'disabled'}`} onClick={onSave}>Save</button>
                            </div>
                    </div>
            }
            <Prompt show={!!err?.length} message={err} singleBtn={true} btnLabel="OK" handler={()=> setErr("")}/>
        </div>
    )
};

export default AlertOption;
