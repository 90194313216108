import React, {useEffect} from 'react';
import CodeIcon from '@mui/icons-material/Code';
import ShareLinkPage from '../SendEmail/ShareLinkPage';
import {useDispatch} from "react-redux";
import {emailActions} from "../../../actions";

const origin = window.location.origin;

const SocialLink = ({onEmbedClickView, surveyID, survey_url}) =>{
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(emailActions.getAudience(surveyID))
    }, [surveyID]);
    return(
        <div className={`sendEmailBody`}>
            <div className="head">
                <div className="icon"><img src={origin+"/images/SVG/brokenLink.svg"} alt="link"/></div>
                Share survey link
            </div>

            <div className="sendEmailInner">
                <ShareLinkPage surveyID={surveyID} survey_url={survey_url} />
                <div className="socialLinkPage embedViewWrap">
                    <div className="link">
                        <CodeIcon/>
                    </div>
                    <h1>Embed in a web page</h1>
                   <div className="embedView">
                       <div className="item active" onClick={()=>onEmbedClickView("standard")}>
                           <img src={origin+"/images/SVG/standered.svg"} alt="link"/>
                           <h3>Standard</h3>
                           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                       </div>
                       <div className="item"  onClick={()=>onEmbedClickView("full")}>
                           <img src={origin+"/images/SVG/full.svg"} alt="link"/>
                           <h3>Full</h3>
                           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                       </div>
                       <div className="item"  onClick={()=>onEmbedClickView("slider")}>
                           <img src={origin+"/images/SVG/slider.svg"} alt="link"/>
                           <h3>Slider</h3>
                           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                       </div>
                       <div className="item"  onClick={()=>onEmbedClickView("sliderTop")}>
                           <img src={origin+"/images/SVG/sliderTop.svg"} alt="link"/>
                           <h3>Slider Top</h3>
                           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                       </div>
                   </div>

                </div>
            </div>
        </div>
    )
}

export default SocialLink;
