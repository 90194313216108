import React, {useEffect, useState, useRef} from "react";
import { PreviewDialog } from './Preview/Preview';
import { useDispatch, useSelector } from 'react-redux';
import { workspaceActions } from '../../actions/index';
import { workspaceService, emailService } from '../../services/index';
import {URL_VAL} from '../Common/Constants'
import {unscrambleEmail, cookieAccepted} from '../Common/Utils';
import CookieBanner from "../Common/CookieBanner";
import AlertPopup from "../Common/ConfirmationPrompt";

const info_data = {
    "address": {},
    "userDetails": {
        "group": ""
    }
}

const dummyAddress = {
    "country_code": "",
    "country_name": "",
    "city": "",
    "postal": "",
    "latitude": 0,
    "longitude": 0,
    "IPv4": "0.0.0.0",
    "state": "",
    "state_code": "",
    "flag": "",
    "emoji_flag": "",
    "emoji_unicode": ""
};

const SurveyAnswer = (props) => {
    const [surveyID, setSurveyID] = useState();
    const [data, setData] = useState({});
    const [rawData, setRawData] = useState([]);
    const [info, setInfo] = useState(info_data);
    const [respBy, setRespBy] = useState({email:"", group:""});
    const [userAddress, setUserAddress] = useState({});
    const [basicAddress, setBasicAddress] = useState(dummyAddress);
    const [pageNotFound, setPageNotFound] = useState(false);
    const [err, setError] = useState("");
    const [validation, setValidation] = useState("");
    const {loading, success} = useSelector(state => state.addData);
    const [showPopup, setPopup] = useState(false);
    const [isDemo, setDemo] = useState("");
    const [isCookiesEnable, setCookiesState] = useState(false);
    const { wsloading, res = {} } = useSelector(
        (state) => state["wsDetails"]
      );
    const dispatch = useDispatch();

    useEffect(() => {
        let s_id = 0;
        let grpName = '';
        let hasGrp = false;
        let em ="";
        if(props?.match?.params){
            const {id, personal} = props.match.params;
            if(id && id.includes(URL_VAL) && id.split('&')[1] === URL_VAL ){
                const u = id.split('&'+URL_VAL);
                s_id = u.length ? parseInt(u[0], 10) : parseInt(id, 10);
            }else if (id){
                setPageNotFound(true);
                return;
            }

            if(id && personal){
                s_id && emailService.getAudience(s_id).then(res=> {
                    if(res && res.data && res.data.audience){
                        const audi = res.data.audience;
                        let matched = false;
                        for(let i=0; i<audi.length; i++){
                            const splitLink = audi[i].personal_link.split(id+'/');
                            if(splitLink[splitLink.length-1] === personal){
                                matched = true;
                            }
                        }
                        if(!matched && !props.ignoreUrlChk){
                            setPageNotFound(true);
                            return;
                        }
                    }
                });
                hasGrp = personal.includes('grp=');
                if(hasGrp){
                    const g = personal.split('grp=');
                    grpName = g.length > 1 ? g[1]:g[0];
                }else{
                    const e = unscrambleEmail(personal);
                    em = e && e.length ? e : "";
                }
            }
        }
        setRespBy(res=>({email:em, group: grpName}));
        setSurveyID(s_id);
        setInfo(info => ({...info, userDetails: {...info.userDetails, group: grpName, Email: em}}));
    }, [props.match.params]);

    useEffect(()=>{
        workspaceService.getUserAddress().then(res=>{
            if(res && res.country_name !== ""){
                const address = {"country_code": res.country_code,
                    "country_name": res.country_name,
                    "city": res.city,
                    "postal": res.postal,
                    "latitude": res.latitude,
                    "longitude": res.longitude,
                    "IPv4": res.ip,
                    "state": res.region,
                    "state_code": res.region_code,
                    "flag": res.flag,
                    "emoji_flag": res.emoji_flag,
                    "emoji_unicode": res.emoji_unicode};
                setUserAddress(address);
                setBasicAddress({
                    "country_code": res.country_code,
                    "country_name": res.country_name,
                    "flag": res.flag,
                    "emoji_flag": res.emoji_flag,
                    "emoji_unicode": res.emoji_unicode
                  });
                surveyID && dispatch(workspaceActions.getWsDetail(surveyID));
            }
        });
    }, [surveyID]);

    useEffect(()=>{
        if(!wsloading && res){
            setCookiesState(res?.cookies);
        }
      }, [wsloading, res]);

    const onChange = (e) => {
        const {name, value, error=""} = e;
        if(error && error.length){
            setValidation(error);
            return;
        }
        if(value && ['Contact','Email'].indexOf(value.q_type)>-1){
            setInfo(info => ({...info, userDetails: {...info.userDetails, [value.q_type]: value.answers[0].text}}));
        }
            setData(data => ({...data, [name]:value}))
    };

    const checkEmptyAns = (item) => {
        let isEmpty = true;
        if(item && item.answers && item.answers.length){
            item.answers.forEach(a=> {
                let str = a.hasOwnProperty('text') ? String(a.text) : "";
                if(item.q_type === 'Picture' && str === "" && a.hasOwnProperty('base64')){
                    str = a.base64.length ? "base64Image" : "";
                }
                if(str.trim() != ""){
                    isEmpty = false;
                }
            })
        }
        return isEmpty;
    };

    const onSubmit = (e) => {
        const {isTimeExpire = false} = e || {};
        if(props.ignoreUrlChk){
            return;
        }
        if(validation && validation.length){
            setError(validation);
            setValidation("");
            return;
        }
        const answer_data = Object.values(data);
        let noEmptyRequiredQues = true;
        if(!isTimeExpire){
            const requiredQues = rawData.filter(r=> r.settings.require && !r.isHidden);
            requiredQues.forEach(rq=>{
                let i=0;
                for(i; i<answer_data.length; i++){
                    const ans = answer_data[i];
                    if(rq.qus_id === ans.qus_id && !checkEmptyAns(ans) ){
                        break;
                    }
                }
                if(i === answer_data.length){
                    setError("Answer the all required questions!");
                    noEmptyRequiredQues = false;
                }
            });
        }
        if(noEmptyRequiredQues){
          const add = (isCookiesEnable && cookieAccepted()) ? userAddress : basicAddress;
          const finalData = {detail_id: surveyID,  answer_data, info_data:{...info, address:add}};
          if(isDemo){
            setPopup(true);
            return;
          }
          dispatch(workspaceActions.addData(finalData));
          reset();
        }
    };

    const reset = () => {
        setData({});
    };

    const PageNotFound = () => {
        return <div style={{ textAlign: "center" }}>
            <div>
                <span style={{ fontSize: "6em" }}>Page Not Found</span>
                <p style={{ fontSize: "2em" }}>Invalid Url: Check shared survey url</p>
            </div>
        </div>
    }

    return pageNotFound ? <PageNotFound/>
        : <>
        {isCookiesEnable ? <CookieBanner /> : null}
        <PreviewDialog surveyID={surveyID} show={true} error={err} setError={setError} setRawData={setRawData} onChange={onChange} onSubmit={onSubmit} submitted={success} responseBy={respBy} inline={props.inline} userAddress={userAddress} className="surveyAnswerPreview" setDemo={setDemo} previewFull/>
        <AlertPopup show={showPopup} message="You can't answer a demo Survey." singleBtn={true} btnLabel="OK" handler={()=> setPopup(false)}/>
        </>
};

export default SurveyAnswer;
