import api from "./api";

async function addDelegation(addDelegationData, userId) {
  const result = await api.post(`/api/delegate/user/${userId}`, addDelegationData);
  return result;
}

async function editDelegation(addDelegationData, delegationId) {
  const result = await api.put(`/editaiagent/${delegationId}`, addDelegationData);
  return result;
}

async function deleteDelegation(delegationId, userId) {
  const result = await api.delete(`/api/delegate/${delegationId}/user/${userId}`);
  return result;
}


async function getDelegationByUserId(userId) {
  const result = await api.get(`/api/delegate/user/${userId}`);
  return result;
}

async function getDelegationById(delegationId) {
  const result = await api.get(`/api/delegate/user/${delegationId}`);
  return result;
}


export const DelegationService = {
  addDelegation,
  getDelegationByUserId,
  getDelegationById,
  editDelegation,
  deleteDelegation
}