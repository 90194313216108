import cn from "classnames";

import "react-circular-progressbar/dist/styles.css";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import Highlighter from "react-highlight-words";
import ReactPaginate from "react-paginate";
import "./pagination.css";
import { swalAlert } from "../../../../Common/SwalAlert";

import { useSelector, useDispatch } from "react-redux";
import { surveyActions } from "../../../../../actions";
import { useParams } from "react-router";
const AiRecommendations = ({ questionId }) => {
  const [showAnswer, setShowAnswer] = useState(false);
  const [addCustomCategory, setAddCustomCategory] = useState(false);
  const [customCategoryIndex, setCustomCategoryIndex] = useState(null);
  const [showHighlightedAnswer, setShowHighlightedAnswer] = useState(true);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  const [activeIndex, setActiveIndex] = useState([]);
  const { id } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const aiRecommendationData = useSelector(
    (state) => state.survey.aiRecommendationData
  );
  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setPageNumber(selectedPage + 1);
  };
  const [aiRecommended, setAiRecommended] = useState([]);

  const [categoryText, setCategoryText] = useState("");

  useEffect(() => {
    dispatch(
      surveyActions.getAiRecommendations(id, questionId, "", pageNumber)
    );
  }, [questionId, pageNumber]);
  const searchNow = () => {
    setPageNumber(1);
    dispatch(surveyActions.getAiRecommendations(id, questionId, searchText, 1));
  };
  const addHighestRecommended = () => {
    const temp = [...aiRecommended];
    const filtered = temp.map((item, indx) => {
      if (
        item.categories.findIndex(
          (x) => x.word === item.recommendations[0].word
        ) === -1
      ) {
        item.categories = [
          ...item.categories,
          {
            word: item.recommendations[0].word,
          },
        ];
      }
      return item;
    });
    swalAlert({
      icon: "success",
      title: "Most Recommended Categories are Added".toString(),
      showConfirmButton: true,
    });
    setAiRecommended(filtered);
  };

  useEffect(() => {
    if (
      aiRecommendationData !== null &&
      aiRecommendationData.message &&
      aiRecommendationData.message.length > 0
    ) {
      setAiRecommended(aiRecommendationData.message);
    } else {
      setAiRecommended([]);
    }
  }, [aiRecommendationData]);

  const updateCategories = () => {
    const temp = [...aiRecommended];
    temp.forEach((e, i) => {
      e.Question = undefined;
      e.detail_id = undefined;
      e.outlier = undefined;
      e.tldr = undefined;
      e.qus_id = undefined;
      e.key = i;
      e.answer = e.answers;
      e.answers = undefined;
      e.assignedCategory = undefined;
    });
    const updatedObj = {
      params: {
        catGroups: temp,
        detail_id: id,
        qus_id: questionId,
        redo: null,
      },
    };

    dispatch(surveyActions.updateCategory(updatedObj));
  };
  return (
    <>
      {" "}
      <div className="tabData-Wrap">
        <div className="headBox">
          <div className="title">
            {" "}
            {aiRecommended &&
              aiRecommended.length > 0 &&
              aiRecommended[0].Question}
          </div>
          <div className="item">
            <button className="setting-btn">
              <span className="setting-title">
                <img
                  onClick={() => {
                    addHighestRecommended();
                  }}
                  style={{
                    cursor: "pointer",
                    width: "25px",
                    height: "25px",
                  }}
                  src={window.location.origin + "/images/AddNew.png"}
                />
                <span
                  style={{
                    cursor: "pointer",
                    color: "#8A94A6",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    addHighestRecommended();
                  }}
                >
                  High Recommended
                </span>
              </span>
            </button>
          </div>
        </div>
        <div className="contentbox">
          <div className="searchBar rightbox mb-3">
            <input
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              type="text"
              placeholder={"Search"}
            />
            <div onClick={searchNow} className="searchBtn">
              <SearchIcon />
            </div>
          </div>
          <table
            style={{ border: "1px solid #dee2e6" }}
            className="table table-borderless"
          >
            <thead className="thead-blue">
              <tr style={{ background: "rgba(24, 160, 251, 0.2)" }}>
                <th scope="col">Show AI</th>
                <th scope="col">Number Of Answers</th>
                <th scope="col">% Of Answers</th>
                <th scope="col">Recommended Categories</th>
                <th scope="col">Add Custom Category</th>
                <th scope="col">Assigned Category</th>
              </tr>
            </thead>
            <tbody>
              {aiRecommended && aiRecommended && aiRecommended.length > 0
                ? aiRecommended.map((value, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>
                            <div
                              onClick={() => {
                                setShowAnswer(!showAnswer);
                                const newArr = [...activeIndex];
                                newArr.push(index);
                                setActiveIndex(newArr);
                              }}
                              className="seeAnswers"
                            >
                              See Answers
                            </div>
                          </td>
                          <td>{value.answers && value.answers.length}</td>

                          <td>
                            {value.answers &&
                              aiRecommendationData &&
                              aiRecommendationData.total_recommended_answers &&
                              value.answers.length &&
                              (
                                (value.answers.length /
                                  aiRecommendationData.total_recommended_answers) *
                                100
                              ).toFixed(2)}
                            %
                          </td>
                          <td>
                            {value.recommendations
                              ? value.recommendations.map((value, i) => {
                                  return (
                                    <div
                                      key={i}
                                      onClick={() => {
                                        const temp = [...aiRecommended];

                                        const idx = temp[
                                          index
                                        ].categories.findIndex(
                                          (x) => x.word === value.word
                                        );
                                        if (idx === -1) {
                                          temp[index].categories = [
                                            ...temp[index].categories,
                                            { word: value.word },
                                          ];
                                          dispatch(
                                            surveyActions.setShowPrompt(true)
                                          );
                                        }
                                        setAiRecommended(temp);
                                      }}
                                      style={{ cursor: "pointer" }}
                                      className={cn("button-item-c", "mb-2")}
                                    >
                                      <span className={cn("customButton")}>
                                        {value.word}
                                      </span>
                                    </div>
                                  );
                                })
                              : null}
                          </td>
                          <td>
                            {customCategoryIndex !== index ? (
                              <>
                                <img
                                  onClick={() => {
                                    setCategoryText("");
                                    setAddCustomCategory(true);
                                    setCustomCategoryIndex(index);
                                  }}
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                  }}
                                  src={
                                    window.location.origin +
                                    "/images/AddNew.png"
                                  }
                                ></img>
                                <span
                                  onClick={() => {
                                    setCategoryText("");
                                    setAddCustomCategory(true);
                                    setCustomCategoryIndex(index);
                                  }}
                                  style={{ cursor: "pointer" }}
                                  className="ml-1"
                                >
                                  {" "}
                                  More Category
                                </span>
                              </>
                            ) : null}
                            {addCustomCategory &&
                            customCategoryIndex === index ? (
                              <div className="times-fieldbox">
                                <input
                                  value={categoryText}
                                  onChange={(e) => {
                                    setCategoryText(e.target.value);
                                  }}
                                  type="text"
                                  placeholder=""
                                />
                                <button
                                  onClick={() => {
                                    if (categoryText.length === 0) {
                                      swalAlert({
                                        icon: "error",
                                        title: "Please Add Category".toString(),
                                        showConfirmButton: true,
                                      });
                                      return 0;
                                    }
                                    const temp = [...aiRecommended];

                                    const idx = temp[
                                      index
                                    ].categories.findIndex(
                                      (x) => x.word === categoryText
                                    );
                                    if (idx === -1) {
                                      temp[index].categories = [
                                        ...temp[index].categories,
                                        { word: categoryText },
                                      ];
                                      dispatch(
                                        surveyActions.setShowPrompt(true)
                                      );
                                    }
                                    setAiRecommended(temp);
                                    setCustomCategoryIndex(null);
                                  }}
                                  className="btn"
                                >
                                  <svg
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.0002 1.83398C9.18717 1.83398 7.41489 2.3716 5.90744 3.37885C4.39999 4.38609 3.22507 5.81773 2.53127 7.49272C1.83747 9.16771 1.65594 11.0108 2.00964 12.789C2.36333 14.5671 3.23637 16.2005 4.51835 17.4825C5.80034 18.7644 7.43368 19.6375 9.21184 19.9912C10.99 20.3449 12.8331 20.1634 14.5081 19.4695C16.1831 18.7757 17.6147 17.6008 18.622 16.0934C19.6292 14.5859 20.1668 12.8136 20.1668 11.0007C20.1668 9.79687 19.9297 8.60487 19.4691 7.49272C19.0084 6.38057 18.3332 5.37004 17.482 4.51884C16.6308 3.66764 15.6202 2.99242 14.5081 2.53176C13.3959 2.07109 12.204 1.83398 11.0002 1.83398ZM11.0002 18.334C9.54977 18.334 8.13195 17.9039 6.92598 17.0981C5.72002 16.2923 4.78009 15.147 4.22505 13.807C3.67001 12.467 3.52478 10.9925 3.80774 9.56999C4.0907 8.14746 4.78913 6.84079 5.81472 5.8152C6.8403 4.78962 8.14698 4.09118 9.5695 3.80823C10.992 3.52527 12.4665 3.67049 13.8065 4.22553C15.1465 4.78058 16.2918 5.72051 17.0976 6.92647C17.9034 8.13243 18.3335 9.55025 18.3335 11.0007C18.3335 12.9456 17.5609 14.8108 16.1856 16.1861C14.8103 17.5614 12.9451 18.334 11.0002 18.334ZM14.6668 10.084H11.9168V7.33398C11.9168 7.09087 11.8203 6.85771 11.6483 6.6858C11.4764 6.51389 11.2433 6.41732 11.0002 6.41732C10.7571 6.41732 10.5239 6.51389 10.352 6.6858C10.1801 6.85771 10.0835 7.09087 10.0835 7.33398V10.084H7.3335C7.09038 10.084 6.85723 10.1806 6.68532 10.3525C6.51341 10.5244 6.41683 10.7575 6.41683 11.0007C6.41683 11.2438 6.51341 11.4769 6.68532 11.6488C6.85723 11.8207 7.09038 11.9173 7.3335 11.9173H10.0835V14.6673C10.0835 14.9104 10.1801 15.1436 10.352 15.3155C10.5239 15.4874 10.7571 15.584 11.0002 15.584C11.2433 15.584 11.4764 15.4874 11.6483 15.3155C11.8203 15.1436 11.9168 14.9104 11.9168 14.6673V11.9173H14.6668C14.9099 11.9173 15.1431 11.8207 15.315 11.6488C15.4869 11.4769 15.5835 11.2438 15.5835 11.0007C15.5835 10.7575 15.4869 10.5244 15.315 10.3525C15.1431 10.1806 14.9099 10.084 14.6668 10.084Z"
                                      fill="white"
                                    />
                                  </svg>
                                </button>
                              </div>
                            ) : null}
                          </td>
                          <td>
                            {" "}
                            {value.categories && value.categories.length > 0
                              ? value.categories.map((value, i) => {
                                  return (
                                    <div
                                      className={cn("darkButton", "mb-2")}
                                      key={i}
                                    >
                                      <span>{value.word}</span>
                                      <button
                                        onClick={() => {
                                          const temp = [...aiRecommended];
                                          const filtered = temp.map(
                                            (item, indx) => {
                                              if (indx === index) {
                                                item.categories.splice(i, 1);
                                              }
                                              return item;
                                            }
                                          );
                                          dispatch(
                                            surveyActions.setShowPrompt(true)
                                          );

                                          setAiRecommended(filtered);
                                        }}
                                        className="memoryclose-btn"
                                      >
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 13 13"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g filter="url(#filter0_d_1831_5873)">
                                            <circle
                                              cx="6.5"
                                              cy="4.5"
                                              r="3.5"
                                              fill="white"
                                            />
                                          </g>
                                          <path
                                            d="M6.81485 4.50026L7.77799 3.53855C7.82017 3.49634 7.84387 3.43909 7.84387 3.37939C7.84387 3.31969 7.82017 3.26244 7.77799 3.22022C7.73582 3.17801 7.67861 3.1543 7.61896 3.1543C7.55931 3.1543 7.50211 3.17801 7.45993 3.22022L6.49903 4.18417L5.53812 3.22022C5.49595 3.17801 5.43874 3.1543 5.37909 3.1543C5.31945 3.1543 5.26224 3.17801 5.22006 3.22022C5.17789 3.26244 5.15419 3.31969 5.15419 3.37939C5.15419 3.43909 5.17789 3.49634 5.22006 3.53855L6.18321 4.50026L5.22006 5.46197C5.19907 5.48281 5.18241 5.5076 5.17103 5.53492C5.15966 5.56224 5.15381 5.59154 5.15381 5.62113C5.15381 5.65072 5.15966 5.68003 5.17103 5.70734C5.18241 5.73466 5.19907 5.75945 5.22006 5.78029C5.24089 5.80131 5.26566 5.81798 5.29295 5.82936C5.32025 5.84075 5.34953 5.8466 5.37909 5.8466C5.40866 5.8466 5.43794 5.84075 5.46523 5.82936C5.49253 5.81798 5.5173 5.80131 5.53812 5.78029L6.49903 4.81635L7.45993 5.78029C7.48075 5.80131 7.50553 5.81798 7.53282 5.82936C7.56012 5.84075 7.58939 5.8466 7.61896 5.8466C7.64853 5.8466 7.67781 5.84075 7.7051 5.82936C7.7324 5.81798 7.75717 5.80131 7.77799 5.78029C7.79899 5.75945 7.81565 5.73466 7.82702 5.70734C7.83839 5.68003 7.84425 5.65072 7.84425 5.62113C7.84425 5.59154 7.83839 5.56224 7.82702 5.53492C7.81565 5.5076 7.79899 5.48281 7.77799 5.46197L6.81485 4.50026Z"
                                            fill="#181818"
                                          />
                                          <defs>
                                            <filter
                                              id="filter0_d_1831_5873"
                                              x="0"
                                              y="0"
                                              width="13"
                                              height="13"
                                              filterUnits="userSpaceOnUse"
                                              colorInterpolationFilters="sRGB"
                                            >
                                              <feFlood
                                                floodOpacity="0"
                                                result="BackgroundImageFix"
                                              />
                                              <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                              />
                                              <feOffset dy="2" />
                                              <feGaussianBlur stdDeviation="1.5" />
                                              <feComposite
                                                in2="hardAlpha"
                                                operator="out"
                                              />
                                              <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                              />
                                              <feBlend
                                                mode="normal"
                                                in2="BackgroundImageFix"
                                                result="effect1_dropShadow_1831_5873"
                                              />
                                              <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="effect1_dropShadow_1831_5873"
                                                result="shape"
                                              />
                                            </filter>
                                          </defs>
                                        </svg>
                                      </button>
                                    </div>
                                  );
                                })
                              : null}
                            {/* <div className={cn("darkButton", "mb-2")}>
                                <span>Times</span>
                                <button className="memoryclose-btn">
                                  <svg
                                    width="13"
                                    height="13"
                                    viewBox="0 0 13 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g filter="url(#filter0_d_1831_5873)">
                                      <circle
                                        cx="6.5"
                                        cy="4.5"
                                        r="3.5"
                                        fill="white"
                                      />
                                    </g>
                                    <path
                                      d="M6.81485 4.50026L7.77799 3.53855C7.82017 3.49634 7.84387 3.43909 7.84387 3.37939C7.84387 3.31969 7.82017 3.26244 7.77799 3.22022C7.73582 3.17801 7.67861 3.1543 7.61896 3.1543C7.55931 3.1543 7.50211 3.17801 7.45993 3.22022L6.49903 4.18417L5.53812 3.22022C5.49595 3.17801 5.43874 3.1543 5.37909 3.1543C5.31945 3.1543 5.26224 3.17801 5.22006 3.22022C5.17789 3.26244 5.15419 3.31969 5.15419 3.37939C5.15419 3.43909 5.17789 3.49634 5.22006 3.53855L6.18321 4.50026L5.22006 5.46197C5.19907 5.48281 5.18241 5.5076 5.17103 5.53492C5.15966 5.56224 5.15381 5.59154 5.15381 5.62113C5.15381 5.65072 5.15966 5.68003 5.17103 5.70734C5.18241 5.73466 5.19907 5.75945 5.22006 5.78029C5.24089 5.80131 5.26566 5.81798 5.29295 5.82936C5.32025 5.84075 5.34953 5.8466 5.37909 5.8466C5.40866 5.8466 5.43794 5.84075 5.46523 5.82936C5.49253 5.81798 5.5173 5.80131 5.53812 5.78029L6.49903 4.81635L7.45993 5.78029C7.48075 5.80131 7.50553 5.81798 7.53282 5.82936C7.56012 5.84075 7.58939 5.8466 7.61896 5.8466C7.64853 5.8466 7.67781 5.84075 7.7051 5.82936C7.7324 5.81798 7.75717 5.80131 7.77799 5.78029C7.79899 5.75945 7.81565 5.73466 7.82702 5.70734C7.83839 5.68003 7.84425 5.65072 7.84425 5.62113C7.84425 5.59154 7.83839 5.56224 7.82702 5.53492C7.81565 5.5076 7.79899 5.48281 7.77799 5.46197L6.81485 4.50026Z"
                                      fill="#181818"
                                    />
                                    <defs>
                                      <filter
                                        id="filter0_d_1831_5873"
                                        x="0"
                                        y="0"
                                        width="13"
                                        height="13"
                                        filterUnits="userSpaceOnUse"
                                        color-interpolation-filters="sRGB"
                                      >
                                        <feFlood
                                          flood-opacity="0"
                                          result="BackgroundImageFix"
                                        />
                                        <feColorMatrix
                                          in="SourceAlpha"
                                          type="matrix"
                                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                          result="hardAlpha"
                                        />
                                        <feOffset dy="2" />
                                        <feGaussianBlur stdDeviation="1.5" />
                                        <feComposite
                                          in2="hardAlpha"
                                          operator="out"
                                        />
                                        <feColorMatrix
                                          type="matrix"
                                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                        />
                                        <feBlend
                                          mode="normal"
                                          in2="BackgroundImageFix"
                                          result="effect1_dropShadow_1831_5873"
                                        />
                                        <feBlend
                                          mode="normal"
                                          in="SourceGraphic"
                                          in2="effect1_dropShadow_1831_5873"
                                          result="shape"
                                        />
                                      </filter>
                                    </defs>
                                  </svg>
                                </button>
                              </div> */}
                          </td>
                        </tr>
                        {/* {showAnswer ? (
                            <>
                              <thead
                                style={{
                                  border: "1px solid #dee2e6",
                                }}
                                className="table table-borderless ml-5"
                              >
                                <tr
                                  style={{
                                    background: "rgba(24, 160, 251, 0.2)",
                                  }}
                                >
                                  <th scope="col" style={{ width: "90%" }}>
                                    <span className="tableHeadingStyles">
                                      Show Answers
                                    </span>
                                  </th>
                                  <th scope="col">
                                    <svg
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setShowHighlightedAnswer(
                                          !showHighlightedAnswer
                                        );
                                      }}
                                      width="56"
                                      height="56"
                                      viewBox="0 0 56 56"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g filter="url(#filter0_d_1831_5876)">
                                        <g filter="url(#filter1_d_1831_5876)">
                                          <circle
                                            cx="28"
                                            cy="26"
                                            r="8"
                                            fill="white"
                                          />
                                        </g>
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M23.1826 24.7332C23.4749 24.4492 23.9489 24.4492 24.2412 24.7332L27.7041 28.0977L31.167 24.7332C31.4593 24.4492 31.9333 24.4492 32.2256 24.7332C32.5179 25.0172 32.5179 25.4777 32.2256 25.7617L28.2334 29.6405C27.9411 29.9245 27.4671 29.9245 27.1748 29.6405L23.1826 25.7617C22.8903 25.4777 22.8903 25.0172 23.1826 24.7332Z"
                                          fill="#18A0FB"
                                        />
                                      </g>
                                      <defs>
                                        <filter
                                          id="filter0_d_1831_5876"
                                          x="0"
                                          y="0"
                                          width="56"
                                          height="56"
                                          filterUnits="userSpaceOnUse"
                                          color-interpolation-filters="sRGB"
                                        >
                                          <feFlood
                                            flood-opacity="0"
                                            result="BackgroundImageFix"
                                          />
                                          <feColorMatrix
                                            in="SourceAlpha"
                                            type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"
                                          />
                                          <feOffset dy="2" />
                                          <feGaussianBlur stdDeviation="10" />
                                          <feComposite
                                            in2="hardAlpha"
                                            operator="out"
                                          />
                                          <feColorMatrix
                                            type="matrix"
                                            values="0 0 0 0 0.0903993 0 0 0 0 0.353273 0 0 0 0 0.529167 0 0 0 0.1 0"
                                          />
                                          <feBlend
                                            mode="normal"
                                            in2="BackgroundImageFix"
                                            result="effect1_dropShadow_1831_5876"
                                          />
                                          <feBlend
                                            mode="normal"
                                            in="SourceGraphic"
                                            in2="effect1_dropShadow_1831_5876"
                                            result="shape"
                                          />
                                        </filter>
                                        <filter
                                          id="filter1_d_1831_5876"
                                          x="17"
                                          y="17"
                                          width="22"
                                          height="22"
                                          filterUnits="userSpaceOnUse"
                                          color-interpolation-filters="sRGB"
                                        >
                                          <feFlood
                                            flood-opacity="0"
                                            result="BackgroundImageFix"
                                          />
                                          <feColorMatrix
                                            in="SourceAlpha"
                                            type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"
                                          />
                                          <feOffset dy="2" />
                                          <feGaussianBlur stdDeviation="1.5" />
                                          <feComposite
                                            in2="hardAlpha"
                                            operator="out"
                                          />
                                          <feColorMatrix
                                            type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                          />
                                          <feBlend
                                            mode="normal"
                                            in2="BackgroundImageFix"
                                            result="effect1_dropShadow_1831_5876"
                                          />
                                          <feBlend
                                            mode="normal"
                                            in="SourceGraphic"
                                            in2="effect1_dropShadow_1831_5876"
                                            result="shape"
                                          />
                                        </filter>
                                      </defs>
                                    </svg>
                                  </th>
                                </tr>
                                <tr></tr>
                              </thead>
                              {showHighlightedAnswer ? (
                                <tr>
                                  <td>
                                    {value.answers
                                      ? value.answers.map((value, index) => {
                                          return (
                                            <div className="mt-4">
                                              {" "}
                                              <Highlighter
                                                highlightClassName="YourHighlightClass"
                                                searchWords={value.new_bold}
                                                autoEscape={true}
                                                textToHighlight={value.sentence}
                                              />
                                            </div>
                                          );
                                        })
                                      : null}
                                  </td>
                                </tr>
                              ) : null}
                            </>
                          ) : null} */}

                        {activeIndex?.indexOf(index) !== -1 ? (
                          <>
                            <tr
                              style={{
                                background: "rgba(24, 160, 251, 0.2)",
                              }}
                              className="showAnswerRow"
                            >
                              <td>
                                <span className="tableHeadingStyles">
                                  Show Answers
                                </span>
                              </td>
                              <td valign="middle">
                                <div
                                  style={{
                                    textAlign: "left",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingTop: "2px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    onClick={() => {
                                      let newArr = [...activeIndex];
                                      const arr = newArr.filter(
                                        (item) => item !== index
                                      );

                                      setActiveIndex(arr);
                                    }}
                                    style={{
                                      width: "22px",
                                    }}
                                    src={
                                      window.location.origin +
                                      "/images/downarrow-small.png"
                                    }
                                  />
                                </div>
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            {showHighlightedAnswer ? (
                              <tr className="showAnswerRowItems">
                                <td
                                  colSpan="6"
                                  style={{ width: "100%", lineHeight: "1.6" }}
                                >
                                  {value.answers
                                    ? value.answers.map((value, index) => {
                                        return (
                                          <p className="tableAnswerItems">
                                            {" "}
                                            <b>{index + 1}.&nbsp;&nbsp;</b>
                                            <Highlighter
                                              highlightClassName="YourHighlightClass"
                                              searchWords={value.new_bold}
                                              autoEscape={true}
                                              textToHighlight={value.sentence}
                                            />
                                          </p>
                                        );
                                      })
                                    : null}
                                </td>
                              </tr>
                            ) : null}
                          </>
                        ) : null}
                      </>
                    );
                  })
                : null}
            </tbody>
          </table>

          <div className="table-footer">
            <div className="pagination-wrapper">
              {aiRecommendationData && aiRecommendationData.total_records ? (
                <ReactPaginate
                  forcePage={pageNumber - 1}
                  breakLabel="..."
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={Math.ceil(aiRecommendationData?.total_records / 9)}
                  previousLabel="< previous"
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  subContainerClassName={"page-item"}
                  activeClassName={"active"}
                />
              ) : null}
            </div>
            <div
              onClick={() => {
                updateCategories();
              }}
              className="upgradeBtn"
            >
              <span
                className={
                  aiRecommended && aiRecommended.length === 0
                    ? "btn lightblue customButtonDisabled"
                    : "btn lightblue"
                }
              >
                Save
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AiRecommendations;
