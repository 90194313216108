import React, { useState, useEffect} from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Checkbox from '@mui/material/Checkbox';
import { lightGreen, green } from '@mui/material/colors';
import { CommonWrapper, Wrapper, StartSurvey } from '../Common/CommonWrapper';
import { MaterialInput, MaterialSelect } from "../../common/controls/inputs/Material";
import Label from '../../common/controls/inputs/Label';
import {clone, getWorkspaceID, getLocalStorage} from '../Common/Utils';
import { history } from '../../helpers';
import Backdrop from '../Common/Backdrop'
import '../Survey/surveyStyle.css';

import { useDispatch, useSelector } from 'react-redux';
import { workspaceActions, dashboardActions } from '../../actions';
import Prompt from '../Common/ConfirmationPrompt';
import EmbedPage from "../Survey/shareLink/EmbedPage";
import CodeIcon from "@mui/icons-material/Code";

const origin = window.location.origin;
const ScratchForm = ({surveyName, wsID, surveyData}) => {
    const [data, setData] = useState({survey_category:""});
    const [check, setCheck] = useState({});
    const [hasNonAnonType, setNonAnonType] = useState(false);
    const [mounted, setMount] = useState(false);
    const [hasExp, setExpStatus] = useState(false);
    const [submitting, setSubmit] = useState(false);
    const [err, setErr] = useState("");
    const {loading, items} = useSelector(state => state.themes);
    const { loading: wsLoading, res: wsResult } = useSelector(state => state.wsDetailsAuth);
    const [theme, setTheme] = React.useState({});
    const [themesArr, setThemesArr] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [activeCat, setActiveCat] = React.useState();
    const [activeTheme, setActiveTheme] = React.useState();
    const [embedType, setEmbedType] = useState('standard');
    const dispatch = useDispatch();

    useEffect(()=> {
        !loading && !items && dispatch(dashboardActions.getAllThemes());
    }, []);

    useEffect(()=> {
        if(!loading && !mounted && items && items.data){
            const arr = Object.keys(items.data);
            if(arr && arr.length){
                setTheme(items.data);
                setActiveTheme(arr[0]);
                setThemesArr(arr);
                setDataByTheme(arr[0], items.data);
                setMount(true);


            }
        }
    }, [loading, items]);

    const setDataByTheme = (name, themeArr, changeEffect = false) => {
        let expName = name;
        let catName = null;
        const isNewSurvey = getLocalStorage('isNewSurvey');
        const _surveyData = surveyData||wsResult;
        if(!isNewSurvey && _surveyData && Object.keys(_surveyData).length && !changeEffect){
            const {experience_type, survey_category} = _surveyData;
            expName = experience_type;
            catName = survey_category;
            //setExpStatus(true);
        }
        const cat = themeArr ? themeArr[expName] : (theme[expName] || {});
        const c_arr = cat ? Object.keys(cat) : [];
        console.log('exp name: ', expName);
        setActiveTheme(expName);
        setCategories(c_arr);
        c_arr.length && setActiveCat(catName || c_arr[0]);
        setData(data=>({...data, 'survey_category': catName || c_arr[0]}));
        setCheck({[catName || c_arr[0]]: !check[catName || c_arr[0]]});
    };

    useEffect(()=> {
        if(wsResult && wsResult.survey_title === surveyName){
            if(items && items.data){
                const exp = wsResult?.experience_type;
                const arr = Object.keys(items.data);
                if(arr && arr.length){
                    setTheme(items.data);
                    setActiveTheme(exp||arr[0]);
                    setThemesArr(arr);
                    setDataByTheme(exp||arr[0], items.data);
                }
            }
            cookedData(wsResult);
        }
    },[]);

    useEffect(()=>{
        cookedData(surveyData);
    },[surveyData]);

    const cookedData = (survey_data) => {
        if(survey_data){
            const clonedSurvey = clone(survey_data);
            const {id, survey_theme_picture, quest_desc={}} = clonedSurvey||{};
            if(quest_desc.questions_list && quest_desc.questions_list.length){
                const list = quest_desc.questions_list?.filter(d=>['Email', 'Contact'].indexOf(d.q_type) > -1) || [];
                setNonAnonType(list.length);
            }

            if(survey_theme_picture?.length){
                const p = survey_theme_picture.split('/');
                p.length && setCheck({[p[p.length-1]]:true})
            }
            clonedSurvey['survey_id'] = id;
            delete clonedSurvey.quest_desc;
            delete clonedSurvey.id;
            setData(clonedSurvey);
        }
    }

    const onChange=(e)=> {
        if(e?.target?.value){
            setData(data => ({ ...data, [e.target.name]:  e.target.value}));
        }
    };

    const onChangeTheme=(e)=> {
        if(e?.target?.value){
            setDataByTheme(e?.target?.value, null, true);
        }
    };

    const setSubCat = (e) => {
        const name = e?.target?.name;
        if(name){
            setCheck({[name]: !check[name]});
            setData(data => ({ ...data, 'survey_category': name}));
        }
    };

    const onCreate=(e)=> {
        const name = localStorage.getItem("formName");
        setSubmit(true);
        setTimeout(() => {setSubmit(false)}, 2000);
        data['survey_title'] = surveyName || name;
        data["campaign_id"] = getWorkspaceID()||wsID;
        data["active"] = true;
        data['type'] = 'form';
        data['survey_type'] = 'form';
        data['share_responses'] = '';
        data['experience_type'] = activeTheme;
        data['embed_type'] = embedType;

        dispatch(workspaceActions.createWorkspaceDetails(data));

    };

    const themes = () =>{
        return categories.map(e=> {
            const ob = theme[activeTheme][e];
            return <div className="imgWrap" key={"w"+e}>
                <input type="radio" name={e} checked={!!check[e]} onChange={setSubCat} />
                <div className="selected"></div>
                {ob.most_popular ? <span className="popular">Most Popular</span> : null }
                {ob.image ? <img key={'theme_'+e} src={ob.image} className={'survey-event-img'} />: null}
                <div className='bg-overlay'></div>
                <p className='theme-title'>{e}</p>
            </div>
        });

    }
    const dropDownConfig = {name: "theme",variant: "outlined", size:"small", value:activeTheme||themesArr[0]||"", menuItems:themesArr, fieldClass:'select-experience',onChange:onChangeTheme};

    return <div className={"innerScratch"}>
        <div className="scratch-heading">{"Bring you new art survey to life"}</div>
        <div className="choose-theme">
            <div className="select-exp-label">Choose Theme</div>
            <MaterialSelect config={dropDownConfig}/>
        </div>
        <div className="survey-event">
            <div className="eventWrap"> {themes()} </div>
            <Embed type={embedType} setType={setEmbedType}/>
        </div>
        <div className={"btnGroup"}>
            <div className='back-btn' onClick={()=>{history.push('/')}} ><Label config={{label: 'Cancel', className: 'back-btn-text'}} /></div>
            <div className='next-btn' style={{pointerEvents:submitting?"none":"unset"}}onClick={onCreate}><Label config={{label: 'Create', className: 'next-btn-text'}}/></div>
        </div>
        <Prompt show={err.length} message={err} handler={()=>setErr("")} singleBtn={true} btnLabel="OK" />
        <Backdrop show={loading}/>
    </div>

};

const Embed = ({type, setType}) => {
    const onEmbedClickView =(t)=>{
        setType(t)
    }
    return <div className="socialLinkPage embedViewWrap">
        <div className="link">
            <CodeIcon/>
        </div>
        <h1>Embed in a web page</h1>
        <div className="embedView" style={{background:'#fff', padding:25}}>
            <div className={`item ${type === 'standard'? 'active':''}`} style={type !== 'standard' ? {background:'#e6e6e6'}:{}} onClick={()=>onEmbedClickView("standard")}>
                <img src={origin+"/images/SVG/standered.svg"} alt="link"/>
                <h3>Standard</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
            </div>
            <div className={`item ${type === 'full'? 'active':''}`} style={type !== 'full' ? {background:'#e6e6e6'}:{}} onClick={()=>onEmbedClickView("full")}>
                <img src={origin+"/images/SVG/full.svg"} alt="link"/>
                <h3>Full-Page</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
            </div>
            <div className={`item ${type === 'slider'? 'active':''}`} style={type !== 'slider' ? {background:'#e6e6e6'}:{}} onClick={()=>onEmbedClickView("slider")}>
                <img src={origin+"/images/SVG/slider.svg"} alt="link"/>
                <h3>Slider</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
            </div>
            <div className={`item ${type === 'sliderTop'? 'active':''}`} style={type !== 'sliderTop' ? {background:'#e6e6e6'}:{}} onClick={()=>onEmbedClickView("sliderTop")}>
                <img src={origin+"/images/SVG/sliderTop.svg"} alt="link"/>
                <h3>Slider Top</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
            </div>
            <div><ProTips /></div>
        </div>

    </div>}

const ProTips = () => {
    const [tips, setTips] =useState(false);
    const onProTipHandle = (e) => {
        setTips(!tips);
    };
    return <div className="proTips formTips">
        <div className="proTipButton" onClick={onProTipHandle}>
            <img src={origin+"/images/SVG/protip_icon.svg"} width="60" height="35" alt="Pro Tips"/>
        </div>
        {
            tips &&  <div className="proTipPopup formProTipPopup">
                {/* <div className="rightIcon"><ArrowRightIcon/></div> */}
                <h4>New feature!</h4>
                <p>This is a handy template you can use for your apps (as a an onboarding tip feature for instance). Feel free to resize it, change colours and modify the arrow position.</p>
                <div className="btnGroup">
                    <button className="btn btnPrv">Prev</button>
                    <button className="btn btnNext">Next</button>
                </div>
            </div>
        }

    </div>
};

const Base = (props) => {
    const {state} = props?.location || {};
    return <StartSurvey title="Start and scratch">
        <ScratchForm {...state}/>
    </StartSurvey>
};

export default Base;
