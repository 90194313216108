import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { workspaceActions } from "../../actions";
import { FORM_MENUS } from "../Common/Constants";
import NewForm from "./NewForm";
import { PreviewDialog } from "./Preview/Preview";
import Summary from "../Survey/Summary/index";
import PageLogic from "../Survey/DesignSurvey/PageLogic";
import CollectResponse from "../Survey/SendEmail/SendEmail";
import ShareLink from "../Survey/shareLink/ShareLink";
import Analyse from "../Survey/AnalyseResults";
import Tags from "../Survey/AnalyseResults/Tags";
import { Header, MainMenu } from "../Common/Header";
import SurveySubHeader from "../Survey/Components/SurveySubHeader";
import { isFormType, setLocalStorage, getLocalStorage } from "../Common/Utils";

const Form = (props) => {
  const surveyID = Number(props?.match?.params?.id) || 0;
  const [active, setActive] = useState(
    getLocalStorage("activeTab") || "Summary"
  );
  const { loading, res } = useSelector((state) => state.wsDetailsAuth);
  const [activeMenus, setActiveMenus] = useState(FORM_MENUS);
  const [analysisTab, setAnalysisTab] = useState(
    getLocalStorage("activeSubTab") || ""
  );
  const [response, setResponse] = useState();
  const dispatch = useDispatch();
  setLocalStorage("isNewSurvey", false);

  useEffect(() => {
    surveyID && dispatch(workspaceActions.getWsDetailWithAuth(surveyID));
  }, [surveyID]);

  useEffect(() => {
    if (props?.location?.state?.tab) {
      setActive(props.location.state.tab);
    }
    if (props?.location?.state?.menus) {
      setActiveMenus(props.location.state.menus);
    }
  }, [props?.location]);

  useEffect(() => {
    setLocalStorage("activeTab", active);
    if (analysisTab !== "") {
      setTimeout(() => {
        setAnalysisTab(null);
      });
    }
    setLocalStorage("activeSubTab", null);
  }, [active]);

  const handleClose = () => {
    setActive("CreateForm");
  };

  const activeComp = () => {
    switch (active) {
      case "Summary":
        return (
          <Summary
            surveyID={surveyID}
            setActiveTab={setActive}
            setAnalysisTab={setAnalysisTab}
          />
        );
      case "CreateForm":
        return (
          <NewForm
            surveyID={surveyID}
            setActiveTab={setActive}
            setAnalysisTab={setAnalysisTab}
          />
        );
      case "PageLogic":
        return <PageLogic surveyID={surveyID} />;
      case "AnalyseSubmission":
        return (
          <Analyse
            surveyID={surveyID}
            type={res?.type || "survey"}
            analysisTab={analysisTab}
          />
        );
      case "Tags":
        return <Tags surveyID={surveyID} />;
      case "Preview":
        return (
          <PreviewDialog
            surveyID={surveyID}
            show={true}
            handleClose={handleClose}
            isPreview={true}
          />
        );
      case "CollectResponses":
        return <ShareLink surveyID={surveyID} isFormType={true} />;
      default:
        return;
    }
  };

  return (
    <div className={"CreateSurveyWrapper CreateFormWrapper"}>
      <Header surveyID={surveyID} showEyeIcon={true} />
      <MainMenu />
      <SurveySubHeader
        surveyTitle={res?.survey_title || ""}
        activeMenu={active}
        surveyMenus={activeMenus}
        setActiveMenu={setActive}
        flexStart={false}
      />
      {activeComp()}
    </div>
  );
};

export default Form;
