import api from "./api";


async function createCallQueue(payload) {
    const result = await api.post("/call_queue/addCallQueue", payload);
    return result.data;
}

async function getCallQueue() {
    const result = await api.get(`/call_queue/getCallQueue`);
    return result.data;
}

async function updateCallQeue(callQueueId, payload) {
    const result = await api.put(`/call_queue/updateCallQueue/${callQueueId}`,payload);
    return result.data;
}

async function deleteCallQueue(callQueueId) {
    const result = await api.delete(`/call_queue/deleteCallQueue/${callQueueId}`);
    return result.data;
}

export const CallQueueService = {
    createCallQueue,
    getCallQueue,
    updateCallQeue,
    deleteCallQueue
}
