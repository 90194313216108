import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Paper,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Chip,
  FormControlLabel,
  Checkbox,
  IconButton,
  Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Header, MainMenu } from '../../Common/Header';
import { integrationAction } from '../../../actions/integrationAction';
import { swalAlert } from '../../Common/SwalAlert';
import { useDispatch, useSelector } from "react-redux";
import { IntegrationService } from "../../../services/IntegrationService";
import { integrationsConstants } from '../../../constants/integrationConstants';
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useMediaQuery} from "@mui/material";


const ApolloIntegrationForm = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [formData, setFormData] = useState({
    integration_type: "",
    integration_provider: "",
    api_available: "",
    api_endpoint: "",
    data_format: "JSON",
    active: "TESTING",
    name: "",
    api_key: "",
    auth_type: "API_KEY",
    request_method: "POST",
    description: "",
    status: "INACTIVE",
  });
  const [loading, setLoading] = useState(false);
  const [integrationData, setIntegrationData] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [intId, setIntId] = useState("");


  const dispatch = useDispatch();
  const isIntegrationAdded = useSelector((state) => state.integrationLogic.isIntegrationAdded);



  const integrations = [
    {
      name: "Apollo",
      icon: "images/integration/apollo.png",
      description: "Apollo provides advanced people and organization search."
    },
    {
      name: "Salesforce",
      icon: "images/integration/sales.png",
      description: "Salesforce helps manage leads and accounts."
    },
    {
      name: "HubSpot",
      icon: "images/integration/hubspot.png",
      description: "HubSpot offers tools for contact and campaign management."
    },
    {
      name: "ZoomInfo",
      icon: "images/integration/zoominfo.png",
      description: "ZoomInfo specializes in company and contact search."
    },
    {
      name: "Clearbit",
      icon: "images/integration/clearbit.png",
      description: "Clearbit is known for fast data querying."
    },
  ];


  const integratedList = [
    {
      name: "Search people",
      provider: "Apollo",
      request: "GET",
      status: "Active"
    },
    {
      name: "Enrich people",
      provider: "Apollo",
      request: "POST",
      status: "Active",
    },
    {
      name: "Search contacts",
      provider: "Apollo",
      request: "GET",
      status: "Inactive",
    },
    {
      name: "Search Orgaization",
      provider: "Salesforce",
      request: "GET",
      status: "Inactive",
    },
    {
      name: "Search User",
      provider: "Salesforce",
      request: "GET",
      status: "Active",
    }
  ];

  const fetchIntegration = async () => {
    setLoading(true);
    try {
      const response = await IntegrationService.getIntegrations();
      const integration = response || [];
      console.log("integration65", integration);

      setIntegrationData(integration.data);
    } catch (error) {
      console.error("Error fetching agents:", error);
      setIntegrationData([]); // Reset the table to empty if an error occurs
    } finally {
      setLoading(false);
    }
  };

  let connectionStatus = {
    Apollo: false,
    Salesforce: false,
    HubSpot: false,
    ZoomInfo: false,
    Clearbit: false,
  };

  // Update connectionStatus dynamically
  Object.keys(connectionStatus).forEach(provider => {
    // Check if any `integration_provider` matches the provider name
    connectionStatus[provider] = integrationData?.some(
      item =>
        item.integration_provider &&
        item.integration_provider.toLowerCase() === provider.toLowerCase()
    );
  });

  const apiOptions = {
    Apollo: {
      "people Search": "https://apollo.com/search/people",
      "organization Search": "https://apollo.com/search/organization",
      "job Postings": "https://apollo.com/job/postings",
    },
    Salesforce: {
      "lead Search": "https://salesforce.com/search/leads",
      "account Management": "https://salesforce.com/manage/accounts",
    },
    HubSpot: {
      "contact Search": "https://hubspot.com/search/contacts",
      "campaign Analysis": "https://hubspot.com/analyze/campaigns",
    },
    ZoomInfo: {
      "company Search": "https://zoominfo.com/search/company",
      "contact Enrichment": "https://zoominfo.com/enrich/contact",
    },
    Clearbit: {
      "quick Query": "https://Clearbit.com/query",
    },
  };


  const integrationOptions = {
    "customer_details": {
      providers: ["Apollo", "Salesforce", "HubSpot"],
      apis: {
        "Search Customer": "search",
        "Enrich Customer": "enrich",
      },
    },
    "customer_organization": {
      providers: ["ZoomInfo", "Clearbit"],
      apis: {
        "Search Organization": "search",
        "Enrich Organization": "enrich",
      },
    },
    "customer_contacts": {
      providers: ["Apollo", "HubSpot"],
      apis: {
        "Search Contacts": "search",
        "Enrich Contacts": "enrich",
      },
    },
    "billing": {
      providers: ["Apollo", "Salesforce", "HubSpot"],
      apis: {
        "People Search": "People Search",
        "People Enrich": "People Enrich",
      },
    },
  };

  const providerBaseUrls = {
    Apollo: "https://apollo.com",
    Salesforce: "https://salesforce.com",
    HubSpot: "https://hubspot.com",
    ZoomInfo: "https://zoominfo.com",
    Clearbit: "https://clearbit.com",
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleIntegrationSelect = (provider) => {
    setFormData((prevData) => ({
      ...prevData,
      integration_provider: provider,
      api_available: "",
      api_endpoint: "",
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };

      if (name === "integration_type") {
        updatedData.integration_provider = "";
        updatedData.api_available = "";
        updatedData.api_endpoint = "";
      }

      if (name === "integration_provider") {
        updatedData.api_available = "";
        updatedData.api_endpoint = providerBaseUrls[value] || "";
      }

      if (name === "api_available" && formData.integration_provider) {
        const baseUrl = providerBaseUrls[formData.integration_provider] || "";
        const apiPath =
          integrationOptions[formData.integration_type]?.apis[value] || "";
        updatedData.api_endpoint = `${baseUrl}/${apiPath}`;
        updatedData.name = value;
      }

      if (name === "auth_type" && value !== "API_KEY") {
        updatedData.api_key = "";
      }

      return updatedData;
    });
  };

  const handleCheckboxChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      status: e.target.checked ? "ACTIVE" : "INACTIVE",
    }));
  };

  useEffect(() => {
    // Validate all required fields (excluding optional fields like integration_status)
    const isValid = Object.entries(formData)
      .filter(([key]) => key !== "integration_status") // Exclude optional field
      .every(([, value]) => value.trim() !== ''); // Ensure fields are not empty

    setIsFormValid(isValid);
  }, [formData]);

  const handleSubscribe = async (integration) => {
    setIntId(integration.id)
    try {
      const response = await IntegrationService.getIntegrationById(integration.id);
      const responseByTeam = await IntegrationService.getIntegrationByTeamId(integration.id);
      const integrationDetails = response.data;
      console.log("responseByTeam123", responseByTeam);
      console.log("integrationDetails123", integrationDetails);

      const integrationDetailsByTeam = responseByTeam.data;

      setFormData({
        integration_type: integrationDetails.integration_type || "",
        integration_provider: integrationDetails.integration_provider || "",
        api_available: integrationDetails.api_available || "",
        api_endpoint: integrationDetails.api_endpoint || "",
        data_format: integrationDetails.data_format || "JSON",
        active: integrationDetailsByTeam.status || "TESTING",
        name: integrationDetails.api_available || "",
        api_key: integrationDetailsByTeam.api_key || "",
        auth_type: integrationDetailsByTeam.auth_type || "API_KEY",
        request_method: integrationDetailsByTeam.request_method || "POST",
        description: integrationDetailsByTeam.description || "",
        status: integrationDetails.status || "INACTIVE",
      });

      const updatePayload = {
        integration_type: formData.integration_type,
        integration_provider: formData.integration_provider,
        api_available: formData.api_available,
        api_endpoint: formData.api_endpoint,
        data_format: formData.data_format,
        name: formData.api_available,
        active: formData.status,
      };

      const updateTeamPayload = {
        name: formData.api_available,
        api_key: formData.api_key,
        auth_type: formData.auth_type,
        request_method: formData.request_method,
        description: formData.description,
        status: formData.status,
      };


      await IntegrationService.addIntegrationById(updateTeamPayload, intId)

      swalAlert({
        icon: "success",
        title: "Integration Subscri successfully!",
        showConfirmButton: true,
      });


    } catch (error) {
      console.error("Error during integration submission:", error);
      swalAlert({
        icon: "error",
        title: error?.response?.data?.message || "Failed to process integration.",
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }


  }

  const handleSubmit = async () => {
    setLoading(true);

    try {
      if (isEditMode) {
        // Update logic for edit mode
        const updatePayload = {
          integration_type: formData.integration_type,
          integration_provider: formData.integration_provider,
          api_available: formData.api_available,
          api_endpoint: formData.api_endpoint,
          data_format: formData.data_format,
          name: formData.api_available,
          active: formData.status,
        };

        const updateTeamPayload = {
          name: formData.api_available,
          api_key: formData.api_key,
          auth_type: formData.auth_type,
          request_method: formData.request_method,
          description: formData.description,
          status: formData.status,
        };
        console.log("updatePayload", updatePayload);
        console.log("intId123", intId);


        // Call the update API
        //await IntegrationService.updateIntegration(updatePayload, intId); // Assuming `formData.id` contains the integration ID
        //await IntegrationService.updateIntegrationByTeamId(updateTeamPayload,intId)
        await IntegrationService.addIntegrationById(intId, updateTeamPayload)

        swalAlert({
          icon: "success",
          title: "Integration subscribed successfully!",
          showConfirmButton: true,
        });

        setIsEditMode(false); // Exit edit mode
      } else {
        // Logic for adding a new integration
        const firstPayload = {
          integration_type: formData.integration_type,
          integration_provider: formData.integration_provider,
          api_available: formData.api_available,
          api_endpoint: formData.api_endpoint,
          data_format: formData.data_format,
          active: formData.active,
        };

        const firstResponse = await IntegrationService.addIntegration(firstPayload); // Add integration API call
        const integrationId = firstResponse?.data?.provider?.id; // Extract the ID from the response

        if (!integrationId) {
          console.error("Integration ID missing in the response");
          return;
        }

        const secondPayload = {
          name: formData.api_available,
          api_key: formData.api_key,
          auth_type: formData.auth_type,
          request_method: formData.request_method,
          description: formData.description,
          status: formData.status,
        };

        await IntegrationService.addIntegrationById(integrationId, secondPayload); // Additional configuration API call

        swalAlert({
          icon: "success",
          title: "Integration added successfully!",
          showConfirmButton: true,
        });
      }

      // Reset form and refresh integrations
      setFormData({
        integration_type: "",
        integration_provider: "",
        api_available: "",
        api_endpoint: "",
        data_format: "JSON",
        active: "TESTING",
        name: "",
        api_key: "",
        auth_type: "API_KEY",
        request_method: "POST",
        description: "",
        status: "INACTIVE",
      });
      fetchIntegration(); // Refresh the integration list
    } catch (error) {
      console.error("Error during integration submission:", error);
      swalAlert({
        icon: "error",
        title: error?.response?.data?.message || "Failed to process integration.",
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }
  };


  const handleEdit = async (integration) => {
    setLoading(true);
    setIntId(integration.id)
    try {
      const response = await IntegrationService.getIntegrationById(integration.id);
      //const responseByTeam = await IntegrationService.getIntegrationByTeamId(integration.id);
      const integrationDetails = response.data;
      //console.log("responseByTeam123",responseByTeam);
      console.log("integrationDetails123", integrationDetails);

      //const integrationDetailsByTeam = responseByTeam.data;

      setFormData({
        integration_type: integrationDetails.integration_type || "",
        integration_provider: integrationDetails.integration_provider || "",
        api_available: integrationDetails.api_available || "",
        api_endpoint: integrationDetails.api_endpoint || "",
        data_format: integrationDetails.data_format || "JSON",
        active: "TESTING",
        name: integrationDetails.api_available || "",
        api_key: "",
        auth_type: "API_KEY",
        request_method: "POST",
        description: "",
        status: integrationDetails.status || "INACTIVE",
      });

      setIsEditMode(true); // Set edit mode
      setCurrentTab(1); // Navigate to configuration tab
    } catch (error) {
      console.error("Error fetching integration details:", error);
      swalAlert({
        icon: "error",
        title: "Failed to fetch integration details.",
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }
  };



  const handleDelete = (integrationId) => {
    dispatch(integrationAction.deleteIntegration(integrationId));

  };

  useEffect(() => {
    fetchIntegration();

    if (isIntegrationAdded) {
      fetchIntegration();
      dispatch({ type: integrationsConstants.RESET_INTEGRATION_ADDED }); // Reset flag after re-fetch
    }
  }, [isIntegrationAdded, dispatch]);

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <div>
      {/* <Header /> */}
      {/* <MainMenu /> */}
      <Container maxWidth="xl" sx={{ mt: 4 }}>
        <Tabs value={currentTab} onChange={handleTabChange} variant="fullWidth">
          <Tab label="Integration List" />
          <Tab label="Integration Configuration" />
        </Tabs>

        <Grid container spacing={3} sx={{ mt: 2 }}>
          {/* Left Container */}
          <Grid item xs={4} sx={{display: isMobile ? "contents" : "block"}}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Available Integrations
              </Typography>
              <List>
                {integrations.map((integration) => {
                  const isConnected = connectionStatus[integration.name];

                  return (
                    <ListItem
                      key={integration.name}
                      button
                      //onClick={() => handleIntegrationSelect(integration.name)}
                      selected={formData.integration_provider === integration.name}
                    >
                      <ListItemAvatar>
                        <Avatar src={integration.icon}>
                          {!integration.icon.startsWith('images/') && integration.icon}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <span>{integration.name}</span>
                            <Chip
                              label={isConnected ? "Connected" : "Not Connected"}
                              size="small"
                              sx={{
                                ml: 1,
                                px: 0.7,
                                py: 0.5, // Adjust padding for a smaller size
                                fontSize: '0.65rem',
                                height: '18px', // Adjust height
                                color: isConnected ? "white" : "inherit",
                                backgroundColor: isConnected ? "#237b2a" : undefined, // Light green for connected
                              }}
                            />
                          </Box>
                        }
                        secondary={integration.description}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Paper>
          </Grid>

          {/* Right Container */}
          <Grid item xs={8} sx={{maxWidth: isMobile ? "100%" : "66.66%"}}>
            <Paper elevation={3} sx={{ p: 4 }}>
              {currentTab === 0 && (
                <>
                  <Typography variant="h5" gutterBottom>
                    Integration List
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>API Name</TableCell>
                          <TableCell>Provider</TableCell>
                          <TableCell>Endpoint</TableCell>
                          <TableCell>Active</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {integrationData?.map((integration) => (
                          <TableRow key={integration.id}>
                            <TableCell>{integration.api_available}</TableCell>
                            <TableCell>{integration.integration_provider}</TableCell>
                            <TableCell>{integration.api_endpoint}</TableCell>
                            <TableCell>{integration.active}</TableCell>
                            <TableCell>
                              {integration.subscribed ? (
                                <Button
                                  variant="contained"
                                  color="success"
                                  aria-label="subscribed"
                                  startIcon={<CheckCircleIcon />}
                                  sx={{
                                    borderRadius: "20px",
                                    fontWeight: "bold",
                                    textTransform: "none",
                                    padding: "6px 16px",
                                    backgroundColor: "#4caf50", // Green for subscribed
                                    color: "white",
                                    "&:hover": { backgroundColor: "#45a049" },
                                  }}
                                >
                                  Subscribed
                                </Button>
                              ) : (
                                <Button
                                  color="primary"
                                  onClick={() => handleEdit(integration)}
                                  aria-label="edit"
                                  startIcon={<SubscriptionsIcon />}
                                  sx={{
                                    borderRadius: "20px",
                                    fontWeight: "bold",
                                    textTransform: "none",
                                    padding: "6px 16px",
                                    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                                  }}
                                >
                                  Subscribe
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>

                    </Table>
                  </TableContainer>
                </>
              )}

              {currentTab === 1 && (
                <>
                  <Typography variant="h5" gutterBottom>
                    Integration Configuration
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth >
                        <InputLabel id="integration-type-label">
                          Integration Type <Typography component="span" color="error">*</Typography>
                        </InputLabel>
                        <Select
                          labelId="integration-type-label"
                          name="integration_type"
                          label="API Available"
                          value={formData.integration_type}
                          onChange={handleChange}
                        >
                          <MenuItem value="customer_details">Customer Details</MenuItem>
                          <MenuItem value="customer_organization">Customer Organization</MenuItem>
                          <MenuItem value="customer_contacts">Customer Contacts</MenuItem>
                          <MenuItem value="billing">Billing</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth >
                        <InputLabel id="integration-provider-label">
                          Integration Provider <Typography component="span" color="error">*</Typography>
                        </InputLabel>
                        <Select
                          labelId="integration-provider-label"
                          name="integration_provider"
                          label="Integration Provider"
                          value={formData.integration_provider}
                          onChange={handleChange}
                          disabled={!formData.integration_type}
                        >
                          {formData.integration_type &&
                            integrationOptions[formData.integration_type]?.providers.map((provider) => (
                              <MenuItem key={provider} value={provider}>
                                {provider}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel id="api-available-label" >
                          API Available <Typography component="span" color="error">*</Typography>
                        </InputLabel>
                        <Select
                          labelId="api-available-label"
                          name="api_available"
                          label="API Available"
                          value={formData.api_available}
                          onChange={handleChange}
                          disabled={!formData.integration_provider}
                        >
                          {formData.integration_type &&
                            Object.keys(
                              integrationOptions[formData.integration_type]?.apis || {}
                            ).map((api) => (
                              <MenuItem key={api} value={api}>
                                {api}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label={
                          <>
                            API Endpoint
                            <Typography component="span" color="error"> *</Typography>
                          </>
                        }
                        name="api_endpoint"
                        value={formData.api_endpoint}
                        onChange={handleChange}
                        disabled

                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth >
                        <InputLabel id="data-format-label">
                          Data Format <Typography component="span" color="error">*</Typography>
                        </InputLabel>
                        <Select
                          labelId="data-format-label"
                          name="data_format"
                          label="Data Format"
                          value={formData.data_format}
                          onChange={handleChange}
                        >
                          <MenuItem value="JSON">JSON</MenuItem>
                          <MenuItem value="XML">XML</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth >
                        <InputLabel id="active-label">
                          Status <Typography component="span" color="error">*</Typography>
                        </InputLabel>
                        <Select
                          labelId="active-label"
                          name="active"
                          label="Status"
                          value={formData.active}
                          onChange={handleChange}
                        >
                          <MenuItem value="TESTING">Testing</MenuItem>
                          <MenuItem value="ACTIVE">Active</MenuItem>
                          <MenuItem value="INACTIVE">Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth >
                        <InputLabel id="auth-type-label">
                          Auth Type <Typography component="span" color="error">*</Typography>
                        </InputLabel>
                        <Select
                          labelId="auth-type-label"
                          name="auth_type"
                          label="Auth Type"
                          value={formData.auth_type}
                          onChange={handleChange}
                        >
                          <MenuItem value="API_KEY">API Key</MenuItem>
                          <MenuItem value="OAUTH2">OAuth2</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {formData.auth_type === "API_KEY" && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label={
                            <>
                              API Key
                              <Typography component="span" color="error"> *</Typography>
                            </>
                          }
                          name="api_key"
                          value={formData.api_key}
                          onChange={handleChange}
                          placeholder="Enter API Key"
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel id="request-method-label">
                          Request Method <Typography component="span" color="error">*</Typography>
                        </InputLabel>
                        <Select
                          labelId="request-method-label"
                          label="Request Method"
                          name="request_method"
                          value={formData.request_method}
                          onChange={handleChange}
                        >
                          <MenuItem value="GET">GET</MenuItem>
                          <MenuItem value="POST">POST</MenuItem>
                          <MenuItem value="PUT">PUT</MenuItem>
                          <MenuItem value="DELETE">DELETE</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={
                          <>
                            Description
                            <Typography component="span" color="error"> *</Typography>
                          </>
                        }
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        placeholder="Enter a description"
                        multiline
                        rows={3}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <Typography variant="body1" gutterBottom>
                          Integration Status
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formData.status === "ACTIVE"}
                              onChange={handleCheckboxChange}
                              color="primary"
                            />
                          }
                          label={formData.status === "ACTIVE" ? "Active" : "Inactive"}
                        />
                      </FormControl>
                    </Grid>


                    <Grid item xs={12}>
                      <Tooltip
                        title={!isFormValid ? "Please fill in all required fields" : ""}
                        placement="top"
                        arrow
                      >
                        <span>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleSubmit}
                            sx={{ width: "auto" }}
                            disabled={!isEditMode && !isFormValid}
                          >
                            Subscribe
                          </Button>
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ApolloIntegrationForm;
