import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
  Box,
  Autocomplete,
} from "@mui/material";
import PropTypes from "prop-types";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdminService } from "../../../services/AdminService";
import { useDispatch } from "react-redux";
import { delegationAction } from "../../../actions/delegationAction"; // Adjust the import based on your project structure

const DelegationCreationForm = ({ onCancel }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    user: "",
    delegate_user: null, // Store the selected user object
    startDateTime: null,
    endDateTime: null,
    territories: "",
    description: "",
    members: [],
    active_flag: true,
  });
  const [userData, setUserData] = useState([]); // Store full user objects

  const AllocationList = ["Call routing", "Messaging", "Appointments"];

  useEffect(() => {
    const fetchUsers = async () => {
      const data = await AdminService.getUsers();
      setUserData(data?.data || []); // Store the full user objects
    };
    fetchUsers();
  }, []);

  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleMemberToggle = (member) => {
    setFormData((prev) => ({
      ...prev,
      members: prev.members.includes(member)
        ? prev.members.filter((m) => m !== member)
        : [...prev.members, member],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct the payload
    const payload = {
      delegate_user_id: formData.delegate_user?.id, // Use the ID of the selected user
      start_date: formData.startDateTime.toISOString(),
      end_date: formData.endDateTime.toISOString(),
      active_flag: formData.active_flag,
      allocation: formData.members,
    };

    // Call the API
    dispatch(delegationAction.addDelegation(payload, "103")); // Replace `userId` with the actual user ID

    // Reset form and close
    setFormData({
      user: "",
      delegate_user: null,
      startDateTime: null,
      endDateTime: null,
      territories: "",
      description: "",
      members: [],
      active_flag: true,
    });
    onCancel();
  };

  return (
    <Paper elevation={3} style={{ padding: "20px", borderRadius: "10px" }}>
      <Typography variant="h6" gutterBottom>
        Create New Delegation
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {/* Left Section */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              {/* Delegate User Dropdown (Single Select) */}
              <Grid item xs={12}>
                <Autocomplete
                  options={userData}
                  value={formData.delegate_user}
                  getOptionLabel={(option) => option.firstname} // Display the firstname in the dropdown
                  onChange={(event, newValue) =>
                    handleChange("delegate_user", newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Delegate User"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>

              {/* Start Date and Time */}
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Start Date and Time"
                    value={formData.startDateTime}
                    onChange={(newValue) =>
                      handleChange("startDateTime", newValue)
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              {/* End Date and Time */}
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="End Date and Time"
                    value={formData.endDateTime}
                    onChange={(newValue) =>
                      handleChange("endDateTime", newValue)
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              {/* Active Flag Checkbox */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.active_flag}
                      onChange={(e) =>
                        handleChange("active_flag", e.target.checked)
                      }
                      color="primary"
                    />
                  }
                  label="Active"
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Right Section */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                border: "1px solid #ccc",
                borderRadius: "10px",
                padding: "15px",
                maxHeight: "400px",
                overflowY: "auto",
                backgroundColor: "#f9f9f9",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{ textAlign: "center", fontWeight: "bold", color: "#1976d2" }}
              >
                Allocation
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ textAlign: "center", color: "#555", marginBottom: "15px" }}
              >
                Select the features you want to allocate
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "15px",
                }}
              >
                {AllocationList.map((member, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      backgroundColor: "#ffffff",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                      transition: "transform 0.2s ease, box-shadow 0.2s ease",
                      "&:hover": {
                        transform: "scale(1.03)",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      },
                    }}
                  >
                    <Checkbox
                      checked={formData.members.includes(member)}
                      onChange={() => handleMemberToggle(member)}
                      color="primary"
                    />
                    <Typography
                      variant="body1"
                      sx={{ marginLeft: "10px", fontWeight: "500", color: "#333" }}
                    >
                      {member}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Submit and Cancel Buttons */}
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

DelegationCreationForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default DelegationCreationForm;