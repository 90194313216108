import React, { useEffect, useMemo, useState, Suspense } from "react";
import { Grid, Box, styled, MenuItem, colors, Select, Button } from "@mui/material";
import { UserCheck, UserX, Coffee } from "lucide-react";
import SIPGrid from "./SipDashboard/SIPGrid";
import UserTable from "./SipDashboard/UserTable";
import QueueTable from "./SipDashboard/QueueTable";
import { Typography } from "antd";
//import DelegatedTable from "./SipDashboard/DeligateAction";
//import FTTRTable from "./SipDashboard/FTTRTable";
import { useDispatch, useSelector } from "react-redux";
import { callRouteAction } from "../../actions/callRouteAction";
import { AdminService } from "../../services/AdminService";
import PhoneIcon from "@mui/icons-material/Phone";
import DialPad from "../Dialer/DialerModal";
import {useMediaQuery} from "@mui/material"


const CallButton = styled(Button)({
    background: "linear-gradient(to bottom, #33ccff, #0099ff)",
    borderRadius: "20px",
    color: "white",
    padding: "9px 18px",
    fontSize: "17px",
    fontWeight: "bold",
    textTransform: "none",
    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "&:hover": {
        background: "linear-gradient(to bottom, #0099ff, #007acc)",
    },
});

// Massive sample data generation
const generateMassiveData = () => {
    const issues = [
        "Billing",
        "Technical Support",
        "Account Setup",
        "Renewal",
        "Complaint",
    ];

    const customerQueue = Array.from({ length: 30 }, (_, idx) => ({
        id: idx + 1,
        name: `Queue Customer ${idx + 1}`,
        waitTime: Math.floor(Math.random() * 60),
        issue: issues[Math.floor(Math.random() * issues.length)],
    }));

    return { customerQueue };
};

const CommonSelect = styled(Select)(({ theme }) => ({
    "& .MuiSelect-select": {
        padding: theme.spacing(0.2),
        paddingLeft: theme.spacing(1),
        fontSize: theme.typography.pxToRem(16),
        color: colors.grey[900],
        fontWeight: 600,
        borderRadius: 0,
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
    },
    "& .MuiSelect-icon": {
        color: colors.grey[900],
    },
}));

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(16),
    color: colors.grey[900],
    fontWeight: 600,
    lineHeight: 1,
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(0.5),
}));

const SIPDashboard = () => {
    const { customerQueue } = useMemo(generateMassiveData, []);
    const { systemUsers } = useSelector((state) => ({
        systemUsers: state.callRoute.systemUsers,
    }));

    const [territory, setTerritory] = useState();
    const [openDialer, setOpenDialer] = useState(false);


    //const activeCalls = useSelector((state) => state.customCall.activeCalls || []);

    const { activeCalls, callQueue } = useSelector((state) => ({
        activeCalls: state.callRoute?.activeCalls,
        callQueue: state.callRoute?.callQueue,
    }));

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchSystemUsers = () => {
            dispatch(callRouteAction.getSystemUsers());
        };
        fetchSystemUsers();
        const interval = setInterval(fetchSystemUsers, 18000);
        return () => clearInterval(interval);
    }, [dispatch]);

    useEffect(() => {
        (async () => {
            const res = await AdminService.getTerritory();
            setTerritory(res.data);
        })();
    }, []);

    const statusMap = {
        ACTIVE: {
            color: "success",
            icon: <UserCheck className="pulse-animation" color="green" />,
        },
        busy: { color: "error", icon: <UserX color="red" /> },
        away: { color: "warning", icon: <Coffee color="orange" /> },
    };

    // ******** count users ******** //
    const {
        totalUsers,
        availableUsers,
        busyUsers,
        awayUsers,
        averageAvailableUsersPercentage,
        averageBusyUsersPercentage,
        averageAwayUsersPercentage,
    } = useMemo(() => {
        const totalUsers = systemUsers?.length;

        const availableUsers = systemUsers?.filter(
            (user) => user?.status === "ACTIVE"
        ).length;

        const busyUsers = systemUsers?.filter(
            (user) => user?.status === "busy"
        ).length;

        const awayUsers = systemUsers?.filter(
            (user) => user?.status === "away"
        ).length;

        const averageAvailableUsersPercentage = (availableUsers / totalUsers) * 100;
        const averageBusyUsersPercentage = (busyUsers / totalUsers) * 100;
        const averageAwayUsersPercentage = (awayUsers / totalUsers) * 100;

        return {
            totalUsers,
            availableUsers,
            busyUsers,
            awayUsers,
            averageAvailableUsersPercentage,
            averageBusyUsersPercentage,
            averageAwayUsersPercentage,
        };
    }, [systemUsers]);

    const userStats = {
        total: totalUsers,
        available: availableUsers,
        busy: busyUsers,
        away: awayUsers,
        activeCalls: activeCalls.length,
        totalCalls: 250,
        pendingCalls: callQueue.length,
        maxCallDuration: 45,
        departmentBreakdown: {
            Support: 0,
            Sales: 0,
            Technical: 0,
            Billing: 0,
        },
        totalAssignedCustomers: 120,
        averageAssignedCustomers: 2.4,
        userStatusPercentage: {
            available: averageAvailableUsersPercentage,
            busy: averageBusyUsersPercentage,
            away: averageAwayUsersPercentage,
        },
    };

    const handleCallClick = () => {
        setOpenDialer(true);
    };

    const isMobile = useMediaQuery("(max-width:600px)");

    //<Grid container spacing={3} mt={1}>
    //  <DelegatedTable />
    //  <FTTRTable />
    //</Grid>

    return (
        <Box p={2} pr={4}>
        <Box my={2} display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"}>
        <Box>
        <Typography.Title level={4}>SIP Dashboard</Typography.Title>
        </Box>
        <Box display="flex" justifyContent="space-between">
        <Box display="flex" gap={2}>
        <Box>
        <Typography.Text mr={2}>Filter by Territory:</Typography.Text>
        <CommonSelect defaultValue={"all"}>
        <CustomMenuItem value="all">All</CustomMenuItem>
        {territory?.map((t, i) => (
            <CustomMenuItem key={i} value="north">
            {t?.name}
            </CustomMenuItem>
        ))}
        </CommonSelect>
        </Box>
        <Box>
        <Typography.Text>Filter by Department:</Typography.Text>
        <CommonSelect defaultValue={"all"}>
        <CustomMenuItem value="all">All</CustomMenuItem>
        <CustomMenuItem value="sales">Sales</CustomMenuItem>
        <CustomMenuItem value="support">Support</CustomMenuItem>
        <CustomMenuItem value="billing">Billing</CustomMenuItem>
        <CustomMenuItem value="technical">Technical</CustomMenuItem>
        </CommonSelect>
        </Box>
        </Box>
        </Box>
        <CallButton startIcon={<PhoneIcon />} onClick={handleCallClick} sx={{margin: isMobile ? "10px" : ""}}>
        Call Now
        </CallButton>
        </Box>

        {/* Compact Stats Cards */}
        <SIPGrid userStats={userStats} />

        <Grid container spacing={3} mt={1}>
        <UserTable systemUsers={systemUsers} statusMap={statusMap} />

        {/* Dense Queue Table */}
        <QueueTable />
        </Grid>


        {openDialer && (
            <Suspense fallback={<p>Loading</p>}>
            <DialPad open={openDialer} handleClose={() => setOpenDialer(false)} />
            </Suspense>
        )}

        <style jsx global>{`
        .pulse-animation {
          animation: pulse 2s infinite;
        }
        @keyframes pulse {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.1);
          }
          100% {
            transform: scale(1);
          }
        }
      `}</style>
        </Box>
    );
};

export default SIPDashboard;
