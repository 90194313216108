import axios from "axios";
import {
  getLocalAccessToken,
  getLocalRefreshToken,
  updateLocalAccessToken,
  logout,
} from "../helpers"; 

const devApiUrl = "https://betavocapi.articence.com";

const devInstance = axios.create({
  baseURL: devApiUrl,
  headers: {
    Authorization: "Bearer " + getLocalRefreshToken(),
    "Content-Type": "application/json",
  },
});

devInstance.interceptors.request.use(
  (config) => {
    const token = getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

devInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/login" && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await axios.post(
            `${devApiUrl}/token/refresh`,
            {},
            {
              headers: {
                Authorization: "Bearer " + getLocalRefreshToken(),
                "Content-Type": "application/json",
              },
            }
          );
          const { access_token } = rs.data;
          updateLocalAccessToken(access_token);

          return devInstance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);

export default devInstance;
