import React, { useRef } from "react";
import JoditEditor from "jodit-react";

const configButton = {
  useSearch: false,
  toolbarSticky: false,
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  // iframe: true,
  buttons:
    "bold,italic,underline,strikethrough,eraser,ul,ol,font,fontsize,paragraph,lineHeight,superscript,subscript,file,image,table,link,indent,outdent,left,brush,undo,redo,preview,source",
};

const TextEditor = ({ placeholder, onChange, editorValue }) => {
  const editor = useRef(null);
  return (
    <JoditEditor
      ref={editor}
      value={editorValue}
      config={configButton}
      tabIndex={1}
      onBlur={(newContent) => onChange(newContent)}
      onChange={(newContent) => {}}
    />
  );
};

export default TextEditor;
