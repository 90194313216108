import React, { useEffect, useState } from "react";
import {
    Modal,
    Box,
    Button,
    Typography,
    IconButton,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Snackbar, 
    Alert,
} from "@mui/material";
import { PhoneInTalk, Cancel, CallEnd, Mic, MicOff } from "@mui/icons-material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { customCallActions } from "../../actions/customCallActions";
import { CrmService } from "../../services";
import { crmActions } from "../../actions";
import AddModal from "../../voc/Common/AddModal";
import EmailIcon from "@mui/icons-material/Email";
import BusinessIcon from "@mui/icons-material/Business";
import CallIcon from "@mui/icons-material/Call";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import PauseIcon from "@mui/icons-material/Pause";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

import StopCircleIcon from "@mui/icons-material/StopCircle";
import ChatBot from "./ChatBot";
import CustomerCreationModal from "./CustomerCreationModal";
import { customCallConstant } from "../../constants/customCallConstant";
import EscalationIcon from "@mui/icons-material/ArrowUpward";
import { keyframes } from "@emotion/react";
import { AdminService } from "../../services/AdminService";

const StyledModal = styled(Modal)(({ theme }) => ({
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
}));

const ModalBox = styled(Box)(({ theme }) => ({
    backgroundColor: "#d9bda5",
    borderRadius: "8px",
    padding: "20px",
    width: "300px",
    boxShadow:
    "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
    position: "relative",
    animation: "slide-in 0.5s ease-out, bounce 0.5s ease-in-out forwards",
    "@keyframes slide-in": {
        "0%": {
            transform: "translate(100%, 100%)",
            opacity: 0,
        },
        "100%": {
            transform: "translate(0, 0)",
            opacity: 1,
        },
    },
    "@keyframes bounce": {
        "0%": {
            transform: "scale(1)",
        },
        "50%": {
            transform: "scale(1.05)",
        },
        "100%": {
            transform: "scale(1)",
        },
    },
}));

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ProfilePicture = styled("img")({
    width: "60px",
    height: "60px",
    borderRadius: "50%",
});

const PulseButton = styled(Button)(({ theme }) => ({
    position: "relative",
    overflow: "hidden",

    "&:hover": {
        backgroundColor: "#4CAF50",
        boxShadow:
        "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
        transition: "background-color 0.3s ease",

        "&::after": {
            content: "''",
            position: "absolute",
            top: "-50%",
            left: "-50%",
            width: "200%",
            height: "200%",
            borderRadius: "50%",
            backgroundColor: "#4CAF50",
            animation: "$pulse-animation 1s infinite",
            opacity: 0.5,
        },
    },

    "@keyframes pulse-animation": {
        "0%": {
            transform: "scale(1)",
        },
        "50%": {
            transform: "scale(1.05)",
        },
        "100%": {
            transform: "scale(1)",
        },
    },
}));

const IncomingCallModal = () => {
    const [callerDetails, setCallerDetails] = useState(null);
    const [isAddNoteOpen, setIsAddNoteOpen] = useState(false);
    const [isAddCustomerOpen, setIsAddCustomerOpen] = useState(false);
    const [noteContent, setNoteContent] = useState("");
    const [status, setStatus] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [callLogs, setCallLogs] = useState([]);
    const [SummaryResponse, setSummaryResponse] = useState("");
    const [openTransferModal, setOpenTransferModal] = useState(false);
    const [isAssistantOpen, setIsAssistantOpen] = useState(false);
    const [transferNo, setTransferNo] = useState("");
    const [isEscalatePopupOpen, setIsEscalatePopupOpen] = useState(false);
    const [escalationReason, setEscalationReason] = useState(""); 
    const [isEscalationInProgress, setIsEscalationInProgress] = useState(false);

    const {
        isCalling,
        callerNumber,
        callStatus,
        audioRef,
        isAccepted,
        dialerNum,
        isHold,
        isMuted,
        isRecording,
        ai_response,
        callType,
        callLogId,
    } = useSelector((state) => ({
        isCalling: state.customCall.isCalling,
        callerNumber: state.customCall.callerNumber,
        callStatus: state.customCall.callStatus,
        audioRef: state.customCall.audioRef,
        isAccepted: state.customCall.isAccepted,
        dialerNum: state.customCall.dialerNum,
        isHold: state.customCall.isHold,
        isMuted: state.customCall.isMuted,
        isRecording: state.customCall.isRecording,
        ai_response: state.customCall.ai_response,
        callType: state.customCall.callType,
        callLogId: state.customCall.callLogId,
    }));

    console.log(audioRef, "audioRef");
    const dispatch = useDispatch();

    useEffect(() => {
        if (dialerNum || callerNumber) { // Ensure at least one has a value
            (async () => {
                try {
                    const callerData = await CrmService.getCustomerDataByNo(dialerNum || callerNumber);
                    console.log(callerData?.data?.customers[0], "callerData");
                    const customerX = callerData?.data?.customers[0];
                    console.log(customerX, "callerData");

                    dispatch(
                        customCallActions.StoreCallLogs(
                            customerX?.id || null,
                            callType,
                            "Failed",
                            callerNumber || dialerNum,
                        ),
                    );
                    if (callerData?.data?.customers.length !== 0) {
                        console.log(callerData, "callerData");
                        setCallerDetails(customerX);
                        setDisplayName(customerX.first_name);
                    }
                } catch (error) {
                    console.error("Error fetching customer data:", error);
                }
            })();
        }
    }, [isCalling, dialerNum, callerNumber, dispatch]); 


    const handleTransferCall = (target) => {
        dispatch(customCallActions.transferCallRequest(target));
    };

    const handleHoldCall = () => {
        dispatch(customCallActions.holdCallRequest());
    };

    const handleAccept = () => {
        console.log("handleAccept");

        dispatch(customCallActions.acceptCallRequest());
    };

    const handleUnHoldCall = () => {
        dispatch(customCallActions.unHoldCallRequest());
    };

    const handleMuteCall = () => {
        dispatch(customCallActions.muteCallRequest());
    };

    const handleUnMuteCall = () => {
        dispatch(customCallActions.unMuteCallRequest());
    };

    useEffect(() => {
        if (isCalling === true) {
            (async () => {
                const response = await CrmService.getCallsLogsByCustomerId(
                    callerDetails?.id
                );
                const callLogs = response?.data?.call_logs || [];

                setCallLogs(callLogs);
            })();
        }
    }, [isCalling]);

    useEffect(() => {
        if (isCalling === true && callerDetails) {
            (async () => {
                const response = await CrmService.getSummaryResponse(
                    callerDetails?.id || "8"
                );
                const Summary = response?.data || [];
                setSummaryResponse(Summary);
            })();
        }
    }, [isCalling, callerDetails?.id]);

    const handleDropCall = async () => {
        dispatch(customCallActions.declineCallRequest());
        dispatch(customCallActions.endCallRequest());
    };

    useEffect(() => {
        // reset state
        if (isCalling === false) {
            setCallerDetails(null);
            setDisplayName("");
            setCallLogs([]);
            setSummaryResponse("");
        }
    }, [isCalling]);

    const latestCalls = callLogs.slice(0, 2);

    const handleAddNoteClick = () => {
        setIsAddNoteOpen(true);
    };

    const handleCloseNote = () => {
        setIsAddNoteOpen(false);
    };

    const handleSaveNote = () => {
        const addNotesData = {
            content: noteContent,
            status: status,
        };
        dispatch(crmActions.addNotes(addNotesData, callerDetails?.id));
        handleCloseNote();
    };

    const handleAddCustomerClick = () => {
        setIsAddCustomerOpen(true);
    };

    const handleCloseCustomerModal = () => {
        setIsAddCustomerOpen(false);
    };

    const handleEscalateClick = () => {
        setIsEscalatePopupOpen(true); // Show the escalation popup
      };
    
      const handleEscalateConfirm = async (confirm) => {
        if (confirm) {
          try {
            const callId = callLogId; 
            const reason = escalationReason; 
            const result = await AdminService.addEscalation(callId, reason);
            
            if(result?.data?.manager_extension){
                handleTransferCall(`sip:${result?.data?.manager_extension}@pbx.articence.com`);
            }

            setIsEscalationInProgress(true);
            setTimeout(() => {
                setIsEscalationInProgress(false);
              }, 5000);
            
          } catch (error) {
            console.error("Failed to escalate call:", error);
          }
        }
        setIsEscalatePopupOpen(false); 
        setEscalationReason(""); 
      };

    return (
        <>
        <audio ref={audioRef} autoPlay id="remoteAudio" />
        <StyledModal
        open={isCalling}
        sx={{ zIndex: 1000, justifyContent: "center", alignItems: "center" }}
        >
        <ModalBox>
        <Box
        sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }}
        ><Tooltip title="Escalate call" arrow>
            <IconButton onClick={handleEscalateClick}>
              <EscalationIcon fontSize="large" color="primary" />
            </IconButton></Tooltip>
        <IconButton onClick={() => setIsAssistantOpen(true)}>
        <AutoAwesomeIcon />
        </IconButton>
        </Box>

        <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={1}
        marginTop={2}
        >
        <ProfilePicture src="https://via.placeholder.com/60" alt="Caller" />
        <Typography variant="h6" fontWeight="bold">
        {displayName}
        </Typography>

        <Typography
        variant="body2"
        sx={{ display: "flex", alignItems: "center", gap: 1 }}
        >
        {callerDetails?.first_phone || callerNumber}
        </Typography>
        <div>
        <Typography
        variant="h6"
        sx={{
            display: "flex",
                alignItems: "center",
                gap: 1,
                fontSize: "15px",
        }}
        >
        <BusinessIcon fontSize="small" />{" "}
        {(callerDetails && callerDetails?.company) || "Not available"}
        </Typography>

        <Typography
        variant="h6"
        sx={{
            display: "flex",
                alignItems: "center",
                gap: 1,
                fontSize: "15px",
        }}
        >
        <EmailIcon fontSize="small" />{" "}
        {(callerDetails && callerDetails?.email) || "Not available"}
        </Typography>
        </div>

        {/* Call History List with reduced spacing */}
        <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
        {/* Smaller Heading for Call History */}
        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
        Call History:
        </Typography>
        {latestCalls.map((call, index) => (
            <ListItem
            key={index}
            sx={{ paddingLeft: 0, paddingTop: 0.5, paddingBottom: 0.5 }}
            >
            <ListItemIcon sx={{ minWidth: 30 }}>
            <CallIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText
            primary={`Call ${index + 1} Answered:`}
            secondary={new Date(call.call_start_time).toLocaleString()}
            primaryTypographyProps={{
                fontWeight: "bold",
                    fontSize: "0.875rem",
            }}
            secondaryTypographyProps={{ fontSize: "0.75rem" }}
            />
            </ListItem>
        ))}
        </List>

        <Typography
        variant="h6"
        sx={{
            display: "flex",
                alignItems: "center",
                gap: 1,
                fontSize: "12px",
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: "7px",
        }}
        >
        {SummaryResponse.action_item}
        </Typography>
        </Box>

        {/* Add Note Button */}
        <Box display="flex" justifyContent="center" marginTop={2}>
        {callerDetails === null ? (
            // If callerDetails is null, show the Add Customer button
            <Button
            variant="contained"
            color="primary"
            onClick={handleAddCustomerClick}
            >
            Add Customer
            </Button>
        ) : (
            // If callerDetails is not null, show the Add Note button
            <Button
            variant="contained"
            color="primary"
            onClick={handleAddNoteClick}
            >
            Add Note
            </Button>
        )}
        </Box>

        <Box
        sx={{
            display: "flex",
                justifyContent: "center",
                alignItems: "center",
                my: 1,
        }}
        >
        <Typography variant="p" fontWeight="bold" marginTop={2}>
        {callStatus}
        </Typography>
        </Box>

        {/* Call Actions */}
        {!isAccepted ? (
            <Box display="flex" justifyContent="space-between" marginTop={2}>
            <PulseButton
            variant="contained"
            color="success"
            startIcon={<PhoneInTalk />}
            onClick={handleAccept}
            >
            Accept
            </PulseButton>
            <Button
            variant="contained"
            color="error"
            startIcon={<Cancel />}
            onClick={handleDropCall}
            >
            Reject
            </Button>
            </Box>
        ) : (
            <>
            <Box marginTop={2}>
            <Grid container spacing={2} justifyContent="center">
            <Grid item xs={2}>
            {!isMuted ? (
                <IconButton onClick={handleMuteCall}>
                <Mic />
                </IconButton>
            ) : (
                <IconButton onClick={handleUnMuteCall}>
                <MicOff />
                </IconButton>
            )}
            </Grid>
            {/* <Grid item xs={2}>
                <IconButton onClick={() => setSpeakerOn(!speakerOn)}>
                {speakerOn ? <VolumeUp /> : <VolumeOff />}
                </IconButton>
                </Grid> */}
            <Grid item xs={2}>
            {!isHold ? (
                <IconButton onClick={handleHoldCall}>
                <PauseIcon />
                </IconButton>
            ) : (
                <IconButton onClick={handleUnHoldCall}>
                <StopCircleIcon />
                </IconButton>
            )}
            </Grid>
            <Grid item xs={2}>
            <IconButton
            sx={{
                backgroundColor: isRecording && "#f44336",
            }}
            onClick={() => {
                // isRecording
                //   ? transcribeAudio()
                //   : dispatch(customCallActions.startCaptureVoice());
            }}
            >
            <RadioButtonCheckedIcon />
            </IconButton>
            </Grid>
            <Grid item xs={2}>
            <IconButton onClick={() => setOpenTransferModal(true)}>
            <MoveUpIcon />
            </IconButton>
            </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" marginTop={2}>
            <Button
            variant="contained"
            color="error"
            startIcon={<CallEnd />}
            onClick={handleDropCall}
            >
            Drop Call
            </Button>
            </Box>
            </Box>

            <Dialog
            open={openTransferModal}
            onClose={() => setOpenTransferModal(false)}
            maxWidth="md"
            >
            <DialogTitle>Transfer Call</DialogTitle>
            <DialogContent>
            {/* <TextField label="Transfer To" fullWidth margin="dense" /> */}
            <TextField
            placeholder="Enter Extension"
            fullWidth
            margin="dense"
            onChange={(e) => setTransferNo(e.target.value)}
            />
            </DialogContent>
            <DialogActions>
            <Button
            onClick={() => setOpenTransferModal(false)}
            color="inherit"
            >
            Cancel
            </Button>
            <Button
            onClick={() => {
                setOpenTransferModal(false);
                handleTransferCall(`sip:${transferNo}@pbx.articence.com`);
            }}
            color="primary"
            >
            Transfer
            </Button>
            </DialogActions>
            </Dialog>
            </>
        )}
        {/* Conditionally render AddModal */}
        {isAddNoteOpen && (
            <AddModal
            modalShow={isAddNoteOpen}
            handleClose={handleCloseNote}
            noteContent={noteContent}
            setNoteContent={setNoteContent}
            status={status}
            setStatus={setStatus}
            handleSaveNote={handleSaveNote}
            />
        )}
        {isAddCustomerOpen && (
            <CustomerCreationModal
            open={isAddCustomerOpen}
            onClose={handleCloseCustomerModal}
            phone={callerNumber || dialerNum}
            />
        )}

        {/* Add Note Form in Dialog */}
        {/* <Dialog open={isAddNoteOpen} onClose={handleCloseNote}>
                <DialogTitle>Add Note</DialogTitle>
                <DialogContent>
                <TextField
            label="Note Content"
            fullWidth
            multiline
            rows={3}
            value={noteContent}
            onChange={(e) => setNoteContent(e.target.value)}
            margin="dense"
                />
                <Select
            fullWidth
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            displayEmpty
            margin="dense"
                >
                <MenuItem value="">
                <em>Select Status</em>
                </MenuItem>
                <MenuItem value="open">Open</MenuItem>
                <MenuItem value="closed">Closed</MenuItem>
                <MenuItem value="in_progress">In Progress</MenuItem>
                </Select>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseNote} color="inherit">
                Close
                </Button>
                <Button onClick={handleSaveNote} color="primary">
                Save Note
                </Button>
                </DialogActions>
                </Dialog> */}
        {/* ------------------  Call Features---------------------------- */}
        {isAssistantOpen && (
            <ChatBot
            handleClose={() => setIsAssistantOpen(false)}
            transcription={ai_response?.transcription}
            message={ai_response?.message}
            />
        )}
        {/* ------------------  Call Features---------------------------- */}
        </ModalBox>
        </StyledModal>
        
        <Dialog
        open={isEscalatePopupOpen}
        onClose={() => setIsEscalatePopupOpen(false)}
        sx={{
          "& .MuiDialog-paper": {
            animation: `${fadeIn} 0.3s ease-out`,
            borderRadius: "16px",
            padding: "16px",
            background: "linear-gradient(145deg, #ffffff, #f0f0f0)",
            boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
            maxWidth: "none",
            overflow: "visible",
            height: "auto",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: "#1976d2",
          }}
        >
          Escalate Call
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            sx={{ textAlign: "center", color: "#555", marginBottom: "16px" }}
          >
            Please enter the reason for escalation:
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter reason"
            value={escalationReason}
            onChange={(e) => setEscalationReason(e.target.value)}
            sx={{ marginBottom: "16px" }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            gap: "16px",
            paddingBottom: "16px",
          }}
        >
          <Button
            onClick={() => handleEscalateConfirm(false)}
            variant="outlined"
            sx={{
              color: "#f44336",
              borderColor: "#f44336",
              "&:hover": {
                backgroundColor: "#f44336",
                color: "#fff",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleEscalateConfirm(true)}
            variant="contained"
            sx={{
              backgroundColor: "#1976d2",
              "&:hover": {
                backgroundColor: "#1565c0",
              },
            }}
            disabled={!escalationReason.trim()} // Disable if reason is empty
          >
            Escalate
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={isEscalationInProgress}
        autoHideDuration={5000}
        onClose={() => setIsEscalationInProgress(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }} 
      >
        <Alert
          severity="info" 
          sx={{
            backgroundColor: "#1976d2", 
            color: "#fff", 
            borderRadius: "8px", 
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
          }}
        >
          Your call is being escalated to the manager. Please wait...
        </Alert>
      </Snackbar>
        </>
    );
};

export default IncomingCallModal;
