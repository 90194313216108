import React from 'react';
import {
    Paper,
    Box,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Grid,
    TextField,
    MenuItem,
    IconButton,
    Tooltip,
    Stack,
    styled,
    Collapse,
    Divider,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const CompactTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputLabel-root': {
        fontSize: '1rem',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: "2rem",
    },
}));

const RotatingIconButton = styled(IconButton)(({ theme, open }) => ({
    transform: open ? 'rotate(180deg)' : 'rotate(0)',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shorter,
    }),
}));

const DashboardFilter = ({ dashboardFilter, handleOnChangeFilter, toggleDate, setToggleDate, redirectName }) => {
    const [showFilter, setShowFilter] = React.useState(false);

    const handleToggleFilter = () => {
        setShowFilter(!showFilter);
    };
    

    return (
        <Paper
            elevation={5}
            sx={{
                p: 1,
                borderRadius: "2rem",
                bgcolor: "transparent",
                boxShadow: `${!showFilter ? "0" : "0 4px 10px rgba(0, 0, 0, 0.1)"}`,
            }}
        >
            <Stack spacing={2}>
                {/* Header */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                      {redirectName} Dashboard
                    </Typography>

                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2
                    }}>

                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2
                        }}>
                            <CompactTextField
                                type="date"
                                name="start_date"
                                value={dashboardFilter.startDate}
                                onChange={handleOnChangeFilter}
                                size="small"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label="Start Date"
                            />
                            <CompactTextField
                                type="date"
                                name="end_date"
                                value={dashboardFilter.endDate}
                                onChange={handleOnChangeFilter}
                                size="small"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label="End Date"
                            />

                            <CompactTextField
                                select
                                label="Call Status"
                                name="call_status"
                                value={dashboardFilter.callStatus}
                                onChange={handleOnChangeFilter}
                                size="small"
                                fullWidth
                            >
                                <MenuItem value="">All Status</MenuItem>
                                <MenuItem value="completed">Completed</MenuItem>
                                <MenuItem value="missed">Missed</MenuItem>
                                <MenuItem value="ongoing">Ongoing</MenuItem>
                            </CompactTextField>

                            <CompactTextField
                                select
                                label="Call Type"
                                name="call_type"
                                value={dashboardFilter.callType}
                                onChange={handleOnChangeFilter}
                                size="small"
                                fullWidth
                            >
                                <MenuItem value="">All Types</MenuItem>
                                <MenuItem value="inbound">Inbound</MenuItem>
                                <MenuItem value="outbound">Outbound</MenuItem>
                            </CompactTextField>
                            <CompactTextField
                                type="number"
                                label="Min Duration (mins)"
                                name="min_duration"
                                value={dashboardFilter.minDuration}
                                onChange={handleOnChangeFilter}
                                size="small"
                                fullWidth
                                InputProps={{ inputProps: { min: 0 } }}
                            />
                            <CompactTextField
                                type="number"
                                label="Max Duration (mins)"
                                name="max_duration"
                                value={dashboardFilter.maxDuration}
                                onChange={handleOnChangeFilter}
                                size="small"
                                fullWidth
                                InputProps={{ inputProps: { min: 0 } }}
                            />
                        </Box>
                        <Box
                            onClick={handleToggleFilter}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                cursor: 'pointer',
                            }}
                        >

                        </Box>
                    </Box>

                </Box>


            </Stack>

        </Paper>
    );
};

export default DashboardFilter;
