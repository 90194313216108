import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { queryBuilderActions } from "../../../../actions";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import TableRenderers from "react-pivottable/TableRenderers";
import Plotly from "plotly.js-basic-dist";
import createPlotlyComponent from "react-plotly.js/factory";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import { commonService } from "../../../../services/CommonService";
import "./builder.css";
import api from "../.././../../services/api";
import { swalAlert } from "../../../../voc/Common/SwalAlert";

import {
  Tabs,
  Card,
  Space,
  Statistic,
  Row,
  Col,
  Table,
  Steps,
  Button,
  Input,
  Modal,
  Form,
} from "antd";
import { useParams } from "react-router";
// import { omitHideDisabledProps } from "rsuite/esm/utils/dateUtils";
import { getLocalAccessToken } from "../../../../helpers";
const QueryBuilder = ({ props }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [form] = Form.useForm();

  const pivots = useSelector((state) => state.queryBuilder.items);
  const queryData = useSelector((state) => state.queryBuilder.queries);
  console.log("dfkjjjj", queryData);
  useEffect(() => {
    if (queryData && queryData.queries) {
      setPivotTableData(queryData.queries);
    }
  }, [queryData]);

  const [pivotTableData, setPivotTableData] = useState([]);
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const queryColumns = [
    {
      title: "Query Name",
      dataIndex: "query_name",
      key: "query_name",
    },
    {
      title: "Query Description",
      dataIndex: "query_description",
      key: "query_description",
    },
    {
      title: "Date Submitted",
      dataIndex: "date_submitted",
      key: "date_submitted",
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button type="link" onClick={(e) => restorePreviousQuery(e, record)}>
            Restore
          </Button>
          <Button type="link" onClick={(e) => deleteQuery(e, record)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];
  console.log("dssssss", pivots);
  const [saveQuery, setSaveQuery] = useState(false);
  const handleCancel = () => {
    setSaveQuery(false);
  };

  const restorePreviousQuery = (e, elem) => {
    console.log("elem", elem);
    if (elem != undefined) {
      let pivotData = JSON.parse(elem.pivot_state);
      if (pivotData !== undefined) {
        pivotData.data = pivots;
        console.log("pivot post return", pivotData.data);
        pivotData.unusedOrientationCutoff = Infinity;
        setPivotState(pivotData);
        console.log("pivot data?", pivotData);
      }
    }
  };
  const downloadFile = async () => {
    const apiUrl = commonService.apiUrl;
    console.log(id);
    fetch(`${apiUrl}/getDownload?detail_id=${id}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + getLocalAccessToken(),
        "Content-Type": "text/csv",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `FileName.csv`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };
  useEffect(() => {
    dispatch(queryBuilderActions.getQueriesById(id));
  }, []);
  
  const deleteQuery = (e, elem) => {
    dispatch(queryBuilderActions.deleteQuery(elem.detail_id, elem.query_name)).then(res=>{
      dispatch(queryBuilderActions.getQueriesById(id));
      swalAlert({
        icon: "success",
        title: "Query has been deleted!".toString(),
        showConfirmButton: true,
      });
    });
    // let temp = queryData;
    // for (let i = 0; i < temp.queries.length; i++) {
    //   if (
    //     temp.queries[i].detail_id == elem.detail_id &&
    //     temp.queries[i].query_name === elem.query_name
    //   ) {
    //     temp.queries.splice(i, 1);
    //     break;
    //   }
    // }
    // setPivotTableData(temp);
    // console.log("pivots.items.query after wards?", pivots.items.queries)
    // alert("Query has been deleted!");
  };
  const handlePivotSave = (values) => {
    if (pivotState && pivotState.rows && pivotState.rows.length >= 1) {
      let alreadyExist = false;
      for (let i = 0; i < pivotTableData.length; i++) {
        if (pivotTableData[i].query_name === values.query_name) {
          alreadyExist = true;
        }
      }
      if (alreadyExist) {
        swalAlert({
          icon: "error",
          title: "Query Already Exists!".toString(),
          showConfirmButton: true,
        });
      } else {
        dispatch(
          queryBuilderActions.savePivotState(
            id,
            values.query_name,
            values.query_description,
            pivotState.rows,
            pivotState
          )
        ).then(res=>{
          if(res && res.status === 200){
            dispatch(queryBuilderActions.getQueriesById(id));
          }
        });
      }
      form.resetFields();

      setSaveQuery(false);

      return 0;
      let current = new Date();
      let temp = {
        detail_id: id,
        query_name: values.query_name,
        query_description: values.query_description,
        date_submitted: `${current.getDate()}/${
          current.getMonth() + 1
        }/${current.getFullYear()}`,
        questions: pivotState.rows,
        pivot_state: pivotState,
        // {
        //     title: 'Query Name',
        //     dataIndex: 'query_name',
        //     key: 'query_name',

        //   },
        //   {
        //     title: 'Query Description',
        //     dataIndex: 'query_description',
        //     key: 'query_description',
        //   },
        //   {
        //     title: 'Date Submitted',
        //     dataIndex: 'date_submitted',
        //     key: 'date_submitted',
        //   },
      };
      setPivotTableData([...pivotTableData, temp]);
    } else if (pivotState && pivotState.cols && pivotState.cols.length >= 1) {
      let alreadyExist = false;
      for (let i = 0; i < pivotTableData.length; i++) {
        if (pivotTableData[i].query_name === values.query_name) {
          alreadyExist = true;
        }
      }
      if (alreadyExist) {
        swalAlert({
          icon: "error",
          title: "Query Already Exists!".toString(),
          showConfirmButton: true,
        });
      } else {
        dispatch(
          queryBuilderActions.savePivotState(
            id,
            values.query_name,
            values.query_description,
            pivotState.cols,
            pivotState
          )
        ).then(res=>{
          if(res && res.status === 200){
            dispatch(queryBuilderActions.getQueriesById(id));
          }
        });
      }
      form.resetFields();

      setSaveQuery(false);

      return 0;
      let current = new Date();
      let temp = {
        detail_id: id,
        query_name: values.query_name,
        query_description: values.query_description,
        date_submitted: `${current.getDate()}/${
          current.getMonth() + 1
        }/${current.getFullYear()}`,
        questions: pivotState.rows,
        pivot_state: pivotState,
      };
      setPivotTableData([...pivotTableData, temp]);
    }
    setSaveQuery(false);
    //patch(campaignActions.getQueriesById(id));
  };
  useEffect(() => {
    dispatch(queryBuilderActions.getQueryBuilder(id));
  }, []);
  const showModal = () => {
    setSaveQuery(true);
  };

  useEffect(() => {
    if (pivots && pivots.length > 0) setPivotData(pivots);
  }, [pivots]);
  const [pivotData, setPivotData] = useState([]);

  const [pivotState, setPivotState] = useState({});

  const Plot = createPlotlyComponent(Plotly);

  const PlotlyRenderers = createPlotlyRenderers(Plot);
  var config = {
    responsive: true,
    showlegend: false,
    displaylogo: false,
    autosize: true,
  };
  return (
    <div className="answerAnalysisPage">
      <div
        style={{
          //   marginLeft: 50,
          //   marginRight: 50,
          //   marginBottom: 50,
          //   maxWidth: 500,
          overflowX: true,
        }}
      >
        {console.log("Sddsdsfd", pivotState)}
        <Space direction="vertical" size="large">
          <PivotTableUI
            data={pivotData}
            config={config}
            onChange={(s) => setPivotState(s)}
            unusedOrientationCutoff={Infinity}
            renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
            {...pivotState}
          />
          <Space direction="horizontal">
            <Button type="primary" onClick={showModal}>
              Save Query
            </Button>
            <Modal
              title="Basic Modal"
              open={saveQuery}
              onCancel={handleCancel}
              footer={[
                <Button key="back" onClick={handleCancel}>
                  Cancel
                </Button>,
              ]}
            >
              <Form
                form={form}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{
                  remember: true,
                  query_name: "",
                  query_description: "",
                }}
                onFinish={handlePivotSave}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Query Name"
                  name="query_name"
                  rules={[
                    { required: true, message: "Please input a query name!" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Query Description"
                  name="query_description"
                  rules={[
                    {
                      required: true,
                      message: "Please input a query description!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
            <Button type="primary" onClick={downloadFile}>
              Download Data
            </Button>
          </Space>
          {pivotTableData.length >= 1 ? (
            <Table columns={queryColumns} dataSource={pivotTableData} />
          ) : (
            ""
          )}
        </Space>
      </div>
    </div>
  );
};

export default QueryBuilder;
