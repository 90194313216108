import React, {useState, useEffect} from 'react';
import ShareLinkPage from './ShareLinkPage';
const origin = window.location.origin;
const SocialLink = (props) =>{
    const {header="Email Contacts"} = props;
    const iconName = header === "Email Contacts" ? "sendemailicon.svg" : "brokenLink.svg";
    return(
        <div className={`sendEmailBody`}>
            <div className="head">
                <div className="icon"><img src={origin+"/images/SVG/"+iconName} alt="link"/></div>
                {header}
            </div>
            <div className="sendEmailInner">
                <ShareLinkPage {...props}/>
            </div>
        </div>
    )
};

export default SocialLink;
