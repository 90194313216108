import { AiCallConstant } from "../constants/aicallConstant";
import { CrmService } from "../services";
import { AdminService } from "../services/AdminService";
import { customCallActions } from "./customCallActions";
import JsSIP from "jssip";

// store call log
async function StoreCallLog(phone_no) {
  return async (dispatch, getState) => {
    const callerData = await CrmService.getCustomerDataByNo("917654638762");

    console.log(callerData, "callerData");

    if (callerData?.status === 200) {
      const customer = callerData?.data;
      dispatch(
        customCallActions.StoreCallLogs(
          customer.id,
          "AI_Outbound",
          "Initiated",
          customer.first_phone
        )
      );
    }
  };
}

// get AudioFIle from API
async function getAIAudioStream(customer_audio, call_log_id) {
  const formData = new FormData();
  formData.append("file", customer_audio, "recording.webm"); // add UUID here
  formData.append("call_log_id", call_log_id);
  formData.append("start_time", new Date().toISOString());
  formData.append("end_time", new Date().toISOString());
  formData.append("sequence_number", 1);
  formData.append("response_type", "audio");
  formData.append("upload_file_uuid", "aaaaasssssdddddfffff");
  formData.append("download_file_uuid", "aaaaasssssdddddfffff");
  formData.append("speaker", "ai");

  const audio = await AdminService.aiAudioResponse(formData);

  // convert into mediastream & return

  return audio;
}

// get call_log_id

// pass it to AI call

// make AI call

function registerSIP() {
  // const SOCKET_URL = "https://testvocapp-ws.articence.com";
  const SIP_WS_URL = "wss://pbx.articence.com:8089/ws";

  const extension_number = "2004";
  const extension_password = "6c81bb7e12bb7d0486b7dba8c0ce5948";

  const SIP_URI = `sip:${extension_number}@pbx.articence.com`;
  const SIP_PASSWORD = extension_password;

  const sipSocket = new JsSIP.WebSocketInterface(SIP_WS_URL);

  const configuration = {
    sockets: [sipSocket],
    uri: SIP_URI,
    password: SIP_PASSWORD,
  };
  const ua = new JsSIP.UA(configuration);

  ua.on("connected", (e) => {
    console.log("WebSocket connected");
  });

  ua.on("disconnected", () => {
    console.log("WebSocket disconnected");
  });

  ua.on("registered", () => {
    console.log("Registered with SIP server");
  });

  ua.on("unregistered", () => {
    console.log("Unregistered from SIP server");
  });

  ua.on("registrationFailed", (event) => {
    console.error("Registration failed:", event);
  });

  ua.start();
  return ua;
}

function makeAiCall(customer, call) {
  const initiate = () => ({
    type: AiCallConstant.INITIATE_AI_CALL,
  });

  const success = (customer) => ({
    type: AiCallConstant.SUCCESS_AI_CALL,
    customer,
  });

  const failure = (error) => ({
    type: AiCallConstant.FAILED_AI_CALL,
    error,
  });

  return async (dispatch, getState) => {
    const state = getState();
    // const { id, phone_no } = customer;
    // const { incomingCall } = state.customCall;
    // const callId = call.remote_identity.uri.user;
    // await dispatch(StoreCallLog(phone_no));

    // const { callLogId } = state.customCall;

    const ua = registerSIP();

    dispatch(initiate());

    const eventHandlers = {
      progress: () => console.log("Call is in progress..."),
      failed: (e) => console.error("Call failed:", e),
      ended: () => console.log("Call ended."),
      confirmed: () => console.log("Call confirmed."),
    };

    const mediaConstraints = { audio: true, video: false };

    const phone_no = "011917654638762"

    // Create the call session
    const session = ua.call(`sip:${phone_no}@pbx.articence.com`, {
      eventHandlers,
      mediaConstraints,
    });

    console.log(session);

    const audioStream = getAIAudioStream();
  };
}

//  Capture Customer's Voice
function startCaptureVoice() {
  return async (dispatch, getState) => {
    const state = getState();
    const { session } = state.customCall;

    const sessionRef = session?.current?.connection;

    if (sessionRef) {
      const remoteStream = new MediaStream();

      sessionRef.getReceivers().forEach((receiver) => {
        if (receiver.track && receiver.track.kind === "audio") {
          remoteStream.addTrack(receiver.track);
        }
      });

      if (remoteStream.getTracks().length === 0) {
        console.warn("No audio tracks available for recording.");
        return;
      }

      try {
        const remoteMediaRecorder = new MediaRecorder(remoteStream, {
          mimeType: "audio/webm",
        });

        const audioChunks = [];
        remoteMediaRecorder.ondataavailable = (event) => {
          if (event.data && event.data.size > 0) {
            audioChunks.push(event.data);
          }
        };

        remoteMediaRecorder.start(1000); // Record chunks every 1 second

        // Save MediaRecorder and audioChunks to session
        session.current.mediaRecorder = remoteMediaRecorder;
        session.current.audioChunks = audioChunks;

        console.log("MediaRecorder started:", remoteMediaRecorder);
      } catch (error) {
        console.error("Failed to start MediaRecorder:", error);
      }
    } else {
      console.error("No session connection available for capturing voice.");
    }
  };
}

function stopCaptureVoice() {
  return async (dispatch, getState) => {
    const state = getState();
    const { session } = state.customCall;
    const { customerVoiceCaptureStartTime } = state.customCall;
    if (session?.current?.mediaRecorder) {
      const mediaRecorder = session.current.mediaRecorder;
      const audioChunks = session.current.audioChunks;

      if (mediaRecorder.state !== "inactive") {
        return new Promise((resolve, reject) => {
          mediaRecorder.onstop = () => {
            console.log("MediaRecorder stopped");

            if (audioChunks.length === 0) {
              console.error("No audio chunks recorded.");
              reject(new Error("No audio chunks recorded"));
              return;
            }

            // Combine audio chunks into a single Blob
            const audioBlob = new Blob(audioChunks, { type: "audio/webm" });

            // Clear MediaRecorder and chunks from session
            session.current.mediaRecorder = null;
            session.current.audioChunks = null;

            resolve(audioBlob, customerVoiceCaptureStartTime);
          };

          // Stop the MediaRecorder
          mediaRecorder.stop();
          console.log("Stopping MediaRecorder...");
        });
      } else {
        console.warn("MediaRecorder is not active.");
      }
    } else {
      console.error("MediaRecorder not found in session.");
    }
  };
}

export const AiCallActions = {
  makeAiCall,
};
