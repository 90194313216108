import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { workspaceActions } from "../../../actions";

import { useParams } from "react-router";
import { ANALYSE_RESULTS_MENU } from "../../Common/Constants";
import "./style.css";

const AnalyseResultsMenu = ({ active, setActive }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    id && dispatch(workspaceActions.getWsDetailWithAuth(id));
  }, []);

  const { loading, res } = useSelector((state) => state.wsDetailsAuth);

  const onClickRow = (active) => {
    setActive(active);
  };

  return (
    <div className="analyseResultsMenu">
      <ul>
        {res?.survey_type === "external" || res?.survey_type === "historical"
          ? ANALYSE_RESULTS_MENU.filter((x) => x.name !== "AudienceAnalysis")
              .filter((x) => x.name !== "SurveyEffectiveness")
              .filter((x) => x.name !== "LexicalDataAnalysis")
              .filter((x) => x.name !== "LexicalAudienceAnalysis")
              .filter((x) => x.name !== "LexicalEffectiveness")
              .map((element, index) => {
                const isActive = active.name === element.name;
                return (
                  <li
                    key={index}
                    className={isActive ? "active" : "deactive"}
                    onClick={() => onClickRow(element)}
                  >
                    {element.label}
                  </li>
                );
              })
          : null}
        {res?.survey_type === "internal"
          ? ANALYSE_RESULTS_MENU.map((element, index) => {
              const isActive = active.name === element.name;
              return (
                <li
                  key={index}
                  className={isActive ? "active" : "deactive"}
                  onClick={() => onClickRow(element)}
                >
                  {element.label === "Survey Effectiveness" &&
                  window.location.href.includes("form")
                    ? "Response Effectiveness"
                    : element.label}
                </li>
              );
            })
          : null}
      </ul>
    </div>
  );
};

export default AnalyseResultsMenu;
