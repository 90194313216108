import React, { useState } from 'react';
import cn from 'classnames';
import { TextField, FormHelperText, FormControl, MenuItem, InputLabel, Switch, Input, InputAdornment, CircularProgress} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Label from './Label';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const MaterialSelect = ({ config: { menuItems = [], isMenuWithContent, label, onChange, fieldClass, name, value, isError, error, variant="standard",size="" }, } ) => {

    const menuItem = menuItems.map((item, index) => {
        if (isMenuWithContent) {
            const { id, value, label, icon, iconClass, labelClass } = item;
            return <MenuItem key={index} value={value} className="typeListItem">
                {icon ? <img className={iconClass} src={icon} /> : null} {<Label config={{label, className: labelClass}}/>}
            </MenuItem>
        }
        return <MenuItem key={index} value={item} className="typeListItem">{item}</MenuItem>
    });

    return <div className={fieldClass}>
        <FormControl variant={variant} sx={{ m: 1, minWidth: 581.5 }}>
            <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
            <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={onChange}
                label={label}
                name={name}
                value={value}
                size={size}
                className="answerFormControl"
            >
                {menuItem}
            </Select>
            {isError && <FormHelperText id="component-error-text">{error}</FormHelperText>}
        </FormControl>
    </div>
};

const MaterialInput = ({ config: { label, name, onBlur, fieldClass,onKeyPress, value: oldValue, isError, error } }) => {

    const [value, setValue] = useState(oldValue)
    const onChange = (e) => {
        const { target: { name, value } = {} } = e;
        setValue(value);
    }

    return <div className={fieldClass}>
        <TextField id="standard-basic" label={label} name={name} value={value} onChange={onChange} onKeyPress={onKeyPress} variant="standard" onBlur={onBlur} helperText={isError ? error : ''} />
    </div>
};

const Toggle = ({ config: { value, name, handleValue, className } }) => {

    const handleChange = (e) => {
        const { target: { name, checked } = {} } = e;
        handleValue(checked, name);
    };

    return <div>
        <Switch name={name} className={cn(className, 'toggle')} checked={value} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
    </div>
};

const SearchInput = ({name, value, onChange, onSearch, className, variant="standard", placeholder, placeholderStyle, onKeyPress}) => {
    return <div className={className}>
        <FormControl sx={{ m: 1, width: '150px' }} variant={variant} >
        <Input
    id="standard-adornment-password"
    placeholder={placeholder}
    type='text'
    name={name}
    value={value}
    onChange={onChange}
    className="questionSearchInput"
    endAdornment={
        <InputAdornment position="end">
        <SearchOutlinedIcon className={"searchIcon"} style={{cursor:'pointer'}} onClick={()=>onSearch(value)}/>
    </InputAdornment>
}
    inputProps={{
        sx: {
            "&::placeholder": placeholderStyle
        },
        onKeyPressCapture: onKeyPress
    }}
    />
    </FormControl>
    </div>
}

const Spinner = ({color, className}) => <div className={className}> <CircularProgress sx={{color}} /></div>

export { MaterialSelect, MaterialInput, Toggle, SearchInput, Spinner }
