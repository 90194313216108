import {useState, useEffect} from 'react'
import { getUser } from '../../../../helpers';
import { useDispatch } from "react-redux";
import { getAccessToken } from '../../../../components/Email_Integration/Outlook';
import { crmActions, workspaceActions } from '../../../../actions';
// import { workspaceService } from '../../../../../services/workspaceService';
import { workspaceService } from "../../../../services";
import EmailBody from './Emailbody';
import Dialogbox from '../../../Common/DialogBox';
import { setLocalStorage, getLocalStorage } from "../../../Common/Utils";
import Authenticate from '../../../Common/Auth/Authenticate';
// import getLocalStorage from '../../../../helpers/getLocalStorage';
import useSocialAuth from '../../../Common/Auth/useSocialAuth';

const ComposeByOutlook = ({
  surveyID,
  show,
  isSchedule,
  handleClose,
  records = [],
  emailType,
}) => {

  const auth = getLocalStorage("auth") || {};
  const [body, setBody] = useState({ subject: "", content: "" });
  const [token, setToken] = useState(auth.accessToken || "");
  const dispatch = useDispatch();
  const userDetails = getUser();
  const { initiateAuth } = useSocialAuth();

  useEffect(() => {
    if (userDetails?.social_type === "outlook") {
      getAccessToken((res, err) => {
        if (res) {
          setToken(res.accessToken);
        }
      });
    }
  }, []);
  const reset = () => {
    setBody({ subject: "", content: "" });
  };
  const onChange = (e) => {
    const { name = "", value = "" } = e && e.target ? e.target : {};
    setBody((body) => ({ ...body, [name]: value }));
  };

  const sendEmail = async () => { 
    try {

      const user = getUser();
      
      if (user?.social_type === "outlook") {
        // If user is Outlook and no token, trigger authentication
        await initiateAuth("outlook");
        return;
      }

      const timezoneOffset = new Date().getTimezoneOffset() / 60; 
      const params = {
        // survey_id: surveyID,
        access_token: token,
        email_type: emailType ? emailType : "",
        subject: body.subject,
        body: body.content,
        sender_email: auth.email || "anon@anonymous.com",
        sender_name: auth.name || "",
        records: records,
        schedule: body.schedule,
        interval: body.interval,
        timezone: timezoneOffset,
      };
      dispatch(crmActions.sendEmail(params));
      reset();
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <Dialogbox
      open={show}
      rootClass={"addLogoDialog addPageTitleDialog textEditorDialog"}
      handleClose={handleClose}
      dialogContentClass={"pageTitleDialogInner"}
    >
      <EmailBody
        body={body}
        onChange={onChange}
        handleClose={handleClose}
        setBody={setBody}
        sendEmail={sendEmail}
        isSchedule={isSchedule}
      />
    </Dialogbox>
  );
};

export default ComposeByOutlook