import React, {useState, useEffect, useRef} from 'react';
import {LINKS, SURVEY_BG_THEME} from '../Common/Constants';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from 'react-redux';
import { workspaceActions } from '../../actions/index';
import { history } from '../../helpers';
import {base64Image} from "../Common/Utils";

const Format = ({data, setData, setSubmitted, rightMenuActive}) =>{
    const [background,setBackground] = React.useState({});
    const [themes,setThemes] = React.useState(false);
    const [isSubmittable, setSubmittable] = React.useState(true);
    const [disableCookies, setCookiesState] = React.useState(false);
    const {loading=false} = useSelector(state => state.quesDesc);
    const [img, setImg] = useState("");
    const  [err, setErr] = useState("");
    const dispatch = useDispatch();
    const fileInput = useRef(null)

    useEffect(()=> {
        if(data?.survey_bg_theme?.length){
            if(data.survey_bg_theme.includes('base64')){
                setImg(data.survey_bg_theme);
                setBackground({'custom':true });
            } else{
                setBackground({ [data.survey_bg_theme]: true });
            }
        }
        setSubmittable(data?.is_submittable);
        if(data.hasOwnProperty("cookies")){
            setCookiesState(!data?.cookies);
        }

    }, [data]);

    const onSelectBg = (e, item)=>{
        setBackground({[item]:true});
        setData(data => ({ ...data, 'survey_bg_theme': item}));
        save('survey_bg_theme', item);
    }
    const onHandleTheme = ()=>{
        setThemes(!themes);
    }
    const onClickHandle = (e, item)=>{
        if(item === "Conversation"){
            history.push(LINKS.help_conversational_AI);
            return;
        }
        setData(data => ({ ...data, format: item }));
        save('format', item);
    };

    const save = (name, val) => {
        setSubmitted && setSubmitted(true);
        dispatch(workspaceActions.createQuestDesc({...data, [name]: val}));
    };

    const onCheckboxChange = (e) => {
        if (e && e.target) {
            setSubmittable(e.target.checked);
            setData(data => ({ ...data, "is_submittable": e.target.checked }));
            save("is_submittable", e.target.checked);
        }
    };

    const cookiesHandler = (e) => {
        if (e && e.target) {
            setCookiesState(e.target.checked);
            setData(data => ({ ...data, "cookies": !e.target.checked }));
            save("cookies", !e.target.checked);
        }
    };

    const bgThemes = () => {
        let bg = [];
        for(const key in SURVEY_BG_THEME.small){
            const v = SURVEY_BG_THEME.small[key];
            const t = <div key={key} className={`item ${background && background[key] ? "active":""}`} onClick={(e)=>onSelectBg(e,key)}><img src={origin+"/images/themes/"+v} alt="icon"/><CheckIcon/></div>
            bg.push(t);
        }
        return bg;
    };

    const  themeHandler = (e) => {
        const imageSize = 1000000;
        if(e?.target?.files?.length ){
            const file = e.target.files[0];
            if(file.type.includes("jpeg") || file.type.includes("png") || file.type.includes("jpg")) {
    
                if (file.size <= imageSize) {
                    setErr(false);
                    base64Image(file, (result) => {
                        setImg(result);
                    });
                } else {
                    setErr("Image size should be less than or equal to 1MB");
                }
            } else {
                setErr("Uploaded file should be jpg or png");
            }
        }
    };
    
    const chooseTheme = () => {
      background['custom'] && setBackground({"custom": false})
      fileInput.current.click();
    };
    
    const onCancelTheme = (e) => {
      e && e.stopPropagation();
      setImg("");
    };

    return(
        <div className={"formatWrapper"} ref={rightMenuActive}>
            <div className="head">Form Format
                <div className='surveyMenuCloseBtn'>
                    <button onClick={()=>rightMenuActive.current.classList.toggle('menu-active')}>
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_2441_8773)">
                            <circle cx="9.5" cy="8.5" r="7.5" fill="#18A0FB"/>
                            </g>
                            <g clipPath="url(#clip0_2441_8773)">
                            <path d="M13.6253 3.91797H5.37533C5.25377 3.91797 5.13719 3.96626 5.05123 4.05221C4.96528 4.13817 4.91699 4.25474 4.91699 4.3763V12.6263C4.91699 12.7479 4.96528 12.8644 5.05123 12.9504C5.13719 13.0363 5.25377 13.0846 5.37533 13.0846H13.6253C13.7469 13.0846 13.8635 13.0363 13.9494 12.9504C14.0354 12.8644 14.0837 12.7479 14.0837 12.6263V4.3763C14.0837 4.25474 14.0354 4.13817 13.9494 4.05221C13.8635 3.96626 13.7469 3.91797 13.6253 3.91797ZM10.417 12.168H5.83366V4.83464H10.417V12.168ZM13.167 12.168H11.3337V4.83464H13.167V12.168Z" fill="white"/>
                            </g>
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.44009 9.27037C8.51997 9.18815 8.51997 9.05486 8.44009 8.97264L7.49382 7.9987L8.44009 7.02476C8.51997 6.94254 8.51997 6.80924 8.44009 6.72703C8.36021 6.64481 8.2307 6.64481 8.15082 6.72703L7.05991 7.84983C6.98003 7.93205 6.98003 8.06535 7.05991 8.14756L8.15082 9.27037C8.2307 9.35259 8.36021 9.35259 8.44009 9.27037Z" fill="white" stroke="white" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <defs>
                            <filter id="filter0_d_2441_8773" x="0" y="0" width="19" height="19" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="1"/>
                            <feGaussianBlur stdDeviation="1"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2441_8773"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2441_8773" result="shape"/>
                            </filter>
                            <clipPath id="clip0_2441_8773">
                            <rect width="11" height="11" fill="white" transform="translate(4 3)"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </button>
                </div>
            </div>
            {/* loading ? <div style={{position: 'absolute', top: 18, right: 15}}>saving...</div> : null*/}
            <div className="backTheme">
                <button className={`btn ${themes ? "active":""}` } onClick={onHandleTheme}>
                    Background Theme
                    <KeyboardArrowRightIcon/>
                </button>
                {themes && <div className="chooseBgTheme bgThemes">
                        <button className='uploadBtn' onClick={chooseTheme}> Choose Theme</button>
                        <input type="file"  ref={fileInput} style={{display:"none"}}  onChange={themeHandler} accept={'image/x-png, image/jpeg'}/>
                        <div className={`item uploadedImg ${background && background['custom'] ? "active" : ""}`}>
                        <div style={img.length ? {} : {height: 75, border:'1px solid #ccc'}} onClick={(e) => onSelectBg(e, img.length ? img : 'blank', 'custom')}>
                            {img.length ? <img src={img} alt="upload icon" />: <div className="noTheme">No Theme</div>}
                            <CheckIcon />
                            {img.length ? <div className="cancelBtn" onClick={onCancelTheme}> x </div>:null}
                        </div>
                        </div>
                        {err.length ? <p style={{color:'red', fontWeight:'normal'}}>{err}</p> : null }
                    </div>
                }
                { themes && <div className="bgThemes"> { bgThemes() } </div> }

            </div>
            <div className="questionTheme"  style={{ borderBottom: "1px solid #AEB6CF" }}>
                <div className={`item ${data.format=="Classic" ?"active":""}`} onClick={(e)=>onClickHandle(e,"Classic")}>
                    <div className="imageWrap">
                        <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1" width="26.3784" height="28" rx="1" stroke="#18A0FB" strokeWidth="2"/>
                            <circle cx="5.27019" cy="7.70281" r="2.02703" fill="#18A0FB"/>
                            <circle cx="5.27019" cy="15.0002" r="2.02703" fill="#18A0FB"/>
                            <circle cx="5.27019" cy="22.2975" r="2.02703" fill="#18A0FB"/>
                            <line x1="9.41895" y1="7.60791" x2="24.6352" y2="7.60791" stroke="#18A0FB" strokeLinecap="round"/>
                            <line x1="9.41895" y1="14.9053" x2="24.6352" y2="14.9053" stroke="#18A0FB" strokeLinecap="round"/>
                            <line x1="9.41895" y1="22.2026" x2="24.6352" y2="22.2026" stroke="#18A0FB" strokeLinecap="round"/>
                        </svg>
                        {/*<img src={origin+"/images/SVG/multip-question.svg"} alt="multiple-question"/>*/}
                    </div>
                    <h3>Classic</h3>
                    <p>Show all elements on a page at a once</p>
                </div>
                <div className={`item ${data.format=="SingleQuestion" ?"active":""}`}  onClick={(e)=>onClickHandle(e,"SingleQuestion")}>
                    <div className="imageWrap">
                        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="6" y="15" width="33" height="16" rx="1" stroke="#AEB6CF" strokeWidth="2"/>
                            <circle cx="11.5" cy="23.5" r="2.5" fill="#AEB6CF"/>
                            <line x1="16.5" y1="23.5" x2="35.5" y2="23.5" stroke="#AEB6CF" strokeLinecap="round"/>
                        </svg>
                        {/*<img src={origin+"/images/SVG/one-question.svg"} alt="one-question"/>*/}
                    </div>
                    <h3>One element at a time</h3>
                    <p>Show one element on a page at a once</p>
                </div>
                <div className={`item ${data.format=="Conversation" ?"active":""}`}  onClick={(e)=>onClickHandle(e,"Conversation")}>
                    <div className="beta">Beta</div>
                    <div className="imageWrap">
                        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.1848 19.8464C15.1406 19.8464 14.2941 20.7091 14.2941 21.7733C14.2941 22.8375 15.1406 23.7002 16.1848 23.7002C17.229 23.7002 18.0755 22.8375 18.0755 21.7733C18.0755 20.7091 17.229 19.8464 16.1848 19.8464Z" fill="#AEB6CF"/>
                            <path d="M22.2351 19.8464C21.1909 19.8464 20.3444 20.7091 20.3444 21.7733C20.3444 22.8375 21.1909 23.7002 22.2351 23.7002C23.2794 23.7002 24.1259 22.8375 24.1259 21.7733C24.1259 20.7091 23.2794 19.8464 22.2351 19.8464Z" fill="#AEB6CF"/>
                            <path d="M26.3948 21.7733C26.3948 20.7091 27.2413 19.8464 28.2855 19.8464C29.3297 19.8464 30.1762 20.7091 30.1762 21.7733C30.1762 22.8375 29.3297 23.7002 28.2855 23.7002C27.2413 23.7002 26.3948 22.8375 26.3948 21.7733Z" fill="#AEB6CF"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M28.4379 12.282C24.3627 11.9278 20.2662 11.9072 16.1877 12.2205L15.8955 12.243C12.0061 12.5417 9 15.8449 9 19.8198V32.9491C9 33.3559 9.20974 33.7327 9.55232 33.9414C9.89489 34.1502 10.3199 34.1601 10.6715 33.9675L16.5869 30.7277C16.862 30.577 17.1696 30.4982 17.4819 30.4982H31.0595C32.7719 30.4982 34.2394 29.2505 34.5464 27.5336C35.1685 24.0541 35.2176 20.4933 34.6918 16.9973L34.5368 15.9671C34.2547 14.0915 32.7491 12.6567 30.8939 12.4954L28.4379 12.282ZM16.3583 14.5262C20.3157 14.2222 24.2907 14.2422 28.2451 14.5858L30.7011 14.7993C31.5124 14.8698 32.1708 15.4973 32.2941 16.3175L32.4491 17.3477C32.9367 20.5899 32.8912 23.8922 32.3142 27.1192C32.2038 27.737 31.6757 28.1859 31.0595 28.1859H17.4819C16.7947 28.1859 16.1182 28.3594 15.5128 28.691L11.2689 31.0153V19.8198C11.2689 17.0545 13.3602 14.7565 16.066 14.5487L16.3583 14.5262Z" fill="#AEB6CF"/>
                        </svg>
                        {/* <img src={origin+"/images/SVG/conversation.svg"} alt="conversation"/>*/}
                    </div>
                    <h3>Conversation</h3>
                    <p>Show all elements on a page at a once</p>
                </div>
            </div>
            <div className="surveyTime">
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={isSubmittable} onChange={onCheckboxChange} inputProps={{ 'aria-label': 'controlled' }} />}
                        label="Submittable Form"
                        className="checkbox-autoSubmit"
                    />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={disableCookies} onChange={cookiesHandler} inputProps={{ 'aria-label': 'controlled' }} />}
                        label="Disable Cookies"
                        className="checkbox-autoSubmit cookiesLabel"
                    />
                    <p className="cookiesSubLabel">(State, City and IP won't be stored of the response user.)</p>
                </FormGroup>
            </div>
        </div>
    )
};

export default Format;
