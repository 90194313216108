export const teamsConstants = {
    // get all teams
    GET_ALL_TEAMS_REQUEST: "GET_ALL_TEAMS_REQUEST",
    GET_ALL_TEAMS_SUCCESS: "GET_ALL_TEAMS_SUCCESS",
    GET_ALL_TEAMS_FAILURE: "GET_ALL_TEAMS_FAILURE",
  
    // add teams
    ADD_TEAMS_REQUEST: "ADD_TEAMS_REQUEST",
    ADD_TEAMS_SUCCESS: "ADD_TEAMS_SUCCESS",
    ADD_TEAMS_FAILURE: "ADD_TEAMS_FAILURE",
    RESET_TEAM_ADDED: "RESET_TEAM_ADDED",
  
    // edit teams
    EDIT_TEAMS_REQUEST: "EDIT_TEAMS_REQUEST",
    EDIT_TEAMS_SUCCESS: "EDIT_TEAMS_SUCCESS",
    EDIT_TEAMS_FAILURE: "EDIT_TEAMS_FAILURE",
  
    // delete teams
    DELETE_TEAMS_REQUEST: "DELETE_TEAMS_REQUEST",
    DELETE_TEAMS_SUCCESS: "DELETE_TEAMS_SUCCESS",
    DELETE_TEAMS_FAILURE: "DELETE_TEAMS_FAILURE",
};
