import React, {useEffect, useState, useRef} from "react";
import { PreviewDialog } from '../Survey/Preview/Preview';
import { useDispatch, useSelector } from "react-redux";
import {workspaceActions} from '../../actions';
import { PreviewDialog as FormPreviewDialog } from "../Form/Preview/Preview";
const SurveyPreview = ({id, show, handleClose,type="survey"}) => {
    const dispatch = useDispatch();
    useEffect(()=>{
        if(id && !window.ismounted) {
            window.ismounted = true;
            dispatch(workspaceActions.getWsDetailWithAuth(id));

            setTimeout(()=>{window.ismounted = false}, 2000)
        }
    }, [id]);
    return type==="form" ? <FormPreviewDialog show={show} handleClose={handleClose} surveyID={id} isPreview={true} /> :
     <PreviewDialog show={show} handleClose={handleClose} surveyID={id} isPreview={true} />
};

export default SurveyPreview;