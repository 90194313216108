import React, {useEffect, useState} from 'react';
import {useGoogleLogin} from 'react-google-login'
import axios from 'axios';
import Dialogbox from "../../Common/DialogBox";
import { CLIENT_ID, API_KEY, SCOPES, DISCOVERY_DOCS } from '../../Common/Constants';
import {getAccessToken} from '../../../components/Email_Integration/Outlook';
import {getUser} from '../../Common/Utils';
import useFetch from '../../../components/Email_Integration/FetchGraphApi';
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import Alert from '@mui/material/Alert';

const EmailContacts = (props) => {
    const usr = getUser();
    if(usr && usr.social_type === 'gmail') {
        return <ImportGmailContacts {...props}/>
    } if(usr && usr.social_type === 'outlook') {
        return <ImportOutlookContacts {...props}/>
    }else{
        alert('Login with social chanel.')
    }
};


const loadGoogleScript = () => {
    //loads the Google JavaScript Library
    (function () {
        const id = 'google-js';
        const src = 'https://apis.google.com/js/platform.js';

        //we have at least one script (React)
        const firstJs = document.getElementsByTagName('script')[0];

        //prevent script from loading twice
        if (document.getElementById(id)) { return; }
        const js = document.createElement('script');
        js.id = id;
        js.src = src;
        js.onload = window.onGoogleScriptLoad;
        firstJs.parentNode.insertBefore(js, firstJs);
    }());

};

const ImportGmailContacts = ({onClose, onOK}) => {
    const [gAuth, setGoogleAuth] = useState();
    const [gapi, setGapi] = useState();
    const [accessToken, setAccessToken] = useState();
    const [processing, setPrcessing] = useState(false);
    const [err, setErr] = useState('');

    useEffect(() => {
        //window.gapi is available at this point
        window.onGoogleScriptLoad = () => {
            const _gapi = window.gapi;
            setGapi(_gapi);
            _gapi.load('auth2', () => {
                (async () => {
                    const _googleAuth = await _gapi.auth2.init({
                        client_id: CLIENT_ID,
                        api_key: API_KEY,
                        discoveryDocs: DISCOVERY_DOCS,
                        scope: SCOPES
                    });

                    console.log('_googleAuth', _googleAuth);

                    setGoogleAuth(_googleAuth);
                })();
            });

        };
        //ensure everything is set before loading the script
        loadGoogleScript();

    });
    useEffect(()=> {
        gAuth && getToken();
    }, [gAuth]);

    const getToken = () => {
        try {

            let googleA= window.gapi.auth2.init();

            let googleUser = googleA.currentUser.get();
            let authResp = googleUser.getAuthResponse(true);

            if(authResp){
                let access_token = authResp["access_token"];
                setAccessToken(authResp["access_token"]);
            }
        } catch(e) {
            console.log('Access token not fond', e);
            processing && setErr('Access token not fond, try again.');
        }
        setPrcessing(false);
    };

    const onSuccess = (res) => {
        if(res?.accessToken){
            const {accessToken, profileObj={}} = res;
            setAccessToken(accessToken);
            setTimeout(()=>{importHandler(accessToken)}, 100);
        }
    };
    const onFailure = (res) => {
        console.log('onFailure ', res);
    };

    const {signIn} = useGoogleLogin({
        onSuccess,
        onFailure,
        clientId: CLIENT_ID,
        isSignedIn: false,
        accessType: 'offline',
    });

    const importHandler = (token) => {
        setPrcessing(true);
        if(!accessToken && !token){
            try{
                signIn();
            }catch(e){
                console.log("--err-->> ",e);
            }
            return;
        }
        //axios.get('https://people.googleapis.com/v1/otherContacts', {
        axios.get('https://people.googleapis.com/v1/people/me/connections', {
            params:{
                //readMask: 'names,emailAddresses,phoneNumbers'
                personFields: 'names,emailAddresses,phoneNumbers',
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + (token || accessToken),

            },})
            .then(respond => {
                const contacts = retrieveContacts(respond);
                onOK && onOK(contacts);
                setPrcessing(false);
            })
            .catch(err=> {
                setErr('Something went wrong, Try again.');
                setPrcessing(false);
            });
    };

    const retrieveContacts = (res={}) => {
        const arr = [];
        const connections = res?.data?.connections || res?.data?.otherContacts;
        if (connections) {
            connections.forEach(person => {
                if (person.emailAddresses && person.emailAddresses.length > 0 && person.names && person.names.length > 0) {
                    const {displayNameLastFirst} = person.names[0];
                    const {value} = person.emailAddresses[0];
                    const phone = (person.phoneNumbers?.length)? person.phoneNumbers[0] : {value:''}
                    const name = displayNameLastFirst.split(',');
                    arr.push({
                        email: value,
                        "first name": name.length > 1 ? name[1] : name[0],
                        "last name": name.length > 1 ? name[0] : '',
                        phone: phone.value
                    });
                }
                /*else {
                    setErr("No display name found for connection.");
                }*/
            });
        } else {
            setErr("No connections found.");
        }
        return arr;
    };

    return <Dialog onClose={onClose} onOK={onOK} err={err} setErr={setErr} importHandler={importHandler} processing={processing}/>
};

const Dialog = ({onClose, onOK, err, setErr, importHandler, processing, isOutlook=false}) => {
    const iconStyle = processing ? {pointerEvents:'none', opacity:'0.6'} : {cursor:'pointer'};
    const heading = isOutlook ? 'Click to allow your Outlook contacts' : 'Click to allow your Gmail contacts';
    const logoUrl = isOutlook ? '/images/msLogo.png' : '/images/googleLogo.png';
    return(
        <Dialogbox open={true} handleClose={onClose} handleOk={onOK} dialogContentClass={'uploadDialogInner'} rootClass="uploadDialog">
            {err.length ? <Alert variant="outlined" severity="error" onClose={() => {setErr('')}}>{err}</Alert> : null}
            <div className="closeDailogNew" onClick={onClose}><HighlightOffTwoToneIcon/></div>
            <div className="icon" onClick={()=>importHandler()} style={iconStyle}><img src={logoUrl}/></div>
            <h3>{heading}</h3>
            <p>Click or drag email list to this area to upl lipsum as it is sometimes known, is dummy text used in laying out
                print, graphic or web designs. The passage is attributed to an unknownoad.</p>
        </Dialogbox>
    );
};

const ImportOutlookContacts = ({onClose, onOK}) => {
    const [accessToken, setAccessToken] = useState('');
    const [err, setErr] = useState('');
    const [loading, setLoading] = useState(false);
    const url = 'https://graph.microsoft.com/v1.0/me/contacts';
    useEffect(()=> {
        getAccessToken((res, err)=>{
            if(res){
                setAccessToken(res.accessToken);
            } else {
                setErr('Access token not valid');
            }
        });
    }, []);

    const importHandler = () => {
        setLoading(true);
        const headers = {'Authorization': ('Bearer ' + accessToken)};
        fetch(url, { headers })
            .then((res) => res.json())
            .then(({value=[]}) => {
                const contactList = contactBuilder(value);
                onOK && onOK(contactList);
                setLoading(false);
            }).catch(err=>{
                setLoading(false);
                setErr('Something went wrong, try again.');
        });
    };

    const contactBuilder = (list) => {
        const contactList = [];
        list.forEach(contact => {
            const {displayName="", emailAddresses=[], mobilePhone } = contact;
            if(displayName.length && emailAddresses.length){
                const name = displayName.split(" ");
                contactList.push({
                    email: emailAddresses[0].address,
                    "first name": name[0],
                    "last name": name.length > 1 ? name[1] : '',
                    phone: mobilePhone
                });
            }
        });
        return contactList;
    };

    return <Dialog onClose={onClose} onOK={onOK} err={err} setErr={setErr} importHandler={importHandler} processing={loading} isOutlook={true}/>

};

export default EmailContacts;