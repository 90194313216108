export const delegationConstants = {
    // get all DELEGATION
    GET_ALL_DELEGATION_REQUEST: "GET_ALL_DELEGATION_REQUEST",
    GET_ALL_DELEGATION_SUCCESS: "GET_ALL_DELEGATION_SUCCESS",
    GET_ALL_DELEGATION_FAILURE: "GET_ALL_DELEGATION_FAILURE",
  
    // add DELEGATION
    ADD_DELEGATION_REQUEST: "ADD_DELEGATION_REQUEST",
    ADD_DELEGATION_SUCCESS: "ADD_DELEGATION_SUCCESS",
    ADD_DELEGATION_FAILURE: "ADD_DELEGATION_FAILURE",
    RESET_DELEGATION_ADDED: "RESET_DELEGATION_ADDED",
  
    // edit DELEGATION
    EDIT_DELEGATION_REQUEST: "EDIT_DELEGATION_REQUEST",
    EDIT_DELEGATION_SUCCESS: "EDIT_DELEGATION_SUCCESS",
    EDIT_DELEGATION_FAILURE: "EDIT_DELEGATION_FAILURE",
    RESET_DELEGATION_UPDATED : "RESET_DELEGATION_UPDATED",
  
    // delete DELEGATION
    DELETE_DELEGATION_REQUEST: "DELETE_DELEGATION_REQUEST",
    DELETE_DELEGATION_SUCCESS: "DELETE_DELEGATION_SUCCESS",
    DELETE_DELEGATION_FAILURE: "DELETE_DELEGATION_FAILURE",
  };