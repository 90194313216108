import { useEffect, useState, useRef } from "react";
import cn from "classnames";
import { ChartComponent, DoughnutComponent } from "../Chart";
import Label from "../../../../common/controls/inputs/Label";
import Notes from "./Notes";
import { surveyActions, workspaceActions } from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "../../../Common/Backdrop";
import Highlight from "highlight-react/dist/highlight";
import { Pie } from "react-chartjs-2";
import autocolors from "chartjs-plugin-autocolors";
import MuiTooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ANS_TYPE } from "../../../Common/Constants";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useParams } from "react-router";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  autocolors,
  Tooltip,
  Legend
);
const AnswersAnalysis = ({ isFormType }) => {
  const [barShowloader, setBarShowLoader] = useState(false);
  const chartRef = useRef(null);
  const { id } = useParams();
  const { res } = useSelector((state) => state.wsDetailsAuth);
  const getFormattedLabel = (val) => {
    let label = "";
    ANS_TYPE.forEach((e) => {
      if (e.value === val) {
        label = e.label;
      }
    });
    return label;
  };
  const onClick = (event) => {
    setBarShowLoader(true);
    setTimeout(() => {
      setBarShowLoader(false);
    }, [2000]);
    document.location.href = "#answerResponseSection";
    if (chartRef.current) {
      const chart = ChartJS.getChart(chartRef.current);
      const clickedElements = chart.getElementsAtEventForMode(
        event,
        "y",
        { axis: "x", intersect: false },
        true
      );
      if (clickedElements.length > 0) {
        var label = chart.data.labels[clickedElements[0].index];

        setselectedtext(label);
        getSummarizedDataByLabel(label);
      }
    }
  };

  const dispatch = useDispatch();
  const customWidth = useRef();
  const aiFilter = useSelector((state) => state.survey.aiFilter);
  const [categorization, setCategorization] = useState("");
  const longSurveyYear = useSelector((state) => state.survey.longSurveyYear);
  const longSurveyMonth = useSelector((state) => state.survey.longSurveyMonth);
  const surveyYear = useSelector((state) => state.survey.surveyYear);
  const surveyMonth = useSelector((state) => state.survey.surveyMonth);
  const getDropList = () => {
    const year = new Date().getFullYear() - 20;
    return Array.from(new Array(50), (v, i) => (
      <option key={i} value={year + i}>
        {year + i}
      </option>
    ));
  };
  const getSummarizedData = (text) => {
    var results;
    longTextSummary.data.forEach((e) => {
      e.sub_headings.forEach((val) => {
        if (val.text === text) {
          results = val.occurrence;
        }
      });
    });

    setoccurence(results);
  };
  const getSummarizedDataByLabel = (text) => {
    var results = [];

    let index = longTextSummary.data.findIndex((x) => x.text === text);
    for (let i = 0; i < longTextSummary.data[index].sub_headings.length; i++) {
      for (
        let j = 0;
        j < longTextSummary.data[index].sub_headings[i].occurrence.length;
        j++
      )
        results.push(longTextSummary.data[index].sub_headings[i].occurrence[j]);
    }

    let unique = [...new Set(results)];
    setoccurence(unique);
  };
  const getCount = (text) => {
    return text.split(selectedtext).length - 1;
  };

  const surveyData = useSelector((state) => state.survey.items);
  const surveyData1 = useSelector((state) => state.survey.longSurveyItems);
  const longTextSummary = useSelector((state) => state.survey.longTextSummary);

  const [longQuestionText, setLongQuestionText] = useState("");
  const [otherQuestionText, setOtherQuestionText] = useState("");

  const longSurveyData = useSelector((state) => state.survey.longSurveyItems);
  const longQuestionId = useSelector((state) => state.survey.longQuestionId);
  const otherQuestionId = useSelector((state) => state.survey.otherQuestionId);

  const questionData = useSelector(
    (state) => state.survey.questionAnalysisData
  );

  const getBarChartData = () => {
    const label = longTextSummary.data.map((x) => {
      return x.text;
    });
    let newData = [];
    const insert = (index, newItem) => {
      let newarr = [];
      for (let i = 0; i < index + 1; i++) {
        if (i === index) newarr.push(newItem);
        else newarr.push(0);
      }
      return newarr;
    };
    longTextSummary.data.map((x, j) => {
      x.sub_headings.map((k, i) => {
        newData.push({
          stack: x.text,
          data: insert(
            j,
            Math.max(
              k.positive,
              k.negative,
              k.neutral,
              k["very negative"],
              k["extremely negative"],
              k["very positive"],
              k["extremely positive"]
            )
          ),
          barPercentage:
            longTextSummary &&
            longTextSummary.data.length > 0 &&
            longTextSummary &&
            longTextSummary.data.length < 2
              ? 0.2
              : longTextSummary &&
                longTextSummary.data.length > 2 &&
                longTextSummary &&
                longTextSummary.data.length < 5
              ? 0.3
              : longTextSummary &&
                longTextSummary.data.length >= 5 &&
                longTextSummary &&
                longTextSummary.data.length < 7
              ? 1
              : longTextSummary &&
                longTextSummary.data.length >= 7 &&
                longTextSummary &&
                longTextSummary.data.length < 10
              ? 2
              : longTextSummary &&
                longTextSummary.data.length >= 10 &&
                longTextSummary &&
                longTextSummary.data.length < 13
              ? 3
              : longTextSummary &&
                longTextSummary.data.length >= 13 &&
                longTextSummary &&
                longTextSummary.data.length < 16
              ? 4
              : longTextSummary &&
                longTextSummary.data.length >= 16 &&
                longTextSummary &&
                longTextSummary.data.length < 19
              ? 5
              : longTextSummary &&
                longTextSummary.data.length >= 19 &&
                longTextSummary &&
                longTextSummary.data.length < 22
              ? 6
              : longTextSummary &&
                longTextSummary.data.length >= 22 &&
                longTextSummary &&
                longTextSummary.data.length < 25
              ? 7
              : longTextSummary && longTextSummary.data.length >= 25
              ? 8
              : undefined,

          label: k.text,
          borderColor: "black",
          borderWidth: 1,
          backgroundColor:
            Math.max(
              k.positive,
              k.negative,
              k.neutral,
              k["very negative"],
              k["extremely negative"],
              k["very positive"],
              k["extremely positive"]
            ) == k.positive
              ? "#AFE1AF"
              : Math.max(
                  k.positive,
                  k.negative,
                  k.neutral,
                  k["very negative"],
                  k["extremely negative"],
                  k["very positive"],
                  k["extremely positive"]
                ) == k["very positive"]
              ? "#50C878"
              : Math.max(
                  k.positive,
                  k.negative,
                  k.neutral,
                  k["very negative"],
                  k["extremely negative"],
                  k["very positive"],
                  k["extremely positive"]
                ) == k["extremely positive"]
              ? "#097969"
              : Math.max(
                  k.positive,
                  k.negative,
                  k.neutral,
                  k["very negative"],
                  k["extremely negative"],
                  k["very positive"],
                  k["extremely positive"]
                ) == k.negative
              ? "#EE4B2B"
              : Math.max(
                  k.positive,
                  k.negative,
                  k.neutral,
                  k["very negative"],
                  k["extremely negative"],
                  k["very positive"],
                  k["extremely positive"]
                ) == k["very negative"]
              ? "#AA4A44"
              : Math.max(
                  k.positive,
                  k.negative,
                  k.neutral,
                  k["very negative"],
                  k["extremely negative"],
                  k["very positive"],
                  k["extremely positive"]
                ) == k["extremely negative"]
              ? "#880808"
              : "#FFD300",
        });
      });
    });
    const data = {
      labels: label,
      datasets: newData,
      fill: false, // <-- Set `fill: false` here
    };
    return data;
  };
  const [selectedtext, setselectedtext] = useState("");
  const [showloader, setshowloader] = useState(false);
  const [doughnutData, setDoughnutData] = useState([]);
  const [doughnutLabel, setDoughnutLabel] = useState([]);

  const [pieChartData, setpieChartData] = useState([]);
  const [pieChartLabel, setpieChartLabel] = useState([]);

  const [chartData, setChartData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [defaultChecked, setDefaultChecked] = useState(0);
  const [occurence, setoccurence] = useState([]);
  const [chartLabel, setChartLabel] = useState([]);
  const [longDoughnutData, setLongDoughnutData] = useState([]);
  const [longDoughnutLabel, setLongDoughnutLabel] = useState([]);
  const [longChartData, setLongChartData] = useState([]);
  const [longChartLabel, setLongChartLabel] = useState([]);
  const [cxLoading, setCxLoading] = useState({ cx1: false, cx2: false });
  const [isNote, setNote] = useState(false),
    openNotes = () => {
      setNote(true);
      setCategorization("category");
    },
    openNotes1 = () => {
      setNote(true);
      setCategorization("longTextSummary");
    };
  const formatDate = (date) => {
    let arr_date = date.split("-");

    let months = [
      "",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[parseInt(arr_date[1])] + " " + arr_date[2];
  };

  useEffect(
    () => {
      setshowloader(true);
      setTimeout(() => {
        setshowloader(false);
      }, [5000]);
    },
    [
      // surveyData,
      // surveyMonth,
      // surveyYear,
      // longSurveyData,
      // longSurveyMonth,
      // longSurveyYear,
      // longTextSummary,
      // questionData,
    ]
  );
  useEffect(() => {
    let infoArray = [];
    let labelArray = [];
    let graphLabel = [];
    let graphArray = [];

    surveyData &&
      surveyData["chart_response"] &&
      surveyData["chart_response"].forEach((e) => {
        labelArray.push(e.answer);
        infoArray.push(e.per);
      });

    setDoughnutData(infoArray);
    setDoughnutLabel(labelArray);

    if (surveyMonth !== "all") {
      surveyData &&
        surveyData["graph_response"] &&
        Object.entries(surveyData["graph_response"]).forEach(([key, value]) => {
          graphLabel.push(formatDate(key));
          graphArray.push(value);
        });
    } else {
      surveyData &&
        surveyData["graph_response"] &&
        Object.entries(surveyData["graph_response"]).forEach(([key, value]) => {
          graphLabel.push(key);
          graphArray.push(value);
        });
    }
    setChartData(graphArray);
    setChartLabel(graphLabel);
  }, [surveyData, surveyMonth, surveyYear]);

  useEffect(() => {
    let longInfoArray = [];
    let longLabelArray = [];
    let longGraphLabel = [];
    let longGraphArray = [];
    let pieLabel = [];
    let pieArray = [];
    longSurveyData &&
      longSurveyData["chart_response"] &&
      longSurveyData["chart_response"].forEach((e) => {
        longLabelArray.push(e.answer);
        longInfoArray.push(e.per);
      });
    setLongDoughnutData(longInfoArray);
    setLongDoughnutLabel(longLabelArray);
    longSurveyData &&
      longSurveyData["long_answer_chart_response"] &&
      Object.entries(longSurveyData["long_answer_chart_response"]).forEach(
        ([key, value]) => {
          pieLabel.push(key);
          pieArray.push(parseInt(value));
        }
      );
    setpieChartData(pieArray);
    setpieChartLabel(pieLabel);
    if (longSurveyMonth !== "all") {
      longSurveyData &&
        longSurveyData["graph_response"] &&
        Object.entries(longSurveyData["graph_response"]).forEach(
          ([key, value]) => {
            longGraphLabel.push(formatDate(key));
            longGraphArray.push(value);
          }
        );
    } else {
      longSurveyData &&
        longSurveyData["graph_response"] &&
        Object.entries(longSurveyData["graph_response"]).forEach(
          ([key, value]) => {
            longGraphLabel.push(key);
            longGraphArray.push(value);
          }
        );
    }
    setLongChartData(longGraphArray);
    setLongChartLabel(longGraphLabel);
  }, [longSurveyData, longSurveyMonth, longSurveyYear]);

  useEffect(() => {
    if (
      longTextSummary &&
      longTextSummary.data &&
      longTextSummary.data.length > 0
    ) {
      setselectedtext(longTextSummary.data[0].sub_headings[0].text);
      getSummarizedData(longTextSummary.data[0].sub_headings[0].text);
    }
    if (
      longTextSummary &&
      longTextSummary.data &&
      longTextSummary.data.length === 0
    ) {
      setselectedtext("");
      getSummarizedData("");
    }
  }, [longTextSummary]);

  useEffect(() => {
    setLongQuestionText(
      questionData &&
        questionData.long.length > 0 &&
        questionData.long[0].question
    );
    // setOtherQuestionText(
    //   questionData &&
    //     questionData.others.length > 0 &&
    //     questionData.others[0].question
    // );
    if (otherQuestionId !== null) {
      let index = questionData?.others.findIndex(
        (x) => x.qus_id === parseInt(otherQuestionId)
      );
      setOtherQuestionText(questionData?.others[index]?.question);
    } else {
      setOtherQuestionText(
        questionData &&
          questionData.others?.length > 0 &&
          questionData.others[0]?.question
      );
    }
  }, [questionData]);

  useEffect(() => {
    id && dispatch(workspaceActions.getWsDetailWithAuth(id));
  }, []);

  return (
    <>
      <div className="answerAnalysisPage">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <div className="row">
              <div className="col-md-7 col-sm-12">
                <div className={cn("", "analyseQuestionSelect")}>
                  {questionData &&
                  questionData.others &&
                  questionData.others.length > 0 ? (
                    <Box
                      sx={{ maxWidth: 485, maxHeight: 55 }}
                      className="analysisCustomSelectBox"
                    >
                      <FormControl fullWidth>
                        <Select
                          name="question"
                          value={otherQuestionId || ""}
                          label=""
                          onChange={(e) => {
                            setshowloader(true);
                            setTimeout(() => {
                              setshowloader(false);
                            }, [2000]);
                            let index = questionData.others.findIndex(
                              (x) => x.qus_id === parseInt(e.target.value)
                            );
                            setOtherQuestionText(
                              questionData.others[index].question
                            );
                            dispatch(
                              surveyActions.setOtherQuestionId(e.target.value)
                            );
                          }}
                          className="analysisCustomSelect"
                          MenuProps={{
                            classes: { paper: "selectPaperRoot" },
                          }}
                        >
                          {questionData &&
                            questionData.others &&
                            questionData.others.length > 0 &&
                            questionData.others.map((ques, index) => {
                              return (
                                <MenuItem
                                  value={ques.qus_id}
                                  key={index}
                                  className="analysisMenuItem"
                                >
                                  Q:{index + 1} {ques.question}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                  ) : null}
                </div>
              </div>
              <div className="col-md-5 col-sm-12">
                {surveyData?.current_question_type ? (
                  <div className={cn("analyseCard", "analyseQuestionType")}>
                    <span className="text-capitalize">
                      {surveyData &&
                        getFormattedLabel(surveyData?.current_question_type)}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {res?.experience_type ? (
            <div className="col-md-4 col-sm-12">
              <div className={cn("analyseCard", "analyseExperience")}>
                <span>{res?.experience_type}</span>
              </div>
            </div>
          ) : null}
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <div
              className={cn("analyseCard", "analyseResponse", "totalSurveyAns")}
            >
              <div className="iconbox">
                <img src="/images/5280.png" className={""} />
              </div>
              <div className="total">
                {isFormType ? "Total Answers" : "Total Survey Answers"}
              </div>
              <div className="responseTotal">
                {Object.keys(surveyData).length
                  ? surveyData?.total_answers_received
                  : surveyData1?.total_answers_received}
              </div>
              <div className="responseTotalType">
                Multiple:{" "}
                {Object.keys(surveyData).length
                  ? surveyData?.type_of_questions?.multiple
                  : surveyData1?.type_of_questions?.multiple}
                , Long Answers:
                {Object.keys(surveyData).length
                  ? surveyData?.type_of_questions?.long_answers
                  : surveyData1?.type_of_questions?.long_answers}
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div
              className={cn(
                "analyseCard",
                "analyseResponse",
                "totalTargetResponse"
              )}
            >
              <div className="iconbox">
                <img src="/images/5281.png" className={""} />
              </div>
              <div className="total">Total Target Response</div>
              <div className="responseTotal">
                {Object.keys(surveyData).length
                  ? surveyData?.total_target_survey_response
                  : surveyData1?.total_target_survey_response}
              </div>
              <div className="responseTotalType">
                Total Response Recieved-{" "}
                {Object.keys(surveyData).length
                  ? surveyData?.total_survey_response_received
                  : surveyData1?.total_survey_response_received}
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div
              className={cn(
                "analyseCard",
                "analyseResponse",
                "totalQuestionResponse"
              )}
            >
              <div className="iconbox">
                <img src="/images/5282.png" className={""} />
              </div>
              <div className="total">Q1 Answer Response </div>
              <div className="responseTotal">
                {" "}
                {surveyData &&
                  surveyData.answer_responses &&
                  surveyData.answer_responses.given}
              </div>
              <div className="responseTotalType">
                No Response-{" "}
                {surveyData &&
                  surveyData.answer_responses &&
                  surveyData.answer_responses.not_given}
              </div>
            </div>
          </div>
        </div>

        <div className="row chart-row mt-30 ">
          {doughnutData && doughnutData.length > 0 ? (
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div
                className={cn("analyseCard", "analyseCircle", "analyseChart1")}
              >
                <div className="analyse-filter-box">
                  <MuiTooltip title={otherQuestionText}>
                    <div className="title">{otherQuestionText}</div>
                  </MuiTooltip>
                </div>
                {doughnutData && doughnutData.length > 0 ? (
                  <DoughnutComponent
                    data={doughnutData}
                    label={doughnutLabel}
                  />
                ) : (
                  <div>
                    <p>No Data Found</p>
                  </div>
                )}
              </div>
            </div>
          ) : null}

          {chartData && chartData.length > 0 ? (
            <div className="col-sm-12 col-md-6 col-lg-8">
              <div className={cn("analyseCard", "analyseChart2")}>
                <div className="analyse-filter-box">
                  <div className="title">
                    {isFormType ? "Responses Submitted" : "Answers Submitted"}
                  </div>
                  <div className="select-items">
                    <div className="item">
                      <select
                        value={surveyYear}
                        onChange={(e) => {
                          setCxLoading({ cx1: true });

                          setshowloader(true);
                          setTimeout(() => {
                            setshowloader(false);
                          }, [2000]);
                          setTimeout(() => {
                            setCxLoading({ cx1: false });
                          }, 2000);

                          dispatch(surveyActions.setSurveyMonth("all"));
                          dispatch(surveyActions.setSurveyYear(e.target.value));
                        }}
                      >
                        {getDropList()}
                      </select>
                    </div>
                    <div className="item">
                      <select
                        value={surveyMonth}
                        onChange={(e) => {
                          setCxLoading({ cx1: true });

                          setshowloader(true);
                          setTimeout(() => {
                            setshowloader(false);
                          }, [2000]);
                          setTimeout(() => {
                            setCxLoading({ cx1: false });
                          }, 2000);
                          dispatch(
                            surveyActions.setSurveyMonth(e.target.value)
                          );
                        }}
                      >
                        <option value="all">Select</option>

                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div style={{ height: "380px" }}>
                  {" "}
                  {cxLoading.cx1 ? (
                    <p style={{ textAlign: "center", marginTop: 20 }}>
                      Loading...
                    </p>
                  ) : (
                    <ChartComponent
                      text=""
                      info={chartData}
                      labels={chartLabel}
                    />
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {questionData && questionData.long && questionData.long.length > 0 ? (
          <div className="row">
            <div className="col-md-8 col-sm-12">
              <div className="row">
                <div className="col-md-7 col-sm-12">
                  <div className={cn("analyseQuestionSelect", "questionsLong")}>
                    <Box
                      sx={{ maxWidth: 485, maxHeight: 55 }}
                      className="analysisCustomSelectBox"
                    >
                      <FormControl fullWidth>
                        <Select
                          name="question"
                          value={longQuestionId || ""}
                          onChange={(e) => {
                            setshowloader(true);
                            setTimeout(() => {
                              setshowloader(false);
                            }, [2000]);
                            let index = questionData.long.findIndex(
                              (x) => x.qus_id === parseInt(e.target.value)
                            );
                            setLongQuestionText(
                              questionData.long[index].question
                            );
                            dispatch(
                              surveyActions.setLongQuestionId(e.target.value)
                            );
                          }}
                          label=""
                          className="analysisCustomSelect"
                          MenuProps={{
                            classes: { paper: "selectPaperRoot" },
                          }}
                        >
                          {questionData &&
                            questionData.long &&
                            questionData.long.length > 0 &&
                            questionData.long.map((ques, index) => {
                              return (
                                <MenuItem
                                  value={ques.qus_id}
                                  key={index}
                                  className="analysisMenuItem"
                                >
                                  Q:{index + 1} {ques.question}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                </div>
                <div className="col-md-5 col-sm-12 mt-1">
                  <div
                    className={cn("analyseCard", "analyseQuestionType", "mt-4")}
                  >
                    <span>Long Answer</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {longChartData &&
        longChartData.length > 0 &&
        longChartLabel &&
        longChartLabel.length > 0 ? (
          <div className="row chart-row mt-30">
            <div className="col-sm-12 col-md-6 col-lg-8">
              <div className={cn("analyseCard", "analyseChart2", "m-0")}>
                <div className="analyse-filter-box">
                  <div className="title">
                    {" "}
                    {isFormType ? "Responses Submitted" : "Answers Submitted"}
                  </div>
                  <div className="select-items">
                    <div className="item">
                      <select
                        value={longSurveyYear}
                        onChange={(e) => {
                          setCxLoading({ cx2: true });

                          setshowloader(true);
                          setTimeout(() => {
                            setshowloader(false);
                          }, [2000]);
                          setTimeout(() => {
                            setCxLoading({ cx2: false });
                          }, 2000);
                          dispatch(surveyActions.setLongSurveyMonth("all"));

                          dispatch(
                            surveyActions.setLongSurveyYear(e.target.value)
                          );
                        }}
                      >
                        {getDropList()}
                      </select>
                    </div>
                    <div className="item">
                      <select
                        value={longSurveyMonth}
                        onChange={(e) => {
                          setCxLoading({ cx2: true });

                          setshowloader(true);
                          setTimeout(() => {
                            setshowloader(false);
                          }, [2000]);
                          setTimeout(() => {
                            setCxLoading({ cx2: false });
                          }, 2000);
                          dispatch(
                            surveyActions.setLongSurveyMonth(e.target.value)
                          );
                        }}
                      >
                        <option value="all">Select</option>

                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div style={{ height: "380px" }}>
                  {cxLoading.cx2 ? (
                    <p style={{ textAlign: "center", marginTop: 20 }}>
                      Loading...
                    </p>
                  ) : (
                    <ChartComponent
                      text=""
                      info={longChartData}
                      labels={longChartLabel}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div
                className={cn("analyseCard", "analyseCircle", "analyseMargin")}
              >
                <div className="analyse-filter-box">
                  <div className="title">Categorized Value</div>
                  <div className="items">
                    {longDoughnutData && longDoughnutData.length > 0 ? (
                      <div className="assignBtnBox">
                        <Label
                          config={{
                            label: "Assign",
                            className: "assignBtn",
                            onClick: openNotes,
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                {pieChartData && pieChartData.length > 0 ? (
                  <Pie
                    plugins={autocolors}
                    options={{
                      responsive: true,
                      maintainAspectRatio: true,
                      // labels: pieChartLabel,
                      // datasets: pieChartData,

                      plugins: {
                        maintainAspectRatio: false,
                        autocolors: {
                          mode: "data",
                        },
                        legend: {
                          position: "bottom",
                          align: "middle",
                          padding: {
                            top: 4,
                          },
                        },
                      },
                    }}
                    data={{
                      labels: pieChartLabel,
                      plugins: autocolors,

                      datasets: [
                        {
                          data: pieChartData,

                          borderWidth: 1,
                        },
                      ],
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
        ) : null}

        <div className="row">
          <div className="col-md-12">
            <div
              className="analyse-answerResponse"
              ref={customWidth}
              id="answerResponseSection"
            >
              <div className="answer-item">
                <div className="filterbox">
                  <h3 className="title">Answer Response </h3>
                  <div className="buttonbox">
                    <button
                      onClick={() => {
                        setshowloader(true);
                        setTimeout(() => {
                          setshowloader(false);
                        }, [2000]);
                        customWidth.current.classList.toggle(
                          "customColumnWidth"
                        );
                      }}
                      className="view-btn"
                    >
                      <svg
                        width="14"
                        height="10"
                        viewBox="0 0 14 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 3.24126C6.09488 3.24126 5.35851 4.03013 5.35851 5.00003C5.35851 5.96993 6.09488 6.7588 7 6.7588C7.90512 6.7588 8.64149 5.96981 8.64149 5.00003C8.64149 4.03024 7.90512 3.24126 7 3.24126ZM7 0C3.89998 0 0.84123 2.3823 0 5C0.841283 7.61759 3.89998 10 7 10C10.1 10 13.1588 7.6177 14 5C13.1587 2.38241 10.1 0 7 0ZM7 7.86253C5.52668 7.86253 4.32835 6.57839 4.32835 5C4.32835 3.42161 5.52678 2.13747 7 2.13747C8.47322 2.13747 9.67165 3.42172 9.67165 5C9.67165 6.57828 8.47322 7.86253 7 7.86253Z"
                          fill="#18A0FB"
                        />
                      </svg>
                    </button>
                    <select
                      value={aiFilter}
                      onChange={(e) => {
                        setDefaultChecked(0);
                        setActiveIndex(0);
                        setshowloader(true);
                        setTimeout(() => {
                          setshowloader(false);
                        }, [2000]);
                        dispatch(surveyActions.setAiFilter(e.target.value));
                      }}
                      className="select-field"
                    >
                      <option value="all">All</option>
                      <option value="30">Last 30 Days</option>
                      <option value="50">Last 50 Days</option>
                    </select>
                  </div>
                </div>
                <ul className="progress-list">
                  {longTextSummary &&
                  longTextSummary.data.length > 0 &&
                  !showloader ? (
                    <div
                      style={{
                        height:
                          longTextSummary && longTextSummary.data.length === 1
                            ? "120px"
                            : longTextSummary &&
                              longTextSummary.data.length === 2
                            ? "140px"
                            : longTextSummary &&
                              longTextSummary.data.length === 3
                            ? "300px"
                            : longTextSummary &&
                              longTextSummary.data.length > 3 &&
                              longTextSummary &&
                              longTextSummary.data.length < 12
                            ? "400px"
                            : longTextSummary &&
                              longTextSummary.data.length >= 12 &&
                              longTextSummary &&
                              longTextSummary.data.length < 21
                            ? "600px"
                            : longTextSummary &&
                              longTextSummary.data.length >= 21 &&
                              longTextSummary &&
                              longTextSummary.data.length < 24
                            ? "800px"
                            : longTextSummary &&
                              longTextSummary.data.length >= 24 &&
                              longTextSummary &&
                              longTextSummary.data.length < 32
                            ? "1200px"
                            : "1600px",
                      }}
                    >
                      <Bar
                        ref={chartRef}
                        options={{
                          indexAxis: "y",
                          responsive: true,

                          maintainAspectRatio: false,

                          // aspectRatio: 1,
                          onClick: (c, i) => {
                            setBarShowLoader(true);
                            setTimeout(() => {
                              setBarShowLoader(false);
                            }, [2000]);

                            setselectedtext(
                              c.chart.tooltip.dataPoints[0].dataset.label
                            );
                            getSummarizedData(
                              c.chart.tooltip.dataPoints[0].dataset.label
                            );
                          },

                          plugins: {
                            legend: {
                              labels: {
                                position: "bottom",
                                generateLabels: (chart) => {
                                  var text = [];

                                  text.push({
                                    text: "Positive",
                                    fillStyle: "#AFE1AF",
                                  });
                                  text.push({
                                    text: "Very Positive",
                                    fillStyle: "#50C878",
                                  });
                                  text.push({
                                    text: "Extremely Positive",
                                    fillStyle: "#097969",
                                  });
                                  text.push({
                                    text: "Negative",
                                    fillStyle: "#EE4B2B",
                                  });
                                  text.push({
                                    text: "Very Negative",
                                    fillStyle: "#AA4A44",
                                  });
                                  text.push({
                                    text: "Extremely Negative",
                                    fillStyle: "#880808",
                                  });
                                  text.push({
                                    text: "Neutral",
                                    fillStyle: "#FFD300",
                                  });

                                  return text;
                                },
                              },
                            },
                            tooltip: {
                              callbacks: {
                                title: function (custom) {
                                  return custom[0].dataset.label;
                                },
                              },
                            },
                          },

                          scales: {
                            x: {
                              stacked: true,

                              ticks: {
                                autoSkip: false,
                                display: false,
                              },
                            },
                            y: {
                              stacked: true,
                              ticks: {
                                autoSkip: false,
                              },
                            },
                          },
                        }}
                        onClick={onClick}
                        data={getBarChartData()}
                      />
                    </div>
                  ) : // </div>
                  null}
                </ul>
              </div>
              <div className="answer-item">
                <div className="filterbox">
                  <button onClick={openNotes1} className="assign-btn">
                    Assign
                  </button>
                </div>
                <ul className="listingwrap">
                  {occurence && occurence.length > 0 && !showloader
                    ? occurence.map((value, index) => {
                        return (
                          <li
                            className="questtion-item active"
                            key={"occ" + index}
                          >
                            <h3 className="questtion-tilte">
                              <Highlight search={selectedtext}>
                                {value}
                              </Highlight>

                              <span className="coutdata">
                                {getCount(value)}
                              </span>
                            </h3>
                          </li>
                        );
                      })
                    : null}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {isNote && (
          <Notes
            type="answer"
            question={longQuestionText}
            categorization={categorization}
            setDialog={setNote}
          />
        )}
      </div>
      <Backdrop show={showloader || barShowloader} />
    </>
  );
};

export default AnswersAnalysis;
