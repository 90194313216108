import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from '@mui/icons-material/Search';
import { visuallyHidden } from '@mui/utils';
import Table from './Table';
import { clone } from '../../Common/Utils';
import Backdrop from '../../Common/Backdrop';
import Prompt from "../../Common/ConfirmationPrompt";

const EnhancedTableToolbar = ({searching}) => {
    const inputRef= useRef();
    const onSearch = () => {
        const el = inputRef.current;
        searching(el.value || "");
    };
    const onKeyPress = (e) => {
        e.key === 'Enter' && onSearch();
    };

    return (
        <Toolbar sx={{pl: { sm: 2 }, pr: { xs: 1, sm: 1 }}}>
            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Contacts List
            </Typography>
            <div className="rightSideFilter">
                {/*<div className="sortSelect">
                    <select>
                        <option value="">Sort by All</option>
                        <option value="">Sort by Selected</option>
                    </select>
                </div>*/}
                <div className="searchBar">
                    <input type="text" ref={inputRef} placeholder={"Search"} onKeyPressCapture={onKeyPress}/>
                    <div className="icon" onClick={onSearch}><SearchIcon/></div>
                </div>
            </div>
        </Toolbar>
    );
};

/*EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};*/


export default function DataTable({onContactListSubmit, uploadedData, headings, setPersonalizedCont, onCancel, processing=false, count=10}) {
    const [selectedRows, setSelectedRow] = React.useState({});
    const [rows, setRows] = useState(uploadedData);
    const [err, setErr] = useState("");
    useEffect(() => {
        setRows(uploadedData);
    }, [uploadedData]);

    const onRowSelect = (row, index, checked) => {
        const clonedRows = clone(rows);
        const idx = clonedRows.findIndex(x=> x.id === row.id);
        clonedRows[idx]['selected'] = checked
        setRows(clonedRows);
    };
  const onAllSelect = (e) => {
      const {checked} = e?.target;
      const clonedRows = clone(rows);
      const alterRows = clonedRows.map((r,i)=>{
           r['selected'] = checked;
          return r;
      });
      setRows(alterRows);
    };

    const onSave = () => {
        const selectList = rows.filter(r=> r.selected === true);
        const list = Object.values(selectList);
        if(list.length <= count){
            onContactListSubmit && onContactListSubmit(list);
        }else {
            setErr("Only "+count+" contacts are allowed.");
        }
    };

    const searching = (keyword) => {
        const cloned = clone(uploadedData);
        const filteredRow = cloned.filter(r => {
            const em = r["email"]?.toLowerCase()?.includes(keyword.toLowerCase());
            const fn = r["first name"]?.toLowerCase()?.includes(keyword.toLowerCase());
            const ln = r["last name"]?.toLowerCase()?.includes(keyword.toLowerCase());
            const phn =String(r["phone number"])?.includes(keyword);

            if(em || fn || ln || phn){
                return r;
            }
        });
        setRows(filteredRow);
    };

    const active = rows.filter(r=> r.selected).length ? ' active' : '';
    return (
        <div className="sendEmailBody contactListWrap">
            <div className="sendEmailInner">
                <Box sx={{ width: '100%' }} className={"contactListPage"}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                        <EnhancedTableToolbar searching={searching} />
                        <Table rows={rows} headCells={headings} onRowSelect={onRowSelect} onAllSelect={onAllSelect}/>
                        <button className={"saveBtn"+active} onClick={onSave}>Save</button>
                        <button className="saveBtn cancelBtn" onClick={onCancel}>Cancel</button>
                    </Paper>
                </Box>
            </div>
            <Prompt show={err.length} message={err} handler={()=>setErr("")} singleBtn={true} btnLabel="OK" />
            <Backdrop show={processing}/>
        </div>
    );
}
