import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
    Box,
    Grid,
    TextField,
    MenuItem,
    Button,
    Stack,
    Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { callQueueActions } from "../../../actions/callQueueActions";

const CustomerAction = ({
    open,
    onClose,
    customerData,
    onPriorityChange,
    onAssignUser,
    callQueueId
}) => {
    const [priority, setPriority] = useState(customerData.priority || "Medium");
    const [assignedUser, setAssignedUser] = useState("");

    const mockUsers = ["John Doe", "Jane Smith", "Alice Johnson"];
    const callVolumeData = [
        { date: "Jan", successful: 3, failed: 1, missed: 2 },
        { date: "Feb", successful: 5, failed: 2, missed: 1 },
        { date: "Mar", successful: 2, failed: 0, missed: 3 },
        { date: "Apr", successful: 7, failed: 1, missed: 1 },
    ];
    const dispatch = useDispatch();
    const { systemUsers } = useSelector((state) => ({
        systemUsers: state.callRoute.systemUsers,
    }));
    const handlePriorityChange = (e) => {
        const newPriority = e.target.value;
        setPriority(newPriority);
        onPriorityChange(customerData.id, newPriority);
        dispatch(callQueueActions.updateCallQueue(callQueueId, {priority: newPriority}))
    };

    const handleAssignUser = () => {
        onAssignUser(customerData.id, assignedUser);
        dispatch(callQueueActions.updateCallQueue(callQueueId, {assigned_user:assignedUser }))
        setAssignedUser("");
    };

    return (
        <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        sx={{
            "& .MuiDialog-paper": {
                padding: 1,
            },
        }}
        >
        <DialogTitle
        sx={{
            display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
        }}
        >
        <Typography variant="h6" fontWeight={600}>
        Customer Action
        </Typography>
        <IconButton onClick={onClose}>
        <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
        <Box
        mb={2}
        sx={{
            display: "grid",
                gridTemplateColumns: "auto 2fr",
                gap: "0 2rem",
        }}
        >
        <Typography variant="body2">
        <strong>ID:</strong> {customerData.id}
        </Typography>
        <Typography variant="body2">
        <strong>Customer:</strong> {customerData.name}
        </Typography>
        <Typography variant="body2">
        <strong>Wait Time:</strong> {customerData.waitTime} mins
        </Typography>
        <Typography variant="body2">
        <strong>Issue:</strong> {customerData.issue}
        </Typography>
        <Typography variant="body2">
        <strong>Priority:</strong> {priority}
        </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
        <TextField
        select
        label="Change Priority"
        value={priority}
        onChange={handlePriorityChange}
        fullWidth
        size="small"
        InputProps={{
            startAdornment: <PriorityHighIcon sx={{ mr: 1 }} />,
        }}
        >
        <MenuItem value="High">High</MenuItem>
        <MenuItem value="Medium">Medium</MenuItem>
        <MenuItem value="Low">Low</MenuItem>
        </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
        <TextField
        select
        label="Assign User"
        value={assignedUser}
        onChange={(e) => setAssignedUser(e.target.value)}
        fullWidth
        size="small"
        InputProps={{
            startAdornment: <PersonAddIcon sx={{ mr: 1 }} />,
        }}
        >
        {systemUsers?.map((user, index) => (
            <MenuItem key={index} value={user?.id}>
            {user?.name}
            </MenuItem>
        ))}
        </TextField>
        <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 1 }}
        onClick={handleAssignUser}
        disabled={!assignedUser}
        >
        Assign
        </Button>
        </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />

        <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Past Call Volume
        </Typography>
        <Box sx={{ height: 200 }}>
        <ResponsiveContainer>
        <LineChart data={callVolumeData}>
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
        type="monotone"
        dataKey="successful"
        stroke="#2196F3" // Color for successful calls
        strokeWidth={2}
        />
        <Line
        type="monotone"
        dataKey="failed"
        stroke="#FF0000" // Color for failed calls (red)
        strokeWidth={2}
        />
        <Line
        type="monotone"
        dataKey="missed"
        stroke="#FFA500" // Color for missed calls (orange)
        strokeWidth={2}
        />
        </LineChart>
        </ResponsiveContainer>
        </Box>
        </DialogContent>
        </Dialog>
    );
};

export default CustomerAction;
