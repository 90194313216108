import React, { useState, useEffect, useRef } from "react";
import { MaterialSelect } from "../../../common/controls/inputs/Material";
import { clone, uploadFileToS3 } from "../../Common/Utils";
import PictureGrid from "../../Survey/Components/DnD/index";
import TextArea from "../../../common/controls/inputs/TextArea";
import CancelIcon from "@mui/icons-material/Cancel";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import Prompt from "../../Common/ConfirmationPrompt";

import "./Layouts.css";

const PictureChoice = ({
  quesNo,
  quesType,
  setQuesType,
  value,
  onChange,
  onSave,
  ansTypes,
  children,
}) => {
  const {
    question = "",
    desc = "",
    settings = {},
    if_pictures_type = [],
  } = value;
  const {
      font,
      underline,
      bold,
      fontSize,
      italic,
      fontColor,
      layout,
      supersize,
    } = settings,
    style = {
      fontFamily: font,
      fontSize,
      fontWeight: bold ? "bold" : "",
      fontStyle: italic ? "italic" : "",
      textDecoration: underline ? "underline" : "",
      color: fontColor,
    };
  const [imgData, setImgData] = useState(if_pictures_type);
  const [data, setData] = useState({ question, desc });
  const [err, setErr] = useState();
  const [alertMsg, setAlertMsg] = useState("");
  useEffect(() => {
    const elements = document.getElementsByName("question");
    let ele = elements.length ? elements[0] : elements;
    ele.style.setProperty("font-family", font || "Poppins", "important");
  }, [font]);

  const beforeOnSave = () => {
    if (value.question.length < 1) {
      setErr("Enter the question to proceed.");
      return;
    }
    setErr();
    onSave && onSave();
  };
  const getImgData = (file, index) => {
    uploadFileToS3(file, (result) => {
      const clonedImgData = clone(imgData) || [];
      if (index > -1) {
        clonedImgData[index] = { base64: result, id: index + 1, label: "" };
      } else {
        clonedImgData.push({
          base64: result,
          id: clonedImgData.length + 1,
          label: "",
        });
      }
      setImgData(clonedImgData);
      onChange({ name: "if_pictures_type", value: clonedImgData });
    });
  };

  const updatePictureDetail = (e, index) => {
    if (e && e.target) {
      const clonedImgData = clone(imgData);
      clonedImgData[index][e.target.name] = e.target.value;
      setImgData(clonedImgData);
      onChange({ name: "if_pictures_type", value: clonedImgData });
    }
  };

  const onSelect = (e, index = -1) => {
    const ImageSize = 200000; //200kb
    if (e?.target?.files?.length) {
      const file = e.target.files[0];
      console.log(file);
      if (file?.size <= ImageSize) {
        getImgData(file, index);
      } else {
        setAlertMsg("Image size should be less than or equal to 200KB");
      }
    }
  };

  const onBlur = (e) => {
    if (e?.target) {
      e.target.value = e.target.value.trim();
      (e.target.name === "question" || e.target.name === "desc") &&
        handleChange(e);
      onChange(e.target);
    }
  };

  const handleChange = (e) => {
    if (e && e.target) {
      setData((data) => ({ ...data, [e.target.name]: e.target.value }));
    }
  };

  const GridItem = ({ item, index }) => {
    const [details, setDetails] = useState(item),
      editLabel = (e) => {
        if (e && e.target) {
          const cloned = clone(details);
          cloned[e.target.name] = e.target.value;
          setDetails(cloned);
        }
      };

    const blur = (e) => {
      updatePictureDetail(e, index);
    };

    const onCancel = () => {
      setImgData((imgData) => {
        imgData?.length && imgData.splice(index, 1);
        onChange({ name: "if_pictures_type", value: imgData });
        return imgData;
      });
    };

    return (
      <div
        key={"img_" + index}
        className={`imageUpload uploaded ${supersize ? "superSize" : ""}`}
      >
        <div className="cancel" onClick={onCancel}>
          <CancelIcon />
        </div>
        <div className="inner">
          <img src={item.base64} alt="arrow icon" />
          <AddPhotoAlternateIcon />
          <input
            type="file"
            id="img"
            name="img"
            accept="image/*"
            onChange={(e) => onSelect(e, index)}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>{String.fromCharCode(65 + index)}</div>
          {settings.show_label && (
            <input
              type="text"
              name="label"
              placeholder={"Type label"}
              value={details.label || ""}
              onChange={editLabel}
              onBlur={blur}
            />
          )}
        </div>
      </div>
    );
  };

  const StaticItem = (props) => {
    return (
      <div className="imageUpload" style={{ height: 110, width: 150 }}>
        <div className="inner">
          <AddPhotoAlternateIcon />
          <input
            type="file"
            id="img"
            name="img"
            accept="image/*"
            onChange={onSelect}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="imageWrapInner">
      <div className={"questionWrap"} style={{ display: "flex" }}>
        <div className="question">
          <span className={"num"} style={style}>
            {quesNo + "- "}
          </span>
          <TextArea
            config={{
              name: "question",
              inputClass: "questionInputArea",
              placeholder:
                "Your elements here. Recall information with@Description",
              textAreaStyle: style,
            }}
            onBlur={onBlur}
            value={data.question}
            onChange={handleChange}
          />
        </div>
      </div>
      <em>
        <input
          type="text"
          name="desc"
          placeholder={"Description (optional)"}
          value={data.desc}
          onBlur={onBlur}
          onChange={handleChange}
        />
      </em>

      {settings?.layout == 1 && children}
      <div className="imageUploadWrap imageUploadItems">
        <PictureGrid
          items={imgData}
          onSelect={onSelect}
          GridRenderer={GridItem}
          StaticRenderer={StaticItem}
          canDrag={settings.randomize}
          className={
            settings.horizontal ? "pictureHorizontal" : "pictureVertical"
          }
        />
      </div>

      <div className="btnGroup" style={{ paddingBottom: 0 }}>
        <button className="btnSave" onClick={beforeOnSave}>
          Save
        </button>
        <div className="answerTypeSelectBox">
          <MaterialSelect
            config={{
              isMenuWithContent: true,
              fieldClass: "answerInputType",
              menuItems: ansTypes,
              value: quesType,
              onChange: (e) => setQuesType(e.target.value),
            }}
          />
        </div>
      </div>
      {typeof err !== "undefined" && err.length ? (
        <span className={"invalid-feedback"} style={{ position: "unset" }}>
          {err}
        </span>
      ) : null}
      <Prompt
        show={alertMsg?.length}
        message={alertMsg}
        handler={() => setAlertMsg("")}
        singleBtn={true}
        btnLabel="OK"
      />
    </div>
  );
};

export default PictureChoice;
