import React, { useState, useEffect} from 'react';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import Checkbox from '@mui/material/Checkbox';
import { lightGreen, green } from '@mui/material/colors';
import { CommonWrapper, Wrapper, StartSurvey } from '../Common/CommonWrapper';
import { MaterialInput, MaterialSelect } from "../../common/controls/inputs/Material";
import Label from '../../common/controls/inputs/Label';
import {clone, getLocalStorage, getWorkspaceID, setLocalStorage} from '../Common/Utils';
import { history } from '../../helpers';
import Backdrop from '../Common/Backdrop'
import './surveyStyle.css';
import { getUser } from '../Common/Utils';

import { useDispatch, useSelector } from 'react-redux';
import { workspaceActions, dashboardActions } from '../../actions';
import Prompt from '../Common/ConfirmationPrompt';

const origin = window.location.origin;
const Scratch = ({surveyName, wsID, surveyData}) => {
    const themePath = origin+"/surveyTheme/";
    /*const menuItems=['Community or volunteer feedback', 'Customer feedback', 'General market research', 'Employee engagement',
        'Employee performance', 'General employee feedback', 'Event registration', 'Event feedback', 'Academic research','Quiz'];
    const surveyThemes = ['image-1.png', 'image-2.png', 'image-3.png', 'image-4.png', 'image-5.png'];*/
    const [data, setData] = useState({survey_category:"", share_responses: 'shareLink', audience_responses:'nonAnon'});
    const [check, setCheck] = useState({});
    const [hasNonAnonType, setNonAnonType] = useState(false);
    const [mounted, setMount] = useState(false);
    const [hasExp, setExpStatus] = useState(false);
    const [submitting, setSubmit] = useState(false);
    const [err, setErr] = useState("");
    const {loading, items} = useSelector(state => state.themes);
    const { loading: wsLoading, res: wsResult } = useSelector(state => state.wsDetailsAuth);
    const [theme, setTheme] = React.useState({});
    const [themesArr, setThemesArr] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [activeCat, setActiveCat] = React.useState();
    const [activeTheme, setActiveTheme] = React.useState();
    const [enable, setEnable] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=> {
        !loading && !items && dispatch(dashboardActions.getAllThemes());
    }, []);

    useEffect(()=> {
        if(!loading && !mounted && items && items.data){
            const arr = Object.keys(items.data);
            if(arr && arr.length){
                setTheme(items.data);
                setActiveTheme(arr[0]);
                setThemesArr(arr);
                setDataByTheme(arr[0], items.data);
                setMount(true);

            }
        }
    }, [loading, items]);

    /*useEffect(()=>{
        if(themesArr.length ){
            const name = themesArr[0];
            setDataByTheme(name);

        }
    }, [theme]);*/
    useEffect(()=> {
        if(wsResult && wsResult.survey_title === surveyName){
            if(items && items.data){
                const exp = wsResult?.experience_type;
                const arr = Object.keys(items.data);
                if(arr && arr.length){
                    setTheme(items.data);
                    setActiveTheme(exp||arr[0]);
                    setThemesArr(arr);
                    setDataByTheme(exp||arr[0], items.data);
                }
            }
            cookedData(wsResult);
        }
    },[]);

    const setDataByTheme = (name, themeArr, changeEffect = false) => {
        let expName = name;
        let catName = null;
        const isNewSurvey = getLocalStorage('isNewSurvey');
        const _surveyData = surveyData||wsResult;
        if(!isNewSurvey && _surveyData && Object.keys(_surveyData).length && !changeEffect){
            const {experience_type, survey_category} = _surveyData;
            expName = experience_type;
            catName = survey_category;
            //setExpStatus(true);
        }
        const cat = themeArr ? themeArr[expName] : (theme[expName] || {});
        const c_arr = cat ? Object.keys(cat) : [];
        console.log('exp name: ', expName);
        setActiveTheme(expName);
        setCategories(c_arr);
        c_arr.length && setActiveCat(catName || c_arr[0]);
        setData(data=>({...data, 'survey_category': catName || c_arr[0]}));
        setCheck({[catName || c_arr[0]]: !check[catName || c_arr[0]]});
    };

    useEffect(()=>{
        cookedData(surveyData);

    },[surveyData]);

    const cookedData = (survey_data) => {
        if(survey_data){
            const clonedSurvey = clone(survey_data);
            const {id, survey_theme_picture, quest_desc={}} = clonedSurvey||{};
            if(quest_desc.questions_list && quest_desc.questions_list.length){
                const list = quest_desc.questions_list?.filter(d=>['Email', 'Contact'].indexOf(d.q_type) > -1) || [];
                setNonAnonType(list.length);
            }

            if(survey_theme_picture?.length){
                const p = survey_theme_picture.split('/');
                p.length && setCheck({[p[p.length-1]]:true})
            }
            clonedSurvey['survey_id'] = id;
            delete clonedSurvey.quest_desc;
            delete clonedSurvey.id;
            setData(clonedSurvey);
        }
    }

    const onChange=(e)=> {
        if(e?.target?.value){
            setData(data => ({ ...data, [e.target.name]:  e.target.value}));
        }
    };

    const onChangeTheme=(e)=> {
        if(e?.target?.value){
            setDataByTheme(e?.target?.value, null, true)
        }
    };

    const setSubCat = (e) => {
        const name = e?.target?.name;
        if(name){
            setCheck({[name]: !check[name]});
            setData(data => ({ ...data, 'survey_category': name}));
        }
    };

    const onCreate=(e)=> {
        const name = localStorage.getItem("surveyName");
        setSubmit(true);
        setTimeout(() => {setSubmit(false)}, 2000);
        data['survey_title'] = surveyName || name;
        data["campaign_id"] = getWorkspaceID()||wsID;
        data["active"] = true;
        data['experience_type'] = activeTheme;
        dispatch(workspaceActions.createWorkspaceDetails(data));

    };

    const themes = () =>{
        return categories.map(e=> {
            const ob = theme[activeTheme][e];
            return <div className="imgWrap" key={"w"+e}>
                <input type="radio" name={e} checked={!!check[e]} onChange={setSubCat} />
                <div className="selected"></div>
                {ob.most_popular ? <span className="popular">Most Popular</span> : null }
                {ob.image ? <img key={'theme_'+e} src={ob.image} className={'survey-event-img'} />: null}
                <div className='bg-overlay'></div>
                <p className='theme-title'>{e}</p>
            </div>
        });

    }
    const dropDownConfig = {name: "theme",variant: "outlined", size:"small", value:activeTheme||themesArr[0]||"", menuItems:themesArr, fieldClass:'select-experience',onChange:onChangeTheme};

    return <div className={"innerScratch"}>
            <div className="scratch-heading">{"Bring you new art survey to life"}</div>
            <div className="choose-theme">
                <div className="select-exp-label">Choose Theme</div>
                <MaterialSelect config={dropDownConfig}/>
            </div>
            <div className="survey-event">
                <div className="eventWrap"> {themes()} </div>
                <div className={"response"} style={{marginTop:30}}>
                    <ShareResponse setData={setData} data={data} hasNonAnonType={hasNonAnonType} setErr={setErr}/>
                    <DisplayResponse setData={setData} data={data} hasNonAnonType={hasNonAnonType} setErr={setErr}/>
                </div>
            </div>
            <div className={"btnGroup"}>
                <div className='back-btn' onClick={()=>{history.push('/')}} ><Label config={{label: 'Cancel', className: 'back-btn-text'}} /></div>
                <div className='next-btn' style={{pointerEvents:submitting?"none":"unset"}}onClick={onCreate}><Label config={{label: 'Create', className: 'next-btn-text'}}/></div>
            </div>
        <Prompt show={err.length} message={err} handler={()=>setErr("")} singleBtn={true} btnLabel="OK" />
        <Backdrop show={loading}/>
        </div>

};

const ShareResponse = ({setData, data, hasNonAnonType, setErr}) => {
    const onSelect = (e) => {
        const {name} = e?.target || {};
        if(name == "sendEmail" && hasNonAnonType){
            setErr('Survey has non-anonymous record.');
          return;
        }
        if(name){
            const cloned = clone(data);
            cloned['share_responses'] = name;
            cloned['audience_responses'] = name === "shareLink" ? 'nonAnon' : '';
            setData(cloned);
        }
    };

    return <div className='response-whiteboard' style={{left:0}}>
            <span className="response-label">Choose how to Share responses</span>
            <div className="survey-response" >
                <ShareLink  value={data.share_responses|| ""} setCheckbox={onSelect}/>
                <SendEmail  value={data.share_responses || ""} setCheckbox={onSelect}/>
            </div>
        </div>
};

const ShareLink = ({value, setCheckbox}) => {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } }
    return <div style={{display:'flex', alignItems:'center'}}>
            <object data={origin+"/images/SVG/survey-link.svg"} width="26" height="28"></object>
            <div className='survey-response-type'>
                <span className='survey-link'>Share survey link</span>
                <span className='survey-helping-txt'>Reference site about Reference site about</span>
            </div>
        <div className="checkBoxWrap"><Checkbox{...label} name="shareLink" value={value} checked={value == "shareLink"} onChange={setCheckbox} sx={{color: "#9FF4A7",'& .MuiSvgIcon-root': { fontSize: 28 }, '&.Mui-checked': {color: "#9FF4A7"}}}/></div>
    </div>
};

const SendEmail = ({value, setCheckbox}) => {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } }
    return <div style={{display:'flex', alignItems:'center', marginTop:25}}>
            <object data={origin+"/images/SVG/sendEmail.svg"} width="26" height="28"></object>
            <div className='survey-response-type'>
                <span className='survey-link'>Send my survey to email</span>
                <span className='survey-helping-txt'>Reference site about Reference site about</span>
            </div>
            <div className="checkBoxWrap"><Checkbox{...label} name="sendEmail" disabled={false} value={value} checked={value == "sendEmail"} onChange={setCheckbox} sx={{color: "#9FF4A7", '& .MuiSvgIcon-root': { fontSize: 28 },'&.Mui-checked': {color: "#9FF4A7"}}}/></div>
    </div>
};

const DisplayResponse = ({data, setData, hasNonAnonType, setErr}) => {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [tips, setTips] =useState(false);

    const onProTipHandle = (e) => {
        setTips(!tips);
    };
    const changeHandler = (e) =>{
        const {name, checked} = e?.target || {};
        if(name){
            if(name == "anon" && hasNonAnonType){
                setErr('Survey has non-anonymous record.');
                return;
            }
            let cloned = clone(data);
            if(checked){
                cloned['audience_responses'] = name;
            }
            if(name == "confidential"){
                cloned['audience_responses'] = checked ? name : "";
            }
            setData(cloned);
        }
    };
    return <div className='response-whiteboard'>
                <span className='response-label'>How to display responses</span>
                <div className="survey-response">
                    <div className={"responseHeading"}>
                        <object data={origin+"/images/SVG/Audience.svg"} width="26" height="28"></object>
                        <span className='survey-link' style={{margin: '2px 15px 0px 15px', fontSize:16}}>Audience Responses</span>

                        <div className="proTips">
                            <div className="proTipButton" onClick={onProTipHandle}>
                                <img src={origin+"/images/SVG/protip_icon.svg"} width="60" height="35" alt="Pro Tips"/>
                            </div>
                            {
                                tips &&  <div className="proTipPopup">
                                    <div className="leftIcon"><ArrowLeftIcon/></div>
                                    <h4>New feature!</h4>
                                    <p>
                                        This is a handy template you can use for your apps
                                        (as a an onboarding tip feature for instance).
                                        Feel free to resize it, change colours and modify the arrow position.
                                    </p>
                                    <div className="btnGroup">
                                        <button className="btn btnPrv">Prev</button>
                                        <button className="btn btnNext">Next</button>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>

                <div className={"responseBody"}>
                    <div className={"responseItem"}>
                        <div className="checkBoxWrap"><Checkbox{...label} name="nonAnon" checked={data.audience_responses === "nonAnon"} onChange={changeHandler} sx={{color: "#9FF4A7", '&.Mui-checked': {color: "#9FF4A7"}}} disabled={data.share_responses === "sendEmail"}/></div>
                        <div className='survey-response-type' style={{marginLeft:15}}>
                            <span className='survey-link'>Non-anonymous</span>
                            <span className='survey-helping-txt'>Collect email addresses Required to send response copies</span>
                        </div>
                    </div>

                    <div className={"responseItem"}>
                        <div className="checkBoxWrap"><Checkbox{...label} name="confidential" checked={data.audience_responses === "confidential"} onChange={changeHandler} sx={{color: "#9FF4A7", '&.Mui-checked': {color: "#9FF4A7"}}} disabled={data.share_responses === "shareLink"}/></div>
                        <div className='survey-response-type' style={{marginLeft:15}}>
                            <span className='survey-link'>Confidential</span>
                            <span className='survey-helping-txt'>Reference site about Reference site about</span>
                        </div>
                    </div>

                    <div className={"responseItem"}>
                        <div className="checkBoxWrap"><Checkbox{...label} name="anon" checked={data.audience_responses === "anon"} onChange={changeHandler} sx={{color: "#9FF4A7", '&.Mui-checked': {color: "#9FF4A7"}}} disabled={data.share_responses === "sendEmail"}/></div>
                        <div className='survey-response-type' style={{marginLeft:15}}>
                            <span className='survey-link'>Anonymous</span>
                            <span className='survey-helping-txt'>Reference site about Reference site about</span>
                        </div>
                    </div>

                </div>

                </div>
            </div>
};

const Base = (props) => {
    const {state} = props?.location || {};
    return <StartSurvey title="Start and scratch">
        <Scratch {...state}/>
    </StartSurvey>
};

export default Base;
