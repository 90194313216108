import React, {useRef, useState} from "react"; 
import Label from '../../../common/controls/inputs/Label';
import '../surveyStyle.css';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Tooltip from '@mui/material/Tooltip';
import { history } from "../../../helpers";

const CommonImage = ({ config: { id, text, isActive, isStatic, onClickHandler, x } }) => {
    return <div className={"itemInner"} onClick={() => onClickHandler(id)}>
        {!isStatic ? <div className='subMenuIcon'>
                <svg width="184" height="46" viewBox="0 0 184 46" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: '-9px' }}>
            <path d="M165.951 0H0L14.0381 24.0698L0 46H165.951L184 24.0698L165.951 0Z" width={200} strokeWidth={"3px"} stroke={isActive ? '#18A0FB' : '#E1E4E8'}  fill={isActive ? '#18A0FB' : '#F9F9F9'} />
            <text width="127" height="22" x={x||"33"} y="28" fill={isActive ? '#FFFFFF' : '#181818'} style={{ position: 'absolute', fontFamily: 'Poppins', fontStyle: 'normal', fontWeight: 500, fontSize: '16px', lineHeight: '24px' }}>{text}</text>
        </svg></div>
            :
            id === 'Home' ? <div style={{cursor:"pointer"}} onClick={()=> history.push("/")}>
                <svg width="109" height="46" viewBox="0 0 109 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M109 24L87.0959 0H-14V46H87.0959L109 24Z" fill="#F9F9F9" stroke="#E1E4E8" strokeWidth="3px" />
                <path d="M60 23H58L67 14L76 23H74" stroke="#18A0FB" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M60 23V30C60 30.5304 60.2107 31.0391 60.5858 31.4142C60.9609 31.7893 61.4696 32 62 32H72C72.5304 32 73.0391 31.7893 73.4142 31.4142C73.7893 31.0391 74 30.5304 74 30V23" stroke="#18A0FB" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M64 32V26C64 25.4696 64.2107 24.9609 64.5858 24.5858C64.9609 24.2107 65.4696 24 66 24H68C68.5304 24 69.0391 24.2107 69.4142 24.5858C69.7893 24.9609 70 25.4696 70 26V32" stroke="#18A0FB" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            </svg> </div>:
                <svg width="85.5" height="46" viewBox="0 0 85.5 46" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: '-15px', marginTop: '-2px', cursor: 'pointer' }} className={isActive ? "pageLogicActive":""}>
                    <g filter="url(#filter0_d_721_1723)">
                        <path d="M91.5 2H6L20.0381 26.0698L6 48H91.5V26.0698V2Z" fill={isActive ? '#18A0FB' : ' #F9F9F9'} stroke="#E1E4E8" strokeWidth="3px"/>
                    </g>
                    <defs>
                        <filter id="filter0_d_721_1723" x="0" y="0" width="97.5" height="58" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="3" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_721_1723" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_721_1723" result="shape" />
                        </filter>
                    </defs>

                    <text x="10" y="28" fill={isActive ? '#F9F9F9' : '#181818'} style={{ position: 'absolute', fontFamily: 'Poppins', fontStyle: 'normal', fontWeight: 500, fontSize: '16px', lineHeight: '18px' }}>{text}</text>
                </svg>}
    </div>
},

    Company = ({ config: { text } }) => {
        return <Tooltip title={text}>
            <div className='companyName'><Label config={{ label: text, className: 'companyNameText' }} /></div>
        </Tooltip>
    },

    config = {
        Home: {
            component: CommonImage,
            isStatic: true
        },
        Summary: {
            text: 'Summary',
            component: CommonImage
        },
        DesignSurvey: {
            text: 'Design survey',
            component: CommonImage

        },
        Preview: {
            text: 'Preview & Score',
            component: CommonImage
        },
        CollectResponses: {
            text: 'Collect responses',
            component: CommonImage,
            x:25
        },
        AnalyseResults: {
            text: 'Analyse Results',
            component: CommonImage
        },
        PresentResults: {
            text: 'Present Results',
            component: CommonImage
        },
        PageLogic: {
            text: 'Page Logic',
            component: CommonImage,
            isStatic: true,
            wrapperClass: 'pageLogicItem'
        },
        SurveyName: {
            text: 'Demo',
            component: Company,
            isStatic: true,
            className: "surveyLastItem",
        },
        FormName: {
            text: 'Form',
            component: Company,
            isStatic: true, 
            wrapperClass: "formLastItem"
        },
        CreateForm:{
            text: 'Design Form',
            component: CommonImage
        },
        AnalyseSubmission:{
            text: 'Analyse Submission',
            component: CommonImage,
            className: "analyseSubmissionItem",
            x:16,
        }
    };

const menus = ['Home', 'Summary', 'DesignSurvey', 'Preview', 'CollectResponses', 'AnalyseResults', 'PageLogic', 'SurveyName'],
    IntializeMenu = ({ id, activeMenu, onClickHandler, surveyTitle, flexStart }) => {
        const { text, isStatic, component: Component, className = "", wrapperClass = "", x } = config[id];
        const label = id === 'SurveyName' ? surveyTitle : text;

        return <div className={`item ${flexStart ? className + " " + wrapperClass : wrapperClass}`} style={{ float: 'left' }}>
            <Component config={{ isActive: (activeMenu === id), text:label, isStatic, id, onClickHandler, x }} />
        </div>
    },

    SubHeaderContent = ({ menus, activeMenu, setActiveMenu, surveyTitle, flexStart=false }) => {
        const onClickHandler = (newId) => {
            setActiveMenu(newId === 'Home'? 'Summary': newId);
        },
            items = menus.map((id, index) => <IntializeMenu key={index} id={id} activeMenu={activeMenu} onClickHandler={onClickHandler} surveyTitle={surveyTitle} flexStart={flexStart}/>)

        const mobileMenu = useRef();
        const backdropOverlay = useRef();
        return <>
            <div className='surveyMenuWrapper'>
                <div className="mobileMenuWrapper">
                    <button onClick={()=> {mobileMenu.current.classList.add('menu-active');backdropOverlay.current.classList.add('active')}}>
                        <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="18" height="18" fill="transparent"/>
                        <line x1="1" y1="3" x2="17" y2="3" stroke="#18A0FB" strokeWidth="2" strokeLinecap="round"/>
                        <line x1="1" y1="9" x2="17" y2="9" stroke="#18A0FB" strokeWidth="2" strokeLinecap="round"/>
                        <line x1="1" y1="15" x2="17" y2="15" stroke="#18A0FB" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </button>
                </div>
                <div className={"surveySubMenu"} style={{ margin:'5px 0', justifyContent: flexStart ? "flex-start":"space-between" }} ref={mobileMenu}>
                    <div className="menuClose">
                        <button onClick={()=>{mobileMenu.current.classList.remove('menu-active');backdropOverlay.current.classList.remove('active');}}><CloseRoundedIcon/></button>
                    </div>
                    {items}
                </div>
                <div className='backdrop-overlay' ref={backdropOverlay} onClick={()=>{mobileMenu.current.classList.remove('menu-active');backdropOverlay.current.classList.remove('active')}}></div>
            </div>
        </>
    },

    SurveySubHeader = (props) => {
    const {surveyMenus} = props;
        return <SubHeaderContent  menus={surveyMenus || menus} {...props} />
    };

export default SurveySubHeader;
